import { CrewTabs } from 'components/elements/crewTabs/crewTabs'
import { FC, memo } from 'react'
import { ProjectMemberEntryDialogTabs } from 'enums/app'
import { useMemo } from 'react'
import { CrewTabProps } from 'components/elements/crewTabs/components/crewTab'
import { useTranslation } from '@crew/modules/i18n'
import { useSystemPermissions } from '@crew/hooks'

export type ProjectMemberEntryTabProps = {
  selectedIndex: number
  tabsSelectionChanged: (selectedIndex: number) => void
}

export const ProjectMemberEntryTab: FC<ProjectMemberEntryTabProps> = memo(
  (props) => {
    const { t } = useTranslation()

    const {
      hasSysExternalUserInvitePermission,
      hasSysInternalUserInvitePermission,
    } = useSystemPermissions()

    // タブアイテム
    const displayTabItems: CrewTabProps[] = useMemo(() => {
      // filter tabs by user permissions
      const tabs = Object.values(ProjectMemberEntryDialogTabs).filter(
        (item) => {
          if (
            !hasSysExternalUserInvitePermission &&
            !hasSysInternalUserInvitePermission &&
            item.id === ProjectMemberEntryDialogTabs.NewUsers.id
          ) {
            return false
          }

          return true
        }
      )
      const items = tabs.map((item) => {
        return {
          onClick: () => {
            // Update selected tab
            props.tabsSelectionChanged(item.id)
          },
          selected: item.id === props.selectedIndex,
          text: t(item.text),
          icon: undefined,
        }
      })

      return items
    }, [
      hasSysExternalUserInvitePermission,
      hasSysInternalUserInvitePermission,
      props,
      t,
    ])

    return <CrewTabs tabItems={displayTabItems} />
  }
)
