import { useTranslation } from '@crew/modules/i18n'
import { useMemo } from 'react'
import { TaskStateTypes } from 'enums/app'
import { useDataSource } from './useDataSource'

// タスク状態タイプから全項目または選択可能な項目をカスタムデータソースで返す
export const useTaskStateTypeDataSource = (onlyChooseable: boolean) => {
  const { t } = useTranslation()

  // enumsのTaskStateTypesをベースにリスト出力用データを用意（nameを翻訳する必要があるので）
  const taskStateTypeBase = useMemo(() => {
    return Object.values(TaskStateTypes).map((taskStateType) => {
      return {
        id: taskStateType.value,
        name: t('label.' + taskStateType.value),
        chooseable: taskStateType.chooseable,
      }
    })
  }, [t])

  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (onlyChooseable) {
          // 選択可能項目のみ出力
          return Object.values(taskStateTypeBase).filter(
            (taskStateType) => taskStateType.chooseable
          )
        }

        return taskStateTypeBase
      },
      byKey: async (taskStateType) => {
        return Object.values(taskStateTypeBase).find((key) => {
          return key.id === taskStateType
        })
      },
    }),
    [onlyChooseable, taskStateTypeBase]
  )
}
