import {
  $applyNodeReplacement,
  $isTextNode,
  DOMConversion,
  DOMConversionMap,
  DOMConversionOutput,
  LexicalNode,
  SerializedTextNode,
  TextNode,
} from 'lexical'

import { ATTRIBUTE_NAME_DEFAULT_VALUE } from '../plugins/importDefaultValuePlugin'

/**
 * 拡張テキストノード
 *
 * 標準のTextNodeでは、DOMをimportした時に文字の色、背景色、フォントサイズが維持されない。
 * そのため、メッセージ編集時に初期値として設定されたテキストからも、これらのスタイルが剥ぎ取られてしまい維持されない。
 * 拡張テキストノードを作成して標準のTextNodeと置き換えることで、初期値に含まれるこれらのスタイルが維持されるようにする。
 *
 * ただし編集欄に入力された値(コピペなど)の場合はこれらのスタイルは破棄したいので、その場合は標準のTextNodeの処理をそのまま使う。
 *
 * 初期値か入力値かの判別は、ノードがカスタム属性を持っているかどうかで判定する。
 * 初期値の場合はImportDefaultValuePluginでカスタム属性を設定する。入力値の場合はカスタム属性が設定されないので、これで判別する。
 *
 *
 * 【補足】TextNodeはプレーンテキストを含むLexicalの基本のノードとなっており、
 * リッチテキストを扱う場合は拡張ノードを作成するよう公式で案内がある。以下URLを参考にしている。
 * https://lexical.dev/docs/concepts/serialization#handling-extended-html-styling
 */
export class ExtendedTextNode extends TextNode {
  static getType(): string {
    return 'extended-text'
  }

  static clone(node: ExtendedTextNode): ExtendedTextNode {
    return new ExtendedTextNode(node.__text, node.__key)
  }

  static importDOM(): DOMConversionMap | null {
    const importers = TextNode.importDOM()
    return {
      ...importers,
      // apply style to text normal
      span: () => ({
        conversion: patchStyleConversion(importers?.span),
        priority: 1,
      }),
      // apply style to text bold
      strong: () => ({
        conversion: patchStyleConversion(importers?.strong),
        priority: 1,
      }),
      // apply style to text underline
      u: () => ({
        conversion: patchStyleConversion(importers?.u),
        priority: 1,
      }),
      // apply style to text strikethrough
      s: () => ({
        conversion: patchStyleConversion(importers?.s),
        priority: 1,
      }),
      // apply style to text italic
      em: () => ({
        conversion: patchStyleConversion(importers?.em),
        priority: 1,
      }),
    }
  }

  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return TextNode.importJSON(serializedNode)
  }

  isSimpleText() {
    return this.__type === 'extended-text' && this.__mode === 0
  }

  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: 'extended-text',
      version: 1,
    }
  }
}

export function $createExtendedTextNode(text: string): ExtendedTextNode {
  return $applyNodeReplacement(new ExtendedTextNode(text))
}

export function $isExtendedTextNode(
  node: LexicalNode | null | undefined
): node is ExtendedTextNode {
  return node instanceof ExtendedTextNode
}

function patchStyleConversion(
  originalDOMConverter?: (node: HTMLElement) => DOMConversion | null
): (node: HTMLElement) => DOMConversionOutput | null {
  return (node) => {
    const original = originalDOMConverter?.(node)
    if (!original) {
      return null
    }
    const originalOutput = original.conversion(node)

    if (!originalOutput) {
      return originalOutput
    }

    // 初期値として設定されたノードかどうかを判定する
    if (!node.getAttribute(ATTRIBUTE_NAME_DEFAULT_VALUE)) {
      // 初期値でない=入力されたテキスト。標準のTextNodeの処理結果を返す
      return originalOutput
    }

    // 初期値。スタイルを維持するため追加で処理を行う

    const backgroundColor = node.style.backgroundColor
    const color = node.style.color
    const fontSize = node.style.fontSize

    return {
      ...originalOutput,
      forChild: (lexicalNode, parent) => {
        const originalForChild = originalOutput?.forChild ?? ((x) => x)
        const result = originalForChild(lexicalNode, parent)
        if ($isTextNode(result)) {
          const style = [
            backgroundColor ? `background-color: ${backgroundColor}` : null,
            color ? `color: ${color}` : null,
            fontSize ? `font-size: ${fontSize}` : null,
          ]
            .filter((value) => value != null)
            .join('; ')
          if (style.length) {
            return result.setStyle(style)
          }
        }
        return result
      },
    }
  }
}
