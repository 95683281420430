import {
  useLazyGetLookupTaskCategoriesQuery,
  useLazyGetLookupTaskCategoryQuery,
} from '@crew/apis/lookup/lookupApis'
import { useDataSource } from './useDataSource'
import { EntityType } from '@crew/enums/domain'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'

export const useTaskCategoryDataSource = (
  entityType: EntityType | undefined,
  entityRecordId: string | undefined,
  isGrouped: boolean = false
) => {
  const [lazyGetTaskCategoriesQuery] = useLazyGetLookupTaskCategoriesQuery()
  const [lazyGetTaskCategoryQuery] = useLazyGetLookupTaskCategoryQuery()

  return useDataSource(
    () => ({
      key: 'id',
      // Using 'raw' load mode to avoid grouping issue
      // https://supportcenter.devexpress.com/ticket/details/t1017195/datasource-grouping-not-rendering-correctly-using-custom-datasource
      loadMode: isGrouped ? 'raw' : undefined,
      load: async (loadOptions) => {
        if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // Filtering selected task category IDs
          const filteringTaskCategoryIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetTaskCategoriesQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            name: undefined,
            taskCategoryIds: filteringTaskCategoryIds,
          }).unwrap()

          return response.taskCategories
        } else {
          // インクリメンタルサーチ
          const response = await lazyGetTaskCategoriesQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            name: loadOptions.searchValue,
            taskCategoryIds: undefined,
          }).unwrap()

          return response.taskCategories
        }
      },
      byKey: async (taskCategoryId: string) => {
        const response = await lazyGetTaskCategoryQuery({
          taskCategoryId,
        }).unwrap()
        return response.taskCategory
      },
      group: isGrouped ? 'projectId' : null,
    }),
    [
      isGrouped,
      entityRecordId,
      entityType,
      lazyGetTaskCategoriesQuery,
      lazyGetTaskCategoryQuery,
    ]
  )
}
