import { $getSelection, LexicalEditor } from 'lexical'
import { $patchStyleText } from '@lexical/selection'
import { DropDownItem, ToolbarDropDown } from './toolbarDropDown'
import { HTML_EDITOR_FONT_SIZES } from 'configs/constants'
import { useCallback } from 'react'
import classNames from 'classnames'

export function ToolbarFontSizeChangeDropDown({
  disabled = false,
  editor,
  value,
  isRTL,
}: {
  disabled: boolean
  editor: LexicalEditor
  value: string
  isRTL: boolean
}) {
  const handleValueChanged = useCallback(
    (size: string) => {
      return () => {
        editor.update(() => {
          // 現在選択中のテキストに対してフォントサイズを設定する
          const selection = $getSelection()
          if (selection !== null) {
            $patchStyleText(selection, {
              'font-size': size,
              // Adjust the line height as well, as the spacing between lines becomes narrower when you change the font size
              'line-height': '1.3',
            })
          }
        })
      }
    },
    [editor]
  )

  return (
    <ToolbarDropDown
      disabled={disabled}
      buttonAriaLabel="Formatting options for font size"
      buttonLabel={value}
      showDropDownIcon={true}
    >
      {Object.entries(HTML_EDITOR_FONT_SIZES).map(([size, text]) => (
        <DropDownItem
          className={classNames(
            'item',
            value === size && 'active dropdown-item-active',
            'fontsize-item'
          )}
          onClick={handleValueChanged(size)}
          key={size}
        >
          <span className="text">{text}</span>
        </DropDownItem>
      ))}
    </ToolbarDropDown>
  )
}
