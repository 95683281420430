import { forwardRef, HTMLAttributes, memo } from 'react'
import classNames from 'classnames'

import { BaseProps } from './base'
import { CrewShowMicrophoneMuteAndName } from 'components/elements/crewShowMicrophoneMuteAndName/crewShowMicrophoneMuteAndName'

export const ObjectFit = {
  Contain: 'contain',
  Cover: 'cover',
  Fill: 'fill',
  None: 'none',
  ScaleDown: 'scale-down',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ObjectFit = (typeof ObjectFit)[keyof typeof ObjectFit]

const objectFitToClassName = (objectFit: ObjectFit): string =>
  `object-${objectFit}`

export type VideoTileProps = HTMLAttributes<HTMLDivElement> &
  BaseProps & {
    /**
     * タイルに表示する名前
     */
    name?: string | null
    /**
     * コンテナに対するビデオタイルのリサイズ方法
     */
    objectFit?: ObjectFit
    /**
     * Id member attendee
     */
    chimeAttendeeId?: string
  }

/**
 * ビデオ表示領域
 */
export const VideoTile = memo(
  forwardRef<HTMLVideoElement, VideoTileProps>(
    ({ className, name, objectFit, chimeAttendeeId, ...rest }, ref) => (
      // relative, absolute を使用しているため、親要素で高さを明示的に指定しないと正しく表示されない
      <div className={classNames('relative', className)} {...rest}>
        <video
          ref={ref}
          className={classNames(
            'absolute',
            'top-0',
            'left-0',
            'w-full',
            'h-full',
            objectFitToClassName(objectFit ?? 'contain')
          )}
        />
        {chimeAttendeeId && name && (
          <CrewShowMicrophoneMuteAndName
            chimeAttendeeId={chimeAttendeeId}
            name={name}
          />
        )}
      </div>
    )
  )
)
