//TODO: リファクタリングが必要
//      https://break-tmc.atlassian.net/browse/CREW-9777
import { EntityType } from '@crew/enums/domain';
import { apiBaseUrl } from '@crew/configs/constants';
import { ImageFormat } from '@crew/enums/app';
/**
 * Generate url avatar by entity type
 * @date 5/18/2023 - 11:35:34 AM
 *
 * @param {EntityType} entityType
 * @param {string} entityRecordId
 * @returns {string}
 */
export const generateImageAvatarUrl = (entityType, entityRecordId, format = ImageFormat.Svg) => {
    switch (entityType) {
        case EntityType.Project: {
            return entityRecordId
                ? `${apiBaseUrl()}api/v1/projects/avatar-download?projectId=${entityRecordId}&format=${format}`
                : undefined;
        }
        case EntityType.User: {
            return entityRecordId
                ? `${apiBaseUrl()}api/v1/users/avatar-download?userId=${entityRecordId}&format=${format}`
                : undefined;
        }
        case EntityType.File: {
            return entityRecordId
                ? `${apiBaseUrl()}api/v1/files/avatar-download?fileId=${entityRecordId}&format=${format}`
                : undefined;
        }
        case EntityType.Tenant: {
            const downloadUrl = `${apiBaseUrl()}api/v1/tenants/avatar-download?format=${format}`;
            return entityRecordId
                ? `${downloadUrl}&tenantId=${entityRecordId}`
                : downloadUrl;
        }
        case EntityType.DirectChannel: {
            return entityRecordId
                ? `${apiBaseUrl()}api/v1/projects/direct-channels/avatar-download?entityRecordId=${entityRecordId}&format=${format}`
                : undefined;
        }
        default:
            return undefined;
    }
};
