import { FC, memo } from 'react'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { TaskKindListToolbar } from './components/taskKindListToolbar/taskKindListToolbar'
import { TaskKindListGrid } from './components/taskKindListGrid/taskKindListGrid'

export const ProjectSettingTaskKindList: FC = memo(() => {
  return (
    <div className="flex flex-col gap-2">
      <CrewErrorBoundary>
        <TaskKindListToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <TaskKindListGrid />
      </CrewErrorBoundary>
    </div>
  )
})
