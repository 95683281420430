import { CrewAccordion } from 'components/elements/crewAccordion/crewAccordion'
import { FC, memo } from 'react'
import { UnreadProjectList } from './components/unreadProjectList/unreadProjectList'
import { useTranslation } from '@crew/modules/i18n'

// 未読プロジェクトを表示するパネル
export const UnreadProjectPanel: FC = memo((props) => {
  const { t } = useTranslation()

  return (
    <CrewAccordion title={t('label.unread')} content={<UnreadProjectList />} />
  )
})
