//TODO utils配下でよいか検討し、必要なら別フォルダに移動

import { BillingCycle, ContractPlan } from '@crew/enums/app'
import { KeywordFilterCondition } from '@crew/enums/domain'
import { getEnumDataByValue } from '@crew/utils/enum'
import { TaskPriorities } from 'enums/app'
import { CrewBadgeInvertedColorClass } from 'enums/color'
import { isEqual, isNil, omitBy } from 'lodash'
import { ParsedQs } from 'qs'

import { memo } from 'react'

// 文字からバッジ色(inverted)を取得する
export const convertStringToBadgeColor = (str: string) => {
  //バッジの色リスト
  const colors = Object.values(CrewBadgeInvertedColorClass)

  const initials = str.trim()[0].charAt(0) // get first letter

  const charIndex = initials.charCodeAt(0)

  const colorIndex = charIndex % colors.length // get index of colour
  return colors[colorIndex]
}

// genericsコンポーネント向けに型を素通しするmemo関数
// https://stackoverflow.com/questions/57477395/typescript-generic-class-equivalent-for-react-memo
//TODO: webとmobileでReactのバージョンが違うため共通化できない。CREW-1322 の対応でReact18に揃ったら@crew/utilsに移動する。
export const genericMemo: <T>(component: T) => T = memo

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

// タスク優先度の値からEnumを取得して返す関数
export const getTaskPriorityEnumByValue = (value: number) => {
  return getEnumDataByValue(TaskPriorities, value)
}

// set covert file to base64
export const convertFileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

// validate value is ContractPlan value
export const validateContractPlan = (value: unknown): value is ContractPlan => {
  if (typeof value !== 'string' || value === null) return false
  return Object.values(ContractPlan).includes(value as ContractPlan)
}

// validate value is BillingCycle value
export const validateBillingCycle = (value: unknown): value is BillingCycle => {
  if (typeof value !== 'string' || value === null) return false
  return Object.values(BillingCycle).includes(value as BillingCycle)
}

export const isEqualParams = (a: object, b: object): boolean => {
  const aWithoutUndefined = omitBy(a, isNil)
  const bWithoutUndefined = omitBy(b, isNil)

  return isEqual(aWithoutUndefined, bWithoutUndefined)
}

export function getParamAsString(
  key: string,
  parsed: ParsedQs
): string | undefined {
  const value = parsed[key]

  if (typeof value === 'string') {
    return value
  }

  return undefined
}

export function getParamAsArray(
  key: string,
  parsed: ParsedQs
): string[] | undefined {
  const value = parsed[key]

  // ひとつの場合も配列で返す
  if (typeof value === 'string' && value.length > 0) {
    return [value]
  } else if (Array.isArray(value)) {
    return value.map(String) // string[] に変換
  }

  return undefined
}

export function getUniqueStr(myStrong?: number): string {
  let strong = 1000
  if (myStrong) strong = myStrong
  return (
    new Date().getTime().toString(16) +
    Math.floor(strong * Math.random()).toString(16)
  )
}

// Check valid date string (format: yyyy-MM-dd)
export function isValidDate(value: string | undefined): boolean {
  return /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value ?? '')
}

export function getParamAsDate(
  key: string,
  parsed: ParsedQs
): string | undefined {
  const value = parsed[key]

  if (typeof value === 'string') {
    const dateArray = value.split(':')
    const dateValue = dateArray.length > 1 ? dateArray[1] : undefined
    switch (dateArray[0]) {
      case 'yesterday':
      case 'today':
      case 'tomorrow':
      case 'lastMonth':
      case 'thisMonth':
      case 'nextMonth':
        return value
      case 'thatDay':
      case 'beforeAt':
      case 'afterAt':
        if (isValidDate(dateValue)) {
          return value
        }
        return undefined
      case 'pastDays':
      case 'nextDays':
        if (!isNaN(Number(dateValue))) {
          return value
        }
        return undefined
      case 'between':
        const dateValueArray = dateValue ? dateValue.split('_') : []
        if (dateValueArray.length === 2) {
          return isValidDate(dateValueArray[0]) &&
            isValidDate(dateValueArray[1])
            ? value
            : undefined
        }
        return undefined
    }
  }

  return undefined
}

// これまで行っていたwindow.location.hrefに代入する処理だと都合が悪い（画面遷移として扱われてしまう）
// Function to download a file from a given URL
/**
 * 引数のURLからファイルをダウンロードする
 * @param url ダウンロード先URL
 * @param fileName ダウンロード時に設定するファイル名
 * @param useCache キャッシュを使用するかどうか
 */
export const downloadFileFromUrl = async (
  url: string,
  fileName: string,
  useCache: boolean = true
) => {
  // 引数のURLからファイルをダウンロードする
  // useCacheが指定されていない場合はキャッシュを使用し、falseの場合はキャッシュを使用しないようにする
  // これは、ChromeのCORSポリシーに関連しており、キャッシュされているとOriginヘッダーが付与されないため、S3側のCORS設定によってエラーが発生する。
  // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948
  // この問題はLightboxのファイルダウンロード処理に影響があったため、CREW-14564で対応を行った
  // （Lightbox上に表示するURLとダウンロード時のURLが同じになることに起因している）
  const response = await fetch(url, {
    cache: useCache ? 'default' : 'no-store',
  })

  // Convert the response into a Blob (Binary Large OBject)
  // which represents data that isn't necessarily in a JavaScript-native format
  const blob = await response.blob()

  // Create a new object URL representing the specified File object or Blob object
  const downloadUrl = window.URL.createObjectURL(blob)

  // Create a new 'a' HTML element
  const a = document.createElement('a')

  // Set the href of the 'a' element to the download URL
  a.href = downloadUrl

  // Set the download attribute of the 'a' element to the provided file name
  // This will be the file name for the downloaded file
  a.download = fileName

  // Programmatically click the 'a' element to start the download
  a.click()

  // After triggering the download, clean up the object URL to free up memory
  window.URL.revokeObjectURL(downloadUrl)
}

// Custom sorting function for WBS (Work Breakdown Structure) numbers.
export const compareWBSNumber = (a: string, b: string): number => {
  // Regular expression to match valid WBS format x.x.x
  const wbsRegex = /^\d+(\.\d+)*$/

  // Function to split WBS numbers into an array of numbers
  const parseWBS = (wbs: string): number[] => {
    return wbs.split('.').map(Number)
  }

  // Check if both WBS numbers are valid
  const aIsValid = wbsRegex.test(a)
  const bIsValid = wbsRegex.test(b)

  // If both are valid, compare them numerically
  if (aIsValid && bIsValid) {
    const aParts = parseWBS(a)
    const bParts = parseWBS(b)

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aValue = aParts[i] ?? 0
      const bValue = bParts[i] ?? 0

      if (aValue < bValue) return -1
      if (aValue > bValue) return 1
    }
    return 0
  }

  // If only one is valid, the valid one should come first
  if (aIsValid) return -1
  if (bIsValid) return 1

  // If a is empty, it should come first
  if (!a) return 1

  // If both are invalid, compare them lexicographically
  return a.localeCompare(b)
}

// Get the operator for keyword filter
export const getParamsOperator = (
  key: string,
  parsed: ParsedQs
): KeywordFilterCondition | undefined => {
  const value = parsed[key]

  if (value === 'and' || value === 'or') {
    return value
  }

  return undefined
}
