import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useSendAuthenticationCodeMutation } from '@crew/apis/setting/settingApis'

export type FormValues = {
  emailAddress: string
}
const formInitialValues: FormValues = {
  emailAddress: '',
}

export const useTwoFactorMail = () => {
  // react-hook-formの各種データを取得
  const { control, handleSubmit, reset, setValue, setError } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Get function for send authentication code
  const [sendAuthenticationCodeMutation] = useSendAuthenticationCodeMutation()

  // Send authentication code process
  const sendAuthCode = useCallback(
    async (emailAddress: string) => {
      // send verification code to email address
      await sendAuthenticationCodeMutation({
        destination: emailAddress,
        regionCode: undefined,
      }).unwrap()
    },
    [sendAuthenticationCodeMutation]
  )

  return {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    sendAuthCode,
  }
}
