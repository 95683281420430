import { FC, memo } from 'react'
import { useTranslation } from '@crew/modules/i18n'

import { CrewLoadingText } from 'components/elements/crewLoading/crewLoadingText/crewLoadingText'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'
import { EntityType } from '@crew/enums/domain'
import { CrewLink, LinkColor } from 'components/elements/crewLink/crewLink'

import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { getDefaultTabValue } from '@crew/utils/enum'
import { DirectMessageTabs } from 'enums/app'

export type CrewChatMessageItemHeaderProps = {
  userName?: string
  userId?: string
  timestamp: string
  isShowChatRoomName: boolean
  entityType: EntityType
  entityRecordId: string
  rootEntityType: EntityType
  rootEntityRecordId: string
}

export const CrewChatMessageItemHeader: FC<CrewChatMessageItemHeaderProps> =
  memo((props) => {
    const { t } = useTranslation()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    return (
      <div className="flex flex-row gap-2 truncate">
        <CrewLink
          // Go user detail by click avatar
          to={`/${EntityType.User}/${props.userId}/${getDefaultTabValue(
            DirectMessageTabs
          )}`}
          className="text-sm font-bold crew-text-gray-4 hover:text-crew-gray-500 hover:dark:text-crew-gray-400"
        >
          {props.userName ?? (
            <CrewLoadingText className="h-full inline-block" />
          )}
        </CrewLink>
        <span className="text-sm crew-text-gray-5">
          {t('format.timestamp', {
            value: props.timestamp,
            timeZone: defaultUserProfileRegion,
          })}
        </span>
        {/* Show related link when isShowChatRoomName is equal to true */}
        {props.isShowChatRoomName && (
          <span className="text-sm crew-text-gray-5 truncate">
            {props.entityRecordId !== props.rootEntityRecordId && (
              <>
                <CrewRelatedItemLink
                  entityType={props.rootEntityType}
                  id={props.rootEntityRecordId}
                  color={LinkColor.Gray}
                />
                &nbsp;/&nbsp;
              </>
            )}
            <CrewRelatedItemLink
              entityType={props.entityType}
              id={props.entityRecordId}
              color={LinkColor.Gray}
            />
          </span>
        )}
      </div>
    )
  })
