import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Scheduler, { View } from 'devextreme-react/scheduler'
import { useProjectDetailTaskListCalendar } from './useProjectDetailTaskListCalendar'
import dayjs from '@crew/modules/dayjs'
import {
  CrewTaskTooltip,
  TaskTooltip,
} from 'components/elements/crewTaskTooltip/crewTaskTooltip'
import { Task } from '@crew/models/domain'
import { useAppSelector } from 'states/hooks'
import { AppointmentFormOpeningEvent } from 'devextreme/ui/scheduler'
import { useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { getParamAsArray, getParamAsDate, getParamAsString } from 'utils'
import { DetailTaskSearchOptions } from 'enums/app'
import { JsonDateFormat } from '@crew/enums/system'
import { isDate } from '@crew/utils'

export const ProjectDetailTaskListCalendar = memo(() => {
  // 表示対象のDate（データ取得対象期間の算出に使用する）
  const [displayTargetDate, setDisplayTargetDate] = useState<Date>(new Date())

  const [searchParams] = useSearchParams()
  const params = useMemo(
    () => qs.parse(searchParams.toString()),
    [searchParams]
  )

  const { tasksDataSource } = useProjectDetailTaskListCalendar()

  useEffect(() => {
    const filterParams = {
      keyword: getParamAsString(DetailTaskSearchOptions.Keyword.id, params),
      taskKindIds: getParamAsArray(
        DetailTaskSearchOptions.TaskKindId.id,
        params
      ),
      assignToUser: getParamAsString(
        DetailTaskSearchOptions.AssignToUser.id,
        params
      ),
      taskStateIds: getParamAsArray(
        DetailTaskSearchOptions.TaskStateId.id,
        params
      ),
      taskStateTypes: getParamAsArray(
        DetailTaskSearchOptions.TaskStateType.id,
        params
      ),
      taskPriorities: getParamAsArray(
        DetailTaskSearchOptions.TaskPriority.id,
        params
      )?.map((taskPriority) => Number(taskPriority)), // string[] -> number[]
      taskCategoryIds: getParamAsArray(
        DetailTaskSearchOptions.TaskCategoryId.id,
        params
      ),
      startDate: getParamAsDate(DetailTaskSearchOptions.StartDate.id, params),
      dueDate: getParamAsDate(DetailTaskSearchOptions.DueDate.id, params),
      createdById: getParamAsString(
        DetailTaskSearchOptions.CreatedById.id,
        params
      ),
      updatedById: getParamAsString(
        DetailTaskSearchOptions.UpdatedById.id,
        params
      ),
      createdAt: getParamAsDate(DetailTaskSearchOptions.CreatedAt.id, params),
      updatedAt: getParamAsDate(DetailTaskSearchOptions.UpdatedAt.id, params),
      targetDateFrom: dayjs(displayTargetDate)
        .startOf('month') // 月の初日
        .format(JsonDateFormat.YYYYMMDD),
      targetDateTo: dayjs(displayTargetDate)
        .endOf('month') // 月の最終日
        .format(JsonDateFormat.YYYYMMDD),
    }

    tasksDataSource.filter(filterParams)
    tasksDataSource.load()
  }, [displayTargetDate, params, tasksDataSource])

  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)

  // タスクの更新・削除が行われたときのみカスタムデータソースをリロード
  useEffect(() => {
    if (taskEventMessage) {
      // データソースがロードされていないと実行時エラーが発生するため処理を中断する
      if (!tasksDataSource.isLoaded()) return

      tasksDataSource.reload()
    }
  }, [taskEventMessage, tasksDataSource])

  // カレンダーセルクリック時のデフォルトツールチップ表示を抑制
  // NOTE: onCellClickでe.cancel = trueとしてもセルクリック時は抑制できるが、
  //       編集ダイアログ表示時になぜかツールチップもセットで表示されてしまうため、
  //       ツールチップ自体の起動タイミングでキャンセルできるonAppointmentFormOpeningを使用
  const handleAppointmentFormOpening = useCallback(
    (event: AppointmentFormOpeningEvent) => {
      event.cancel = true
    },
    []
  )

  // 日付変更イベントハンドラ
  const handleCurrentDateChange = useCallback(
    (e: string | number | Date) => {
      if (isDate(e)) {
        // 表示対象日付のstateを更新する
        setDisplayTargetDate(e)
      }
    },
    [setDisplayTargetDate]
  )

  const [schedulerWidth, setSchedulerWidth] = useState<number>()

  // Listen layout change to update schedule width
  // Cell item position/width will be recalculated by the devextreme after update schedule width
  const schedulerRef = useCallback((node: HTMLDivElement) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() =>
      setSchedulerWidth(node.offsetWidth)
    )
    resizeObserver.observe(node)
  }, [])

  return (
    <div ref={schedulerRef}>
      <Scheduler
        timeZone={dayjs.tz.guess()}
        dataSource={tasksDataSource}
        defaultCurrentView="month"
        defaultCurrentDate={displayTargetDate}
        height={800}
        width={schedulerWidth}
        textExpr="subject"
        startDateExpr="startDate"
        endDateExpr="dueDate"
        appointmentTooltipComponent={(item) => {
          // Task item data of appointment tooltip
          const task: Task = item.data.appointmentData
          // Data to display in tooltip
          const taskTooltip: TaskTooltip = {
            id: task.id,
            subject: task.subject,
            description: task.description,
            startDate: task.startDate,
            dueDate: task.dueDate,
            assignToUser: task.assignToUser,
            taskPriority: task.taskPriority,
            taskKind: task.taskKind,
            taskState: task.taskState,
            estimatedWorkTimes: task.estimatedWorkTimes,
            actualWorkTimes: task.actualWorkTimes,
            remainingWorkTimes: task.remainingWorkTimes,
          }

          return <CrewTaskTooltip data={taskTooltip} />
        }}
        onAppointmentFormOpening={handleAppointmentFormOpening}
        onCurrentDateChange={handleCurrentDateChange}
      >
        <View type="month" />
      </Scheduler>
    </div>
  )
})
