import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { memo } from 'react'
import { TaskDetailWorkInputForm } from './components/taskDetailWorkInputForm/taskDetailWorkInputForm'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { TaskDetailCommentListPageContext } from '../../useTaskDetailCommentListPanel'

type TaskDetailWorkInputProps = {
  isEditMode: boolean
  taskWorkId?: string
  version?: number
  onClosed?: () => void
}

export const TaskDetailWorkInputPanel = memo(
  (props: TaskDetailWorkInputProps) => {
    const { t } = useTranslation()
    const [isOpenWorkInput, setIsOpenWorkInput] = useState(false)
    const { editItemId, setEditItemId } = useContext(
      TaskDetailCommentListPageContext
    )

    // タスク作業の1行テキストボックスフォーカス時はタスク作業入力パネルをオープン
    const handleOpenWorkInput = useCallback(() => {
      setIsOpenWorkInput(true)
      setEditItemId(null)
    }, [setEditItemId])

    // タスク作業入力パネルのキャンセルボタン押下時はタスク作業入力パネルをクローズ
    const handleCloseWorkInput = useCallback((handleClosed?: () => void) => {
      setIsOpenWorkInput(false)

      // クローズ後の処理
      // TODO: CREW-3963の編集フォームに切り替え実装時にキャンセルボタン押下でコメントブロックに戻す引数（関数）を組み込む
      //       https://break-tmc.atlassian.net/browse/CREW-3963
      if (handleClosed && typeof handleClosed === 'function') {
        handleClosed()
      }
    }, [])

    //close create work input when edit work input open
    useEffect(() => {
      if (editItemId) {
        setIsOpenWorkInput(false)
      }
    }, [editItemId])

    return (
      <>
        {isOpenWorkInput ? (
          <TaskDetailWorkInputForm
            isEditMode={props.isEditMode}
            taskWorkId={props.taskWorkId}
            version={props.version}
            onClose={handleCloseWorkInput}
          />
        ) : (
          <>
            {/* 新規登録時の1行テキストボックス表示 */}
            {!props.isEditMode && (
              <CrewTextBox
                placeholder={`${t('label.comment')}...`}
                onFocusIn={handleOpenWorkInput}
              />
            )}
          </>
        )}
      </>
    )
  }
)
