import {
  CrewAvatar,
  CrewAvatarShapeType,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewGroupAvatar } from 'components/elements/crewGroupAvatar/crewGroupAvatar'
import { memo } from 'react'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import {
  useGetTenantQuery,
  useGetTenantSettingMembersQuery,
} from '@crew/apis/tenantSetting/tenantSettingApis'
import { SettingKeyType } from '@crew/enums/app'
import { EntityType } from '@crew/enums/domain'
import { UserRef } from '@crew/models/refs'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useMemo } from 'react'
import { useAppSelector } from 'states/hooks'

//return type for reduce function
type ReduceReturnType = {
  [key in SettingKeyType]?: string | null
}

/**
 * Tenant Setting Head Panel
 * @date 8/8/2023 - 11:19:23 AM
 *
 * @type {*}
 */
export const TenantSettingDetailPanel = memo(() => {
  const tenantSettingEventMessage = useAppSelector(
    (state) => state.app.tenantSettingEventMessage
  )
  const getTenantSettingMembersParams = {
    keyword: undefined,
  }
  const getTenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [SettingKeyType.OrganizationName],
  }
  // get tenant setting data
  const { data: getTenantSettings, refetch: reloadGetTenantSettings } =
    useGetTenantSettingsQuery(getTenantSettingsRequestParams)
  // Call API get list user tenant
  const { data: getTenantSettingMembersResult } =
    useGetTenantSettingMembersQuery(getTenantSettingMembersParams ?? skipToken)

  // テナント情報取得（テナントアバター用にバージョンが必要なため）
  const { data: getTenant } = useGetTenantQuery()

  // tenant user list
  const tenantUserList = useMemo(() => {
    let listUser: UserRef[] = []
    if (getTenantSettingMembersResult?.tenantSettingMembers) {
      // Because CrewGroupAvatar require UserRef so we need map list tenant user to UserRef
      listUser = getTenantSettingMembersResult.tenantSettingMembers.map(
        (item) => {
          return {
            id: item.userId,
            displayName: item.displayName,
            version: item.version,
          }
        }
      )
    }
    return listUser
  }, [getTenantSettingMembersResult])

  // tenant name
  const tenantName = useMemo(() => {
    //convert tenant settings to object
    const tenantSettings =
      getTenantSettings?.tenantSettings.reduce<ReduceReturnType>(
        (tenantSetting, currentValue) => ({
          ...tenantSetting,
          [currentValue.key]: currentValue.value,
        }),
        {}
      )
    return tenantSettings?.[SettingKeyType.OrganizationName]
  }, [getTenantSettings])

  // tenant avatar url
  // TODO: Web: generateImageAvatarUrlのリファクタリング
  // https://break-tmc.atlassian.net/browse/CREW-9777
  const tenantAvatarUrl = generateImageAvatarUrl(EntityType.Tenant)

  // refresh tenant name
  useEffect(() => {
    reloadGetTenantSettings()
  }, [tenantSettingEventMessage, reloadGetTenantSettings])
  return (
    <div className="flex flex-row justify-between items-start gap-2.5">
      <div className="flex flex-row items-center gap-2.5">
        {/* Tenant Avatar */}

        <CrewAvatar
          displayName={tenantName ?? ''}
          shape={CrewAvatarShapeType.Square}
          imageURL={tenantAvatarUrl}
          cacheValue={
            // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
            getTenant?.tenant
              ? getTenant.tenant.id + getTenant.tenant.version
              : ''
          }
        />

        <div className="flex flex-col">
          {/* Tenant name */}
          <div className="font-normal text-xl" title={tenantName ?? ''}>
            {tenantName}
          </div>
        </div>

        <div className="flex">
          {/* Tenant user list */}
          <CrewGroupAvatar groupAvatar={tenantUserList} showAddAvatar={true} />
        </div>
      </div>
    </div>
  )
})
