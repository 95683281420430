import type { RefObject } from 'react'
import { useEffect } from 'react'

/**
 * Hook to verify if the user clicked outside the element.
 * @param elements - array of ref elements
 * @param callback - the callback to call when the user clicked outside
 * @public
 */

export function useOutsideClick<T extends Element>(
  ref: RefObject<T>,
  callback: (e: MouseEvent) => void
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        return callback(event)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [callback, ref])
}
