import { memo, useCallback, useEffect, useMemo } from 'react'
import Scheduler, { Resource, SchedulerTypes } from 'devextreme-react/scheduler'
import { useProjectDetailTaskListTimelineScheduler } from './useProjectDetailTaskListTimelineScheduler'
import dayjs from '@crew/modules/dayjs'
import {
  CrewTaskTooltip,
  TaskTooltip,
} from 'components/elements/crewTaskTooltip/crewTaskTooltip'
import { Task } from '@crew/models/domain'
import { AppointmentFormOpeningEvent } from 'devextreme/ui/scheduler'
import { useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { DetailTaskSearchOptions } from 'enums/app'
import { getParamAsArray, getParamAsDate, getParamAsString } from 'utils'

const groups = ['assignToUser.id']
const views: SchedulerTypes.ViewType[] = ['timelineMonth']

export const ProjectDetailTaskListTimelineScheduler = memo(() => {
  const [searchParams] = useSearchParams()
  const params = useMemo(
    () => qs.parse(searchParams.toString()),
    [searchParams]
  )

  const {
    usersDataSource,
    tasksDataSource,
    taskStateDataSource,
    tasksSchedulerRef,
    taskEventMessage,
  } = useProjectDetailTaskListTimelineScheduler()

  useEffect(() => {
    const filterParams = {
      keyword: getParamAsString(DetailTaskSearchOptions.Keyword.id, params),
      taskKindIds: getParamAsArray(
        DetailTaskSearchOptions.TaskKindId.id,
        params
      ),
      assignToUser: getParamAsString(
        DetailTaskSearchOptions.AssignToUser.id,
        params
      ),
      taskStateIds: getParamAsArray(
        DetailTaskSearchOptions.TaskStateId.id,
        params
      ),
      taskStateTypes: getParamAsArray(
        DetailTaskSearchOptions.TaskStateType.id,
        params
      ),
      taskPriorities: getParamAsArray(
        DetailTaskSearchOptions.TaskPriority.id,
        params
      )?.map((taskPriority) => Number(taskPriority)), // string[] -> number[]
      taskCategoryIds: getParamAsArray(
        DetailTaskSearchOptions.TaskCategoryId.id,
        params
      ),
      startDate: getParamAsDate(DetailTaskSearchOptions.StartDate.id, params),
      dueDate: getParamAsDate(DetailTaskSearchOptions.DueDate.id, params),
      createdById: getParamAsString(
        DetailTaskSearchOptions.CreatedById.id,
        params
      ),
      updatedById: getParamAsString(
        DetailTaskSearchOptions.UpdatedById.id,
        params
      ),
      createdAt: getParamAsDate(DetailTaskSearchOptions.CreatedAt.id, params),
      updatedAt: getParamAsDate(DetailTaskSearchOptions.UpdatedAt.id, params),
    }

    tasksDataSource.filter(filterParams)

    // データソースがロードされていないと実行時エラーが発生するため処理を中断する
    if (tasksDataSource.isLoaded()) {
      tasksDataSource.load()
    }
  }, [params, tasksDataSource])

  // タスクの更新・削除が行われたときのみカスタムデータソースをリロード
  useEffect(() => {
    if (taskEventMessage) {
      // データソースがロードされていないと実行時エラーが発生するため処理を中断する
      if (!tasksDataSource.isLoaded()) return

      tasksDataSource.reload()
    }
  }, [taskEventMessage, tasksDataSource])

  // カレンダーセルクリック時のデフォルトツールチップ表示を抑制
  // NOTE: onCellClickでe.cancel = trueとしてもセルクリック時は抑制できるが、
  //       編集ダイアログ表示時になぜかツールチップもセットで表示されてしまうため、
  //       ツールチップ自体の起動タイミングでキャンセルできるonAppointmentFormOpeningを使用
  const handleAppointmentFormOpening = useCallback(
    (event: AppointmentFormOpeningEvent) => {
      event.cancel = true
    },
    []
  )

  // appointmentComponentのレンダリング
  // NOTE: 公式のカスタムテンプレートを参考
  //       https://js.devexpress.com/Demos/WidgetsGallery/Demo/Scheduler/CustomTemplates/React/Light/
  const appointment = useCallback((model: any) => {
    return <>{model.data.appointmentData.subject}</>
  }, [])

  return (
    <div className="h-full pb-2.5">
      <Scheduler
        timeZone={dayjs.tz.guess()}
        dataSource={tasksDataSource}
        defaultCurrentView="timelineMonth"
        defaultCurrentDate={new Date()}
        height="100%"
        textExpr="subject"
        startDateExpr="startDate"
        endDateExpr="dueDate"
        ref={tasksSchedulerRef}
        groups={groups}
        views={views}
        appointmentComponent={appointment}
        appointmentTooltipComponent={(item) => {
          // Task item data of appointment tooltip
          const task: Task = item.data.appointmentData
          // Data to display in tooltip
          const taskTooltip: TaskTooltip = {
            id: task.id,
            subject: task.subject,
            description: task.description,
            startDate: task.startDate,
            dueDate: task.dueDate,
            assignToUser: task.assignToUser,
            taskPriority: task.taskPriority,
            taskKind: task.taskKind,
            taskState: task.taskState,
            estimatedWorkTimes: task.estimatedWorkTimes,
            actualWorkTimes: task.actualWorkTimes,
            remainingWorkTimes: task.remainingWorkTimes,
          }

          return <CrewTaskTooltip data={taskTooltip} />
        }}
        onAppointmentFormOpening={handleAppointmentFormOpening}
      >
        <Resource
          fieldExpr="taskState.id"
          dataSource={taskStateDataSource}
          useColorAsDefault={true}
          colorExpr="displayColor"
        />

        <Resource
          fieldExpr="assignToUser.id"
          displayExpr="displayName"
          allowMultiple={false}
          dataSource={usersDataSource}
          label=""
        />
      </Scheduler>
    </div>
  )
})
