import { FC, memo } from 'react'
import { AttentionTabs } from './components/attentionTabs/attentionTabs'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AttentionTabType } from 'enums/app'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { AllMessageList } from './components/allMessageList/allMessageList'
import { MentionMessageList } from './components/mentionMessageList/mentionMessageList'
import { ReplyMessageList } from './components/replyMessageList/replyMessageList'
import { ReactionMessageList } from './components/reactionMessageList/reactionMessageList'

export const AttentionPage: FC = memo(() => {
  return (
    <div className="min-h-0 flex-1 flex flex-col grow w-full">
      <CrewErrorBoundary>
        <div className="p-2">
          <AttentionTabs />
        </div>
        {/* URLに応じて表示するコンポーネントを切り替える */}
        <Routes>
          {/** ルート(/)へのアクセス時に「すべて」に遷移する用のルート */}
          <Route
            index
            element={
              <Navigate to={AttentionTabType.All.value} replace={true} />
            }
          />
          <Route
            path={AttentionTabType.All.value}
            element={<AllMessageList />}
          />
          <Route
            path={AttentionTabType.Mention.value}
            // TODO: CREW-14070 でメンション一覧表示の実装
            element={<MentionMessageList />}
          />
          <Route
            path={AttentionTabType.Reply.value}
            element={<ReplyMessageList />}
          />
          <Route
            path={AttentionTabType.Reaction.value}
            element={<ReactionMessageList />}
          />
        </Routes>
      </CrewErrorBoundary>
    </div>
  )
})
