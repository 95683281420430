import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { Modal } from 'components/layouts/modal/modal'
import { FC, memo, useCallback, useMemo } from 'react'
import { FormValues, useFolderEntryDialog } from './useFolderEntryDialog'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { EntityType } from '@crew/enums/domain'
import { useGetFolderQuery } from '@crew/apis/folder/folderApis'
import { GetFolderRequest } from '@crew/apis/folder/models/getFolder/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useValueChangeEffect } from '@crew/hooks'

type FolderEntryDialogProps = {
  isEditMode: boolean
  isOpen: boolean
  onClose: () => void
  folderId: string | null
  entityType: EntityType
  entityRecordId: string
  title: string
}

export const FolderEntryDialog: FC<FolderEntryDialogProps> = memo((props) => {
  const { t } = useTranslation()
  const toast = useToast()

  const {
    control,
    validateRules,
    updateFolderName,
    createFolder,
    formState,
    handleSubmit,
    reset,
  } = useFolderEntryDialog()

  const [showApiErrors] = useShowApiErrors()

  const canSend = useMemo(
    // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    // check permission create task
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState]
  )

  const getFolderParams: GetFolderRequest | undefined =
    props.folderId && props.isEditMode
      ? {
          folderId: props.folderId,
        }
      : undefined
  const { data: getFolderResult } = useGetFolderQuery(
    getFolderParams ?? skipToken
  )

  // initialize form data
  useValueChangeEffect(
    () => {
      if (getFolderResult?.folder) {
        reset({
          folderName: getFolderResult.folder.name,
        })
      }
    },
    [getFolderResult?.folder, reset],
    getFolderResult?.folder
  )

  // handle submit form and call api register event
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (props.isEditMode) {
          // If the folder does not exist, return
          if (!getFolderResult?.folder) return

          // フォルダ名更新APIリクエスト
          await updateFolderName(
            getFolderResult.folder.id,
            data.folderName,
            getFolderResult.folder.version
          )

          toast.success(t('message.folder.folderUpdated'))

          // ダイアログを閉じる
          props.onClose()
        } else {
          // サブフォルダ作成APIリクエスト
          await createFolder(
            data.folderName,
            props.folderId,
            props.entityType,
            props.entityRecordId
          )

          toast.success(t('message.folder.folderRegistered'))

          // ダイアログを閉じる
          props.onClose()
        }
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    props,
    getFolderResult?.folder,
    updateFolderName,
    toast,
    t,
    createFolder,
    showApiErrors,
  ])

  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <div className="flex flex-col gap-2.5">
        <div className="grow">
          <CrewTextBoxField
            control={control}
            id="folderName"
            name="folderName"
            label={t('label.folderName')}
            required
            rules={validateRules.folderName}
          />
        </div>

        <div className="flex gap-2.5 justify-end">
          {/* 登録 */}
          <CrewButton
            text={t('action.register')}
            type="primary"
            onClick={handleSubmitButtonClick}
            disabled={!canSend}
          />

          {/* キャンセル */}
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={props.onClose}
          />
        </div>
      </div>
    </Modal>
  )
})
