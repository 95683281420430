import { useCallback } from 'react'
import { useDeleteProjectGroupMutation } from '@crew/apis/project/projectApis'
import { useAppDispatch } from 'states/hooks'
import {
  notifyProjectGroupEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { ProjectGroup } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'

export type SelectedProjectGroup = {
  projectGroupId: string
  version: number
}
export const useProjectGroupListGrid = () => {
  const dispatch = useAppDispatch()

  // Get function for delete project group
  const [deleteProjectGroupMutation] = useDeleteProjectGroupMutation()

  // Delete project group process
  const deleteProjectGroup = useCallback(
    async (projectGroupId: string, version: number) => {
      await deleteProjectGroupMutation({
        projectGroupId,
        version,
      })

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<ProjectGroup> = {
        eventType: NotifyEventType.Deleted,
        id: projectGroupId,
        object: undefined,
      }

      dispatch(notifyProjectGroupEvent(objectEventMessage))
    },
    [deleteProjectGroupMutation, dispatch]
  )

  return {
    deleteProjectGroup,
  }
}
