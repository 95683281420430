import { apis } from '../apiBase/apiBase';
const chatReactionApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        insertChatReaction: builder.mutation({
            query: (params) => ({
                url: `chat-reactions/insert`,
                method: `POST`,
                body: params,
            }),
        }),
        deleteChatReaction: builder.mutation({
            query: (params) => ({
                url: `chat-reactions/delete`,
                method: `POST`,
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useInsertChatReactionMutation, useDeleteChatReactionMutation } = chatReactionApis;
