import { useCallback } from 'react'
import {
  useStartRecordMutation,
  useEndRecordMutation,
} from '@crew/apis/meeting/meetingApis'
import { useAppDispatch } from 'states/hooks'
import { setMediaPipeline } from 'features/webMeeting/states/webMeetingSlice'

export const useEventDetailWebMeetingPanel = () => {
  const dispatch = useAppDispatch()

  // Get function for start/end record meeting
  const [startRecordMutation] = useStartRecordMutation()
  const [endRecordMutation] = useEndRecordMutation()

  // Start record meeting
  const startRecord = useCallback(
    async (meetingId: string) => {
      const result = await startRecordMutation({ meetingId }).unwrap()
      dispatch(setMediaPipeline(result.mediaPipeline))
      return result
    },
    [dispatch, startRecordMutation]
  )

  // End record meeting
  const endRecord = useCallback(
    async (
      meetingId: string,
      mediaPipelineId: string,
      mediaPipelineArn: string
    ) => {
      await endRecordMutation({
        meetingId,
        mediaPipelineId,
        mediaPipelineArn,
      })
      dispatch(setMediaPipeline(null))
    },
    [dispatch, endRecordMutation]
  )

  return {
    startRecord,
    endRecord,
  }
}
