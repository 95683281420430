import { useGetMyActualWorkTimesQuery } from '@crew/apis/task/taskApis'
import { JsonDateFormat } from '@crew/enums/system'
import dayjs from '@crew/modules'
import { convertMinutesToHHMM } from '@crew/utils'
import classNames from 'classnames'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { isWeekend } from 'utils/date'
import KeyboardArrowLeft from '~icons/material-symbols/keyboard-arrow-left'
import KeyboardArrowRight from '~icons/material-symbols/keyboard-arrow-right'
import { WorkingTimeEntryDialog } from '../workingTimeEntryDialog/workingTimeEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { useTranslation } from '@crew/modules/i18n'
import { WorkingTimeDay } from './components/workingTimeDay/workingTimeDay'
import { useAppSelector } from 'states/hooks'
import { useValueChangeEffect } from '@crew/hooks'
import { CrewButton } from 'components/elements/crewButton/crewButton'

export const WorkingTimeWeek: FC = memo(() => {
  const { t } = useTranslation()

  const [
    isWorkTimeEntryDialogOpen,
    openWorkTimeEntryDialog,
    closeWorkTimeEntryDialog,
  ] = useModal()

  const [startDateOfWeek, setStartDateOfWeek] = useState(
    dayjs().startOf('week').toDate()
  )
  const [endDateOfWeek, setEndDateOfWeek] = useState(
    dayjs().endOf('week').toDate()
  )

  // Event handler for when the previous date button is clicked
  const handlePrevWeekButtonClick = useCallback(() => {
    setStartDateOfWeek((prev) => {
      const next = dayjs(prev).subtract(1, 'week').toDate()
      return next
    })

    setEndDateOfWeek((prev) => {
      const next = dayjs(prev).subtract(1, 'week').toDate()
      return next
    })
  }, [])

  // Event handler for when the next date button is clicked
  const handleNextWeekButtonClick = useCallback(() => {
    setStartDateOfWeek((prev) => {
      const next = dayjs(prev).add(1, 'week').toDate()
      return next
    })

    setEndDateOfWeek((prev) => {
      const next = dayjs(prev).add(1, 'week').toDate()
      return next
    })
  }, [])

  // Create a list of days of the week
  const listDaysOfWeek = useMemo(() => {
    const daysOfWeek = []
    for (let i = 0; i < 7; i++) {
      daysOfWeek.push(dayjs(startDateOfWeek).add(i, 'day').toDate())
    }
    return daysOfWeek
  }, [startDateOfWeek])

  const taskWorkEventMessage = useAppSelector(
    (state) => state.app.taskWorkEventMessage
  )

  const {
    data: getMyActualWorkTimesResult,
    refetch: refetchMyActualWorkTimes,
  } = useGetMyActualWorkTimesQuery({
    startDate: dayjs(startDateOfWeek).format(JsonDateFormat.YYYYMMDD),
    endDate: dayjs(endDateOfWeek).format(JsonDateFormat.YYYYMMDD),
  })

  // Refetch worktimes when taskWorkEventMessage changes
  useValueChangeEffect(
    () => {
      refetchMyActualWorkTimes()
    },
    [refetchMyActualWorkTimes],
    taskWorkEventMessage
  )

  // Map the work time data to the day
  const mapDayToWorkTimes = useMemo(() => {
    const map = new Map<string, any[]>()
    listDaysOfWeek.forEach((day) => {
      map.set(dayjs(day).format(JsonDateFormat.YYYYMMDD), [])
    })

    // Group the work time data by day
    getMyActualWorkTimesResult?.workTimes.forEach((workTime) => {
      const workDate = dayjs(workTime.workDate).format(JsonDateFormat.YYYYMMDD)
      const workTimes = map.get(workDate)
      if (workTimes) {
        workTimes.push(workTime)
      }
    })

    return map
  }, [getMyActualWorkTimesResult?.workTimes, listDaysOfWeek])

  const totalWorkTimes = useMemo(() => {
    if (!getMyActualWorkTimesResult?.workTimes) return 0

    return getMyActualWorkTimesResult.workTimes.reduce((acc, cur) => {
      return acc + (cur.actualWorkTimes ?? 0)
    }, 0)
  }, [getMyActualWorkTimesResult?.workTimes])

  const [targetDate, setTargetDate] = useState<Date>()

  // Event handler for opening the work time entry dialog
  const handleOpenWorkTimeEntryDialog = useCallback(
    (workDate: string) => {
      setTargetDate(new Date(workDate))
      openWorkTimeEntryDialog()
    },
    [openWorkTimeEntryDialog]
  )

  return (
    <>
      {/* toolbar action */}
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2.5">
          {/* prev month button */}
          <CrewButton
            stylingMode="text"
            icon={<KeyboardArrowLeft width={24} height={24} />}
            size="xs"
            onClick={handlePrevWeekButtonClick}
          />

          {/* display week date */}
          <span>
            {t('format.dateWithText', {
              value: startDateOfWeek,
            })}
          </span>

          <span>-</span>

          <span>
            {t('format.dateWithText', {
              value: endDateOfWeek,
            })}
          </span>

          {/* next month button */}
          <CrewButton
            stylingMode="text"
            icon={<KeyboardArrowRight width={24} height={24} />}
            size="xs"
            onClick={handleNextWeekButtonClick}
          />
        </div>

        <p className="flex flex-row items-center gap-2">
          <span>{t('label.total')}</span>
          <span className="font-bold text-lg">
            {convertMinutesToHHMM(totalWorkTimes)}
          </span>
        </p>
      </div>

      {/* worktimes week */}
      <div className="flex-1 w-full">
        <table className="w-full h-full max-w-full">
          <thead>
            {listDaysOfWeek.map((day) => {
              return (
                <th
                  key={day.getTime()}
                  className={classNames(
                    // calculate the width of the column based on the number of days in the week
                    // all columns are the same width
                    'border border-gray-300 py-0.5 font-normal w-[calc(100%/7)] h-1',
                    isWeekend(day) && 'crew-bg-gray-1'
                  )}
                >
                  {t('format.dayOfWeekShort', {
                    value: day,
                  })}
                </th>
              )
            })}
          </thead>
          <tbody>
            <tr>
              {Array.from(mapDayToWorkTimes).map(([key, data]) => (
                <WorkingTimeDay
                  key={key}
                  workDate={key}
                  workingTimes={data}
                  onOpenWorkTimeEntryDialog={handleOpenWorkTimeEntryDialog}
                />
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      {targetDate && (
        <WorkingTimeEntryDialog
          title={t('label.registerWorkingTime')}
          isOpen={isWorkTimeEntryDialogOpen}
          onClose={closeWorkTimeEntryDialog}
          targetDate={targetDate}
        />
      )}
    </>
  )
})
