import { memo, useMemo } from 'react'
import { useTenantUserEntryDialog } from './useTenantUserEntryDialog'
import { Modal } from 'components/layouts/modal/modal'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { InviteNewUsersToolbar } from './component/inviteNewUsersToolbar/inviteNewUsersToolbar'
import { InviteNewUserListPanel } from './component/inviteNewUserListPanel/inviteNewUserListPanel'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useEffect, useState } from 'react'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import {
  InvitationRoleType,
  SettingKeyType,
  ContractPlan,
} from '@crew/enums/app'
import { NewUserType } from './useTenantUserEntryDialog'
import {
  useGetInviteableExternalUsersQuery,
  useGetInviteableUsersForFreePlanQuery,
} from '@crew/apis/user/userApis'
import { useAppSelector } from 'states/hooks'
import { MAX_NUMBER_OF_USERS_FOR_FREE_PLAN } from '@crew/configs/constants'

export type PropsMode = {
  title: string
  isOpen: boolean
  onClose: () => void
}

export const TenantUserEntryDialog = memo((props: PropsMode) => {
  const { tenantUserEntryDialogContext, insertUserPendings } =
    useTenantUserEntryDialog()

  const tenantSettingUserEventMessage = useAppSelector(
    (state) => state.app.tenantSettingUserEventMessage
  )

  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  const { t } = useTranslation()
  const [showApiErrors] = useShowApiErrors()
  const toast = useToast()

  // 招待可能な外部ユーザー数を取得
  const {
    data: getInviteableExternalUsersResult,
    refetch: refetchInviteableExternalUsersResult,
  } = useGetInviteableExternalUsersQuery(undefined, {
    // フリープランの場合は招待可能な外部ユーザー数の取得をskipする
    skip: currentPlan === ContractPlan.Free,
  })

  // 招待可能なユーザー数を取得
  const {
    data: getInviteableUsersResult,
    refetch: refetchInviteableUsersResult,
  } = useGetInviteableUsersForFreePlanQuery(undefined, {
    // フリープラン以外の場合は招待可能なユーザー数の取得をskipする
    skip: currentPlan !== ContractPlan.Free,
  })

  // refetch inviteable users/external users when tenant setting member updated
  useEffect(() => {
    if (currentPlan === ContractPlan.Free) {
      refetchInviteableUsersResult()
    } else {
      refetchInviteableExternalUsersResult()
    }
  }, [
    tenantSettingUserEventMessage,
    refetchInviteableExternalUsersResult,
    currentPlan,
    refetchInviteableUsersResult,
  ])

  // get remaining inviteable external users
  const remainingInviteableExternalUsers = useMemo(
    () =>
      getInviteableExternalUsersResult
        ? getInviteableExternalUsersResult.inviteableExternalUsers -
          getInviteableExternalUsersResult.currentExternalUsers
        : 0,
    [getInviteableExternalUsersResult]
  )

  const [organizationDomain, setOrganizationDomain] = useState<string | null>(
    null
  )
  const [selectedNewUsers, setSelectedNewUsers] = useState<NewUserType[]>([])

  //close dialog
  const handleCloseDialog = useCallback(() => {
    props.onClose()

    //clear context state
    setSelectedNewUsers([])
  }, [props])

  const getTenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [SettingKeyType.OrganizationDomain],
  }
  // get tenant setting data
  const { data: getTenantSettings } = useGetTenantSettingsQuery(
    getTenantSettingsRequestParams
  )

  useEffect(() => {
    //save organization domain to local state
    if (
      getTenantSettings?.tenantSettings[0].key ===
      SettingKeyType.OrganizationDomain
    ) {
      setOrganizationDomain(getTenantSettings.tenantSettings[0].value)
    }
  }, [getTenantSettings?.tenantSettings])

  //add button click invite user to tenant
  const handleInviteMemberButtonClick = useCallback(async () => {
    //invite new user to tenant
    try {
      //if selected new users is empty, return
      if (selectedNewUsers.length === 0) return

      if (currentPlan === ContractPlan.Free) {
        if (
          selectedNewUsers.length >
          (getInviteableUsersResult?.inviteableUsers ?? 0)
        ) {
          toast.error(
            t('message.apiError.tenantUsersExceedLimit', {
              remainingUser: getInviteableUsersResult?.inviteableUsers,
            })
          )
          return
        }
      } else {
        // count the number of selected new users is external users
        const externalUsersCount = selectedNewUsers.filter(
          (user) => user.invitationRoleType === InvitationRoleType.External.key
        ).length

        // if the number of selected new users is greater than the remaining inviteable external users, return
        if (externalUsersCount > remainingInviteableExternalUsers) {
          toast.error(
            t('message.apiError.inviteExternalUserLimitExceeded', {
              inviteableExternalUserCount:
                getInviteableExternalUsersResult?.inviteableExternalUsers,
              currentExternalUserCount:
                getInviteableExternalUsersResult?.currentExternalUsers,
              exceededExternalUserCount:
                externalUsersCount - remainingInviteableExternalUsers,
            })
          )
          return
        }
      }

      await insertUserPendings(selectedNewUsers)

      toast.success(t('message.tenant.inviteUserSuccess'))

      //clear context state
      setSelectedNewUsers([])

      //close dialog
      props.onClose()
    } catch (error) {
      console.log(error)
      showApiErrors(error)
    }
  }, [
    currentPlan,
    getInviteableExternalUsersResult?.currentExternalUsers,
    getInviteableExternalUsersResult?.inviteableExternalUsers,
    getInviteableUsersResult?.inviteableUsers,
    insertUserPendings,
    props,
    remainingInviteableExternalUsers,
    selectedNewUsers,
    showApiErrors,
    t,
    toast,
  ])

  return (
    <tenantUserEntryDialogContext.Provider
      value={{
        selectedNewUsers,
        setSelectedNewUsers,
        organizationDomain,
      }}
    >
      <Modal
        title={props.title}
        isOpen={props.isOpen}
        onClose={handleCloseDialog}
      >
        <div className="flex flex-col gap-y-3 h-full">
          <CrewScrollView>
            <p className="text-sm my-3">
              {t('label.inviteNewUsersDescription')}
            </p>

            <div className="flex flex-col gap-y-5">
              <CrewErrorBoundary>
                <InviteNewUsersToolbar />
              </CrewErrorBoundary>

              <CrewErrorBoundary>
                <InviteNewUserListPanel />
              </CrewErrorBoundary>
            </div>
          </CrewScrollView>

          {/* Dialog action */}
          <div className="flex  gap-x-3 mt-5 items-center">
            <p className="mr-auto">
              {currentPlan === ContractPlan.Free
                ? // フリープランの場合は招待可能なユーザー数を表示
                  t('label.hintForAllowInviteableUsers', {
                    remaining: getInviteableUsersResult?.inviteableUsers ?? 0,
                    total: MAX_NUMBER_OF_USERS_FOR_FREE_PLAN,
                  })
                : // フリープラン以外の場合は招待可能な外部ユーザー数を表示
                  t('label.hintForAllowInviteableExternalUsers', {
                    remaining: remainingInviteableExternalUsers,
                    total:
                      getInviteableExternalUsersResult?.inviteableExternalUsers ??
                      0,
                  })}
            </p>
            {/* ユーザーを招待ボタン */}
            <CrewButton
              text={t('action.inviteUser')}
              type="primary"
              onClick={handleInviteMemberButtonClick}
            />

            {/* キャンセルボタン */}
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={handleCloseDialog}
            />
          </div>
        </div>
      </Modal>
    </tenantUserEntryDialogContext.Provider>
  )
})
