import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { EventRecording } from '@crew/models/domain'
import { CrewMeetingArchiveItem } from './components/crewMeetingArchiveItem/crewMeetingArchiveItem'
import { CrewVideoPlayer } from '../crewVideoPlayer/crewVideoPlayer'
import classNames from 'classnames'
import KeyboardArrowDown from '~icons/material-symbols/keyboard-arrow-down'
import KeyboardArrowUp from '~icons/material-symbols/keyboard-arrow-up'
import { useLazyGetEventRecordingUrlQuery } from '@crew/apis/project/projectApis'

export type CrewMeetingArchiveProps = {
  archiveFiles: EventRecording[]
  showDeleteButton: boolean
  showDownloadButton: boolean
}

export const CrewMeetingArchive = memo((props: CrewMeetingArchiveProps) => {
  const [archiveFiles, setArchiveFiles] = useState(props.archiveFiles)

  // CrewVideoPlayer制御用のstate
  const [isOpen, setIsOpen] = useState(false)
  const [eventRecordingsShowAll, setEventRecordingsShowAll] =
    useState<boolean>(true)

  // Base style for collapsed animation of archive frame
  const archiveBaseClassNames = useMemo(() => {
    return 'transition-all duration-500 ease-linear'
  }, [])

  const [lazyGetEventRecordingUrlQuery, lazyGetEventRecordingUrlQueryResult] =
    useLazyGetEventRecordingUrlQuery()

  // ファイルのリンクを押したときに、再生したいファイルのUrlを取得する
  const handleLinkClick = useCallback(
    (recordingId: string) => {
      lazyGetEventRecordingUrlQuery({
        eventRecordingId: recordingId,
      })
      setIsOpen(true)
    },
    [lazyGetEventRecordingUrlQuery]
  )

  // CrewVideoPlayerを閉じる
  const handleLightBoxClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  // 他ユーザによるアーカイブファイル削除でwebsocket経由でファイル情報が更新されたらアーカイブファイル表示に反映
  // TODO: useCrewMeetingArchiveItem内でnotifyEventRecordingEventを実行しているので、ここでlistenするのはeventRecordingEventsMessage?
  // https://break-tmc.atlassian.net/browse/CREW-7671
  useEffect(() => {
    setArchiveFiles(props.archiveFiles)
  }, [props.archiveFiles])

  // ファイル削除後に「archive files」から該当項目を削除して表示上から対象ファイル表記を消す
  const handleDeleteArchiveFilesItem = useCallback((meetingId: string) => {
    if (meetingId) {
      // 「archive files」に格納しているファイル情報を削除する
      setArchiveFiles((baseData) =>
        baseData.filter((item) => item.meetingId !== meetingId)
      )
    }
  }, [])
  // Handle button collapse or show all recording list
  const handleShowAllRecordingsButtonClick = useCallback(() => {
    setEventRecordingsShowAll(!eventRecordingsShowAll)
  }, [eventRecordingsShowAll])

  return archiveFiles.length > 0 ? (
    <div className="inline-flex flex-row gap-1 relative">
      {/* 録画ファイル一覧 */}
      <div
        className={classNames(
          archiveBaseClassNames,
          !eventRecordingsShowAll ? 'max-h-6 overflow-hidden' : null
        )}
      >
        {archiveFiles.map((archiveFile) => (
          <CrewMeetingArchiveItem
            key={archiveFile.meetingId}
            eventRecordingId={archiveFile.id}
            eventId={archiveFile.eventId}
            meetingId={archiveFile.meetingId}
            startDatetime={archiveFile.startDatetime}
            endDatetime={archiveFile.endDatetime}
            showDeleteButton={props.showDeleteButton}
            showDownloadButton={props.showDownloadButton}
            onDeleteArchiveFileItem={handleDeleteArchiveFilesItem}
            onLinkClick={() => {
              handleLinkClick(archiveFile.id)
            }}
          />
        ))}

        {lazyGetEventRecordingUrlQueryResult.data?.url && (
          <CrewVideoPlayer
            streamingUrl={lazyGetEventRecordingUrlQueryResult.data.url}
            isOpen={isOpen}
            showDownloadButton={props.showDownloadButton}
            onClose={handleLightBoxClose}
          />
        )}
      </div>
      {/* 録画ファイル一覧の折り畳み表示ボタン */}
      <div
        onClick={handleShowAllRecordingsButtonClick}
        className="cursor-pointer"
      >
        {eventRecordingsShowAll ? (
          <KeyboardArrowUp width={24} height={24} />
        ) : (
          <KeyboardArrowDown width={24} height={24} />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
})
