import { FC, memo, useMemo } from 'react'
import classNames from 'classnames'

type Props = {
  displayColor: string
  className?: string
  children?: React.ReactNode
}

// バッジコンポーネント
// text-smなどでバッジの全体の大きさを調整する、横幅はmin-w-{XXX}で調整する
export const CrewBadge: FC<Props> = memo((props) => {
  const badgeBaseClassNames = useMemo(() => {
    // 最低幅として、5文字分確保できるようにする
    return 'crew-badge text-sm truncate justify-center min-w-[5rem]'
  }, [])

  return (
    <span
      className={classNames(
        badgeBaseClassNames,
        props.className,
        props.displayColor
      )}
    >
      {props.children}
    </span>
  )
})
