import { useMemo } from 'react'

const useIsPWA = () => {
  // PWAとして実行されているか確認
  const isPWA = useMemo(
    () =>
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone === true,
    []
  )

  return isPWA
}

export default useIsPWA
