import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@crew/modules/i18n'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { RoleType, UserType } from '@crew/enums/domain'
import { useCallback, useMemo } from 'react'
import {
  useInsertProjectMembersMutation,
  useLazyGetNonProjectMembersQuery,
  useUpdateProjectMemberMutation,
} from '@crew/apis/project/projectApis'
import { useAppDispatch } from 'states/hooks'
import { ValidateRules } from '@crew/utils/form'
import { useRoleDataSource } from 'hooks/dataSource/useRoleDataSource'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyProjectEvent,
  notifyProjectSettingMemberEvent,
} from 'features/app/states/appSlice'
import { Project, ProjectMember } from '@crew/models/domain'
import { useParams } from 'react-router-dom'
import { Roles } from '@crew/enums/app'

export type FormValues = {
  roleId: null | string
  userIds: string[]
  userId: string
}
const formInitialValues: FormValues = {
  roleId: null,
  userIds: [],
  userId: '',
}

export const useProjectDetailMemberEntryForm = (
  userType: string | undefined
) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { projectId } = useParams()

  const [lazyGetNonProjectMembersQuery] = useLazyGetNonProjectMembersQuery()
  const [insertProjectMembersMutation] = useInsertProjectMembersMutation()
  const [updateProjectMemberMutation] = useUpdateProjectMemberMutation()

  // react-hook-formの各種データを取得
  const { control, reset, clearErrors, setError, formState, handleSubmit } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Update project member process
  const updateProjectMember = useCallback(
    async (projectId: string, roleId: string, userId: string) => {
      await updateProjectMemberMutation({
        projectMember: {
          projectId,
          roleId,
          userId,
        },
      }).unwrap()

      //trigger event refresh project setting members list
      const objectEventMessage: ObjectEventMessage<ProjectMember> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: {
          projectId,
          roleId,
          userId,
        } as ProjectMember,
      }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessage))
    },
    [dispatch, updateProjectMemberMutation]
  )

  // Insert project member process
  const insertProjectMember = useCallback(
    async (projectId: string, userIds: string[], roleId: string) => {
      const users = userIds.map((userId) => ({
        userId,
        roleId: roleId as string,
      }))

      await insertProjectMembersMutation({
        projectMembers: {
          projectId,
          users,
        },
      }).unwrap()

      //trigger event refresh project setting members list
      const objectEventMessageProjectMember: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Inserted,
          id: users[0].userId,
          object: users[0] as ProjectMember,
        }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessageProjectMember))

      //trigger project detail refresh members list
      const objectEventMessageProject: ObjectEventMessage<Project> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: {
          id: projectId,
        },
      }
      dispatch(notifyProjectEvent(objectEventMessageProject))
    },
    [dispatch, insertProjectMembersMutation]
  )

  //set data source for user dropdown
  const userDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if ((!loadOptions.searchValue && !loadOptions.filter) || !projectId) {
          return []
        }

        if (loadOptions.searchValue) {
          // インクリメンタルサーチ
          const response = await lazyGetNonProjectMembersQuery({
            projectId,
            displayName: loadOptions.searchValue,
            userId: undefined,
          }).unwrap()

          return response.users
        } else if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したユーザIDのフィルタリング
          const filteringUserIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetNonProjectMembersQuery({
            projectId,
            displayName: undefined,
            userId: filteringUserIds,
          }).unwrap()

          return response.users
        }

        return []
      },
    }),
    [lazyGetNonProjectMembersQuery, projectId]
  )

  // list role code need exclude in role dropdown
  const excludeRoles = useMemo(() => {
    // exclude role prj_admin and prj_member when selected user is external user
    if (userType === UserType.External) {
      return [Roles.PrjAdmin, Roles.PrjMember]
    }

    return []
  }, [userType])

  //set data source for role dropdown
  const roleDataSource = useRoleDataSource(
    RoleType.Project,
    excludeRoles,
    // If you are an external user, you can only have the `prj_guest` role in the project.
    // So if you are an external user, don't get user-defined roles here.
    !(userType === UserType.External)
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      userIds: {
        required: t('message.general.required'),
      },
      roleId: {
        required: t('message.general.required'),
      },
      // not validate below
      userId: {},
    }),
    [t]
  )

  return {
    control,
    reset,
    clearErrors,
    setError,
    formState,
    handleSubmit,

    userDataSource,
    roleDataSource,

    validateRules,

    updateProjectMember,
    insertProjectMember,
  }
}
