import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FC, memo, useCallback, useMemo } from 'react'
import { FileEntryDialog } from 'features/file/components/fileEntryDialog/fileEntryDialog'
import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { getDefaultTabValue } from '@crew/utils/enum'
import { FileDetailListTabs, FileListDisplayMode } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { FolderEntryDialog } from 'components/elements/crewFolderList/components/folderEntryDialog/folderEntryDialog'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { setSelectedFileListDisplayMode } from 'features/project/components/projectDetailPage/states/projectDetailSlice'
import classNames from 'classnames'
import { DirectMessageFileSearch } from './components/directMessageFileSearch/directMessageFileSearch'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'
import SharpSearch from '~icons/ic/sharp-search'

type ButtonGroupInfo = {
  index: number
  text: string | undefined
  icon: JSX.Element | undefined
}

type DirectMessageFileListToolbarProps = {
  onSearchConditionButtonClick: () => void
  isShowSearchCondition: boolean
}

export const DirectMessageFileListToolbar: FC<DirectMessageFileListToolbarProps> =
  memo((props) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { projectId } = useParams()
    const { navigate } = useCrewNavigate()
    const { hasPrjFileCreatePermission } = useProjectPermissions(
      EntityType.Project,
      projectId
    )

    // 選択中の表示形式
    const selectedFileListDisplayMode = useAppSelector(
      (state) => state.projectDetail.selectedFileListDisplayMode
    )

    /** ダイアログ */
    // ファイル登録ダイアログ
    const [isOpenFileEntryDialog, openFileEntryDialog, closeFileEntryDialog] =
      useModal()

    const [
      isFolderEntryDialogOpen,
      openFolderEntryDialog,
      closeFolderEntryDialog,
    ] = useModal()

    // 表示形式のボタングループ
    const fileDisplayModeButtonGroup: ButtonGroupInfo[] = useMemo(() => {
      const items = Object.keys(FileListDisplayMode).map((key) => {
        const item =
          FileListDisplayMode[key as keyof typeof FileListDisplayMode]

        const SvgIcon = item.icon
        return {
          index: item.id,
          text: undefined,
          icon: <SvgIcon width={24} height={24} />,
        }
      })

      return items
    }, [])

    // ファイル登録後はファイル詳細画面に遷移
    const handleRegisteredFile = useCallback(
      (fileId: string) => {
        closeFileEntryDialog()

        navigate(`/files/${fileId}/${getDefaultTabValue(FileDetailListTabs)}`)
      },
      [closeFileEntryDialog, navigate]
    )

    // 表示形式の切替ボタンクリック時
    const handleFileDisplayModeItemClick = useCallback(
      (itemData: ButtonGroupInfo) => {
        // 表示形式を更新
        dispatch(setSelectedFileListDisplayMode(itemData.index))
      },
      [dispatch]
    )

    return (
      <div className="p-2.5 flex flex-col gap-1">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center gap-1">
            {/* 表示切替ボタングループ */}
            <CrewButtonGroup
              items={fileDisplayModeButtonGroup}
              keyExpr="index"
              textExpr="text"
              iconExpr="icon"
              stylingMode="text"
              selectedItemKey={selectedFileListDisplayMode}
              onItemClick={handleFileDisplayModeItemClick}
            />

            {/* 詳細検索 */}
            <CrewButton
              type="normal"
              text={t('label.searchCondition')}
              icon={<SharpSearch width={24} height={24} />}
              className={classNames(
                props.isShowSearchCondition && 'crew-bg-gray-2'
              )}
              stylingMode="text"
              onClick={props.onSearchConditionButtonClick}
            />
          </div>

          {hasPrjFileCreatePermission && (
            <div className="pb-2.5 flex flex-row gap-1.5 justify-end">
              {/* 新規フォルダ */}
              <CrewButton
                type="normal"
                stylingMode="outlined"
                text={t('action.newFolder')}
                onClick={openFolderEntryDialog}
              />

              {/* 新規ファイルボタン */}
              <CrewButton
                type="primary"
                text={t('action.newFile')}
                onClick={openFileEntryDialog}
              />
            </div>
          )}
        </div>

        {/* 検索条件 */}
        {props.isShowSearchCondition && <DirectMessageFileSearch />}

        {/* ファイル登録ダイアログ */}
        <FileEntryDialog
          isEditMode={false}
          title={t('label.registerNewFile')}
          isOpen={isOpenFileEntryDialog}
          onClose={closeFileEntryDialog}
          onRegistered={handleRegisteredFile}
          entityRecordId={projectId}
          entityType={EntityType.Project}
        />

        {/* 新規フォルダーの登録 */}
        <FolderEntryDialog
          isOpen={isFolderEntryDialogOpen}
          isEditMode={false}
          title={t('label.registerFolder')}
          onClose={closeFolderEntryDialog}
          folderId={null}
          entityType={EntityType.Project}
          entityRecordId={projectId ?? ''}
        />
      </div>
    )
  })
