import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectListPanelDisplayMode } from 'enums/app'

type ProjectListPageStateType = {
  displayMode: number
}

const initialProjectListPageState: ProjectListPageStateType = {
  displayMode: ProjectListPanelDisplayMode.List.id,
}

export const projectListPageSlice = createSlice({
  name: 'projectListPage',
  initialState: initialProjectListPageState,
  reducers: {
    displayModeUpdated: (state, action: PayloadAction<number>) => {
      state.displayMode = action.payload
    },
  },
})

export const { displayModeUpdated } = projectListPageSlice.actions
