/**
 * 引数に渡したenumオブジェクトのうち、「value」が一致する要素を返す関数
 * @param targetEnumObject 検索対象のenumオブジェクト
 * @param searchValue enumのvalueフィールドに対して検索をかける文字列
 * @returns
 */
export const getEnumDataByValue = (targetEnumObject, searchValue) => {
    return Object.values(targetEnumObject).find((data) => {
        return data.value === searchValue;
    });
};
/**
 * 引数に渡したenumオブジェクトのうち、「isDefault」がtrueの要素を返す関数
 * @param targetEnumObject 検索対象のenumオブジェクト
 * @returns
 */
export const getDefaultEnumData = (targetEnumObject) => {
    return Object.values(targetEnumObject).find((data) => {
        return data.isDefault;
    });
};
/**
 * 引数に渡したタブのenumオブジェクトのうち、デフォルトタブのvalueを返す関数
 * @param targetEnumObject デフォルトタブ取得対象のenumオブジェクト
 * @returns
 */
export const getDefaultTabValue = (targetEnumObject) => {
    // デフォルトタブを取得
    const defaultEnum = getDefaultEnumData(targetEnumObject);
    // デフォルトタブのenumを取得できたらURLに使用しているvalueを返す
    if (defaultEnum) {
        return defaultEnum.value;
    }
    // 通常あり得ないが、万が一defaultがない場合は空文字を返す
    return '';
};
