import { FC, memo, useCallback } from 'react'

import { CrewChatMessageEditor } from 'components/elements/crewChatMessageEditor/crewChatMessageEditor'

import { ChatThreadHeader } from './components/chatThreadHeader/chatThreadHeader'
import { useProjectPermissions } from '@crew/hooks'
import { useChatThread } from './useChatThread'
import { ChatThreadMessageList } from './components/chatThreadMessageList/chatThreadMessageList'

export type ChatThreadProps = {
  showBackButton?: boolean // ヘッダーに戻るボタンを表示するか。デフォルトはfalse
}

/**
 * チャットルームをスレッド形式で表示するコンポーネント
 */
// TODO: ChatInThreadをChatThreadにリネームする対応は他と衝突する可能性が高いので以下タスクで対応する
// https://break-tmc.atlassian.net/browse/CREW-11549
export const ChatThread: FC<ChatThreadProps> = memo((props) => {
  const { currentChatThread, resetInThread } = useChatThread()

  const { hasPrjFileCreatePermission } = useProjectPermissions(
    currentChatThread.entityType,
    currentChatThread.entityRecordId
  )

  /**
   * 戻るボタンクリックイベントハンドラ
   */
  const handleBackButtonClick = useCallback(() => {
    // Thread表示を解除するためトピックIDをリセットする
    resetInThread()
  }, [resetInThread])

  return (
    <div className="flex flex-col w-full h-full gap-1 overflow-hidden">
      {/* ヘッダ（戻るボタンや関連先リンクを表示） */}
      <div>
        <ChatThreadHeader
          showBackButton={props.showBackButton ?? false}
          onBackButtonClick={handleBackButtonClick}
        />
      </div>

      {/* メッセージリスト */}
      {/* TODO: 後ほどkey指定を外すが、これを現時点で外すとチャットルームやスレッドを切り替えた際にうまく処理が走らない */}
      <ChatThreadMessageList key={currentChatThread.topicId} />

      {/* チャット投稿コンポーネント(表示しているトピックへの返信として投稿される) */}
      <CrewChatMessageEditor
        isEditMode={false}
        chatRoomId={currentChatThread.chatRoomId}
        threadRootMessageId={currentChatThread.topicId}
        entityType={currentChatThread.entityType}
        entityRecordId={currentChatThread.entityRecordId}
        canUploadFile={hasPrjFileCreatePermission}
      />
    </div>
  )
})
