import { FC, memo, useState } from 'react'

import { useLocalVideo } from 'modules/amazon-chime-sdk-component-library-devextreme'

import { CrewSwitch } from 'components/devextreme/crewSwitch'

import { useAsyncStateConnector } from './hooks/useAsyncStateConnector'
import { useValueChangeEffect } from '@crew/hooks'
import { useBackgroundEffect } from './backgroundEffectProvider'

/**
 * カメラの有効・無効を切り替えるスイッチ
 */
export const CrewCameraSwitch: FC = memo(() => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo()

  const { value, handleValueChanged, isChangingAsyncState } =
    useAsyncStateConnector(isVideoEnabled, toggleVideo)

  // TODO: 背景の初期値をONにしているが、カメラをONにしないと背景エフェクトが有効にできない。そのため、カメラの初回ON時に背景エフェクトを有効にする暫定対応をう。
  //       恒久対応についてはCREW-13320で調査する
  //       https://break-tmc.atlassian.net/browse/CREW-13320
  const {
    isBackgroundEffectEnabled,
    isNowBackgroundEffectEnabled,
    enableBackgroundEffect,
  } = useBackgroundEffect()

  const [isCameraSwitchFirstChanged, setIsCameraSwitchFirstChanged] =
    useState(false)

  // カメラが有効になった初回時に背景エフェクトを有効にする
  useValueChangeEffect(
    () => {
      // カメラ初回ON時且つ、カメラのデバイスが認識済み、背景がON、背景エフェクトが有効じゃない場合に背景エフェクトを有効にする
      if (
        !isCameraSwitchFirstChanged &&
        isVideoEnabled &&
        isBackgroundEffectEnabled &&
        !isNowBackgroundEffectEnabled
      ) {
        enableBackgroundEffect(true)
        setIsCameraSwitchFirstChanged(true)
      }
    },
    [
      enableBackgroundEffect,
      isBackgroundEffectEnabled,
      isCameraSwitchFirstChanged,
      isNowBackgroundEffectEnabled,
      isVideoEnabled,
    ],
    isVideoEnabled
  )
  // --- 暫定対応ここまで

  return (
    <CrewSwitch
      disabled={isChangingAsyncState} // 切り替え中は変更不可とする
      defaultValue={value}
      onValueChanged={handleValueChanged}
    />
  )
})
