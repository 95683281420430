import { useDeleteTaskMutation } from '@crew/apis/task/taskApis'
import { EntityType } from '@crew/enums/domain'
import { useLookupMemberDataSource } from 'hooks/lookupDataSource/useLookupMemberDataSource'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

export const useProjectDetailTaskListWBSEdit = () => {
  const { projectId } = useParams()

  const [deleteTaskMutation] = useDeleteTaskMutation()

  // 担当者DataSource構築（インクリメンタルサーチ）
  const memberDataSource = useLookupMemberDataSource(
    EntityType.Project,
    projectId,
    true
  )

  // Function to delete a task
  const deleteTask = useCallback(
    async (taskId: string, version: number) => {
      await deleteTaskMutation({
        taskId,
        version,
      }).unwrap()
    },
    [deleteTaskMutation]
  )

  return { memberDataSource, deleteTask }
}
