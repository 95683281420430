import { useUpdateFolderMutation } from '@crew/apis/file/fileApis'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyFileEvent,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'

export const useFolderItem = () => {
  const dispatch = useAppDispatch()

  const [updateFolderMutation] = useUpdateFolderMutation()

  const moveFileToFolder = useCallback(
    async (folderId: string, fileId: string, version: number) => {
      const result = await updateFolderMutation({
        folderId,
        files: [
          {
            fileId,
            version,
          },
        ],
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<File> = {
        eventType: NotifyEventType.Inserted,
        id: fileId,
        object: undefined,
      }

      //Refresh file list
      dispatch(notifyFileEvent(objectEventMessage))

      return result
    },
    [dispatch, updateFolderMutation]
  )

  return { moveFileToFolder }
}
