import { LexicalEditor } from 'lexical'
import { DropDownItem, ToolbarDropDown } from './toolbarDropDown'

import useModal from '../hooks/useModal'
import { InsertTableDialog } from '../plugins/tablePlugin'
import { useTranslation } from '@crew/modules/i18n'
import Add from '~icons/material-symbols/add'
import Table from '~icons/mdi/table'

export function ToolbarInsertSpecialEditorNodeDropdown({
  disabled = false,
  editor,
}: {
  disabled: boolean
  editor: LexicalEditor
}) {
  const { t } = useTranslation()
  const [modal, showModal] = useModal()
  return (
    <>
      <ToolbarDropDown
        disabled={disabled}
        icon={<Add width={24} height={24} />}
        buttonAriaLabel="Insert specialized editor node"
        showDropDownIcon={true}
      >
        <DropDownItem
          onClick={() => {
            showModal(t('action.lexical.insertTable'), (onClose) => (
              <InsertTableDialog activeEditor={editor} onClose={onClose} />
            ))
          }}
          className="item"
        >
          <div className="flex justify-center items-center gap-x-1">
            <Table width={24} height={24} />
            <span className="text">{t('action.lexical.table')}</span>
          </div>
        </DropDownItem>
      </ToolbarDropDown>
      {modal}
    </>
  )
}
