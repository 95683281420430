import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type BadgeCountType = {
  chatMessageCount: number
  projectCount: number
  taskCount: number
  fileCount: number
}

type SearchPageStateType = {
  badgeCount: BadgeCountType
}

const initialSearchPageState: SearchPageStateType = {
  badgeCount: {
    chatMessageCount: 0,
    projectCount: 0,
    taskCount: 0,
    fileCount: 0,
  }
}

export const searchPageSlice = createSlice({
  name: 'searchPage',
  initialState: initialSearchPageState,
  reducers: {
    chatMessageCountUpdated: (state, action: PayloadAction<number>) => {
      state.badgeCount.chatMessageCount = action.payload
    },
    projectCountUpdated: (state, action: PayloadAction<number>) => {
      state.badgeCount.projectCount = action.payload
    },
    taskCountUpdated: (state, action: PayloadAction<number>) => {
      state.badgeCount.taskCount = action.payload
    },
    fileCountUpdated: (state, action: PayloadAction<number>) => {
      state.badgeCount.fileCount = action.payload
    },
  },
})

export const {
  chatMessageCountUpdated,
  projectCountUpdated,
  taskCountUpdated,
  fileCountUpdated,
} = searchPageSlice.actions
