import { memo } from 'react'
import { UserListToolbar } from './components/userListToolbar/userListToolbar'
import { UserListGrid } from './components/userListGrid/userListGrid'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { useUserList } from './useUserList'
import { useState } from 'react'
import { SelectedUserType } from './useUserList'

export const UserList = memo(() => {
  const { tenantUserListContext } = useUserList()
  const [selectedUsers, setSelectedUsers] = useState<SelectedUserType[]>([])

  return (
    <tenantUserListContext.Provider
      value={{
        selectedUsers,
        setSelectedUsers,
      }}
    >
      <div className="flex flex-col gap-2">
        <CrewErrorBoundary>
          <UserListToolbar />
        </CrewErrorBoundary>

        <CrewErrorBoundary>
          <UserListGrid />
        </CrewErrorBoundary>
      </div>
    </tenantUserListContext.Provider>
  )
})
