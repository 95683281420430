import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { FileListToolbar } from 'features/file/components/fileListPage/components/fileListToolbar/fileListToolbar'
import { FC, memo } from 'react'
import { FileTable } from './components/fileTable/fileTable'

export const FileListPage: FC = memo(() => {
  return (
    <div className="min-h-0 flex-1 flex flex-col grow w-full">
      <div className="min-h-0 flex flex-col gap-4 p-2">
        <CrewErrorBoundary>
          <FileListToolbar />
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <FileTable />
        </CrewErrorBoundary>
      </div>
    </div>
  )
})
