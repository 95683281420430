import { ImageExtensionsImageHtmlSupported, VideoExtensionsVideoHtmlSupported, } from '@crew/enums/app';
import { cloneDeep } from 'lodash';
import dayjs from '@crew/modules';
import { dateComparer } from './index';
// 返信ボタンの表示タイプ
export const ShowReplyButtonType = {
    // 表示しない
    None: 'none',
    // 「○件の返信」の形式で表示する
    ShowWithCount: 'showWithCount',
    // 「返信」のみ表示する
    ShowWithoutCount: 'showWithoutCount',
};
// リアクションの表示タイプ
export const ShowReactionType = {
    // 何も表示しない
    None: 'none',
    // リアクションの表示のみ
    ShowOnly: 'showOnly',
    // リアクションの表示とボタンの表示
    ShowAndButton: 'showAndButton',
};
/**
 * 引数のチャットメッセージのリアクションからリアクションの集計を行う関数
 * @param chatMessage リアクションの集計を行う対象のチャットメッセージ
 * @param loggedInUserId ログインユーザのID
 * @returns
 */
export const getChatMessageReactionItems = (chatMessage, loggedInUser) => {
    // ユーザのリアクション毎の配列で渡されてくるので、リアクション別にカウントを集計するためインデックスシグネチャの配列に再加工
    // 例）reactions: [
    //      { reaction: 1F44D, createdById: u0001, ... },
    //      { reaction: 1F981, createdById: u0001, ... },
    //      { reaction: 1F981, createdById: u0002, ... },
    //     ]
    //                        ↓↓↓
    //     {
    //      1F44D: { reactionCount: 1, ... },
    //      1F981: { reactionCount: 2, ... },
    //     }
    //     上記の配列を元にメッセージのバッジを出力
    let reactionItems = {};
    let reactions = cloneDeep(chatMessage.reactions);
    reactions.sort((a, b) => {
        // リアクションが登録順に表示される
        return dateComparer(dayjs(a.createdAt), dayjs(b.createdAt), 'asc');
    });
    reactions.forEach((reaction) => {
        // 絵文字名をkeyとする（crewReactionBadgeのshortNameに入る値）
        const key = reaction.reaction;
        // ログインユーザのリアクション有無
        const isReactedLoggedInUser = (loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.id) === reaction.createdById;
        if (reactionItems[key]) {
            // reactionItemsにリアクションがすでに追加されている場合はカウントを加算
            reactionItems[key].reactionCount++;
            // ログインユーザがリアクションしていた場合はリアクション有とする
            // （一度リアクション有となったら以降はフラグを変更する必要がないのでスルー）
            if (!reactionItems[key].reacted && isReactedLoggedInUser) {
                reactionItems[key].reacted = isReactedLoggedInUser;
            }
            reaction.user.id && reactionItems[key].members.push(reaction.user);
        }
        else {
            // reactionItemsにリアクションがまだ追加されてなければカウント1で追加
            reactionItems[key] = {
                chatMessageId: reaction.chatMessageId,
                reactionCount: 1,
                reacted: isReactedLoggedInUser,
                members: [reaction.user],
            };
        }
    });
    return reactionItems;
};
// Check image file valid to preview
export const isImageFile = (fileName) => {
    console.log(fileName);
    const fileExtension = fileName.split('.').pop() || '';
    return (Object.values(ImageExtensionsImageHtmlSupported).findIndex((item) => item === fileExtension.toLocaleLowerCase()) >= 0);
};
// Check image file valid to preview
export const isVideoFile = (fileName) => {
    const fileExtension = fileName.split('.').pop() || '';
    return (Object.values(VideoExtensionsVideoHtmlSupported).findIndex((item) => item === fileExtension.toLocaleLowerCase()) >= 0);
};
/**
 * 引数のチャットメッセージから添付画像ファイルを抽出する関数
 * @param chatMessage 添付画像ファイルを抽出する対象のチャットメッセージ
 * @returns 添付画像ファイルの配列
 */
export const getChatMessageImageFile = (chatMessage) => {
    return chatMessage.fileHistories.filter((item) => {
        if (isImageFile(item.name) || isVideoFile(item.name)) {
            return true;
        }
        return false;
    });
};
/**
 * 引数のチャットメッセージから画像以外の添付ファイルを抽出する関数
 * @param chatMessage 画像以外の添付ファイルを抽出する対象のチャットメッセージ
 * @returns 添付ファイルの配列
 */
export const getChatMessageFileWithoutImageFile = (chatMessage) => {
    return chatMessage.fileHistories.filter((item) => {
        if (!isImageFile(item.name) && !isVideoFile(item.name)) {
            return true;
        }
        return false;
    });
};
// Function to escape special characters in the keyword
export const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};
