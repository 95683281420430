import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewFollowerUs } from 'components/elements/crewFollowerUs/crewFollowerUs'
import { CrewGroupAvatar } from 'components/elements/crewGroupAvatar/crewGroupAvatar'
import { CrewProgressBar } from 'components/elements/crewProgressBar/crewProgressBar'
import { LoadIndicator } from 'devextreme-react'
import { useProjectDetailHeadPanel } from 'features/project/components/projectDetailPage/components/projectDetailHeadPanel/useProjectDetailHeadPanel'
import { ProjectEntryDialog } from 'features/project/components/projectEntryDialog/projectEntryDialog'
import {
  FC,
  Fragment,
  RefCallback,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from '@crew/modules/i18n'
import classNames from 'classnames'
import { ProjectScope, ProjectSettingTabs } from 'enums/app'
import { EntityType, PendingState, ProjectType } from '@crew/enums/domain'
import {
  CrewAvatarShapeType,
  CrewAvatar,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { CrewFavorite } from 'components/elements/crewFavorite/crewFavorite'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { ProjectMemberEntryDialog } from 'features/project/components/projectMemberEntryDialog/projectMemberEntryDialog'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { getDefaultTabValue } from '@crew/utils/enum'
import { Link, useParams } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useProjectPermissions } from '@crew/hooks'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useToast } from 'hooks/useToast'
import { useModal } from 'components/layouts/modal/useModal'
import {
  useGetMyProjectMemberPendingByProjectQuery,
  useGetProjectProgressQuery,
} from '@crew/apis/project/projectApis'
import { GetMyProjectMemberPendingByProjectRequest } from '@crew/apis/project/models/getMyProjectMemberPendingByProject/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { updateIsExpandedDescription } from 'features/project/components/projectDetailPage/states/projectDetailSlice'
import { GetProjectProgressRequest } from '@crew/apis/project/models/getProjectProgress/request'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { DISPLAY_LIMIT } from 'components/elements/crewGroupAvatar/crewGroupAvatar'
import MoreHoriz from '~icons/material-symbols/more-horiz'
import { ProjectGroupRef, UserRef } from '@crew/models/refs'
import { Favorite, Follow } from '@crew/models/domain'

type Project = {
  id: string
  subject: string
  description: string | null
  scope: string
  approvalToJoin: boolean
  ownerUser: UserRef
  projectGroup: ProjectGroupRef
  projectType: ProjectType
  projectMembers: UserRef[]
  projectFollow: Follow | null
  projectFavorite: Favorite | null
  archived: boolean
  version: number
}

export type ProjectDetailHeadPanelProps = {
  projectDetail: Project | null | undefined
  isLoadingProjectDetail: boolean
  isErrorProjectDetail: boolean
  isJoinedProject: boolean
  onProjectDeleted: () => void
}

// Set the height to display the project's description on 2 lines
const MAX_DESCRIPTION_HEIGHT = 40
const MIN_PANEL_WIDTH_SHOW_FULL_AVATAR = 500
export const ProjectDetailHeadPanel: FC<ProjectDetailHeadPanelProps> = memo(
  (props) => {
    const {
      joinProject,
      leaveProject,
      isApprovalPending,
      setIsApprovalPending,
    } = useProjectDetailHeadPanel()

    const { projectId } = useParams()

    const { t } = useTranslation()
    const { error, success } = useToast()
    const [showApiErrors] = useShowApiErrors()
    const { navigate } = useCrewNavigate()
    const dispatch = useAppDispatch()

    const [isLongDescription, setIsLongDescription] = useState(false)

    // 確認ダイアログメッセージ
    const [confirmMessage, setConfirmMessage] = useState('')
    const [displayAvatarLimit, setDisplayAvatarLimit] = useState(DISPLAY_LIMIT)

    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()

    // プロジェクト登録ダイアログ
    const [
      isProjectEntryDialogOpen,
      openProjectEntryDialog,
      closeProjectEntryDialog,
    ] = useModal()

    // プロジェクトメンバー追加ダイアログ
    const [
      isProjectMemberEntryDialogOpen,
      openProjectMemberEntryDialog,
      closeProjectMemberEntryDialog,
    ] = useModal()

    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
    const isExpandedDescription = useAppSelector(
      (state) => state.projectDetail.isExpandedDescription
    )

    // 進捗バーの情報を取得する
    // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
    const getProjectProgressParam: GetProjectProgressRequest | undefined =
      projectId
        ? {
            projectId,
          }
        : undefined
    const {
      data: getProjectProgressResult,
      refetch: getProjectProgressRefetch,
    } = useGetProjectProgressQuery(getProjectProgressParam ?? skipToken)

    const taskEventMessage = useAppSelector(
      (state) => state.app.taskEventMessage
    )

    // Project progress update when submit add/edit project task (send projectTaskEventMessage)
    // Get project progress
    const projectProgress = getProjectProgressResult?.projectProgress

    // Call API get Progress State and  Project Detail
    useEffect(() => {
      if (projectId) {
        getProjectProgressRefetch()
      }
    }, [projectId, getProjectProgressRefetch, taskEventMessage])

    // プロジェクトメンバー保留情報を取得する
    const getMyProjectMemberPendingByProjectParam:
      | GetMyProjectMemberPendingByProjectRequest
      | undefined = projectId
      ? {
          projectId,
        }
      : undefined

    const { data: getMyProjectMemberPendingByProjectResult } =
      useGetMyProjectMemberPendingByProjectQuery(
        getMyProjectMemberPendingByProjectParam ?? skipToken
      )

    // プロジェクトメンバー保留情報を取得したら、保留状態を更新する
    useEffect(() => {
      const projectMemberPending =
        getMyProjectMemberPendingByProjectResult?.projectMemberPending

      setIsApprovalPending(
        projectMemberPending
          ? projectMemberPending.pendingState === PendingState.WaitingApproval
          : false
      )
    }, [getMyProjectMemberPendingByProjectResult, setIsApprovalPending])

    // Based on the height of the container containing the project description to change the Toggle button's state
    const descriptionMeasuredRef = useCallback<RefCallback<HTMLDivElement>>(
      (node) => {
        if (node !== null) {
          setIsLongDescription(node.scrollHeight > MAX_DESCRIPTION_HEIGHT)
        }
      },
      // フィルタ選択時のみ実行とする
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.projectDetail]
    )

    useEffect(() => {
      return () => {
        // When the component is unmounded, reset the state of IsExpandedDescription
        dispatch(updateIsExpandedDescription(false))
      }
    }, [dispatch])

    // Event handle when the toggle button to expand/collapse the project description is clicked
    const handleToggleDescription = useCallback(() => {
      dispatch(updateIsExpandedDescription(!isExpandedDescription))
    }, [dispatch, isExpandedDescription])

    // Event handle when the Registration button are submitted
    const handleProjectUpdated = useCallback(
      (projectId: string) => {
        // Close entry dialog
        closeProjectEntryDialog()
      },
      [closeProjectEntryDialog]
    )

    // Event handle when Join project button is clicked
    const handleJoinProjectButtonClick = useCallback(async () => {
      if (projectId && props.projectDetail) {
        try {
          // Execute join project process
          joinProject(projectId, props.projectDetail?.approvalToJoin)
        } catch (err) {
          error(t('message.project.failedToJoin'))
        }
      }
    }, [projectId, props.projectDetail, joinProject, error, t])

    // Event handle when leave project button is clicked
    const handleLeaveProjectButtonClick = useCallback(() => {
      setConfirmMessage(t('message.project.confirmLeaveProject'))
      openConfirmDialog()
    }, [t, setConfirmMessage, openConfirmDialog])

    // Event handle when the OK button to accept to leave project is clicked
    const handleLeaveProjectPermitButtonClick = useCallback(async () => {
      // Close confirm dialog
      closeConfirmDialog()

      if (projectId && loggedInUser) {
        try {
          // Execute leave project process
          await leaveProject(projectId, loggedInUser?.id)

          // Display a toast indicating successful to leave project
          success(t('message.project.leaveProjectSuccess'))

          // Go to project list screen after leave project successfully
          navigate('/projects')
        } catch (error) {
          showApiErrors(error)
        }
      }
    }, [
      closeConfirmDialog,
      projectId,
      loggedInUser,
      leaveProject,
      success,
      t,
      navigate,
      showApiErrors,
    ])

    // Create a `headPanelRef` callback function using `useCallback`
    const headPanelRef = useCallback((node: HTMLDivElement) => {
      // Check if the `node` exists; if not, return
      if (!node) return

      // Create a `ResizeObserver` to monitor changes in the `node`'s size
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          // Check if the `width` of the `entry` is less than the specified minimum width
          if (entry.contentRect.width < MIN_PANEL_WIDTH_SHOW_FULL_AVATAR) {
            // If it is, set `displayAvatarLimit` to 0 to hide avatars
            setDisplayAvatarLimit(0)
          } else {
            // If not, set `displayAvatarLimit` to the default value (DISPLAY_LIMIT)
            setDisplayAvatarLimit(DISPLAY_LIMIT)
          }
        }
      })

      // Start observing the `node` with the `ResizeObserver`
      resizeObserver.observe(node)

      // Cleanup function: Unobserve the `node` when the component unmounts
      return () => {
        resizeObserver.unobserve(node)
      }
    }, [])

    // Get project edit permission
    const {
      hasPrjProjectEditPermission,
      hasPrjProjectSettingEditPermission,
      hasPrjMemberAddPermission,
    } = useProjectPermissions(EntityType.Project, projectId)

    if (props.isErrorProjectDetail) {
      return <></>
    }
    if (props.isLoadingProjectDetail) {
      return <LoadIndicator height="1em" width="1em" />
    }

    return (
      <>
        {props.projectDetail && (
          <div className="flex flex-col gap-2.5 p-2.5" ref={headPanelRef}>
            <div className="w-full flex gap-x-2.5 justify-between items-center @container">
              {/* プロジェクトアバター */}
              <div className="flex gap-2 items-center">
                <CrewAvatar
                  cacheValue={
                    props.projectDetail.id + props.projectDetail.version
                  }
                  displayName={props.projectDetail.subject}
                  shape={CrewAvatarShapeType.Square}
                  isPrivate={
                    props.projectDetail.scope === ProjectScope.Private.key
                  }
                  imageURL={generateImageAvatarUrl(
                    EntityType.Project,
                    props.projectDetail.id
                  )}
                  size={CrewAvatarSize.lg}
                />
                {/* 件名 */}
                <div>
                  <p
                    className="text-2xl line-clamp-1 break-all"
                    title={props.projectDetail.subject}
                  >
                    {props.projectDetail.subject}
                  </p>

                  {/* プロジェクトグループ
                   * FIXME: 遷移先が決まり次第toプロパティを設定する
                   * https://break-tmc.atlassian.net/browse/CREW-6918
                   */}
                  <CrewLink
                    title={props.projectDetail.projectGroup?.name}
                    className="line-clamp-1 break-all"
                  >
                    {props.projectDetail.projectGroup?.name}
                  </CrewLink>
                </div>

                {props.isJoinedProject && (
                  // TODO: Web: フォローボタンを刷新する
                  // https://break-tmc.atlassian.net/browse/CREW-15248
                  <>
                    {/* フォローバッジ */}
                    <CrewFollowerUs
                      entityType={EntityType.Project}
                      entityRecordId={props.projectDetail.id}
                    />

                    {/* お気に入りアイコン */}
                    <CrewFavorite
                      key={props.projectDetail.id}
                      entityType={EntityType.Project}
                      entityRecordId={props.projectDetail.id}
                      isFavorite={
                        props.projectDetail.projectFavorite ? true : false
                      }
                    />
                  </>
                )}
              </div>

              {/* disable click add member when use has not joined the project */}
              <div className="flex shrink-0">
                {/* プロジェクトメンバー */}
                <CrewGroupAvatar
                  groupAvatar={props.projectDetail.projectMembers}
                  // Display the Add user button when the logged in user is a project member and has the permission to add project member
                  showAddAvatar={
                    props.isJoinedProject && hasPrjMemberAddPermission
                  }
                  onAddAvatarClick={openProjectMemberEntryDialog}
                  limit={displayAvatarLimit}
                />
              </div>

              {/* アーカイブ済み */}
              {props.projectDetail.archived && (
                <CrewBadge
                  displayColor="bg-crew-gray-4-light dark:bg-crew-gray-4-dark text-crew-white"
                  className="!font-normal !rounded shrink-0"
                >
                  {t('label.isArchived')}
                </CrewBadge>
              )}
              <div className="@3xl:w-60 w-0 ml-auto">
                {projectProgress && props.isJoinedProject && (
                  <>
                    {/* 進捗バー */}
                    <CrewProgressBar
                      completed={projectProgress.completed}
                      incomplete={projectProgress.incomplete}
                      overdue={projectProgress.overdue}
                    />
                  </>
                )}
              </div>
              {/* パブリックプロジェクトを非メンバーが閲覧した場合は「プロジェクトに参加」ボタンを表示
                  承認待ちの場合は「承認待ち」ボタン（非活性）を表示 */}
              {!props.isJoinedProject && (
                <div className="flex">
                  {isApprovalPending ? (
                    // 承認待ちボタン
                    <CrewButton
                      type="primary"
                      text={t('label.approvalPending')}
                      disabled
                    />
                  ) : (
                    // プロジェクトに参加ボタン
                    <CrewButton
                      onClick={handleJoinProjectButtonClick}
                      type="primary"
                      text={t('label.joinProject')}
                    />
                  )}
                </div>
              )}

              {props.isJoinedProject && (
                <div className="flex gap-x-1 shrink-0">
                  <div className="flex gap-x-2.5">
                    {/* 編集ボタン */}
                    {hasPrjProjectEditPermission && (
                      <CrewButton
                        onClick={openProjectEntryDialog}
                        text={t('action.edit')}
                        type="normal"
                        stylingMode="outlined"
                      />
                    )}

                    {/* プロジェクト設定ボタン */}
                    {/* TODO: CrewButtonにMDIが適用できないので暫定対応でbootstrapで代用
                              アイコン付きボタンの実装についてはCREW-7493で対応
                              https://break-tmc.atlassian.net/browse/CREW-7493 */}
                    <div className="flex flex-row items-center">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="flex items-center rounded-md">
                            <CrewButton
                              icon={<MoreHoriz width={20} height={20} />}
                              type="normal"
                              stylingMode="outlined"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          {/* メニューの位置を固定し、一番上に表示する必要があるため、absoluteとz-10を指定している。 */}
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md crew-bg-default shadow-lg ring-1 ring-crew-gray-200 dark:ring-crew-gray-700 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {/* プロジェクトから退出 */}
                                {({ active }) => (
                                  <span
                                    className={classNames(
                                      { 'crew-bg-gray-2': active },
                                      'block px-4 py-2 text-sm',
                                      'cursor-pointer'
                                    )}
                                    onClick={handleLeaveProjectButtonClick}
                                  >
                                    {t('label.leaveProject')}
                                  </span>
                                )}
                              </Menu.Item>
                              {hasPrjProjectSettingEditPermission && (
                                <Menu.Item>
                                  {/* 設定 */}
                                  {({ active }) => (
                                    // CrewLinkを使うとテキストが青くなってしまうので、Linkを使っている
                                    <Link
                                      to={`settings/${getDefaultTabValue(
                                        ProjectSettingTabs
                                      )}`}
                                      className={classNames(
                                        { 'crew-bg-gray-2': active },
                                        'block px-4 py-2 text-sm'
                                      )}
                                    >
                                      {t('label.settings')}
                                    </Link>
                                  )}
                                </Menu.Item>
                              )}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {props.projectDetail.description && (
              <div className="lg:max-w-3xl">
                <div
                  className={classNames(
                    'relative',
                    { 'px-2': !isLongDescription },
                    // 続きを表示リンクがある場合は下余白を追加
                    {
                      'px-2 pt-2 pb-6': isLongDescription,
                    }
                  )}
                >
                  {/* 説明 */}
                  <div
                    ref={descriptionMeasuredRef}
                    className={classNames(
                      !isExpandedDescription && 'line-clamp-2'
                    )}
                  >
                    <div className="text-crew-gray-3-light dark:text-crew-gray-1-dark whitespace-pre-wrap break-all">
                      {props.projectDetail.description}
                    </div>
                  </div>

                  {/* 説明文の折りたたみ部位 */}
                  {isLongDescription && (
                    <div>
                      <div className="w-full absolute bottom-0">
                        <span
                          className="crew-link cursor-pointer"
                          onClick={handleToggleDescription}
                        >
                          {isExpandedDescription
                            ? t('action.abbreviate')
                            : t('action.showMore')}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {/* プロジェクト登録ダイアログ */}
        <ProjectEntryDialog
          isEditMode={true}
          title={t('label.projectEditTitle')}
          isOpen={isProjectEntryDialogOpen}
          onClose={closeProjectEntryDialog}
          projectId={projectId}
          onSubmit={handleProjectUpdated}
          onDeleted={props.onProjectDeleted}
        />

        {/* プロジェクトメンバー追加ダイアログ */}
        <ProjectMemberEntryDialog
          title={t('label.addOrInviteMembers')}
          isOpen={isProjectMemberEntryDialogOpen}
          onClose={closeProjectMemberEntryDialog}
        />
        {/* Confirm leave project dialog */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleLeaveProjectPermitButtonClick}
          onCancelButtonClick={closeConfirmDialog}
        />
      </>
    )
  }
)
