import { CrewButton } from 'components/elements/crewButton/crewButton'
import React from 'react'
import { useTranslation } from '@crew/modules/i18n'

type Props = {
  error: Error
  resetErrorBoundary: () => void
}

export const CrewErrorFallback: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation()

  return (
    <div role="alert" className="p-2 flex flex-col items-center gap-1.5">
      <p className="text-sm">{t('message.general.loadingError')}</p>

      {/* 再レンダリングボタン */}
      <div>
        <CrewButton
          onClick={props.resetErrorBoundary}
          text={t('action.retry')}
          stylingMode="outlined"
        />
      </div>
    </div>
  )
})
