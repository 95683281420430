import { memo, useCallback, useMemo, useState } from 'react'
import { useCrewAttachmentItem } from './useCrewAttachmentItem'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { CrewFileIcon } from 'components/elements/crewFileIcon/crewFileIcon'
import { FileDetailListTabs } from 'enums/app'
import { getDefaultTabValue } from '@crew/utils/enum'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { formatByteSize } from '@crew/utils/number'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { FileHistory, File } from '@crew/models/domain'
import { isFileHistory } from '@crew/utils'
import { useFileSignedUrl } from 'hooks/useFileSignedUrl'
import { downloadFileFromUrl } from 'utils'
import BaselineSaveAlt from '~icons/ic/baseline-save-alt'
import CloseCircleOutline from '~icons/mdi/close-circle-outline'

export type CrewAttachmentItemProps = {
  attachment: File | FileHistory
  showDeleteButton: boolean
  canDownload: boolean
  onDeleteAttachmentItem: (fileId: string) => void // CrewAttachments側のファイル表記削除イベント
}

export const CrewAttachmentItem = memo((props: CrewAttachmentItemProps) => {
  const { deleteAttachment } = useCrewAttachmentItem(props.attachment)
  const [generalShowApiErrors] = useShowApiErrors()
  const { t } = useTranslation()
  const toast = useToast()
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const { getFileHistorySignedUrl, getFileSignedUrl } = useFileSignedUrl()

  // ファイルサイズ
  const fileSize = useMemo(() => {
    return formatByteSize(props.attachment.size)
  }, [props.attachment.size])

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  // Action when download button is clicked
  const handleDownloadButtonClick = useCallback(async () => {
    if (props.canDownload) {
      let url: string

      if (isFileHistory(props.attachment)) {
        // FileHistoryの場合
        url = await getFileHistorySignedUrl(props.attachment.id)
      } else {
        // Fileの場合
        url = await getFileSignedUrl(props.attachment.id)
      }

      // download file from signed url
      downloadFileFromUrl(url, props.attachment.name)
    } else {
      toast.error(t('message.apiError.downloadFileNotAllowed'))
    }
  }, [
    props.canDownload,
    props.attachment,
    getFileHistorySignedUrl,
    getFileSignedUrl,
    toast,
    t,
  ])

  // Action when delete button is clicked
  const handleDeleteButtonClick = useCallback(() => {
    // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
    setConfirmMessage(t('message.general.confirmMessage.delete'))
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // 削除確認ダイアログの許可ボタンクリック時のイベントハンドラ
  const handleDeletePermitButtonClick = useCallback(async () => {
    try {
      // ファイル削除
      await deleteAttachment()

      // ファイル削除に成功した旨のトーストを表示する
      toast.success(t('message.file.deleteSuccess'))

      // 確認ダイアログを閉じる
      closeConfirmDialog()

      // 削除したファイルの表記を消す
      props.onDeleteAttachmentItem(props.attachment.id)
    } catch (err) {
      generalShowApiErrors(err)
    }
  }, [
    closeConfirmDialog,
    deleteAttachment,
    generalShowApiErrors,
    props,
    t,
    toast,
  ])

  const fileId = useMemo(() => {
    return isFileHistory(props.attachment)
      ? props.attachment.fileId
      : props.attachment.id
  }, [props.attachment])

  return (
    <>
      <div className="flex gap-1 items-center">
        <div>
          {/* File icon */}
          <CrewFileIcon fileName={props.attachment.name} />
        </div>
        <CrewLink
          className="text-md"
          to={`/files/${fileId}/${getDefaultTabValue(FileDetailListTabs)}`}
        >
          {props.attachment.name}
          <span className="ml-1">({fileSize})</span>
        </CrewLink>
        {props.canDownload && (
          <div
            className="cursor-pointer hover:opacity-70"
            onClick={handleDownloadButtonClick}
          >
            <BaselineSaveAlt width={20} height={20} />
          </div>
        )}
        {/* 削除アイコン（ボタン） */}
        {props.showDeleteButton && (
          <div
            className="cursor-pointer hover:opacity-70"
            onClick={handleDeleteButtonClick}
          >
            <CloseCircleOutline width={20} height={20} />
          </div>
        )}
      </div>

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </>
  )
})
