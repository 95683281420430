import { useEndMeetingMutation } from '@crew/apis/meeting/meetingApis'
import { useCallback } from 'react'

export const useEventDetailPanel = () => {
  // Get function for end meeting process
  const [endMeetingMutation] = useEndMeetingMutation()

  // End meeting
  const endMeeting = useCallback(
    async (meetingId: string) => {
      // 会議終了API
      await endMeetingMutation({ meetingId: meetingId })
    },
    [endMeetingMutation]
  )

  return {
    endMeeting,
  }
}
