import { FC, memo, useCallback, useEffect, useState } from 'react'
import { $generateHtmlFromNodes } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { mention as mentionConst } from '@crew/configs/constants'
import _ from 'lodash'
import { COMMAND_PRIORITY_EDITOR, BLUR_COMMAND } from 'lexical'
import { getAttributesFromHtml } from 'utils/html'

export const ValueChangeEvent = {
  /**
   * 入力が確定(入力後フォーカスアウト)したらイベントが発火する
   */
  change: 'change',
  /**
   * 文字が入力されたらイベントが発火する
   */
  input: 'input',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ValueChangeEvent =
  (typeof ValueChangeEvent)[keyof typeof ValueChangeEvent]

type Props = {
  valueChangeEvent?: ValueChangeEvent
  onValueChange?: (value: string) => void
  onMentionTargetChange?: (mentionTargetId: string[]) => void
}

/**
 * LexicalのonChangeイベントをハンドリングするためのプラグイン。
 * 発火タイミングをvalueChangeEventで変更できる
 */
export const OnChangeHtmlPlugin: FC<Props> = memo((props) => {
  const [editor] = useLexicalComposerContext()

  // eslintreact-hooks/exhaustive-depsの推奨に従い、useCallback内部で使う関数を分割しておく
  const {
    onValueChange: propsOnValueChange,
    onMentionTargetChange: propsOnMentionTargetChange,
  } = props

  // メンション対象ユーザ
  const [mentionTargetIds, setMentionTargetIds] = useState<string[]>([])

  // 親に値変更イベントを通知する
  const raiseValueChangeEvent = useCallback(() => {
    // 値変更イベントの引数は現在入力されているRich textのHTML表現
    const contentAsHTML = $generateHtmlFromNodes(editor)
    // 値変更イベント通知
    propsOnValueChange?.(contentAsHTML)

    // contentAsHTMLを解析してメンション対象を取得し、変更があればcallbackする
    // 例）<span data-mention-id="XXXX">ユーザー1_T1</span>
    const newMentionTargetIds = getAttributesFromHtml(
      contentAsHTML,
      `${mentionConst.TAG_NAME.toLowerCase()}.${mentionConst.CLASS_NAME}`,
      mentionConst.MENTION_ID_DATA_ATTRIBUTE
    ).sort() //並び順を固定する

    if (!_.isEqual(newMentionTargetIds, mentionTargetIds)) {
      setMentionTargetIds(newMentionTargetIds)
      propsOnMentionTargetChange?.(newMentionTargetIds)
    }
  }, [editor, mentionTargetIds, propsOnMentionTargetChange, propsOnValueChange])

  useEffect(() => {
    // mergeRegisterに渡すコマンドを空配列で用意しておき、
    // valueChangeEvent設定に応じて値更新イベントの発行タイミングを追加する
    const registerCommands = []

    switch (props.valueChangeEvent) {
      case 'change': {
        // 'change'が渡された場合はBLUR_COMMANDで値更新イベントが走るようにする
        registerCommands.push(
          // blur時に走らせる処理を登録
          editor.registerCommand(
            BLUR_COMMAND,
            () => {
              // 値更新イベントを発行する
              editor.update(() => {
                raiseValueChangeEvent()
              })
              return true
            },
            COMMAND_PRIORITY_EDITOR
          )
        )
        break
      }
      case 'input': {
        // 'input'が渡された場合は単にLexicalのUpdateを検知して値更新イベントが走るようにする
        registerCommands.push(
          editor.registerUpdateListener(() => {
            editor.update(() => {
              raiseValueChangeEvent()
            })
          })
        )
        break
      }
      default:
        // 現状はCrewHtmlEditorの方でデフォルト値として'change'が渡されているため、このエラーが発生することはない
        throw new Error(
          `[OnChange] Unknown valueChangeEvent :${props.valueChangeEvent}`
        )
    }

    // 設定されたコマンドを登録する
    return mergeRegister(...registerCommands)
  }, [editor, raiseValueChangeEvent, props.valueChangeEvent])

  return null
})
