import { memo } from 'react'

import { ChatPanel } from 'features/chat/components/chatPanel/chatPanel'
import { useDirectMessageChatPanel } from './useDirectMessageChatPanel'

export const DirectMessageChatPanel = memo(() => {
  useDirectMessageChatPanel()

  return (
    <div className="flex flex-col grow min-h-0 h-full w-full">
      <ChatPanel />
    </div>
  )
})
