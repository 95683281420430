import { ProjectDetailEventListDisplayMode } from 'enums/app'
import { ProjectDetailEventListPanel } from 'features/project/components/projectDetailPage/components/projectDetailEventList/components/projectDetailEventListPanel/projectDetailEventListPanel'
import { ProjectDetailEventListToolbar } from 'features/project/components/projectDetailPage/components/projectDetailEventList/components/projectDetailEventListToolbar/projectDetailEventListToolbar'
import { memo } from 'react'
import { useAppSelector } from 'states/hooks'
import { ProjectDetailEventCalendar } from './components/projectDetailEventCalendar/projectDetailEventCalendar'

export const ProjectDetailEventList = memo(() => {
  const selectedEventListDisplayMode = useAppSelector(
    (state) => state.projectDetail.selectedEventListDisplayMode
  )
  return (
    <div className="flex flex-col grow min-h-0 h-full w-full overflow-y-auto px-2.5 pb-2.5">
      <ProjectDetailEventListToolbar />

      {selectedEventListDisplayMode ===
      ProjectDetailEventListDisplayMode.List.id ? (
        <ProjectDetailEventListPanel />
      ) : (
        <ProjectDetailEventCalendar />
      )}
    </div>
  )
})
