import { FC, memo } from 'react'
import KeyboardArrowLeft from '~icons/material-symbols/keyboard-arrow-left'
import KeyboardArrowRight from '~icons/material-symbols/keyboard-arrow-right'
import { toggleIsRightSideBarLargeWidth } from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'states/hooks'

export const ToggleButtonUpdateWidth: FC = memo(() => {
  const dispatch = useDispatch()

  const isRightSideBarLargeWidth = useAppSelector(
    (state) => state.app.isRightSideBarLargeWidth
  )

  // Button click resize so the right sidebar width is large/normal
  const handleToggleIsRightSideBarLargeWidthButtonClick = useCallback(() => {
    dispatch(toggleIsRightSideBarLargeWidth())
  }, [dispatch])

  return (
    <div
      className="rounded-full cursor-pointer shadow-lg border-[1px] bg-crew-gray-200 dark:bg-crew-gray-900 hover:bg-crew-gray-900 hover:text-crew-gray-50 dark:hover:bg-crew-gray-700"
      onClick={handleToggleIsRightSideBarLargeWidthButtonClick}
    >
      {isRightSideBarLargeWidth ? (
        <KeyboardArrowRight width={24} height={24} />
      ) : (
        <KeyboardArrowLeft width={24} height={24} />
      )}
    </div>
  )
})
