import type {
  Active,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove,
} from '@dnd-kit/sortable'
import type { ReactNode } from 'react'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { CrewDragHandle } from './components/crewDragHandle/crewDragHandle'
import { CrewSortableItem } from './components/crewSortableItem/crewSortableItem'
import { CrewSortableOverlay } from './components/crewSortableOverlay/crewSortableOverlay'

export type CrewSortableListBaseItem = {
  id: UniqueIdentifier
}

export { arrayMove }

type CrewSortableListProps<T> = {
  items: T[]
  onReorder(reorderingEntityId: string, targetPositionEntityId: string): void
  renderItem(item: T): ReactNode
  header: ReactNode
}

export const CrewSortableList = <T extends CrewSortableListBaseItem>({
  items,
  onReorder,
  renderItem,
  header,
}: CrewSortableListProps<T>) => {
  const [active, setActive] = useState<Active | null>(null)

  // Get dragging item from the list
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  )

  // Detect different input methods in order to initiate drag operations, respond to movement and end or cancel the operation.
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  // Handle when drag a specific row
  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    // Update dragging item
    setActive(active)
  }, [])

  // Handle when drop to another specific row
  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      // Update dragging item to nothing
      setActive(null)

      if (over == null || active.id === over.id) {
        // 順番入れ替え無し
        return
      }

      if (typeof active.id !== 'string' || typeof over.id !== 'string') {
        // ここでidがstringでないのは実装ミス
        throw new Error('Invalid id type. Expected string.')
      }

      onReorder(active.id, over.id)
    },
    [onReorder]
  )

  // Handle when doesn't drop to another specific row
  const handleDragCancel = useCallback(() => {
    setActive(null)
  }, [])

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items}>
        <table className="crew-table custom-table">
          {header}
          <tbody>
            {items.map((item) => (
              <Fragment key={item.id}>{renderItem(item)}</Fragment>
            ))}
          </tbody>
        </table>
        <CrewSortableOverlay>
          <table>
            <tbody>{activeItem ? renderItem(activeItem) : null}</tbody>
          </table>
        </CrewSortableOverlay>
      </SortableContext>
    </DndContext>
  )
}

CrewSortableList.Item = CrewSortableItem
CrewSortableList.DragHandle = CrewDragHandle
