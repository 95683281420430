import {
  useInsertProjectMemberCostMutation,
  useUpdateProjectMemberCostMutation,
} from '@crew/apis/project/projectApis'
import { JsonDateFormat } from '@crew/enums/system'
import { ProjectMemberCost } from '@crew/models/domain'
import dayjs from '@crew/modules'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyProjectDetailSettingMemberUnitPriceEvent,
} from 'features/app/states/appSlice'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'

export type FormValues = {
  effectiveStartDate: Date | null
  unitPrice: number | null
}
const formInitialValues: FormValues = {
  effectiveStartDate: null,
  unitPrice: null,
}

export const useProjectDetailMemberUnitPriceEntryForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // react-hook-formの各種データを取得
  const { control, setError, reset, formState, handleSubmit } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  const [insertProjectMemberCostMutation] = useInsertProjectMemberCostMutation()
  const [updateProjectMemberCostMutation] = useUpdateProjectMemberCostMutation()

  // insert project member cost
  const insertProjectMemberCost = useCallback(
    async (data: FormValues, projectId: string, userId: string) => {
      if (!data.effectiveStartDate || !data.unitPrice) {
        return
      }
      const response = await insertProjectMemberCostMutation({
        projectMemberCost: {
          effectiveStartDate: dayjs(data.effectiveStartDate).format(
            JsonDateFormat.YYYYMMDD
          ),
          unitPrice: data.unitPrice,
          projectId,
          userId,
        },
      }).unwrap()

      // send event to update project member list
      const objectEventMessage: ObjectEventMessage<ProjectMemberCost> = {
        eventType: NotifyEventType.Inserted,
        id: response.projectMemberCost?.id ?? '',
        object: response.projectMemberCost ?? undefined,
      }
      dispatch(
        notifyProjectDetailSettingMemberUnitPriceEvent(objectEventMessage)
      )
    },
    [dispatch, insertProjectMemberCostMutation]
  )

  // update project member cost
  const updateProjectMemberCost = useCallback(
    async (
      data: FormValues,
      projectId: string,
      userId: string,
      projectMemberCostId: string,
      version: number
    ) => {
      if (!data.effectiveStartDate || !data.unitPrice) {
        return
      }
      const response = await updateProjectMemberCostMutation({
        projectMemberCost: {
          effectiveStartDate: dayjs(data.effectiveStartDate).format(
            JsonDateFormat.YYYYMMDD
          ),
          unitPrice: data.unitPrice,
          projectId,
          userId,
          projectMemberCostId,
          version,
        },
      }).unwrap()

      // send event to update project member list
      const objectEventMessage: ObjectEventMessage<ProjectMemberCost> = {
        eventType: NotifyEventType.Updated,
        id: response.projectMemberCost?.id ?? '',
        object: response.projectMemberCost ?? undefined,
      }
      dispatch(
        notifyProjectDetailSettingMemberUnitPriceEvent(objectEventMessage)
      )
    },
    [dispatch, updateProjectMemberCostMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      effectiveStartDate: {
        required: t('message.general.required'),
      },
      unitPrice: {
        required: t('message.general.required'),
      },
    }),
    [t]
  )

  return {
    control,
    formState,
    validateRules,
    setError,
    reset,
    handleSubmit,
    insertProjectMemberCost,
    updateProjectMemberCost,
  }
}
