import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { memo, FC } from 'react'
import { UserDetailPanel } from './components/userDetailPanel/userDetailPanel'

export const UserDetailPage: FC = memo(() => {
  return (
    <CrewErrorBoundary>
      {
        // ダイレクトチャンネルが未登録の場合はユーザー詳細パネルを表示する
        <UserDetailPanel />
      }
    </CrewErrorBoundary>
  )
})
