import { arrayUniq } from './';
export const convertApiErrorValidationFailed = (error) => {
    const errors = error.errors;
    const errorFields = arrayUniq(errors.map((err) => err.field));
    const types = {};
    errors.forEach((err) => {
        if (types[err.field] && Array.isArray(types[err.field])) {
            types[err.field].push(err.message);
        }
        else {
            types[err.field] = [err.message];
        }
    });
    return errorFields.map((field) => {
        return {
            field,
            types: Object.fromEntries(types[field].map((val, index) => [index, val])),
        };
    });
};
