import { FC, memo, useCallback } from 'react'

import { useChatTimelineHeader } from './useChatTimelineHeader'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import SharpSearch from '~icons/ic/sharp-search'
import { useAppSelector } from 'states/hooks'
import { Chat } from '@crew/states'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'

/**
 * Timeline形式のヘッダ
 */
export const ChatTimelineHeader: FC = memo(() => {
  const { changeToSearchMode } = useChatTimelineHeader()

  // ViewModelに格納されているCurrentの表示Style(2ペイン表示orコンパクト表示)を取得する
  const currentStyle = useAppSelector(
    (state) => state.message.chat.current.style
  )

  // 表示対象のチャットルームを取得する
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )

  // 検索ボタンクリック時
  const handleSearchButtonClick = useCallback(() => {
    // 検索機能に切り替える
    changeToSearchMode()
  }, [changeToSearchMode])

  // currentStyleに応じてヘッダ部に描画するコンテンツを切り替える
  const renderHeaderContent = useCallback(() => {
    // 表示に必要なデータが指定されていない場合、表示することができないので、何も表示しない
    if (!currentChatRoom) {
      return null
    }

    switch (currentStyle) {
      case Chat.Style.Full:
        return (
          <>
            {/* 検索アイコンボタン */}
            <CrewButton
              className="flex-grow-0 flex-shrink-0"
              icon={<SharpSearch width={24} height={24} />}
              type="normal"
              stylingMode="text"
              size="sm"
              onClick={handleSearchButtonClick}
            />
          </>
        )
      case Chat.Style.Compact:
        return (
          <>
            {/* 関連先リンク */}
            <CrewRelatedItemLink
              entityType={currentChatRoom.entityType}
              id={currentChatRoom.entityRecordId}
              className="grow line-clamp-1 break-all"
            />
            {/* 検索アイコンボタン */}
            <CrewButton
              className="flex-grow-0 flex-shrink-0"
              icon={<SharpSearch width={24} height={24} />}
              type="normal"
              stylingMode="text"
              size="sm"
              onClick={handleSearchButtonClick}
            />
          </>
        )
      default:
        return null
    }
  }, [currentChatRoom, currentStyle, handleSearchButtonClick])

  return (
    <div className="flex px-2 py-1 gap-2 items-center">
      {renderHeaderContent()}
    </div>
  )
})
