import { Modal } from 'components/layouts/modal/modal'
import { useCallback } from 'react'
import { TaskStateEntryForm } from './components/taskStateEntryForm/taskStateEntryForm'

import { FC, memo } from 'react'

export type TaskStateDialogProps = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  taskStateId?: string
}

export const TaskStateEntryDialog: FC<TaskStateDialogProps> = memo((props) => {
  // 登録ボタン押下
  const handleSubmitButtonClick = useCallback(() => {
    props.onClose()
  }, [props])

  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <TaskStateEntryForm
        isEditMode={props.isEditMode}
        taskStateId={props.taskStateId}
        onSubmit={handleSubmitButtonClick}
        onClose={props.onClose}
      />
    </Modal>
  )
})
