import { memo } from 'react'
import AccountCircleOutline from '~icons/material-symbols/account-circle-outline'
import { UserListSearchPanel } from './components/userListSearchPanel/userListSearchPanel'
import { useTranslation } from '@crew/modules/i18n'

export const UserListToolbar = memo(() => {
  const { t } = useTranslation()

  return (
    <>
      {/* ページタイトル */}
      <div className="flex gap-2 items-center h-8 text-crew-gray-3-light dark:text-crew-gray-2-dark">
        <AccountCircleOutline width={24} height={24} />
        <p className="font-bold">{t('label.user') + t('label.list')}</p>
      </div>

      {/* 標準検索 */}
      <UserListSearchPanel />
    </>
  )
})
