import { ChatView } from '@crew/enums/app'
import { useCallback } from 'react'

import { useChatThreadListService, useChatTimelineService } from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'

export const useChatThread = () => {
  // 処理対象のチャットスレッドをViewModelから取得
  const currentChatThread = useAppSelector(
    (state) => state.message.chat.current.chatThread
  )
  // この処理が流れる際、ViewModelには必ずチャットスレッドが設定されているはずなので、未設定の場合はエラーとする
  if (!currentChatThread) {
    throw new Error('currentChatThread is undefined')
  }

  const dispatch = useAppDispatch()

  // Sliceの操作を行うためのServiceを取得
  const chatTimelineService = useChatTimelineService(dispatch)
  const chatThreadListService = useChatThreadListService(dispatch)

  // 戻るボタンクリック時のslice操作対象を特定するため、トピック表示形式を取得する
  const currentDisplayFormat = useAppSelector(
    (state) => state.message.chat.current.displayFormat
  )

  /**
   * InThread表示を解除する(TopicIdをリセット)
   */
  const resetInThread = useCallback(() => {
    // InThread表示を解除するためトピックIDをリセットする
    // リセット対象はsliceのdisplayFormatに応じて制御する
    if (currentDisplayFormat === ChatView.Timeline) {
      chatTimelineService.resetTimelineTopicId({
        chatRoomId: currentChatThread.chatRoomId,
      })
    } else if (currentDisplayFormat === ChatView.ThreadList) {
      chatThreadListService.resetThreadListTopicId({
        chatRoomId: currentChatThread.chatRoomId,
      })
    }
  }, [
    currentDisplayFormat,
    currentChatThread.chatRoomId,
    chatThreadListService,
    chatTimelineService,
  ])

  return {
    currentChatThread,
    resetInThread,
  }
}
