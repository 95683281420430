import { FC, memo, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { BillingCycle } from '@crew/enums/app'
import { useGetBillingCycleQuery } from '@crew/apis/dist/contract/contractApis'
import { RenderCycleItemPrice } from './components/renderCycleItemPrice/renderCycleItemPrice'
import { CrewRadioGroup } from 'components/devextreme/crewRadioGroup'
import { ComponentCallbackHandler } from '@crew/utils'

// render content
// renderとして使うのでmemo不可
const ContractCycleRadioItem: FC<{
  id: BillingCycle
  name: string
  description: string
  billingCycleFee: number | undefined
}> = (props) => (
  <div
    id={`radioId-${props.id}`}
    className="w-full h-10 justify-start items-center inline-flex"
  >
    <span className="leading-5 w-20">{props.name}</span>
    <div className="w-48 crew-text-gray-5 leading-tight">
      {props.description}
    </div>

    <div className="grow shrink basis-0 crew-text-gray-5 text-md leading-tight">
      <RenderCycleItemPrice
        billingCycle={props.id}
        billingCycleFee={props.billingCycleFee}
      />
    </div>
  </div>
)

export type CrewContractChangeBillingCycleRadioGroupProps = {
  id: string
  name: string
  value: string
  onValueChanged: ComponentCallbackHandler<
    typeof CrewRadioGroup,
    'onValueChanged'
  >
}

/**
 * 請求サイクル変更用のラジオボタン
 */
export const CrewContractChangeBillingCycleRadioGroup: FC<CrewContractChangeBillingCycleRadioGroupProps> =
  memo((props) => {
    const { t } = useTranslation()

    // get cycle billing data
    const { data: billingCycleData, isFetching } = useGetBillingCycleQuery()

    // get billing list item
    const billingItems = useMemo(() => {
      return Object.values(BillingCycle).map((item) => {
        let billingCycleFee: number | undefined = undefined
        if (billingCycleData) {
          const billing = billingCycleData.billingCycle.find(
            (billing) => billing.billingCycle === item
          )
          if (billing) {
            billingCycleFee = billing.fee
          }
        }
        return {
          id: item,
          name: t(`label.cycleBilling.${item}.name`),
          description: t(`label.cycleBilling.${item}.description`),
          billingCycleFee,
        }
      })
    }, [billingCycleData, t])

    return (
      <CrewRadioGroup
        id={props.id}
        name={props.name}
        layout="vertical"
        items={billingItems}
        valueExpr="id"
        displayExpr="name"
        itemRender={ContractCycleRadioItem}
        value={props.value}
        onValueChanged={props.onValueChanged}
        disabled={isFetching}
      />
    )
  })
