import { useTranslation } from '@crew/modules/i18n'
import { useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import {
  useInsertTaskStateMutation,
  useUpdateTaskStateMutation,
} from '@crew/apis/task/taskApis'
import { TaskState } from '@crew/models/domain'
import {
  notifyTaskStateEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { useAppDispatch } from 'states/hooks'
import { InsertTaskStateRequest } from '@crew/apis/task/models/insertTaskState/request'
import { UpdateTaskStateRequest } from '@crew/apis/task/models/updateTaskState/request'
import { ValidateRules } from '@crew/utils/form'
import { CrewBadgeColorClass } from 'enums/color'
import { EntityType } from '@crew/enums/domain'
import { useParams } from 'react-router-dom'

export type FormValues = {
  id: string | null
  name: string
  displayColor: string | null
  taskStateType: string | null
  version: number | null
}

// ダイアログ初期値
const formInitialValues: FormValues = {
  id: null,
  name: '',
  displayColor: CrewBadgeColorClass.Red, //add color default is red
  taskStateType: null,
  version: null,
}

export const useTaskStateEntryForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectId } = useParams()

  // Get functions for update task state information
  const [insertTaskStateMutation] = useInsertTaskStateMutation()
  const [updateTaskStateMutation] = useUpdateTaskStateMutation()

  // react-hook-formの各種データを取得
  const { control, reset, formState, handleSubmit, clearErrors, setError } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Insert task state process
  const insertTaskState = useCallback(
    async (data: FormValues) => {
      if (!projectId) return

      const taskStateToSave: InsertTaskStateRequest = {
        taskState: {
          entityType: EntityType.Project,
          entityRecordId: projectId,
          name: data.name,
          displayColor: data.displayColor ?? '',
          taskStateType: data.taskStateType ?? '',
        },
      }

      const result = await insertTaskStateMutation(taskStateToSave).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TaskState> = {
        eventType: NotifyEventType.Inserted,
        id: result.taskState?.id ?? '',
        object: result.taskState ?? undefined,
      }

      dispatch(notifyTaskStateEvent(objectEventMessage))

      return result
    },
    [dispatch, insertTaskStateMutation, projectId]
  )

  // Insert task state process
  const updateTaskState = useCallback(
    async (data: FormValues) => {
      const taskStateToSave: UpdateTaskStateRequest = {
        taskState: {
          id: data.id as string,
          name: data.name,
          taskStateType: data.taskStateType ?? '',
          displayColor: data.displayColor ?? '',
          version: data.version as number,
        },
      }

      const result = await updateTaskStateMutation(taskStateToSave).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TaskState> = {
        eventType: NotifyEventType.Updated,
        id: result.taskState?.id ?? '',
        object: result.taskState ?? undefined,
      }

      dispatch(notifyTaskStateEvent(objectEventMessage))

      return result
    },
    [dispatch, updateTaskStateMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      name: {
        required: t('message.general.isRequired', {
          name: t('label.name'),
        }),
      },
      taskStateType: {
        required: t('message.general.isRequired', {
          name: t('label.type'),
        }),
      },
      displayColor: {
        required: t('message.general.isRequired', {
          name: t('label.displayColor'),
        }),
      },
      // not validate below
      id: {},
      version: {},
    }),
    [t]
  )

  return {
    control,
    reset,
    formState,
    handleSubmit,
    clearErrors,
    setError,

    validateRules,

    insertTaskState,
    updateTaskState,
  }
}
