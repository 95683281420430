import { useTranslation } from '@crew/modules/i18n'
import { memo } from 'react'

export const TaskKindTableHeader = memo(() => {
  const { t } = useTranslation()

  return (
    <thead className="text-sm">
      <tr>
        <th></th>
        <th>
          <div className="flex flex-row gap-2 group">
            <span>{t('label.name')}</span>
          </div>
        </th>
        <th>
          <div className="flex flex-row gap-2 group">
            <span>{t('label.initialValue')}</span>
          </div>
        </th>
        <th>
          <div className="flex flex-row gap-2 group">
            <span>{t('label.registeredDate')}</span>
          </div>
        </th>
        <th className="text-left">
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
  )
})
