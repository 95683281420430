import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import { useContentShareState } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/ContentShareProvider'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { VideoTile } from './components/videoTile'
import { RosterAttendeeType } from 'modules/amazon-chime-sdk-component-library-devextreme/types'
import { useRosterState } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/RosterProvider'
import { useLazyGetLookupUserQuery } from '@crew/apis/dist/lookup/lookupApis'
import { ContentShareConstants } from 'amazon-chime-sdk-js'
import _ from 'lodash'

type Props = BaseSdkProps & { id?: string }

export const CrewContentShare: FC<Props> = memo((props) => {
  const videoElementRef = useRef<HTMLVideoElement>(null)
  const audioVideo = useAudioVideo()
  const { tileId, sharingAttendeeId } = useContentShareState()
  const { roster } = useRosterState()

  const [sharingUserName, setSharingUserName] = useState<string | null>()

  const [lazyGetUserQuery] = useLazyGetLookupUserQuery()

  useEffect(() => {
    if (!audioVideo || !videoElementRef.current || !tileId) {
      return
    }

    // 画面共有しているユーザ情報を取得
    const getUser = async (attendees: RosterAttendeeType[]) => {
      // sharingAttendeeIdからchimeAttendeeIdを取得（sharingAttendeeIdから「#content」を削るだけ）
      const chimeAttendeeId = sharingAttendeeId?.replace(
        ContentShareConstants.Modality,
        ''
      )

      // 参加者からchimeAttendeeIdを元に画面共有しているユーザを抽出
      const sharingAttendee = _.find(attendees, (attendee) => {
        return attendee.chimeAttendeeId === chimeAttendeeId
      })

      // externalUserIdからユーザ情報を取得
      const response = await lazyGetUserQuery({
        userId: sharingAttendee?.externalUserId as string,
      }).unwrap()

      setSharingUserName(response.user?.displayName ?? null)
    }

    const attendees = Object.values(roster)

    getUser(attendees)

    audioVideo.bindVideoElement(tileId, videoElementRef.current)

    return () => {
      setSharingUserName(null)

      const tile = audioVideo.getVideoTile(tileId)
      if (tile) {
        audioVideo.unbindVideoElement(tileId)
      }
    }
  }, [audioVideo, lazyGetUserQuery, roster, sharingAttendeeId, tileId])

  return <VideoTile ref={videoElementRef} {...props} name={sharingUserName} />
})
