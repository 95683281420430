import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewTextAreaField } from 'components/forms/crewTextAreaField'
import { memo, useCallback, useMemo } from 'react'
import {
  useFileUploadConfirmForm,
  FormValues,
} from './useFileUploadConfirmForm'
import { UploadFile } from 'models/domain/uploadFile'
import { CrewUploadedFileList } from 'components/devextreme/crewFileUploader/components/crewUploadedFileList'
import { useFocusInput } from 'hooks/useFocusInput'
import { useTranslation } from '@crew/modules/dist/i18n'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'

export type FileUploadConfirmFormProps = {
  onSubmit: (data: FormValues) => void
  onCancel: () => void
  uploadedFiles: UploadFile[]
}

export const FileUploadConfirmForm = memo(
  (props: FileUploadConfirmFormProps) => {
    const { formState, control, reset, handleSubmit, validateRules } =
      useFileUploadConfirmForm(props)

    const { t } = useTranslation()

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    // キャンセルボタン押下
    const fileUploadConfirmDialogClose = useCallback(() => {
      reset()
      props.onCancel()
    }, [props, reset])

    // 登録ボタン押下
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        // APIによる登録・更新処理は呼び出し側で行う
        props.onSubmit && props.onSubmit(data)
      }
      handleSubmit(onSubmit)()
    }, [handleSubmit, props])

    useFocusInput('description')

    // アップロード中かどうか
    const isUploading = useMemo(() => {
      return props.uploadedFiles.some((file) => file.progress)
    }, [props.uploadedFiles])

    return (
      <form className="flex flex-col gap-5 h-full">
        <CrewScrollView>
          {/* モーダルの最小幅を制限し、アップロードしたファイル一覧が正しく表示されるようにする */}
          <div className="overflow-x-auto">
            <div className="py-2 px-1 min-w-[640px]">
              {/* アップロードファイルリスト */}
              <div className="pb-4 mb-4 h-40 overflow-y-auto">
                {props.uploadedFiles && (
                  <CrewUploadedFileList
                    uploadedFileList={props.uploadedFiles}
                    direction="vertical"
                  />
                )}
              </div>
              <div className="flex flex-col gap-2.5">
                {/* 変更内容 */}
                <CrewTextAreaField
                  id="description"
                  name="description"
                  control={control}
                  rules={validateRules.description}
                  height={60}
                  labelMode="hidden"
                  label={t('label.changes')}
                />

                {/* チャットに投稿する */}
                <CrewCheckBoxField
                  id="needNotification"
                  name="needNotification"
                  control={control}
                  label={t('label.needNotification')}
                  defaultValue={false}
                />
              </div>
            </div>
          </div>
        </CrewScrollView>
        <div className="flex justify-between items-center">
          <div className="ml-auto flex gap-x-5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              disabled={!canSend || isUploading}
              onClick={handleSubmitButtonClick}
            />
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={fileUploadConfirmDialogClose}
            />
          </div>
        </div>
      </form>
    )
  }
)
