import { useCallback } from 'react'
import { useCreatePortalSessionMutation } from '@crew/apis/contract/contractApis'

export const useContractPortalPage = () => {
  // Get create portal function
  const [createPortalSessionMutation] = useCreatePortalSessionMutation()

  // Create portal process
  const createPortalSession = useCallback(async () => {
    const result = await createPortalSessionMutation().unwrap()

    return result
  }, [createPortalSessionMutation])

  return {
    createPortalSession,
  }
}
