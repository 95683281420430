import { Emoji, getEmojiByName } from '@crew/emoji-data'
import { useCallback, useState } from 'react'
import { useEffectOnce } from '@dx-system/react-use'

// One row can have 9 emojis
// Max 3 rows for recently used emojis
const MAX_RECENTLY_USED_EMOJIS = 27

// Check if emoji exists
const isEmoji = (emoji: Emoji | undefined): emoji is Emoji => {
  return !!emoji
}

// Get emojis by shortName
const getEmojisByNames = (shortNames: string[]): Emoji[] => {
  // get emoji by shortName
  const emojis = shortNames
    .map((shortName) => getEmojiByName(shortName))
    .filter(isEmoji)

  return emojis
}

export const useFrequentlyUsedEmoji = () => {
  const [frequentlyUsedEmojis, setFrequentlyUsedEmojis] = useState<Emoji[]>([])

  useEffectOnce(() => {
    const getFrequentlyUsedEmojis = () => {
      // Get the frequently used emojis from local storage
      const frequentlyUsedEmojis = localStorage.getItem('frequentlyUsedEmojis')
      // If there are any, parse them and set the state
      if (frequentlyUsedEmojis) {
        const parsedFrequentlyUsedEmojis = JSON.parse(frequentlyUsedEmojis)

        // Get the emojis by shortNames
        const emojis = getEmojisByNames(parsedFrequentlyUsedEmojis)
        setFrequentlyUsedEmojis(emojis)
      }
    }
    getFrequentlyUsedEmojis()
  })

  // Add emoji to frequently used list
  const addFrequentlyUsedEmoji = useCallback((shortName: string) => {
    // Get the current list of frequently used emojis from local storage
    const frequentlyUsedEmojis = localStorage.getItem('frequentlyUsedEmojis')

    // If there are any, parse them, add the new emoji to the start, remove any duplicates, and update local storage and state
    if (frequentlyUsedEmojis) {
      const parsedFrequentlyUsedEmojis = JSON.parse(frequentlyUsedEmojis)
      const newFrequentlyUsedEmojis = [
        shortName,
        ...parsedFrequentlyUsedEmojis.filter(
          (usedEmoji: string) => usedEmoji !== shortName
        ),
      ].slice(0, MAX_RECENTLY_USED_EMOJIS) // Limit the frequently used emojis to 27 (3 rows) and show the most recent ones

      localStorage.setItem(
        'frequentlyUsedEmojis',
        JSON.stringify(newFrequentlyUsedEmojis)
      )

      // Get the emojis by shortNames
      const emojis = getEmojisByNames(newFrequentlyUsedEmojis)
      setFrequentlyUsedEmojis(emojis)
    } else {
      // If there are no frequently used emojis yet, add the new emoji to local storage and state
      localStorage.setItem('frequentlyUsedEmojis', JSON.stringify([shortName]))

      const emoji = getEmojiByName(shortName)
      if (!emoji) return
      setFrequentlyUsedEmojis([emoji])
    }
  }, [])

  return {
    frequentlyUsedEmojis,
    addFrequentlyUsedEmoji,
  }
}
