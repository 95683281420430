import { ReactNode, useCallback, useState, useEffect, Key } from 'react'
import {
  ButtonProps,
  ButtonSize,
  CrewButton,
  StylingMode,
} from '../crewButton/crewButton'
import { genericMemo } from 'utils'
import classNames from 'classnames'

type ButtonGroupItemProps<TItem> = ButtonProps & {
  item: TItem
  onItemClick?: (item: TItem) => void
  isSelected?: boolean
}

export const CrewButtonGroupItem = genericMemo(
  <TItem,>({
    item,
    onItemClick,
    isSelected,
    type,
    stylingMode,
    ...rest
  }: ButtonGroupItemProps<TItem>) => {
    // Handle button click
    const handleItemClick = useCallback(() => {
      onItemClick?.(item)
    }, [item, onItemClick])

    return (
      <CrewButton
        onClick={handleItemClick}
        type={type}
        stylingMode={stylingMode}
        className={classNames(
          stylingMode === 'outlined' &&
            'first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md rounded-none !border-r-0 last:!border-r',
          isSelected && '!bg-crew-gray-200 dark:!bg-crew-gray-700'
        )}
        {...rest}
      />
    )
  }
)

type ButtonGroupProps<TItem> = {
  items: TItem[]
  keyExpr: keyof TItem // 一意となるキーを指すプロパティ名
  textExpr: keyof TItem // 表示する文字列を指すプロパティ名
  iconExpr?: keyof TItem // アイコンのコンポーネントを指すプロパティ名
  stylingMode?: StylingMode
  defaultSelectedItemKey?: string | number
  selectedItemKey?: string | number
  selectedItem?: TItem
  onItemClick?: (itemData: TItem) => void
  buttonRender?: (itemData: TItem) => React.ReactElement
  size?: ButtonSize
  accesskey?: string
  disabled?: boolean
  tabIndex?: number
  visible?: boolean
  hint?: string
}

export const CrewButtonGroup = genericMemo(
  <TItem,>({
    items,
    stylingMode = 'contained',
    defaultSelectedItemKey,
    selectedItemKey,
    selectedItem,
    onItemClick,
    buttonRender,
    size = 'sm',
    disabled,
    tabIndex,
    visible = true,
    hint,
    keyExpr,
    textExpr,
    iconExpr,
    ...rest
  }: ButtonGroupProps<TItem>) => {
    const [selectedKey, setSelectedKey] = useState(defaultSelectedItemKey)

    useEffect(() => {
      if (
        typeof selectedItemKey === 'number' ||
        typeof selectedItemKey === 'string'
      ) {
        setSelectedKey(selectedItemKey)
      } else if (selectedItem) {
        setSelectedKey(selectedItem[keyExpr] as string | number)
      }
    }, [keyExpr, selectedItem, selectedItemKey])

    // Handle item button click
    const handleItemButtonClick = useCallback(
      (item: TItem) => {
        onItemClick?.(item)

        setSelectedKey(item[keyExpr] as string | number)
      },
      [onItemClick, keyExpr]
    )

    // Render button group item
    const renderItem = useCallback(
      (item: TItem) => {
        if (buttonRender) {
          return buttonRender(item)
        } else {
          return (
            <CrewButtonGroupItem
              key={item[keyExpr] as Key}
              item={item}
              text={item[textExpr] ? String(item[textExpr]) : undefined}
              icon={iconExpr ? (item[iconExpr] as ReactNode) : undefined}
              stylingMode={stylingMode}
              onItemClick={handleItemButtonClick}
              size={size}
              disabled={disabled}
              tabIndex={tabIndex}
              visible={visible}
              hint={hint}
              isSelected={selectedKey === item[keyExpr]}
              {...rest}
            />
          )
        }
      },
      [
        buttonRender,
        disabled,
        handleItemButtonClick,
        hint,
        iconExpr,
        keyExpr,
        rest,
        selectedKey,
        size,
        stylingMode,
        tabIndex,
        textExpr,
        visible,
      ]
    )

    return (
      <div className="flex flex-row items-center">
        {/* Render button group item */}
        {items.map((item) => renderItem(item))}
      </div>
    )
  }
)
