import classNames from 'classnames'
import { FC, memo } from 'react'
import { NavLink } from 'react-router-dom'

import { EntityType } from '@crew/enums/dist/domain'
import { getDefaultTabValue } from '@crew/utils/enum'

import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'

import { DirectMessageTabs } from 'enums/app'
import { CrewUserAvatar } from '../crewUserAvatar/crewUserAvatar'

type Props = {
  id: string
  displayName: string
  version: number
  avatarSize?: CrewAvatarSize
}

// メニューの基本スタイル
const baseClassNames =
  'flex flex-row h-full w-full items-center gap-2 hover:font-bold truncate'

// 選択メニューのアクティブ状態スタイル（ハイライト）
const activeClassNames = 'font-bold crew-text-gray-1 crew-bg-gray-2'

export const CrewUserItem: FC<Props> = memo((props: Props) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <CrewUserAvatar
        userId={props.id}
        displayName={props.displayName}
        size={props.avatarSize ?? CrewAvatarSize.xs}
        cacheValue={props.id + props.version}
      />
      <NavLink
        // ユーザー詳細画面に遷移する
        to={`/${EntityType.User}/${props.id}/${getDefaultTabValue(
          DirectMessageTabs
        )}`}
        className={({ isActive }) =>
          classNames(baseClassNames, isActive && activeClassNames)
        }
        end
      >
        <span className="truncate">{props.displayName}</span>
      </NavLink>
    </div>
  )
})
