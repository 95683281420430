import { FC, memo } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { EventEntryForm } from './components/eventEntryForm/eventEntryForm'

type EventEntryDialogProps = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  projectId?: string
  eventId?: string
  onSubmit: (eventId: string, deleteFlg?: boolean) => void
  startDatetime?: Date
  endDatetime?: Date
}

export const EventEntryDialog: FC<EventEntryDialogProps> = memo((props) => {
  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <div className="flex flex-col gap-y-5 h-full">
        <EventEntryForm
          isEditMode={props.isEditMode}
          eventId={props.eventId}
          projectId={props.projectId}
          onCancel={props.onClose}
          onSubmit={props.onSubmit}
          startDatetime={props.startDatetime}
          endDatetime={props.endDatetime}
        />
      </div>
    </Modal>
  )
})
