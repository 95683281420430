import { FC, memo, useCallback } from 'react'

import { ChatView } from '@crew/enums/app'
import { Chat } from '@crew/states'
import { useAppSelector } from 'states/hooks'
import { ChatSearch } from '../chatSearch/chatSearch'
import { ChatThreadList } from '../chatThreadList/chatThreadList'
import { ChatTimeline } from '../chatTimeline/chatTimeline'

/**
 * プロジェクト詳細画面のチャットタブで中央に表示されるチャットパネル
 * Searchモード
 * - ChatSearchを表示する
 * Chatモード
 * - Timeline,ThreadListのどちらかで表示する
 *   - sliceの値に応じてTimeline形式かThreadList形式で表示
 */
export const ChatPanel: FC = memo(() => {
  // チャットのトピック表示形式を取得する
  const currentDisplayFormat = useAppSelector(
    (state) => state.message.chat.current.displayFormat
  )

  // 表示対象のチャットルームを取得する
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )

  // ViewModelに格納されているモードを取得する
  const currentMode = useAppSelector((state) => state.message.chat.current.mode)

  const renderChatPanel = useCallback(() => {
    // 表示対象のチャットルームが設定されていない場合はチャットが存在していない旨のメッセージを表示する
    if (!currentChatRoom) return null

    switch (currentMode) {
      case Chat.Mode.Search:
        // 検索モード
        return <ChatSearch />
      case Chat.Mode.Chat:
        // チャットモード
        switch (currentDisplayFormat) {
          case ChatView.ThreadList:
            return <ChatThreadList />
          case ChatView.Timeline:
            return <ChatTimeline />
          default:
            return null
        }
      default:
        return null
    }
  }, [currentChatRoom, currentDisplayFormat, currentMode])

  return (
    <div className="h-full">
      {/* chat panel */}
      {renderChatPanel()}
    </div>
  )
})
