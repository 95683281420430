import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { memo, useCallback, useEffect } from 'react'
import { useAuthoritySettingRoleEntryForm } from './useAuthoritySettingRoleEntryForm'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { camelCase } from 'lodash'
import { Role } from '../../../../useAuthoritySettingPanel'
import { RoleRef } from '@crew/models/refs'
import { Roles } from '@crew/enums/app'
import { FormValues } from './useAuthoritySettingRoleEntryForm'
import { useTranslation } from '@crew/modules/i18n'

export type AuthoritySettingRoleEntryFormProps = {
  roles: RoleRef[]
  onClose: () => void
  onSubmit: (role: Role) => void
}

export const AuthoritySettingRoleEntryForm = memo(
  (props: AuthoritySettingRoleEntryFormProps) => {
    const { control, setValue, handleSubmit, validateRules } =
      useAuthoritySettingRoleEntryForm(props.roles)

    const { t } = useTranslation()

    useEffect(() => {
      if (props.roles.length > 0) {
        const adminRole = props.roles.find(
          (role) => role.roleCode === Roles.PrjAdmin.value
        )
        if (adminRole) {
          setValue('originalRoleId', adminRole.id)
        }
      }
    }, [props.roles, setValue])

    // handle submit form and call api register event
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        props.onSubmit?.(data)
      }
      handleSubmit(onSubmit)()
    }, [handleSubmit, props])

    return (
      <div className="flex flex-col gap-y-3">
        <div className="flex-col flex gap-y-2.5">
          {/* 名称 */}
          <CrewTextBoxField
            control={control}
            id="name"
            name="name"
            required
            label={t('label.roleName')}
            rules={validateRules.name}
          />

          {/* コピー元 */}
          <CrewSelectBoxField
            id="originalRoleId"
            control={control}
            name="originalRoleId"
            items={props.roles}
            valueExpr="id"
            // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxRadioGroup/Configuration/#displayExpr
            // item can be null
            displayExpr={(item) =>
              item?.isUserDefined
                ? item?.name
                : item?.roleCode
                ? t(`label.roles.${camelCase(item.roleCode)}`)
                : null
            }
            minSearchLength={0}
            label={t('label.original')}
          />
        </div>

        {/* Dialog action */}
        <div className="flex justify-end gap-x-3 mt-5">
          <CrewButton
            text={t('action.register')}
            type="primary"
            onClick={handleSubmitButtonClick}
          />

          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={props.onClose}
          />
        </div>
      </div>
    )
  }
)
