import { Modal } from 'components/layouts/modal/modal'
import { useCallback } from 'react'
import { TaskKindEntryForm } from './components/taskKindEntryForm/taskKindEntryForm'

import { FC, memo } from 'react'

export type TaskKindDialogProps = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  taskKindId?: string
}

export const TaskKindEntryDialog: FC<TaskKindDialogProps> = memo((props) => {
  // 登録ボタン押下
  const handleSubmitButtonClick = useCallback(() => {
    props.onClose()
  }, [props])

  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <TaskKindEntryForm
        isEditMode={props.isEditMode}
        taskKindId={props.taskKindId}
        onSubmit={handleSubmitButtonClick}
        onClose={props.onClose}
      />
    </Modal>
  )
})
