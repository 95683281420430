import { apis } from '../apiBase/apiBase';
export const tenantSettingApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getTenantSettingMembers: builder.query({
            query: (params) => {
                return {
                    url: `tenant-setting-members`,
                    params: params,
                    method: `GET`,
                };
            },
        }),
        deleteUserPending: builder.mutation({
            query: (params) => ({
                url: `user-pendings/delete`,
                method: `POST`,
                body: params,
            }),
        }),
        insertUserPendings: builder.mutation({
            query: (params) => ({
                url: 'user-pendings/insert',
                method: 'POST',
                body: params,
            }),
        }),
        deleteUserPendingsInvitation: builder.mutation({
            query: (params) => ({
                url: `user-pendings/bulk-delete/inviting`,
                method: `POST`,
                body: params,
            }),
        }),
        getTenant: builder.query({
            query: (params) => {
                return {
                    url: '/tenant',
                    method: 'GET',
                };
            },
        }),
        verifyBulkInvitation: builder.mutation({
            query: (params) => {
                // リクエストのファイルをformDataに格納して送信
                const formData = new FormData();
                formData.append('file', params.file);
                return {
                    url: '/user-pendings/bulk-insert/verify',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        bulkInviteUser: builder.mutation({
            query: (params) => {
                return {
                    url: '/user-pendings/bulk-insert',
                    method: 'POST',
                    body: params,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetTenantSettingMembersQuery, useLazyGetTenantSettingMembersQuery, useDeleteUserPendingMutation, useInsertUserPendingsMutation, useDeleteUserPendingsInvitationMutation, useGetTenantQuery, useVerifyBulkInvitationMutation, useBulkInviteUserMutation, useLazyGetTenantQuery, } = tenantSettingApis;
