import { FC, memo, useMemo } from 'react'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewLoadingEffect } from 'components/elements/crewLoading/crewLoadingEffect/crewLoadingEffect'
import { UserRef } from '@crew/models/refs'
import classNames from 'classnames'
import ChatOutline from '~icons/material-symbols/chat-outline'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'

// アバターの表示位置
export const AvatarPosition = {
  Right: 'right',
  Center: 'center',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AvatarPosition =
  (typeof AvatarPosition)[keyof typeof AvatarPosition]

type CrewChatMessageItemAvatarProps = {
  isLargeAvatar: boolean
  user?: UserRef
  position: AvatarPosition
  omitUserAvatar: boolean
}

export const CrewChatMessageItemAvatar: FC<CrewChatMessageItemAvatarProps> =
  memo((props) => {
    // アバターの表示位置制御用クラス
    const avatarPositionClassNames = useMemo(() => {
      // 表示形式が「スレッド内」、またはスレッドヘッダ（吹き出しアイコン）の場合は中央寄せ
      if (props.position === AvatarPosition.Center) {
        return 'justify-center'
      } else {
        // それ以外は右寄せ
        return 'justify-end'
      }
    }, [props.position])

    return (
      <div
        className={classNames(
          'flex flex-shrink-0 items-start w-10',
          avatarPositionClassNames
        )}
      >
        {props.omitUserAvatar ? (
          <div>
            <ChatOutline width={24} height={24} />
          </div>
        ) : props.user ? (
          <CrewUserAvatar
            userId={props.user.id}
            displayName={props.user.displayName}
            size={props.isLargeAvatar ? CrewAvatarSize.base : CrewAvatarSize.xs}
            cacheValue={props.user.id + props.user.version}
          />
        ) : (
          <div
            className={props.isLargeAvatar ? 'crew-avatar' : 'crew-avatar-xs'}
          >
            <CrewLoadingEffect />
          </div>
        )}
      </div>
    )
  })
