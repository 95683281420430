import { FC, memo } from 'react'
import {
  CrewTab,
  CrewTabProps,
} from 'components/elements/crewTabs/components/crewTab'

type Props = {
  tabItems: CrewTabProps[]
}
export const CrewTabs: FC<Props> = memo((props) => {
  return (
    <div className="flex flex-row flex-none h-10 gap-2 items-center border-b crew-text-gray-4 crew-bg-gray-1 crew-border-gray">
      {/* tabItem分だけ表示する */}
      {props.tabItems.map((item) => (
        <CrewTab
          text={item.text}
          selected={item.selected}
          onClick={item.onClick}
          // Warning表示回避のkey指定
          key={item.text}
          icon={item.icon}
        />
      ))}
    </div>
  )
})
