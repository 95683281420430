import { FC, memo, ReactNode, useCallback, useMemo } from 'react'
import { motion, useAnimationControls } from 'framer-motion'
import { useToggle } from '@dx-system/react-use'
import classNames from 'classnames'

export type AccordionProps = {
  title: string
  content: ReactNode
  renderToolIcon?: () => ReactNode
}

export const CrewAccordion: FC<AccordionProps> = memo((props) => {
  const [expanded, toggleExpanded] = useToggle(true)

  const controls = useAnimationControls()

  const handleToggleButtonClick = useCallback(() => {
    if (expanded) {
      controls.start('collapsed')
    } else {
      controls.start('expanded')
    }
    toggleExpanded()
  }, [controls, expanded, toggleExpanded])

  const variants = useMemo(
    () => ({
      expanded: { height: 'auto' },
      collapsed: { height: '0px' },
    }),
    []
  )

  return (
    <div>
      <div className="flex flex-row items-center select-none cursor-pointer">
        <div className="flex flex-row w-full p-2">
          <div className="flex-1 font-bold text-sm">{props.title}</div>

          <div className="flex gap-2">
            {/* renderToolIconが指定されていれば描画する */}
            {props.renderToolIcon?.()}

            <i
              className={classNames(
                'bi',
                expanded ? 'bi-chevron-down' : 'bi-chevron-right'
              )}
              onClick={handleToggleButtonClick}
            />
          </div>
        </div>
      </div>
      <motion.div
        animate={controls}
        initial="expanded"
        variants={variants}
        transition={{ duration: 0.3 }}
        className={classNames(
          'flex flex-row relative',
          !expanded && 'overflow-hidden'
        )}
      >
        {props.content}
      </motion.div>
    </div>
  )
})
