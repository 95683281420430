import {
  useDeleteFolderMutation,
  useUpdateFolderParentMutation,
} from '@crew/apis/folder/folderApis'
import { Folder } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyFolderEvent,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'

export const useCrewFolderList = () => {
  const dispatch = useAppDispatch()

  const [deleteFolderMutation] = useDeleteFolderMutation()
  const [updateFolderParentMutation] = useUpdateFolderParentMutation()

  // Process to delete folder
  const deleteFolder = useCallback(
    async (folderId: string, version: number) => {
      await deleteFolderMutation({
        folderId,
        version,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Folder> = {
        eventType: NotifyEventType.Deleted,
        id: folderId,
        object: undefined,
      }

      dispatch(notifyFolderEvent(objectEventMessage))
    },
    [deleteFolderMutation, dispatch]
  )

  // Move a folder to a folder
  const moveFolderToFolder = useCallback(
    async (folderId: string, parentFolderId: string, version: number) => {
      await updateFolderParentMutation({
        folder: {
          id: folderId,
          parentFolderId,
          version,
        },
      }).unwrap()
    },
    [updateFolderParentMutation]
  )

  return { deleteFolder, moveFolderToFolder }
}
