import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { formatByteSize } from '@crew/utils/number'
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  getCoreRowModel,
} from '@tanstack/react-table'
import { CrewFileAction } from 'components/elements/crewFileAction/crewFileAction'
import { CrewFileChangerItem } from 'components/elements/crewFileChangerItem/crewFileChangerItem'
import { CrewFileName } from 'components/elements/crewFileName/crewFileName'
import {
  CrewTable,
  SelectCheckbox,
} from 'components/elements/crewTable/crewTable'
import { EventFile } from '@crew/apis/project/models/getEventFiles/response'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewFileTag } from 'components/elements/crewFileTag/crewFileTag'
import {
  useDeleteFileMutation,
  useBulkDeleteFileMutation,
  useLazyDownloadFilesQuery,
} from '@crew/apis/dist/file/fileApis'
import { GetEventFilesRequest } from '@crew/apis/dist/project/models/getEventFiles/request'
import { useGetEventFilesQuery } from '@crew/apis/dist/project/projectApis'
import { Region, SettingKeyType } from '@crew/enums/app'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useModal } from 'components/layouts/modal/useModal'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import {
  NotifyEventType,
  DetailFileSearchOptions,
  FileListDisplayMode,
  DisplayAnonymousFile,
} from 'enums/app'
import {
  ObjectEventMessage,
  notifyFileEvent,
} from 'features/app/states/appSlice'
import { FileListTagsEntryDialog } from 'features/file/components/fileListPage/components/fileListTagsEntryDialog/fileListTagsEntryDialog'
import { useToast } from 'hooks/useToast'
import { useUserSetting } from '@crew/states'
import qs from 'qs'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import {
  getParamAsString,
  getParamAsArray,
  getParamAsDate,
  downloadFileFromUrl,
} from 'utils'
import _ from 'lodash'
import { CrewPagination } from 'components/elements/crewPagination/crewPagination'
import { CrewGridFileItem } from 'components/elements/crewGridFileItem/crewGridFileItem'
import { useValueChangeEffect } from '@crew/hooks'
import BaselineSaveAlt from '~icons/ic/baseline-save-alt'
import RoundLocalOffer from '~icons/ic/round-local-offer'
import BaselineDelete from '~icons/ic/baseline-delete'
import { useShowApiErrors } from 'hooks/useShowApiErrors'

// renderとして使うのでmemo不可
const SizeText: FC<{ size: number }> = (props) => (
  <>{formatByteSize(props.size)}</>
)

// renderとして使うのでmemo不可
const UpdateAt: FC<{ date: string }> = (props) => {
  const [t] = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  return (
    <>
      {props
        ? t('format.timestamp', {
            value: props.date,
            timeZone: defaultUserProfileRegion,
          })
        : undefined}
    </>
  )
}

type SelectedFile = {
  id: string
  entityRecordId: string
  version: number
}

export const FileTable = memo(() => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { success, error } = useToast()
  const [showApiErrors] = useShowApiErrors()

  const [searchParams] = useSearchParams()

  const params = qs.parse(searchParams.toString())

  const { projectId, eventId } = useParams()

  // Get list project ID
  const projectIds = useMemo(() => (projectId ? [projectId] : []), [projectId])

  const [
    isSingleDeleteFileConfirmDialogOpen,
    openSingleDeleteFileConfirmDialog,
    closeSingleDeleteFileConfirmDialog,
  ] = useModal()

  const [fileRemove, setFileRemove] = useState<EventFile>()

  const [deleteFileMutation] = useDeleteFileMutation()
  const [lazyDownloadFiles] = useLazyDownloadFilesQuery()

  const [bulkDeleteFileMutation] = useBulkDeleteFileMutation()
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const fileEventMessage = useAppSelector((state) => state.app.fileEventMessage)
  // 選択中の表示形式
  const selectedFileListDisplayMode = useAppSelector(
    (state) => state.eventDetailPage.selectedFileListDisplayMode
  )

  const [isOpenTagsEntryDialog, openTagsEntryDialog, closeTagsEntryDialog] =
    useModal()

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  // 無名ファイル
  const isIncludesAnonymousFiles = useMemo(() => {
    if (!getParamAsString(DetailFileSearchOptions.AnonymousFile.id, params)) {
      return undefined
    }
    return (
      getParamAsString(DetailFileSearchOptions.AnonymousFile.id, params) ===
      DisplayAnonymousFile.Display.value
    )
  }, [params])

  const requestParams: GetEventFilesRequest | undefined = eventId
    ? {
        eventId,
        keyword: getParamAsString(DetailFileSearchOptions.Keyword.id, params),
        tagIds: getParamAsArray(DetailFileSearchOptions.Tag.id, params),
        createdById: getParamAsString(
          DetailFileSearchOptions.CreatedById.id,
          params
        ),
        updatedById: getParamAsString(
          DetailFileSearchOptions.UpdatedById.id,
          params
        ),
        createdAt: getParamAsDate(DetailFileSearchOptions.CreatedAt.id, params),
        updatedAt: getParamAsDate(DetailFileSearchOptions.UpdatedAt.id, params),
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        sort: searchParams.getAll('sort') || undefined,
        isIncludesAnonymousFiles,
      }
    : undefined

  const { data: getEventFilesResult, refetch: getEventFilesRefetch } =
    useGetEventFilesQuery(requestParams ?? skipToken)

  const totalCount = useMemo(
    () => getEventFilesResult?.totalCount ?? 0,
    [getEventFilesResult?.totalCount]
  )

  const eventFiles = useMemo(
    () => getEventFilesResult?.eventFiles ?? [],
    [getEventFilesResult?.eventFiles]
  )

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([])
  // get selected files from row selection
  useValueChangeEffect(
    () => {
      if (!getEventFilesResult?.eventFiles) {
        setSelectedFiles([])
      } else {
        setSelectedFiles(
          getEventFilesResult.eventFiles.filter((file) => {
            return rowSelection[file.id]
          })
        )
      }
    },
    [getEventFilesResult?.eventFiles, rowSelection],
    rowSelection
  )

  // get selected file ids from selected files
  const selectedFileIds = useMemo(() => {
    return selectedFiles.map((row) => row.id)
  }, [selectedFiles])

  // Handle open confirm dialog to bulk delete file
  const handleOpenConfirmBulkDeleteFileButtonClick = useCallback(() => {
    setConfirmMessage(t('message.file.bulkDeleteConfirm'))
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // Press submit confirmation button to delete file
  const handleSubmitPermitDeleteFileButtonClick = useCallback(async () => {
    try {
      closeConfirmDialog()

      // Request to bulk delete file
      await bulkDeleteFileMutation({
        files: selectedFiles.map((item: SelectedFile) => {
          return {
            fileId: item.id,
            version: item.version,
          }
        }),
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<EventFile> = {
        eventType: NotifyEventType.Deleted,
        id: selectedFiles[0].id,
        object: undefined,
      }
      dispatch(notifyFileEvent(objectEventMessage))

      // reset row selection
      setRowSelection({})

      success(t('message.file.deleteSuccess'))
    } catch (err) {
      error(t('message.general.failedToDelete'))
    }
  }, [
    closeConfirmDialog,
    bulkDeleteFileMutation,
    selectedFiles,
    dispatch,
    success,
    t,
    error,
  ])

  // Click button download request to link download bulk file
  const handleBulkFileDownloadButtonClick = useCallback(async () => {
    try {
      // Get signed url
      const response = await lazyDownloadFiles({
        fileIds: selectedFileIds,
      })

      if (!response.data) {
        error(t('message.general.failedToDownload'))
        return
      }

      // download file from signed url
      downloadFileFromUrl(response.data.url, response.data.fileName)
    } catch (err) {
      showApiErrors(err)
    }
  }, [error, lazyDownloadFiles, selectedFileIds, showApiErrors, t])

  // refresh file list
  useEffect(() => {
    getEventFilesRefetch()
  }, [getEventFilesRefetch, fileEventMessage, selectedFileListDisplayMode])

  // reset row selection when change display mode
  useValueChangeEffect(
    () => {
      // reset row selection
      setRowSelection({})
    },
    [],
    selectedFileListDisplayMode
  )

  const sorting: SortingState = useMemo(() => {
    const sortArray = getParamAsArray('sort', params) || []
    return sortArray.map((sort) => {
      const [id, direction] = sort.split('.')
      return {
        id,
        desc: direction === 'desc',
      }
    })
  }, [params])

  const pageCount = Math.ceil((totalCount ?? 0) / pagination.pageSize)

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
    right: ['action'],
  })

  // https://github.com/TanStack/table/discussions/3899
  // https://github.com/TanStack/table/discussions/3619
  // https://github.com/infonomic/remix.infonomic.io/blob/d3a7f628d3ad6e1e80cc80d4ac72db74da90e8d6/app/routes/admin%2B/users.tsx#L116
  // Func handle change pagination
  const handlePaginationChange: OnChangeFn<PaginationState> = useCallback(
    (updaterOrValue) => {
      let values: PaginationState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(pagination)
      } else {
        values = updaterOrValue
      }

      const newParams = {
        ...params,
        pageIndex: values.pageIndex,
        pageSize: values.pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })
      navigate(`?${newQueryString}`)
    },
    [navigate, pagination, params]
  )

  // Func handle change sorting
  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      let values: SortingState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(sorting)
      } else {
        values = updaterOrValue
      }

      const sortList = values.map((sort) => {
        return `${sort.id}.${sort.desc ? 'desc' : 'asc'}`
      })

      const newParams = {
        ...params,
        sort: sortList,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })
      navigate(`?${newQueryString}`)
    },
    [navigate, params, sorting]
  )

  // Handle open confirm dialog to delete file
  const handleSingleDeleteFileButtonClick = useCallback(
    (row: Row<EventFile>) => {
      setFileRemove(row.original)
      openSingleDeleteFileConfirmDialog()
    },
    [openSingleDeleteFileConfirmDialog]
  )

  // Press submit confirmation button to delete file
  const handleSubmitSingleDeleteFileButtonClick = useCallback(async () => {
    try {
      closeSingleDeleteFileConfirmDialog()

      // check file id exists
      if (!fileRemove) {
        return
      }

      // Request to delete file
      await deleteFileMutation({
        fileId: fileRemove.id,
        version: fileRemove.version,
      }).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<EventFile> = {
        eventType: NotifyEventType.Deleted,
        id: fileRemove.id,
        object: undefined,
      }
      dispatch(notifyFileEvent(objectEventMessage))

      // reset row selection
      const selection = Object.keys(rowSelection).reduce((obj, key) => {
        if (fileRemove.id !== key) {
          obj[key] = rowSelection[key]
        }
        return obj
      }, {} as RowSelectionState)
      setRowSelection(selection)

      success(t('message.file.deleteSuccess'))
    } catch (err) {
      error(t('message.general.failedToDelete'))
    }
  }, [
    closeSingleDeleteFileConfirmDialog,
    fileRemove,
    deleteFileMutation,
    dispatch,
    rowSelection,
    success,
    t,
    error,
  ])

  const columns = useMemo<ColumnDef<EventFile>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        header: ({ table }) => (
          <SelectCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="flex flex-1 justify-center">
            <SelectCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        size: 80,
        minSize: 50,
        enableSorting: false,
        meta: {
          align: 'center',
        },
      },
      {
        id: 'name',
        accessorKey: 'name',
        header: () => t('label.fileName'),
        cell: ({ row }) => (
          <div className="w-full">
            <CrewFileName
              fileId={row.original.id}
              fileName={row.original.name}
              entityType={EntityType.Event}
              entityRecordId={eventId}
              className="line-clamp-2 break-all"
            />
          </div>
        ),
        size: 500,
        minSize: 50,
      },
      {
        id: 'tags',
        header: () => t('label.tag'),
        cell: ({ row }) => (
          // we show only 2 lines of tags so need using max-h-[60px]
          <div className="w-full flex flex-wrap gap-1 text-sm overflow-hidden max-h-[52px]">
            {row.original.tags.map((tag) => (
              <CrewFileTag key={tag.id} tag={tag} />
            ))}
          </div>
        ),
        size: 240,
        minSize: 50,
      },
      {
        id: 'revision',
        accessorKey: 'revision',
        header: () => t('label.version'),
        cell: (ctx) => (
          <div className="text-right w-full">{ctx.getValue() as string}</div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'size',
        accessorKey: 'size',
        header: () => t('label.size'),
        cell: ({ row }) => (
          <div className="text-right w-full truncate">
            <SizeText size={row.original.size} />
          </div>
        ),
        size: 80,
        minSize: 50,
      },
      {
        id: 'updatedBy',
        accessorKey: 'updatedBy',
        header: () => t('label.updatedBy'),
        cell: ({ row }) => (
          <div className="text-right w-full truncate">
            <CrewFileChangerItem
              displayName={row.original.lastUpdatedAttachmentBy.displayName}
              userId={row.original.lastUpdatedAttachmentBy.id}
              version={row.original.lastUpdatedAttachmentBy.version}
            />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'lastUpdatedAttachmentAt',
        accessorKey: 'lastUpdatedAttachmentAt',
        header: () => t('label.updateDatetime'),
        cell: ({ row }) => (
          <div className="truncate">
            <UpdateAt date={row.original.lastUpdatedAttachmentAt} />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => (
          <CrewFileAction
            fileId={row.original.id}
            entityType={EntityType.Event}
            entityRecordId={eventId}
            onDelete={() => handleSingleDeleteFileButtonClick(row)}
          />
        ),
        size: 50,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [t, eventId, handleSingleDeleteFileButtonClick]
  )

  const tableOptions: TableOptions<EventFile> = {
    data: eventFiles,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    pageCount,
    state: {
      pagination,
      sorting,
      columnVisibility,
      columnPinning,
      rowSelection,
    },
    getRowId: (row) => row.id,
    onPaginationChange: handlePaginationChange,
    onSortingChange: handleSortingChange,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    enableRowSelection: true,
    maxMultiSortColCount: 2,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  // handle change pagination grid mode
  const handlePaginationGridChange = useCallback(
    (pageIndex: number, pageSize: number) => {
      const newParams = {
        ...params,
        pageIndex,
        pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, params]
  )

  // Event when check item in grid mode
  const handleSelectItem = useCallback(
    (fileId: string, version: number, entityRecordId: string) => {
      // get selected fileIds
      setSelectedFiles((prev) => {
        // If fileId exists in selectedFileIds, remove it
        if (prev.some((item) => item.id === fileId)) {
          return prev.filter((item) => item.id !== fileId)
        }
        // If fileId does not exist in selectedFileIds, add it
        return [...prev, { id: fileId, version, entityRecordId }]
      })
    },
    []
  )

  return (
    <>
      <div className="flex flex-col gap-y-2 relative pt-5 @container">
        <div className="flex gap-x-2.5 absolute right-0 top-3">
          {/* 一括ダウンロードボタン */}
          <CrewButton
            icon={<BaselineSaveAlt width={20} height={20} />}
            disabled={selectedFileIds.length === 0}
            onClick={handleBulkFileDownloadButtonClick}
            stylingMode="outlined"
          />

          {/* 一括タグ追加ボタン */}
          <CrewButton
            icon={<RoundLocalOffer width={20} height={20} />}
            disabled={selectedFileIds.length === 0}
            onClick={openTagsEntryDialog}
            stylingMode="outlined"
          />

          {/* 一括削除ボタン */}
          <CrewButton
            icon={<BaselineDelete width={20} height={20} />}
            disabled={selectedFileIds.length === 0}
            onClick={handleOpenConfirmBulkDeleteFileButtonClick}
            stylingMode="outlined"
          />

          {/* 一括削除時の確認ダイアログ */}
          <CrewConfirmDialog
            isOpen={isConfirmDialogOpen}
            message={confirmMessage}
            onPermitButtonClick={handleSubmitPermitDeleteFileButtonClick}
            onCancelButtonClick={closeConfirmDialog}
          />
        </div>

        {selectedFileListDisplayMode === FileListDisplayMode.List.id ? (
          // list mode
          <div className="flex-1 overflow-y-hidden">
            {/* file list table */}
            <CrewTable tableOptions={tableOptions} />
          </div>
        ) : (
          // grid mode
          <>
            <CrewPagination
              pageSize={pagination.pageSize}
              pageIndex={pagination.pageIndex}
              pageCount={Math.ceil(totalCount / pagination.pageSize)}
              onPaginationChange={handlePaginationGridChange}
            />
            <div className="grid @7xl:grid-cols-7 @3xl:grid-cols-5 grid-cols-3 gap-2.5">
              {eventFiles.map((file) => (
                <CrewGridFileItem
                  key={file.id}
                  file={file}
                  onSelect={handleSelectItem}
                />
              ))}
            </div>
            <CrewPagination
              pageSize={pagination.pageSize}
              pageIndex={pagination.pageIndex}
              pageCount={Math.ceil(totalCount / pagination.pageSize)}
              onPaginationChange={handlePaginationGridChange}
            />
          </>
        )}
      </div>

      <FileListTagsEntryDialog
        isOpen={isOpenTagsEntryDialog}
        title={t('label.saveTags')}
        fileIds={selectedFileIds}
        projectIds={projectIds}
        onClose={closeTagsEntryDialog}
      />

      {/* Single file delete confirm dialog */}
      <CrewConfirmDialog
        isOpen={isSingleDeleteFileConfirmDialogOpen}
        message={t('message.general.confirmMessage.delete')}
        onPermitButtonClick={handleSubmitSingleDeleteFileButtonClick}
        onCancelButtonClick={closeSingleDeleteFileConfirmDialog}
      />
    </>
  )
})
