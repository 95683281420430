import { useCallback } from 'react'
import { useDeleteTaskWorkMutation } from '@crew/apis/task/taskApis'
import { TaskWork } from '@crew/models/dist/domain'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTaskWorkEvent,
} from 'features/app/states/appSlice'
import { useAppDispatch } from 'states/hooks'

export const useTaskDetailWorkItem = () => {
  const dispatch = useAppDispatch()

  // Get function for delete task work
  const [deleteTaskWorkMutation] = useDeleteTaskWorkMutation()

  // Delete task work process
  const deleteTaskWork = useCallback(
    async (taskWorkId: string, version: number) => {
      await deleteTaskWorkMutation({
        taskWorkId,
        version,
      }).unwrap()

      // タスク作業が削除された旨のEventMessageを通知
      const objectEventMessage: ObjectEventMessage<TaskWork> = {
        eventType: NotifyEventType.Deleted,
        id: taskWorkId,
        object: undefined,
      }
      dispatch(notifyTaskWorkEvent(objectEventMessage))
    },
    [deleteTaskWorkMutation, dispatch]
  )

  return {
    deleteTaskWork,
  }
}
