import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// TODO: 暫定的にここに定義しているが、定義箇所は変えた方が良さそう。API側にも同じ内容の別定義があるのでそれも統一させる
// https://break-tmc.atlassian.net/browse/CREW-6905
export type MediaPipeline = {
  mediaPipelineArn: string
  mediaPipelineId: string
}

type WebMeetingStateType = {
  isOrganizer: boolean
  isLeaveMeeting: boolean
  mediaPipeline: MediaPipeline | null
}

const initialWebMeetingState: WebMeetingStateType = {
  isOrganizer: false,
  isLeaveMeeting: false,
  mediaPipeline: null,
}

export const webMeetingSlice = createSlice({
  name: 'webMeeting',
  initialState: initialWebMeetingState,
  reducers: {
    // 会議の開催者判定を設定
    setIsOrganizer: (state, action: PayloadAction<boolean>) => {
      state.isOrganizer = action.payload
    },

    // 会議からの退室判定
    setIsLeaveMeeting: (state, action: PayloadAction<boolean>) => {
      state.isLeaveMeeting = action.payload
    },

    // mediaPipeline(Id,Arn)を設定
    setMediaPipeline: (state, action: PayloadAction<MediaPipeline | null>) => {
      state.mediaPipeline = action.payload
    },
  },
})

export const { setIsOrganizer, setIsLeaveMeeting, setMediaPipeline } =
  webMeetingSlice.actions
