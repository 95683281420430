import { apis } from '../apiBase/apiBase';
const attentionApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getAttentions: builder.query({
            query: (params) => ({
                url: `attentions`,
                params: params,
                method: `GET`,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useGetAttentionsQuery, useLazyGetAttentionsQuery } = attentionApis;
