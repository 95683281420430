import { apis } from '../apiBase/apiBase';
const feedApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getFeed: builder.query({
            query: (params) => ({
                url: `feeds`,
                params: params,
                method: 'GET',
            }),
        }),
        getRelatedItemByChatRoomId: builder.query({
            query: (params) => ({
                url: `related-item-by-chatroom-id/${params.chatRoomId}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useLazyGetFeedQuery, useLazyGetRelatedItemByChatRoomIdQuery, useGetRelatedItemByChatRoomIdQuery, } = feedApis;
