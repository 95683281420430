import { apis } from '../apiBase/apiBase';
export const crossSearchApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getRecordsCount: builder.query({
            query: (params) => {
                return {
                    url: `cross-search/records-count`,
                    method: 'GET',
                    params: params,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetRecordsCountQuery, useLazyGetRecordsCountQuery, } = crossSearchApis;
