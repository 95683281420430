import {
  useLazyGetMemberQuery,
  useLazyGetMembersQuery,
} from '@crew/apis/lookup/lookupApis'
import { useDataSource } from './useDataSource'
import { MAX_INITIAL_RECORDS } from 'configs/constants'
import { EntityType } from '@crew/enums/domain'
import { SelfKey, SelfName } from 'utils/filter'
import { useTranslation } from '@crew/modules/i18n'
export const useMemberDataSource = (
  entityType: EntityType | null | undefined,
  entityRecordId: string | null | undefined,
  isEnabledUser?: boolean,
  addMe?: boolean
) => {
  const [lazyGetMembersQuery] = useLazyGetMembersQuery()
  const [lazyGetMemberQuery] = useLazyGetMemberQuery()
  const { t } = useTranslation()

  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        // search with myself
        const mySelf = addMe ? [{ id: SelfKey, displayName: t(SelfName) }] : []
        if (!entityType || !entityRecordId) {
          return mySelf
        }

        if (!loadOptions.searchValue) {
          const response = await lazyGetMembersQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            keyword: undefined,
            limit: MAX_INITIAL_RECORDS,
            isEnabledUser,
          }).unwrap()
          return [...mySelf, ...response.projectMembers]
        }

        const response = await lazyGetMembersQuery({
          entityType: entityType,
          entityRecordId: entityRecordId,
          keyword: loadOptions.searchValue,
          isEnabledUser,
        }).unwrap()
        return [...mySelf, ...response.projectMembers]
      },
      byKey: async (userId) => {
        if (!entityType || !entityRecordId || !userId) {
          return null
        }
        if (userId === SelfKey) return { id: SelfKey, displayName: t(SelfName) }
        const response = await lazyGetMemberQuery({
          entityType: entityType,
          entityRecordId: entityRecordId,
          userId: userId,
          isEnabledUser,
        }).unwrap()

        return response.projectMember
      },
    }),
    [
      entityRecordId,
      entityType,
      isEnabledUser,
      lazyGetMemberQuery,
      lazyGetMembersQuery,
      t,
      addMe,
    ]
  )
}
