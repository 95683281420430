import { memo } from 'react'
import { usePersonalSettingSecurityPanel } from './usePersonalSettingSecurityPanel'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { ChangeMailForm } from './components/changeMailForm/changeMailForm'
import { ChangePasswordForm } from './components/changePasswordForm/changePasswordForm'
import { TwoFactorForm } from './components/twoFactorForm/twoFactorForm'

export const PersonalSettingSecurityPanel = memo(() => {
  const {
    t,
    isChangeEmailFormOpen,
    handleChangeEmailButtonClick,
    isChangePasswordFormOpen,
    handleChangePasswordButtonClick,
    isTwoFactorFormOpen,
    handleChangeTwoFactorButtonClick,
    account,
    securityAuthenticationType,
    handleClosePasswordForm,
    handleCloseChangeMailForm,
    handleSubmitTwoFactorForm,
    securityTwoFactorAuthDestination,
    securityTwoFactorAuthCountryCode,
  } = usePersonalSettingSecurityPanel()

  return (
    <div className="max-w-2xl mx-auto w-full">
      <div className="flex flex-col gap-y-5">
        {/* メールアドレス */}
        <div className="flex flex-col py-2.5 border-b crew-border-gray">
          <p className="font-bold leading-5">{t('label.mailAddress')}</p>
          <div className="flex justify-between items-center">
            <p className="py-2 leading-5">{account?.email}</p>
            {!isChangeEmailFormOpen && (
              <CrewButton
                text={t('label.change')}
                onClick={handleChangeEmailButtonClick}
                stylingMode="outlined"
              />
            )}
          </div>

          {isChangeEmailFormOpen && (
            <ChangeMailForm onCloseChangeMailForm={handleCloseChangeMailForm} />
          )}
        </div>

        {/* パスワード */}
        <div className="flex flex-col py-2.5 border-b crew-border-gray">
          <p className="font-bold leading-5">{t('label.password')}</p>
          <div className="flex justify-between items-center">
            {/* Fixed display 5 characters* */}
            <p className="py-2 leading-5">*****</p>
            {!isChangePasswordFormOpen && (
              <CrewButton
                text={t('label.change')}
                onClick={handleChangePasswordButtonClick}
                stylingMode="outlined"
              />
            )}
          </div>

          {isChangePasswordFormOpen && (
            <ChangePasswordForm onClosePasswordForm={handleClosePasswordForm} />
          )}
        </div>

        {/* 2要素認証 */}
        <div className="flex flex-col py-2.5 border-b crew-border-gray">
          <p className="font-bold leading-5">
            {t('label.twoFactorAuthentication')}
          </p>
          {!isTwoFactorFormOpen && (
            <div className="flex justify-between items-center">
              {securityAuthenticationType ? (
                <p className="py-2 leading-5">
                  {t('label.securityEnabled', {
                    securityAuthenticationType,
                  })}
                </p>
              ) : (
                <p className="py-2 leading-5">
                  {t('message.personalSetting.notBeenSet')}
                </p>
              )}
              <CrewButton
                text={t('label.change')}
                onClick={handleChangeTwoFactorButtonClick}
                stylingMode="outlined"
              />
            </div>
          )}
          {isTwoFactorFormOpen && (
            <TwoFactorForm
              securityAuthenticationType={securityAuthenticationType}
              emailAddress={account?.email}
              securityTwoFactorAuthDestination={
                securityTwoFactorAuthDestination
              }
              securityTwoFactorAuthCountryCode={
                securityTwoFactorAuthCountryCode
              }
              onSubmit={handleSubmitTwoFactorForm}
            />
          )}
        </div>
      </div>
    </div>
  )
})
