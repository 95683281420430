import { FC, memo, useMemo } from 'react'
import classNames from 'classnames'
import {
  CrewRetryImage,
  CrewRetryImageRetryType,
} from '../crewRetryImage/crewRetryImage'
import { hashCode } from '@crew/utils'
import { NavLink } from 'react-router-dom'
import Lock from '~icons/material-symbols/lock'

// アバターの形状
export const CrewAvatarShapeType = {
  Circle: 'circle',
  Square: 'square',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CrewAvatarShapeType =
  (typeof CrewAvatarShapeType)[keyof typeof CrewAvatarShapeType]

// アバターのサイズ
export const CrewAvatarSize = {
  base: 'base',
  '3xs': '3xs',
  '2xs': '2xs',
  xs: 'xs',
  sm: 'sm',
  lg: 'lg',
  xl: 'xl',
  '2xl': '2xl',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CrewAvatarSize =
  (typeof CrewAvatarSize)[keyof typeof CrewAvatarSize]

type avatarProps = {
  displayName: string
  avatarImage?: string
  className?: string
  imageURL?: string
  shape?: CrewAvatarShapeType // 未指定の場合は、丸型がデフォルト
  size?: CrewAvatarSize // 未指定の場合は、baseがデフォルト
  isPrivate?: boolean
  cacheValue: string | number // キャッシュ対策用。基本は「ID+version」を指定。プロジェクト登録ダイアログのアバター初期表示時など明示的なentityIdがない場合は表示名等を指定する。
  redirectUrl?: string // url when click avatar
}

export const CrewAvatar: FC<avatarProps> = memo((props) => {
  const avatarBaseClassNames = useMemo(() => {
    return 'flex items-center justify-center relative shrink-0'
  }, [])

  const targetSizeClassNames = useMemo(() => {
    if (props.size === CrewAvatarSize.base || props.size === undefined) {
      return 'crew-avatar'
    } else if (props.size === CrewAvatarSize['3xs']) {
      return 'crew-avatar-3xs'
    } else if (props.size === CrewAvatarSize['2xs']) {
      return 'crew-avatar-2xs'
    } else if (props.size === CrewAvatarSize.xs) {
      return 'crew-avatar-xs'
    } else if (props.size === CrewAvatarSize.sm) {
      return 'crew-avatar-sm'
    } else if (props.size === CrewAvatarSize.lg) {
      return 'crew-avatar-lg'
    } else if (props.size === CrewAvatarSize.xl) {
      return 'crew-avatar-xl'
    } else if (props.size === CrewAvatarSize['2xl']) {
      return 'crew-avatar-2xl'
    } else {
      return ''
    }
  }, [props.size])

  const targetShapeClassNames = useMemo(() => {
    if (
      props.shape === CrewAvatarShapeType.Circle ||
      props.shape === undefined
    ) {
      // 丸型
      return 'rounded-full'
    } else if (props.shape === CrewAvatarShapeType.Square) {
      // 四角型
      return 'rounded-md'
    } else {
      return ''
    }
  }, [props.shape])

  const targetSrc = useMemo(() => {
    if (!props.imageURL) {
      return ''
    }

    // キャッシュ対策用の値が数値の場合は文字列に変換
    const cacheValue =
      typeof props.cacheValue === 'string'
        ? props.cacheValue
        : props.cacheValue.toString()

    // 画像URLにキャッシュ対策用のクエリパラメータを付与
    return `${props.imageURL}&cache=${hashCode(cacheValue)}`
  }, [props.imageURL, props.cacheValue])

  const renderAvatar = useMemo(
    () => (
      <div
        title={props.displayName}
        className={classNames(avatarBaseClassNames, props.className)}
      >
        {props.imageURL ? (
          <CrewRetryImage
            key={props.cacheValue}
            src={targetSrc}
            alt={props.displayName}
            className={classNames(
              targetShapeClassNames,
              targetSizeClassNames,
              'object-cover'
            )}
            retryType={CrewRetryImageRetryType.RetryWithSameUrl} // 取得に失敗したら同じURLでリトライする
          />
        ) : props.avatarImage ? (
          <img
            src={props.avatarImage}
            alt={props.displayName}
            className={classNames(
              targetShapeClassNames,
              targetSizeClassNames,
              'object-cover'
            )}
          />
        ) : (
          // imageURL, avatarImage のどちらも設定されていない場合は、アバターの文字のみ表示
          <div>{props.displayName.slice(0, 1)}</div>
        )}
        {props.isPrivate && (
          <Lock
            // アバター表示内に鍵アイコンを表示させるためabsoluteを使用
            className="absolute -bottom-1 -right-1 crew-text-gray-2"
          />
        )}
      </div>
    ),
    [
      avatarBaseClassNames,
      props.avatarImage,
      props.cacheValue,
      props.className,
      props.displayName,
      props.imageURL,
      props.isPrivate,
      targetShapeClassNames,
      targetSizeClassNames,
      targetSrc,
    ]
  )

  return (
    <>
      {props?.redirectUrl ? (
        <NavLink to={props.redirectUrl} end>
          {renderAvatar}
        </NavLink>
      ) : (
        renderAvatar
      )}
    </>
  )
})
