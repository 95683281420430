import { FC, memo } from 'react'
import { Lightbox, Slide } from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Video from 'yet-another-react-lightbox/plugins/video'

export type CrewImagePreviewLightboxProps = {
  slides: Slide[]
  slideIndex: number
  isOpen: boolean
  onClosed: () => void
  canDownload: boolean
}

const ZOOM_CONFIG = {
  maxZoomPixelRatio: 3, // ratio of image pixels to physical pixels at maximum zoom level
  scrollToZoom: true, // enables image zoom via scroll gestures for mouse and trackpad users
}

export const CrewImagePreviewLightbox: FC<CrewImagePreviewLightboxProps> = memo(
  (props) => {
    return (
      <Lightbox
        open={props.isOpen}
        close={props.onClosed}
        plugins={[Video, Captions, Zoom]}
        captions={{ showToggle: true, descriptionTextAlign: 'end' }}
        slides={props.slides}
        index={props.slideIndex}
        zoom={ZOOM_CONFIG}
        className="lightbox-container"
      />
    )
  }
)
