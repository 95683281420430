export const CompareOperator = {
    LessThan: '<',
    LessThanOrEqual: '<=',
    Equal: '==',
    NotEqual: '!=',
    GreaterThanOrEqual: '>=',
    GreaterThan: '>',
};
const ulidMatcher = /^[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}$/i;
export const ULID_ZERO = '00000000000000000000000000';
export const ULID_MAX = 'ZZZZZZZZZZZZZZZZZZZZZZZZZZ';
/**
 * Ulidを比較する
 * @param string ulid
 * @param string ulid
 * @returns a op b
 * @example
 * ```typescript
 * compareUlid(ulidA, ">", ulidB)
 * ```
 */
export const compareUlid = (a, op, b) => {
    // フロントエンド側のulid比較は表示に使うためもので、最悪エラーになってもデータの整合性を破壊するものではない。
    // そのため例外は吐かずログの表示に留めておく
    if (!a.match(ulidMatcher)) {
        console.error('invalid ulid: ' + a);
    }
    if (!b.match(ulidMatcher)) {
        console.error('invalid ulid: ' + b);
    }
    const _a = a.toUpperCase();
    const _b = b.toUpperCase();
    switch (op) {
        case CompareOperator.LessThan:
            return _a < _b;
        case CompareOperator.LessThanOrEqual:
            return _a <= _b;
        case CompareOperator.Equal:
            return _a === _b;
        case CompareOperator.NotEqual:
            return _a !== _b;
        case CompareOperator.GreaterThanOrEqual:
            return _a >= _b;
        case CompareOperator.GreaterThan:
            return _a > _b;
    }
};
