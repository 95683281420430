import { NewsMentionAndReplies, SettingKeyType } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useLoadUserSettings, useUserSetting } from '@crew/states'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewRequireDot } from 'components/elements/crewRequireDot'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import {
  NotificationMentionAndReplies,
  NotificationNewArrivals,
} from 'enums/app'
import { useNotificationPermission } from 'hooks/pushNotification/useNotificationPermission'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { memo, useCallback, useEffect, useMemo } from 'react'
import {
  formInitialValues,
  FormValues,
  usePersonalSettingNotificationForm,
} from './usePersonalSettingNotificationForm'

/**
 * Personal Setting Notification Form
 * @date 8/3/2023 - 12:49:19 PM
 *
 * @type {*}
 */
export const PersonalSettingNotificationForm = memo(() => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    validateRules,
    updateUserSettings,
  } = usePersonalSettingNotificationForm()

  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const [loadUserSettings] = useLoadUserSettings()

  const [permission, requestPermissionAsync] = useNotificationPermission()

  // Get the notify setting for logged in user
  const defaultNewsMentionAndReplies = useUserSetting(
    SettingKeyType.NewsMentionAndReplies,
    NewsMentionAndReplies.All.value
  )
  const defaultNotificationNewArrivals = useUserSetting(
    SettingKeyType.NotificationNewArrivals,
    NotificationNewArrivals.BannerAndEmail.value
  )
  const defaultNotificationMentionAndReplies = useUserSetting(
    SettingKeyType.NotificationMentionAndReplies,
    NotificationMentionAndReplies.BannerAndEmail.value
  )
  const defaultNotificationMeOnlyWhenOffline = useUserSetting(
    SettingKeyType.NotificationMeOnlyWhenOffline,
    'false'
  )

  // Get the list of mention and reply
  const newsMentionAndReplies = useMemo(() => {
    return Object.values(NewsMentionAndReplies).map((item) => {
      return {
        value: item.value,
        text: t(item.text),
      }
    })
  }, [t])

  // [Notification] section
  // Get the list of new arrival
  const notificationNewArrivals = useMemo(() => {
    return Object.values(NotificationNewArrivals).map((item) => {
      return {
        value: item.value,
        text: t(item.text),
      }
    })
  }, [t])

  // Get the list of mention and reply
  const notificationMentionAndReplies = useMemo(() => {
    return Object.values(NotificationMentionAndReplies).map((item) => {
      return {
        value: item.value,
        text: t(item.text),
      }
    })
  }, [t])

  // Display saved settings on the screen
  useEffect(() => {
    formInitialValues.newsMentionAndReply = String(defaultNewsMentionAndReplies)
    formInitialValues.notificationNewArrival = String(
      defaultNotificationNewArrivals
    )
    formInitialValues.notificationMentionAndReply = String(
      defaultNotificationMentionAndReplies
    )
    formInitialValues.notificationMeOnlyWhenOffline =
      defaultNotificationMeOnlyWhenOffline === 'true' ? true : false

    reset({
      ...formInitialValues,
    })
  }, [
    defaultNewsMentionAndReplies,
    defaultNotificationMeOnlyWhenOffline,
    defaultNotificationMentionAndReplies,
    defaultNotificationNewArrivals,
    reset,
  ])

  // Event handle when the Save button is clicked
  const handleSaveSettingButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // Execute update user setting process
        await updateUserSettings(data)

        // Display a toast indicating that the setting update was successful
        success(t('message.personalSetting.saveUserSettingSuccess'))

        // Reload the settings
        loadUserSettings()
      } catch (err) {
        // show error api
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    updateUserSettings,
    success,
    t,
    loadUserSettings,
    showApiErrors,
  ])

  const handlePermissionRequestButtonClick = useCallback(() => {
    requestPermissionAsync()
  }, [requestPermissionAsync])

  return (
    <form className="flex flex-col gap-y-5">
      {/*  通知する内容 */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.whatToNotify')}</p>

        {/* メンションと返信 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.newsMentionAndReply')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="newsMentionAndReply"
            name="newsMentionAndReply"
            className="w-80"
            items={newsMentionAndReplies}
            valueExpr="value"
            displayExpr="text"
            minSearchLength={0}
            showClearButton={false}
            rules={validateRules.newsMentionAndReply}
          />
        </div>
      </div>

      {/* Notification  */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.notificationMethod')}</p>

        {/* Notification NewArrival */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.newArrival')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="notificationNewArrival"
            name="notificationNewArrival"
            className="w-80"
            items={notificationNewArrivals}
            valueExpr="value"
            displayExpr="text"
            minSearchLength={0}
            showClearButton={false}
            rules={validateRules.notificationNewArrival}
          />
        </div>

        {/* Notification Mention And Reply */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.notificationMentionAndReply')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="notificationMentionAndReply"
            name="notificationMentionAndReply"
            className="w-80"
            items={notificationMentionAndReplies}
            valueExpr="value"
            displayExpr="text"
            minSearchLength={0}
            showClearButton={false}
            rules={validateRules.notificationMentionAndReply}
          />
        </div>
        {/* オフライン時のみ通知する */}
        <div className="w-80 ml-auto flex gap-x-2.5 items-center">
          <CrewCheckBoxField
            id="notificationMeOnlyWhenOffline"
            control={control}
            name="notificationMeOnlyWhenOffline"
            label={t('label.notificationMeOnlyWhenOffline')}
            className="crew-text-default"
          />
        </div>

        {/* バナーの許可 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.allowBannerNotification')}
          </span>

          <div className="w-80 ml-auto flex gap-x-2.5 items-center">
            {permission === 'default' ? ( // permissionが未確認の場合のみdialogを表示することができる
              <>
                <span className="crew-text-default grow">
                  {t(`label.pushPermissions.${permission}`)}
                </span>
                <CrewButton
                  type="normal"
                  stylingMode="outlined"
                  text={t('action.configureNotification')}
                  onClick={handlePermissionRequestButtonClick}
                />
              </>
            ) : (
              <div className="crew-text-default grow">
                <p>{t(`label.pushPermissions.${permission}`)}</p>
                <p>{t(`label.canChangeInBrowserSetting`)}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Action Save*/}
      <CrewButton
        onClick={handleSaveSettingButtonClick}
        text={t('action.toSave')}
        type="primary"
        className="mr-auto"
      />
    </form>
  )
})
