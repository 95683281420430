import { useTranslation } from '@crew/modules/i18n'
import { HomeMenuItems } from 'enums/app'
import { FC, memo, useCallback } from 'react'
import { SideMenuItem } from './components/sideMenuItem/sideMenuItem'
import { strictTypeObjectEntries } from '@crew/utils'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'

export const SideMenuList: FC = memo((props) => {
  const { t } = useTranslation()

  // 「新着」を表示するかどうかの個人設定値
  const showNewArrival = useUserSetting(SettingKeyType.ShowNewArrival)
  // 「マイスケジュール」を表示するかどうかの個人設定値
  const showSchedule = useUserSetting(SettingKeyType.ShowMySchedule)
  // 「マイタスク」を表示するかどうかの個人設定値
  const showMyTask = useUserSetting(SettingKeyType.ShowMyTask)

  // サイドメニューのアイテムを描画する
  // 個人設定に応じて表示非表示の制御も行う
  const renderSideMenuItems = useCallback(() => {
    return strictTypeObjectEntries(HomeMenuItems).map(([key, item]) => {
      // TODO: 表示するかどうかをbooleanで扱う項目が文字列として処理しているため、以下でリファクタ予定
      // 現在の仕様だと、「!showNewArrival」のような指定だとfalseの時に正しく動作しない
      // https://break-tmc.atlassian.net/browse/CREW-14819

      // 個人設定で「新着」を表示しない設定の場合は非表示とする
      if (item === HomeMenuItems.NewArrival && showNewArrival === 'false') {
        return null
      }
      // 個人設定で「マイスケジュール」を表示しない設定の場合は非表示とする
      if (item === HomeMenuItems.Schedule && showSchedule === 'false') {
        return null
      }
      // 個人設定で「マイタスク」を表示しない設定の場合は非表示とする
      if (item === HomeMenuItems.MyTask && showMyTask === 'false') {
        return null
      }

      return (
        <SideMenuItem
          icon={item.icon}
          label={t(item.label)}
          to={item.path}
          value={item.value}
          key={key}
        />
      )
    })
  }, [showSchedule, showMyTask, showNewArrival, t])

  return <ul className="mb-2">{renderSideMenuItems()}</ul>
})
