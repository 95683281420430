import {
  ComponentProps,
  FC,
  forwardRef,
  memo,
  PropsWithChildren,
  useMemo,
} from 'react'
import { SelectBox } from 'devextreme-react'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { CrewTextBox } from 'components/devextreme/crewTextBox'

type FieldRenderProps = {
  id?: string
  'data-testid'?: string
  displayColor: string
  name: string
}

// フィールド表示のレンダリング。renderとして使うのでmemo不可
const BadgeSelectBoxField: FC<FieldRenderProps> = (data) => (
  <div
    id={data.id}
    data-testid={data['data-testid']}
    className="dx-texteditor-input py-0 flex items-center"
  >
    {data && (
      <>
        <CrewBadge displayColor={data.displayColor}>{data.name}</CrewBadge>
        <div className="hidden">
          <CrewTextBox defaultValue={data.name} readOnly={true} />
        </div>
      </>
    )}
  </div>
)

type ItemRenderProps = {
  displayColor: string
  name: string
}

// リスト項目のレンダリング。renderとして使うのでmemo不可
const BadgeSelectBoxItem: FC<ItemRenderProps> = (data) =>
  data && <CrewBadge displayColor={data.displayColor}>{data.name}</CrewBadge>

type Props = PropsWithChildren<ComponentProps<typeof SelectBox>> & {
  'data-testid'?: string
}

/**
 * バッジ選択用のSelectBoxラッパー（react-hook-form非対応版）
 */
export const CrewBadgeSelectBox = memo(
  forwardRef<SelectBox, Props>(({ children, ...rest }, ref) => {
    const fieldRender = useMemo(() => {
      return (params: { name: string; displayColor: string }) => (
        <BadgeSelectBoxField
          id={rest.id}
          data-testid={rest['data-testid']}
          name={params?.name}
          displayColor={params?.displayColor}
        />
      )
    }, [rest])

    return (
      <SelectBox
        {...rest}
        ref={ref}
        // NOTE: ただのSelectBoxとして使用する際、余計なお世話となる↓
        labelMode={rest.labelMode ?? 'hidden'}
        displayExpr={rest.displayExpr ?? 'name'}
        valueExpr={rest.valueExpr ?? 'id'}
        searchEnabled={rest.searchEnabled ?? false}
        searchExpr={rest.searchExpr ?? 'key'}
        searchTimeout={rest.searchTimeout ?? SEARCH_TIMEOUT_MSEC}
        // カスタムドロー部分のレンダリング
        fieldRender={fieldRender}
        itemRender={BadgeSelectBoxItem}
        showClearButton={rest.showClearButton ?? true}
      >
        {children}
      </SelectBox>
    )
  })
)
