import { apis } from '../apiBase/apiBase';
export const roleApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        deleteRole: builder.mutation({
            query: (params) => ({
                url: `roles/delete`,
                method: `POST`,
                body: params,
            }),
        }),
        updateRoles: builder.mutation({
            query: (params) => ({
                url: `roles/update`,
                method: `POST`,
                body: params,
            }),
        }),
        getRoles: builder.query({
            query: (params) => {
                return {
                    url: `roles`,
                    params: params,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useDeleteRoleMutation, useUpdateRolesMutation, useGetRolesQuery, useLazyGetRolesQuery, } = roleApis;
