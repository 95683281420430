import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useForm } from 'react-hook-form'
import {
  useInsertChatMessageMutation,
  useUpdateChatMessageMutation,
} from '@crew/apis/chat/chatApis'
import { UploadFile } from 'models/domain/uploadFile'
import { MessageType } from '@crew/enums/dist/domain'
import { sanitizeHtmlAllowNothing } from 'utils/html'
import { useChatDraftService } from '@crew/states'
import { useAppDispatch } from 'states/hooks'
import { ChatMessage } from '@crew/models/domain'

export type FormValues = {
  chatMessage: string
}

export const useCrewChatMessageEditor = (
  threadRootMessageId: string | undefined,
  chatRoomId: string | null,
  message: ChatMessage | undefined,
  isEditMode: boolean
) => {
  const [insertChatMessageMutation] = useInsertChatMessageMutation()
  const [updateChatMessageMutation] = useUpdateChatMessageMutation()

  const dispatch = useAppDispatch()
  const { saveChatDraft, deleteChatDraft } = useChatDraftService(dispatch)

  /**
   * 下書きの取得先ID
   * 下書きは新規投稿時のみ保存されるため、トピックIDまたはチャットルームIDを設定する
   *
   *  threadRootMessageIdが設定されている場合：スレッド形式と判断し、threadRootMessageIdを取得先IDとする
   *  threadRootMessageIdが設定されていない場合：タイムライン形式またはスレッドリスト形式と判断し、chatRoomIdを取得先IDとする
   */
  const draftTargetId = (threadRootMessageId ?? chatRoomId) as string // 新規投稿時、トピックIDまたはチャットルームIDどちらかは必ず設定される想定のため、as stringでキャスト

  // 現在の下書きの取得先ID
  const [currentDraftTargetId, setCurrentDraftTargetId] =
    useState(draftTargetId)

  const defaultValues: FormValues = {
    chatMessage: message?.text ?? '',
  }

  const { control, handleSubmit, reset, formState, getValues, setError } =
    useForm<FormValues>({
      defaultValues: defaultValues,
      criteriaMode: 'all',
    })

  const { t } = useTranslation()

  // 添付ファイル（アップロードファイル）情報格納用の配列
  const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])

  const messageRequiredValidateRule = useCallback(() => {
    const message = getValues('chatMessage')
    // get plain text from html
    const plainText = sanitizeHtmlAllowNothing(message)
    if (!plainText && uploadedFileList.length === 0) return false

    return true
  }, [getValues, uploadedFileList.length])

  // バリデーションルール（チャットメッセージ）
  const chatMessageValidateRule = useMemo(() => {
    return {
      validate: {
        always: () =>
          messageRequiredValidateRule() || t('message.general.required'),
      },
    }
  }, [messageRequiredValidateRule, t])

  // update message
  const updateMessage = useCallback(
    async (
      data: FormValues,
      chatMessageId: string,
      version: number,
      mentionUserIds: string[] | null
    ) => {
      await updateChatMessageMutation({
        chatMessage: {
          chatMessageId,
          text: data.chatMessage,
          version,
        },
        mentionUserIds,
      }).unwrap()
    },
    [updateChatMessageMutation]
  )

  // insert message
  const insertMessage = useCallback(
    async (
      data: FormValues,
      messageType: MessageType,
      chatRoomId: string | undefined,
      parentChatMessageId: string | undefined,
      uploadedFileList: UploadFile[] | null,
      mentionUserIds: string[] | null
    ) => {
      await insertChatMessageMutation({
        chatMessage: {
          messageType,
          text: data.chatMessage,
          chatRoomId,
          parentChatMessageId,
          files: uploadedFileList,
        },
        mentionUserIds,
      }).unwrap()
    },
    [insertChatMessageMutation]
  )

  // 下書きをreduxに保存する
  const saveDraft = useCallback(() => {
    // 編集時は下書き保存しないため、何もしない
    if (isEditMode) {
      return
    }

    const message = getValues('chatMessage')
    const plainText = sanitizeHtmlAllowNothing(message)

    if (!plainText && uploadedFileList.length === 0) {
      // 「メッセージが空」かつ「添付ファイルがない」場合：下書きをreduxから削除
      deleteChatDraft(currentDraftTargetId)
    } else {
      // メッセージが入力されているか、添付ファイルがある場合：下書きをreduxに保存
      saveChatDraft({
        id: currentDraftTargetId,
        text: message,
        files: uploadedFileList,
      })
    }
  }, [
    currentDraftTargetId,
    deleteChatDraft,
    getValues,
    isEditMode,
    saveChatDraft,
    uploadedFileList,
  ])

  return {
    control,
    chatMessageValidateRule,
    uploadedFileList,
    setUploadedFileList,
    currentDraftTargetId,
    setCurrentDraftTargetId,
    reset,
    setError,
    handleSubmit,
    updateMessage,
    insertMessage,
    saveDraft,
    deleteChatDraft,
    formState,
  }
}
