import { Modal } from 'components/layouts/modal/modal'
import { FC, memo } from 'react'
import { EditTenantUserEntryForm } from './components/editTenantUserEntryForm/editTenantUserEntryForm'
import { UserType } from '@crew/enums/domain'

export type EditUserDialogProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  userId?: string
  version?: number
  displayName?: string
  roleId?: string
  enabled?: boolean
  userType: UserType | null
  expirationDatetime: string | null
}

export const EditTenantUserEntryDialog: FC<EditUserDialogProps> = memo(
  (props) => {
    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <EditTenantUserEntryForm
          userId={props.userId}
          version={props.version}
          displayName={props.displayName}
          roleId={props.roleId}
          enabled={props.enabled}
          onCancel={props.onClose}
          userType={props.userType}
          expirationDatetime={props.expirationDatetime}
        />
      </Modal>
    )
  }
)
