import { FC, Fragment, memo, useCallback, useState } from 'react'

import { ChatView } from '@crew/enums/app'

import { CrewChatDivider } from 'components/elements/crewChatDivider/crewChatDivider'
import { CrewUnreadLine } from 'components/elements/crewUnreadLine/crewUnreadLine'
import { ChatTimelineMessageListItem } from './components/chatTimelineMessageListItem/chatTimelineMessageListItem'

import {
  DisplayMessageItem,
  MessageItemType,
  useChatTimelineMessageList,
} from './useChatTimelineMessageList'
import { getChatMessageListItemDomId } from 'utils/chat'

type TopicSummaryProps = {
  item: DisplayMessageItem
}

const TopicSummary: FC<TopicSummaryProps> = (props) => {
  // スレッド返信でない場合は再掲トピックを表示しない
  if (props.item.type !== MessageItemType.ReplyMessage) {
    return null
  }
  // 区切り線が表示されない場合は同じスレッドであるか先頭のアイテムのため、再掲トピックを表示しない
  if (!props.item.showDivideLine) {
    return null
  }

  // 再掲トピックを表示する
  return (
    <ChatTimelineMessageListItem
      key={`${props.item.topicId}_${MessageItemType.TopicSummary}`}
      id={`${props.item.topicId}_${MessageItemType.TopicSummary}`}
      type={MessageItemType.TopicSummary} // 再掲トピックとして表示する
      itemId={props.item.id}
      chatMessageId={props.item.topicId}
      topicId={props.item.topicId}
    />
  )
}

export const ChatTimelineMessageList: FC = memo(() => {
  const {
    displayItems,
    itemsScrollableDivRef,
    messageInView,
    messageOutOfView,
  } = useChatTimelineMessageList()

  const [selectedMessageId, setSelectedMessageId] = useState<
    string | undefined
  >(undefined)

  // メッセージ全体表示イベントハンドラ
  const handleMessageInView = useCallback(
    (inView: boolean, id: string, messageId: string) => {
      if (inView) {
        messageInView(id, messageId)
      } else {
        messageOutOfView(id)
      }
    },
    [messageInView, messageOutOfView]
  )

  return (
    <div
      className="grow flex overflow-y-scroll flex-col-reverse"
      ref={itemsScrollableDivRef}
    >
      <div className="grow flex flex-col">
        {displayItems.map((item) => (
          <Fragment key={item.id}>
            {item.showDivideLine && <CrewChatDivider />}
            <CrewUnreadLine show={item.showUnreadLine} />

            {/* 再掲トピックの表示制御 */}
            <TopicSummary item={item} />

            <ChatTimelineMessageListItem
              key={item.id}
              // スクロール位置制御のためChatViewを含めたidとして指定する
              id={getChatMessageListItemDomId(
                item.messageId,
                ChatView.Timeline
              )}
              type={item.type}
              itemId={item.id}
              chatMessageId={item.messageId}
              topicId={item.topicId}
              container={itemsScrollableDivRef}
              onMessageInView={handleMessageInView}
              onAdditionalLoading={item.handleAdditionalLoading}
              onFirstUnreadMessageViewed={item.handleFirstUnreadMessageViewed}
              selectedItemId={selectedMessageId}
              setSelectedItemId={setSelectedMessageId}
              isFirstUnreadMessage={item.isFirstUnreadMessage}
            />
          </Fragment>
        ))}
      </div>
    </div>
  )
})
