import { useDeleteTwoFactorAuthMutation } from '@crew/apis/setting/settingApis'
import { TwoFactorAuthenticationType } from 'enums/app'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export type FormValues = {
  securityAuthenticationType: string
}
const formInitialValues: FormValues = {
  securityAuthenticationType: TwoFactorAuthenticationType.App.value,
}

export const useTwoFactorForm = () => {
  const { control, reset } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Get function to delete two factor auth
  const [deleteTwoFactorAuthMutation] = useDeleteTwoFactorAuthMutation()

  // Delete two factor authentication process
  const deleteTwoFactorAuth = useCallback(async () => {
    // call api delete two factor
    await deleteTwoFactorAuthMutation().unwrap()
  }, [deleteTwoFactorAuthMutation])

  return {
    control,
    reset,
    deleteTwoFactorAuth,
  }
}
