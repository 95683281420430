import { FC, memo, useCallback, useMemo } from 'react'

import { useCanAccessChatRoomQuery } from '@crew/apis/chat/chatApis'
import { ChatView } from '@crew/enums/app'
import { Chat } from '@crew/states'
import { skipToken } from '@reduxjs/toolkit/query'
import { useAppSelector } from 'states/hooks'
import { ChatSearch } from '../chatSearch/chatSearch'
import { ChatThread } from '../chatThread/chatThread'
import { ChatThreadList } from '../chatThreadList/chatThreadList'
import { ChatTimeline } from '../chatTimeline/chatTimeline'

/**
 * イベント詳細画面やタスク詳細画面で右に表示されるコンパクト形式のチャットパネル
 * Searchモード
 * - ChatSearchを表示する
 * Chatモード
 * - Timeline,ThreadList,InThreadのいずれかを表示する
 *   - 対象チャットルームのtopicIdがあればInThread形式表示
 *   - 対象チャットルームのtopicIdがなければsliceの値に応じてTimeline形式かThreadList形式で表示
 */
export const ChatCompactPanel: FC = memo(() => {
  // チャットのトピック表示形式を取得する
  const currentDisplayFormat = useAppSelector(
    (state) => state.message.chat.current.displayFormat
  )

  // 表示対象のトピックIDを取得する
  const currentChatThread = useAppSelector(
    (state) => state.message.chat.current.chatThread
  )
  const currentTopicId = currentChatThread?.topicId

  // トピックIDが指定されていればInThread表示、そうでない場合は個人設定値で設定されたsliceの値をもとに表示する
  const currentChatView = currentTopicId
    ? ChatView.Thread
    : currentDisplayFormat

  // 表示対象のチャットルームを取得する
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )

  // トピックIDが指定されている場合はchatThread側のchatRoomIdを参照し、そうでない場合はchatRoom側のidを参照する
  const currentChatRoomId =
    currentChatView === ChatView.Thread
      ? currentChatThread?.chatRoomId
      : currentChatRoom?.id

  // モードを取得する
  // この値をもとに、検索コンポーネントと各チャット表示のコンポーネントを切り替える
  const currentMode = useAppSelector((state) => state.message.chat.current.mode)

  // このチャットルームのプロジェクトのメンバーかどうかを確認する
  const canAccessChatRoomParams = currentChatRoomId
    ? {
        chatRoomId: currentChatRoomId,
      }
    : undefined

  const { data } = useCanAccessChatRoomQuery(
    canAccessChatRoomParams ?? skipToken
  )

  // プロジェクトのメンバーであれば、アクセス可能としてチャットを表示する
  const canAccessChatRoom = useMemo(() => {
    const canAccess = data?.canAccess
    if (canAccess === undefined) {
      return false
    } else {
      return canAccess
    }
  }, [data?.canAccess])

  // render chat panel by current mode and view
  const renderChatPanel = useCallback(() => {
    // 現在のモードによって表示するコンポーネントを切り替える
    switch (currentMode) {
      case Chat.Mode.Search:
        // 検索モード
        return <ChatSearch />
      case Chat.Mode.Chat:
        // チャットモード
        // 表示するViewに応じて表示するコンポーネントを切り替える
        switch (currentChatView) {
          // InThread形式
          case ChatView.Thread:
            return currentTopicId && <ChatThread showBackButton={true} />
          // Timeline形式
          case ChatView.Timeline:
            return <ChatTimeline />
          // ThreadList形式
          case ChatView.ThreadList:
            return <ChatThreadList />
          default:
            return null
        }
      default:
        return null
    }
  }, [currentChatView, currentMode, currentTopicId])

  // 表示対象のチャットルームが設定されていない場合はチャットが存在していない旨のメッセージを表示する
  if (!currentChatRoomId) {
    return null
  }

  // チャットルームにアクセス権限がない
  if (!canAccessChatRoom) {
    return null
  }

  // Chat panel
  return renderChatPanel()
})
