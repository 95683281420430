import { ProjectRef } from '@crew/models/refs'
import dayjs from '@crew/modules'
import { convertMinutesToHHMM } from '@crew/utils'
import classNames from 'classnames'
import { FC, memo, useCallback, useMemo } from 'react'
import { WorkingTimeItem } from './components/workingTimeItem/workingTimeItem'
import { useTranslation } from '@crew/modules/i18n'

const MAX_DISPLAY_WORK_TIMES = 3 // 表示する件数は3件まで

export type WorkTime = {
  taskId: string
  project: ProjectRef
  subject: string
  workTimesId: string
  actualWorkTimes: number
  workDate: string
  version: number
}

type DateCellDataProps = {
  date: Date
  isOtherMonth: boolean
  data: WorkTime[]
  onDateCellClick: (workDate: Date) => void
}

export const DateCellData: FC<DateCellDataProps> = memo((props) => {
  const { t } = useTranslation()

  const totalWorkTimes = props.data.reduce((acc, cur) => {
    return acc + (cur.actualWorkTimes ?? 0)
  }, 0)

  // 表示する件数は3件までとし、それを越える場合は「その他x件」と表示する
  const displayData = useMemo(
    () => props.data.slice(0, MAX_DISPLAY_WORK_TIMES),
    [props.data]
  )
  const otherData = useMemo(
    () => props.data.slice(MAX_DISPLAY_WORK_TIMES),
    [props.data]
  )

  // 日付セルクリック時の処理
  const handleDateCellClick = useCallback(() => {
    props.onDateCellClick(props.date)
  }, [props])

  return (
    // date cell
    <div className="w-full h-full" onClick={handleDateCellClick}>
      <div className="flex flex-row items-center justify-between mb-1">
        {/* day of month */}
        <span className={classNames(props.isOtherMonth && 'crew-text-gray-5')}>
          {dayjs(props.date).format('D')}
        </span>

        {/* total work times of day */}
        {props.data.length > 0 && (
          <span className="font-bold">
            {convertMinutesToHHMM(totalWorkTimes)}
          </span>
        )}
      </div>

      <div className="flex flex-col gap-0.5">
        {/* Display list working times */}
        {displayData.map((workTime) => (
          <WorkingTimeItem key={workTime.workTimesId} workTime={workTime} />
        ))}

        {/* 表示する件数は3件までとし、それを越える場合は「その他x件」と表示する */}
        {otherData.length > 0 && (
          <p className="text-xs">
            {t('label.otherWorkTimes', {
              count: otherData.length,
            })}
          </p>
        )}
      </div>
    </div>
  )
})
