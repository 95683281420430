import classNames from 'classnames'
import { FC, memo, useMemo } from 'react'

import { useTranslation } from '@crew/modules/i18n'

import { useCrewMessageReplyButton as useCrewMessageReplyWithCountButton } from './useCrewMessageReplyWithCountButton'

type Props = {
  topicId: string
  replyCount: number
  onClick: () => void
}

export const CrewMessageReplyWithCountButton: FC<Props> = memo((props) => {
  const { total, toMe, toGroup } = useCrewMessageReplyWithCountButton(
    props.topicId
  )

  const { t } = useTranslation()

  const replyButtonColor = useMemo(
    () =>
      total === 0
        ? 'crew-action-normal' // 未読無し
        : toMe > 0
        ? 'crew-badge-red-inverted hover:opacity-80' // 赤：ユーザー個人宛のメンションがある場合
        : toGroup > 0
        ? 'crew-badge-orange-inverted hover:opacity-80' // オレンジ：ユーザが属するグループ宛のメンションがある場合。@allや@hereなど
        : 'crew-badge-blue-inverted hover:opacity-80', // 青：上記以外
    [total, toMe, toGroup]
  )

  return (
    <span
      className={classNames(
        'crew-button-sm cursor-pointer break-normal',
        replyButtonColor
      )}
      onClick={props.onClick}
    >
      {t('message.chat.replyCount', {
        count: props.replyCount,
      })}
    </span>
  )
})
