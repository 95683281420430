import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { Chart } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof Chart>>
export const CrewChart = memo(
  forwardRef<Chart, Props>(({ children, ...rest }, ref) => (
    <Chart ref={ref} {...rest}>
      {children}
    </Chart>
  ))
)
