import { memo } from 'react'
import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core'
import type { DropAnimation } from '@dnd-kit/core'

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
}

type CrewSortableOverlayProps = {
  children?: React.ReactNode
}

export const CrewSortableOverlay: React.FC<CrewSortableOverlayProps> = memo(
  (props) => {
    return (
      <DragOverlay dropAnimation={dropAnimationConfig}>
        {props.children}
      </DragOverlay>
    )
  }
)
