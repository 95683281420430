import { CrewFilePreview } from 'components/elements/crewFilePreview/crewFilePreview'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

export const FileDetailPreview = memo(() => {
  const { fileId } = useParams()
  return fileId ? (
    <div className="grow min-h-0 w-full flex flex-col gap-y-2 px-2 h-full">
      <CrewFilePreview fileId={fileId} />
    </div>
  ) : (
    <></>
  )
})
