import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_HIGH, KEY_ESCAPE_COMMAND, LexicalNode } from 'lexical'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import classNames from 'classnames'
import Close from '~icons/material-symbols/close'
import ShortText from '~icons/material-symbols/short-text'
import CardText from '~icons/mdi/card-text'
import { createPortal } from 'react-dom'
import { CONVERT_TO_LINK_CARD_COMMAND } from '../linkCardPlugin/linkCardPlugin'
import { sanitizeUrl } from '../../utils/lexical'

type AutoLinkEditorProps = {
  isOpen: boolean
  anchorNode: LexicalNode
  anchorElement?: Element
  url: string
  onClose: () => void
}

export function AutoLinkEditor({
  isOpen,
  anchorNode,
  anchorElement,
  url,
  onClose,
}: AutoLinkEditorProps) {
  const [editor] = useLexicalComposerContext()
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const referenceElement = useMemo(
    () => anchorElement ?? editor.getElementByKey(anchorNode.getKey()),
    [anchorElement, anchorNode, editor]
  )
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  })

  console.log('AutoLinkEditor', isOpen, anchorNode, anchorElement, url)

  const handleLinkCardButtonClick = useCallback(() => {
    console.log('handleLinkCardButtonClick', sanitizeUrl(url))
    editor.dispatchCommand(CONVERT_TO_LINK_CARD_COMMAND, sanitizeUrl(url))
    onClose()
  }, [editor, onClose, url])

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          console.log('KEY_ESCAPE_COMMAND')
          onClose()
          return true
        },
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [editor, onClose])

  return (
    <>
      {isOpen &&
        createPortal(
          <div className="relative">
            <div className="fixed inset-0 z-10" onClick={onClose}></div>
            <div
              style={{ ...styles.popper }}
              {...attributes.popper}
              className={classNames(
                'flex overflow-hidden rounded items-center p-1 text-sm shadow crew-shadow-gray crew-bg-default',
                // because modal has z-60, so we need to set z-70 to make sure it's on top of modal
                'z-70'
              )}
              ref={setPopperElement}
            >
              <div
                className="link-text flex items-center p-1 rounded crew-bg-gray-2 crew-hover-gray-3"
                role="button"
              >
                <ShortText width={24} height={24} />
                <span>リンク</span>
              </div>
              <div
                className="link-card flex items-center p-1 rounded crew-hover-gray-3"
                role="button"
                onMouseDown={(event) => event.preventDefault()}
                onClick={handleLinkCardButtonClick}
              >
                <CardText width={24} height={24} />
                <span>カード</span>
              </div>
              <div
                className="link-cancel flex items-center p-1 rounded crew-hover-gray-3"
                role="button"
                tabIndex={0}
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                  onClose()
                }}
              >
                <Close width={24} height={24} />
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  )
}
