import { FC, memo, useMemo } from 'react'
import classNames from 'classnames'
import { TaskPriorities } from 'enums/app'
import { useTranslation } from '@crew/modules/i18n'

type Props = {
  taskPriority: TaskPriorities
}

export const CrewTaskPriorityIcon: FC<Props> = memo((props) => {
  const { t } = useTranslation()

  const priorityIcon = useMemo(() => {
    const SvgIcon = props.taskPriority.iconClass
    return (
      <span
        className={classNames(props.taskPriority.iconColorClass)}
        title={t(props.taskPriority.text)}
      >
        <SvgIcon width={20} height={20} />
      </span>
    )
  }, [
    props.taskPriority.iconClass,
    props.taskPriority.iconColorClass,
    props.taskPriority.text,
    t,
  ])

  return priorityIcon
})
