import { useMemo } from 'react'

import { useTranslation } from '@crew/modules/dist/i18n'

import {
  BackgroundEffect,
  useBackgroundEffect,
} from '../backgroundEffectProvider'

export const useCrewBackgroundEffectSelectBox = () => {
  const { t } = useTranslation()

  const {
    isBackgroundEffectSupported,
    backgroundEffect,
    selectBackgroundEffect,
  } = useBackgroundEffect()

  const effectList = useMemo(
    () =>
      Object.values(BackgroundEffect).map((name) => ({
        value: name,
        display: t(`label.${name}`),
      })),
    [t]
  )

  return {
    backgroundEffect,
    selectBackgroundEffect,
    effectList,
    isBackgroundEffectSupported,
  }
}
