import { forwardRef, memo } from 'react'
import classNames from 'classnames'
import { ButtonType } from 'enums/app'
import KeyboardArrowDown from '~icons/material-symbols/keyboard-arrow-down'

type ButtonProps = {
  disabled?: boolean
  isApplied?: boolean
  className?: string
  type?: string
  icon?: React.ReactNode
  onHandleButtonClick: () => void
  buttonLabel?: string
  showDropDownIcon?: boolean
}

/**ボタン表示コンポーネント */
export const ToolbarButton = memo(
  forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const buttonType = props.type || ButtonType.Normal

    return (
      <button
        ref={ref}
        disabled={props.disabled || false}
        onClick={props.onHandleButtonClick}
        // フォーカスが移動するのを防止する
        // これがないと正しい位置にポップアップが出ない
        // セレクションが外れてしまうから？
        onMouseDown={(event) => event.preventDefault()}
        className={classNames(
          'h-full border-none rounded',
          {
            'align-middle': buttonType !== ButtonType.More,
          },
          {
            'absolute w-6 !h-6 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2':
              buttonType === ButtonType.More,
          },
          {
            'crew-hover-gray-2 p-1':
              buttonType === ButtonType.Normal ||
              buttonType === ButtonType.More,
          },
          {
            'crew-action-default': buttonType === ButtonType.Action,
          },
          {
            'crew-bg-gray-2': props.isApplied,
          },
          props.disabled && 'pointer-events-none opacity-70',
          props.className
        )}
        type="button"
      >
        <div className="flex justify-center items-center gap-x-1">
          {props.icon}
          {props.buttonLabel && (
            <div className="text whitespace-nowrap">{props.buttonLabel}</div>
          )}
          {props.showDropDownIcon && <KeyboardArrowDown />}
        </div>
      </button>
    )
  })
)
