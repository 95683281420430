import { useEffect } from 'react'

/**
 * useFocusInput
 * @param id id input need focus
 */
export const useFocusInput = (id: string) => {
  useEffect(() => {
    const el = document.getElementById(id)
    el?.focus()
  }, [id])
}
