import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { FC, memo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { useProjectSettingTaskCategoryEntryForm } from './useProjectSettingTaskCategoryEntryForm'
import { useLazyGetTaskCategoryQuery } from '@crew/apis/task/taskApis'
import { useTranslation } from '@crew/modules/i18n'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { FormValues } from './useProjectSettingTaskCategoryEntryForm'

export type ProjectSettingTaskCategoryFormProps = {
  isEditMode: boolean
  taskCategoryId: string | null
  onSubmit: () => void
  onCancel: () => void
}

export const ProjectSettingTaskCategoryEntryForm: FC<ProjectSettingTaskCategoryFormProps> =
  memo((props) => {
    const {
      control,
      reset,
      clearErrors,
      setError,
      formState,
      handleSubmit,

      validateRules,

      insertTaskCategory,
      updateTaskCategory,
    } = useProjectSettingTaskCategoryEntryForm()

    const { t } = useTranslation()
    const toast = useToast()

    const { projectId } = useParams()

    const [taskCategoryVersion, setTaskCategoryVersion] = useState(1) // default version is 1

    const [lazyGetTaskCategoryQuery] = useLazyGetTaskCategoryQuery()

    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    useEffect(() => {
      //initialization form data when open project setting task category edit
      const initData = async (taskCategoryId: string) => {
        const result = await lazyGetTaskCategoryQuery({
          taskCategoryId,
        }).unwrap()

        if (result.taskCategory) {
          setTaskCategoryVersion(result.taskCategory?.version)

          reset({
            name: result.taskCategory.name,
          })
        }
      }

      if (props.taskCategoryId) {
        initData(props.taskCategoryId)
      }
    }, [lazyGetTaskCategoryQuery, reset, props.taskCategoryId])

    // Press 登録 button
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        if (!projectId) return

        try {
          if (props.isEditMode) {
            if (props.taskCategoryId) {
              // Execute update task category process
              await updateTaskCategory(
                props.taskCategoryId,
                data.name,
                taskCategoryVersion
              )

              toast.success(t('message.taskCategory.taskCategoryUpdated'))
            }
          } else {
            if (data.name) {
              // Execute insert task category process
              await insertTaskCategory(projectId, data.name)

              toast.success(t('message.taskCategory.taskCategoryAdded'))
            }
          }
          reset()
          clearErrors()
          props.onSubmit && props.onSubmit()
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [
      handleSubmit,
      projectId,
      props,
      reset,
      clearErrors,
      updateTaskCategory,
      taskCategoryVersion,
      toast,
      t,
      insertTaskCategory,
      showApiErrors,
    ])

    // Press キャンセル button
    const handleCancelButtonClick = useCallback(() => {
      props.onCancel()
    }, [props])

    // 投稿可能か
    const canSend = useMemo(
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      [formState]
    )

    // Form内Enterキー押下によるSubmit制御
    // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
    //       https://break-tmc.atlassian.net/browse/CREW-3338
    const handleFormSubmit = useCallback<FormEventHandler>((event) => {
      event.preventDefault()
    }, [])

    return (
      <form
        className="flex flex-col gap-y-5 h-full"
        // Form内Enterキー押下によるSubmit制御
        // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
        //       https://break-tmc.atlassian.net/browse/CREW-3338
        onSubmit={handleFormSubmit}
      >
        <CrewScrollView>
          <div className="flex flex-col gap-y-2.5">
            <CrewTextBoxField
              id="name"
              name="name"
              labelMode="hidden"
              label={t('label.name')}
              control={control}
              required={true}
              rules={validateRules.name}
            />
            <CrewErrorSummary formState={formState} />
          </div>
        </CrewScrollView>
        <div className="flex justify-end gap-x-2.5">
          <CrewButton
            text={t('action.register')}
            type="primary"
            onClick={handleSubmitButtonClick}
            disabled={!canSend}
          />
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </form>
    )
  })
