import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { memo, useCallback, useMemo } from 'react'
import { useEventDetailAttendeesEntryForm } from './useEventDetailAttendeesEntryForm'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useToast } from 'hooks/useToast'
import { useParams } from 'react-router-dom'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useTranslation } from '@crew/modules/dist/i18n'
import { FormValues } from './useEventDetailAttendeesEntryForm'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
export type EventDetailAttendeesEntryFormProps = {
  onClose: () => void
}

export const EventDetailAttendeesEntryForm = memo(
  (props: EventDetailAttendeesEntryFormProps) => {
    const {
      handleSubmit,
      control,
      setError,
      formState,
      validateRules,
      eventAttendeeDataSource,
      insertEventAttendees,
    } = useEventDetailAttendeesEntryForm()

    const { t } = useTranslation()
    const toast = useToast()

    const { eventId } = useParams()

    const canSend = useMemo(
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      [formState]
    )

    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    // Event handler when registration button is clicked
    const handleSubmitButtonClick = useCallback(() => {
      // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
      const onSubmit = async (data: FormValues) => {
        try {
          // Execute insert attendees process
          await insertEventAttendees(eventId as string, data)

          //close dialog
          props.onClose()

          toast.success(t('message.meeting.addAttendeesSuccess'))
        } catch (err) {
          showApiErrors(err)
        }
      }

      // ファイル更新実行
      handleSubmit(onSubmit)()
    }, [
      handleSubmit,
      insertEventAttendees,
      eventId,
      props,
      toast,
      t,
      showApiErrors,
    ])

    return (
      <form>
        <div className="flex flex-col gap-2.5">
          <CrewTagBoxField
            id="userIds"
            name="userIds"
            control={control}
            displayExpr="displayName"
            valueExpr="id"
            dataSource={eventAttendeeDataSource}
            searchEnabled={true}
            searchMode="contains"
            searchExpr="displayName"
            searchTimeout={SEARCH_TIMEOUT_MSEC}
            minSearchLength={0}
            showLabel={false}
            rules={validateRules.userIds}
          />

          {/* チャットに投稿する */}
          <CrewCheckBoxField
            id="needNotification"
            name="needNotification"
            control={control}
            label={t('label.needNotification')}
          />
        </div>

        {/* Dialog form action */}
        <div className="flex justify-end gap-x-3 mt-5">
          <CrewButton
            text={t('action.addToAttendees')}
            type="primary"
            onClick={handleSubmitButtonClick}
            disabled={!canSend}
          />

          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={props.onClose}
          />
        </div>
      </form>
    )
  }
)
