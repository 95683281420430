import { getImageData, tag as emojiTag } from '@crew/emoji-data'
import { memo } from 'react'

type EmojiPickerCategoryItemProps = {
  category: string
  icon: string
  index: number
  active: boolean
  handleGoToCategory: (categoryIndex: number) => void
}

export const EmojiCategoryItem = memo((props: EmojiPickerCategoryItemProps) => {
  const validEmoji = Boolean(getImageData(props.icon))

  const className = validEmoji
    ? `${emojiTag.name} ${emojiTag.className}`
    : undefined

  const innerText = validEmoji ? '' : '〓'

  // 絵文字のshortNameをcustom data attributeとして埋め込む
  const customData = {
    [`data-${emojiTag.shortNameCustomDataAttributeName}`]: props.icon,
  }
  return (
    <div
      className={`border-b-2 border-solid pb-2 w-full transition-all cursor-pointer ${
        props.active
          ? 'border-crew-blue-4-light dark:border-crew-blue-4-dark'
          : 'border-crew-gray-2-light dark:border-crew-gray-2-dark'
      }}`}
      onClick={() => props.handleGoToCategory(props.index)}
    >
      <span
        className={className}
        {...customData}
        style={{
          display: 'block',
          width: '20px',
          height: '20px',
          margin: '0 auto',
        }}
      >
        {innerText}
      </span>
    </div>
  )
})
