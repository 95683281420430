import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  TableOptions,
  getCoreRowModel,
} from '@tanstack/react-table'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { CrewUserItem } from 'components/elements/crewUserItem/crewUserItem'
import { Project } from '@crew/apis/project/models/getProjects/response'
import { CrewProgressBarItem } from 'components/elements/crewProgressBar/crewProgressBarItem'
import { CrewProjectNameItem } from 'components/elements/crewProjectNameItem/crewProjectNameItem'
import {
  ProjectListPanelDisplayMode,
  ProjectScope,
  ProjectSearchOptions,
} from 'enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { useNavigate, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import {
  DEFAULT_PAGING_PARAMS,
  PROJECT_DEFAULT_SORT_COLUMN,
} from 'configs/constants'
import { getParamAsArray, getParamAsDate, getParamAsString } from 'utils'
import { GetProjectsRequest } from '@crew/apis/project/models/getProjects/request'
import { useGetProjectsQuery } from '@crew/apis/project/projectApis'
import { SettingKeyType } from '@crew/enums/app'
import { skipToken } from '@reduxjs/toolkit/query'
import { useUserSetting } from '@crew/states'
import { useAppSelector } from 'states/hooks'
import { CrewPagination } from 'components/elements/crewPagination/crewPagination'
import { ProjectItem } from './components/projectItem/projectItem'
import _ from 'lodash'
import { ProjectType } from '@crew/enums/domain'

export const ProjectTable = memo(() => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const params = qs.parse(searchParams.toString())

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  // ソート順。指定されていない場合は「プロジェクト名」の昇順
  const sortParams = useMemo(
    () =>
      searchParams.getAll('sort').length > 0
        ? searchParams.getAll('sort')
        : PROJECT_DEFAULT_SORT_COLUMN,
    [searchParams]
  )

  const getProjectsParams: GetProjectsRequest | undefined = {
    keyword: getParamAsString(ProjectSearchOptions.Keyword.id, params),
    keywordFilterCondition: undefined,
    joiningProjectStatuses: getParamAsArray(
      ProjectSearchOptions.JoiningProjectStatus.id,
      params
    ),
    archiveStatuses: getParamAsArray(
      ProjectSearchOptions.ArchivedStatus.id,
      params
    ),
    projectGroupIds: getParamAsArray(
      ProjectSearchOptions.ProjectGroupId.id,
      params
    ),
    ownerUser: getParamAsString(ProjectSearchOptions.OwnerUser.id, params),
    createdAt: getParamAsDate(ProjectSearchOptions.CreatedAt.id, params),
    updatedAt: getParamAsDate(ProjectSearchOptions.UpdatedAt.id, params),
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    sort: sortParams,
    projectType: ProjectType.Project,
  }

  const { data: getProjectsResult, refetch: getProjectsRefetch } =
    useGetProjectsQuery(getProjectsParams ?? skipToken)

  const projects = useMemo(
    () => getProjectsResult?.projects ?? [],
    [getProjectsResult?.projects]
  )

  const totalCount = useMemo(
    () => getProjectsResult?.totalCount ?? 0,
    [getProjectsResult?.totalCount]
  )

  const displayMode = useAppSelector(
    (state) => state.projectListPage.displayMode
  )

  const projectEventMessage = useAppSelector(
    (state) => state.app.projectEventMessage
  )

  const favoriteEventMessage = useAppSelector(
    (state) => state.app.favoriteEventMessage
  )

  // refresh project list
  useEffect(() => {
    getProjectsRefetch()
  }, [getProjectsRefetch, projectEventMessage, favoriteEventMessage])

  // handle change pagination grid mode
  const handlePaginationGridChange = useCallback(
    (pageIndex: number, pageSize: number) => {
      const newParams = {
        ...params,
        pageIndex,
        pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, params]
  )

  const sorting: SortingState = useMemo(() => {
    const sortArray =
      getParamAsArray('sort', params) || PROJECT_DEFAULT_SORT_COLUMN
    return sortArray.map((sort) => {
      const [id, direction] = sort.split('.')
      return {
        id,
        desc: direction === 'desc',
      }
    })
  }, [params])

  const pageCount = Math.ceil((totalCount ?? 0) / pagination.pageSize)

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
    right: ['action'],
  })

  // https://github.com/TanStack/table/discussions/3899
  // https://github.com/TanStack/table/discussions/3619
  // https://github.com/infonomic/remix.infonomic.io/blob/d3a7f628d3ad6e1e80cc80d4ac72db74da90e8d6/app/routes/admin%2B/users.tsx#L116
  // Func handle change pagination
  const handlePaginationListChange: OnChangeFn<PaginationState> = useCallback(
    (updaterOrValue) => {
      let values: PaginationState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(pagination)
      } else {
        values = updaterOrValue
      }

      const newParams = {
        ...params,
        pageIndex: values.pageIndex,
        pageSize: values.pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, pagination, params]
  )

  // Func handle change sorting
  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      let values: SortingState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(sorting)
      } else {
        values = updaterOrValue
      }

      const sortList = values.map((sort) => {
        return `${sort.id}.${sort.desc ? 'desc' : 'asc'}`
      })

      const newParams = {
        ...params,
        sort: sortList,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, params, sorting]
  )

  const columns = useMemo<ColumnDef<Project>[]>(
    () => [
      {
        id: 'subject',
        accessorKey: 'subject',
        header: () => t('label.projectName'),
        cell: ({ row }) => (
          <div className="w-full">
            <CrewProjectNameItem
              projectId={row.original.id}
              projectName={row.original.subject}
              projectVersion={row.original.version}
              isPrivate={row.original.scope === ProjectScope.Private.key}
              className="line-clamp-2 break-all"
            />
          </div>
        ),
        size: 500,
        minSize: 50,
      },
      {
        id: 'ownerUserName',
        accessorKey: 'ownerUserName',
        header: () => t('label.ownerUserName'),
        cell: ({ row }) => (
          <div className="w-full">
            <CrewUserItem
              id={row.original.ownerUser.id}
              displayName={row.original.ownerUser.displayName}
              version={row.original.ownerUser.version}
            />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'progress',
        header: () => t('label.progress'),
        cell: ({ row }) => (
          // 進捗バーは領域いっぱいに表示するため、w-fullを指定している
          <div className="w-full">
            <CrewProgressBarItem
              key={row.original.id}
              projectId={row.original.id}
            />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: () => t('label.description'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.description}</div>
        ),
        size: 500,
        minSize: 50,
      },
    ],
    [t]
  )

  const tableOptions: TableOptions<Project> = {
    data: projects,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    pageCount,
    state: {
      pagination,
      sorting,
      columnVisibility,
      columnPinning,
    },
    onPaginationChange: handlePaginationListChange,
    onSortingChange: handleSortingChange,
    onColumnVisibilityChange: setColumnVisibility,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    maxMultiSortColCount: 2,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  return (
    <div className="flex flex-col gap-2 overflow-y-hidden">
      {displayMode === ProjectListPanelDisplayMode.List.id ? (
        <div className="flex overflow-y-hidden">
          {/* Project table */}
          <CrewTable tableOptions={tableOptions} />
        </div>
      ) : (
        <>
          <CrewPagination
            pageSize={pagination.pageSize}
            pageIndex={pagination.pageIndex}
            pageCount={Math.ceil(totalCount / pagination.pageSize)}
            onPaginationChange={handlePaginationGridChange}
          />
          <div className="lg:columns-3 md:columns-2 columns-1 gap-4 space-y-4 overflow-y-auto p-2.5">
            {projects &&
              projects?.map((item) => {
                return <ProjectItem key={item.id} item={item} />
              })}
          </div>
          <CrewPagination
            pageSize={pagination.pageSize}
            pageIndex={pagination.pageIndex}
            pageCount={Math.ceil(totalCount / pagination.pageSize)}
            onPaginationChange={handlePaginationGridChange}
          />
        </>
      )}
    </div>
  )
})
