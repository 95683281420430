import { FC, memo } from 'react'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { MyTaskListToolbar } from './components/myTaskListToolbar/myTaskListToolbar'
import { MyTaskListPanel } from './components/myTaskListPanel/myTaskListPanel'

export const MyTaskPage: FC = memo(() => {
  return (
    <div className="min-h-0 flex-1 flex flex-col grow w-full">
      <div className="min-h-0 flex flex-col gap-4 p-2">
        <CrewErrorBoundary>
          <MyTaskListToolbar />
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <MyTaskListPanel />
        </CrewErrorBoundary>
      </div>
    </div>
  )
})
