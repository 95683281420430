// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect, useState } from 'react'

import { useAudioVideo } from '../../providers/AudioVideoProvider'

export function useAttendeeAudioStatus(attendeeId: string) {
  const audioVideo = useAudioVideo()
  const [muted, setMuted] = useState(false)

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const callback = (
      _: string,
      __: number | null,
      muted: boolean | null
    ): void => {
      if (muted !== null) {
        setMuted(muted)
      }
    }

    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, callback)

    return () =>
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, callback)
  }, [audioVideo, attendeeId])

  return {
    muted,
  }
}

export default useAttendeeAudioStatus
