import { CrewErrorFallback } from 'components/functions/crewErrorFallback'
import { FC, memo, PropsWithChildren } from 'react'
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary'

// type Props = PropsWithChildren<ComponentProps<typeof ErrorBoundary>>
type Props = Omit<
  PropsWithChildren<ErrorBoundaryPropsWithComponent>,
  'FallbackComponent'
>

export const CrewErrorBoundary: FC<Props> = memo(({ children, ...rest }) => {
  const handleError = (
    error: Error,
    info: {
      componentStack: string
    }
  ) => {
    console.log('CrewErrorBoundary', error)
    if (rest.onError) {
      rest.onError(error, info)
    }
  }
  return (
    <ErrorBoundary
      FallbackComponent={CrewErrorFallback}
      onError={handleError}
      onReset={rest.onReset}
    >
      {children}
    </ErrorBoundary>
  )
})
