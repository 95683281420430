var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo } from 'react';
import { IncomingControlMessageType, } from '@crew/enums/domain';
import { useSubscriptionService } from '@crew/states';
import { useWebsocketContext } from './websocketProvider';
/**
 * 指定したリソースと非同期通信するためのcustom hook
 * @param param0
 * @param enable 接続するか否か。falseにすると接続しない。trueか省略すると接続開始する
 * @returns
 */
export const useWebsocket = ({ targetType, targetId, dataType, messageTypeFilter, IsValidMessage, onRecvMessage, }, enable = true) => {
    // サブスクリプションID。イベントハンドラの管理用に使用する。
    //   ・ユーザーのmeta、dataチャンネル　　　　: {targetType}_{dataType}_{messageType}
    //                                  　　　　 例）user_meta_feed_unread_added、user_data_feed_item_added
    //   ・チャットルームのmeta、dataチャンネル　: {targetType}_{targetId}_{dataType}_{messageType}
    //                                        　 例）chat_room_{chatRoomId}_meta_unread_added、chat_room_{chatRoomId}_data_chat_message_added
    //   ・チャットスレッドのmeta、dataチャンネル：{targetType}_{targetId}_{dataType}_{messageType}
    //                                          例）chat_thread_{chatThreadId}_meta_unread_added、chat_thread_{chatThreadId1}_data_chat_message_added
    const subscriptionId = useMemo(() => {
        return Symbol(`${targetType}${targetId ? `_${targetId}` : ''}_${dataType}_${messageTypeFilter}`);
    }, [dataType, messageTypeFilter, targetId, targetType]);
    const { subscribe, unsubscribe, isSubscribing } = useWebsocketContext();
    const subscriptionService = useSubscriptionService();
    /**
     * messageTypeFilterを基に受信対象のメッセージ種類かチェックする関数
     */
    const isReceiveTargetMessageType = useCallback((type) => messageTypeFilter === type, [messageTypeFilter]);
    /**
     * 非同期通信でメッセージを受信した時に呼び出すイベントハンドラ
     */
    const handleRecvMessage = useCallback((type, message) => {
        if (!isReceiveTargetMessageType(type)) {
            // messageTypeでfilterされた、receiveしない
            console.info(`[useWebsocket] Incoming message is filtered by message type. type:${type}`);
            return;
        }
        if (IsValidMessage !== true && !IsValidMessage(message)) {
            console.warn(`[useWebsocket] Incoming message is invalid. ${JSON.stringify(message)}`);
            return;
        }
        console.info(`[useWebsocket] Incoming message is valid.`);
        onRecvMessage(type, message);
    }, [IsValidMessage, isReceiveTargetMessageType, onRecvMessage]);
    // 制御系メッセージを受信した時に呼び出すイベントハンドラ
    const handleRecvControlMessage = useCallback((type) => {
        console.info(`[useWebsocket] Incoming control message. type:${type}`);
        // サブスクライブ成功時、subscribeステータスを更新する
        if (type === IncomingControlMessageType.Success) {
            subscriptionService.updateSubscribeStateSubscribed({
                targetType,
                targetId,
                dataType,
            });
        }
    }, [dataType, subscriptionService, targetId, targetType]);
    // サブスクライブ処理
    useEffect(() => {
        if (!enable) {
            return;
        }
        // cleanup関数でsubscribe完了を待ってからunsubscribeするため、subscribeのawaiterを保持しておく
        const subscribeAwaiter = subscribe(subscriptionId, targetType, targetId, dataType, messageTypeFilter, handleRecvMessage, handleRecvControlMessage, subscriptionService.addSubscriptionHandler, subscriptionService.updateSubscribeStateSubscribing);
        return () => {
            ;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                // subscribe完了を待ってからunsubscribeする
                yield subscribeAwaiter;
                unsubscribe(messageTypeFilter, subscriptionId, subscriptionService.deleteSubscriptionHandler, subscriptionService.updateSubscribeStateNotSubscribed);
            }))();
        };
    }, [
        dataType,
        enable,
        handleRecvControlMessage,
        handleRecvMessage,
        messageTypeFilter,
        subscribe,
        subscriptionId,
        subscriptionService.addSubscriptionHandler,
        subscriptionService.deleteSubscriptionHandler,
        subscriptionService.updateSubscribeStateNotSubscribed,
        subscriptionService.updateSubscribeStateSubscribing,
        targetId,
        targetType,
        unsubscribe,
    ]);
    return isSubscribing(messageTypeFilter);
};
/**
 * Websocketを強制的に切断する関数を取得するcustom hook
 * @returns
 */
export const useWebsocketForceDisconnect = () => {
    const { forceDisconnect } = useWebsocketContext();
    return forceDisconnect;
};
/**
 * Websocketの接続を一時停止・再開する関数を取得するcustom hook
 * @returns
 */
export const useWebsocketPauser = () => {
    const { pause: pauseConnection, resume: resumeConnection } = useWebsocketContext();
    return { pauseConnection, resumeConnection };
};
