import { createContext, memo, useMemo } from 'react'
import type { CSSProperties, PropsWithChildren } from 'react'
import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

type CrewSortableItemProps = {
  id: UniqueIdentifier
}

type CrewSortableItemContextType = {
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref(node: HTMLElement | null): void
}

export const CrewSortableItemContext =
  createContext<CrewSortableItemContextType>({
    attributes: {},
    listeners: undefined,
    ref() {},
  })

// Sortable item
export const CrewSortableItem = memo(
  ({ children, id }: PropsWithChildren<CrewSortableItemProps>) => {
    const {
      attributes,
      isDragging,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
    } = useSortable({ id })

    const context = useMemo(
      () => ({
        attributes,
        listeners,
        ref: setActivatorNodeRef,
      }),
      [attributes, listeners, setActivatorNodeRef]
    )

    const style: CSSProperties = {
      opacity: isDragging ? 0.4 : undefined,
      transform: CSS.Translate.toString(transform),
      transition,
    }

    return (
      <CrewSortableItemContext.Provider value={context}>
        <tr ref={setNodeRef} style={style}>
          {children}
        </tr>
      </CrewSortableItemContext.Provider>
    )
  }
)
