import Menu from '~icons/material-symbols/menu'
import { memo, useContext } from 'react'
import { CrewSortableItemContext } from '../crewSortableItem/crewSortableItem'
// Drag handle item
export const CrewDragHandle = memo(() => {
  const { attributes, listeners, ref } = useContext(CrewSortableItemContext)

  return (
    <span {...attributes} {...listeners} ref={ref}>
      <Menu width={24} height={24} />
    </span>
  )
})
