import { UpdateProjectSettingsRequest } from '@crew/apis/setting/models/updateProjectSettings/request'
import { useUpdateProjectSettingsMutation } from '@crew/apis/setting/settingApis'
import { SettingKeyType } from '@crew/enums/app'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export type FormValues = {
  doEventManagement: boolean
  doTaskManagement: boolean
  doProjectManagement: boolean
}

const formInitialValues: FormValues = {
  doEventManagement: false,
  doTaskManagement: false,
  doProjectManagement: false,
}

export const useProjectSettingAdvancedSetting = () => {
  // react-hook-formの各種データを取得
  const { handleSubmit, control, setError, reset } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  const [updateProjectSettingsMutation] = useUpdateProjectSettingsMutation()

  // Update project settings process
  const updateProjectSettings = useCallback(
    async (projectId: string, data: FormValues) => {
      // tenant setting params
      const projectSettingData: UpdateProjectSettingsRequest = {
        projectSettings: {
          projectId,
          settings: [
            {
              key: SettingKeyType.DoEventManagement,
              value: String(data.doEventManagement) ?? 'false',
            },
            {
              key: SettingKeyType.DoTaskManagement,
              value: String(data.doTaskManagement) ?? 'false',
            },
            {
              key: SettingKeyType.DoProjectManagement,
              value: String(data.doProjectManagement) ?? 'false',
            },
          ],
        },
      }

      // call api save setting project
      await updateProjectSettingsMutation(projectSettingData).unwrap()
    },
    [updateProjectSettingsMutation]
  )

  return {
    control,
    setError,
    handleSubmit,
    reset,
    updateProjectSettings,
  }
}
