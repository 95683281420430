import {
  ColumnDef,
  TableOptions,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { FC, memo, useMemo } from 'react'
import { ProjectGroup } from '@crew/apis/dist/project/models/getProjectGroups/response'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineCreate from '~icons/ic/baseline-create'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useCallback, useState } from 'react'
import { OnChangeFn, SortingState } from '@tanstack/react-table'
import qs from 'qs'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getParamAsArray } from 'utils'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'

export type ProjectGroupTableProps = {
  onProjectGroupGridDeleteButtonClick: (
    projectGroupId: string,
    projectGroupVersion: number
  ) => void
  onProjectGroupGridEditButtonClick: (
    projectGroupId: string,
    projectGroupVersion: number
  ) => void
  projectGroups: ProjectGroup[]
}

export const ProjectGroupTable: FC<ProjectGroupTableProps> = memo((props) => {
  const { t } = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    right: ['action'],
  })
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const params = qs.parse(searchParams.toString())
  const sorting: SortingState = useMemo(() => {
    const sortArray = getParamAsArray('sort', params) || []
    return sortArray.map((sort) => {
      const [id, direction] = sort.split('.')
      return {
        id,
        desc: direction === 'desc',
      }
    })
  }, [params])
  // Func handle change sorting
  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      let values: SortingState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(sorting)
      } else {
        values = updaterOrValue
      }

      const sortList = values.map((sort) => {
        return `${sort.id}.${sort.desc ? 'desc' : 'asc'}`
      })

      const newParams = {
        ...params,
        sort: sortList,
      }

      navigate(`?${qs.stringify(newParams, { arrayFormat: 'repeat' })}`)
    },
    [navigate, params, sorting]
  )
  const columns = useMemo<ColumnDef<ProjectGroup>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => t('label.name'),
        cell: ({ row }) => {
          return <div>{row.original.name}</div>
        },
        size: 500,
        minSize: 50,
        enableSorting: true,
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: () => t('label.registeredDate'),
        cell: ({ row }) => {
          return (
            <>
              {t('format.timestamp', {
                value: row.original.createdAt,
                timeZone: defaultUserProfileRegion,
              })}
            </>
          )
        },
        size: 160,
        minSize: 50,
        enableSorting: true,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => {
          return (
            <div>
              <div className="flex gap-2 pr-2 justify-end items-center">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    props.onProjectGroupGridEditButtonClick(
                      row.original.id,
                      row.original.version
                    )
                  }
                >
                  <BaselineCreate width={24} height={24} />
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    props.onProjectGroupGridDeleteButtonClick(
                      row.original.id,
                      row.original.version
                    )
                  }
                >
                  <BaselineDelete width={24} height={24} />
                </span>
              </div>
            </div>
          )
        },
        size: 80,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [t, defaultUserProfileRegion, props]
  )
  const tableOptions: TableOptions<ProjectGroup> = {
    data: props.projectGroups,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnPinning,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: handleSortingChange,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    enableRowSelection: true,
    maxMultiSortColCount: 2,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }
  useReactTable(tableOptions)
  return (
    <CrewTable
      tableOptions={tableOptions}
      showColumnSelector={false}
      showPager={false}
    />
  )
})
