import { memo } from 'react'
import { PersonalSettingDisplayForm } from './components/personalSettingDisplayForm/personalSettingDisplayForm'

export const PersonalSettingDisplayPanel = memo(() => {
  return (
    <div className="max-w-2xl mx-auto w-full">
      <PersonalSettingDisplayForm />
    </div>
  )
})
