import { createContext, useContext } from 'react';
/**
 * 初期値なしのコンテキストをいい感じに扱うためのラッパー関数
 *
 * createContext()をそのまま使う場合、型にundefinedないしnullを含めるか、初期化時に型に合致するobjectを設定する必要がある。
 * 前者はuseContextするときに都度型が必要になり、後者は意味のある値を設定することが難しい場合がある。
 * この関数を使うことで、contextとその型チェックを含むuseContextをセットで生成できる。
 *  https://www.carlrippon.com/react-context-with-typescript-p4/
 *  https://qiita.com/johnmackay150/items/88654e5064290c24a32a
 * @returns
 */
export const createUninitializedContext = () => {
    const context = createContext(undefined);
    const useInitializedContext = () => {
        const ctx = useContext(context);
        if (!ctx) {
            throw new Error('[useInitializedContext] undefined context. forgot provider?');
        }
        return ctx;
    };
    return { context, useInitializedContext };
};
