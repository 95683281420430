import { memo, useCallback, useEffect, useState } from 'react'
import LoadIndicator from 'devextreme-react/load-indicator'
import classNames from 'classnames'
import FileImageLine from '~icons/ri/file-image-line'
import { useValueChangeEffect } from '@crew/hooks'

export const LOAD_START_INTERVAL_MS = 100 // マウント時の画像読み込み開始までのミリ秒
export const RETRY_INTERVAL_MS = 500 // 画像読み込み再試行のミリ秒
export const IMAGE_LOAD_RETRY_COUNT_MAX = 5 //画像読み込みの最大再試行回数

// リトライ時のリトライ方法
export const CrewRetryImageRetryType = {
  RetryWithSameUrl: 'retryWithSameUrl',
  GenerateNewUrl: 'generateNewUrl',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CrewRetryImageRetryType =
  (typeof CrewRetryImageRetryType)[keyof typeof CrewRetryImageRetryType]

export type CrewRetryImageProps = {
  src: string
  alt: string
  className?: string

  // 画像読み込みに失敗した場合のリトライ方法を扱うプロパティ
  //  'retryWithSameUrl': 同じURLでリトライする
  //      末尾にretryパラメータを付与して繰り返す
  //  'generateNewUrl': 新しいURLを生成してリトライする
  //      retryパラメータは付与せずにリトライする
  //      この場合、onGenerateNewUrlの指定は必須となる
  retryType: CrewRetryImageRetryType

  // 新しいURLを生成するコールバック関数
  // retryTypeが'generateNewUrl'の場合は指定必須
  onGenerateNewUrl?: () => Promise<string>

  draggable?: boolean
}

// サムネイル画像表示（失敗時はリトライする）
export const CrewRetryImage = memo((props: CrewRetryImageProps) => {
  const [retriesLeft, setRetriesLeft] = useState(IMAGE_LOAD_RETRY_COUNT_MAX)
  const [imgSrc, setImgSrc] = useState(
    // 遅延ロードするため、初期表示を透明な1px画像とする。空文字にするとエラーになり再ロードが走ってしまう。
    // https://stackoverflow.com/questions/5775469/whats-the-valid-way-to-include-an-image-with-no-src
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  )

  // リトライ時のローディング表示フラグ（マウント時のローディング表示はしない）
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showImage, setShowImage] = useState(false)

  useValueChangeEffect(
    () => {
      setImgSrc(props.src)
    },
    [props.src],
    props.src
  )

  useEffect(() => {
    // 全てのリトライに失敗した場合はローディングを解除し、img要素は非表示にする
    if (error && retriesLeft > 0) {
      // dont show loading spinner for first retry
      if (retriesLeft < IMAGE_LOAD_RETRY_COUNT_MAX) {
        setIsLoading(true)
      }

      const timer = window.setTimeout(async () => {
        setRetriesLeft(retriesLeft - 1)

        // 同じURLでリトライする場合
        if (props.retryType === CrewRetryImageRetryType.RetryWithSameUrl) {
          // 画像のURLにクエリパラメータを付与して、画像のキャッシュを無視する
          setImgSrc(`${props.src}&retry=${Date.now()}`)
        } else if (props.retryType === CrewRetryImageRetryType.GenerateNewUrl) {
          // コールバック関数を実行して新しいURLを生成する
          const newImgSrc = await props.onGenerateNewUrl?.()
          if (newImgSrc) {
            setImgSrc(newImgSrc)
          }
        }
      }, RETRY_INTERVAL_MS)

      return () => {
        window.clearTimeout(timer)
      }
    } else {
      setIsLoading(false)
    }
  }, [error, props, retriesLeft])

  // 画像読み込みが成功したらローディング表示を解除する
  const handleImageLoaded = useCallback(() => {
    setIsLoading(false)
    setError(false)
    setShowImage(true)
  }, [])

  // 画像読み込みが失敗したら指定回数リトライ
  const handleImageError = useCallback(() => {
    if (retriesLeft <= 0) {
      setIsLoading(false)
      setShowImage(true)
      setError(true)
      return
    }

    setError(true)
  }, [retriesLeft])

  return (
    <div className="relative w-full h-full">
      {/* If showImage is true, display the image */}
      <img
        src={imgSrc}
        alt={props.alt}
        className={classNames(
          // 指定のclassがあればそちらを優先、無ければデフォルトのclassを使用
          props.className || 'rounded-md h-full w-full object-cover',
          // 画像非表示状態orローディング中は画像を非表示にする
          (!showImage || isLoading || error) && 'invisible'
        )}
        onLoad={handleImageLoaded}
        onError={handleImageError}
        draggable={props.draggable}
      />

      {/* Display default image icon if cannot load image */}
      {!isLoading && error && showImage && (
        <div className="w-full h-full absolute top-0 left-0 flex flex-col items-center justify-center">
          <FileImageLine className="text-crew-gray-500 w-1/2 h-auto" />
        </div>
      )}

      {/* 画像を読み込み完了、またはリトライ失敗までローディング表示 */}
      {isLoading && (
        // ダイアログに下の画面のスピナーが重なるのを防ぐためにz-indexを設定
        <div className="flex items-center justify-center dx-overlay-wrapper z-20 absolute">
          <LoadIndicator />
        </div>
      )}
    </div>
  )
})
