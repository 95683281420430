import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { Modal } from 'components/layouts/modal/modal'
import { FC, memo } from 'react'
import { FilterTable } from './components/filterTable/filterTable'

type CrewFilterSettingsDialogProps = {
  isOpen: boolean
  onClose: () => void
  entityType: EntityType
}

export const CrewFilterSettingsDialog: FC<CrewFilterSettingsDialogProps> = memo(
  (props) => {
    const { t } = useTranslation()
    return (
      <Modal
        title={t('label.filterSettings')}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <div className="flex flex-col gap-2.5">
          <div className="grow">
            {/* Filters list */}
            <FilterTable entityType={props.entityType} />
          </div>

          <div className="text-right">
            {/* close dialog action */}
            <CrewButton
              text={t('action.close')}
              type="normal"
              stylingMode="outlined"
              onClick={props.onClose}
            />
          </div>
        </div>
      </Modal>
    )
  }
)
