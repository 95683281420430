import { useTranslation } from '@crew/modules/i18n'
import SharpSearch from '~icons/ic/sharp-search'
import { memo } from 'react'
import { SearchTab } from './components/searchTab/searchTab'
import { Route, Routes } from 'react-router-dom'
import { SearchTabs } from 'enums/app'
import { SearchChatResultList } from './components/searchChatResultList/searchChatResultList'
import { SearchProjectResultList } from './components/searchProjectResultList/searchProjectResultList'
import { SearchTaskResultList } from './components/searchTaskResultList/searchTaskResultList'
import { SearchFileResultList } from './components/searchFileResultList/searchFileResultList'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

export const SearchPage = memo(() => {
  const { t } = useTranslation()

  return (
    <div className="min-h-0 flex-1 flex flex-col grow">
      <div className="min-h-0 flex flex-col gap-2 p-2">
        <div className="flex gap-2 items-center h-8 text-crew-gray-3-light dark:text-crew-gray-2-dark">
          <SharpSearch width={24} height={24} />
          <p className="font-bold">{t('action.search')}</p>
        </div>
        <CrewErrorBoundary>
          {/* List tab of search page */}
          <SearchTab />
          <div className="grow min-h-0 w-full flex flex-col">
            {/* URLに応じて表示するコンポーネントを切り替える */}
            <Routes>
              {/* 
                          TODO: 一時的にデフォルトルート（index）を除去している
                          以下タスク対応時にデフォルトルートを設定する予定
                          https://break-tmc.atlassian.net/browse/CREW-9163
                        */}
              <Route
                path={SearchTabs.Chat.value}
                element={<SearchChatResultList />}
              />
              <Route
                path={SearchTabs.Project.value}
                element={<SearchProjectResultList />}
              />
              <Route
                path={SearchTabs.Task.value}
                element={<SearchTaskResultList />}
              />
              <Route
                path={SearchTabs.File.value}
                element={<SearchFileResultList />}
              />
            </Routes>
          </div>
        </CrewErrorBoundary>
      </div>
    </div>
  )
})
