import { apis } from '../apiBase/apiBase';
export const folderApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        insertFolder: builder.mutation({
            query: (params) => ({
                url: `folders/insert`,
                method: `POST`,
                body: params,
            }),
        }),
        updateFolderName: builder.mutation({
            query: (params) => ({
                url: `folders/update-name`,
                method: `POST`,
                body: params,
            }),
        }),
        updateFolderParent: builder.mutation({
            query: (params) => ({
                url: `folders/update-parent`,
                method: `POST`,
                body: params,
            }),
        }),
        deleteFolder: builder.mutation({
            query: (params) => ({
                url: `folders/delete`,
                method: `POST`,
                body: params,
            }),
        }),
        getFolderTree: builder.query({
            query: (params) => ({
                url: `folders/tree`,
                method: 'GET',
                params: params,
            }),
        }),
        getFolderFiles: builder.query({
            query: (params) => {
                return {
                    url: `folders/${params.folderId}/files`,
                    method: 'GET',
                    params,
                };
            },
        }),
        getFolder: builder.query({
            query: (params) => ({
                url: `folders/${params.folderId}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useInsertFolderMutation, useUpdateFolderNameMutation, useUpdateFolderParentMutation, useDeleteFolderMutation, useGetFolderTreeQuery, useLazyGetFolderTreeQuery, useGetFolderFilesQuery, useLazyGetFolderFilesQuery, useGetFolderQuery, useLazyGetFolderQuery, } = folderApis;
