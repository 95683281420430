import { memo } from 'react'

import { AttendeeTable } from './components/attendeeTable/attendeeTable'
import { useGetEventAttendeesQuery } from '@crew/apis/project/projectApis'
import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useEventAttendeesContext } from '../../eventDetailAttendeeList'
import { useAppSelector } from 'states/hooks'

export const EventDetailAttendeeListPanel = memo(() => {
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const { eventId } = useParams()

  const eventAttendeeEventMessage = useAppSelector(
    (state) => state.app.eventAttendeeEventMessage
  )

  const { setCurrentAttendee } = useEventAttendeesContext()

  const { data: getEventAttendeesResult, refetch: getEventAttendeesRefetch } =
    useGetEventAttendeesQuery(eventId ? { eventId } : skipToken)

  useEffect(() => {
    if (loggedInUser) {
      //get current user's event attendees
      const currentAttendee = getEventAttendeesResult?.eventAttendees?.find(
        (attendee) => attendee.userId === loggedInUser.id
      )

      setCurrentAttendee(currentAttendee ?? null)
    }
  }, [
    getEventAttendeesResult?.eventAttendees,
    loggedInUser,
    setCurrentAttendee,
  ])

  // refresh attendees list
  useEffect(() => {
    getEventAttendeesRefetch()
  }, [getEventAttendeesRefetch, eventAttendeeEventMessage])

  return (
    <div className="flex flex-col p-2">
      {getEventAttendeesResult?.eventAttendees && (
        <AttendeeTable
          eventAttendees={getEventAttendeesResult.eventAttendees ?? []}
        />
      )}
    </div>
  )
})
