import { NotifyEventType } from 'enums/app'
import { useCallback } from 'react'
import {
  useDeleteBookmarkMutation,
  useInsertBookmarkMutation,
  useUpdateBookmarkArchiveMutation,
} from '@crew/apis/bookmark/bookmarkApis'
import { useDeleteChatMessageMutation } from '@crew/apis/chat/chatApis'
import { useContextMenuDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useAppDispatch } from 'states/hooks'
import {
  ObjectEventMessage,
  notifyBookmarkEvent,
} from 'features/app/states/appSlice'
import { Bookmark } from '@crew/models/dist/domain'

export const useCrewChatMessageActionMenu = (disabledContextMenu: boolean) => {
  const [insertBookmarkMutation] = useInsertBookmarkMutation()
  const [deleteBookmarkMutation] = useDeleteBookmarkMutation()
  const [updateBookmarkArchiveMutation] = useUpdateBookmarkArchiveMutation()
  const [deleteChatMessageMutation] = useDeleteChatMessageMutation()

  const dispatch = useAppDispatch()

  // 実際にContextMenuに格納するDataSource定義
  const contextMenuDataSource = useContextMenuDataSource(disabledContextMenu)

  // delete bookmark
  const deleteBookmark = useCallback(
    async (chatMessageId: string) => {
      // ここではブックマークされているかの状態はpropsでしか持っていないためバックエンドのブックマークAPI側でwebSocket経由でブックマーク更新のメッセージを投げる
      // 別端末・同じユーザーでログインしていた場合にブックマークの状態を反映させるため
      await deleteBookmarkMutation({
        chatMessageId,
      }).unwrap()
    },
    [deleteBookmarkMutation]
  )

  // insert bookmark
  const insertBookmark = useCallback(
    async (chatMessageId: string) => {
      // ここではブックマークされているかの状態はpropsでしか持っていないためバックエンドのブックマークAPI側でwebSocket経由でブックマーク更新のメッセージを投げる
      // 別端末・同じユーザーでログインしていた場合にブックマークの状態を反映させるため
      await insertBookmarkMutation({
        chatMessageId,
      }).unwrap()
    },
    [insertBookmarkMutation]
  )

  // 削除確認ダイアログ OKボタン
  const deleteChatMessage = useCallback(
    async (chatMessageId: string, version: number) => {
      await deleteChatMessageMutation({
        chatMessageId,
        version,
      }).unwrap()
    },
    [deleteChatMessageMutation]
  )

  //Bookmark archive checkbox changed
  const bookmarkArchiveSelectionChange = useCallback(
    async (chatMessageId: string, isArchived: boolean, version: number) => {
      // update bookmark message archive
      const paramBookmarkArchiveRequest = {
        chatMessageId,
        archived: !isArchived,
        version,
      }

      await updateBookmarkArchiveMutation({
        bookmark: paramBookmarkArchiveRequest,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Bookmark> = {
        eventType: NotifyEventType.Updated,
        id: chatMessageId,
        object: undefined,
      }

      dispatch(notifyBookmarkEvent(objectEventMessage))
    },
    [dispatch, updateBookmarkArchiveMutation]
  )

  return {
    contextMenuDataSource,
    deleteChatMessage,
    deleteBookmark,
    insertBookmark,
    bookmarkArchiveSelectionChange,
  }
}
