import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { memo } from 'react'
import { TaskDetailPanel } from './components/taskDetailPanel/taskDetailPanel'
import { TaskDetailTab } from './components/taskDetailTab/taskDetailTab'
import { Route, Routes } from 'react-router-dom'
import { TaskDetailListTabs } from 'enums/app'
import { TaskDetailCommentList } from 'features/task/components/taskDetailPage/components/taskDetailCommentList/taskDetailCommentList'
import { TaskDetailFileList } from './components/taskDetailFileList/taskDetailFileList'
import { useGetChatRoomByEntityQuery } from '@crew/apis/chat/chatApis'
import { EntityType } from '@crew/enums/domain'
import { useChatCurrentService } from '@crew/states'
import { setTaskId } from 'features/task/components/taskDetailPage/states/taskDetailSlice'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useUnmount } from '@dx-system/react-use'
import { useAppDispatch } from 'states/hooks'
import { TaskDetailDependencyList } from './components/taskDetailDependencyList/taskDetailDependencyList'

export const TaskDetailPage = memo(() => {
  const dispatch = useAppDispatch()
  const { taskId } = useParams()

  // Sliceの操作を行うためのServiceを取得
  const chatCurrentService = useChatCurrentService(dispatch)

  // タスクに紐づくチャットルームIDを取得する
  const { data: getChatRoomResponse } = useGetChatRoomByEntityQuery({
    entityType: EntityType.Task,
    entityRecordId: taskId as string,
  })

  useEffect(() => {
    dispatch(setTaskId(taskId))

    //clear taskId in redux store when leave page
    return () => {
      dispatch(setTaskId())
    }
  }, [dispatch, taskId])

  // 取得したチャットルームIDに紐づくチャットメッセージを取得する
  useEffect(
    () => {
      if (getChatRoomResponse?.chatRoom && taskId) {
        // 表示するチャットルームを設定する
        chatCurrentService.setCurrentChatRoomAndRestoreThread({
          id: getChatRoomResponse.chatRoom.id,
          entityType: EntityType.Task,
          entityRecordId: taskId,
        })
      }
    },
    // マウント時のみ値を格納とするので依存は不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getChatRoomResponse?.chatRoom?.id]
  )

  // アンマウント時、現在のチャットルーム情報をリセット
  useUnmount(() => {
    chatCurrentService.resetCurrentChatRoom()
  })

  return (
    <div className="flex flex-1 grow min-h-0">
      <main className="grow flex flex-col gap-y-2 h-full w-2/3 overflow-y-auto">
        <CrewErrorBoundary>
          <div className="flex flex-col">
            <TaskDetailPanel />
          </div>
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <div className="min-h-0 flex-1 flex flex-col grow w-full">
            {/* タスク詳細タブ */}
            <div className="px-2">
              <TaskDetailTab />
            </div>
            <div className="grow w-full flex flex-col h-full overflow-y-auto">
              {/* URLに応じて表示するコンポーネントを切り替える */}
              <Routes>
                {/* 
                  TODO: 一時的にデフォルトルート（index）を除去している
                  以下タスク対応時にデフォルトルートを設定する予定
                  https://break-tmc.atlassian.net/browse/CREW-9163
                */}
                <Route
                  path={TaskDetailListTabs.Comment.value}
                  element={<TaskDetailCommentList />}
                />
                <Route
                  path={TaskDetailListTabs.File.value}
                  element={<TaskDetailFileList />}
                />
                <Route
                  path={TaskDetailListTabs.Dependency.value}
                  element={<TaskDetailDependencyList />}
                />
              </Routes>
            </div>
          </div>
        </CrewErrorBoundary>
      </main>
    </div>
  )
})
