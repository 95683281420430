import { EditorThemeClasses, Klass, LexicalEditor, LexicalNode } from 'lexical'
import { INSERT_TABLE_COMMAND } from '@lexical/table'
import { createContext, useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { TextInput } from '../../components/textInput'
import { DialogActions } from '../../components/dialog'
import { useTranslation } from '@crew/modules/i18n'

export const TableTheme = {
  table: 'CrewEditorTheme__table',
  tableAddColumns: 'CrewEditorTheme__tableAddColumns',
  tableAddRows: 'CrewEditorTheme__tableAddRows',
  tableCell: 'CrewEditorTheme__tableCell',
  tableCellActionButton: 'CrewEditorTheme__tableCellActionButton',
  tableCellActionButtonContainer:
    'CrewEditorTheme__tableCellActionButtonContainer',
  tableCellEditing: 'CrewEditorTheme__tableCellEditing',
  tableCellHeader: 'CrewEditorTheme__tableCellHeader',
  tableCellPrimarySelected: 'CrewEditorTheme__tableCellPrimarySelected',
  tableCellSelected: 'CrewEditorTheme__tableCellSelected',
  tableCellSortedIndicator: 'CrewEditorTheme__tableCellSortedIndicator',
  tableSelected: 'CrewEditorTheme__tableSelected',
}

export type InsertTableCommandPayload = Readonly<{
  columns: string
  rows: string
  includeHeaders?: boolean
}>

export type CellContextShape = {
  cellEditorConfig: null | CellEditorConfig
  cellEditorPlugins: null | JSX.Element | Array<JSX.Element>
  set: (
    cellEditorConfig: null | CellEditorConfig,
    cellEditorPlugins: null | JSX.Element | Array<JSX.Element>
  ) => void
}

export type CellEditorConfig = Readonly<{
  namespace: string
  nodes?: ReadonlyArray<Klass<LexicalNode>>
  onError: (error: Error, editor: LexicalEditor) => void
  readOnly?: boolean
  theme?: EditorThemeClasses
}>

// @ts-ignore: not sure why TS doesn't like using null as the value?
export const CellContext: React.Context<CellContextShape> = createContext({
  cellEditorConfig: null,
  cellEditorPlugins: null,
  set: () => {
    // Empty
  },
})

export function TableContext({ children }: { children: React.ReactNode }) {
  const [contextValue, setContextValue] = useState<{
    cellEditorConfig: null | CellEditorConfig
    cellEditorPlugins: null | JSX.Element | Array<JSX.Element>
  }>({
    cellEditorConfig: null,
    cellEditorPlugins: null,
  })
  return (
    <CellContext.Provider
      value={useMemo(
        () => ({
          cellEditorConfig: contextValue.cellEditorConfig,
          cellEditorPlugins: contextValue.cellEditorPlugins,
          set: (cellEditorConfig, cellEditorPlugins) => {
            setContextValue({ cellEditorConfig, cellEditorPlugins })
          },
        }),
        [contextValue.cellEditorConfig, contextValue.cellEditorPlugins]
      )}
    >
      {children}
    </CellContext.Provider>
  )
}

export function InsertTableDialog({
  activeEditor,
  onClose,
}: {
  activeEditor: LexicalEditor
  onClose: () => void
}): JSX.Element {
  const { t } = useTranslation()

  // initial table row numbers
  const [rows, setRows] = useState('5')
  // initial table column numbers
  const [columns, setColumns] = useState('5')
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(true)

  useEffect(() => {
    const row = Number(rows)
    const column = Number(columns)

    // Check if both row and column values are valid
    if (row && row > 0 && row <= 500 && column && column > 0 && column <= 50) {
      // Enable confirm button if row and column are within valid ranges
      setIsDisabledConfirmButton(false)
    } else {
      // Disable confirm button if row or column is outside valid ranges
      setIsDisabledConfirmButton(true)
    }
  }, [rows, columns])

  // confirm create table with rows and columns
  const handleConfirmButtonClick = () => {
    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns,
      rows,
    })

    onClose()
  }

  return (
    <>
      <TextInput
        placeholder={'# of rows (1-500)'}
        label={t('action.lexical.rows')}
        onChange={setRows}
        value={rows}
        data-test-id="table-modal-rows"
        type="number"
      />
      <TextInput
        placeholder={'# of columns (1-50)'}
        label={t('action.lexical.columns')}
        onChange={setColumns}
        value={columns}
        data-test-id="table-modal-columns"
        type="number"
      />
      <DialogActions data-test-id="table-model-confirm-insert">
        <button
          disabled={isDisabledConfirmButton}
          onClick={handleConfirmButtonClick}
        >
          {t('action.lexical.confirm')}
        </button>
      </DialogActions>
    </>
  )
}
