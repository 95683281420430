import { sheetColumns, sheetRows, EmojiData, } from 'emoji-data-ts';
import emojiRawData from 'emoji-data-ts/dist/lib/emoji.json';
export { sheetColumns, sheetRows };
export const emojis = emojiRawData;
const emojiData = new EmojiData();
export const currentVersion = emojiData.currentVersion;
export const emojiCategoryLookUp = emojiData.emojiCategoryLookUp;
export const emojiUnicodeRegex = emojiData.emojiUnicodeRegex;
// オブジェクトのメソッドをexportするため、明示的にbindする
export const getImageData = emojiData.getImageData.bind(emojiData);
export const getEmojiByName = emojiData.getEmojiByName.bind(emojiData);
export const getVariationEmojis = emojiData.getVariationEmojis.bind(emojiData);
export const emojiCategories = Array.from(emojiData.emojiCategoryLookUp).map(([category]) => category);
export const sheetSizeX = 100 * sheetColumns;
export const sheetSizeY = 100 * sheetRows;
export const tag = {
    name: 'SPAN',
    className: 'ce-emoji',
    shortNameCustomDataAttributeName: 'sn',
};
export const libVersion = `v${currentVersion.replaceAll('.', '_')}`;
