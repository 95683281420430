import { FC, memo, useEffect } from 'react'
import { CrewNavTabs } from 'components/elements/crewNavTabs/crewNavTabs'
import { useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { DisplayAnonymousFile, ProjectListTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { CrewNavTabProps } from 'components/elements/crewNavTabs/components/crewNavTab'
import { useGetLookupFiltersQuery } from '@crew/apis/lookup/lookupApis'
import { EntityType } from '@crew/enums/dist/domain'
import { useAppSelector } from 'states/hooks'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import { buildObjUrlParamsFromFilterDefinition } from '@crew/utils'

type ProjectDetailTabProps = {
  doTaskManagement: boolean
  doEventManagement: boolean
}

export const ProjectDetailTab: FC<ProjectDetailTabProps> = memo((props) => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  const filterEventMessage = useAppSelector(
    (state) => state.app.filterEventMessage
  )

  const defaultPageSize = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  // Declare default pagination information
  const defaultPagination = useMemo(() => {
    return {
      pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
      pageSize: defaultPageSize,
    }
  }, [defaultPageSize])

  // ====================================================
  // Build url parameter for the link of Event tab
  // ====================================================
  // Get event filter
  const { data: eventFilters, refetch: refetchEventFilter } =
    useGetLookupFiltersQuery({
      entityType: EntityType.Event,
    })

  // When an event related to Filter arises, perform get task filters again
  useEffect(() => {
    refetchEventFilter()
  }, [filterEventMessage, refetchEventFilter])

  // get event default filter
  const eventDefaultFilter = useMemo(
    () => eventFilters?.filters.find((filter) => filter.default),
    [eventFilters?.filters]
  )

  // event params
  const eventFilterCondition =
    buildObjUrlParamsFromFilterDefinition(eventDefaultFilter)
  const eventParams = useMemo(() => {
    return {
      ...defaultPagination,
      ...eventFilterCondition,
    }
  }, [defaultPagination, eventFilterCondition])

  // ====================================================
  // Build url parameter for the link of Task tab
  // ====================================================
  // Get task filter
  const { data: taskFilters, refetch: refetchTaskFilter } =
    useGetLookupFiltersQuery({
      entityType: EntityType.Task,
    })

  // When an event related to Filter arises, perform get task filters again
  useEffect(() => {
    refetchTaskFilter()
  }, [filterEventMessage, refetchTaskFilter])

  // get task default filter
  const taskDefaultFilter = useMemo(
    () => taskFilters?.filters.find((filter) => filter.default),
    [taskFilters?.filters]
  )

  // task params
  const taskFilterCondition =
    buildObjUrlParamsFromFilterDefinition(taskDefaultFilter)
  const taskParams = useMemo(() => {
    return {
      ...defaultPagination,
      ...taskFilterCondition,
    }
  }, [defaultPagination, taskFilterCondition])

  // ====================================================
  // Build url parameter for the link of File tab
  // ====================================================
  // Get file filter
  const { data: fileFilters, refetch: refetchFileFilter } =
    useGetLookupFiltersQuery({
      entityType: EntityType.File,
    })

  // When an event related to Filter arises, perform get task filters again
  useEffect(() => {
    refetchFileFilter()
  }, [filterEventMessage, refetchFileFilter])

  // get task default filter
  const fileDefaultFilter = useMemo(
    () => fileFilters?.filters.find((filter) => filter.default),
    [fileFilters?.filters]
  )

  // file params
  const fileFilterCondition =
    buildObjUrlParamsFromFilterDefinition(fileDefaultFilter)
  const fileParams = useMemo(() => {
    return {
      ...defaultPagination,
      ...fileFilterCondition,
      // デフォルトは匿名ファイルを表示しない
      ...{ anonymousFile: DisplayAnonymousFile.DoNotDisplay.value },
    }
  }, [defaultPagination, fileFilterCondition])

  // タブアイテム
  const displayTabItems: CrewNavTabProps[] = useMemo(() => {
    return Object.values(ProjectListTabs)
      .filter((tab) => {
        if (tab.value === ProjectListTabs.Task.value) {
          return props.doTaskManagement
        } else if (tab.value === ProjectListTabs.Meeting.value) {
          return props.doEventManagement
        }
        return true
      })
      .map((item) => {
        let param: any = {}
        if (item.value === ProjectListTabs.Task.value) {
          param = taskParams
        } else if (item.value === ProjectListTabs.Meeting.value) {
          param = eventParams
        } else if (item.value === ProjectListTabs.File.value) {
          param = fileParams
        }
        return {
          onClick: () => {
            // タブに対応するURLへ遷移（相対パス指定）
            navigate(item.value, {
              ...param,
            })
          },
          to: item.value,
          text: t(item.text),
          icon: item.icon,
        }
      })
  }, [
    eventParams,
    fileParams,
    navigate,
    props.doEventManagement,
    props.doTaskManagement,
    t,
    taskParams,
  ])

  return <CrewNavTabs tabItems={displayTabItems} />
})
