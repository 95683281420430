import { ReactNode, ReactElement } from 'react'
import { useState, useCallback } from 'react'

import { CrewEmojiPickerContext } from './crewEmojiPickerContext'
import { CrewEmojiPicker } from '../../devextreme/crewEmojiPicker/crewEmojiPicker'
import { createPortal } from 'react-dom'

export const CrewEmojiPickerProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [emojiPicker, setEmojiPicker] = useState<ReactElement | null>(null)

  const open = useCallback(
    (ref: Element, callback: (emoji: string) => void) => {
      return setEmojiPicker(
        createPortal(
          <CrewEmojiPicker
            reference={ref}
            onClose={() => setEmojiPicker(null)}
            onPickEmoji={(emoji) => callback(emoji)}
          />,
          document.body
        )
      )
    },
    []
  )

  const contextValue = {
    isOpen: emojiPicker !== null,
    close: () => setEmojiPicker(null),
    open,
  }

  return (
    <CrewEmojiPickerContext.Provider value={contextValue}>
      {children}
      {emojiPicker}
    </CrewEmojiPickerContext.Provider>
  )
}
