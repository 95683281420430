import { EntityType } from '@crew/enums/domain'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FileEntryDialog } from 'features/file/components/fileEntryDialog/fileEntryDialog'
import { memo, useCallback, useMemo } from 'react'
import { useProjectPermissions } from '@crew/hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { getDefaultTabValue } from '@crew/utils/enum'
import { FileDetailListTabs, FileListDisplayMode } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { TaskDetailFileSearch } from './components/taskDetailFileSearch/taskDetailFileSearch'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { setSelectedFileListDisplayMode } from 'features/task/components/taskDetailPage/states/taskDetailSlice'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'

type ButtonGroupInfo = {
  index: number
  text: string | undefined
  icon: JSX.Element | undefined
}

export const TaskDetailFileListToolbar = memo(() => {
  const { t } = useTranslation()
  const { taskId } = useParams()
  const { navigate } = useCrewNavigate()
  const dispatch = useAppDispatch()

  // 選択中の表示形式
  const selectedFileListDisplayMode = useAppSelector(
    (state) => state.taskDetail.selectedFileListDisplayMode
  )

  /** ダイアログ */
  // ファイル登録ダイアログ
  const [isOpenFileEntryDialog, openFileEntryDialog, closeFileEntryDialog] =
    useModal()

  const { hasPrjFileCreatePermission } = useProjectPermissions(
    EntityType.Task,
    taskId
  )

  // ファイル登録後はファイル詳細画面に遷移
  const handleRegisteredFile = useCallback(
    (fileId: string) => {
      closeFileEntryDialog()

      navigate(`/files/${fileId}/${getDefaultTabValue(FileDetailListTabs)}`)
    },
    [closeFileEntryDialog, navigate]
  )

  // 表示形式のボタングループ
  const fileDisplayModeButtonGroup: ButtonGroupInfo[] = useMemo(() => {
    const items = Object.keys(FileListDisplayMode).map((key) => {
      const item = FileListDisplayMode[key as keyof typeof FileListDisplayMode]
      const SvgIcon = item.icon
      return {
        index: item.id,
        text: undefined,
        icon: <SvgIcon width={24} height={24} />,
      }
    })

    return items
  }, [])

  // 表示形式の切替ボタンクリック時
  const handleFileDisplayModeItemClick = useCallback(
    (itemData: ButtonGroupInfo) => {
      // 表示形式を更新
      dispatch(setSelectedFileListDisplayMode(itemData.index))
    },
    [dispatch]
  )

  return (
    <div className="p-2.5">
      <div className="flex flex-row justify-between items-center pb-2.5">
        {/* 表示切替ボタングループ */}
        <CrewButtonGroup
          items={fileDisplayModeButtonGroup}
          keyExpr="index"
          textExpr="text"
          iconExpr="icon"
          stylingMode="text"
          selectedItemKey={selectedFileListDisplayMode}
          onItemClick={handleFileDisplayModeItemClick}
        />

        {/* 新規ファイルボタン */}
        {hasPrjFileCreatePermission && (
          <CrewButton
            type="primary"
            className="ml-auto"
            text={t('action.newFile')}
            onClick={openFileEntryDialog}
          />
        )}
      </div>

      {/* 検索条件 */}
      <TaskDetailFileSearch />

      {/* ファイル登録ダイアログ */}
      <FileEntryDialog
        isEditMode={false}
        title={t('label.registerNewFile')}
        isOpen={isOpenFileEntryDialog}
        onClose={closeFileEntryDialog}
        onRegistered={handleRegisteredFile}
        entityRecordId={taskId}
        entityType={EntityType.Task}
      />
    </div>
  )
})
