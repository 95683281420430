import { EventDetailPanel } from './components/eventDetailPanel/eventDetailPanel'
import { memo, useEffect, useMemo, useState } from 'react'
import { useEventDetailPage } from 'features/project/components/eventDetailPage/useEventDetailPage'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { EventDetailTab } from 'features/project/components/eventDetailPage/components/eventDetailTab/eventDetailTab'
import { Route, Routes, useParams } from 'react-router-dom'
import { EventDetailTabs } from 'enums/app'
import { EventDetailTaskList } from 'features/project/components/eventDetailPage/components/eventDetailTaskList/eventDetailTaskList'
import { EventDetailFileList } from './components/eventDetailFileList/eventDetailFileList'
import { EventDetailWebMeetingPanel } from './components/eventDetailWebMeetingPanel/eventDetailWebMeetingPanel'
import { EventDetailAttendeeList } from './components/eventDetailAttendeeList/eventDetailAttendeeList'
import { EventDescriptionPanel } from './components/eventDescriptionPanel/eventDescriptionPanel'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useMeetingStatus } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { MeetingStatus } from 'modules/amazon-chime-sdk-component-library-devextreme/types'
import { useGetChatRoomByEntityQuery } from '@crew/apis/dist/chat/chatApis'
import { useChatCurrentService } from '@crew/states'
import { useGetEventQuery } from '@crew/apis/dist/project/projectApis'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { EntityType } from '@crew/enums/dist/domain'
import { useUnmount } from '@dx-system/react-use'
import { GetEventRequest } from '@crew/apis/dist/project/models/getEvent/request'
import { EventDetailMeetingMinutesPanel } from './components/eventDetailMeetingMinutesPanel/eventDetailMeetingMinutesPanel'

export const EventDetailPage = memo(() => {
  const { eventDetailPageContext } = useEventDetailPage()

  const dispatch = useAppDispatch()

  // Sliceの操作を行うためのServiceを取得
  const chatCurrentService = useChatCurrentService(dispatch)

  const { eventId } = useParams()
  const eventEventMessage = useAppSelector(
    (state) => state.app.eventEventMessage
  )

  // The trigger to detect event atteendee updates
  const eventAttendeeEventMessage = useAppSelector(
    (state) => state.app.eventAttendeeEventMessage
  )

  // イベントに紐づくチャットルームIDを取得する
  const { data: getChatRoomResponse } = useGetChatRoomByEntityQuery({
    entityType: EntityType.Event,
    entityRecordId: eventId as string,
  })

  // イベント詳細の取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const useGetEventParam: GetEventRequest | undefined = eventId
    ? {
        eventId,
      }
    : undefined

  const {
    data: getEventQueryResult,
    refetch: getEventQueryRefetch,
    isLoading: isLoadingEvent,
    isError: isErrorEvent,
  } = useGetEventQuery(useGetEventParam ?? skipToken)

  //TODO: 新規登録ダイアログからの遷移時に、Queryが走らないため強制的に走るようにはしているが、CREW-7202で実施したデータの取り回しの変更内容を含めてこの対応が適切かどうか検討する必要がある
  //      https://break-tmc.atlassian.net/browse/CREW-8131
  // Event handle when the data of event is changed, or the data of event attendee is changed
  useEffect(() => {
    if (eventId) {
      getEventQueryRefetch()
    }
  }, [
    eventId,
    getEventQueryRefetch,
    eventEventMessage,
    eventAttendeeEventMessage,
  ])

  // 取得したチャットルームIDに紐づくチャットメッセージを取得する
  useEffect(
    () => {
      if (getChatRoomResponse?.chatRoom && eventId) {
        // 表示するチャットルームを設定する
        chatCurrentService.setCurrentChatRoomAndRestoreThread({
          id: getChatRoomResponse.chatRoom.id,
          entityType: EntityType.Event,
          entityRecordId: eventId,
        })
      }
    },
    // マウント時のみ値を格納とするので、chatStyleについての依存は不要。
    // chatStyleの依存を追加してしまうと、表示形式を切り替えるだけでチャットルームの変更dispatchが走ってしまい挙動がおかしくなるため。
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getChatRoomResponse?.chatRoom?.id]
  )

  // アンマウント時、現在のチャットルーム情報をリセット
  useUnmount(() => {
    chatCurrentService.resetCurrentChatRoom()
  })

  // 参加確認の選択状態（LocalState）
  const [confirmAttendance, setConfirmAttendance] = useState<boolean>(false)

  // 会議中かどうかの状態を取得
  const meetingStatus = useMeetingStatus()
  // ミーティング中か
  const inMeeting = useMemo(() => {
    const isMeetingNotStarted =
      meetingStatus === MeetingStatus.Loading ||
      meetingStatus === MeetingStatus.Left ||
      meetingStatus === MeetingStatus.Ended

    // 会議開始の失敗
    const isMeetingFailed =
      meetingStatus === MeetingStatus.Failed ||
      meetingStatus === MeetingStatus.TerminalFailure

    return !isMeetingNotStarted && !isMeetingFailed
  }, [meetingStatus])

  const showMeetingPanel = useAppSelector(
    (state) => state.eventDetailPage.showMeetingPanel
  )

  return (
    <eventDetailPageContext.Provider
      value={{
        confirmAttendance,
        setConfirmAttendance,
      }}
    >
      <div className="flex flex-1 gap-2 min-h-0">
        <main className="grow flex flex-col h-full w-2/3 overflow-y-auto">
          <CrewErrorBoundary>
            <div className="flex flex-col pb-2">
              {getEventQueryResult?.event && (
                <EventDetailPanel
                  event={getEventQueryResult?.event}
                  isLoadingEvent={isLoadingEvent}
                  isErrorEvent={isErrorEvent}
                />
              )}
            </div>
          </CrewErrorBoundary>
          <CrewErrorBoundary>
            {inMeeting && showMeetingPanel ? (
              // Web会議中
              <div className="flex-1 flex flex-col min-h-0 w-full p-2">
                <EventDetailWebMeetingPanel />
              </div>
            ) : (
              // 各一覧表示
              <div className="min-h-0 flex-1 flex flex-col grow w-full">
                {/* イベント詳細タブ */}
                <div className="px-2">
                  <EventDetailTab />
                </div>
                <div className="grow min-h-0 w-full flex flex-col h-full overflow-y-scroll">
                  {/* URLに応じて表示するコンポーネントを切り替える */}
                  <Routes>
                    {/* 
                      TODO: 一時的にデフォルトルート（index）を除去している
                      以下タスク対応時にデフォルトルートを設定する予定
                      https://break-tmc.atlassian.net/browse/CREW-9163
                    */}
                    <Route
                      path={EventDetailTabs.Detail.value}
                      element={
                        <EventDescriptionPanel
                          eventId={getEventQueryResult?.event?.id}
                          description={getEventQueryResult?.event?.description}
                          fileHistories={
                            getEventQueryResult?.event?.fileHistories
                          }
                        />
                      }
                    />
                    <Route
                      path={EventDetailTabs.MeetingMinutes.value}
                      element={<EventDetailMeetingMinutesPanel />}
                    />
                    <Route
                      path={EventDetailTabs.Task.value}
                      element={<EventDetailTaskList />}
                    />
                    <Route
                      path={EventDetailTabs.File.value}
                      element={<EventDetailFileList />}
                    />
                    <Route
                      path={EventDetailTabs.Attendee.value}
                      element={<EventDetailAttendeeList />}
                    />
                  </Routes>
                </div>
              </div>
            )}
          </CrewErrorBoundary>
        </main>
      </div>
    </eventDetailPageContext.Provider>
  )
})
