import { EntityType } from '@crew/enums/domain'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import Star from '~icons/material-symbols/star'
import classNames from 'classnames'
import { useCrewFavorite } from './useCrewFavorite'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'

export type CrewFavoriteProps = {
  entityType: EntityType
  entityRecordId: string
  isFavorite: boolean
}
export const CrewFavorite: FC<CrewFavoriteProps> = memo((props) => {
  const { unsubscribeFavorite, subscribeFavorite } = useCrewFavorite()

  const { t } = useTranslation()
  const { error } = useToast()

  const [isFavorite, setIsFavorite] = useState<boolean>(props.isFavorite)

  const favoriteBaseClassNames = useMemo(() => {
    return 'flex items-center justify-center relative cursor-pointer'
  }, [])

  const targetColorClassName = useMemo(() => {
    return isFavorite ? 'text-crew-yellow-400' : 'text-crew-gray-400'
  }, [isFavorite])

  // Event handler for clicking the favorite button
  const handleSubscribeToFavoritesButtonClick = useCallback(async () => {
    try {
      const response = await subscribeFavorite(
        props.entityType,
        props.entityRecordId
      )
      if (response) {
        setIsFavorite(true)
      }
    } catch (e) {
      error(t('message.favorite.failedToFavorite'))
    }
  }, [error, subscribeFavorite, props.entityRecordId, props.entityType, t])

  // Event handler for clicking the unfavorite button
  const handleUnSubscribeFromFavoritesButtonClick = useCallback(async () => {
    try {
      await unsubscribeFavorite(props.entityType, props.entityRecordId)
      setIsFavorite(false)
    } catch (e) {
      error(t('message.favorite.failedToUnFavorite'))
    }
  }, [error, props.entityRecordId, props.entityType, t, unsubscribeFavorite])

  return (
    <div
      className="flex gap-x-3 shrink-0"
      onClick={
        isFavorite
          ? handleUnSubscribeFromFavoritesButtonClick
          : handleSubscribeToFavoritesButtonClick
      }
    >
      <Star
        className={classNames(favoriteBaseClassNames, targetColorClassName)}
        width={20}
        height={20}
      />
    </div>
  )
})
