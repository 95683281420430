import { apis } from '../apiBase/apiBase';
export const filterApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getFilter: builder.query({
            query: (params) => {
                return {
                    url: `filters/${params.filterId}`,
                    method: 'GET',
                };
            },
        }),
        deleteFilter: builder.mutation({
            query: (params) => ({
                url: `filters/delete`,
                method: `POST`,
                body: params,
            }),
        }),
        getFilters: builder.query({
            query: (params) => {
                return {
                    url: `filters`,
                    method: 'GET',
                    params,
                };
            },
        }),
        replaceFilter: builder.mutation({
            query: (params) => ({
                url: `filters/replace`,
                method: `POST`,
                body: params,
            }),
        }),
        updateFilter: builder.mutation({
            query: (params) => ({
                url: `filters/update`,
                method: `POST`,
                body: params,
            }),
        }),
        checkExistenceFilterName: builder.query({
            query: (params) => {
                return {
                    url: 'filters/check-existence-filter-name',
                    method: 'GET',
                    params,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetFilterQuery, useLazyGetFilterQuery, useDeleteFilterMutation, useGetFiltersQuery, useLazyGetFiltersQuery, useReplaceFilterMutation, useUpdateFilterMutation, useLazyCheckExistenceFilterNameQuery, } = filterApis;
