import { memo } from 'react'

import { ChatPanel } from 'features/chat/components/chatPanel/chatPanel'
import { useProjectDetailChatPanel } from './useProjectDetailChatPanel'

export const ProjectDetailChatPanel = memo(() => {
  useProjectDetailChatPanel()

  return (
    <div className="flex flex-col grow min-h-0 h-full w-full">
      <ChatPanel />
    </div>
  )
})
