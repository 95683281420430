import { ChatView, TimelineDirection } from '@crew/enums/app'
import { ChatMessage } from '@crew/models/domain'
import {
  Chat,
  useChatCurrentService,
  useChatMessageService,
  useChatSearchService,
  useChatTimelineService,
  useChatThreadListService,
  useChatThreadService,
} from '@crew/states'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useLazyGetChatRoomMessagesQuery } from '@crew/apis/chat/chatApis'
import { useFetchLimit, useValueChangeEffect } from '@crew/hooks'
import { MESSAGE_AREA_MIN_HEIGHT } from '@crew/configs/constants'
import { getWindowDimensions } from 'utils'
import { GetChatRoomMessagesRequest } from '@crew/apis/chat/models/getChatRoomMessages/request'

export const MessageItemType = {
  /**
   * 基本的なスタイルで表示する。インデントなし、アバターはユーザアイコン大
   */
  NormalMessage: 'normalMessage',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageItemType =
  (typeof MessageItemType)[keyof typeof MessageItemType]

/** メッセージアイテムの型 */
export type DisplayMessageItem = {
  id: string
  messageId: string
  handleAdditionalLoading: (() => void) | undefined // 追加読込処理
}

/** messagesから表示用アイテムへ変換する関数の戻り値の型 */
type ConvertMessagesToDisplayItemsResult = {
  items: DisplayMessageItem[]
}

/** 複数メッセージ追加時のパラメータ */
type AddMessagesParams = {
  chatMessages: ChatMessage[]
  criterionMessageId: string | undefined
  direction: TimelineDirection
  isLoadedOfEndOfMessages: boolean
}

/** チャットメッセージをロードするためのパラメータ */
type LoadSearchParams = {
  criterionMessageId: string | undefined
  direction: TimelineDirection
}

/**
 * 引数のmessagesから表示用のアイテムリストを生成する関数
 * @param messages ViewModelから取得したSearch形式のmessages
 * @param loadChatMessages チャットメッセージをロードする関数
 * @returns 表示用アイテムリスト
 */
export const convertMessagesToDisplayItems = (
  messages: Chat.SearchMessage[],
  loadChatMessages: (params: LoadSearchParams) => void
): ConvertMessagesToDisplayItemsResult => {
  const items: DisplayMessageItem[] = []

  messages.forEach((messageItem, index) => {
    const messageId = messageItem.id

    // 追加読込の方向を決定する。必要無ければundefined
    const additionalLoadingDirection =
      messageItem.hasMorePrev && messageItem.hasMoreNext // 時間軸上で両方向の隣のメッセージが未読込だった場合はboth
        ? TimelineDirection.BothNewerAndOlder
        : messageItem.hasMorePrev // 時間軸上でprev方向の隣のメッセージが未読込だった場合はolder
        ? TimelineDirection.Older
        : messageItem.hasMoreNext // 時間軸上でnext方向の隣のメッセージが未読込だった場合はnewer
        ? TimelineDirection.Newer
        : undefined // 両方向の隣のメッセージが読込済だった場合は追加読込は不要

    // 追加読込処理を行う関数を生成する。必要無ければundefined
    const handleAdditionalLoading = additionalLoadingDirection
      ? () =>
          loadChatMessages({
            criterionMessageId: messageId,
            direction: additionalLoadingDirection,
          })
      : undefined

    items.push({
      id: `${MessageItemType.NormalMessage}_${messageId}`,
      messageId: messageId,
      handleAdditionalLoading,
    })
  })

  return { items }
}

export const useChatSearchMessageList = () => {
  const dispatch = useAppDispatch()

  // Sliceの操作を行うためのServiceを取得
  const chatMessageService = useChatMessageService(dispatch)
  const chatTimelineService = useChatTimelineService(dispatch)
  const chatThreadListService = useChatThreadListService(dispatch)
  const chatThreadService = useChatThreadService(dispatch)
  const chatSearchService = useChatSearchService(dispatch)
  const chatCurrentService = useChatCurrentService(dispatch)

  // チャットデータ取得API
  const [lazyGetChatRoomMessagesQuery] = useLazyGetChatRoomMessagesQuery()

  // 処理対象のチャットルームをViewModelから取得
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )
  // この処理が流れる際、ViewModelには必ずチャットルームが設定されているはずなので、未設定の場合はエラーとする
  if (!currentChatRoom) {
    throw new Error('currentChatRoom is undefined')
  }
  const currentChatRoomId = currentChatRoom.id

  // ViewModelに格納されているCurrentの表示Styleを取得する
  const currentStyle = useAppSelector(
    (state) => state.message.chat.current.style
  )
  // ViewModelに格納されているCurrentの表示Formatを取得する
  const currentDisplayFormat = useAppSelector(
    (state) => state.message.chat.current.displayFormat
  )

  // ViewModelに格納されている対象チャットルームのキーワードを取得する
  const keyword = useAppSelector(
    (state) => state.message.chat.search.entities[currentChatRoomId]?.keyword
  )
  // ViewModelに格納されている対象チャットルームの検索範囲を取得する
  const searchRange = useAppSelector(
    (state) =>
      state.message.chat.search.entities[currentChatRoomId]?.searchRange
  )
  // ViewModelに格納されている対象ターゲットのメッセージのDictionaryを取得する
  const chatSearchMessageDictionary = useAppSelector(
    (state) =>
      state.message.chat.search.entities[currentChatRoomId]?.messages.entities
  )
  // ViewModelに格納されている対象チャットルームのメッセージのIDリストを取得する
  // ViewModelに格納されている順番で取得する必要があるので、entitiesだけでなくidsも取得しておく必要がある
  const chatSearchMessageIds = useAppSelector(
    (state) =>
      state.message.chat.search.entities[currentChatRoomId]?.messages.ids
  )

  // 表示対象のメッセージを返す
  const chatSearchMessages = useMemo(() => {
    if (!chatSearchMessageIds || !chatSearchMessageDictionary) {
      return []
    }

    // idの降順にメッセージを設定する
    return chatSearchMessageIds.reduce((result: Chat.SearchMessage[], id) => {
      const message = chatSearchMessageDictionary[id]
      if (message) {
        result.push(message)
      }
      return result
    }, [])
  }, [chatSearchMessageIds, chatSearchMessageDictionary])

  /**
   * 複数アイテムをViewModelに追加する
   * @param params
   */
  const addItems = useCallback(
    (params: AddMessagesParams) => {
      // ViewModelへデータを追加
      const parameter = {
        chatRoomId: currentChatRoomId,
        messages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfMessages,
      }
      chatSearchService.addChatSearchMessages(parameter)
    },
    [chatSearchService, currentChatRoomId]
  )

  /**
   * API経由で取得したチャットメッセージをメッセージのキャッシュとViewModelに追加する
   */
  const addMessages = useCallback(
    (params: AddMessagesParams) => {
      // キャッシュへ追加
      chatMessageService.addChatMessagesToCache({
        chatMessages: params.chatMessages,
      })
      // ViewModelへ追加
      addItems({
        chatMessages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfMessages: params.isLoadedOfEndOfMessages,
      })
    },
    [addItems, chatMessageService]
  )

  // 一度にfetchするサイズ
  const fetchLimit = useFetchLimit(MESSAGE_AREA_MIN_HEIGHT, getWindowDimensions)

  /**
   * チャットメッセージをタイムラインにロードするメソッド
   * @param params 対象メッセージを絞り込むためのパラメータ
   * @returns
   */
  const loadChatMessages = useCallback(
    async (params: LoadSearchParams) => {
      if (!searchRange) {
        return
      }

      const request: GetChatRoomMessagesRequest = {
        filter: searchRange,
        keyword: keyword ?? '',
        chatRoomId: currentChatRoomId,
        threadRootOnly: false, // スレッドルートだけでなくすべて取得する
        threadRootMessageId: undefined,
        normalMessageOnly: true, // 通常メッセージのみを検索対象とする
        criterionMessageId: params.criterionMessageId ?? undefined,
        direction: params.direction,
        limit: fetchLimit,
      }

      try {
        const data = await lazyGetChatRoomMessagesQuery(request).unwrap()

        const isLoadedOfEndOfMessages = request.limit > data.chatMessages.length

        const messagesAddedPayload: AddMessagesParams = {
          chatMessages: data.chatMessages,
          ...request, // 型が違うがduck typeによって代入可能
          isLoadedOfEndOfMessages,
        }

        addMessages(messagesAddedPayload)
      } catch (err) {
        // TODO: CREW-13720の対応で、プロジェクトから退出した際にエラートーストが出てしまう問題が発生し、トースト表示をコメントアウトする暫定対応を行った
        // ただ、これにより追加ロード時にエラーがあっても画面上表示されないという状態であるため、以下タスクで恒久対応を行う
        // 現時点ではコンソールにエラーを表示するにとどめる
        // https://break-tmc.atlassian.net/browse/CREW-13724
        // toast.error(t('message.general.failedToRetrieveData'))
        console.error(err)
      }
    },
    [
      addMessages,
      currentChatRoomId,
      fetchLimit,
      keyword,
      lazyGetChatRoomMessagesQuery,
      searchRange,
    ]
  )

  // チャット表示用アイテムリスト
  const { items: displayItems } = useMemo(
    () => convertMessagesToDisplayItems(chatSearchMessages, loadChatMessages),
    [chatSearchMessages, loadChatMessages]
  )

  // メッセージアイテムをクリックしたときの処理
  const displayTargetChatMessage = useCallback(
    (id: string, parentChatMessageId: string | null) => {
      const item = displayItems.find((item) => item.id === id)

      if (!item) {
        console.error(`[Chat] handleClickItem: Unknown message id. ${id}`)
        return
      }

      const topicId = parentChatMessageId ?? item.messageId
      // 検索元のViewに応じて選択したメッセージIDを保存する処理を分岐する
      switch (currentDisplayFormat) {
        case ChatView.Timeline:
          // タイムライン形式
          // 検索で選択したメッセージをスクロールして表示する
          chatTimelineService.setTimelineSelectedMessageId({
            chatMessageId: item.messageId,
            chatRoomId: currentChatRoomId,
          })
          // 2ペインの場合はサブパネルに対象のトピックを表示する
          if (currentStyle === Chat.Style.Full) {
            // メインパネル
            chatTimelineService.setTimelineTopicId({
              chatMessageId: topicId,
              chatRoomId: currentChatRoomId,
            })
            // サブパネル
            // -> 検索で選んだメッセージがトピックではないメッセージの場合、スクロールして表示する
            if (item.messageId !== topicId) {
              chatThreadService.setChatThreadSelectedMessageId({
                chatMessageId: item.messageId,
                topicId: topicId,
              })
            }
          }
          break
        case ChatView.ThreadList:
          // スレッド形式
          // 検索で選んだメッセージのトピックメッセージをスクロールして表示する
          chatThreadListService.setThreadListSelectedMessageId({
            chatMessageId: topicId,
            chatRoomId: currentChatRoomId,
          })
          // 2ペインの場合はサブパネルに対象のトピックを表示する
          if (currentStyle === Chat.Style.Full) {
            // メインパネル
            chatThreadListService.setThreadListTopicId({
              chatMessageId: topicId,
              chatRoomId: currentChatRoomId,
            })
            // サブパネル
            // -> 検索で選んだメッセージがトピックではないメッセージの場合、スクロールして表示する
            if (item.messageId !== topicId) {
              chatThreadService.setChatThreadSelectedMessageId({
                chatMessageId: item.messageId,
                topicId: topicId,
              })
            }
          } else {
            // コンパクトパネル
            // 選んだメッセージが返信メッセージの場合はInThreadを表示する
            if (topicId !== item.messageId) {
              // メインパネル
              chatThreadListService.setThreadListTopicId({
                chatRoomId: currentChatRoom.id,
                chatMessageId: topicId,
              })
              // 選択したメッセージをスクロールして表示する
              chatThreadService.setChatThreadSelectedMessageId({
                chatMessageId: item.messageId,
                topicId: topicId,
              })
            }
          }
      }
      // モードを「チャット」に戻す
      chatCurrentService.setCurrentMode(Chat.Mode.Chat)
    },
    [
      chatCurrentService,
      chatThreadListService,
      chatThreadService,
      chatTimelineService,
      currentChatRoom.id,
      currentChatRoomId,
      currentDisplayFormat,
      currentStyle,
      displayItems,
    ]
  )

  // キーワードが変わったら最初から検索しなおし
  useValueChangeEffect(
    () => {
      if (chatSearchMessages.length > 0) {
        // 前回検索結果が残っている場合は検索しない
        return
      }

      if (!keyword) {
        // キーワードが空の場合は検索しない
        return
      }

      loadChatMessages({
        // 最新のメッセージからOlder方向に取得する
        criterionMessageId: undefined,
        direction: TimelineDirection.Older,
      })
    },
    [chatSearchMessages.length, keyword, loadChatMessages],
    keyword,
    true // 初回ロード時には実行しない（スキップする）
  )

  return {
    keyword,
    displayItems,
    displayTargetChatMessage,
  }
}
