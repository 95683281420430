import { RoleType } from '@crew/enums/domain'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useRoleDataSource } from 'hooks/dataSource/useRoleDataSource'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { ValidateRules } from '@crew/utils/dist/form'
import { useLazyGetNonProjectMembersQuery } from '@crew/apis/dist/project/projectApis'
import { useTranslation } from '@crew/modules/dist/i18n'
import { User } from '@crew/apis/project/models/getNonProjectMembers/response'

export type FormValues = {
  roleId: string | null
  users: User[]
}

const formInitialValues: FormValues = {
  roleId: null,
  users: [],
}

export const useAddExistingUserToolbar = () => {
  const { t } = useTranslation()

  const { projectId } = useParams()

  const [lazyGetNonProjectMembersQuery] = useLazyGetNonProjectMembersQuery()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, reset, formState } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  //set data source for role dropdown
  const roleDataSource = useRoleDataSource(RoleType.Project, undefined, true) // true: include user-defined role

  //set data source for project members
  const noneProjectMembersDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したユーザIDのフィルタリング
          const filteringUserIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetNonProjectMembersQuery({
            projectId: projectId as string,
            displayName: undefined,
            userId: filteringUserIds,
          }).unwrap()

          return response.users
        } else {
          // インクリメンタルサーチ
          const response = await lazyGetNonProjectMembersQuery({
            projectId: projectId as string,
            displayName: loadOptions.searchValue,
            userId: undefined,
          }).unwrap()

          return response.users
        }
      },
    }),
    [lazyGetNonProjectMembersQuery, projectId]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      users: {
        required: t('message.general.required'),
      },
      roleId: {
        required: t('message.general.required'),
      },
    }),
    [t]
  )

  return {
    control,
    handleSubmit,
    reset,
    formState,

    roleDataSource,
    noneProjectMembersDataSource,

    validateRules,
  }
}
