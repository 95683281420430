import { ProjectRef } from '@crew/models/refs'
import { convertMinutesToHHMM } from '@crew/utils'
import { memo, FC } from 'react'

type WorkingTime = {
  taskId: string
  project: ProjectRef
  subject: string
  workTimesId: string
  actualWorkTimes: number
  workDate: string
}

type WorkingTimeItemProps = {
  workingTime: WorkingTime
}

export const WorkingTimeItem: FC<WorkingTimeItemProps> = memo((props) => {
  return (
    <div className="flex flex-col p-0.5 bg-crew-blue-100 text-crew-gray-900">
      <span className="font-bold line-clamp-3">
        {props.workingTime.subject}
      </span>

      <div className="flex gap-2.5 items-center justify-between">
        <span className="line-clamp-3">
          {props.workingTime.project.subject}
        </span>
        <span>
          {convertMinutesToHHMM(props.workingTime.actualWorkTimes ?? 0)}
        </span>
      </div>
    </div>
  )
})
