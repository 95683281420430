import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Scheduler, { Resource, SchedulerTypes } from 'devextreme-react/scheduler'
import Toolbar from 'devextreme/ui/toolbar'
import { ContentReadyEvent } from 'devextreme/ui/scheduler'
import { isArray } from 'lodash'
import { useTranslation } from '@crew/modules/i18n'
import { ValueChangedEvent } from 'devextreme/ui/select_box'
import { ScheduleAddUserDialog } from '../scheduleAddUserDialog/scheduleAddUserDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { EventKindRef, UserRef } from '@crew/models/refs'
import { GroupScheduleFilterType } from '@crew/enums/domain'
import { CrewUserItem } from 'components/elements/crewUserItem/crewUserItem'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useScheduleGroupMonthly } from './useScheduleGroupMonthly'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useParticipatedProjectDataSource } from 'hooks/dataSource/userParticipatedProjectDataSource'
import { useScheduleGroupMonthDataSource } from 'hooks/dataSource/useScheduleGroupMonthDataSource'
import { Appointment } from './components/appointment/appointment'
import { DataCell } from './components/dataCell/dataCell'
import { DateCell } from './components/dateCell/dateCell'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useAppSelector } from 'states/hooks'
import {
  CrewEventPopover,
  EventPopover,
} from 'components/elements/crewEventPopover/crewEventPopover'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { EventEntryDialog } from 'features/project/components/eventEntryDialog/eventEntryDialog'
import BaselineDelete from '~icons/ic/baseline-delete'
import { useLazyGetMyRoleInEventQuery } from '@crew/apis/project/projectApis'
import {
  CrewBadgeInvertedColorClass,
  CrewBadgeInvertedColorToHex,
} from 'enums/color'

const DEFAULT_TOOLBAR_ITEMS_COUNT = 2 // date navigator, view switcher

const groups = ['user.id']
const views: SchedulerTypes.ViewType[] = ['timelineMonth']

type Event = {
  id: string | null
  subject: string | null
  description: string | null
  startDatetime: string
  endDatetime: string
  entityRecordId: string | null
  eventKind: EventKindRef | null
  eventAttendees: UserRef[]
}

type AppointmentType = {
  data: { appointmentData: Event }
}

// Render date cell
const renderDateCell = ({ date }: { date: Date }) => <DateCell date={date} />

export const ScheduleGroupMonthly: FC = memo(() => {
  const { t } = useTranslation()
  const [showApiErrors] = useShowApiErrors()
  const schedulerRef = useRef<Scheduler>(null)
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  const eventEventMessage = useAppSelector(
    (state) => state.app.eventEventMessage
  )

  const [lazyGetMyRoleInEventQuery] = useLazyGetMyRoleInEventQuery()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  const [isOpenAddUserDialog, openAddUserDialog, closeAddUserDialog] =
    useModal()
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()
  const [isEventEntryDialogOpen, openEventEntryDialog, closeEventEntryDialog] =
    useModal()

  const myParticipatedProjectDataSource = useParticipatedProjectDataSource()

  const [currentDate, setCurrentDate] = useState(new Date())
  const [filterValue, setFilterValue] = useState(
    GroupScheduleFilterType.GroupScheduleCustomFilter
  )
  const [selectedDeleteMemberId, setSelectedDeleteMemberId] = useState<string>()

  const projectId = useMemo(
    () =>
      filterValue === GroupScheduleFilterType.GroupScheduleCustomFilter
        ? undefined
        : filterValue,
    [filterValue]
  )

  const {
    usersDataSource,
    insertCustomScheduleMembers,
    deleteCustomScheduleMember,
  } = useScheduleGroupMonthly(projectId)

  const groupScheduleDataSource = useScheduleGroupMonthDataSource(
    currentDate,
    filterValue,
    projectId
  )

  useEffect(() => {
    // Reload the users data source
    if (groupScheduleDataSource.isLoaded()) {
      groupScheduleDataSource.reload()
    }
  }, [eventEventMessage, groupScheduleDataSource])

  // Event handler for when the participated project selectbox is changed
  const handleFilterValueChange = useCallback((event: ValueChangedEvent) => {
    // Set the selected project
    setFilterValue(event.value)
  }, [])

  // Event handler for when the add user button is clicked
  const handleAddUserButtonClick = useCallback(() => {
    openAddUserDialog()
  }, [openAddUserDialog])

  // Event handler for when the content is ready
  // https://supportcenter.devexpress.com/ticket/details/t958543/scheduler-customize-toolbar
  const onContentReady = useCallback(
    (e: ContentReadyEvent) => {
      // find group header
      const groupHeader = e.element.querySelector(
        '.dx-scheduler-header-panel-empty-cell'
      )

      if (groupHeader) {
        // set group header text
        groupHeader.textContent = t('label.user')
        groupHeader.classList.add('flex', 'items-center', 'justify-center')
      }

      const dxToolbar = e.element.querySelector('.dx-toolbar')
      if (!dxToolbar) return

      const toolbarInstance = Toolbar.getInstance(dxToolbar)

      const toolbarItems = toolbarInstance.option('items')

      // If the toolbar items are the default count, add the select box and button
      if (
        isArray(toolbarItems) &&
        toolbarItems.length === DEFAULT_TOOLBAR_ITEMS_COUNT
      ) {
        toolbarInstance.option('items', [
          toolbarItems[0],
          {
            widget: 'dxSelectBox',
            options: {
              dataSource: myParticipatedProjectDataSource,
              displayExpr: 'subject',
              valueExpr: 'id',
              value: filterValue,
              onValueChanged: handleFilterValueChange,
              deferRendering: false,
            },
            location: 'after',
          },
          {
            widget: 'dxButton',
            location: 'after',
            options: {
              text: t('action.addUser'),
              onClick: handleAddUserButtonClick,
              // プロジェクトを選択した場合
              // ユーザー追加ボタンは使用不可
              // 「カスタム」を選択した場合
              // ユーザー追加ボタンが使用可
              disabled:
                filterValue !==
                GroupScheduleFilterType.GroupScheduleCustomFilter,
            },
          },
        ])
      } else {
        // Disable the add user button if the selected project is not "Custom"
        toolbarInstance.option(
          'items[2].options.disabled',
          filterValue !== GroupScheduleFilterType.GroupScheduleCustomFilter
        )
      }
    },
    [
      filterValue,
      handleAddUserButtonClick,
      handleFilterValueChange,
      myParticipatedProjectDataSource,
      t,
    ]
  )

  // Event handler for when the current date is changed
  const handleCurrentDateChange = useCallback(
    (value: string | number | Date) => {
      setCurrentDate(new Date(value))
    },
    []
  )

  // Render resource cell (user)
  const renderResourceCell = useCallback(
    ({ data }: { data: UserRef }) => {
      // Event handler for when the delete button is clicked
      const handleDeleteButtonClick = () => {
        openConfirmDialog()

        // Set the selected delete member id
        setSelectedDeleteMemberId(data.id)
      }

      return (
        <div className="max-w-full w-full py-0.5">
          <div className="flex flex-row items-center">
            <div className="grow min-w-0">
              <CrewUserItem
                id={data.id}
                displayName={data.displayName}
                version={data.version}
              />
            </div>

            {/* Show delete button only custom schedule members */}
            {filterValue ===
              GroupScheduleFilterType.GroupScheduleCustomFilter &&
              loggedInUser?.id !== data.id && (
                <CrewButton
                  icon={<BaselineDelete width={20} height={20} />}
                  stylingMode="text"
                  onClick={handleDeleteButtonClick}
                />
              )}
          </div>
        </div>
      )
    },
    [filterValue, loggedInUser?.id, openConfirmDialog]
  )

  // Event handler for when an appointment is rendered
  const handleAppointmentRendered = useCallback(
    (e: SchedulerTypes.AppointmentRenderedEvent) => {
      e.appointmentElement.style.height = 'auto'
      // If is private event (id is null), set the background color to gray
      if (!e.appointmentData.id) {
        e.appointmentElement.style.backgroundColor = '#d4d4d4'
        // Add disabled class
        e.appointmentElement.className += ' dx-state-disabled'
      } else {
        // Set the background color to the event color
        e.appointmentElement.style.backgroundColor =
          CrewBadgeInvertedColorToHex[
            e.appointmentData.eventKind
              .displayColor as CrewBadgeInvertedColorClass
          ]
      }
    },
    []
  )

  // Event handler for when an appointment is clicked
  const handleAppointmentClick = useCallback(
    (e: SchedulerTypes.AppointmentClickEvent) => {
      // If is private event (id is null), cancel the event
      if (!e.appointmentData.id) {
        e.cancel = true
      }
    },
    []
  )

  // Event handler for when an appointment is double clicked
  const handleAppointmentDblClick = useCallback(
    (e: SchedulerTypes.AppointmentDblClickEvent) => {
      e.cancel = true

      // If is private event (id is null), cancel the event
      if (!e.appointmentData.id) return

      openEventEntryDialog()
    },
    [openEventEntryDialog]
  )

  // Event handler for adding a custom user
  const handleAddCustomUser = useCallback(
    async (users: UserRef[]) => {
      closeAddUserDialog()

      try {
        const userIds = users.map((user) => user.id)
        await insertCustomScheduleMembers(userIds)

        // Refresh the users data source
        usersDataSource.reload()

        // Refresh the scheduler
        // https://supportcenter.devexpress.com/ticket/details/t878136/scheduler-how-to-update-resources-on-view-change
        schedulerRef.current?.instance.option(
          'resources[0].dataSource',
          usersDataSource
        )
      } catch (error) {
        showApiErrors(error)
      }
    },
    [
      closeAddUserDialog,
      insertCustomScheduleMembers,
      showApiErrors,
      usersDataSource,
    ]
  )

  // Event handler for when the permit button is clicked
  const handlePermitButtonClick = useCallback(async () => {
    try {
      closeConfirmDialog()

      if (!selectedDeleteMemberId) return

      await deleteCustomScheduleMember(selectedDeleteMemberId)

      // Reset the selected delete member id
      setSelectedDeleteMemberId(undefined)

      // Refresh the users data source
      usersDataSource.reload()

      // Refresh the scheduler
      // https://supportcenter.devexpress.com/ticket/details/t878136/scheduler-how-to-update-resources-on-view-change
      schedulerRef.current?.instance.option(
        'resources[0].dataSource',
        usersDataSource
      )
    } catch (error) {
      showApiErrors(error)
    }
  }, [
    closeConfirmDialog,
    deleteCustomScheduleMember,
    selectedDeleteMemberId,
    showApiErrors,
    usersDataSource,
  ])

  // Render appointment tooltip
  const renderAppointmentTooltip = useCallback((item: AppointmentType) => {
    // when the event is private, the appointmentData property is null
    // so return null to prevent the tooltip from being displayed
    if (
      !item.data.appointmentData.id ||
      !item.data.appointmentData.eventKind ||
      !item.data.appointmentData.subject ||
      !item.data.appointmentData.entityRecordId
    ) {
      return null
    }

    const data: EventPopover = {
      id: item.data.appointmentData.id,
      description: item.data.appointmentData.description,
      attendees: item.data.appointmentData.eventAttendees,
      startDatetime: item.data.appointmentData.startDatetime,
      endDatetime: item.data.appointmentData.endDatetime,
      entityRecordId: item.data.appointmentData.entityRecordId,
      eventKind: item.data.appointmentData.eventKind,
      subject: item.data.appointmentData.subject,
    }
    return <CrewEventPopover data={data} />
  }, [])

  // Customize date navigator display text
  const customizeDateNavigatorText = useCallback(
    (info: SchedulerTypes.DateNavigatorTextInfo) => {
      return t('format.yearMonthWithText', {
        value: info.startDate,
      })
    },
    [t]
  )

  // Register event finish
  const handleEventRegistered = useCallback(
    (eventId: string, deleteFlg: boolean = false) => {
      // Close entry dialog
      closeEventEntryDialog()
    },
    [closeEventEntryDialog]
  )

  // Open event entry dialog
  const handleOpenEventEntryDialog = useCallback(
    (e: SchedulerTypes.AppointmentFormOpeningEvent) => {
      e.cancel = true

      // Open crew event entry dialog
      openEventEntryDialog()
    },
    [openEventEntryDialog]
  )

  // Event handler for when an appointment is updated
  const handleAppointmentUpdating = useCallback(
    async (e: SchedulerTypes.AppointmentUpdatingEvent) => {
      e.cancel = true

      // Get my role in the event
      const result = await lazyGetMyRoleInEventQuery({
        eventId: e.newData.id,
      }).unwrap()

      // Update the event if the user is a project member
      if (result.isProjectMember) {
        await groupScheduleDataSource.store().update(e.newData.id, e.newData)
        groupScheduleDataSource.reload()
      }
    },
    [groupScheduleDataSource, lazyGetMyRoleInEventQuery]
  )

  return (
    <div className="flex-1">
      <Scheduler
        id="schedulerGroupMonthly"
        timeZone={defaultUserProfileRegion as string}
        ref={schedulerRef}
        dataSource={groupScheduleDataSource}
        defaultCurrentView="timelineMonth"
        startDateExpr="startDatetime"
        endDateExpr="endDatetime"
        onContentReady={onContentReady}
        dateCellRender={renderDateCell}
        dataCellComponent={DataCell}
        onCurrentDateChange={handleCurrentDateChange}
        customizeDateNavigatorText={customizeDateNavigatorText}
        resourceCellRender={renderResourceCell}
        showCurrentTimeIndicator={false}
        groups={groups}
        views={views}
        appointmentComponent={Appointment}
        onAppointmentRendered={handleAppointmentRendered}
        onAppointmentClick={handleAppointmentClick}
        onAppointmentDblClick={handleAppointmentDblClick}
        height="100%"
        appointmentTooltipComponent={renderAppointmentTooltip}
        onAppointmentFormOpening={handleOpenEventEntryDialog}
        onAppointmentUpdating={handleAppointmentUpdating}
      >
        <Resource fieldExpr="user.id" dataSource={usersDataSource} />
      </Scheduler>

      {isOpenAddUserDialog && (
        <ScheduleAddUserDialog
          title={t('label.addUser')}
          isOpen={isOpenAddUserDialog}
          onClose={closeAddUserDialog}
          onAdd={handleAddCustomUser}
        />
      )}

      <CrewConfirmDialog
        message={t('message.general.confirmMessage.delete')}
        isOpen={isConfirmDialogOpen}
        onCancelButtonClick={closeConfirmDialog}
        onPermitButtonClick={handlePermitButtonClick}
      />

      <EventEntryDialog
        isEditMode={false}
        title={t('label.registerNewMeeting')}
        isOpen={isEventEntryDialogOpen}
        onClose={closeEventEntryDialog}
        onSubmit={handleEventRegistered}
      />
    </div>
  )
})
