import { memo } from 'react'
import BaselineDelete from '~icons/ic/baseline-delete'
import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { CrewButton } from 'components/elements/crewButton/crewButton'

export type CrewFileActionProps = {
  fileId: string
  entityType: EntityType
  entityRecordId?: string
  onDelete: () => void
  showDelete?: boolean
}

export const CrewFileAction = memo((props: CrewFileActionProps) => {
  const { hasPrjFileDeletePermission } = useProjectPermissions(
    props.entityType,
    props.entityRecordId ?? props.fileId
  )

  return hasPrjFileDeletePermission ? (
    <CrewButton
      stylingMode="text"
      icon={<BaselineDelete width={20} height={20} />}
      onClick={props.onDelete}
    />
  ) : (
    <></>
  )
})
