import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLogger } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/LoggerProvider'
import {
  useLocalVideo,
  useMeetingManager,
  useVideoInputs,
} from 'modules/amazon-chime-sdk-component-library-devextreme'
import {
  DefaultVideoTransformDevice,
  Device,
  isVideoTransformDevice,
  VideoInputDevice,
} from 'amazon-chime-sdk-js'

/**
 * 実装メモ:
 * ビデオデバイス: カメラなど、大元のビデオを提供するデバイス。
 * ビデオ入力デバイス  : Chime SDKにビデオを入力するデバイス。ビデオデバイスの場合とビデオ変換デバイス(エフェクト効果)の場合がある。
 *
 * - エフェクト有効状態
 *   - [ビデオデバイス]-[ビデオ変換デバイス]-[ChimeSDK]
 *                                     ↑ビデオ入力デバイス
 * - エフェクト無効状態
 *   - [ビデオデバイス]-[ChimeSDK]
 *              ↑ビデオ入力デバイス
 */

/**
 * デバイスからIDを取得する。ビデオ変換デバイスには対応しない。統一的にデバイスIDを取得する方法がないか、そもそもIDが存在しないため
 * @param device IDを取得したいデバイス
 * @returns デバイスID
 */
const getDeviceId = (device: Device | undefined): string | undefined => {
  if (device === undefined) {
    return undefined
  }

  if (typeof device === 'string') {
    return device
  }
  if ('deviceId' in device) {
    // MediaTrackConstraints

    if (typeof device.deviceId === 'string') {
      return device.deviceId
    }

    console.warn(
      `Failed to get deviceId from MediaTrackConstraints: ${JSON.stringify(
        device
      )}`
    )
    return undefined
  } else if ('id' in device) {
    // MediaStream

    return device.id
  } else {
    console.warn(
      `Failed to get deviceId from Device: ${JSON.stringify(device)}`
    )
    return undefined
  }
}

/**
 * 指定したビデオ入力デバイスから大元のビデオデバイスを取得する
 * @param videoInputDevice ビデオ入力デバイス
 * @returns ビデオデバイス
 */
const getVideoDeviceFromVideoInputDevice = async (
  videoInputDevice: VideoInputDevice | undefined
): Promise<Device | undefined> => {
  if (isVideoTransformDevice(videoInputDevice)) {
    return getVideoDeviceFromVideoInputDevice(
      await videoInputDevice.intrinsicDevice()
    )
  }
  return videoInputDevice
}

/**
 * カメラを選択するセレクトボックス
 */
export const useCrewCameraSelectBox = () => {
  const logger = useLogger()
  const meetingManager = useMeetingManager()
  const { devices, selectedDevice: selectedVideoInputDevice } = useVideoInputs()
  const { isVideoEnabled } = useLocalVideo()

  // 現在のビデオデバイス
  // selectedDevice はビデオ入力デバイスでありそのままビデオデバイスとして扱うことができないので、別途保持する
  const [currentVideoDevice, setCurrentVideoDevice] = useState<
    Device | undefined
  >(undefined)

  // 現在選択中のビデオ入力デバイス(selectedDevice)からビデオデバイスを取得する
  // 非同期処理になるためuseEffectを使う
  useEffect(() => {
    const updateDevice = async () => {
      const inputDevice = await getVideoDeviceFromVideoInputDevice(
        selectedVideoInputDevice
      )

      setCurrentVideoDevice(inputDevice)
    }

    updateDevice()
  }, [selectedVideoInputDevice])

  /**
   * UIで新たなビデオデバイスが選択されたときに呼び出されるcallback
   * @param newVideoDeviceId 新たなビデオデバイスのID
   * @param currentVideoDevice 現在使用しているビデオデバイス
   * @returns
   */
  const updateVideoDeviceAsync = useCallback(
    async (
      newVideoDeviceId: string,
      currentVideoDevice: Device | undefined
    ) => {
      let newDevice: VideoInputDevice = newVideoDeviceId

      if (getDeviceId(currentVideoDevice) === newVideoDeviceId) {
        // 新たに選択されたデバイスは既に使用中のデバイス、何もしない
        return
      }

      // 現在cdkに接続されているビデオ入力デバイスがビデオ変換デバイスの場合、そのまま活かしてビデオデバイスだけ差し替える
      if (isVideoTransformDevice(selectedVideoInputDevice)) {
        if (selectedVideoInputDevice instanceof DefaultVideoTransformDevice) {
          newDevice = selectedVideoInputDevice.chooseNewInnerDevice(newDevice)
        } else {
          logger.error('Transform device cannot choose new inner device')
        }
      }

      if (isVideoEnabled) {
        await meetingManager.startVideoInputDevice(newDevice)
      } else {
        meetingManager.selectVideoInputDevice(newDevice)
      }
    },
    [isVideoEnabled, logger, meetingManager, selectedVideoInputDevice]
  )

  const stateToValue = useCallback(
    (state: Device | undefined) => getDeviceId(state),
    []
  )

  /**
   * 選択可能なビデオ入力デバイスのリスト
   */
  const deviceList = useMemo(
    () =>
      devices.map((device) => ({
        id: device.deviceId,
        name: device.label,
      })),
    [devices]
  )

  return {
    currentVideoDevice,
    updateVideoDeviceAsync,
    stateToValue,
    deviceList,
  }
}
