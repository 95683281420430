import { memo, useCallback } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { MyTaskListSearchPanel } from './components/myTaskListSearchPanel/myTaskListSearchPanel'
import { useTranslation } from '@crew/modules/i18n'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useModal } from 'components/layouts/modal/useModal'
import { getDefaultTabValue } from '@crew/utils/enum'
import { TaskDetailListTabs } from 'enums/app'

export const MyTaskListToolbar = memo(() => {
  const { t } = useTranslation()

  const { navigate } = useCrewNavigate()

  const [isTaskEntryDialogOpen, openTaskEntryDialog, closeTaskEntryDialog] =
    useModal()

  // タスク登録完了時
  const handleTaskRegistered = useCallback(
    (taskId: string) => {
      closeTaskEntryDialog()

      // タスク詳細画面に遷移
      navigate(`/tasks/${taskId}/${getDefaultTabValue(TaskDetailListTabs)}`)
    },
    [closeTaskEntryDialog, navigate]
  )

  return (
    <>
      <div className="w-full flex gap-x-2.5 mb-4 justify-between">
        <div className="flex items-center gap-2">
          <MyTaskListSearchPanel />
        </div>
        {/* 新規タスクボタン */}
        <div className="flex flex-1">
          <CrewButton
            type="primary"
            className="ml-auto"
            text={t('action.newTask')}
            onClick={openTaskEntryDialog}
          />
        </div>
      </div>
      <TaskEntryDialog
        isEditMode={false}
        title={t('label.addTaskTitle')}
        onSubmit={handleTaskRegistered}
        isOpen={isTaskEntryDialogOpen}
        onClose={closeTaskEntryDialog}
      />
    </>
  )
})
