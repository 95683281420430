import { useCallback } from 'react'
import {
  useDeleteTaskCommentMutation,
  useDeleteTaskHistoryMutation,
} from '@crew/apis/task/taskApis'
import { TaskComment } from '@crew/models/dist/domain'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTaskCommentEvent,
} from 'features/app/states/appSlice'
import { useAppDispatch } from 'states/hooks'

export const useTaskDetailCommentItem = () => {
  const dispatch = useAppDispatch()

  // Get functions for delete task comment & task history
  const [deleteTaskCommentMutation] = useDeleteTaskCommentMutation()
  const [deleteTaskHistoryMutation] = useDeleteTaskHistoryMutation()

  // Delete task comment process
  const deleteTaskComment = useCallback(
    async (taskCommentId: string, version: number) => {
      //delete task comment
      await deleteTaskCommentMutation({
        taskCommentId,
        version,
      }).unwrap()

      // タスクコメントが削除された旨のEventMessageを通知
      const objectEventMessage: ObjectEventMessage<TaskComment> = {
        eventType: NotifyEventType.Deleted,
        id: taskCommentId,
        object: undefined,
      }

      dispatch(notifyTaskCommentEvent(objectEventMessage))
    },
    [deleteTaskCommentMutation, dispatch]
  )

  // Delete task history process
  const deleteTaskHistory = useCallback(
    async (taskHistoryId: string) => {
      //delete latest task history
      await deleteTaskHistoryMutation({
        taskHistoryId,
      }).unwrap()

      // タスクコメントが削除された旨のEventMessageを通知
      const objectEventMessage: ObjectEventMessage<TaskComment> = {
        eventType: NotifyEventType.Deleted,
        id: taskHistoryId,
        object: undefined,
      }
      dispatch(notifyTaskCommentEvent(objectEventMessage))
    },
    [deleteTaskHistoryMutation, dispatch]
  )

  return {
    deleteTaskComment,
    deleteTaskHistory,
  }
}
