import { memo } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { FileListTagsEntryForm } from 'features/file/components/fileListPage/components/fileListTagsEntryDialog/components/fileListTagsEntryForm'

type FileListTagsEntryDialogProps = {
  isOpen: boolean
  title: string
  fileIds: string[]
  projectIds: string[]
  onClose: () => void
}

export const FileListTagsEntryDialog = memo(
  (props: FileListTagsEntryDialogProps) => {
    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <FileListTagsEntryForm
          onCancel={props.onClose}
          fileIds={props.fileIds}
          projectIds={props.projectIds}
        />
      </Modal>
    )
  }
)
