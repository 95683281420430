import { ContextMenuItems } from 'enums/app'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'

export const useTaskDetailCommentItemActionMenu = (
  isHideContextMenuItemEdit: boolean | undefined
) => {
  const { t } = useTranslation()

  // ContextMenuに格納するuseDataSource用のデータ
  const contextMenuData = useMemo(() => {
    return (
      Object.values(ContextMenuItems)
        //hidden edit item if task detail comment item is last history item
        .filter(
          (item) =>
            item.action === ContextMenuItems.Delete.action ||
            (item.action === ContextMenuItems.Edit.action &&
              !isHideContextMenuItemEdit)
        )
        .map((item) => {
          return { action: item.action, label: t(item.label) }
        })
    )
  }, [isHideContextMenuItemEdit, t])

  // 実際にContextMenuに格納するDataSource定義
  const contextMenuDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        return contextMenuData
      },
    }),
    [contextMenuData]
  )

  return {
    contextMenuDataSource,
  }
}
