import { FC, memo } from 'react'
import { ProjectGroupListToolbar } from 'features/tenant/components/tenantSettingPage/components/projectGroupList/components/projectGroupListToolbar/projectGroupListToolbar'
import { ProjectGroupListGrid } from 'features/tenant/components/tenantSettingPage/components/projectGroupList/components/projectGroupListGrid/projectGroupListGrid'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

export const ProjectGroupList: FC = memo(() => {
  return (
    <div className="flex flex-col gap-2">
      <CrewErrorBoundary>
        <ProjectGroupListToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <ProjectGroupListGrid />
      </CrewErrorBoundary>
    </div>
  )
})
