import { CrewTaskPriorityIcon } from 'components/elements/crewTaskPriorityIcon/crewTaskPriorityIcon'
import { ReactNode } from 'react'
import { getTaskPriorityEnumByValue } from 'utils'

// SelectBoxのitemRenderに渡すコンポーネント。renderとして使われるのでmemoできない
export const CrewTaskPrioritySelectBoxItemRender = (data: {
  id: number
  name: string
}): ReactNode => {
  const taskPriority = getTaskPriorityEnumByValue(data.id)
  return taskPriority && <CrewTaskPriorityIcon taskPriority={taskPriority} />
}
