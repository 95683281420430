import { memo, useCallback } from 'react'
import { getImageData, tag as emojiTag } from '@crew/emoji-data'

export const EmojiButton: React.VFC<{
  shortName: string
  onChooseEmoji: (shortName: string) => void
}> = memo((props) => {
  const handleClick = useCallback(
    () => props.onChooseEmoji(props.shortName),
    [props]
  )

  const validEmoji = props.shortName && Boolean(getImageData(props.shortName))

  const innerText = validEmoji ? '' : '〓'

  const className = `${emojiTag.name} ${emojiTag.className}`

  const customData = {
    [`data-${emojiTag.shortNameCustomDataAttributeName}`]: props.shortName,
  }

  return (
    <span
      className="inline-block p-1.5 hover:bg-crew-gray-2-light hover:dark:bg-crew-gray-2-dark rounded cursor-pointer"
      onClick={handleClick}
    >
      <span
        className={className}
        {...customData}
        style={{
          display: 'block',
          width: '22px',
          height: '22px',
        }}
      >
        {innerText}
      </span>
    </span>
  )
})
