import { useCallback } from 'react'
import {
  useApproveProjectMemberPendingMutation,
  useDeleteProjectMemberPendingMutation,
  useDeleteProjectMembersMutation,
} from '@crew/apis/project/projectApis'
import { useAppDispatch } from 'states/hooks'
import { ProjectMember, Project } from '@crew/models/dist/domain'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyProjectSettingMemberEvent,
  notifyProjectEvent,
} from 'features/app/states/appSlice'

export const useMemberTable = () => {
  const dispatch = useAppDispatch()

  // Get functions for Add/Edit/Delete project members
  const [approveProjectMemberPendingMutation] =
    useApproveProjectMemberPendingMutation()
  const [deleteProjectMemberPendingMutation] =
    useDeleteProjectMemberPendingMutation()
  const [deleteProjectMembersMutation] = useDeleteProjectMembersMutation()

  // Approve project member pending process
  const approveProjectMemberPending = useCallback(
    async (projectId: string, projectMemberPendingId: string) => {
      // call API approve member to project
      const data = await approveProjectMemberPendingMutation({
        projectMemberPending: {
          projectMemberPendingId,
        },
      }).unwrap()

      //trigger event refresh project setting members list
      const objectEventMessage: ObjectEventMessage<ProjectMember> = {
        eventType: NotifyEventType.Updated,
        id: data.projectMember.userId,
        object: data.projectMember,
      }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessage))

      // プロジェクト詳細のメンバー再取得のためEventMessageをdispatch
      const objectEventMessageProject: ObjectEventMessage<Project> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: {
          id: projectId,
        },
      }
      dispatch(notifyProjectEvent(objectEventMessageProject))
    },
    [approveProjectMemberPendingMutation, dispatch]
  )

  // Delete project member pending process
  const deleteProjectMemberPending = useCallback(
    async (projectMemberPendingId: string) => {
      // Call API Cancel member
      await deleteProjectMemberPendingMutation({
        projectMemberPendingId,
      }).unwrap()

      //trigger event refresh project setting members list
      const objectEventMessage: ObjectEventMessage<ProjectMember> = {
        eventType: NotifyEventType.Deleted,
        id: projectMemberPendingId,
        object: undefined,
      }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessage))
    },
    [deleteProjectMemberPendingMutation, dispatch]
  )

  // Delete project members process
  const deleteProjectMembers = useCallback(
    async (projectId: string, userId: string) => {
      await deleteProjectMembersMutation({
        projectId,
        userIds: [userId],
      }).unwrap()

      //trigger event refresh project setting members list
      const objectEventMessageProjectMember: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Deleted,
          id: userId,
          object: {
            projectId,
            userId,
          },
        }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessageProjectMember))
      //trigger project detail refresh members list
      const objectEventMessageProject: ObjectEventMessage<Project> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: {
          id: projectId,
        },
      }
      dispatch(notifyProjectEvent(objectEventMessageProject))
    },
    [deleteProjectMembersMutation, dispatch]
  )

  return {
    approveProjectMemberPending,
    deleteProjectMemberPending,
    deleteProjectMembers,
  }
}
