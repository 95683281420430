import { apis } from '../apiBase/apiBase';
export const ogpApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getOgpData: builder.query({
            query: (params) => ({
                url: `ogp`,
                method: 'GET',
                params: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useGetOgpDataQuery, useLazyGetOgpDataQuery } = ogpApis;
