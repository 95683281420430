import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { CheckBox } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof CheckBox>>

/**
 * CheckBoxのラッパー（react-hook-form非対応版）
 */
export const CrewCheckBox = memo(
  forwardRef<CheckBox, Props>(({ children, ...rest }, ref) => (
    <CheckBox ref={ref} {...rest}>
      {children}
    </CheckBox>
  ))
)
