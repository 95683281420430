import {
  FC,
  MouseEventHandler,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { ChatMessageReference, FileHistory } from '@crew/models/domain'
import { CrewLoadingText } from 'components/elements/crewLoading/crewLoadingText/crewLoadingText'
import classNames from 'classnames'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { getDefaultTabValue } from '@crew/utils/enum'
import { EventDetailTabs } from 'enums/app'
import { EntityType, MessageType } from '@crew/enums/domain'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetEventQuery } from '@crew/apis/project/projectApis'
import { GetEventRequest } from '@crew/apis/project/models/getEvent/request'
import { useTranslation } from '@crew/modules/i18n'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { CrewAttachmentThumbnails } from 'components/elements//crewAttachmentThumbnails/crewAttachmentThumbnails'
import { CrewAttachments } from 'components/elements/crewAttachments/crewAttachments'
import { useProjectPermissions, useValueChangeEffect } from '@crew/hooks'
import { isImageFile } from '@crew/utils/dist/chat'
import { sanitizeHtmlAllowNothing } from 'utils/html'
import { useLazyGetFileHistoryQuery } from '@crew/apis/dist/file/fileApis'

export type CrewEventNotificationChatMessageProps = {
  messageType: MessageType
  chatMessageReferences: ChatMessageReference[]
  truncateMessage: boolean
}

export const CrewEventNotificationChatMessage: FC<CrewEventNotificationChatMessageProps> =
  memo((props) => {
    // 現時点では、chat_message_referencesのデータをもとに最新のレコードを取得する仕様
    // このためイベントの更新を行った後で「イベント登録時」の自動投稿メッセージには、更新後の件名と説明が表示される（2022/11/10プロダクトオーナー確認済み）

    const { t } = useTranslation()

    // 添付ファイル一覧
    const [attachments, setAttachments] = useState<FileHistory[] | undefined>(
      undefined
    )

    // ファイル履歴取得API
    const [lazyGetFileHistoryQuery] = useLazyGetFileHistoryQuery()

    // イベント詳細画面へのリンククリックイベント
    const handleLinkClick = useCallback<MouseEventHandler>((event) => {
      // スレッド表示への切替を防止するため、stopPropagationする
      event.stopPropagation()

      // 画面遷移自体はLinkタグの機能で行われるため、処理を書く必要はない
    }, [])

    // referencesからイベントに紐づくデータを取得
    const eventReference = useMemo(() => {
      const reference = props.chatMessageReferences.find((data) => {
        return data.entityType === EntityType.Event
      })

      // 自動投稿メッセージの場合、必ず1つのデータが存在するはずなので、存在しない場合は異常なデータなので何も処理しない
      if (!reference) {
        return
      }

      return reference
    }, [props.chatMessageReferences])

    // getTaskHistoty params
    const getEventParam: GetEventRequest | undefined = eventReference
      ? {
          eventId: eventReference.entityRecordId,
        }
      : undefined
    // Get created message user
    const { data: getEventResult } = useGetEventQuery(
      getEventParam ?? skipToken
    )

    // ファイル履歴への参照
    const fileReferences = useMemo(() => {
      const references = props.chatMessageReferences.filter((data) => {
        return data.entityType === EntityType.FileHistories
      })

      if (!references || references.length === 0) {
        return undefined
      }

      return references
    }, [props.chatMessageReferences])

    // ファイル履歴への参照がある場合、ファイル履歴詳細を取得する
    useValueChangeEffect(
      () => {
        // メッセージの詳細を表示しない場合に添付ファイルを表示しないため、ファイル履歴を取得する必要がない。
        if (props.truncateMessage) {
          return
        }

        // ファイル履歴が存在しない場合は何もしない
        if (!fileReferences) {
          return
        }

        let attachments: FileHistory[] = []

        // 各ファイル履歴の詳細データを取得
        const promises = fileReferences.map(async (reference) => {
          const response = await lazyGetFileHistoryQuery({
            fileHistoryId: reference.entityRecordId,
          }).unwrap()

          // 添付ファイル一覧に追加
          if (response.fileHistory != null) {
            attachments = [...attachments, response.fileHistory]
          }
        })

        // 全ての非同期処理が完了したら、添付ファイル一覧を更新
        Promise.all(promises).then(() => {
          setAttachments(attachments)
        })
      },
      [
        attachments,
        fileReferences,
        lazyGetFileHistoryQuery,
        props.truncateMessage,
      ],
      fileReferences
    )

    // Extract non-image attachments
    const files = useMemo(() => {
      if (!attachments) {
        return
      }

      return attachments.filter((item) => {
        if (!isImageFile(item.name)) {
          return true
        }
        return false
      })
    }, [attachments])

    // Extract attached image files
    const imageFiles = useMemo(() => {
      if (!attachments) {
        return
      }
      return attachments.filter((item) => {
        if (isImageFile(item.name)) {
          return true
        }
        return false
      })
    }, [attachments])

    // Get permission to download file
    const { hasPrjFileDownloadPermission } = useProjectPermissions(
      EntityType.Event,
      eventReference?.entityRecordId
    )

    // displayNameの後には、カンマと半角スペースを付与する
    const displayNames = useMemo(() => {
      return getEventResult?.event?.eventAttendees
        .map((user) => t('message.chat.userName', { name: user.displayName }))
        .join(', ')
    }, [getEventResult?.event?.eventAttendees, t])

    const description = useMemo(
      () =>
        getEventResult?.event?.description &&
        (props.truncateMessage
          ? sanitizeHtmlAllowNothing(getEventResult.event.description)
          : getEventResult.event.description),
      [getEventResult?.event?.description, props.truncateMessage]
    )

    // ロードされるまでは表示しない
    if (!getEventResult?.event) {
      return <CrewLoadingText className="w-full h-4" />
    }

    return (
      <>
        {/** イベント詳細へのリンクを出力 */}
        {/* Linkをインラインとするためのdivタグ（flex-colの対象をこのdivタグにする） */}
        <div>
          {/* イベントへのリンク */}
          <CrewLink
            to={`/projects/${getEventResult.event.entityRecordId}/events/${
              getEventResult.event.id
            }/${getDefaultTabValue(EventDetailTabs)}`}
            title={getEventResult.event.subject}
            onClick={handleLinkClick}
            className="line-clamp-2"
          >
            {getEventResult.event.subject}
          </CrewLink>
        </div>

        {/**
         * イベントの説明を出力
         * TODO: 改行も含めすべての説明が表示されるため、表示仕様については以下で議論予定
         * https://break-tmc.atlassian.net/browse/CREW-4208
         */}
        {props.messageType !== MessageType.EventJoined && description && (
          <div
            className={classNames(
              'text-crew-slate-4-light dark:text-crew-slate-1-dark overflow-hidden text-ellipsis',
              HTMLEDITOR_VIEW_STYLE,
              props.truncateMessage && 'truncate'
            )}
          >
            <CrewHtmlContent html={description} />
          </div>
        )}
        {
          /** 関連タスクを全て表示する */
          // TODO: 現在はイベントと同時にタスク起票する機能がないため、関連タスクの表示部分は対象機能実装後に以下タスクで対応する
          // https://break-tmc.atlassian.net/browse/CREW-4207
        }

        {props.messageType === MessageType.EventJoined && (
          // 参加者: XXさん, XXさん, XXさん ←参加者を列挙
          <div className="text-crew-slate-4-light dark:text-crew-slate-1-dark overflow-hidden text-ellipsis">
            {t('label.attendee')}: {displayNames}
          </div>
        )}

        {/* イベントファイル */}
        {attachments && (
          <div>
            {/* 添付画像ファイル */}
            {imageFiles && (
              <CrewAttachmentThumbnails
                attachmentThumbnails={imageFiles}
                showDeleteButton={false}
                canDownload={hasPrjFileDownloadPermission}
              />
            )}

            {/* 添付ファイル */}
            {files && (
              <CrewAttachments
                attachments={files}
                showDeleteButton={false}
                canDownload={hasPrjFileDownloadPermission}
              />
            )}
          </div>
        )}
      </>
    )
  })
