import { ProjectSettingTabs } from 'enums/app'
import { memo } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { ProjectSettingTaskCategoryList } from './components/projectSettingTaskCategoryList/projectSettingTaskCategoryList'
import { ProjectSettingTab } from './components/projectSettingTab/projectSettingTab'
import { ProjectSettingHeadPanel } from './components/projectSettingHeadPanel/projectSettingHeadPanel'
import { useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/dist/domain'
import { GetProjectRequest } from '@crew/apis/project/models/getProject/request'
import { useGetProjectQuery } from '@crew/apis/project/projectApis'
import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'states/hooks'
import { useProjectSettingPage } from './useProjectSettingPage'
import { ProjectSettingEventKindList } from './components/projectSettingEventKindList/projectSettingEventKindList'
import { ProjectSettingTaskKindList } from './components/projectSettingTaskKindList/projectSettingTaskKindList'
import { ProjectSettingTaskStateList } from './components/projectSettingTaskStateList/projectSettingTaskStateList'
import { ProjectSettingAdvancedSetting } from './components/projectSettingAdvancedSetting/projectSettingAdvancedSetting'

export const ProjectSettingPage = memo(() => {
  const { projectSettingPageContext } = useProjectSettingPage()

  const { projectId } = useParams()
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  const [isJoinedProject, setIsJoinedProject] = useState<boolean>(false)

  // プロジェクト詳細を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getProjectParam: GetProjectRequest | undefined = projectId
    ? {
        projectId,
      }
    : undefined
  const { data: getProjectResult } = useGetProjectQuery(
    getProjectParam ?? skipToken
  )

  // Update selected project & joined project state
  useEffect(() => {
    if (projectId && loggedInUser && getProjectResult?.project) {
      setIsJoinedProject(
        getProjectResult?.project.projectMembers.some(
          (projectMember) => loggedInUser.id === projectMember.id
        ) || loggedInUser.id === getProjectResult?.project.ownerUser.id
      )
    }
  }, [projectId, getProjectResult?.project, loggedInUser])

  // プロジェクト件名（LocalState）
  const [projectSubject, setProjectSubject] = useState<string>(
    getProjectResult?.project?.subject ?? ''
  )
  const { hasPrjProjectSettingEditPermission } = useProjectPermissions(
    EntityType.Project,
    projectId
  )

  return hasPrjProjectSettingEditPermission ? (
    <projectSettingPageContext.Provider
      value={{
        projectSubject,
        setProjectSubject,
      }}
    >
      <div className="flex flex-1 gap-2 grow min-h-0">
        <main
          className="grow flex flex-col h-full w-2/3 overflow-y-auto"
          id="pjSetting"
        >
          <div className="p-2">
            <ProjectSettingHeadPanel
              project={getProjectResult?.project}
              isJoinedProject={isJoinedProject}
            />

            {isJoinedProject ? (
              <>
                <ProjectSettingTab />

                <div className="grow min-h-0 w-full flex flex-col mt-2.5">
                  <Routes>
                    <Route
                      path={ProjectSettingTabs.AdvancedSetting.value}
                      element={<ProjectSettingAdvancedSetting />}
                    />
                    <Route
                      path={ProjectSettingTabs.EventKind.value}
                      element={<ProjectSettingEventKindList />}
                    />
                    {/* TODO: イベント分類タブは下記タスクで非表示としたため、現状未使用。
                    https://break-tmc.atlassian.net/browse/CREW-15044
                    <Route
                      path={ProjectSettingTabs.EventCategory.value}
                      element={<ProjectSettingEventCategoryList />}
                    /> */}
                    <Route
                      path={ProjectSettingTabs.TaskKind.value}
                      element={<ProjectSettingTaskKindList />}
                    />
                    <Route
                      path={ProjectSettingTabs.TaskCategory.value}
                      element={<ProjectSettingTaskCategoryList />}
                    />
                    <Route
                      path={ProjectSettingTabs.TaskStatus.value}
                      element={<ProjectSettingTaskStateList />}
                    />
                  </Routes>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </main>
      </div>
    </projectSettingPageContext.Provider>
  ) : (
    <></>
  )
})
