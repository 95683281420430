import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type TenantSettingStateType = {
  user: {
    keyword: string
  }
}

const initialTenantSettingPage: TenantSettingStateType = {
  user: {
    keyword: '',
  },
}

export const tenantSettingPageSlice = createSlice({
  name: 'tenantSettingPage',
  initialState: initialTenantSettingPage,
  reducers: {
    tenantSettingUserStateKeywordUpdated: (
      state,
      action: PayloadAction<string>
    ) => {
      state.user.keyword = action.payload
    },
  },
})

export const { tenantSettingUserStateKeywordUpdated } =
  tenantSettingPageSlice.actions
