export const IsDeletedFeedItem = (v) => {
    if (typeof v !== 'object' || v === null)
        return false;
    const m = v;
    // TODO:厳密化する
    // https://break-tmc.atlassian.net/browse/CREW-4249
    if (typeof m.chatMessageId !== 'string')
        return false;
    return true;
};
