import { FieldValues, FormState } from 'react-hook-form'
import { genericMemo } from 'utils'

type Props<TFieldValues extends FieldValues> = {
  formState: FormState<TFieldValues>
}

export const CrewErrorSummary = genericMemo(
  <TFieldValues extends FieldValues>(props: Props<TFieldValues>) => {
    // console.log('CrewErrorSummary', props.formState.errors)
    return (
      <div>
        {Object.keys(props.formState.errors).length > 0 && (
          <ul>
            {Object.values(props.formState.errors)
              .flat()
              .map((value) =>
                Object.values(
                  value && typeof value === 'object' && 'types' in value
                    ? value.types
                    : {}
                )
              )
              .flat()
              .filter((item): item is NonNullable<typeof item> => item != null)
              .map((message: any, index) => (
                <li key={index} className="crew-error-text">
                  {message}
                </li>
              ))}
          </ul>
        )}
      </div>
    )
  }
)
