import { DataGrid } from 'devextreme-react'
import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'

type Props = PropsWithChildren<ComponentProps<typeof DataGrid>>
export const CrewDataGrid = memo(
  forwardRef<DataGrid, Props>((props, ref) => {
    const { children, ...rest } = props
    return (
      <DataGrid {...rest} ref={ref}>
        {children}
      </DataGrid>
    )
  })
)
