import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { memo } from 'react'
import { useTwoFactorMail } from './useTwoFactorMail'
import { TwoFactorVerifyCode } from '../twoFactorVerifyCode/twoFactorVerifyCode'
import { TwoFactorAuthenticationType } from 'enums/app'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useEffect, useState } from 'react'
import { FormValues } from './useTwoFactorMail'

export type TwoFactorMailProps = {
  emailAddress: string | undefined
  onSubmit: () => void
}

type TwoFactorMailStep = 'input' | 'verify'

export const TwoFactorMail = memo((props: TwoFactorMailProps) => {
  const { control, handleSubmit, reset, setValue, setError, sendAuthCode } =
    useTwoFactorMail()

  const { t } = useTranslation()
  const toast = useToast()

  const [twoFactorMailStep, setTwoFactorMailStep] =
    useState<TwoFactorMailStep>('input')
  const [destination, setDestination] = useState(props.emailAddress ?? '')

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // Set default email
  useEffect(() => {
    //set default email address
    setValue('emailAddress', props.emailAddress ?? '')
  }, [props.emailAddress, setValue])

  // Event handle when the Send button is clicked
  const handleSendVerifyCodeButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // Send verification code to email address
        await sendAuthCode(data.emailAddress)

        // Set destination
        setDestination(data.emailAddress)

        toast.success(t('message.personalSetting.sendVerifyCodeSuccess'))
        setTwoFactorMailStep('verify')
        //reset form values
        reset()
      } catch (error) {
        showApiErrors(error)
      }
    }

    handleSubmit(onSubmit)()
  }, [handleSubmit, reset, sendAuthCode, showApiErrors, t, toast])

  return (
    <div className="mt-2.5">
      <div className="flex flex-col gap-y-2.5">
        {twoFactorMailStep === 'input' ? (
          <span className="crew-text-gray-4">
            {t('message.personalSetting.twoFactorMailNote')}
          </span>
        ) : (
          <span className="crew-text-gray-4">
            {t('message.personalSetting.enterVerificationCode')}
          </span>
        )}
        <div className="flex gap-x-2.5 items-end">
          {twoFactorMailStep === 'input' ? (
            <>
              {/* メールアドレス */}
              <CrewTextBoxField
                control={control}
                id="emailAddress"
                name="emailAddress"
                label={t('label.mailAddress')}
              />

              {/* 送信する */}
              <CrewButton
                text={t('action.sendVerifyCode')}
                type="primary"
                onClick={handleSendVerifyCodeButtonClick}
              />
            </>
          ) : (
            // input form verify code
            <TwoFactorVerifyCode
              onSubmit={props.onSubmit}
              twoFactorAuthenticationType={
                TwoFactorAuthenticationType.Email.value
              }
              destination={destination ?? undefined}
            />
          )}
        </div>
      </div>
    </div>
  )
})
