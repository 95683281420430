import { memo } from 'react'
import { useOrganizationSettingForm } from './useOrganizationSettingForm'
import {
  CrewAvatar,
  CrewAvatarShapeType,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { MAXIMUM_AVATAR_SIZE } from 'configs/constants'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { CrewSwitchField } from 'components/forms/crewSwitchField'
import {
  IsApiErrorInvalidPasswordLength,
  isApiErrorResult,
} from '@crew/apis/errors'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import { DEFAULT_PASSWORD_MIN_LENGTH } from '@crew/configs/constants'
import { ContractPlan, FileExtensions, SettingKeyType } from '@crew/enums/app'
import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { generateImageAvatarUrl } from '@crew/utils/avatar'

import { convertToByte } from '@crew/utils/file'

import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { convertFileToBase64 } from 'utils'
import { FormValues, formInitialValues } from './useOrganizationSettingForm'
import { useGetTenantQuery } from '@crew/apis/dist/tenantSetting/tenantSettingApis'
import { useAppSelector } from 'states/hooks'

//keytype for reduce function
type ReduceReturnType = {
  [key in SettingKeyType]?: string | null
}

export const OrganizationSettingForm = memo(() => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    validateRules,
    updateTenantSettings,
    uploadFile,
  } = useOrganizationSettingForm()

  const { t } = useTranslation()

  const { error, success } = useToast()

  const [showApiErrors] = useShowApiErrors()

  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  const [organizationName, setOrganizationName] = useState<string>('')

  const tenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [
      SettingKeyType.OrganizationName,
      SettingKeyType.OrganizationDomain,
      SettingKeyType.OrganizationExternalUser,
      SettingKeyType.OrganizationExternalUserExpirationDate,
      SettingKeyType.OrganizationPasswordLength,
      SettingKeyType.OrganizationForceComplexPassword,
      SettingKeyType.OrganizationForceTwoFactorAuthentication,
      SettingKeyType.OrganizationRestrictDomains,
    ],
  }

  const { data: getTenantSettings } = useGetTenantSettingsQuery(
    tenantSettingsRequestParams
  )

  // テナント情報取得（テナントアバター用にバージョンが必要なため）
  const { data: getTenant } = useGetTenantQuery()

  useEffect(() => {
    if (getTenantSettings?.tenantSettings) {
      //convert tenant settings to object
      const tenantSettings =
        getTenantSettings?.tenantSettings.reduce<ReduceReturnType>(
          (tenantSetting, currentValue) => ({
            ...tenantSetting,
            [currentValue.key]: currentValue.value,
          }),
          {}
        )

      setOrganizationName(tenantSettings[SettingKeyType.OrganizationName] ?? '')

      formInitialValues.name =
        tenantSettings[SettingKeyType.OrganizationName] ?? ''
      formInitialValues.domain =
        tenantSettings[SettingKeyType.OrganizationDomain] ?? ''
      formInitialValues.externalUser =
        tenantSettings[SettingKeyType.OrganizationExternalUser] ?? ''
      formInitialValues.externalUserExpirationDate =
        tenantSettings[SettingKeyType.OrganizationExternalUserExpirationDate] ??
        ''
      formInitialValues.passwordLength =
        tenantSettings[SettingKeyType.OrganizationPasswordLength] ??
        String(DEFAULT_PASSWORD_MIN_LENGTH)
      formInitialValues.forceComplexPassword =
        tenantSettings[SettingKeyType.OrganizationForceComplexPassword] ===
        'true'
          ? true
          : false
      formInitialValues.forceTwoFactorAuthentication =
        tenantSettings[
          SettingKeyType.OrganizationForceTwoFactorAuthentication
        ] === 'true'
          ? true
          : false
      formInitialValues.restrictDomains =
        tenantSettings[SettingKeyType.OrganizationRestrictDomains] === 'true'
          ? true
          : false
      //set value to form
      reset(formInitialValues)
    }
  }, [getTenantSettings?.tenantSettings, reset])

  //Temporarily displaying 「90日」(90 days) according to Figma
  const externalUserExpirationDateOptions = useMemo(() => {
    return [
      {
        key: '90日',
        value: '90',
      },
    ]
  }, [])

  //Save tenant settings when click save button
  const handleSaveSettingButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (!getTenantSettings?.tenantSettings) return

        await updateTenantSettings(data)

        // toast when success save
        success(t('message.tenant.saveTenantSettingSuccess'))
      } catch (err: unknown) {
        // check invalidPasswordLength
        if (
          isApiErrorResult(err) &&
          IsApiErrorInvalidPasswordLength(err.data)
        ) {
          if (data.forceComplexPassword) {
            error(t('message.apiError.invalidComplexPasswordLength'))
          } else {
            error(t('message.apiError.invalidPasswordLength'))
          }
        } else {
          showApiErrors(err)
        }
      }
    }

    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    getTenantSettings?.tenantSettings,
    updateTenantSettings,
    success,
    t,
    error,
    showApiErrors,
  ])

  const imageRef = useRef<HTMLInputElement>(null)

  // TODO: Web: generateImageAvatarUrlのリファクタリング
  // https://break-tmc.atlassian.net/browse/CREW-9777
  const avatarUrl = generateImageAvatarUrl(EntityType.Tenant)

  const [selectedImage, setSelectedImage] = useState<string | undefined>()

  // upload file image
  const handleFileUpload = useCallback(() => {
    imageRef.current?.click()
  }, [])

  // set image when changed
  const handleImageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event.target.files &&
        event.target.files.length > 0 &&
        imageRef.current
      ) {
        try {
          const file = event.target.files[0]
          // validate file size
          if (file.size > convertToByte(MAXIMUM_AVATAR_SIZE, 'MB')) {
            error(t('message.file.invalidMaxFileSize'))
            // make reselect file
            if (imageRef.current) {
              imageRef.current.value = ''
            }
            return
          }

          // check validate file allowedExtensions
          const allowedExtensions = new RegExp(
            String.raw`(\.${FileExtensions.Jpg}|\.${FileExtensions.Jpeg}|\.${FileExtensions.Png})$`,
            'i'
          )
          if (!allowedExtensions.exec(imageRef.current.value)) {
            error(t('message.file.invalidFileExtension'))
            imageRef.current.value = ''
            return
          }

          // 一時領域にファイルをupload
          const response = await uploadFile(file)

          //set avatar key to form
          setValue('avatarKey', response.key)

          // set image to preview
          const base64 = await convertFileToBase64(file)
          setSelectedImage(base64 as string)
        } catch (err) {
          error(t('message.file.uploadFailed'))
        }
      }
    },
    [uploadFile, setValue, error, t]
  )

  return (
    <form className="flex flex-col gap-y-5">
      <div className="flex gap-x-5 items-center justify-center">
        <CrewAvatar
          displayName={organizationName}
          size={CrewAvatarSize['2xl']}
          // 画像が変更されている場合は変更後の画像、変更されていない場合は登録済みの画像を表示
          imageURL={selectedImage ? undefined : avatarUrl}
          avatarImage={selectedImage}
          shape={CrewAvatarShapeType.Square}
          cacheValue={
            // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
            getTenant?.tenant
              ? getTenant.tenant.id + getTenant.tenant.version
              : ''
          }
        />

        <div className="self-start flex flex-col gap-y-1 items-start">
          <div className="text-center">
            <input
              ref={imageRef}
              type="file"
              className="hidden"
              accept={`.${FileExtensions.Jpg}, .${FileExtensions.Jpeg}, .${FileExtensions.Png}`}
              onChange={handleImageChange}
            />
            <span className="leading-5 crew-link" onClick={handleFileUpload}>
              {t('label.changeAvatar')}
            </span>
          </div>
          <span className="leading-5 crew-text-gray-4 text-sm">
            {t('label.avatarNote')}
          </span>
          <span className="leading-5 crew-text-gray-4 text-sm">
            {t('label.maximumSize', { maximumSize: MAXIMUM_AVATAR_SIZE })}
          </span>
        </div>
      </div>

      {/* 組織 */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.organization')}</p>

        {/* 組織名 */}
        <div className="flex items-center justify-between">
          <CrewFieldLabel text={t('label.organizationName')} required={true} />
          <CrewTextBoxField
            control={control}
            id="name"
            name="name"
            showLabel={false}
            className="w-80"
            showClearButton={false}
            rules={validateRules.name}
          />
        </div>
      </div>

      {/* セキュリティ */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.security')}</p>

        {/* パスワードの文字数 */}
        <div className="flex items-center justify-between">
          <CrewFieldLabel
            text={t('label.numberOfCharactersPassword')}
            required={true}
          />

          <div className="flex items-center gap-x-2.5 w-80">
            <CrewTextBoxField
              control={control}
              id="passwordLength"
              name="passwordLength"
              showLabel={false}
              className="w-16"
              showClearButton={false}
              rules={validateRules.passwordLength}
              disabled={currentPlan === ContractPlan.Free}
            />
            <span>{t('label.lettersOrMore')}</span>
          </div>
        </div>

        {/* 複雑なパスワードを強制する */}
        <div className="flex items-center justify-between">
          <CrewFieldLabel
            text={t('label.enforceComplexPassword')}
            required={true}
          />
          <div className="flex-row w-80">
            <CrewSwitchField
              control={control}
              id="forceComplexPassword"
              name="forceComplexPassword"
              showLabel={false}
              disabled={currentPlan === ContractPlan.Free}
            />
            <span className="text-sm crew-text-gray-4">
              {t('label.forceComplexPasswordHint')}
            </span>
          </div>
        </div>

        {/* 2要素認証を強制する */}

        <div className="flex items-center justify-between">
          <CrewFieldLabel
            text={t('label.enforeTwoFactorAuthentication')}
            required={true}
          />
          <div className="w-80">
            <CrewSwitchField
              control={control}
              id="forceTwoFactorAuthentication"
              name="forceTwoFactorAuthentication"
              showLabel={false}
              disabled={currentPlan === ContractPlan.Free}
            />
          </div>
        </div>
      </div>

      {/* ユーザーの招待 */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.invitingUsers')}</p>

        {/* ドメインを制限する */}
        <div className="flex items-center justify-between">
          <CrewFieldLabel text={t('label.restrictDomains')} required={true} />
          <div className="flex-row w-80">
            <CrewSwitchField
              control={control}
              id="restrictDomains"
              name="restrictDomains"
              showLabel={false}
              disabled={currentPlan === ContractPlan.Free}
            />
            <span className="text-sm crew-text-gray-4">
              {t('label.restrictDomainsHint')}
            </span>
          </div>
        </div>

        {/* 組織のドメイン */}
        <div className="flex items-center justify-between">
          <CrewFieldLabel text={t('label.organizationDomain')} />
          <div className="flex-row">
            <CrewTextBoxField
              control={control}
              id="domain"
              name="domain"
              showLabel={false}
              className="w-80"
              showClearButton={false}
              rules={validateRules.domain}
              disabled={currentPlan === ContractPlan.Free}
            />
            <span className="text-sm crew-text-gray-4">
              {t('label.domainHint')}
            </span>
          </div>
        </div>

        {
          //TODO: 非表示にするため、コメントアウト
          //https://break-tmc.atlassian.net/browse/CREW-13192
        }
        {/* 外部ユーザーの追加 */}
        {/* <div className="flex items-center justify-between">
          <CrewFieldLabel text={t('label.addExternalUser')} required={true} />
          <CrewSelectBoxField
            control={control}
            id="externalUser"
            name="externalUser"
            className="w-80"
            items={addExternalUserOptions}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.externalUser}
            displayExpr="key"
            valueExpr="value"
          />
        </div> */}

        {/* 外部ユーザーの有効期限(初期値) */}
        <div className="flex items-center justify-between">
          <CrewFieldLabel
            text={t('label.externalUserExpirationDate')}
            required={true}
          />
          <CrewSelectBoxField
            control={control}
            id="externalUserExpirationDate"
            name="externalUserExpirationDate"
            className="w-80"
            items={externalUserExpirationDateOptions}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.externalUserExpirationDate}
            displayExpr="key"
            valueExpr="value"
            disabled={currentPlan === ContractPlan.Free}
          />
        </div>
      </div>

      {/* Action */}
      <CrewButton
        text={t('action.toSave')}
        type="primary"
        className="mr-auto"
        onClick={handleSaveSettingButtonClick}
      />
    </form>
  )
})
