import {
  useAudioOutputs,
  useMeetingManager,
} from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useLogger } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/LoggerProvider'
import { useCallback, useMemo } from 'react'

export const useCrewSpeakerSelectBox = () => {
  const logger = useLogger()
  const { devices, selectedDevice } = useAudioOutputs()
  const meetingManager = useMeetingManager()

  // デバイスセレクトボックス選択時
  const updateAudioOutputDeviceAsync = useCallback(
    async (deviceId: string) => {
      try {
        await meetingManager.startAudioOutputDevice(deviceId)
      } catch (error) {
        logger.error('SpeakerSelection failed to select speaker.')
      }
    },
    [logger, meetingManager]
  )

  // 選択可能なオーディオ出力デバイスのリスト
  const deviceList = useMemo(
    () =>
      devices.map((device) => ({
        id: device.deviceId,
        name: device.label,
      })),
    [devices]
  )

  return {
    deviceList,
    selectedDevice,
    updateAudioOutputDeviceAsync,
  }
}
