import { FC, memo } from 'react'
import {
  CrewNavTab,
  CrewNavTabProps,
} from 'components/elements/crewNavTabs/components/crewNavTab'

type Props = {
  tabItems: CrewNavTabProps[]
}
export const CrewNavTabs: FC<Props> = memo((props) => {
  return (
    <div className="flex flex-row flex-none h-10 items-center border-b crew-border-gray">
      {/* tabItem分だけ表示する */}
      {props.tabItems.map((item) => (
        <CrewNavTab
          text={item.text}
          to={item.to}
          onClick={item.onClick}
          // Warning表示回避のkey指定
          key={item.text}
          icon={item.icon}
          badgeCount={item.badgeCount}
          badgeColor={item.badgeColor}
        />
      ))}
    </div>
  )
})
