import { memo } from 'react'
import { MyTaskListDataGrid } from './components/myTaskListDataGrid/myTaskListDataGrid'

export const MyTaskListPanel = memo(() => {
  return (
    <div className="flex h-full overflow-y-hidden">
      {/* task list */}
      <MyTaskListDataGrid />
    </div>
  )
})
