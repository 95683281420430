import { memo, useState } from 'react'
import { EventDetailAttendeeListToolbar } from './components/eventDetailAttendeeListToolbar/eventDetailAttendeeListToolbar'
import { EventDetailAttendeeListPanel } from './components/eventDetailAttendeeListPanel/eventDetailAttendeeListPanel'
import { createUninitializedContext } from '@crew/utils/context'
import { EventAttendee } from '@crew/apis/dist/project/models/getEventAttendees/getEventAttendeesResponse'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

/** Context用の型 */
type EventAttendeesContextType = {
  selectedAttendeeIds: string[]
  setSelectedAttendeeIds: (selectedAttendeeIds: string[]) => void

  //LoggedInUser joined event
  currentAttendee: EventAttendee | null
  setCurrentAttendee: (currentAttendee: EventAttendee | null) => void
}

/** Context */
const {
  context: eventAttendeesContext,
  useInitializedContext: useEventAttendeesContext,
} = createUninitializedContext<EventAttendeesContextType>()

export { useEventAttendeesContext }

export const EventDetailAttendeeList = memo(() => {
  // 検索タイプ（標準検索・詳細検索）の選択状態（LocalState）
  const [selectedAttendeeIds, setSelectedAttendeeIds] = useState<string[]>([])

  const [currentAttendee, setCurrentAttendee] = useState<EventAttendee | null>(
    null
  )

  return (
    <eventAttendeesContext.Provider
      value={{
        selectedAttendeeIds,
        setSelectedAttendeeIds,
        currentAttendee,
        setCurrentAttendee,
      }}
    >
      <>
        <CrewErrorBoundary>
          {/* event detail attendee list toolbar */}
          <EventDetailAttendeeListToolbar />
        </CrewErrorBoundary>

        <CrewErrorBoundary>
          {/* event detail attendee list panel */}
          <EventDetailAttendeeListPanel />
        </CrewErrorBoundary>
      </>
    </eventAttendeesContext.Provider>
  )
})
