import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { FC, memo, useCallback, useMemo, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { TextBox } from 'devextreme-react'
import { debounce } from 'lodash'
import { Chat, useChatCurrentService, useChatSearchService } from '@crew/states'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/dist/constants'
import ChevronBackward from '~icons/material-symbols/chevron-backward'

export const ChatSearchHeader: FC = memo(() => {
  const dispatch = useAppDispatch()

  // 処理対象のチャットルームをViewModelから取得
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )
  // この処理が流れる際、ViewModelには必ずチャットルームが設定されているはずなので、未設定の場合はエラーとする
  if (!currentChatRoom) {
    throw new Error('currentChatRoom is undefined')
  }
  const currentChatRoomId = currentChatRoom.id

  // ViewModelに格納されているキーワードの値を取得する
  const keyword = useAppSelector(
    (state) => state.message.chat.search.entities[currentChatRoomId]?.keyword
  )

  // Sliceの操作を行うためのServiceを取得
  const chatCurrentService = useChatCurrentService(dispatch)
  const chatSearchService = useChatSearchService(dispatch)

  // キーワード検索
  const keywordTextBoxRef = useRef<TextBox>(null)

  // キーワード検索の遅延実行
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        // ViewModelにキーワードの値を設定する
        chatSearchService.setKeyword({
          chatRoomId: currentChatRoomId,
          keyword: value,
        })
      }, SEARCH_TIMEOUT_MSEC),
    [chatSearchService, currentChatRoomId]
  )

  /**
   * キーワードテキストボックスの値変更時の処理
   */
  const handleKeywordValueChanged = useCallback(() => {
    // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
    const input = keywordTextBoxRef.current?.instance.option('text')

    if (input !== undefined && input !== keyword) {
      debouncedSearch(input)
    }
  }, [keyword, debouncedSearch])

  /**
   * 戻るボタンクリック時の処理
   */
  const handleBackButtonClick = useCallback(() => {
    // モードを「チャット」に戻す
    chatCurrentService.setCurrentMode(Chat.Mode.Chat)
  }, [chatCurrentService])

  return (
    <div className="flex gap-2 justify-between px-2 py-1">
      <div className="flex gap-2 grow shrink">
        {/* 戻るボタン */}
        <CrewButton
          icon={<ChevronBackward width={20} height={20} />}
          stylingMode="text"
          type="normal"
          size="sm"
          onClick={handleBackButtonClick}
        />

        {/* キーワード */}
        <CrewTextBox
          className="grow" // 入力欄を表示領域内いっぱいに広げる
          valueChangeEvent="input change"
          showClearButton={true}
          ref={keywordTextBoxRef}
          defaultValue={keyword}
          mode="search"
          onInput={handleKeywordValueChanged}
        />
      </div>
    </div>
  )
})
