import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { FC, memo } from 'react'
import { useCrewMicrophoneSelectBox } from './useCrewMicrophoneSelectBox'
import { useAsyncStateToValueConnector } from '../hooks/useAsyncStateToValueConnector'
import { Device } from 'amazon-chime-sdk-js'

type Props = BaseSdkProps

export const CrewMicrophoneSelectBox: FC<Props> = memo(() => {
  const {
    currentAudioDevice,
    updateAudioInputDeviceAsync,
    stateToValue,
    deviceList,
  } = useCrewMicrophoneSelectBox()

  const { value, handleValueChanged, isChangingAsyncState } =
    useAsyncStateToValueConnector<Device | null, string>(
      currentAudioDevice,
      updateAudioInputDeviceAsync,
      stateToValue
    )

  return (
    <CrewSelectBox
      disabled={isChangingAsyncState} // 変更中は使用不可
      dataSource={deviceList}
      displayExpr="name"
      valueExpr="id"
      value={value}
      showClearButton={false}
      searchEnabled={false}
      onValueChanged={handleValueChanged}
    />
  )
})
