import { Modal } from 'components/layouts/modal/modal'
import { EventKindEntryForm } from './components/eventKindEntryForm/eventKindEntryForm'

import { FC, memo, useCallback } from 'react'

export type EventKindDialogProps = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  eventKindId?: string
}

export const EventKindEntryDialog: FC<EventKindDialogProps> = memo((props) => {
  // 登録ボタン押下
  const handleSubmitButtonClick = useCallback(() => {
    props.onClose()
  }, [props])

  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <EventKindEntryForm
        isEditMode={props.isEditMode}
        eventKindId={props.eventKindId}
        onSubmit={handleSubmitButtonClick}
        onClose={props.onClose}
      />
    </Modal>
  )
})
