import {
  CrewAvatar,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { memo } from 'react'
import { usePersonalSettingProfileForm } from './usePersonalSettingProfileForm'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { MAXIMUM_AVATAR_SIZE } from 'configs/constants'
import { CrewRequireDot } from 'components/elements/crewRequireDot'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import {
  updateLoggedInUserAvatar,
  updateLoggedInUserDisplayName,
} from 'features/app/states/appSlice'
import { LocalStorageKeys } from 'enums/system'
import { setTimezoneGenerator } from '@crew/apis/dist/apiBase/apiBase'
import { useLoadUserSettings, useUserSetting } from '@crew/states'
import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { convertToByte } from '@crew/utils/file'
import { convertFileToBase64 } from 'utils'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useGetUserQuery } from '@crew/apis/user/userApis'
import { GetUserRequest } from '@crew/apis/user/models/getUser/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { FileExtensions, Region, SettingKeyType } from '@crew/enums/app'
import { useToast } from 'hooks/useToast'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AppLanguage } from 'enums/app'
import { formInitialValues, FormValues } from './usePersonalSettingProfileForm'

export const PersonalSettingProfileForm = memo(() => {
  const {
    control,
    setValue,
    reset,
    handleSubmit,
    setError,
    validateRules,
    uploadFile,
    updateUserSettings,
  } = usePersonalSettingProfileForm()

  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useAppDispatch()

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>()
  const [selectedImage, setSelectedImage] = useState<string | undefined>()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const [loadUserSettings] = useLoadUserSettings()

  const requestParams: GetUserRequest = {
    userId: loggedInUser?.id ?? '',
  }
  const { data: getUser, refetch: reloadGetUser } = useGetUserQuery(
    requestParams ?? skipToken
  )

  // Get the profile setting for logged in user
  const defaultUserProfileLanguage = useUserSetting(
    SettingKeyType.UserProfileLanguage,
    AppLanguage.Ja
  )
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // Get avatar of logged in user
  useEffect(() => {
    if (loggedInUser) {
      const avatarUrl = generateImageAvatarUrl(EntityType.User, loggedInUser.id)
      setAvatarUrl(avatarUrl)
    }
  }, [loggedInUser])

  // Display saved settings on the screen
  useEffect(() => {
    if (getUser?.user) {
      formInitialValues.displayName = getUser?.user.displayName ?? ''
      formInitialValues.alias = getUser?.user.alias ?? ''
      formInitialValues.searchKey = getUser?.user.searchKey ?? ''
      formInitialValues.department = getUser?.user.department ?? ''
    }

    formInitialValues.language = String(defaultUserProfileLanguage)
    formInitialValues.region = String(defaultUserProfileRegion)
    reset({ ...formInitialValues })
  }, [
    defaultUserProfileLanguage,
    defaultUserProfileRegion,
    getUser?.user,
    reset,
  ])

  // Get the list of languages
  const languages = useMemo(() => {
    return Object.values(AppLanguage).map((item) => {
      return {
        value: item,
        key: t(`label.${item}`),
      }
    })
  }, [t])

  // Get the list of region
  const regions = useMemo(() => {
    return Object.values(Region).map((item) => {
      return {
        value: item.value,
        key: t(item.text),
      }
    })
  }, [t])

  const imageRef = useRef<HTMLInputElement>(null)

  // Event handle when the label 'Change avatar' is clicked
  const handleFileUpload = useCallback(() => {
    imageRef.current?.click()
  }, [])

  // Event handle when the input file is changed
  const handleImageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event.target.files &&
        event.target.files.length > 0 &&
        imageRef.current
      ) {
        try {
          const file = event.target.files[0]
          // validate file size
          if (file.size > convertToByte(MAXIMUM_AVATAR_SIZE, 'MB')) {
            toast.error(t('message.file.invalidMaxFileSize'))
            // make reselect file
            if (imageRef.current) {
              imageRef.current.value = ''
            }
            return
          }
          // check validate file allowedExtensions
          const allowedExtensions = new RegExp(
            String.raw`(\.${FileExtensions.Jpg}|\.${FileExtensions.Jpeg}|\.${FileExtensions.Png})$`,
            'i'
          )
          if (!allowedExtensions.exec(imageRef.current.value)) {
            toast.error(t('message.file.invalidFileExtension'))
            imageRef.current.value = ''
            return
          }

          // 一時領域にファイルをupload
          const response = await uploadFile(file)

          //set avatar key to form
          setValue('avatarKey', response.key)

          // set image to preview
          const base64 = await convertFileToBase64(file)
          setSelectedImage(base64 as string)
        } catch (err) {
          toast.error(t('message.file.uploadFailed'))
        }
      }
    },
    [setValue, t, toast, uploadFile]
  )

  // Event handle when the Save button is clicked
  const handleSaveButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (!getUser?.user) return

        // call api save setting user
        await updateUserSettings(data, getUser.user.version)

        loadUserSettings()

        //update logged in user
        dispatch(updateLoggedInUserDisplayName(data.displayName))

        // update avatar profile panel
        if (data.avatarKey) {
          dispatch(updateLoggedInUserAvatar(data.avatarKey))
          // TODO: ヘッダなどに表示されているアバターの表示をどうするか、他ユーザーの表示にもリアルタイム反映するべきか
          //https://break-tmc.atlassian.net/browse/CREW-7891
        }

        // 地域が指定されている場合
        if (data.region) {
          // タイムゾーンをローカルストレージへ保存する
          localStorage.setItem(LocalStorageKeys.TimezoneId, data.region)

          // タイムゾーンを再設定する
          setTimezoneGenerator(() => data.region as string)
        }

        // toast when success save
        toast.success(t('message.personalSetting.saveUserSettingSuccess'))
        //reload data from api
        reloadGetUser()
      } catch (err) {
        // show error api
        showApiErrors(err)
      }
    }

    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    getUser?.user,
    updateUserSettings,
    loadUserSettings,
    dispatch,
    toast,
    t,
    reloadGetUser,
    showApiErrors,
  ])

  return (
    <form className="flex flex-col gap-y-5">
      <div className="flex gap-x-5 items-center justify-center">
        <CrewAvatar
          displayName={loggedInUser?.displayName ?? ''}
          size={CrewAvatarSize['2xl']}
          imageURL={selectedImage ? undefined : avatarUrl}
          avatarImage={selectedImage}
          cacheValue={
            // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
            loggedInUser ? loggedInUser.id + loggedInUser.version : ''
          }
        />

        <div className="flex flex-col gap-y-1 items-start">
          <div className="text-center">
            <input
              ref={imageRef}
              type="file"
              className="hidden"
              accept={`.${FileExtensions.Jpg}, .${FileExtensions.Jpeg}, .${FileExtensions.Png}`}
              onChange={handleImageChange}
            />
            <span className="leading-5 crew-link" onClick={handleFileUpload}>
              {t('label.changeAvatar')}
            </span>
          </div>
          <span className="leading-5 text-crew-gray-500 dark:text-crew-gray-400 text-sm">
            {t('label.avatarNote')}
          </span>
          <span className="leading-5 text-crew-gray-500 dark:text-crew-gray-400 text-sm">
            {t('label.avatarSize', { avatarSize: MAXIMUM_AVATAR_SIZE })}
          </span>
        </div>
      </div>

      {/* ユーザー情報 */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.userInformation')}</p>

        {/* 表示名 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.displayName')}
            <CrewRequireDot />
          </span>
          <CrewTextBoxField
            control={control}
            id="displayName"
            name="displayName"
            showLabel={false}
            className="w-80"
            showClearButton={false}
            rules={validateRules.displayName}
          />
        </div>

        {/* 別名(ふりがな) */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">{t('label.userAlias')}</span>
          <CrewTextBoxField
            control={control}
            id="alias"
            name="alias"
            showLabel={false}
            className="w-80"
            showClearButton={false}
          />
        </div>

        {/* 検索キー */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">{t('label.searchKey')}</span>
          <CrewTextBoxField
            control={control}
            id="searchKey"
            name="searchKey"
            showLabel={false}
            className="w-80"
            showClearButton={false}
          />
        </div>

        {/* 所属部門 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">{t('label.department')}</span>
          <CrewTextBoxField
            control={control}
            id="department"
            name="department"
            showLabel={false}
            className="w-80"
            showClearButton={false}
          />
        </div>
      </div>

      {/* 言語と地域 */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.languageAndRegion')}</p>

        {/* 言語 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.language')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="language"
            name="language"
            className="w-80"
            items={languages}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.language}
            displayExpr="key"
            valueExpr="value"
          />
        </div>

        {/* 地域 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.region')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="region"
            name="region"
            className="w-80"
            items={regions}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.region}
            displayExpr="key"
            valueExpr="value"
          />
        </div>
      </div>

      {/* Action */}
      <CrewButton
        text={t('action.toSave')}
        type="primary"
        className="mr-auto"
        onClick={handleSaveButtonClick}
      />
    </form>
  )
})
