import { memo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import {
  PRIVACY_POLICY_URL,
  TERM_OF_SERVICE_URL,
} from '@crew/configs/constants'

// 「利用規約とプライバシーポリシーに同意します」リンク
// 言語切替を考慮して、分割したメッセージを以下のように結合する
//   {prefixMessage} {利用規約リンク} {接続詞} {プライバシーポリシーリンク} {suffixMessage}
//
// なお、react-hook-formへの依存により制御が煩雑化することを防ぐため、
// 現状はチェックボックス部分をこのコンポーネントに含めていない
export const CrewTermsOfServiceAndPrivacyPolicyLink = memo(() => {
  const { t } = useTranslation()

  return (
    <span className="crew-text-gray-4">
      {t('label.TermsOfServiceAndPrivacyPolicy.prefixMessage')}
      {/* 利用規約リンク */}
      <CrewLink to={TERM_OF_SERVICE_URL} target="_blank">
        {t('label.TermsOfServiceAndPrivacyPolicy.termOfService')}
      </CrewLink>
      {t('label.TermsOfServiceAndPrivacyPolicy.and')}
      {/* プライバシーポリシーリンク */}
      <CrewLink to={PRIVACY_POLICY_URL} target="_blank">
        {t('label.TermsOfServiceAndPrivacyPolicy.privacyPolicy')}
      </CrewLink>
      {t('label.TermsOfServiceAndPrivacyPolicy.suffixMessage')}
    </span>
  )
})
