import { memo, FC, useCallback } from 'react'
import classNames from 'classnames'

import Lightbox from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/plugins/video'

type CrewVideoPlayerProps = {
  streamingUrl: string
  isOpen: boolean
  showDownloadButton: boolean
  onClose: () => void
}

export const CrewVideoPlayer: FC<CrewVideoPlayerProps> = memo((props) => {
  // 右クリックメニューに「名前を付けて保存」があるので、メニュー表示を無効化するためのイベントハンドラ
  const handleEventPreventDefault: React.EventHandler<any> = useCallback(
    (e) => e.preventDefault(),
    []
  )

  return (
    <div
      onContextMenu={handleEventPreventDefault} // 右クリックメニューに「名前を付けて保存」があるので無効化する
    >
      <Lightbox
        plugins={[Video]}
        open={props.isOpen}
        close={props.onClose}
        carousel={{ finite: true }} // ひとつの動画再生なのでカルーセルを無効にする
        slides={[
          {
            type: 'video',
            autoPlay: true,
            // ダウンロード不可の場合、chromeのコントロール中のダウンロードボタンを非表示にする
            // Firefoxは元々ダウンロードボタンがない
            controlsList: classNames({ nodownload: !props.showDownloadButton }),
            sources: [
              {
                src: props.streamingUrl,
                type: 'video/mp4',
              },
            ],
          },
        ]}
        className="lightbox-container" // css class for lightbox container
      />
    </div>
  )
})
