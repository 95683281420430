import { CrewButton } from 'components/elements/crewButton/crewButton'
import { memo } from 'react'
import { ProjectSettingTaskCategoryEntryDialog } from '../projectSettingTaskCategoryEntryDialog/projectSettingTaskCategoryEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { useTranslation } from '@crew/modules/i18n'
import { useProjectSettingPageContext } from 'features/project/components/projectSettingPage/useProjectSettingPage'
export const ProjectSettingTaskCategoryListToolbar = memo(() => {
  const { t } = useTranslation()

  const { projectSubject } = useProjectSettingPageContext()

  const [
    isProjectSettingTaskCategoryEntryDialogOpen,
    openProjectSettingTaskCategoryEntryDialog,
    closeProjectSettingTaskCategoryEntryDialog,
  ] = useModal()

  return (
    <div className="text-right">
      <CrewButton
        type="primary"
        text={t('action.newTaskCategory')}
        onClick={openProjectSettingTaskCategoryEntryDialog}
      />

      <ProjectSettingTaskCategoryEntryDialog
        isEditMode={false}
        title={t('label.addTaskCategory') + ' - ' + projectSubject}
        isOpen={isProjectSettingTaskCategoryEntryDialogOpen}
        onClose={closeProjectSettingTaskCategoryEntryDialog}
        taskCategoryId={null}
        version={null}
      />
    </div>
  )
})
