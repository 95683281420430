import { FC, memo, useCallback } from 'react'

import BaselineError from '~icons/ic/baseline-error'

import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useNotificationPermission } from 'hooks/pushNotification/useNotificationPermission'

export const AppRequestNotificationPermissionBar: FC = memo(() => {
  const { t } = useTranslation()
  const [permission, requestPermissionAsync] = useNotificationPermission()

  const handlePermissionRequestButtonClick = useCallback(() => {
    requestPermissionAsync()
  }, [requestPermissionAsync])

  return permission === 'default' ? (
    <div className="flex flex-row items-center h-[3.25rem] p-2.5 gap-1.5 border-b crew-border-gray">
      <BaselineError className=" text-crew-red-500" width={24} height={24} />
      <p>{t('message.notification.requestPermission')}</p>
      <CrewButton
        type="primary"
        text={t('action.configureNotification')}
        onClick={handlePermissionRequestButtonClick}
      />
    </div>
  ) : null
})
