import { FC, SVGProps, memo, useMemo } from 'react'
import classNames from 'classnames'

/**
 * タブコンポーネントのプロパティ
 *  selected:
 *    チャットパネルのように、URLを持たず画面遷移を伴わない場合に指定する
 */
export type CrewTabProps = {
  text: string
  selected: boolean
  onClick: () => void
  icon?: (props: SVGProps<SVGSVGElement>) => React.ReactElement
}

/**
 * タブコンポーネント
 */
export const CrewTab: FC<CrewTabProps> = memo((props) => {
  // props.selectedで選択状態を制御するため、
  // 選択されたタブが自身を示すかどうかを返す
  const isSelectedTab = useMemo(() => {
    return props.selected
  }, [props.selected])

  const selectedTabClassNames = useMemo(() => {
    return classNames(
      'flex flex-row gap-1 items-center h-full p-2 cursor-pointer',
      'font-bold rounded-t-md border-b-2 text-crew-gray-900 dark:text-crew-gray-50 crew-bg-default border-b-crew-cyan-600 dark:border-b-crew-cyan-600 border-x border-x-crew-gray-200 dark:border-x-crew-gray-700'
    )
  }, [])

  const SvgIcon = props.icon

  return isSelectedTab ? (
    <div className={selectedTabClassNames}>
      {SvgIcon && <SvgIcon width={24} height={24} />}
      <span className="font-bold whitespace-nowrap">{props.text}</span>
    </div>
  ) : (
    <div
      className="flex flex-row gap-1 items-center h-full p-2 cursor-pointer"
      onClick={props.onClick}
    >
      {SvgIcon && <SvgIcon width={24} height={24} />}
      <span className="whitespace-nowrap">{props.text}</span>
    </div>
  )
})
