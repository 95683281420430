import { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { CrewCheckBox } from 'components/devextreme/crewCheckBox'
import TreeView from 'devextreme-react/tree-view'
import { TextBox } from 'devextreme-react'
import { GetSuggestionTasksForWorkTimesRegisterRequest } from '@crew/apis/task/models/getSuggestionTasksForWorkTimesRegister/request'
import { useGetSuggestionTasksForWorkTimesRegisterQuery } from '@crew/apis/task/taskApis'
import { ItemClickEvent } from 'devextreme/ui/tree_view'
import { debounce } from 'lodash'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { useEntityRecordDataSource } from 'hooks/dataSource/useEntityRecordDataSource'
import { EntityType } from '@crew/enums/domain'
import { ProjectRef, UserRef } from '@crew/models/refs'
import { CrewTreeView } from 'components/devextreme/CrewTreeView'

type Task = {
  taskId: string
  subject: string
  parentTaskId: string | undefined
  version: number
  children: Task[] | undefined
  assignToUser: UserRef | undefined
  project: ProjectRef
}

type TreeTask = Task & {
  expanded: boolean
}

type ProjectGroup = {
  taskId: string
  subject: string
  parentTaskId: null
  children: TreeTask[] | undefined
  expanded: boolean
}

// add expanded property to task and its children
const addExpandedProperty = (task: Task): TreeTask => {
  return {
    ...task,
    expanded: true,
    children: task.children
      ? task.children.map(addExpandedProperty)
      : undefined,
  }
}

// group tasks by project
const groupTasksByProject = (tasks: Task[]): ProjectGroup[] => {
  const projectMap: Record<string, ProjectGroup> = {}

  tasks.forEach((task) => {
    const projectId = task.project.id
    const projectSubject = task.project.subject

    if (!projectMap[projectId]) {
      projectMap[projectId] = {
        taskId: projectId,
        subject: projectSubject,
        parentTaskId: null,
        children: [],
        expanded: true,
      }
    }

    projectMap[projectId].children?.push(addExpandedProperty(task))
  })

  return Object.values(projectMap)
}

type SearchTasksForWorkTimesPanelProps = {
  onTaskSelected: (task: Task) => void
}

export const SearchTasksForWorkTimesPanel: FC<SearchTasksForWorkTimesPanelProps> =
  memo((props) => {
    const { t } = useTranslation()

    const projectDataSource = useEntityRecordDataSource(EntityType.Project)

    const treeViewRef = useRef<TreeView<Task> | null>(null)
    // キーワード検索
    const keywordTextBoxRef = useRef<TextBox>(null)

    const [keyword, setKeyword] = useState('')
    const [projectId, setProjectId] = useState<string>()
    const [isOnlyIncludesMyTasks, setIsOnlyIncludesMyTasks] = useState(true)
    const [isIncludesCompletedTasks, setIsIncludesCompletedTasks] =
      useState(false)

    const getSuggestionTasksForWorkTimesRegisterParams: GetSuggestionTasksForWorkTimesRegisterRequest =
      {
        keyword,
        projectId: projectId ?? undefined,
        isOnlyIncludesMyTasks,
        isIncludesCompletedTasks,
      }

    const { data: getSuggestionTasksForWorkTimesRegisterResult } =
      useGetSuggestionTasksForWorkTimesRegisterQuery(
        getSuggestionTasksForWorkTimesRegisterParams
      )

    const suggestionTasks = useMemo(() => {
      if (!getSuggestionTasksForWorkTimesRegisterResult?.tasks) return []

      // タスクをプロジェクトごとにグループ化
      const groupTasks = groupTasksByProject(
        getSuggestionTasksForWorkTimesRegisterResult.tasks
      )

      return groupTasks
    }, [getSuggestionTasksForWorkTimesRegisterResult?.tasks])

    // キーワード検索の遅延実行
    const debouncedSearch = useMemo(
      () =>
        debounce((value) => {
          setKeyword(value)
        }, SEARCH_TIMEOUT_MSEC),
      []
    )

    /**
     * キーワードテキストボックスの値変更時の処理
     */
    const handleKeywordValueChanged = useCallback(() => {
      // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
      const input = keywordTextBoxRef.current?.instance.option('text')

      if (input !== keyword) {
        debouncedSearch(input)
      }
    }, [keyword, debouncedSearch])

    // Event handler for when the project value is changed
    const handleProjectValueChange = useCallback((value: string) => {
      setProjectId(value)
    }, [])

    // Event handler for when the "Show only my tasks" checkbox value is changed
    const handleIsOnlyIncludesMyTasksChange = useCallback(
      (value: boolean | null) => {
        setIsOnlyIncludesMyTasks(value ?? false)
      },
      []
    )

    // Event handler for when the "Show completed tasks" checkbox value is changed
    const handleIsIncludesCompletedTasksChange = useCallback(
      (value: boolean | null) => {
        setIsIncludesCompletedTasks(value ?? false)
      },
      []
    )

    // Event handler for when the tree view item is clicked
    const handleTreeViewItemClick = useCallback(
      (event: ItemClickEvent<Task>) => {
        if (!event.itemData) return

        // If node is not a last node, do nothing.
        if (event.node?.children && event.node.children.length > 0) {
          // Expand or collapse the node.
          if (event.itemData.expanded) {
            event.component.collapseItem(event.itemData)
          } else {
            event.component.expandItem(event.itemData)
          }
          return
        }

        const itemData = event.itemData as Task

        props.onTaskSelected(itemData)
      },
      [props]
    )

    return (
      <div className="flex flex-col gap-2.5 w-80">
        <div className="flex flex-col gap-2.5">
          <p className="font-bold">{t('label.searchCondition')}</p>

          {/* キーワード */}
          <div className="flex flex-col gap-1">
            <CrewFieldLabel text={t('label.keyword')} />
            <CrewTextBox
              ref={keywordTextBoxRef}
              mode="search"
              showClearButton={true}
              valueChangeEvent="input change"
              onInput={handleKeywordValueChanged}
            />
          </div>

          {/* プロジェクト */}
          <div className="flex flex-col gap-1">
            <CrewFieldLabel text={t('label.project')} />
            <CrewSelectBox
              dataSource={projectDataSource}
              valueExpr="id"
              displayExpr="name"
              searchExpr="name"
              searchEnabled={true}
              minSearchLength={0}
              showClearButton={true}
              onValueChange={handleProjectValueChange}
            />
          </div>

          {/* 自分が担当のタスクのみ表示 */}
          <CrewCheckBox
            text={t('label.showOnlyMyTasks')}
            value={isOnlyIncludesMyTasks}
            onValueChange={handleIsOnlyIncludesMyTasksChange}
          />

          {/* 完了したタスクも表示 */}
          <CrewCheckBox
            text={t('label.showCompletedTasks')}
            value={isIncludesCompletedTasks}
            onValueChange={handleIsIncludesCompletedTasksChange}
          />
        </div>

        {/* タスク */}
        <div className="flex flex-col gap-2.5 flex-1 overflow-hidden">
          <p className="font-bold">{t('general.entityType.tasks')}</p>

          <div className="overflow-y-auto flex-1">
            <CrewTreeView
              ref={treeViewRef}
              items={suggestionTasks}
              dataStructure="tree"
              displayExpr="subject"
              itemsExpr="children"
              keyExpr="taskId"
              selectionMode="single"
              focusStateEnabled={false}
              selectNodesRecursive={false}
              onItemClick={handleTreeViewItemClick}
              scrollDirection="vertical"
              useNativeScrolling={true}
            />
          </div>
        </div>
      </div>
    )
  })
