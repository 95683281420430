import { memo } from 'react'
import {
  CrewAvatarShapeType,
  CrewAvatarSize,
  CrewAvatar,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewLink } from '../crewLink/crewLink'
import { generateImageAvatarUrl } from '@crew/utils/dist/avatar'
import { EntityType } from '@crew/enums/dist/domain'
import { getDefaultTabValue } from '@crew/utils/dist/enum'
import { ProjectListTabs } from 'enums/app'

type CrewProjectNameItemProps = {
  projectId: string
  projectName: string
  projectVersion: number
  isPrivate: boolean
  className?: string
}

export const CrewProjectNameItem = memo(
  (cellData: CrewProjectNameItemProps) => {
    return (
      <div className="flex items-center gap-x-2">
        <CrewAvatar
          displayName={cellData.projectName}
          size={CrewAvatarSize.xs}
          shape={CrewAvatarShapeType.Square}
          isPrivate={cellData.isPrivate}
          imageURL={generateImageAvatarUrl(
            EntityType.Project,
            cellData.projectId
          )}
          cacheValue={cellData.projectId + cellData.projectVersion}
        />

        <CrewLink
          to={`/projects/${cellData.projectId}/${getDefaultTabValue(
            ProjectListTabs
          )}`}
          title={cellData.projectName}
          className={cellData.className ?? ''}
        >
          {cellData.projectName}
        </CrewLink>
      </div>
    )
  }
)
