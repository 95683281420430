import parse from 'html-react-parser'
import { LinkCard } from '../crewHtmlEditor/components/linkCard'
import { sanitizeHtml } from 'utils/html'

type Props = {
  html: string
}

export const CrewHtmlContent: React.FC<Props> = ({ html }) => {
  const options = {
    replace: (domNode: any) => {
      // 'data-linkcard'がDomに含まれていた場合は、LinkCardに置き換える
      if (domNode.attribs && domNode.attribs['data-linkcard'] !== undefined) {
        const url = domNode.attribs['data-linkcard-url']
        if (url) {
          return <LinkCard url={url} />
        }
      }
    },
  }

  return <>{parse(sanitizeHtml(html), options)}</>
}
