import { FC, memo } from 'react'
import { EventTimePeriod } from 'enums/app'
import { ScheduleGroupMonthly } from './components/scheduleGroupMonthly/scheduleGroupMonthly'
import { ScheduleMonthly } from './components/scheduleMonthly/scheduleMonthly'
import { ScheduleWeekly } from './components/scheduleWeekly/scheduleWeekly'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { ScheduleToolbar } from './components/scheduleToolbar/scheduleToolbar'

export const SchedulePage: FC = memo(() => {
  return (
    <div className="flex flex-col gap-2.5 p-2.5 h-full">
      <CrewErrorBoundary>
        <ScheduleToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        {/* URLに応じて表示するコンポーネントを切り替える */}
        <Routes>
          {/** A route to transition to "groupMonthly" when accessing the root (/) */}
          <Route
            index
            element={
              <Navigate
                to={EventTimePeriod.GroupMonthly.value}
                replace={true}
              />
            }
          />
          <Route
            path={EventTimePeriod.GroupMonthly.value}
            element={<ScheduleGroupMonthly />}
          />
          <Route
            path={EventTimePeriod.Monthly.value}
            element={<ScheduleMonthly />}
          />
          <Route
            path={EventTimePeriod.Weekly.value}
            element={<ScheduleWeekly />}
          />
        </Routes>
      </CrewErrorBoundary>
    </div>
  )
})
