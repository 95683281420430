import { useGetTenantQuery } from '@crew/apis/tenantSetting/tenantSettingApis'
import { useEffect } from 'react'
import { changeDocumentTitle } from 'utils/html'
let didInit = false
// change title tab name hook
export const useChangeDocumentTitle = () => {
  const { data: getTenant } = useGetTenantQuery()
  useEffect(() => {
    // change title document to organization name
    if (getTenant?.tenant && !didInit) {
      didInit = true
      changeDocumentTitle(getTenant.tenant.name)
    }
  }, [getTenant?.tenant])
}
