import { ReactNode, memo, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineCreate from '~icons/ic/baseline-create'
import Check from '~icons/material-symbols/check'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'

type EventKind = {
  name: string
  displayColor: string
  initialValue: boolean
  createdAt: string
}

type EventKindListItemProps = {
  eventKind: EventKind
  onEditButtonClick: () => void
  onDeleteButtonClick: () => void
  dragHandle: ReactNode
}

export const EventKindListItem = memo((props: EventKindListItemProps) => {
  const { t } = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // 名称
  const renderNameCell = useMemo(() => {
    return (
      <CrewBadge displayColor={props.eventKind.displayColor}>
        {props.eventKind.name}
      </CrewBadge>
    )
  }, [props.eventKind.displayColor, props.eventKind.name])

  // 登録日時
  const renderRegisteredDateCell = useMemo(
    () =>
      t('format.timestamp', {
        value: props.eventKind.createdAt,
        timeZone: defaultUserProfileRegion,
      }),
    [t, props.eventKind.createdAt, defaultUserProfileRegion]
  )

  //initial value
  const renderInitialValueCell = useMemo(
    () => <Check width={24} height={24} />,
    []
  )

  return (
    <>
      {/* drag handle item */}
      <td>{props.dragHandle}</td>

      {/* name */}
      <td className="w-4/12 max-w-0">{renderNameCell}</td>

      {/* initial value */}
      <td className="w-1/12">
        {props.eventKind.initialValue && renderInitialValueCell}
      </td>

      {/* registered date */}
      <td className="w-4/12">{renderRegisteredDateCell}</td>

      {/* Action button */}
      <td className="w-3/12">
        <div className="flex gap-2 pr-2 justify-end items-center">
          <span className="cursor-pointer" onClick={props.onEditButtonClick}>
            <BaselineCreate width={24} height={24} />
          </span>
          <span className="cursor-pointer" onClick={props.onDeleteButtonClick}>
            <BaselineDelete width={24} height={24} />
          </span>
        </div>
      </td>
    </>
  )
})
