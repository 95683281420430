import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { memo, FC } from 'react'
import { BookmarkHeader } from './components/bookmarkHeader/bookmarkHeader'
import { BookmarkMessageList } from './components/bookmarkMessageList/bookmarkMessageList'

/**
 * ブックマーク
 */
export const BookmarkPage: FC = memo(() => {
  return (
    <>
      {/* アーカイブフィルタとキーワード検索 */}
      <CrewErrorBoundary>
        <BookmarkHeader />
      </CrewErrorBoundary>

      {/* Bookmark一覧 */}
      <CrewErrorBoundary>
        <BookmarkMessageList />
      </CrewErrorBoundary>
    </>
  )
})
