import { useCallback } from 'react'
import {
  useDeleteTaskKindMutation,
  useReorderTaskKindMutation,
} from '@crew/apis/task/taskApis'
import { ReorderTaskKindRequest } from '@crew/apis/task/models/reorderTaskKind/request'
import { useAppDispatch } from 'states/hooks'
import { TaskKind } from '@crew/models/domain'
import {
  notifyTaskKindEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'

export type SelectedTaskKind = {
  taskKindId: string
  version: number
}

export const useTaskKindListGrid = () => {
  const dispatch = useAppDispatch()

  // Get functions for update task type information
  const [reorderTaskKindMutation] = useReorderTaskKindMutation()
  const [deleteTaskKindMutation] = useDeleteTaskKindMutation()

  // Delete task type process
  const deleteTaskKind = useCallback(
    async (taskKindId: string, version: number) => {
      await deleteTaskKindMutation({
        taskKindId,
        version,
      }).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TaskKind> = {
        eventType: NotifyEventType.Deleted,
        id: taskKindId,
        object: undefined,
      }

      dispatch(notifyTaskKindEvent(objectEventMessage))
    },
    [deleteTaskKindMutation, dispatch]
  )

  // Delete task type process
  const reorderTaskKind = useCallback(
    async (taskKindId: string, targetPositionTaskKindId: string) => {
      const request: ReorderTaskKindRequest = {
        taskKindId,
        targetPositionTaskKindId,
      }

      await reorderTaskKindMutation(request).unwrap()
    },
    [reorderTaskKindMutation]
  )

  return {
    deleteTaskKind,
    reorderTaskKind,
  }
}
