import {
  CODE_LANGUAGE_FRIENDLY_NAME_MAP as CODE_LANGUAGE_FRIENDLY_NAME_MAP_OFFICIAL,
  CODE_LANGUAGE_MAP as CODE_LANGUAGE_MAP_OFFICIAL,
} from '@lexical/code'

export const CODE_LANGUAGE_FRIENDLY_NAME_MAP: Record<string, string> = {
  ...CODE_LANGUAGE_FRIENDLY_NAME_MAP_OFFICIAL,
  go: 'Go',
}

export const CODE_LANGUAGE_MAP: Record<string, string> = {
  ...CODE_LANGUAGE_MAP_OFFICIAL,
  go: 'go',
}
