import { ComponentProps, useMemo } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { genericMemo } from 'utils'
import { CrewErrorMessages } from 'components/forms/crewErrorMessages'

import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import classNames from 'classnames'

const url: URL = new URL(window.location.href)

const protocol = `${url.protocol}//`
const baseHost = url.host.split('.').slice(1).join('.')

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  ComponentProps<typeof CrewTextBox>,
  'ref' | 'name' | 'value' | 'onValueChange' | 'onFocusOut' | 'isValid' //これらはcontrollerが提供するのでpropsから除外する
> & {
  id: string
  required?: boolean
  label?: string
  showLabel?: boolean
} & UseControllerProps<TFieldValues, TName>

/**
 * テナントID入力専用のテキストボックス。
 * テナントID入力時は左右にURLの一部が表示されるが、エラーメッセージをそれらの幅全域に表示するために、
 * TextBoxFieldの構造上、専用のコンポーネントとする必要がある
 */
export const TenantIdTextBoxField = genericMemo(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
    // useController用
    name,
    rules,
    control,
    shouldUnregister,
    defaultValue,
    // ラベル用
    id,
    required = false,
    label,
    showLabel = true,
    // その他
    children,
    className,
    ...rest
  }: Props<TFieldValues, TName>) => {
    const { field, fieldState, formState } = useController({
      name,
      control,
      rules,
      shouldUnregister,
      defaultValue,
    })

    // 要素にid属性を付与するためのオブジェクト
    // data-testidのようなカスタム属性はinputAttrで指定する必要がある
    // https://supportcenter.devexpress.com/ticket/details/t1052998/form-unable-to-pass-custom-attributes-to-items
    const inputAttr = useMemo(() => {
      return { id: id, 'data-testid': field.name }
    }, [field.name, id])

    return (
      <div className="flex flex-col gap-1">
        {showLabel && (
          <CrewFieldLabel text={label} required={required} htmlFor={id} />
        )}
        <div className="flex flex-row items-center gap-3">
          <span className="text-lg">{protocol}</span>
          <CrewTextBox
            {...rest}
            className={classNames('flex-grow', className)}
            inputAttr={inputAttr}
            name={field.name}
            value={field.value}
            onValueChange={field.onChange}
            onFocusOut={field.onBlur}
            isValid={!fieldState.error}
          >
            {children}
          </CrewTextBox>
          <span className="text-lg">.{baseHost}</span>
        </div>
        <CrewErrorMessages
          isValid={!fieldState.error}
          errors={formState.errors}
          field={field}
        />
      </div>
    )
  }
)
