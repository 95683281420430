import { FC, memo } from 'react'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { EventKindListToolbar } from './components/eventKindListToolbar/eventKindListToolbar'
import { EventKindListGrid } from './components/eventKindListGrid/eventKindListGrid'

export const ProjectSettingEventKindList: FC = memo(() => {
  return (
    <div className="flex flex-col gap-2">
      <CrewErrorBoundary>
        <EventKindListToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <EventKindListGrid />
      </CrewErrorBoundary>
    </div>
  )
})
