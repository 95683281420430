import { FC, Fragment, memo, useCallback, useState } from 'react'

import { ChatView } from '@crew/enums/app'

import { getChatMessageListItemDomId } from 'utils/chat'
import { CrewUnreadLine } from 'components/elements/crewUnreadLine/crewUnreadLine'

import { ChatThreadListMessageListItem } from './components/chatThreadListMessageListItem/chatThreadListMessageListItem'
import { useChatThreadListMessageList } from './useChatThreadListMessageList'

export const ChatThreadListMessageList: FC = memo(() => {
  const {
    displayItems,
    itemsScrollableDivRef,
    messageInView,
    messageOutOfView,
  } = useChatThreadListMessageList()

  // メッセージ表示イベントハンドラ
  const handleMessageInView = useCallback(
    (inView: boolean, messageId: string) => {
      if (inView) {
        messageInView(messageId)
      } else {
        messageOutOfView(messageId)
      }
    },
    [messageInView, messageOutOfView]
  )

  const [selectedMessageId, setSelectedMessageId] = useState<
    string | undefined
  >(undefined)

  return (
    // ThreadListMessageList
    <div
      className="grow flex overflow-y-scroll flex-col-reverse"
      ref={itemsScrollableDivRef}
    >
      <div className="grow flex flex-col">
        {displayItems.map((item) => (
          <Fragment key={item.id}>
            <CrewUnreadLine show={item.showUnreadLine} />
            <ChatThreadListMessageListItem
              key={item.id}
              // スクロール位置制御のためChatViewを含めたidとして指定する
              id={getChatMessageListItemDomId(
                item.messageId,
                ChatView.ThreadList
              )}
              replyCount={item.threadReplyCount}
              chatMessageId={item.messageId}
              container={itemsScrollableDivRef}
              onMessageInView={handleMessageInView}
              onAdditionalLoading={item.handleAdditionalLoading}
              onFirstUnreadMessageViewed={item.handleFirstUnreadMessageViewed}
              isFirstUnreadMessage={item.isFirstUnreadMessage}
              selectedItemId={selectedMessageId}
              setSelectedItemId={setSelectedMessageId}
            />
          </Fragment>
        ))}
      </div>
    </div>
  )
})
