import { FC, memo, useCallback, useEffect, useState } from 'react'
import { CrewVideoPlayer } from '../crewVideoPlayer/crewVideoPlayer'
import FileVideoLine from '~icons/ri/file-video-line'
import {
  PREVIEW_SUPPORTED_VIDEO_FILE_TYPES,
  getFileType,
  isOfficeFile,
  isValidPreviewFile,
} from '@crew/utils/file'
import { formatByteSize } from '@crew/utils/number'
import { CrewDocViewer } from '../crewDocViewer/crewDocViewer'
import { useTranslation } from '@crew/modules/i18n'
import { useAppSelector } from 'states/hooks'
import { useGetFileQuery } from '@crew/apis/file/fileApis'
import { GetFileRequest } from '@crew/apis/file/models/getFile/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { IDocument } from '@cyntler/react-doc-viewer'
import { useFileSignedUrl } from 'hooks/useFileSignedUrl'

export type CrewFilePreviewProps = {
  fileId: string
}

export const CrewFilePreview: FC<CrewFilePreviewProps> = memo((props) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const fileEventMessage = useAppSelector((state) => state.app.fileEventMessage)

  // ファイル詳細の取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getFileParam: GetFileRequest | undefined = props.fileId
    ? {
        fileId: props.fileId,
      }
    : undefined
  const { data: getFileResult, refetch: getFileRefetch } = useGetFileQuery(
    getFileParam ?? skipToken
  )

  const [documents, setDocuments] = useState<IDocument[]>([])
  const { getFileSignedUrl, getFileSignedUrlForOfficePreview } =
    useFileSignedUrl()

  // generate preview document
  const generatePreviewDocument = useCallback(async () => {
    if (!getFileResult?.file) return

    // Check if file is supported by react-doc-viewer and video by light box
    if (!isValidPreviewFile(getFileResult.file.fileName)) {
      return
    }

    let signedUrl = ''

    // Call API to get url of preview file
    // If it is a office file, call api to get signed url of file preview for office file ,
    // othewise, call api to get signed url of target file
    if (isOfficeFile(getFileResult.file.fileName)) {
      signedUrl = await getFileSignedUrlForOfficePreview(props.fileId)
    } else {
      signedUrl = await getFileSignedUrl(props.fileId)
    }

    // Get file extension from the file name
    const extension = getFileResult.file.fileName
      .split('.')
      .pop()
      // In PREVIEW_SUPPORTED_EXTENSIONS, the supported extension file has been defined in lower case form,
      // so it is need to convert the file extension of the preview file to lower case too.
      ?.toLowerCase()

    // Extract the file type from the file name
    const fileType = getFileType(extension)

    setDocuments([
      {
        uri: signedUrl,
        fileType,
        fileName: getFileResult.file.fileName,
      },
    ])
  }, [
    getFileResult?.file,
    getFileSignedUrl,
    props.fileId,
    getFileSignedUrlForOfficePreview,
  ])

  useEffect(() => {
    generatePreviewDocument()
  }, [generatePreviewDocument])

  useEffect(() => {
    props.fileId && getFileRefetch()
  }, [fileEventMessage, props.fileId, getFileRefetch])

  // close light box preview
  const handleLightBoxClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  // open lightbox preview
  const handleOpenVideoPreview = useCallback(() => {
    setIsOpen(true)
  }, [])

  // if no can document preview show message
  if (documents.length === 0) {
    return <div>{t('message.file.couldNotPreview')}</div>
  }
  // check document is video to preview with light box
  if (
    documents[0].fileType &&
    PREVIEW_SUPPORTED_VIDEO_FILE_TYPES.includes(documents[0].fileType)
  ) {
    return (
      <div>
        {/* preview video */}
        <div
          onClick={handleOpenVideoPreview}
          className="flex w-60 h-28 p-2.5 rounded-lg border crew-border-gray flex-col justify-center items-center gap-2.5 hover:cursor-pointer"
        >
          <FileVideoLine width={24} height={24} />
          <div className="flex flex-col justify-center items-center w-full">
            {/* file name */}
            <span className="text-center w-full break-all truncate">
              {documents[0].fileName}
            </span>
            {/* file size */}
            <span className="text-center">
              {formatByteSize(getFileResult?.file?.size ?? 0)}
            </span>
          </div>
        </div>
        <CrewVideoPlayer
          streamingUrl={documents[0].uri}
          isOpen={isOpen}
          showDownloadButton={false}
          onClose={handleLightBoxClose}
        />
      </div>
    )
  } else {
    // check document preview with DocViewer
    return <CrewDocViewer documents={documents} />
  }
})
