import { TimelineDirection } from '@crew/enums/app'
import { ChatMessage } from '@crew/models/domain'
import {
  Bookmark,
  useBookmarkService,
  useChatMessageService,
} from '@crew/states'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useLazyGetBookmarksQuery } from '@crew/apis/bookmark/bookmarkApis'
import { useFetchLimit } from '@crew/hooks'
import { MESSAGE_AREA_MIN_HEIGHT } from '@crew/configs/constants'
import { getWindowDimensions } from 'utils'
import { GetBookmarksRequest } from '@crew/apis/dist/bookmark/models/getBookmarks/request'

// メッセージアイテムの型
type DisplayMessageItem = {
  id: string // メッセージIDと表示形式を組み合わせたID
  handleAdditionalLoading: (() => void) | undefined // 追加読込処理
}

// messagesから表示用アイテムへ変換する関数の戻り値の型
type ConvertMessagesToDisplayItemsResult = {
  items: DisplayMessageItem[]
}

/**
 * 複数メッセージ追加用の型
 */
type AddMessageParams = {
  bookmarkMessages: ChatMessage[]
  criterionMessageId: string | undefined
  direction: TimelineDirection
  isLoadedOfEndOfMessages: boolean
}

/**
 * ブックマークメッセージをロードするためのパラメータ
 */
type LoadBookmarksParams = {
  criterionMessageId: string | undefined
  direction: TimelineDirection
}

/**
 * 引数のmessagesから表示用のアイテムリストを生成する関数
 * @param messages ViewModelから取得したmessages
 * @param loadBookmarks ブックマークメッセージをロードする関数
 * @returns 表示用アイテムリスト
 */
export const convertMessagesToDisplayItems = (
  messages: Bookmark.BookmarkMessage[],
  loadBookmarks: (params: LoadBookmarksParams) => void
): ConvertMessagesToDisplayItemsResult => {
  const items: DisplayMessageItem[] = []

  messages.forEach((messageItem, index) => {
    // 追加読込の方向を決定する。必要無ければundefined
    const additionalLoadingDirection =
      messageItem.hasMorePrev && messageItem.hasMoreNext // 時間軸上で両方向の隣のメッセージが未読込だった場合はboth
        ? TimelineDirection.BothNewerAndOlder
        : messageItem.hasMorePrev // 時間軸上でprev方向の隣のメッセージが未読込だった場合はolder
        ? TimelineDirection.Older
        : messageItem.hasMoreNext // 時間軸上でnext方向の隣のメッセージが未読込だった場合はnewer
        ? TimelineDirection.Newer
        : undefined // 両方向の隣のメッセージが読込済だった場合は追加読込は不要

    // 追加読込処理を行う関数を生成する。必要無ければundefined
    const handleAdditionalLoading = additionalLoadingDirection
      ? () =>
          loadBookmarks({
            criterionMessageId: messageItem.id,
            direction: additionalLoadingDirection,
          })
      : undefined

    items.push({
      id: messageItem.id,
      handleAdditionalLoading,
    })
  })

  return { items }
}

export const useBookmarkMessageList = () => {
  const dispatch = useAppDispatch()

  // 取得したチャットメッセージをSliceにキャッシュする操作を提供するService
  const chatMessageService = useChatMessageService(dispatch)
  // Bookmark関連のSliceへの操作を提供するService
  const bookmarkService = useBookmarkService(dispatch)

  // ブックマークデータ取得用のクエリ
  const [lazyGetBookmarksQuery] = useLazyGetBookmarksQuery()

  // Reduxに格納されているアーカイブフィルタの値を取得する
  const archiveFilter = useAppSelector(
    (state) => state.message.bookmark.archiveFilter
  )
  // Reduxに格納されているキーワードの値を取得する
  const keyword = useAppSelector((state) => state.message.bookmark.keyword)
  // Reduxに格納されているブックマークのメッセージのDictionaryを取得する
  const bookmarkMessageDictionary = useAppSelector(
    (state) => state.message.bookmark.messages.entities
  )
  // Reduxに格納されている対象ブックマークのメッセージのIDリストを取得する
  // Reduxに格納されている順番で取得する必要があるので、entitiesだけでなくidsも取得しておく必要がある
  const bookmarkMessageIds = useAppSelector(
    (state) => state.message.bookmark.messages.ids
  )

  // 表示対象のメッセージを返す
  const bookmarkMessages = useMemo(() => {
    if (!bookmarkMessageIds || !bookmarkMessageDictionary) {
      return []
    }
    // id順にメッセージを設定する
    return bookmarkMessageIds.reduce(
      (result: Bookmark.BookmarkMessage[], id) => {
        const message = bookmarkMessageDictionary[id]

        if (message) {
          result.push(message)
        }
        return result
      },
      []
    )
  }, [bookmarkMessageDictionary, bookmarkMessageIds])

  // 一度にfetchするサイズ
  const fetchLimit = useFetchLimit(MESSAGE_AREA_MIN_HEIGHT, getWindowDimensions)

  /**
   * 複数アイテムをViewModelに追加する
   * @param params
   */
  const addItems = useCallback(
    (params: AddMessageParams) => {
      // ViewModelへデータを追加
      const parameter = {
        messages: params.bookmarkMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfMessages,
      }
      bookmarkService.addBookmarkMessages(parameter)
    },
    [bookmarkService]
  )

  /**
   * API経由で取得したチャットメッセージをメッセージのキャッシュとViewModelに追加する
   */
  const addMessages = useCallback(
    (params: AddMessageParams) => {
      // キャッシュへ追加
      chatMessageService.addChatMessagesToCache({
        chatMessages: params.bookmarkMessages,
      })
      // ViewModelへ追加
      addItems({
        bookmarkMessages: params.bookmarkMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfMessages: params.isLoadedOfEndOfMessages,
      })
    },
    [addItems, chatMessageService]
  )

  /**
   * アイテム単体をViewModelから削除する
   * @param id
   */
  const deleteMessage = useCallback(
    (id: string) => {
      bookmarkService.deleteBookmarkMessage({ bookmarkId: id })
    },
    [bookmarkService]
  )

  /**
   * ブックマークメッセージをロードするメソッド
   * @param params 対象メッセージを絞り込むためのパラメータ
   * @returns
   */
  const loadBookmarks = useCallback(
    async (params: LoadBookmarksParams) => {
      const request: GetBookmarksRequest = {
        filter: archiveFilter,
        keyword,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        limit: fetchLimit,
      }

      try {
        const data = await lazyGetBookmarksQuery(request).unwrap()

        const isLoadedOfEndOfMessages =
          request.limit > data.bookmarkMessages.length

        const addMessagesPayload: AddMessageParams = {
          bookmarkMessages: data.bookmarkMessages,
          ...request, // 型が違うがduck typeによって代入可能
          isLoadedOfEndOfMessages,
        }

        // ブックマークメッセージをsliceとviewmodelに追加
        // sliceにはチャットメッセージだけ残してキャッシュとして利用する
        addMessages(addMessagesPayload)
      } catch (err) {
        // TODO: CREW-13720の対応で、プロジェクトから退出した際にエラートーストが出てしまう問題が発生し、トースト表示をコメントアウトする暫定対応を行った
        // ただ、これにより追加ロード時にエラーがあっても画面上表示されないという状態であるため、以下タスクで恒久対応を行う
        // 現時点ではコンソールにエラーを表示するにとどめる
        // https://break-tmc.atlassian.net/browse/CREW-13724
        // toast.error(t('message.general.failedToRetrieveData'))
        console.error(err)
      }
    },
    [addMessages, archiveFilter, fetchLimit, keyword, lazyGetBookmarksQuery]
  )

  // アイテムが領域内に表示された
  const messageInView = useCallback(
    (messageId: string) => {
      // スクロール位置を戻すための対象messageIdを保持しておく
      bookmarkService.setBookmarkScrollToMessageId({
        chatMessageId: messageId,
      })
    },
    [bookmarkService]
  )

  // ブックマーク表示用アイテムリスト
  const { items: displayItems } = useMemo(
    () => convertMessagesToDisplayItems(bookmarkMessages, loadBookmarks),
    [bookmarkMessages, loadBookmarks]
  )

  // 検索処理（初期ロード・アーカイブフィルタ・キーワード検索変更時に使用）
  const searchBookmarkMessages = useCallback(() => {
    // ViewModelを初期化
    bookmarkService.resetBookmarks()
    // データを再取得
    loadBookmarks({
      criterionMessageId: undefined, // 最新のものをロード
      direction: TimelineDirection.Older,
    })
  }, [bookmarkService, loadBookmarks])

  return {
    displayItems,
    messageInView,
    searchBookmarkMessages,
    deleteMessage,
    keyword,
    archiveFilter,
  }
}
