import { BillingCycle } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { memo } from 'react'

type RenderCycleItemPriceProps = {
  billingCycle: BillingCycle
  billingCycleFee: number | undefined
}

export const RenderCycleItemPrice = memo((props: RenderCycleItemPriceProps) => {
  const { t } = useTranslation()

  if (!props.billingCycleFee) {
    return <div>-</div>
  }

  if (props.billingCycle === BillingCycle.Month) {
    // 月払
    return (
      <div className="text-blue-500">
        {t('label.cycleBilling.month.price', {
          price: props.billingCycleFee.toLocaleString(),
        })}
      </div>
    )
  }

  // 年払

  // 年額
  const annualFee = props.billingCycleFee

  return (
    <>
      <div className="text-blue-500">
        {t('label.cycleBilling.year.price', {
          price: annualFee.toLocaleString(),
        })}
      </div>
      <div className="text-red-500 text-sm leading-5">
        {t('label.cycleBilling.year.bonus')}
      </div>
    </>
  )
})
