import bytes from 'bytes';
/**
 * 引数のバイト数を適切な単位で表示する文字列を返す関数
 * @param byte バイト数
 * @returns
 */
export const formatByteSize = (byte) => {
    // 1024未満（KB未満）の場合は小数点以下を表示しない
    // ※「byte=1023」の場合に1023Bという表示になるため、そちらも小数点以下を出さないようにするため1000ではなく1024としている
    if (byte < 1024) {
        return bytes.format(byte);
    }
    // 現在の仕様は以下のとおり
    //  ・小数点以下第二位まで表示する
    //  ・小数点以下が0でも常に小数点以下を表示する
    return bytes.format(byte, { decimalPlaces: 2, fixedDecimals: true });
};
/**
 * 契約プランのストレージ容量をGB単位で表示する文字列を返す関数
 * ※注※使用量はバイト単位で扱っているためformatByteSizeを使用すること
 * @date 9/5/2023 - 11:37:56 AM
 *
 * @param {number} planStorage ストレージ容量（GB）
 * @returns {string}
 */
export const formatContractStorageSize = (planStorage) => {
    // 契約プランのストレージ容量は、GB単位で扱っているためGBを明示的に指定する
    return bytes.format(bytes.parse(`${planStorage}GB`), {
        decimalPlaces: 0,
        fixedDecimals: true,
    });
};
// This function checks whether a string is a positive integer or not
export const isPositiveInteger = (value) => {
    const reg = new RegExp(/^\+?(0|[1-9]\d*)$/);
    return reg.test(value);
};
