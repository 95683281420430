import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileListDisplayMode } from 'enums/app'

type FileListStateType = {
  selectedFileListDisplayMode: number
}

const initialFileListState: FileListStateType = {
  selectedFileListDisplayMode: FileListDisplayMode.List.id,
}

export const fileListSlice = createSlice({
  name: 'fileList',
  initialState: initialFileListState,
  reducers: {
    setSelectedFileListDisplayMode: (state, action: PayloadAction<number>) => {
      state.selectedFileListDisplayMode = action.payload
    },
  },
})

export const { setSelectedFileListDisplayMode } = fileListSlice.actions
