import { PersonalSettingTabs } from 'enums/app'

import { memo } from 'react'
import OutlineSettings from '~icons/ic/outline-settings'
import { Route, Routes } from 'react-router-dom'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { PersonalSettingTab } from './components/personalSettingTab/personalSettingTab'
import { PersonalSettingSecurityPanel } from './components/personalSettingSecurityPanel/personalSettingSecurityPanel'
import { PersonalSettingDisplayPanel } from './components/personalSettingDisplayPanel/personalSettingDisplayPanel'
import { PersonalSettingProfilePanel } from './components/personalSettingProfilePanel/personalSettingProfilePanel'
import { PersonalSettingNotificationPanel } from './components/personalSettingNotificationPanel/personalSettingNotificationPanel'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useAppSelector } from 'states/hooks'

/**
 * PersonalSettingPage user setting page
 * @date 7/17/2023 - 5:21:18 PM
 *
 * @type {*}
 */
export const PersonalSettingPage = memo(() => {
  const { t } = useTranslation()
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  return (
    <div className="flex flex-col gap-2 p-2 h-full">
      <div className="w-full flex justify-between items-center h-9">
        {/* ページタイトル */}
        <div className="flex gap-2 items-center text-crew-gray-3-light dark:text-crew-gray-2-dark">
          <OutlineSettings width={24} height={24} />
          <p className="font-bold">{t('label.personalSetting')}</p>
        </div>
      </div>
      <span className="flex items-center gap-x-2.5">
        <CrewUserAvatar
          key={loggedInUser?.avatarUrl ?? ''} //refresh avatar when avatarUrl changed
          userId={loggedInUser?.id ?? ''}
          displayName={loggedInUser?.displayName ?? ''}
          size={CrewAvatarSize.lg}
          cacheValue={
            // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
            loggedInUser ? loggedInUser.id + loggedInUser.version : ''
          }
        />
        <p className="font-normal text-lg text-crew-gray-900 dark:text-crew-gray-50">
          {loggedInUser?.displayName ?? ''}
        </p>
      </span>

      <CrewErrorBoundary>
        <div className="flex-1 flex flex-col gap-y-5 h-full overflow-y-hidden">
          <PersonalSettingTab />
          <div className="grow min-h-0 w-full flex flex-col h-full overflow-y-scroll">
            {/* URLに応じて表示するコンポーネントを切り替える */}
            <Routes>
              <Route
                path={PersonalSettingTabs.Profile.value}
                element={<PersonalSettingProfilePanel />}
              />
              <Route
                path={PersonalSettingTabs.Display.value}
                element={<PersonalSettingDisplayPanel />}
              />
              <Route
                path={PersonalSettingTabs.Notification.value}
                element={<PersonalSettingNotificationPanel />}
              />
              <Route
                path={PersonalSettingTabs.Security.value}
                element={<PersonalSettingSecurityPanel />}
              />
            </Routes>
          </div>
        </div>
      </CrewErrorBoundary>
    </div>
  )
})
