import { Task } from '@crew/apis/task/models/getTasks/response'
import {
  useLazyGetSuggestionChildTasksQuery,
  useUpdateTaskParentMutation,
  useLazyGetTaskQuery,
} from '@crew/apis/task/taskApis'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTaskEvent,
} from 'features/app/states/appSlice'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'
import { DependencyTask } from '../taskDetailChildParentItem/taskDetailChildParentItem'
import { UpdateTaskParentRequest } from '@crew/apis/task/models/updateTaskParent/request'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'

export type FormValues = {
  childTasks: Task[]
}

export const useTaskDetailDependencyChildTask = (
  taskId: string | undefined
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  // react-hook-formの各種データを取得
  const { handleSubmit, formState, control, setError, reset } =
    useForm<FormValues>({
      criteriaMode: 'all',
    })

  const [lazyGetTaskQuery] = useLazyGetTaskQuery()
  const [updateTaskParentMutation] = useUpdateTaskParentMutation()
  const [lazyGetSuggestionChildTasksQuery] =
    useLazyGetSuggestionChildTasksQuery()

  const childTaskDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (loadOptions.filter) {
          if (!taskId) {
            return []
          }

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // Get selected task ids
          const selectedTaskIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetSuggestionChildTasksQuery({
            taskId,
            keyword: loadOptions.searchValue,
            selectedTaskIds,
          }).unwrap()

          return response.tasks
        } else {
          if (!loadOptions.searchValue || !taskId) {
            return []
          }

          const response = await lazyGetSuggestionChildTasksQuery({
            taskId,
            keyword: loadOptions.searchValue,
            selectedTaskIds: undefined,
          }).unwrap()

          return response.tasks
        }
      },
      byKey: async (id) => {
        const response = await lazyGetTaskQuery({
          taskId: id,
        }).unwrap()

        return response.task
      },
    }),
    [lazyGetSuggestionChildTasksQuery, lazyGetTaskQuery, taskId]
  )

  // 親タスクIDを更新する
  const updateTask = useCallback(
    async (childTasks: Task[], parentTaskId: string) => {
      const payload: UpdateTaskParentRequest = {
        parentTaskId: parentTaskId,
        // Map child tasks to task ID and version
        tasks: childTasks.map((task) => ({
          taskId: task.id,
          version: task.version,
        })),
      }
      await updateTaskParentMutation(payload).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Updated,
        id: parentTaskId,
        object: undefined,
      }

      dispatch(notifyTaskEvent(objectEventMessage))
    },
    [dispatch, updateTaskParentMutation]
  )

  // remove child task from parent task
  const removeChildTask = useCallback(
    async (task: DependencyTask) => {
      const payload: UpdateTaskParentRequest = {
        parentTaskId: null,
        // Map child tasks to task ID and version
        tasks: [{ taskId: task.id, version: task.version }],
      }
      await updateTaskParentMutation(payload).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Updated,
        id: task.id,
        object: undefined,
      }

      dispatch(notifyTaskEvent(objectEventMessage))
    },
    [dispatch, updateTaskParentMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      childTasks: {
        required: t('message.general.required'),
      },
    }),
    [t]
  )

  return {
    control,
    formState,
    validateRules,
    reset,
    setError,
    handleSubmit,
    updateTask,
    childTaskDataSource,
    removeChildTask,
  }
}
