import { skipToken } from '@reduxjs/toolkit/query/react';
import { useMemo } from 'react';
import { useGetMembersQuery } from '@crew/apis/lookup/lookupApis';
import { MentionType } from '@crew/enums/app';
import { useTranslation } from '@crew/modules/i18n';
/**
 * メンション用リストの作成
 * @param entityType エンティティタイプ
 * @param entityRecordId エンティティレコードID
 * @param mentionString 入力したメンション文字列
 * @returns MentionItem[]
 */
export const useMentionList = (entityType, entityRecordId, mentionString) => {
    const getMembersParams = entityType && entityRecordId
        ? {
            entityType,
            entityRecordId,
            keyword: mentionString !== null && mentionString !== void 0 ? mentionString : undefined,
            isEnabledUser: true,
        }
        : undefined;
    const { data: getMembersResult, isFetching } = useGetMembersQuery(getMembersParams !== null && getMembersParams !== void 0 ? getMembersParams : skipToken);
    const { t } = useTranslation();
    const allAndHere = useMemo(() => Object.values(MentionType).map((item) => ({
        id: item.id,
        displayName: item.id,
        avatarImage: undefined,
        listItemLabel: t(item.label),
        version: 0, // All、Hereはバージョンがないため0を設定
    })), [t]);
    const users = useMemo(() => {
        var _a;
        return (_a = getMembersResult === null || getMembersResult === void 0 ? void 0 : getMembersResult.projectMembers.map((member) => ({
            id: member.id,
            displayName: member.displayName,
            listItemLabel: member.displayName,
            avatarImage: undefined,
            version: member.version,
        }))) !== null && _a !== void 0 ? _a : [];
    }, [getMembersResult === null || getMembersResult === void 0 ? void 0 : getMembersResult.projectMembers]);
    const mentionList = useMemo(() => [...allAndHere, ...users], [allAndHere, users]);
    return { targets: mentionList, isFetching };
};
