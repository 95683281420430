import { memo } from 'react'
import { ProjectDetailTaskListPanel } from 'features/project/components/projectDetailPage/components/projectDetailTaskList/components/projectDetailTaskListPanel/projectDetailTaskListPanel'
import { ProjectDetailTaskListToolbar } from 'features/project/components/projectDetailPage/components/projectDetailTaskList/components/projectDetailTaskListToolbar/projectDetailTaskListToolbar'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

export const ProjectDetailTaskList = memo(() => {
  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <CrewErrorBoundary>
        <ProjectDetailTaskListToolbar />
      </CrewErrorBoundary>
      <CrewErrorBoundary>
        <ProjectDetailTaskListPanel />
      </CrewErrorBoundary>
    </div>
  )
})
