import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { memo } from 'react'
import { TaskDetailCommentInputForm } from './components/taskDetailCommentInputForm/taskDetailCommentInputForm'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { TaskDetailCommentListPageContext } from '../../useTaskDetailCommentListPanel'

export const TaskDetailCommentInput = memo(() => {
  const { t } = useTranslation()
  const [isOpenCommentInput, setIsOpenCommentInput] = useState(false)
  const { editItemId, setEditItemId } = useContext(
    TaskDetailCommentListPageContext
  )

  //focus to input open comment panel
  const handleOpenCommentInput = useCallback(() => {
    setIsOpenCommentInput(true)
    setEditItemId(null)
  }, [setEditItemId])

  //click button cancel close comment panel
  const handleCloseCommentInput = useCallback(() => {
    setIsOpenCommentInput(false)
  }, [])

  //close comment input when edit comment input open
  useEffect(() => {
    if (editItemId) {
      setIsOpenCommentInput(false)
    }
  }, [editItemId])

  return isOpenCommentInput ? (
    <TaskDetailCommentInputForm
      onClose={handleCloseCommentInput}
      isEditMode={false}
    />
  ) : (
    <CrewTextBox
      placeholder={`${t('label.comment')}...`}
      onFocusIn={handleOpenCommentInput}
    />
  )
})
