import {
  ColumnDef,
  TableOptions,
  getCoreRowModel,
  PaginationState,
  OnChangeFn,
} from '@tanstack/react-table'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { memo, useCallback, useMemo, useState } from 'react'
import { User } from '@crew/models/domain'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { useTranslation } from '@crew/modules/i18n'
import { useNavigate, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { camelCase } from 'lodash'

export type UserTableProps = {
  users: User[]
  totalCount: number
  pagination: PaginationState
}

export const UserTable = memo((props: UserTableProps) => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  const params = qs.parse(searchParams.toString())

  const pageCount = Math.ceil(
    (props.totalCount ?? 0) / props.pagination.pageSize
  )

  const [columnVisibility, setColumnVisibility] = useState({})

  // https://github.com/TanStack/table/discussions/3899
  // https://github.com/TanStack/table/discussions/3619
  // https://github.com/infonomic/remix.infonomic.io/blob/d3a7f628d3ad6e1e80cc80d4ac72db74da90e8d6/app/routes/admin%2B/users.tsx#L116
  // Func handle change pagination
  const handlePaginationChange: OnChangeFn<PaginationState> = useCallback(
    (updaterOrValue) => {
      let values: PaginationState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(props.pagination)
      } else {
        values = updaterOrValue
      }

      const newParams = {
        ...params,
        pageIndex: values.pageIndex,
        pageSize: values.pageSize,
      }

      navigate(`?${qs.stringify(newParams, { arrayFormat: 'repeat' })}`)
    },
    [navigate, props.pagination, params]
  )

  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        id: 'displayName',
        header: () => t('label.displayName'),
        cell: ({ row }) => (
          <div className="max-w-full">
            <CrewUserAvatar
              userId={row.original.id}
              displayName={row.original.displayName}
              size={CrewAvatarSize.xs}
              showLabel={true}
              labelColor="crew-link"
              cacheValue={row.original.id + row.original.version}
            />
          </div>
        ),
        size: 240,
        minSize: 50,
      },
      {
        id: 'role',
        header: () => t('label.role'),
        cell: ({ row }) => (
          <div className="truncate">
            {row.original.role.roleCode
              ? t(`label.roles.${camelCase(row.original.role.roleCode)}`)
              : row.original.role.name}
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'alias',
        header: () => t('label.alias'),
        cell: ({ row }) => <div className="truncate">{row.original.alias}</div>,
        size: 160,
        minSize: 50,
      },
      {
        id: 'searchKey',
        header: () => t('label.searchKey'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.searchKey}</div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'registrationDatetime',
        header: () => t('label.registrationDatetime'),
        cell: ({ row }) => (
          <div className="truncate">
            {t('format.timestamp', {
              value: row.original.createdAt,
              timeZone: defaultUserProfileRegion,
            })}
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'updateDatetime',
        header: () => t('label.updateDatetime'),
        cell: ({ row }) => (
          <div className="truncate">
            {t('format.timestamp', {
              value: row.original.updatedAt,
              timeZone: defaultUserProfileRegion,
            })}
          </div>
        ),
        size: 160,
        minSize: 50,
      },
    ],
    [defaultUserProfileRegion, t]
  )

  const tableOptions: TableOptions<User> = {
    data: props.users,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    pageCount,
    state: {
      pagination: props.pagination,
      columnVisibility,
    },
    onPaginationChange: handlePaginationChange,
    onColumnVisibilityChange: setColumnVisibility,
    manualPagination: true,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  return (
    <>
      {/* user list table */}
      <CrewTable tableOptions={tableOptions} />
    </>
  )
})
