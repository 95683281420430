import { TwoFactorAuthenticationType } from 'enums/app'
import { memo } from 'react'
import { TwoFactorMail } from './components/twoFactorMail/twoFactorMail'
import { TwoFactorSMS } from './components/twoFactorSMS/twoFactorSMS'
import { TwoFactorApp } from './components/twoFactorApp/twoFactorApp'

// 2FAの種類を表す値の型
export type TwoFactorAuthenticationTypeValue =
  (typeof TwoFactorAuthenticationType)[keyof typeof TwoFactorAuthenticationType]['value']

type TwoFactorContentProps = {
  selectedSecurityAuthenticationType: TwoFactorAuthenticationTypeValue
  emailAddress: string | undefined
  securityTwoFactorAuthDestination: string | undefined
  securityTwoFactorAuthCountryCode: string | undefined
  onSubmit: () => void
}

// Render two factor content by selectedSecurityAuthenticationType
export const TwoFactorContent = memo((props: TwoFactorContentProps) => {
  switch (props.selectedSecurityAuthenticationType) {
    case TwoFactorAuthenticationType.App.value:
      return <TwoFactorApp onSubmit={props.onSubmit} />
    case TwoFactorAuthenticationType.Email.value:
      return (
        <TwoFactorMail
          emailAddress={props.emailAddress}
          onSubmit={props.onSubmit}
        />
      )
    case TwoFactorAuthenticationType.Sms.value:
      return (
        <TwoFactorSMS
          onSubmit={props.onSubmit}
          securityTwoFactorAuthDestination={
            props.securityTwoFactorAuthDestination
          }
          securityTwoFactorAuthCountryCode={
            props.securityTwoFactorAuthCountryCode
          }
        />
      )
    default:
      return null
  }
})
