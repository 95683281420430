import { memo, useCallback } from 'react'
import { AccountSignupPage } from 'enums/app'
import { SignupError } from '../signupError/signupError'
import { SignupNewAccount } from './components/signupNewAccount/signupNewAccount'
import { SignupExistingAccount } from './components/signupExistingAccount/signupExistingAccount'
import { useGetUserPendingQuery } from '@crew/apis/dist/app/appApis'
import { GetUserPendingRequest } from '@crew/apis/dist/app/models/getUserPending/request'
import { DestinationType } from '@crew/enums/dist/domain'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const SignupTenantMember = memo(() => {
  const { invitationToken } = useParams()
  const [signupPage, setSignupPage] = useState<AccountSignupPage | null>(null)

  const getUserPendingParam: GetUserPendingRequest | undefined = invitationToken
    ? {
        invitationToken,
      }
    : undefined

  const {
    data: getUserPendingResult,
    isError,
    isLoading,
  } = useGetUserPendingQuery(getUserPendingParam ?? skipToken)

  useEffect(() => {
    if (!getUserPendingResult || isError) {
      setSignupPage(AccountSignupPage.SignupError)
    }

    const userPending = getUserPendingResult?.userPending

    // 招待タイプに応じて表示する画面を振り分ける
    if (userPending?.destinationType === DestinationType.LoginId) {
      //show sign up existing user page if the invitation is for existing account
      setSignupPage(AccountSignupPage.SignupExistingAccount)
    } else if (userPending?.destinationType === DestinationType.Email) {
      //show sign up new user page if the invitation is for new account
      setSignupPage(AccountSignupPage.SignupNewAccount)
    }
  }, [getUserPendingResult, isError])

  // Render the signup form based on the signup page
  const renderSignupForm = useCallback(() => {
    switch (signupPage) {
      case AccountSignupPage.SignupError:
        return <SignupError />
      case AccountSignupPage.SignupNewAccount:
        return (
          <SignupNewAccount
            userPending={getUserPendingResult?.userPending ?? null}
          />
        )
      case AccountSignupPage.SignupExistingAccount:
        return (
          <SignupExistingAccount
            userPending={getUserPendingResult?.userPending ?? null}
          />
        )
      default:
        return null
    }
  }, [getUserPendingResult?.userPending, signupPage])

  return !isLoading && renderSignupForm()
})
