import { FC, memo, useState } from 'react'
import { $getRoot, $insertNodes } from 'lexical'
import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

type Props = {
  defaultValue?: string
}

export const ATTRIBUTE_NAME_DEFAULT_VALUE = 'data-crew-flag-is-default-value'

/**
 * LexicalEditorに初期値を設定するプラグイン
 */
export const ImportDefaultValuePlugin: FC<Props> = memo((props) => {
  const [editor] = useLexicalComposerContext()

  const [prevValue, setPrevValue] = useState<string>('')

  // defaultValueが更新されたら、HTMLエディタの内容をその値で初期化する
  const defaultValue = props.defaultValue //null判定で型を絞り込むため一旦代入する
  if (defaultValue !== undefined && prevValue !== defaultValue) {
    setPrevValue(defaultValue)

    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(defaultValue, 'text/html')

      // 全ノードに初期値として設定された事を示すカスタム属性を付与する
      const allNodes = dom.querySelectorAll('*')
      allNodes.forEach((node) => {
        node.setAttribute(ATTRIBUTE_NAME_DEFAULT_VALUE, 'true')
      })

      const nodes = $generateNodesFromDOM(editor, dom)
      $getRoot().clear().select()
      $insertNodes(nodes)
    })
  }

  return null
})
