import { apis } from '../apiBase/apiBase';
const chatReadApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getChatRoomRead: builder.query({
            query: (params) => ({
                url: `chat-read/room/${params.chatRoomId}`,
                method: 'GET',
            }),
        }),
        getChatThreadRead: builder.query({
            query: (params) => ({
                url: `chat-read/thread/${params.threadRootMessageId}`,
                method: 'GET',
            }),
        }),
        getChatThreadUnreadCount: builder.query({
            query: (params) => ({
                url: `chat-read/thread/${params.threadRootMessageId}/unread-count`,
                method: 'GET',
            }),
        }),
        getChatThreadUnreadCounts: builder.query({
            query: () => ({
                url: `chat-read/threads/unread-count`,
                method: 'GET',
            }),
        }),
        updateChatRoomRead: builder.mutation({
            query: (params) => ({
                url: `chat-room-read/update`,
                method: `POST`,
                body: params,
            }),
        }),
        updateChatThreadRead: builder.mutation({
            query: (params) => ({
                url: `chat-thread-read/update`,
                method: `POST`,
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useGetChatRoomReadQuery, useGetChatThreadReadQuery, useGetChatThreadUnreadCountQuery, useLazyGetChatThreadUnreadCountQuery, useGetChatThreadUnreadCountsQuery, useLazyGetChatThreadUnreadCountsQuery, useUpdateChatRoomReadMutation, useUpdateChatThreadReadMutation, } = chatReadApis;
