import { FC, memo } from 'react'
import { MemberTable } from './components/memberTable/memberTable'
import { RowSelectionState, Updater } from '@tanstack/react-table'
import { useGetProjectSettingMembersQuery } from '@crew/apis/project/projectApis'
import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'states/hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { GetProjectSettingMembersRequest } from '@crew/apis/project/models/getProjectSettingMembers/request'
import { useParams, useSearchParams } from 'react-router-dom'
import { setSelectedUserIds } from 'features/project/components/projectDetailPage/states/projectDetailSlice'
import { ProjectMemberState } from 'enums/app'

type ProjectDetailMemberListPanelProps = {
  rowSelection: RowSelectionState
  setRowSelection: (rowSelection: Updater<RowSelectionState>) => void
}

export const ProjectDetailMemberListPanel: FC<ProjectDetailMemberListPanelProps> =
  memo((props) => {
    const dispatch = useAppDispatch()

    const { projectId } = useParams()

    const [searchParams] = useSearchParams()

    const projectSettingMemberEventMessage = useAppSelector(
      (state) => state.app.projectSettingMemberEventMessage
    )

    const projectMemberState = searchParams.get('state')
    const requestParams: GetProjectSettingMembersRequest | undefined = projectId
      ? {
          projectId: projectId,
          keyword: searchParams.get('keyword') || '',
          projectMemberState:
            projectMemberState &&
            projectMemberState !== ProjectMemberState.allStates.value
              ? projectMemberState
              : undefined,
        }
      : undefined

    // Call API get list project setting member
    const {
      data: getProjectSettingMembersResult,
      refetch: getProjectSettingMembersRefetch,
    } = useGetProjectSettingMembersQuery(requestParams ?? skipToken)

    // refresh project setting member list and clear list selected member
    useEffect(() => {
      getProjectSettingMembersRefetch()
      dispatch(setSelectedUserIds([]))
    }, [
      dispatch,
      getProjectSettingMembersRefetch,
      projectSettingMemberEventMessage,
    ])

    return (
      <div className="h-full flex flex-col">
        {projectId && (
          <MemberTable
            projectSettingMembers={
              getProjectSettingMembersResult?.projectSettingMembers ?? []
            }
            totalCount={getProjectSettingMembersResult?.totalCount || 0}
            projectId={projectId}
            rowSelection={props.rowSelection}
            setRowSelection={props.setRowSelection}
          />
        )}
      </div>
    )
  })
