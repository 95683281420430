import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {} from 'states/store'

import { RelatedItem } from '@crew/apis/feed/models/getRelatedItemByChatRoomId/response'

type SliceState = {
  toRelatedItem: RelatedItem | null
}

const initialSliceState: SliceState = {
  toRelatedItem: null,
}

export const homeSlice = createSlice({
  name: 'home',
  initialState: initialSliceState,
  reducers: {
    changeRelatedItem: (state, action: PayloadAction<RelatedItem>) => {
      const relatedItem = action.payload
      state.toRelatedItem = relatedItem
    },

    resetRelatedItem: (state) => {
      state.toRelatedItem = null
    },
  },
})

const { changeRelatedItem, resetRelatedItem } = homeSlice.actions

export { changeRelatedItem, resetRelatedItem }
