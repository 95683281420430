import { memo, useCallback, useRef, useState } from 'react'
import { CrewDataGrid } from 'components/devextreme/crewDataGrid'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineCreate from '~icons/ic/baseline-create'
import { Column, RowDragging } from 'devextreme-react/data-grid'
import { useProjectSettingTaskCategoryListPanel } from './useProjectSettingTaskCategoryListPanel'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { ProjectSettingTaskCategoryEntryDialog } from '../projectSettingTaskCategoryEntryDialog/projectSettingTaskCategoryEntryDialog'
import { useTranslation } from '@crew/modules/i18n'
import { DataGrid } from 'devextreme-react'
import { useToast } from 'hooks/useToast'
import { useModal } from 'components/layouts/modal/useModal'
import { ComponentCallbackHandler } from '@crew/utils'
import { useProjectSettingPageContext } from 'features/project/components/projectSettingPage/useProjectSettingPage'
import { TaskCategory } from './useProjectSettingTaskCategoryListPanel'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import dxDataGrid from 'devextreme/ui/data_grid'

export const ProjectSettingTaskCategoryListPanel = memo(() => {
  const {
    projectTaskCategoriesDataSource,
    deleteTaskCategory,
    reorderTaskCategory,
  } = useProjectSettingTaskCategoryListPanel()

  const { t } = useTranslation()
  const toast = useToast()
  const { projectSubject } = useProjectSettingPageContext()
  const [showApiErrors] = useShowApiErrors()

  const [
    isProjectSettingTaskCategoryEntryDialogOpen,
    openProjectSettingTaskCategoryEntryDialog,
    closeProjectSettingTaskCategoryEntryDialog,
  ] = useModal()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  // Task category selected for deletion
  const [taskCategorySelected, setTaskCategorySelected] =
    useState<TaskCategory>()

  const projectTaskCategoriesDataGridRef = useRef<DataGrid>(null)

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  //handle click task category on grid row set select task category id to state
  const handleTaskCategoryGridEditButtonClick = useCallback(
    (id: string, version: number) => {
      setTaskCategorySelected({
        taskCategoryId: id,
        version: version,
      })

      //open task category entry dialog
      openProjectSettingTaskCategoryEntryDialog()
    },
    [openProjectSettingTaskCategoryEntryDialog]
  )

  // Press Delete icon in grid
  const handleTaskCategoryGridDeleteButtonClick = useCallback(
    (id: string, version: number) => {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
      setTaskCategorySelected({
        taskCategoryId: id,
        version: version,
      })
    },
    [openConfirmDialog, t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    try {
      closeConfirmDialog()

      if (taskCategorySelected) {
        // Execute delete task category process
        await deleteTaskCategory(taskCategorySelected)

        toast.success(t('message.project.taskCategoryDeleted'))
      }
    } catch (err) {
      showApiErrors(err)
    }
  }, [
    closeConfirmDialog,
    taskCategorySelected,
    deleteTaskCategory,
    toast,
    t,
    showApiErrors,
  ])

  // Drag and drop to reorder records
  const handleReorder = useCallback<
    ComponentCallbackHandler<typeof RowDragging, 'onReorder'>
  >(
    (event) => {
      const reorderAsync = async (grid: dxDataGrid) => {
        const rows = grid.getVisibleRows()

        const fromId = rows[event.fromIndex].data.id
        const toId = rows[event.toIndex].data.id

        try {
          await reorderTaskCategory(fromId, toId)
        } catch (err) {
          setErrorMessage(t('message.general.errorMessage.reorder'))
          openErrorDialog()
        }
      }

      event.promise = reorderAsync(event.component as dxDataGrid) // componentの型がGridBase型と推論されるが、実際にはdxDataGrid型
    },
    [reorderTaskCategory, t, openErrorDialog]
  )

  // render name task category
  // renderとして使うのでmemo不可
  const NameCell = useCallback(
    (props: { value: string }) => (
      <span className=" truncate block">{props.value}</span>
    ),
    []
  )

  // renderとして使うのでmemo不可
  const RegisteredDateCell = useCallback(
    (props: { value: string }) => {
      return (
        <>
          {t('format.timestamp', {
            value: props.value,
            timeZone: defaultUserProfileRegion,
          })}
        </>
      )
    },
    [defaultUserProfileRegion, t]
  )

  return (
    <div className="h-full flex flex-col">
      <CrewDataGrid
        ref={projectTaskCategoriesDataGridRef}
        focusedRowEnabled={false}
        showBorders={false}
        showRowLines={true}
        showColumnLines={false}
        remoteOperations={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        height="100%"
        dataSource={projectTaskCategoriesDataSource}
        className="custom-table"
      >
        <RowDragging
          allowReordering={true}
          showDragIcons={true}
          dragDirection="vertical"
          onReorder={handleReorder}
        />
        <Column
          caption={t('label.name')}
          dataField="name"
          allowSorting={false}
          cssClass="w-4/12 w-min-0"
          cellRender={NameCell}
        />
        <Column
          caption={t('label.registeredDate')}
          dataField="createdAt"
          cellRender={RegisteredDateCell}
          minWidth={150}
          allowSorting={false}
        />
        <Column
          type="buttons"
          width={100}
          cellRender={(params) => {
            return (
              <div className="flex gap-2">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    handleTaskCategoryGridEditButtonClick(
                      params.data.id,
                      params.data.version
                    )
                  }
                >
                  <BaselineCreate width={24} height={24} />
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    handleTaskCategoryGridDeleteButtonClick(
                      params.data.id,
                      params.data.version
                    )
                  }
                >
                  <BaselineDelete width={24} height={24} />
                </span>
              </div>
            )
          }}
        />
      </CrewDataGrid>

      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />

      <ProjectSettingTaskCategoryEntryDialog
        isEditMode={true}
        title={t('label.editTaskCategory') + ' - ' + projectSubject}
        isOpen={isProjectSettingTaskCategoryEntryDialogOpen}
        onClose={closeProjectSettingTaskCategoryEntryDialog}
        taskCategoryId={
          taskCategorySelected ? taskCategorySelected.taskCategoryId : null
        }
        version={taskCategorySelected ? taskCategorySelected.version : null}
      />
    </div>
  )
})
