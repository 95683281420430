import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { TextBox } from 'devextreme-react'
import { setUserSearchKeyword } from 'features/chat/components/directChannelEntryDialog/states/directChannelEntryDialogSlice'
import { debounce } from 'lodash'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useAppDispatch } from 'states/hooks'

export const DirectChannelUserListToolbar = memo(() => {
  const dispatch = useAppDispatch()

  const [keyword, setKeyword] = useState('')

  const keywordTextBoxRef = useRef<TextBox>(null)

  // reducerのuserSearchKeywordを初期化
  dispatch(setUserSearchKeyword(''))

  // キーワード入力から500ms後に検索処理を実行
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        dispatch(setUserSearchKeyword(value))
      }, SEARCH_TIMEOUT_MSEC),
    [dispatch]
  )

  // キーワードテキストの入力時（keyUp）にdebouncedSearchを呼び出す
  const handleKeywordTextBoxInput = useCallback(() => {
    // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
    const input = keywordTextBoxRef.current?.instance.option('text')
    if (input !== undefined && input !== keyword) {
      setKeyword(input)
      debouncedSearch(input)
    }
  }, [debouncedSearch, keyword])

  return (
    <div className="flex justify-between items-center">
      <CrewTextBox
        className="max-w-xs"
        value={keyword}
        valueChangeEvent="input change"
        onInput={handleKeywordTextBoxInput}
        showClearButton={true}
        ref={keywordTextBoxRef}
        mode="search"
      />
    </div>
  )
})
