import {
  DestinationType,
  TenantMemberState,
  UserType,
} from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import {
  ColumnDef,
  RowSelectionState,
  TableOptions,
  Updater,
  getCoreRowModel,
} from '@tanstack/react-table'
import {
  CrewTable,
  SelectCheckbox,
} from 'components/elements/crewTable/crewTable'
import { FC, memo, useMemo } from 'react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useTenantUserTable } from './useTenantUserTable'
import { camelCase } from 'lodash'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import classNames from 'classnames'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import BaselineCreate from '~icons/ic/baseline-create'
import { EditTenantUserEntryDialog } from '../../../editTenantUserEntryDialog/editTenantUserEntryDialog'
import { useCallback, useState } from 'react'
import { useModal } from 'components/layouts/modal/useModal'
import { useToast } from 'hooks/useToast'
import { useSystemPermissions } from '@crew/hooks'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { RoleRef } from '@crew/models/refs'

// renderとして使うのでmemo不可
const MemberState = (state: TenantMemberState) => {
  const [t] = useTranslation()

  switch (state) {
    case TenantMemberState.Enabled:
      return t(`label.${TenantMemberState.Enabled}`)
    case TenantMemberState.Disabled:
      return t(`label.${TenantMemberState.Disabled}`)
    case TenantMemberState.Inviting:
      return t(`label.${TenantMemberState.Inviting}`)
    case TenantMemberState.WaitingApproval:
      return t(`label.${camelCase(TenantMemberState.WaitingApproval)}`)
    default:
      return
  }
}

// renderとして使うのでmemo不可
const UserByState: FC<{
  state: TenantMemberState
  userId: string
  displayName: string
  version: number
}> = (props) => {
  switch (props.state) {
    case TenantMemberState.Enabled:
      return (
        <CrewUserAvatar
          userId={props.userId}
          displayName={props.displayName}
          size={CrewAvatarSize.xs}
          showLabel={true}
          labelColor="crew-link"
          cacheValue={props.userId + props.version}
        />
      )
    case TenantMemberState.Disabled:
      return (
        <CrewUserAvatar
          userId={props.userId}
          displayName={props.displayName}
          size={CrewAvatarSize.xs}
          showLabel={true}
          labelColor="crew-text-gray-5"
          cacheValue={props.userId + props.version}
        />
      )
    default:
      return <></>
  }
}

const LoginIdByState: FC<{
  loginId: string | null
  displayName: string
  destinationType: string | null
  state: TenantMemberState
}> = ({ loginId, displayName, destinationType, state }) => {
  return (
    <div
      className={classNames('w-full whitespace-nowrap truncate', {
        'crew-text-gray-5': state === TenantMemberState.Disabled,
      })}
    >
      {state === TenantMemberState.Inviting
        ? destinationType === DestinationType.LoginId
          ? displayName
          : null
        : loginId}
    </div>
  )
}

const EmailByState: FC<{
  email: string | null
  displayName: string
  destinationType: string | null
  state: TenantMemberState
}> = ({ email, displayName, destinationType, state }) => {
  return (
    <div
      className={classNames('w-full whitespace-nowrap truncate', {
        'crew-text-gray-5': state === TenantMemberState.Disabled,
      })}
    >
      {state === TenantMemberState.Inviting
        ? destinationType === DestinationType.Email
          ? displayName
          : email
        : email}
    </div>
  )
}

type TenantSettingMember = {
  tenantMemberPendingId: string | null
  userId: string
  displayName: string
  roleId: string
  state: TenantMemberState
  enabled: boolean
  role: RoleRef
  userType: UserType | null
  expirationDatetime: string | null
  createdAt: string
  destinationType: string | null
  loginId: string | null
  email: string | null
  version: number
}

export type TenantUserTableProps = {
  tenantSettingMembers: TenantSettingMember[]
  rowSelection: RowSelectionState
  setRowSelection: (rowSelection: Updater<RowSelectionState>) => void
}

export const TenantUserTable = memo((props: TenantUserTableProps) => {
  const { deletePendingUser } = useTenantUserTable()

  const {
    hasSysExternalUserInvitePermission,
    hasSysInternalUserInvitePermission,
  } = useSystemPermissions()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  const { t } = useTranslation()
  const { success, error } = useToast()

  const [selectedMember, setSelectedMember] = useState<TenantSettingMember>()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
    right: ['action'],
  })

  // handles the event when the user clicks on a button to open a confirmation dialog for canceling a member.
  const handleCancelMemberButtonClick = useCallback(
    (member: TenantSettingMember) => {
      setSelectedMember(member)
      setConfirmMessage(t('message.general.confirmMessage.cancel'))
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  // handles the submission of canceling an invitation for a user.
  const handleSubmitCancelInvitationButtonClick = useCallback(async () => {
    closeConfirmDialog()
    if (selectedMember?.tenantMemberPendingId) {
      // Cancel tenant user inviting when ${tenantMemberPendingId} exist
      try {
        // Call Api cancel member
        await deletePendingUser(selectedMember.tenantMemberPendingId)

        // reset row selection
        const selection = Object.keys(props.rowSelection).reduce((obj, key) => {
          if (selectedMember.userId !== key) {
            obj[key] = props.rowSelection[key]
          }
          return obj
        }, {} as RowSelectionState)
        props.setRowSelection(selection)

        success(t('message.projectMemberPending.projectMemberDelete'))
      } catch (err) {
        error(t('message.general.failedToCancel'))
      }
    }
  }, [
    closeConfirmDialog,
    deletePendingUser,
    error,
    props,
    selectedMember?.tenantMemberPendingId,
    selectedMember?.userId,
    success,
    t,
  ])

  const [
    isEditTenantUserEntryDialogOpen,
    openEditTenantUserEntryDialog,
    closeEditTenantUserEntryDialog,
  ] = useModal()

  // Open dialog for edit member.
  const handleEditTenantUserButtonClick = useCallback(
    (member: TenantSettingMember) => {
      setSelectedMember(member)
      openEditTenantUserEntryDialog()
    },
    [openEditTenantUserEntryDialog]
  )

  const columns = useMemo<ColumnDef<TenantSettingMember>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        header: ({ table }) => (
          <SelectCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="flex flex-1 justify-center">
            <SelectCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        size: 80,
        minSize: 50,
        enableSorting: false,
        meta: {
          align: 'center',
        },
      },
      {
        id: 'user',
        header: () => t('label.displayName'),
        cell: ({ row }) => (
          <div className="truncate">
            <UserByState
              state={row.original.state}
              userId={row.original.userId}
              displayName={row.original.displayName}
              version={row.original.version}
            />
          </div>
        ),
        size: 200,
        minSize: 50,
      },
      {
        id: 'loginId',
        header: () => t('label.loginId'),
        cell: ({ row }) => (
          <LoginIdByState
            loginId={row.original.loginId}
            destinationType={row.original.destinationType}
            displayName={row.original.displayName}
            state={row.original.state}
          />
        ),
        size: 150,
        minSize: 50,
      },
      {
        id: 'email',
        header: () => t('label.mailAddress'),
        cell: ({ row }) => (
          <EmailByState
            email={row.original.email}
            destinationType={row.original.destinationType}
            displayName={row.original.displayName}
            state={row.original.state}
          />
        ),
        size: 240,
        minSize: 50,
      },
      {
        id: 'role',
        header: () => t('label.role'),
        cell: ({ row }) => (
          <div
            className={classNames('w-full whitespace-nowrap truncate', {
              'crew-text-gray-5':
                row.original.state === TenantMemberState.Disabled,
            })}
          >
            {row.original.role.roleCode
              ? t(`label.roles.${camelCase(row.original.role.roleCode)}`)
              : row.original.role.name}
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'memberState',
        header: () => t('label.memberState'),
        cell: ({ row }) => (
          <div className="w-full">
            <div
              className={classNames(
                'flex gap-x-2.5 items-center',
                row.original.state === TenantMemberState.Disabled &&
                  'crew-text-gray-5'
              )}
            >
              {MemberState(row.original.state)}
              {row.original.state === TenantMemberState.Inviting &&
                (hasSysExternalUserInvitePermission ||
                  hasSysInternalUserInvitePermission) && (
                  <CrewButton
                    type="normal"
                    stylingMode="outlined"
                    className="h-8"
                    onClick={() => handleCancelMemberButtonClick(row.original)}
                    render={() => (
                      <div className={'w-16 text-sm py-1'}>
                        {t('action.cancel')}
                      </div>
                    )}
                  />
                )}
            </div>
          </div>
        ),
        size: 240,
        minSize: 240,
      },
      {
        id: 'expirationDate',
        header: () => t('label.expirationDate'),
        cell: ({ row }) => (
          <div
            className={classNames('w-full whitespace-nowrap truncate', {
              'crew-text-gray-5':
                row.original.state === TenantMemberState.Disabled,
            })}
          >
            {row.original.expirationDatetime &&
              t('format.date', {
                value: row.original.expirationDatetime,
              })}
          </div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'registeredDate',
        header: () => t('label.registeredDate'),
        cell: ({ row }) => (
          <div
            className={classNames('w-full whitespace-nowrap truncate', {
              'crew-text-gray-5':
                row.original.state === TenantMemberState.Disabled,
            })}
          >
            {t('format.timestamp', {
              value: row.original.createdAt,
              timeZone: defaultUserProfileRegion,
            })}
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => (
          <div className="flex flex-1 justify-center">
            {row.original.state !== TenantMemberState.Inviting && (
              <CrewButton
                icon={<BaselineCreate width={20} height={20} />}
                tabIndex={-1}
                stylingMode="text"
                onClick={() => handleEditTenantUserButtonClick(row.original)}
                size="sm"
              />
            )}
          </div>
        ),
        size: 120,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [
      t,
      hasSysExternalUserInvitePermission,
      hasSysInternalUserInvitePermission,
      handleCancelMemberButtonClick,
      defaultUserProfileRegion,
      handleEditTenantUserButtonClick,
    ]
  )

  const tableOptions: TableOptions<TenantSettingMember> = {
    data: props.tenantSettingMembers,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnPinning,
      rowSelection: props.rowSelection,
    },
    getRowId: (row) => row.tenantMemberPendingId ?? row.userId,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: props.setRowSelection,
    enableRowSelection: true,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  return (
    <>
      {/* Tenant user list table */}
      <CrewTable
        tableOptions={tableOptions}
        showPager={false}
        showColumnSelector={false}
      />

      {/* Canceling an invitation confirm dialog */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleSubmitCancelInvitationButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />

      {selectedMember && (
        <EditTenantUserEntryDialog
          title={t('label.editUser')}
          isOpen={isEditTenantUserEntryDialogOpen}
          onClose={closeEditTenantUserEntryDialog}
          userId={selectedMember.userId}
          version={selectedMember.version}
          displayName={selectedMember.displayName}
          roleId={selectedMember.roleId}
          enabled={selectedMember.enabled}
          userType={selectedMember.userType}
          expirationDatetime={selectedMember.expirationDatetime}
        />
      )}
    </>
  )
})
