import { memo, FC } from 'react'
import { Provider } from 'react-redux'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from 'react-router-dom'

import { store } from '../../states/store'

import { AppLoginPage } from 'features/app/components/appLoginPage/appLoginPage'
import { AppRequireAuthRouteElement } from 'features/app/components/appRequireAuthRouteElement/appRequireAuthRouteElement'
import { AppRedirectRouteHandler } from 'features/app/components/appRedirectRouteHandler/appRedirectRouteHandler'
import { useApp } from 'features/app/useApp'
import { ChimeProvider } from 'components/chime/chimeProvider'
import { ModalProvider } from 'components/layouts/modal/modalProvider'
import { WebsocketProvider } from '@crew/providers/websocket'
import { ProjectListPage } from 'features/project/components/projectListPage/projectListPage'
import { ProjectDetailPage } from 'features/project/components/projectDetailPage/projectDetailPage'
import { EventDetailPage } from 'features/project/components/eventDetailPage/eventDetailPage'
import { TaskListPage } from 'features/task/components/taskListPage/taskListPage'
import { TaskDetailPage } from 'features/task/components/taskDetailPage/taskDetailPage'
import { FileListPage } from 'features/file/components/fileListPage/fileListPage'
import { FileDetailPage } from 'features/file/components/fileDetailPage/fileDetailPage'
import { AppContainer } from './components/appContainer/appContainer'
// TODO: ダッシュボードが実装される際にコメントアウトを解除
// import { DashboardPanel } from 'features/home/components/homePage/components/homeCenterPanel/components/dashboardPanel/dashboardPanel'
import { HomeMenuItems } from 'enums/app'
import { AppAccountRegistration } from './components/appSignup/components/appAccountRegistration/appAccountRegistration'
import { AppVerifyEmail } from './components/appSignup/components/appVerifyEmail/appVerifyEmail'
import { CrewEmojiPickerProvider } from 'components/elements/crewEmojiPicker/crewEmojiPickerProvider'
import { TenantSettingPage } from 'features/tenant/components/tenantSettingPage/tenantSettingPage'
import { AppSignup } from './components/appSignup/appSignup'
import { AppExistEmail } from './components/appSignup/components/appExistEmail/appExistEmail'
import { AccountSignup } from 'features/accountSignup/accountSignup'
import { SignupType } from '@crew/enums/dist/domain'
import { FeedPage } from 'features/home/components/feedPage/feedPage'
import { BookmarkPage } from 'features/home/components/bookmarkPage/bookmarkPage'
import { MyTaskPage } from 'features/home/components/myTaskPage/myTaskPage'
import { PersonalSettingPage } from 'features/personal/components/personalSettingPage/personalSettingPage'
import { UserDetailPage } from 'features/user/components/userDetailPage/userDetailPage'
import { UserListPage } from 'features/user/components/userListPage/userListPage'
import { ProjectSettingPage } from 'features/project/components/projectSettingPage/projectSettingPage'
import { AppResetPasswordPage } from './components/appResetPasswordPage/appResetPasswordPage'
import { ContractPage } from 'features/contract/components/contractPage'
import { AppForgotPasswordPage } from './components/appForgotPasswordPage/appForgotPasswordPage'
import { AppPlanSelectPage } from './components/appSignup/components/appPlanSelectPage/appPlanSelectPage'
import { AppLoginVerifyCodePage } from './components/appLoginVerifyCodePage/appLoginVerifyCodePage'
import { SearchPage } from 'features/search/components/searchPage/searchPage'
import { AppSsoFreshDesk } from './components/appSsoFreshDesk/appSsoFreshDesk'
import { AttentionPage } from 'features/home/components/attentionPage/attentionPage'
import { WorkingTimePage } from 'features/home/components/workingTimePage/workingTimePage'
import { SchedulePage } from 'features/home/components/schedulePage/schedulePage'
import { DirectMessagePage } from 'features/directMessage/components/directMessagePage/directMessagePage'
import { EventListPage } from 'features/event/eventListPage/eventListPage'

export const App: FC = memo(() => {
  useApp()
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="login" element={<AppLoginPage />} />
        <Route
          path="login-verify-code/:token"
          element={<AppLoginVerifyCodePage />}
        />
        <Route path="forgot-password" element={<AppForgotPasswordPage />} />
        <Route
          path="reset-password/:token"
          element={<AppResetPasswordPage />}
        />
        {/* TODO: 社内説明会向けにサインアップURLを秘匿する。正式リリース時どうするかは別途対応
                  https://break-tmc.atlassian.net/browse/CREW-8538
         */}
        <Route path="plan-select" element={<AppPlanSelectPage />} />
        <Route path="signup" element={<AppAccountRegistration />} />
        <Route path="verify_email" element={<AppVerifyEmail />} />
        <Route path="exist_email" element={<AppExistEmail />} />
        <Route path="signup/register/:token" element={<AppSignup />} />
        <Route
          path="projects/invitation/:invitationToken"
          element={
            <AccountSignup signupType={SignupType.ProjectMemberPendings} />
          }
        />

        <Route
          path="invitation/:invitationToken"
          element={
            <AccountSignup signupType={SignupType.TenantMemberPendings} />
          }
        />
        <Route path="sso/freshdesk" element={<AppSsoFreshDesk />} />

        <Route element={<AppRequireAuthRouteElement />}>
          <Route element={<AppContainer />}>
            {/* 
              TODO: 以下、home配下のルーティングが直接書かれているが、以下で方針決めした後のリファクタリングで整理する予定
              https://break-tmc.atlassian.net/browse/CREW-7080
            */}
            {/* ルート("/")へアクセスがあった際のリダイレクトのため、ここはNavigateによるデフォルトルートを残しておく */}
            <Route
              index
              element={
                <Navigate
                  to={HomeMenuItems.MentionAndReply.path}
                  replace={true}
                />
              }
            />
            {/* TODO: ダッシュボードが実装される際にコメントアウトを解除 */}
            {/* <Route
                    path={HomeMenuItems.Dashboard.path}
                    element={<DashboardPanel />}
                  /> */}
            <Route
              path={`${HomeMenuItems.MentionAndReply.path}/*`}
              element={<AttentionPage />}
            />
            <Route
              path={HomeMenuItems.NewArrival.path}
              element={<FeedPage />}
            />
            <Route
              path={HomeMenuItems.Bookmark.path}
              element={<BookmarkPage />}
            />
            {/* ルート("/")へアクセスがあった際のリダイレクトのため、ここはNavigateによるデフォルトルートを残しておく */}
            <Route
              index
              element={
                <Navigate to={HomeMenuItems.Schedule.path} replace={true} />
              }
            />
            <Route
              path={`${HomeMenuItems.Schedule.path}/*`}
              element={<SchedulePage />}
            />
            <Route path={HomeMenuItems.MyTask.path} element={<MyTaskPage />} />
            <Route
              index
              element={
                <Navigate to={HomeMenuItems.WorkingTime.path} replace={true} />
              }
            />
            <Route
              path={`${HomeMenuItems.WorkingTime.path}/*`}
              element={<WorkingTimePage />}
            />

            <Route path="projects/*">
              <Route index element={<ProjectListPage />} />
              <Route path=":projectId/*" element={<ProjectDetailPage />} />
              <Route
                path=":projectId/settings/*"
                element={<ProjectSettingPage />}
              />
              <Route
                path=":projectId/events/:eventId/*"
                element={
                  <ChimeProvider>
                    <EventDetailPage />
                  </ChimeProvider>
                }
              />
            </Route>
            <Route path="events" element={<EventListPage />} />

            <Route path="tasks/*">
              <Route index element={<TaskListPage />} />
              <Route path=":taskId/*" element={<TaskDetailPage />} />
            </Route>
            <Route path="files/*">
              <Route index element={<FileListPage />} />
              <Route path=":fileId/*" element={<FileDetailPage />} />
            </Route>
            <Route path="redirect" element={<AppRedirectRouteHandler />} />
            <Route path="tenant-settings/*" element={<TenantSettingPage />} />
            <Route path="users/*">
              <Route index element={<UserListPage />} />
              <Route path=":userId/*" element={<UserDetailPage />} />
            </Route>
            <Route
              path="personal-settings/*"
              element={<PersonalSettingPage />}
            />
            <Route path="contract/*" element={<ContractPage />} />
            <Route path="search/*">
              <Route path="*" element={<SearchPage />} />
            </Route>
            <Route path="direct-channels/*">
              <Route path=":projectId/*" element={<DirectMessagePage />} />
            </Route>
          </Route>
        </Route>
      </Route>
    )
  )

  return (
    <Provider store={store}>
      <WebsocketProvider>
        <CrewEmojiPickerProvider>
          <ModalProvider>
            <RouterProvider router={router} />
          </ModalProvider>
        </CrewEmojiPickerProvider>
      </WebsocketProvider>
    </Provider>
  )
})
