import { t } from '@crew/modules/i18n'
import { ProjectProgressItems } from 'enums/app'
import { FC, memo, useMemo } from 'react'

export type CrewProgressBarProps = {
  [key: string]: number
}

export const CrewProgressBar: FC<CrewProgressBarProps> = memo((props) => {
  // get total value progress
  const totalValue = Object.values(props).reduce((accumulator, value) => {
    return accumulator + value
  }, 0)

  // map progress value from enum to percentage
  const progressItems = useMemo(() => {
    const result = Object.keys(ProjectProgressItems).map((key, index) => {
      const item = Object.values(ProjectProgressItems)[index]
      const percentage =
        Object.hasOwn(props, item.text) && (props[item.text] / totalValue) * 100
      return {
        ...item,
        percentage,
        value: props[item.text],
      }
    })
    return result
  }, [props, totalValue])

  return (
    // hiden progress bar when total value equals 0
    totalValue === 0 ? (
      <></>
    ) : (
      // show progress bar when value other than 0
      <div className="flex rounded-md overflow-hidden">
        {progressItems.map((item) => {
          // hide progress item when value equals 0
          if (item.value === 0) {
            return <></>
          } else {
            // show progress item when value other than 0
            return (
              <div
                title={t('label.' + item.text)}
                className={`text-crew-gray-1-light dark:text-crew-gray-1-dark text-sm text-center p-0.5
                             ${item.displayColor}`}
                key={item.text}
                style={{
                  width: item.percentage + '%',
                }}
              >
                {item.value}
              </div>
            )
          }
        })}
      </div>
    )
  )
})
