import { useCallback } from 'react'
import {
  useDeleteTaskStateMutation,
  useReorderTaskStateMutation,
} from '@crew/apis/task/taskApis'
import { ReorderTaskStateRequest } from '@crew/apis/task/models/reorderTaskState/request'
import { NotifyEventType } from 'enums/app'
import { useAppDispatch } from 'states/hooks'
import { TaskState } from '@crew/models/domain'
import {
  notifyTaskStateEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'

export type SelectedTaskState = {
  taskStateId: string
  version: number
}

export const useTaskStateListGrid = () => {
  const dispatch = useAppDispatch()

  // Get functions for update task state information
  const [reorderTaskStateMutation] = useReorderTaskStateMutation()
  const [deleteTaskStateMutation] = useDeleteTaskStateMutation()

  // Delete task state process
  const deleteTaskState = useCallback(
    async (taskStateId: string, version: number) => {
      await deleteTaskStateMutation({
        taskStateId,
        version,
      }).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TaskState> = {
        eventType: NotifyEventType.Deleted,
        id: taskStateId,
        object: undefined,
      }
      dispatch(notifyTaskStateEvent(objectEventMessage))
    },
    [deleteTaskStateMutation, dispatch]
  )

  // Reorder task state process
  const reorderTaskState = useCallback(
    async (taskStateId: string, targetPositionTaskStateId: string) => {
      const request: ReorderTaskStateRequest = {
        taskStateId,
        targetPositionTaskStateId,
      }
      await reorderTaskStateMutation(request).unwrap()
    },
    [reorderTaskStateMutation]
  )

  return {
    deleteTaskState,
    reorderTaskState,
  }
}
