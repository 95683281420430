import { memo } from 'react'
// TODO: 一時的に無効化 CREW-9236 で再度有効化する
// https://break-tmc.atlassian.net/browse/CREW-9236
// import { useCrewReCaptcha } from './useCrewReCaptcha'

export const CrewReCaptcha = memo(() => {
  // TODO: 一時的に無効化 CREW-9236 で再度有効化する
  // https://break-tmc.atlassian.net/browse/CREW-9236
  // useCrewReCaptcha()

  return (
    <div
      className="g-recaptcha"
      data-callback="handleReCaptchaV2Callback"
      data-expired-callback="handleReCaptchaV2ExpiredCallback"
      data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
    />
  )
})
