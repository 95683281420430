import { FC, SVGProps, memo, useMemo } from 'react'
import classNames from 'classnames'
import { To, useMatch, useResolvedPath } from 'react-router-dom'

/**
 * タブコンポーネントのプロパティ
 *  to：
 *    NestedRouteのように、URLを持ち画面遷移を伴う場合に指定する
 */
/** バッジの色 */
export type BadgeColor = 'Blue' | 'Orange' | 'Red'

export type CrewNavTabProps = {
  text: string
  to: To
  onClick: () => void
  icon?: (props: SVGProps<SVGSVGElement>) => React.ReactElement
  // バッジを非表示にしたい場合はbadgeCountをundefinedにする
  badgeCount?: number
  badgeColor?: BadgeColor
}

/**
 * タブコンポーネント
 */
export const CrewNavTab: FC<CrewNavTabProps> = memo((props) => {
  const resolved = useResolvedPath(props.to)
  const match = useMatch({ path: resolved.pathname, end: false })

  // 選択されたタブが自身を示すかどうかを返す
  const isSelectedTab = useMemo(() => {
    return match !== null
  }, [match])

  const selectedTabClassNames = useMemo(() => {
    return classNames(
      'flex flex-row gap-1 items-center h-full p-2 cursor-pointer',
      'font-bold rounded-t-md border-b-2 text-crew-gray-900 dark:text-crew-gray-50 crew-bg-default border-b-crew-cyan-600 dark:border-b-crew-cyan-600'
    )
  }, [])

  // 最低幅として、1文字分確保できるようにする
  const badgeBaseClassNames = useMemo(() => {
    return classNames(
      'crew-badge text-sm truncate justify-center min-w-[1.5rem] h-5 leading-3 !font-normal has-unread'
    )
  }, [])

  const selectedBadgeClassNames = useMemo(() => {
    return classNames(
      badgeBaseClassNames,
      props.badgeColor === 'Red'
        ? 'crew-badge-red-inverted'
        : props.badgeColor === 'Orange'
        ? 'crew-badge-orange-inverted'
        : props.badgeColor === 'Blue'
        ? 'crew-badge-blue-inverted'
        : ''
    )
  }, [badgeBaseClassNames, props.badgeColor])

  const hiddenBadge = props.badgeCount === undefined || props.badgeCount <= 0

  const SvgIcon = props.icon

  return isSelectedTab ? (
    <div className={selectedTabClassNames}>
      {SvgIcon && <SvgIcon width={24} height={24} />}
      <span className="font-bold whitespace-nowrap">{props.text}</span>
      {/* 選択状態のバッジ */}
      {!hiddenBadge && (
        <span
          className={classNames(badgeBaseClassNames, selectedBadgeClassNames)}
        >
          {props.badgeCount}
        </span>
      )}
    </div>
  ) : (
    <div
      className="flex flex-row gap-1 items-center h-full p-2 crew-text-gray-4 cursor-pointer hover:font-bold"
      onClick={props.onClick}
    >
      {SvgIcon && <SvgIcon width={24} height={24} />}
      <span className="whitespace-nowrap">{props.text}</span>
      {/* 未選択状態のバッジ */}
      {!hiddenBadge && (
        <span
          className={classNames(
            badgeBaseClassNames,
            'crew-badge-gray-inverted'
          )}
        >
          {props.badgeCount}
        </span>
      )}
    </div>
  )
})
