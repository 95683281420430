import { ChatView, MentionType } from '@crew/enums/app'
import { ChatMessage } from '@crew/models/domain'

import { mention as mentionConst } from '@crew/configs/constants'

import { getAttributesFromHtml } from './html'

/**
 * Get all mention in message
 * @date 5/19/2023 - 8:56:31 AM
 *
 * @param {ChatMessage} message
 * @returns {string[]}
 */
export const getAllMentionInMessage = (message: ChatMessage): string[] => {
  const mentionTargetIds = getAttributesFromHtml(
    message.text,
    `${mentionConst.TAG_NAME.toLowerCase()}.${mentionConst.CLASS_NAME}`,
    mentionConst.MENTION_ID_DATA_ATTRIBUTE
  ).sort() //並び順を固定す
  return mentionTargetIds ?? []
}

// add mention style to message
export const addMentionStyleByMentionTarget = (
  message: string,
  loggedInUserId: string
) => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(message, 'text/html')
  // mentionクラスが適用されているノード一覧を取得
  const elements = dom.querySelectorAll(`.${mentionConst.CLASS_NAME}`)
  elements.forEach((element) => {
    const mentionTarget = element.getAttribute(
      mentionConst.MENTION_ID_DATA_ATTRIBUTE
    )

    if (mentionTarget) {
      //remove all mention style
      element.classList.remove(
        mentionConst.STYLE_MENTION_ME_CLASS_NAME,
        mentionConst.STYLE_MENTION_ALL_HERE_CLASS_NAME,
        mentionConst.STYLE_MENTION_OTHER_CLASS_NAME
      )

      //mention me
      if (mentionTarget === loggedInUserId) {
        element.classList.add(mentionConst.STYLE_MENTION_ME_CLASS_NAME)
      } else if (
        //mention all or here
        mentionTarget === MentionType.All.id ||
        mentionTarget === MentionType.Here.id
      ) {
        element.classList.add(mentionConst.STYLE_MENTION_ALL_HERE_CLASS_NAME)
      } else {
        //mention other
        element.classList.add(mentionConst.STYLE_MENTION_OTHER_CLASS_NAME)
      }
    }
  })

  return dom.body.innerHTML
}

// preタグをcodeタグに置き換えつつ、styleを付与する
export const addCodeStyleByCodeTarget = (message: string): string => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(message, 'text/html')

  // dom内のpreタグを取得し、codeタグに置き換え
  const preElements = dom.querySelectorAll('pre')
  preElements.forEach((element) => {
    const codeElement = document.createElement('code')
    // codeタグにCrewEditorCode__codeクラスを付与
    codeElement.classList.add('CrewEditorTheme__code')
    // テキスト部分をcodeタグに移動
    codeElement.innerHTML = element.innerHTML
    // preタグの属性をcodeタグに移動
    console.log(element.attributes)
    Array.from(element.attributes).forEach((attr) => {
      codeElement.setAttribute(attr.name, attr.value)
    })
    // 置き換え
    element.replaceWith(codeElement)

    // codeタグ内のbrタグの数を保存する
    const brElements = codeElement.querySelectorAll('br').length

    // codeタグにdata-gutter属性を付与する
    // data-gutterはコードブロック内の行数を表示するためのもの
    // brタグの数 + 1が行数になるので、1始めで文字列を出力する
    let gutter: string = ''

    for (let i = 0; i <= brElements; i++) {
      gutter += `${i + 1}\n`
    }

    codeElement.setAttribute('data-gutter', gutter)
  })

  return dom.body.innerHTML
}

/**
 * 引数のチャットメッセージIDとチャットビューからDOMに指定するIDを返す関数
 * @param chatMessageId チャットメッセージID
 * @param chatView 表示しているビュー
 * @returns DOMに指定するID
 */
export const getChatMessageListItemDomId = (
  chatMessageId: string,
  chatView: ChatView
) => {
  return chatView + '-' + chatMessageId
}
