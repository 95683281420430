import { FC, memo, useMemo } from 'react'
import { FavoriteProjectItem } from './components/favoriteProjectItem/favoriteProjectItem'
import { useAppSelector } from 'states/hooks'
import {
  ActiveChatRoomInfo,
  selectAlreadyReadFavoriteProjectActiveChatRooms,
} from '@crew/states'
import { itemComparer } from '@crew/utils'

// アコーディオン内に表示されるアイテム表示
export const FavoriteProjectList: FC = memo(() => {
  // 未読のないお気に入りプロジェクトのアクティブチャットルーム
  const alreadyReadProjectChatRooms = useAppSelector(
    selectAlreadyReadFavoriteProjectActiveChatRooms
  )
  // 表示名の昇順でソートする
  const sortedAlreadyReadProjectChatRooms = useMemo(() => {
    return [...alreadyReadProjectChatRooms].sort(
      itemComparer<ActiveChatRoomInfo>('asc', 'name')
    )
  }, [alreadyReadProjectChatRooms])

  return (
    <ul className="flex-1 mb-2">
      {sortedAlreadyReadProjectChatRooms.map((chatRoom) => (
        <FavoriteProjectItem key={chatRoom.chatRoomId} chatRoom={chatRoom} />
      ))}
    </ul>
  )
})
