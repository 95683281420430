import { FC, memo, useEffect, useRef } from 'react'

import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'

import { VideoTile } from 'components/chime/components/videoTile'

type Props = BaseSdkProps & {
  /**
   * Video tileのelement id
   */
  id?: string
  /**
   * このcomponentにbindするtileのid
   */
  tileId: number
  /**
   * 表示する名前
   */
  name?: string
  /**
   * Id member attendee
   */
  chimeAttendeeId?: string
}

/**
 * リモートビデオを表示するビデオタイル
 */
export const CrewRemoteVideoTile: FC<Props> = memo(
  ({ tileId, id, chimeAttendeeId, ...rest }) => {
    const audioVideo = useAudioVideo()
    const videoElementRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
      if (!audioVideo || !videoElementRef.current) {
        return
      }

      audioVideo.bindVideoElement(tileId, videoElementRef.current)

      return () => {
        const tile = audioVideo.getVideoTile(tileId)
        if (tile) {
          audioVideo.unbindVideoElement(tileId)
        }
      }
    }, [audioVideo, tileId])
    return (
      <VideoTile
        objectFit="cover" // ボックスサイズに合わせて縦横比を維持しながらリサイズして、トリミングしてはめ込む
        ref={videoElementRef}
        {...rest}
        chimeAttendeeId={chimeAttendeeId}
      />
    )
  }
)
