import {
  useLazyGetMemberQuery,
  useLazyGetMembersQuery,
} from '@crew/apis/lookup/lookupApis'
import { MAX_INITIAL_RECORDS } from 'configs/constants'
import { EntityType } from '@crew/enums/domain'
import { SelfKey, SelfName } from 'utils/filter'
import { useTranslation } from '@crew/modules/i18n'
import { useLookupDataSource } from './useLookupDataSource'
export const useLookupMemberDataSource = (
  entityType: EntityType | null | undefined,
  entityRecordId: string | null | undefined,
  isEnabledUser?: boolean
) => {
  const [lazyGetMembersQuery] = useLazyGetMembersQuery()
  const [lazyGetMemberQuery] = useLazyGetMemberQuery()
  const { t } = useTranslation()

  return useLookupDataSource(
    () => ({
      key: 'id',
      loadMode: 'raw',
      load: async (loadOptions) => {
        if (!entityType || !entityRecordId) {
          return []
        }

        if (!loadOptions.searchValue) {
          const response = await lazyGetMembersQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            keyword: undefined,
            limit: MAX_INITIAL_RECORDS,
            isEnabledUser,
          }).unwrap()
          return response.projectMembers
        }

        const response = await lazyGetMembersQuery({
          entityType: entityType,
          entityRecordId: entityRecordId,
          keyword: loadOptions.searchValue,
          isEnabledUser,
        }).unwrap()
        return response.projectMembers
      },
      byKey: async (userId) => {
        if (!entityType || !entityRecordId || !userId) {
          return null
        }
        if (userId === SelfKey) return { id: SelfKey, displayName: t(SelfName) }
        const response = await lazyGetMemberQuery({
          entityType: entityType,
          entityRecordId: entityRecordId,
          userId: userId,
          isEnabledUser,
        }).unwrap()

        return response.projectMember
      },
    }),
    [entityRecordId, entityType, lazyGetMemberQuery, lazyGetMembersQuery, t]
  )
}
