import { UnreadInfoTarget } from '@crew/enums/domain';
export const IsUpdateUnreadInfo = (v) => {
    if (!Array.isArray(v))
        return false;
    const m = v;
    // TODO:厳密化する
    // https://break-tmc.atlassian.net/browse/CREW-4249
    for (const elem of m) {
        if (typeof elem.targetType !== 'string')
            return false;
        if (elem.targetType !== UnreadInfoTarget.ChatRoom &&
            elem.targetType !== UnreadInfoTarget.ChatThread)
            return false;
        if (elem.targetType === UnreadInfoTarget.ChatThread) {
            if (typeof elem.entityRecordId !== 'string')
                return false;
            if (typeof elem.unreadCount !== 'object')
                return false;
        }
        else if (elem.targetType === UnreadInfoTarget.ChatRoom) {
            if (typeof elem.chatRoomId !== 'string')
                return false;
            if (typeof elem.referenceEntityType !== 'string')
                return false;
            if (typeof elem.referenceEntityRecordId !== 'string')
                return false;
            if (typeof elem.unreadCount !== 'object')
                return false;
        }
        else {
            return false;
        }
    }
    return true;
};
