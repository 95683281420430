import { FC, LabelHTMLAttributes, memo } from 'react'
import classNames from 'classnames'

type Props = LabelHTMLAttributes<HTMLLabelElement> & {
  text: string
}

// 入力コンポーネント付近に表示する、説明文用ラベル
export const CrewFieldDescriptionLabel: FC<Props> = memo(
  ({ className, text, ...rest }) => {
    return (
      <label
        className={classNames('crew-text-gray-4 text-sm', className)}
        {...rest}
      >
        {text}
      </label>
    )
  }
)
