import { FC, memo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { WebMeetingSettingItems } from 'features/webMeeting/components/webMeetingSettingItems/webMeetingSettingItems'
import { CrewButton } from 'components/elements/crewButton/crewButton'

export type WebMeetingSettingFormProps = {
  onClose: () => void
}

export const WebMeetingSettingForm: FC<WebMeetingSettingFormProps> = memo(
  (props) => {
    const { t } = useTranslation()
    return (
      <div className="flex flex-col gap-y-5 h-full">
        <WebMeetingSettingItems />
        <div className="flex justify-between items-center">
          <div className="ml-auto flex gap-x-5">
            <CrewButton
              text={t('action.close')}
              type="normal"
              stylingMode="outlined"
              onClick={props.onClose}
            />
          </div>
        </div>
      </div>
    )
  }
)
