import { FC, memo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { TimePeriod } from 'enums/app'
import { WorkingTimeMonth } from './components/workingTimeMonth/workingTimeMonth'
import { WorkingTimeWeek } from './components/workingTimeWeek/workingTimeWeek'
import { WorkingTimeToolbar } from './components/workingTimeToolbar/workingTimeToolbar'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

export const WorkingTimePage: FC = memo(() => {
  return (
    <div className="flex flex-col p-2.5 gap-2.5 h-full">
      <CrewErrorBoundary>
        <WorkingTimeToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        {/* URLに応じて表示するコンポーネントを切り替える */}
        <Routes>
          {/** A route to transition to "working time week" when accessing the root (/) */}
          <Route
            index
            element={<Navigate to={TimePeriod.Weekly.value} replace={true} />}
          />
          <Route path={TimePeriod.Weekly.value} element={<WorkingTimeWeek />} />
          <Route
            path={TimePeriod.Monthly.value}
            element={<WorkingTimeMonth />}
          />
        </Routes>
      </CrewErrorBoundary>
    </div>
  )
})
