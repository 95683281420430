import { initializeApp } from 'firebase/app'

import { firebaseConfig } from '../../firebase.js'

// firebaseのインスタンス作成
const initializeFirebase = () => {
  if (
    firebaseConfig.apiKey &&
    firebaseConfig.authDomain &&
    firebaseConfig.projectId &&
    firebaseConfig.storageBucket &&
    firebaseConfig.messagingSenderId &&
    firebaseConfig.appId
  ) {
    return initializeApp(firebaseConfig)
  } else {
    console.error(
      '[firebase] Environment variables for push notification must be set.'
    )
  }
}

export const firebaseInstance = initializeFirebase()
