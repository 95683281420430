import { memo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { TaskStateEntryDialog } from '../taskStateEntryDialog/taskStateEntryDialog'

export const TaskStateListToolbar = memo(() => {
  const { t } = useTranslation()

  const [
    isTaskStateEntryDialogOpen,
    openTaskStateEntryDialog,
    closeTaskStateEntryDialog,
  ] = useModal()

  return (
    <>
      <div className="w-full flex">
        <div className="flex flex-1">
          <CrewButton
            type="primary"
            className="ml-auto"
            text={t('action.newTaskState')}
            onClick={openTaskStateEntryDialog}
          />
        </div>
      </div>

      <TaskStateEntryDialog
        isEditMode={false}
        title={t('label.taskStateAdd')}
        isOpen={isTaskStateEntryDialogOpen}
        onClose={closeTaskStateEntryDialog}
      />
    </>
  )
})
