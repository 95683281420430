import { Task } from '@crew/apis/task/models/getTasks/response'
import {
  useDeleteTaskDependencyMutation,
  useInsertTaskDependenciesMutation,
  useLazyGetSuggestionTaskDependenciesQuery,
} from '@crew/apis/task/taskApis'
import { TaskDependencyTypes } from '@crew/enums/app'
import {
  TaskDependenciesInsertionType,
  TaskDependencyType,
} from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTaskDependencyEvent,
} from 'features/app/states/appSlice'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useTaskDependencyTypesDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'states/hooks'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'

export type FormValues = {
  predecessorTaskIds: string[]
  dependencyType: TaskDependencyType
}

export const useTaskDetailDependencyPredecessorTask = () => {
  const { t } = useTranslation()
  const { taskId } = useParams()
  const dispatch = useAppDispatch()
  // react-hook-formの各種データを取得
  const { handleSubmit, formState, control, setError, reset } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: {
        dependencyType: TaskDependencyTypes.FinishToStart.key,
      },
    })

  const [lazyGetSuggestionTaskDependenciesQuery] =
    useLazyGetSuggestionTaskDependenciesQuery()

  const [insertTaskDependenciesMutation] = useInsertTaskDependenciesMutation()
  const [deleteTaskDependencyMutation] = useDeleteTaskDependencyMutation()

  // タスク依存関係の追加
  const insertTaskDependency = useCallback(
    async (data: FormValues) => {
      if (!taskId) {
        return
      }

      await insertTaskDependenciesMutation({
        insertionType: TaskDependenciesInsertionType.PredecessorTask,
        targetTaskId: taskId,
        dependentTaskIds: data.predecessorTaskIds,
        taskDependency: data.dependencyType,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Updated,
        id: taskId as string,
        object: undefined,
      }

      dispatch(notifyTaskDependencyEvent(objectEventMessage))
    },
    [dispatch, insertTaskDependenciesMutation, taskId]
  )

  // タスク依存関係の削除
  const deleteTaskDependency = useCallback(
    async (taskDependencyId: string, version: number) => {
      await deleteTaskDependencyMutation({
        taskDependencyId,
        version,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Updated,
        id: taskId as string,
        object: undefined,
      }

      dispatch(notifyTaskDependencyEvent(objectEventMessage))
    },
    [deleteTaskDependencyMutation, dispatch, taskId]
  )

  const taskDependencyDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (loadOptions.filter) {
          if (!taskId) {
            return []
          }

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // Get selected task ids
          const selectedTaskIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetSuggestionTaskDependenciesQuery({
            taskId,
            keyword: loadOptions.searchValue,
            selectedTaskIds,
          }).unwrap()

          return response.tasks
        } else {
          if (!loadOptions.searchValue || !taskId) {
            return []
          }

          const response = await lazyGetSuggestionTaskDependenciesQuery({
            taskId,
            keyword: loadOptions.searchValue,
            selectedTaskIds: undefined,
          }).unwrap()

          return response.tasks
        }
      },
    }),
    [lazyGetSuggestionTaskDependenciesQuery, taskId]
  )

  const dependencyTypeDataSource = useTaskDependencyTypesDataSource()

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      predecessorTaskIds: {
        required: t('message.general.required'),
      },
      dependencyType: {},
    }),
    [t]
  )

  return {
    control,
    formState,
    validateRules,
    reset,
    setError,
    handleSubmit,
    taskDependencyDataSource,
    insertTaskDependency,
    dependencyTypeDataSource,
    deleteTaskDependency,
  }
}
