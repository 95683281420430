import { apis } from '../apiBase/apiBase';
export const favoriteApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        insertFavorite: builder.mutation({
            query: (params) => ({
                url: 'favorites/insert',
                method: 'POST',
                body: params,
            }),
        }),
        deleteFavorite: builder.mutation({
            query: (params) => ({
                url: 'favorites/delete',
                method: 'POST',
                body: params,
            }),
        }),
        getFavorites: builder.query({
            query: (params) => {
                return {
                    url: `favorites/${params.entityType}`,
                    method: 'GET',
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useInsertFavoriteMutation, useDeleteFavoriteMutation, useGetFavoritesQuery, useLazyGetFavoritesQuery, } = favoriteApis;
