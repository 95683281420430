import { convertMinutesToHHMM } from '@crew/utils'
import { FC, memo, useCallback, useMemo } from 'react'
import { WorkingTimeItem } from './components/workingTimeItem/workingTimeItem'
import classNames from 'classnames'
import { isWeekend } from 'utils/date'
import { ProjectRef } from '@crew/models/refs'

type WorkingTime = {
  taskId: string
  project: ProjectRef
  subject: string
  workTimesId: string
  actualWorkTimes: number
  workDate: string
}

type WorkingTimeDayProps = {
  workDate: string
  workingTimes: WorkingTime[]
  onOpenWorkTimeEntryDialog: (workDate: string) => void
}

export const WorkingTimeDay: FC<WorkingTimeDayProps> = memo((props) => {
  const totalWorkTimes = useMemo(() => {
    return props.workingTimes.reduce((acc, cur) => {
      return acc + (cur.actualWorkTimes ?? 0)
    }, 0)
  }, [props.workingTimes])

  // 日付エリアクリック時の処理
  const handleDateAreaClick = useCallback(() => {
    props.onOpenWorkTimeEntryDialog(props.workDate)
  }, [props])

  return (
    <td
      className={classNames(
        'border border-gray-300 py-0.5 font-normal align-top',
        isWeekend(new Date(props.workDate)) &&
          'bg-crew-gray-50 dark:bg-crew-gray-800/50'
      )}
      onClick={handleDateAreaClick}
    >
      {props.workingTimes.length > 0 && (
        <div className="flex flex-col h-full gap-0.5 ">
          <p className="text-right font-bold p-0.5">
            {convertMinutesToHHMM(totalWorkTimes)}
          </p>

          {props.workingTimes.map((workingTime) => (
            <WorkingTimeItem
              key={workingTime.workTimesId}
              workingTime={workingTime}
            />
          ))}
        </div>
      )}
    </td>
  )
})
