import { memo } from 'react'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { Routes, Route } from 'react-router-dom'
import { ContractHeadPanel } from './contractHeadPanel/contractHeadPanel'
import { ContractUpgradePlanPage } from './contractUpgradePlanPage/contractUpgradePlanPage'
import { ContractPortalPage } from './contractPortalPage/contractPortalPage'
import { ContractChangeStoragePage } from './contractChangeStoragePage/contractChangeStoragePage'
import { ContractChangeCycleBillingPage } from './contractChangeCycleBillingPage/contractChangeCycleBillingPage'
import { ContractRegisterPaymentMethodPage } from './contractRegisterPaymentMethodPage/contractRegisterPaymentMethodPage'
import { ContractUpdatePaymentToCardPage } from './contractUpdatePaymentToCardPage/contractUpdatePaymentToCardPage'
import { ContractChangeBillingAddressPage } from './contractChangeBillingAddressPage/contractChangeBillingAddressPage'
import { ContractPurchaseExternalUserPage } from './contractPurchaseExternalUserPage/contractPurchaseExternalUserPage'
import { ContractChangeContactInformationPage } from './contractChangeContactInformationPage/contractChangeContactInformationPage'

/**
 * ContractPage
 * @date 8/16/2023 - 9:17:24 AM
 *
 * @type {*}
 */
export const ContractPage = memo(() => {
  return (
    <div className="flex flex-col gap-2 p-2">
      <CrewErrorBoundary>
        <ContractHeadPanel />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <div className="flex-1 flex flex-col gap-y-5">
          <div className="grow min-h-0 w-full flex flex-col">
            {/* URLに応じて表示するコンポーネントを切り替える */}
            <Routes>
              <Route
                path={'upgrade-plan'}
                element={<ContractUpgradePlanPage />}
              />
              <Route path={'portal'} element={<ContractPortalPage />} />
              <Route
                path={'change-storage'}
                element={<ContractChangeStoragePage />}
              />
              <Route
                path={'change-cycle'}
                element={<ContractChangeCycleBillingPage />}
              />
              <Route
                path={'register-payment-method'}
                element={<ContractRegisterPaymentMethodPage />}
              />
              <Route
                path={'update-payment-to-card'}
                element={<ContractUpdatePaymentToCardPage />}
              />
              <Route
                path={'change-billing-address'}
                element={<ContractChangeBillingAddressPage />}
              />
              <Route
                path={'purchase-external-user'}
                element={<ContractPurchaseExternalUserPage />}
              />
              <Route
                path={'change-contact-information'}
                element={<ContractChangeContactInformationPage />}
              />
            </Routes>
          </div>
        </div>
      </CrewErrorBoundary>
    </div>
  )
})
