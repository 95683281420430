import { ColumnDef, TableOptions, getCoreRowModel } from '@tanstack/react-table'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import BaselineError from '~icons/ic/baseline-error'
import BaselineWarning from '~icons/ic/baseline-warning'
import { CrewPopover } from 'components/devextreme/crewPopover'
import { uniqueString } from '@crew/utils'

// Horizontal initialization value of popover
const MAX_WITH_POPOVER = 300

// Invalid format error code
const InvalidFormatErrorCode = 'invalid_format'

// The duplicate email error code
const EmailDuplicateErrorCode = 'email_duplicate'

// The past dates error code
const PastDatesErrorCode = 'past_dates'

// The invalid domain error code
const InvalidDomain = 'invalid_domain'

// The error code for no permission to invite internal user
const NotAllowedToInviteInternalUser = 'not_allow_invite_internal_user'

// The error code for no permission to invite external user
const NotAllowedToInviteExternalUser = 'not_allow_invite_external_user'

// project scope
const InvalidFormat = {
  Email: {
    key: 'email',
    message: 'message.tenant.invalidFormatEmail',
  },
  RoleCode: {
    key: 'roleCode',
    message: 'message.tenant.invalidFormatRoleCode',
  },
  ExpirationDatetime: {
    key: 'expirationDatetime',
    message: 'message.tenant.invalidFormatExpirationDatetime',
  },
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvalidFormat = (typeof InvalidFormat)[keyof typeof InvalidFormat]

type VerifyBulkInvitation_Row_Col = {
  value: string | null
  hasError: boolean
  errorCode: string | null
  hasWarning: boolean
  warningCode: string | null
}

type ItemStatusProps = {
  item: VerifyBulkInvitation_Row_Col
  columnKey?: string
}

const ItemStatus = memo((props: ItemStatusProps) => {
  const { t } = useTranslation()
  // ユニークキー生成
  const popoverKey = useMemo(() => uniqueString(), [])
  return (
    <div className="flex flex-row items-center gap-x-2.5">
      {props.item.hasWarning && (
        <div id={`warning-${popoverKey}`}>
          <BaselineWarning
            width={20}
            height={20}
            className="crew-warning-text"
          />
          <CrewPopover
            target={`#warning-${popoverKey}`}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position="top"
            maxWidth={MAX_WITH_POPOVER}
          >
            <span className="text-sm">
              {t(`message.tenant.${props.item.warningCode}`)}
            </span>
          </CrewPopover>
        </div>
      )}
      {props.item.hasError && (
        <div id={`error-${popoverKey}`}>
          <BaselineError width={20} height={20} className="crew-error-text" />
          <CrewPopover
            target={`#error-${popoverKey}`}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position="top"
            maxWidth={MAX_WITH_POPOVER}
          >
            <span className="text-sm">
              {
                // Display a message when encountering formating errors
                props.item.errorCode === InvalidFormatErrorCode
                  ? (() => {
                      switch (props.columnKey) {
                        case InvalidFormat.Email.key:
                          return t(InvalidFormat.Email.message)
                        case InvalidFormat.RoleCode.key:
                          return t(InvalidFormat.RoleCode.message)
                        case InvalidFormat.ExpirationDatetime.key:
                          return t(InvalidFormat.ExpirationDatetime.message)
                        default:
                          return t(`message.tenant.${props.item.errorCode}`)
                      }
                    })()
                  : // Display a message when encountering email duplication errors
                  props.item.errorCode === EmailDuplicateErrorCode
                  ? t(`message.general.duplicateEmail`)
                  : // Display a message when encountering past dates errors
                  props.item.errorCode === PastDatesErrorCode
                  ? t(`message.general.pastDatesInvalid`)
                  : // Display a message when the email's domain invalid
                  props.item.errorCode === InvalidDomain
                  ? t(`message.general.domainInvalid`)
                  : // Display a message when the logged user donot have permission to invite internal user
                  props.item.errorCode === NotAllowedToInviteInternalUser
                  ? t(`message.apiError.notAllowedToInviteInternalUser`)
                  : // Display a message when the logged user donot have permission to invite external user
                  props.item.errorCode === NotAllowedToInviteExternalUser
                  ? t(`message.apiError.notAllowedToInviteExternalUser`)
                  : // Display a common message
                    t(`message.tenant.${props.item.errorCode}`)
              }
            </span>
          </CrewPopover>
        </div>
      )}
    </div>
  )
})

type ResultCode = 'invalid' | 'invitable' | 'warning' | 'registered'

type VerifyBulkInvitation_Row = {
  email: VerifyBulkInvitation_Row_Col
  roleCode: VerifyBulkInvitation_Row_Col
  displayName: VerifyBulkInvitation_Row_Col | null
  alias: VerifyBulkInvitation_Row_Col | null
  searchKey: VerifyBulkInvitation_Row_Col | null
  department: VerifyBulkInvitation_Row_Col | null
  expirationDatetime: VerifyBulkInvitation_Row_Col | null
  checkResult: ResultCode
}

type BulkInviteUserTableProps = {
  inviteUsers: VerifyBulkInvitation_Row[]
}

export const BulkInviteUserTable: FC<BulkInviteUserTableProps> = memo(
  (props) => {
    const { t } = useTranslation()

    const columns = useMemo<ColumnDef<VerifyBulkInvitation_Row>[]>(
      () => [
        {
          id: 'email',
          header: () => t('label.mailAddress'),
          cell: ({ row }) => (
            <div className="w-full flex items-center gap-x-2.5">
              {row.original.email.value}
              <ItemStatus item={row.original.email} columnKey="email" />
            </div>
          ),
          size: 330,
          minSize: 50,
        },
        {
          id: 'role',
          header: () => t('label.role'),
          cell: ({ row }) => (
            <div className="w-full flex items-center gap-x-2.5">
              {row.original.roleCode.value}
              <ItemStatus item={row.original.roleCode} columnKey="roleCode" />
            </div>
          ),
          size: 120,
          minSize: 50,
        },
        {
          id: 'name',
          header: () => t('label.displayName'),
          cell: ({ row }) => (
            <div className="w-full flex items-center gap-x-2.5">
              {row.original.displayName?.value}
              {row.original.displayName && (
                <ItemStatus item={row.original.displayName} />
              )}
            </div>
          ),
          size: 120,
          minSize: 50,
        },
        {
          id: 'alias',
          header: () => t('label.userAlias'),
          cell: ({ row }) => (
            <div className="w-full flex items-center gap-x-2.5">
              {row.original.alias?.value}
              {row.original.alias && <ItemStatus item={row.original.alias} />}
            </div>
          ),
          size: 120,
          minSize: 50,
        },
        {
          id: 'searchKey',
          header: () => t('label.searchKey'),
          cell: ({ row }) => (
            <div className="w-full flex items-center gap-x-2.5">
              {row.original.searchKey?.value}
              {row.original.searchKey && (
                <ItemStatus item={row.original.searchKey} />
              )}
            </div>
          ),
          size: 120,
          minSize: 50,
        },
        {
          id: 'department',
          header: () => t('label.department'),
          cell: ({ row }) => (
            <div className="w-full truncate flex items-center gap-x-2.5">
              {row.original.department?.value}
              {row.original.department && (
                <ItemStatus item={row.original.department} />
              )}
            </div>
          ),
          size: 330,
          minSize: 50,
        },
        {
          id: 'expirationDate',
          header: () => t('label.expirationDate'),
          cell: ({ row }) => (
            <div className="w-full flex items-center gap-x-2.5">
              {row.original.expirationDatetime?.value}
              {row.original.expirationDatetime && (
                <ItemStatus
                  item={row.original.expirationDatetime}
                  columnKey="expirationDatetime"
                />
              )}
            </div>
          ),
          size: 120,
          minSize: 50,
        },
      ],
      [t]
    )

    const tableOptions: TableOptions<VerifyBulkInvitation_Row> = {
      data: props.inviteUsers,
      columns,
      columnResizeMode: 'onChange',
      getCoreRowModel: getCoreRowModel(),
      meta: {
        headerRowHeight: 40,
        dataRowHeight: 50,
      },
    }

    return (
      <div className="max-h-96 flex">
        <div className="grow overflow-hidden">
          {/* new users table */}
          <CrewTable
            tableOptions={tableOptions}
            showPager={false}
            showColumnSelector={false}
          />
        </div>
      </div>
    )
  }
)
