import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { memo } from 'react'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { CrewFollowerUs } from 'components/elements/crewFollowerUs/crewFollowerUs'
import { CrewTaskPriority } from 'components/elements/crewTaskPriority/crewTaskPriority'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'
import EventAvailable from '~icons/material-symbols/event-available'
import classNames from 'classnames'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { useProjectPermissions } from '@crew/hooks'
import { CrewAttachmentThumbnails } from 'components/elements/crewAttachmentThumbnails/crewAttachmentThumbnails'
import { CrewAttachments } from 'components/elements/crewAttachments/crewAttachments'
import { GetTaskRequest } from '@crew/apis/task/models/getTask/request'
import { useGetTaskQuery } from '@crew/apis/task/taskApis'
import { useTranslation } from '@crew/modules/i18n'
import { skipToken } from '@reduxjs/toolkit/query'
import { useModal } from 'components/layouts/modal/useModal'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { RefCallback, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import {
  setProjectId,
  updateIsExpandedDescription,
} from '../../states/taskDetailSlice'
import { EntityType } from '@crew/enums/domain'
import { isImageFile } from '@crew/utils/chat'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { convertMinutesToHHMM } from '@crew/utils'

const MAX_DESCRIPTION_HEIGHT = 50

export const TaskDetailPanel = memo(() => {
  const { t } = useTranslation()

  const { navigate } = useCrewNavigate()

  const [descriptionHeight, setDescriptionHeight] = useState(0)

  const dispatch = useAppDispatch()

  const { taskId } = useParams()

  const isExpandedDescription = useAppSelector(
    (state) => state.taskDetail.isExpandedDescription
  )

  // タスク関連データの更新を検知して再レンダリングを走らせるためのトリガー
  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)
  const taskCommentEventMessage = useAppSelector(
    (state) => state.app.taskCommentEventMessage
  )
  const taskWorkEventMessage = useAppSelector(
    (state) => state.app.taskWorkEventMessage
  )

  const [isTaskEntryDialogOpen, openTaskEntryDialog, closeTaskEntryDialog] =
    useModal()

  // タスク詳細を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getTaskParam: GetTaskRequest | undefined = taskId
    ? {
        taskId: taskId,
      }
    : undefined
  const { data: getTaskResult, refetch: refetchTask } = useGetTaskQuery(
    getTaskParam ?? skipToken
  )

  // TODO:「進捗管理から除外する」の表示は、下記タスクで仕様確定するまでは一時的に非表示とする。
  //       https://break-tmc.atlassian.net/browse/CREW-16286
  // const { doProjectManagement } = useProjectAdvancedSettings(
  //   getTaskResult?.task?.entityRecordId
  // )

  // Update task finish
  const handleTaskUpdated = useCallback(
    (taskId: string) => {
      //close task detail task entry dialog
      closeTaskEntryDialog()
    },
    [closeTaskEntryDialog]
  )

  // タスク削除後にタスク一覧に遷移
  const handleTaskDeleted = useCallback(() => {
    navigate('/tasks')
  }, [navigate])

  // task関連EventMessageの変更を検知して再レンダリングを走らせる
  // TODO: このuseEffectがなくても再レンダリングが走るようになっていたが、以下で調査予定
  // https://break-tmc.atlassian.net/browse/CREW-6110
  useEffect(() => {
    refetchTask()
  }, [
    refetchTask,
    taskEventMessage,
    taskCommentEventMessage,
    taskWorkEventMessage,
  ])

  // Measure the height of the element description
  const descriptionMeasuredRef = useCallback<RefCallback<HTMLDivElement>>(
    (node) => {
      if (node !== null) {
        // Measure the height of the element and update the `descriptionHeight` value
        setDescriptionHeight(node.getBoundingClientRect().height)
        // Dispatch an action to update the `isExpandedDescription` state
        // Check if the height of the element is greater than `MAX_DESCRIPTION_HEIGHT`
        dispatch(
          updateIsExpandedDescription(
            node.getBoundingClientRect().height > MAX_DESCRIPTION_HEIGHT
          )
        )
      }
    },
    // フィルタ選択時のみ実行とする
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getTaskResult?.task]
  )
  // reset state IsExpandedDescription, projectId when page refresh
  useEffect(() => {
    getTaskResult?.task?.entityType === EntityType.Project &&
      dispatch(setProjectId(getTaskResult.task.entityRecordId))

    return () => {
      // When the component is unmounded, reset the state of projectId
      dispatch(setProjectId())
    }
  }, [dispatch, getTaskResult?.task])

  const handleToggleDescription = useCallback(() => {
    dispatch(updateIsExpandedDescription(!isExpandedDescription))
  }, [dispatch, isExpandedDescription])

  // List image file
  const imageFiles = useMemo(
    () =>
      getTaskResult?.task?.fileHistories.filter((item) =>
        isImageFile(item.name)
      ),
    [getTaskResult?.task?.fileHistories]
  )

  // List normal file
  const files = useMemo(
    () =>
      getTaskResult?.task?.fileHistories.filter(
        (item) => !isImageFile(item.name)
      ),
    [getTaskResult?.task?.fileHistories]
  )

  const {
    hasPrjTaskEditPermission,
    hasPrjFileDeletePermission,
    hasPrjFileDownloadPermission,
  } = useProjectPermissions(EntityType.Task, taskId)

  // タスクデータが取得できていない場合は描画できないのでreturnする
  if (!getTaskResult?.task) {
    return null
  }

  const task = getTaskResult.task

  return (
    <div className="flex flex-col gap-2.5 p-2.5">
      <div className="w-full flex gap-x-2.5 justify-between items-center">
        {/* ページタイトル */}
        <div className="flex gap-2 items-center">
          <EventAvailable width={48} height={48} className="shrink-0" />
          <div>
            {/* 件名 */}
            <p className="text-2xl line-clamp-1 break-all" title={task.subject}>
              {task.subject}
            </p>

            {/* 関連先リンク */}
            <CrewRelatedItemLink
              entityType={task.entityType}
              id={task.entityRecordId}
              className="line-clamp-1 break-all"
            />
          </div>

          {/* タスク種別バッジ */}
          <CrewBadge displayColor={task.taskKind.displayColor}>
            {task.taskKind.name}
          </CrewBadge>

          {/* フォローバッジ */}
          <CrewFollowerUs
            entityType={EntityType.Task}
            entityRecordId={task.id}
          />
        </div>
        {/* show when you have edit task permission*/}
        {hasPrjTaskEditPermission && (
          <div className="flex gap-x-1 ml-auto">
            {/* 編集ボタン */}
            <CrewButton
              text={t('action.edit')}
              type="normal"
              stylingMode="outlined"
              onClick={openTaskEntryDialog}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col gap-y-2">
        {/* タスク項目 */}
        <div className="flex gap-x-8 gap-y-2.5 flex-row flex-wrap">
          {/* ステータス */}
          <div className="flex flex-col gap-y-1">
            <span className="crew-text-gray-4">{t('label.state')}</span>
            <CrewBadge displayColor={task.taskState.displayColor}>
              {task.taskState.name}
            </CrewBadge>
          </div>

          {/* 優先度 */}
          <div className="flex flex-col gap-y-1">
            <span className="crew-text-gray-4">{t('label.priority')}</span>
            <CrewTaskPriority taskPriority={task.taskPriority} />
          </div>

          {/* 分類 */}
          <div className="flex flex-col gap-y-1">
            <span className="crew-text-gray-4">{t('label.category')}</span>
            <span>{task.taskCategory?.name}</span>
          </div>

          {/* 担当者 */}
          <div className="flex flex-col gap-y-1 min-w-0 flex-1 max-w-fit">
            <span className="crew-text-gray-4 whitespace-nowrap">
              {t('label.assignedUser')}
            </span>
            {task.assignToUser && (
              <CrewUserAvatar
                userId={task.assignToUser.id}
                displayName={task.assignToUser.displayName}
                size={CrewAvatarSize.xs}
                showLabel={true}
                cacheValue={task.assignToUser.id + task.assignToUser.version}
              />
            )}
          </div>

          {/* 開始日 */}
          <div className="flex flex-col gap-y-1">
            <span className="crew-text-gray-4">{t('label.startDate')}</span>
            <span>
              {t('format.date', {
                value: task.startDate,
              })}
            </span>
          </div>

          {/* 期限 */}
          <div className="flex flex-col gap-y-1">
            <span className="crew-text-gray-4">{t('label.deadline')}</span>
            <span>
              {t('format.date', {
                value: task.dueDate,
              })}
            </span>
          </div>

          {/* 予定時間 */}
          <div className="flex flex-col gap-y-1">
            <span className="crew-text-gray-4">{t('label.scheduledTime')}</span>
            {typeof task.estimatedWorkTimes === 'number' && (
              <span>{convertMinutesToHHMM(task.estimatedWorkTimes)}</span>
            )}
          </div>

          {/* 実績時間 */}
          <div className="flex flex-col gap-y-1">
            <span className="crew-text-gray-4">{t('label.actualTime')}</span>
            {typeof task.actualWorkTimes === 'number' && (
              <span>{convertMinutesToHHMM(task.actualWorkTimes)}</span>
            )}
          </div>

          {/* 進捗率 */}
          <div className="flex flex-col gap-y-1">
            <span className="crew-text-gray-4">{t('label.progressRate')}</span>
            {task.actualProgress && (
              <span>
                {task.actualProgress}
                {t('label.percentSign')}
              </span>
            )}
          </div>

          {/* TODO:「進捗管理から除外する」の表示は、下記タスクで仕様確定するまでは一時的に非表示とする。
                    https://break-tmc.atlassian.net/browse/CREW-16286 */}
          {/* 進捗除外 */}
          {/* {doProjectManagement && (
            <div className="flex flex-col gap-y-1">
              <span className="crew-text-gray-4">
                {t('label.progressExcluded')}
              </span>
              {task.isProgressManagementDisabled && (
                <Check width={24} height={24} />
              )}
            </div>
          )} */}
        </div>
      </div>

      {/* 内容 */}
      <div className="lg:max-w-3xl">
        {(task.description || task.fileHistories.length > 0) && (
          <div
            className={classNames(
              'relative',
              // 続きを表示リンクがある場合は下余白を追加
              {
                'pb-6': descriptionHeight > MAX_DESCRIPTION_HEIGHT,
              }
            )}
          >
            {/* 内容 */}
            <div
              ref={descriptionMeasuredRef}
              className={classNames(
                isExpandedDescription ? 'max-h-32 overflow-hidden' : null
              )}
            >
              {/* Show content description */}
              {task.description && (
                <div
                  className={`text-crew-gray-4-light dark:text-crew-gray-1-dark whitespace-pre-wrap break-all`}
                >
                  <CrewHtmlContent html={task.description} />
                </div>
              )}

              {/* 添付画像ファイル */}
              {imageFiles && (
                <CrewAttachmentThumbnails
                  attachmentThumbnails={imageFiles}
                  showDeleteButton={hasPrjFileDeletePermission}
                  canDownload={hasPrjFileDownloadPermission}
                />
              )}

              {/* 添付ファイル */}
              {files && (
                <CrewAttachments
                  attachments={files}
                  showDeleteButton={hasPrjFileDeletePermission}
                  canDownload={hasPrjFileDownloadPermission}
                />
              )}
            </div>
            {/* 内容文の折りたたみ部位 */}
            {descriptionHeight > MAX_DESCRIPTION_HEIGHT && (
              <div>
                <div className="w-full absolute bottom-0">
                  <span
                    className="crew-link cursor-pointer"
                    onClick={handleToggleDescription}
                  >
                    {isExpandedDescription
                      ? t('action.showMore')
                      : t('action.abbreviate')}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* タスク編集ダイアログ */}
      <TaskEntryDialog
        isEditMode={true}
        title={t('label.editTaskTitle')}
        onSubmit={handleTaskUpdated}
        isOpen={isTaskEntryDialogOpen}
        onClose={closeTaskEntryDialog}
        taskId={task.id}
        onDeleted={handleTaskDeleted}
      />
    </div>
  )
})
