import { Modal } from 'components/layouts/modal/modal'
import { FC, memo } from 'react'
import { WorkingTimeEntryForm } from './components/workingTimeEntryForm/workingTimeEntryForm'

type WorkingTimeEntryDialogProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  targetDate: Date
}

export const WorkingTimeEntryDialog: FC<WorkingTimeEntryDialogProps> = memo(
  (props) => {
    return (
      <Modal
        title={props.title}
        isOpen={props.isOpen}
        onClose={props.onClose}
        className="!max-w-6xl !w-full"
      >
        <WorkingTimeEntryForm
          onCancel={props.onClose}
          targetDate={props.targetDate}
        />
      </Modal>
    )
  }
)
