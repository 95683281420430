import { apis } from '../apiBase/apiBase';
const userDeviceApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        insertUserDevice: builder.mutation({
            query: (params) => ({
                url: 'user_devices/insert',
                method: 'POST',
                body: params,
            }),
        }),
        deleteUserDevice: builder.mutation({
            query: (params) => ({
                url: 'user_devices/delete',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useInsertUserDeviceMutation, useDeleteUserDeviceMutation } = userDeviceApis;
