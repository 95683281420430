import { useCrewFollowerUs } from 'components/elements/crewFollowerUs/useCrewFollowerUs'
import { CrewSubscription } from 'components/elements/crewSubscription/crewSubscription'
import { EntityType } from '@crew/enums/domain'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'

type EntityKey = {
  entityType: EntityType
  entityRecordId: string
}

export type CrewFollowerUsProps = {
  entityType: EntityType
  entityRecordId: string
}

export const CrewFollowerUs: FC<CrewFollowerUsProps> = memo((props) => {
  const { insertFollow, deleteFollow, checkFollowEntity, checkFollowParent } =
    useCrewFollowerUs()

  const { t } = useTranslation()

  const { error } = useToast()

  const [isFollowing, setIsFollowing] = useState(false)
  const [isFollowingParent, setIsFollowingParent] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [followEntityKey, setFollowEntityKey] = useState<EntityKey>({
    entityType: props.entityType,
    entityRecordId: props.entityRecordId,
  })

  useEffect(() => {
    async function checkFollow() {
      // 自Entityのフォローを確認
      const follow = await checkFollowEntity(
        props.entityType,
        props.entityRecordId
      )

      if (follow) {
        setIsSubscribed(follow.notify)
        setIsFollowing(true)
        // 自Entityフォロー済みの場合、親Entityのフォローは無し
        setIsFollowingParent(false)
        setFollowEntityKey({
          entityType: props.entityType,
          entityRecordId: props.entityRecordId,
        })
      } else {
        // 親Entityのフォローを確認
        const parentFollow = await checkFollowParent(
          props.entityType,
          props.entityRecordId
        )

        if (parentFollow) {
          // 親Entityがフォロー済み＝自Entityもフォロー
          setIsSubscribed(parentFollow.notify)
          setIsFollowing(true)
          setIsFollowingParent(true)
          setFollowEntityKey({
            entityType: parentFollow.entityType,
            entityRecordId: parentFollow.entityRecordId,
          })
        } else {
          // 親Entity無し or 親Entity未フォロー
          setIsSubscribed(false)
          setIsFollowing(false)
          setIsFollowingParent(false)
          setFollowEntityKey({
            entityType: props.entityType,
            entityRecordId: props.entityRecordId,
          })
        }
      }
    }
    checkFollow()
  }, [
    checkFollowEntity,
    checkFollowParent,
    props.entityRecordId,
    props.entityType,
  ])

  // フォローする
  const handleFollowButtonClick = useCallback(async () => {
    try {
      const follow = await insertFollow(props.entityType, props.entityRecordId)

      setIsFollowing(true)
      setIsFollowingParent(false)
      if (follow) {
        setIsSubscribed(follow.notify)
        setFollowEntityKey({
          entityType: follow.entityType,
          entityRecordId: follow.entityRecordId,
        })
      } else {
        setIsSubscribed(false)
        setFollowEntityKey({
          entityType: props.entityType,
          entityRecordId: props.entityRecordId,
        })
      }
    } catch (e) {
      error(t('message.follow.failedToFollow'))
    }
  }, [error, insertFollow, props.entityRecordId, props.entityType, t])

  // フォロー解除
  const handleUnFollowButtonClick = useCallback(async () => {
    // 親Entityがフォローされている場合は、解除不可
    if (isFollowingParent) {
      error(t('message.follow.cannotFollowChildEntity'))
      return
    }

    try {
      await deleteFollow(props.entityType, props.entityRecordId)

      setIsFollowing(false)
      setIsFollowingParent(false)
      setIsSubscribed(false)
      setFollowEntityKey({
        entityType: props.entityType,
        entityRecordId: props.entityRecordId,
      })
    } catch (e) {
      error(t('message.follow.failedToUnfollow'))
    }
  }, [
    isFollowingParent,
    error,
    t,
    deleteFollow,
    props.entityType,
    props.entityRecordId,
  ])

  return (
    <div className="flex gap-x-3 shrink-0">
      {isFollowing ? (
        <div className="crew-follow-button crew-follow-on text-sm gap-x-1">
          <div onClick={handleUnFollowButtonClick} className="cursor-pointer">
            {t('action.following')}
          </div>
          <CrewSubscription
            entityType={followEntityKey.entityType}
            entityRecordId={followEntityKey.entityRecordId}
            isSubscribed={isSubscribed}
            isEnabled={!isFollowingParent}
          />
        </div>
      ) : (
        <div
          onClick={handleFollowButtonClick}
          className="crew-follow-button crew-follow-off text-sm cursor-pointer"
        >
          {t('action.followUs')}
        </div>
      )}
    </div>
  )
})
