import { useTranslation } from '@crew/modules/i18n'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ValidateRules } from '@crew/utils/form'
import dayjs from '@crew/modules'
import { DateFormat, JsonDateFormat } from '@crew/enums/system'
import { DisabledDate } from 'devextreme/ui/date_box'
import { InvitationRoleType, SettingKeyType } from '@crew/enums/app'
import { NewUserType } from '../../useTenantUserEntryDialog'
import { useVerifyUserPendingsMutation } from '@crew/apis/app/appApis'
import { useTenantRoleDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useAppSelector } from 'states/hooks'

export type UserTagBoxType = {
  value: string
}

export type FormValues = {
  invitationRoleType: string
  newUsers: string[]
  expirationDatetime: Date | null
}

const formInitialValues: FormValues = {
  invitationRoleType: InvitationRoleType.Internal.key,
  newUsers: [],
  expirationDatetime: null,
}

//return type for reduce function
export type ReduceReturnType = {
  [key in SettingKeyType]?: string | null
}

export const useInviteNewUsersToolbar = () => {
  const { t } = useTranslation()

  const [verifyUserPendingsMutation] = useVerifyUserPendingsMutation()

  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  // react-hook-formの各種データを取得
  const {
    control,
    handleSubmit,
    reset,
    formState,
    getValues,
    clearErrors,
    trigger,
    setError,
    setValue,
  } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // set data source for tenant role dropdown
  const tenantRoleDataSource = useTenantRoleDataSource(currentPlan)

  //set data source for new users tagbox
  const newUserDataSource = useDataSource(
    () => ({
      key: 'value',
      load: async () => {
        //User tagbox always add new user
        //Initial no need get data from API
        //Just return empty array
        return []
      },
      insert: async (value: UserTagBoxType) => {
        return value
      },
    }),
    []
  )

  // 日付の必須チェック
  // 外部ユーザーの場合は必須入力にする
  const validateRequiredDate = useCallback(() => {
    const invitationRoleType = getValues('invitationRoleType')
    const expirationDatetime = getValues('expirationDatetime')
    let isValid = true

    // If tenant role type is external, expiration date time is required
    if (invitationRoleType === InvitationRoleType.External.key) {
      if (!expirationDatetime) {
        isValid = false
      }
    }

    if (isValid) {
      clearErrors('expirationDatetime')
    }
    return isValid
  }, [clearErrors, getValues])

  // Check expiration date time must be greater than or equal to the current date
  const validateDate = useCallback(
    (value: Date | null) => {
      const expirationDatetime = dayjs(value).format(JsonDateFormat.YYYYMMDD)
      const currentDatetime = dayjs(new Date()).format(JsonDateFormat.YYYYMMDD)
      if (!value || currentDatetime <= expirationDatetime) {
        clearErrors('expirationDatetime')
        return true
      }
      return false
    },
    [clearErrors]
  )

  //check is date when user enter in input datepicker
  const validateIsDate = useCallback((value: Date | null) => {
    //when date enter invalid date, datepicker response undefined
    if (typeof value === 'undefined') {
      return false
    }

    return true
  }, [])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      newUsers: {
        required: t('message.general.required'),
        validate: {
          always: () => {
            trigger('expirationDatetime') //trigger validate expiration date when new users change
            return true
          },
        },
      },
      invitationRoleType: {
        required: t('message.general.required'),
      },
      expirationDatetime: {
        validate: {
          always: () => validateRequiredDate() || t('message.general.required'),
          checkDate: () =>
            validateIsDate(getValues('expirationDatetime')) ||
            t('message.general.invalidDate'),
          validateDate: () =>
            validateDate(getValues('expirationDatetime')) ||
            t('message.project.expirationDateMustBeCorrect'),
        },
      },
    }),
    [getValues, t, trigger, validateDate, validateIsDate, validateRequiredDate]
  )

  // 「現在日以降」しか指定できないようバリデーションを追加する。
  const isDisableDates = useCallback((date: DisabledDate) => {
    return (
      dayjs(new Date()).format(JsonDateFormat.YYYYMMDD) >
      dayjs(date.date).format(JsonDateFormat.YYYYMMDD)
    )
  }, [])

  // Verify the list of user before submit
  const verifyUserPendings = useCallback(
    async (invitationRoleType: string, destinations: string[]) => {
      const result = await verifyUserPendingsMutation({
        invitationRoleType,
        destinations,
      }).unwrap()

      return result
    },
    [verifyUserPendingsMutation]
  )

  // add users
  const addUsers = useCallback(
    (data: FormValues, selectedNewUsers: NewUserType[]) => {
      //map form values to new user type
      const payload: NewUserType[] = data.newUsers.map((user) => {
        let expirationDatetime: string | null = data.expirationDatetime
          ? dayjs(data.expirationDatetime).format(DateFormat.YYYYMMDD)
          : null

        //trim space in email or login id
        const emailOrLoginID = user.trim()

        return {
          emailOrLoginID,
          invitationRoleType: data.invitationRoleType,
          expirationDatetime,
        }
      })

      // replace same users in selected new users
      const filteredSelectedNewUsers = selectedNewUsers.filter((newUser) => {
        return !payload.some(
          (user) => user.emailOrLoginID === newUser.emailOrLoginID
        )
      })

      return [...filteredSelectedNewUsers, ...payload]
    },
    []
  )

  return {
    control,
    handleSubmit,
    reset,
    formState,
    setError,
    setValue,
    getValues,

    validateRules,

    newUserDataSource,
    isDisableDates,
    tenantRoleDataSource,
    addUsers,
    verifyUserPendings,
  }
}
