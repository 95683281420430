import { useLazyGetLookupProjectMembersQuery } from '@crew/apis/lookup/lookupApis'
import { useTranslation } from '@crew/modules/i18n'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import {
  useGetEventAttendeesQuery,
  useInsertEventAttendeesMutation,
} from '@crew/apis/project/projectApis'
import { ValidateRules } from '@crew/utils/form'
import { skipToken } from '@reduxjs/toolkit/query'
import { EventAttendee } from '@crew/models/domain'
import {
  ObjectEventMessage,
  notifyEventAttendeeEvent,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { useAppDispatch } from 'states/hooks'

export type FormValues = {
  userIds: string[]
  needNotification: boolean
}
const formInitialValues: FormValues = {
  userIds: [],
  needNotification: false,
}

export const useEventDetailAttendeesEntryForm = () => {
  const { t } = useTranslation()
  const { projectId, eventId } = useParams()
  const dispatch = useAppDispatch()

  // react-hook-formの各種データを取得
  const { handleSubmit, control, setError, formState } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Get the list of event attendees
  const { data: getEventAttendeesResult } = useGetEventAttendeesQuery(
    eventId ? { eventId } : skipToken
  )

  // Get function to get project members
  const [lazyGetProjectMembersQuery] = useLazyGetLookupProjectMembersQuery()

  // Get data source for event attendees
  const eventAttendeeDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        let excludeUserId = undefined
        //get list current user id of event attendees
        if (getEventAttendeesResult?.eventAttendees) {
          excludeUserId = getEventAttendeesResult.eventAttendees.map(
            (attendee) => attendee.userId
          )
        }

        if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したユーザIDのフィルタリング
          const filteringUserIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetProjectMembersQuery({
            projectId: projectId as string,
            keyword: undefined,
            userId: filteringUserIds,
            excludeUserId,
            isEnabledUser: true,
          }).unwrap()

          return response.projectMembers
        } else {
          // インクリメンタルサーチ
          const response = await lazyGetProjectMembersQuery({
            projectId: projectId as string,
            keyword: loadOptions.searchValue,
            userId: undefined,
            excludeUserId,
            isEnabledUser: true,
          }).unwrap()

          return response.projectMembers
        }
      },
    }),
    [lazyGetProjectMembersQuery, projectId, getEventAttendeesResult]
  )

  // Get function for insert event attendees
  const [insertEventAttendeesMutation] = useInsertEventAttendeesMutation()

  // Call API to insert event attendees
  const insertEventAttendees = useCallback(
    async (eventId: string, data: FormValues) => {
      const payload = {
        eventId,
        userIds: data.userIds,
      }
      await insertEventAttendeesMutation({
        eventAttendees: payload,
        needNotification: data.needNotification,
      }).unwrap()

      //trigger update event attendee list
      const objectEventMessage: ObjectEventMessage<EventAttendee> = {
        eventType: NotifyEventType.Inserted,
        id: payload.eventId,
        object: payload,
      }
      dispatch(notifyEventAttendeeEvent(objectEventMessage))
    },
    [dispatch, insertEventAttendeesMutation]
  )

  //  バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      userIds: {
        required: t('message.general.required'),
      },
      needNotification: {},
    }),
    [t]
  )

  return {
    handleSubmit,
    control,
    setError,
    formState,
    validateRules,
    eventAttendeeDataSource,
    insertEventAttendees,
  }
}
