import { Emoji } from '@crew/emoji-data'
import { forwardRef, memo, useCallback, useMemo } from 'react'
import { EmojiButton } from './emoijButton'
import { useTranslation } from '@crew/modules/i18n'

export const CategoryKey = {
  RecentlyUsed: {
    key: 'Recently used items',
    i18n: 'label.emojis.recentlyUsedItems',
  },
  SmileysAndEmotion: {
    key: 'Smileys & Emotion',
    i18n: 'label.emojis.smileysAndEmotion',
  },
  PeopleAndBody: {
    key: 'People & Body',
    i18n: 'label.emojis.peopleAndBody',
  },
  AnimalsAndNature: {
    key: 'Animals & Nature',
    i18n: 'label.emojis.animalsAndNature',
  },
  FoodAndDrink: {
    key: 'Food & Drink',
    i18n: 'label.emojis.foodAndDrink',
  },
  TravelAndPlaces: {
    key: 'Travel & Places',
    i18n: 'label.emojis.travelAndPlaces',
  },
  Activities: {
    key: 'Activities',
    i18n: 'label.emojis.activities',
  },
  Objects: {
    key: 'Objects',
    i18n: 'label.emojis.objects',
  },
  Symbols: {
    key: 'Symbols',
    i18n: 'label.emojis.symbols',
  },
  Flags: {
    key: 'Flags',
    i18n: 'label.emojis.flags',
  },
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CategoryKey = (typeof CategoryKey)[keyof typeof CategoryKey]

export type EmojiCategoryRowProps = {
  emojis: Emoji[]
  categoryKey: string
  handleSelectEmoji: (emoji: string) => void
}

export const EmojiCategoryRow = memo(
  forwardRef<HTMLDivElement, EmojiCategoryRowProps>((props, ref) => {
    const { t } = useTranslation()
    const emojiButton = useCallback(
      (shortName: string) => (
        <EmojiButton
          key={shortName}
          shortName={shortName}
          onChooseEmoji={props.handleSelectEmoji}
        />
      ),
      [props.handleSelectEmoji]
    )

    // i18n category name
    const categoryName = useMemo(() => {
      const categoryKey = Object.values(CategoryKey).find(
        (c) => c.key === props.categoryKey
      )

      return t(categoryKey?.i18n ?? props.categoryKey)
    }, [props.categoryKey, t])

    return (
      <div id={props.categoryKey} ref={ref}>
        <p className="mb-1 pt-2 font-semibold">{categoryName}</p>
        {props.emojis.length > 0 && (
          <div className="flex flex-wrap">
            {props.emojis.map((emoji) => emojiButton(emoji.short_name))}
          </div>
        )}
      </div>
    )
  })
)
