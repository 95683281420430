import React, { FC, memo, forwardRef } from 'react'
import classNames from 'classnames'

export const ButtonType = {
  primary: 'primary',
  success: 'success',
  danger: 'danger',
  normal: 'normal',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ButtonType = (typeof ButtonType)[keyof typeof ButtonType]

export const StylingMode = {
  text: 'text',
  outlined: 'outlined',
  contained: 'contained',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type StylingMode = (typeof StylingMode)[keyof typeof StylingMode]

export const ButtonSize = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize]

export type ButtonProps = {
  type?: ButtonType
  stylingMode?: StylingMode
  text?: string
  icon?: React.ReactNode
  onClick?: () => void
  render?: () => React.ReactElement
  size?: ButtonSize
  accesskey?: string
  disabled?: boolean
  tabIndex?: number
  visible?: boolean
  hint?: string
  className?: string
  useSubmitBehavior?: boolean
}

export const CrewButton: FC<ButtonProps> = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        icon,
        type = 'normal',
        stylingMode = 'contained',
        text,
        onClick,
        render,
        size = 'md',
        disabled,
        tabIndex,
        visible = true,
        hint,
        className,
        useSubmitBehavior = false,
        ...rest
      },
      ref
    ) => {
      return (
        <button
          ref={ref}
          disabled={disabled}
          tabIndex={tabIndex}
          title={hint}
          className={classNames(
            'crew-action-base',
            ButtonSizeClassNamesMap[size],
            ButtonClassNamesMap[type][stylingMode],
            !visible && 'hidden',
            icon && text && 'gap-2',
            className
          )}
          onClick={onClick}
          type={useSubmitBehavior ? 'submit' : 'button'}
          {...rest}
        >
          {/* カスタマイズ用のレンダー。レンダーが指定されている場合はレンダーのみを表示する。 */}
          {render?.() || (
            <>
              {/* render icon */}
              {icon}

              {/* render text */}
              <span className="whitespace-nowrap">{text}</span>
            </>
          )}
        </button>
      )
    }
  )
)

// Define a mapping object for button size
const ButtonSizeClassNamesMap = {
  xs: 'py-0.5 px-0.5 text-xs',
  sm: 'py-1 px-1 text-sm',
  md: 'py-1.5 px-3 text-base',
  lg: 'py-2 px-3 text-lg',
  xl: 'py-2.5 px-3 text-xl',
} as const satisfies { [key in ButtonSize]: string }

// Define a mapping object for button types and styling modes
const ButtonClassNamesMap = {
  primary: {
    contained: 'crew-action-default',
    outlined: 'crew-action-default-outlined',
    text: 'crew-action-default-text',
  },
  success: {
    contained: 'crew-action-success',
    outlined: 'crew-action-success-outlined',
    text: 'crew-action-success-text',
  },
  danger: {
    contained: 'crew-action-danger',
    outlined: 'crew-action-danger-outlined',
    text: 'crew-action-danger-text',
  },
  normal: {
    contained: 'crew-action-normal',
    outlined: 'crew-action-normal-outlined',
    text: 'crew-action-normal-text',
  },
} as const satisfies {
  [key in ButtonType]: {
    [key in StylingMode]: string
  }
}
