import { FC, memo } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { useTranslation } from '@crew/modules/i18n'
import { DirectChannelUserListToolbar } from 'features/chat/components/directChannelEntryDialog/components/directChannelUserList/components/directChannelUserListToolbar/directChannelUserListToolbar'
import { DirectChannelUserListPanel } from 'features/chat/components/directChannelEntryDialog/components/directChannelUserList/components/directChannelUserListPanel/directChannelUserListPanel'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

type DirectChannelEntryDialogProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  onUserSelected: (userId: string) => void // ユーザ選択後のイベント（登録は呼び出し側で行う）
  alreadyRegisteredUserIds: string[] // 登録済みユーザを省くためのdirectChannels登録済データ
}

export const DirectChannelEntryDialog: FC<DirectChannelEntryDialogProps> = memo(
  (props) => {
    const { t } = useTranslation()

    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <CrewErrorBoundary>
          <DirectChannelUserListToolbar />
        </CrewErrorBoundary>
        <CrewScrollView>
          <div className="flex flex-col gap-y-5">
            {/* モーダルの最小幅を制限し、ユーザ一覧が正しく表示されるようにする */}
            <div className="overflow-x-auto">
              <div className="min-w-[400px] flex flex-col gap-y-2.5">
                <DirectChannelUserListPanel
                  onUserSelected={props.onUserSelected}
                  alreadyRegisteredUserIds={props.alreadyRegisteredUserIds}
                />
              </div>
            </div>
          </div>
        </CrewScrollView>
        <div className="text-right">
          <CrewButton
            text={t('action.close')}
            type="normal"
            stylingMode="outlined"
            onClick={props.onClose}
          />
        </div>
      </Modal>
    )
  }
)
