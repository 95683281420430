import { memo, FC } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { ProjectDetailMemberUnitPriceEntryForm } from './components/projectDetailMemberUnitPriceEntryForm/projectDetailMemberUnitPriceEntryForm'

type PropsMode = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  userId: string | null
  projectMemberCostId: string | null
}

export const ProjectDetailMemberUnitPriceEntryDialog: FC<PropsMode> = memo(
  (props) => {
    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <ProjectDetailMemberUnitPriceEntryForm
          isEditMode={props.isEditMode}
          onSubmit={props.onClose}
          onCancel={props.onClose}
          userId={props.userId}
          projectMemberCostId={props.projectMemberCostId}
        />
      </Modal>
    )
  }
)
