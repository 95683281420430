import { memo, FC } from 'react'
import { DirectMessageTab } from './components/directMessageTab/directMessageTab'
import { Route, Routes } from 'react-router-dom'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { DirectMessageTabs } from 'enums/app'
import { DirectMessageChatPanel } from './components/directMessageChatPanel/directMessageChatPanel'
import { DirectMessageHeadPanel } from './components/directMessageHeadPanel/directMessageHeadPanel'
import { DirectMessageFileList } from './components/directMessageFileList/directMessageFileList'

export const DirectMessagePanel: FC = memo(() => {
  return (
    <div className="flex flex-1 gap-2 grow min-h-0">
      <main className="grow flex flex-col h-full w-2/3 overflow-y-auto">
        <CrewErrorBoundary>
          <div className="p-2">
            <DirectMessageHeadPanel />
          </div>
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <div className="min-h-0 flex-1 flex flex-col grow w-full">
            {/* ダイレクトメッセージタブ */}
            <div className="px-2">
              <DirectMessageTab />
            </div>
            <div className="grow min-h-0 w-full flex flex-col">
              {/* URLに応じて表示するコンポーネントを切り替える */}
              <Routes>
                {/* 
                  TODO: 一時的にデフォルトルート（index）を除去している
                  以下タスク対応時にデフォルトルートを設定する予定
                  https://break-tmc.atlassian.net/browse/CREW-9163
                */}
                <Route
                  path={DirectMessageTabs.Chat.value}
                  element={<DirectMessageChatPanel />}
                />

                <Route
                  path={DirectMessageTabs.File.value}
                  element={<DirectMessageFileList />}
                />
              </Routes>
            </div>
          </div>
        </CrewErrorBoundary>
      </main>
    </div>
  )
})
