import { useTranslation } from '@crew/modules/dist/i18n'
import { ValidateRules } from '@crew/utils/dist/form'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  useLoginMutation,
  useNewUserJoinTenantMutation,
} from '@crew/apis/dist/app/appApis'
import { UserPending } from '@crew/models/domain'

export type UserPendingInfo = UserPending & {
  destinationEmail: string
}

export type FormValues = {
  loginId: string
  fullName: string
  password: string
  agree: boolean
}

export const useSignupExistingAccount = (
  userPending: UserPendingInfo | null
) => {
  const { t } = useTranslation()

  // フォームの初期値
  // fullNameにpropsから渡される値を設定するためフック内に定義する
  const formInitialValues: FormValues = {
    loginId: '',
    fullName: userPending?.displayName || '', // パラメータとして渡されてきている場合は初期値として設定する
    password: '',
    agree: false,
  }

  // react-hook-formの各種データを取得
  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    defaultValues: formInitialValues,
    criteriaMode: 'all',
  })

  // Get functions for join tenant
  const [newUserJoinTenantMutation] = useNewUserJoinTenantMutation()
  const [loginMutation] = useLoginMutation()

  // Join tenant process
  const joinTenant = useCallback(
    async (data: FormValues, invitationToken: string) => {
      const payload = {
        ...data,
        displayName: data.fullName,
        invitationToken,
        passwordConfirm: undefined,
      }
      await newUserJoinTenantMutation({
        joinTenant: payload,
      }).unwrap()
    },
    [newUserJoinTenantMutation]
  )

  // Login process
  const login = useCallback(
    async (loginId: string, password: string) => {
      const response = await loginMutation({
        loginId: loginId,
        password: password,
      }).unwrap()

      return response
    },
    [loginMutation]
  )

  // 以下、入力コンポーネントのルールとレンダリングを定義
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      loginId: {
        required: t('message.general.isRequired', {
          name: t('label.userIdOrEmailAddress'),
        }),
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.userIdOrEmailAddress'),
            value: 500,
          }),
        },
      },
      fullName: {
        required: t('message.general.isRequired', {
          name: t('label.displayName'),
        }),
      },
      password: {
        required: t('message.general.isRequired', {
          name: t('label.password'),
        }),
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.password'),
            value: 500,
          }),
        },
      },
      agree: {},
    }),
    [t]
  )

  return {
    control,
    handleSubmit,
    formState,
    validateRules,
    watch,
    joinTenant,
    login,
  }
}
