import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { CrewTaskPriorityIcon } from 'components/elements/crewTaskPriorityIcon/crewTaskPriorityIcon'
import { ReactNode } from 'react'
import { getTaskPriorityEnumByValue } from 'utils'

// SelectBoxのfieldRenderで使うコンポーネント。renderとして使われるのでmemoできない
export const CrewTaskPrioritySelectBoxFieldRender = (data?: {
  id: number
  name: string
}): ReactNode => {
  const taskPriority = data && getTaskPriorityEnumByValue(data.id)
  return (
    <div className="dx-texteditor-input">
      {taskPriority && <CrewTaskPriorityIcon taskPriority={taskPriority} />}
      <div className="hidden">
        <CrewTextBox defaultValue={data?.name} readOnly={true} />
      </div>
    </div>
  )
}
