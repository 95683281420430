import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, memo, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import Close from '~icons/material-symbols/close'

type Props = {
  title: string
  isOpen: boolean
  // TODO: onCloseという命名だが、ダイアログが閉じられるときに実行されるわけではなく、×ボタンとダイアログ外クリック時に実行されるのみ。以下タスクで命名について議論予定
  // https://break-tmc.atlassian.net/browse/CREW-6549
  onClose: () => void
  children?: ReactNode
  className?: string
}

export const Modal: FC<Props> = memo((props) =>
  ReactDOM.createPortal(
    <AnimatePresence>
      {props.isOpen && (
        <motion.div
          key="modal"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          // ダイアログが他要素よりも前面に出るよう「relative z-60」を指定、左のメニューのz-indexが50のため
          className="relative z-60"
        >
          <div
            className="fixed inset-0 mx-auto min-h-screen flex flex-col justify-center overflow-hidden"
            // onScroll={handleOverlayScroll}
          >
            <div
              className="fixed w-full h-full bg-black/50"
              onClick={props.onClose}
            />
            <div
              className={classNames(
                'relative max-h-screen crew-bg-default  shadow-xl ring-1 ring-gray-900/5 p-5 h-full w-full rounded-none sm:h-auto lg:mx-auto lg:w-auto lg:max-w-4xl sm:rounded-lg',
                props.className
              )}
            >
              <div className="flex flex-col h-full gap-3">
                <div className="flex flex-row items-center">
                  <div className="grow">
                    <span className="text-lg">{props.title}</span>
                  </div>
                  <div onClick={props.onClose}>
                    <Close width={24} height={24} className="cursor-pointer" />
                  </div>
                </div>
                <div className="grow min-h-0 flex flex-col justify-center">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  )
)
