import { CrewButton } from 'components/elements/crewButton/crewButton'
import { memo, useCallback, useMemo } from 'react'

import { FileEntryDialog } from 'features/file/components/fileEntryDialog/fileEntryDialog'

import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { EventDetailFileSearch } from './components/eventDetailFileSearch/eventDetailFileSearch'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useParams } from 'react-router-dom'
import { useModal } from 'components/layouts/modal/useModal'
import { getDefaultTabValue } from '@crew/utils/dist/enum'
import { FileDetailListTabs, FileListDisplayMode } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { setSelectedFileListDisplayMode } from 'features/project/components/eventDetailPage/states/eventDetailPageSlice'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'

type ButtonGroupInfo = {
  index: number
  text: string | undefined
  icon: JSX.Element | undefined
}

export const EventDetailFileListToolbar = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()
  const { eventId } = useParams()
  const dispatch = useAppDispatch()

  // 選択中の表示形式
  const selectedFileListDisplayMode = useAppSelector(
    (state) => state.eventDetailPage.selectedFileListDisplayMode
  )

  /** ダイアログ */
  // ファイル登録ダイアログ
  const [isOpenFileEntryDialog, openFileEntryDialog, closeFileEntryDialog] =
    useModal()

  // ファイル登録後はファイル詳細画面に遷移
  const handleFileRegistered = useCallback(
    (fileId: string) => {
      closeFileEntryDialog()

      navigate(`/files/${fileId}/${getDefaultTabValue(FileDetailListTabs)}`)
    },
    [closeFileEntryDialog, navigate]
  )

  const { hasPrjFileCreatePermission } = useProjectPermissions(
    EntityType.Event,
    eventId
  )

  // 表示形式のボタングループ
  const fileDisplayModeButtonGroup = useMemo(() => {
    const items = Object.keys(FileListDisplayMode).map((key) => {
      const item = FileListDisplayMode[key as keyof typeof FileListDisplayMode]

      const SvgIcon = item.icon
      return {
        index: item.id,
        text: undefined,
        icon: <SvgIcon width={24} height={24} />,
      }
    })

    return items
  }, [])

  // 表示形式の切替ボタンクリック時
  const handleFileDisplayModeItemClick = useCallback(
    (itemData: ButtonGroupInfo) => {
      // 表示形式を更新
      dispatch(setSelectedFileListDisplayMode(itemData.index))
    },
    [dispatch]
  )

  return (
    <div className="p-2.5">
      <div className="flex flex-row justify-between items-center pb-2.5">
        {/* 表示切替ボタングループ */}
        <CrewButtonGroup
          items={fileDisplayModeButtonGroup}
          keyExpr="index"
          textExpr="text"
          iconExpr="icon"
          stylingMode="text"
          selectedItemKey={selectedFileListDisplayMode}
          onItemClick={handleFileDisplayModeItemClick}
        />

        {/* ファイル登録ボタン */}
        {hasPrjFileCreatePermission && (
          <div className="text-right pb-2.5">
            <CrewButton
              type="primary"
              className="ml-auto"
              text={t('action.newFile')}
              onClick={openFileEntryDialog}
            />
          </div>
        )}
      </div>

      {/* 検索条件*/}
      <EventDetailFileSearch />

      {/* ファイル登録ダイアログ */}
      <FileEntryDialog
        isEditMode={false}
        title={t('label.registerNewFile')}
        isOpen={isOpenFileEntryDialog}
        onClose={closeFileEntryDialog}
        onRegistered={handleFileRegistered}
        entityRecordId={eventId}
        entityType={EntityType.Event}
      />
    </div>
  )
})
