import { memo } from 'react'
import { useEventDetailAttendanceAndCommentForm } from './useEventDetailAttendanceAndCommentForm'
import { CrewRadioGroupField } from 'components/forms/crewRadioGroupField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useEventAttendeesContext } from '../../eventDetailAttendeeList'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'states/hooks'
import { useToast } from 'hooks/useToast'
import {
  ObjectEventMessage,
  notifyEventAttendeeEvent,
} from 'features/app/states/appSlice'
import { EventAttendee } from '@crew/models/dist/domain'
import { NotifyEventType } from 'enums/app'
import { useCallback, useMemo } from 'react'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useTranslation } from '@crew/modules/dist/i18n'
import { FormValues } from './useEventDetailAttendanceAndCommentForm'

export const EventDetailAttendanceAndCommentForm = memo(() => {
  const {
    control,
    handleSubmit,
    setError,
    formState,
    reset,
    attendanceDataSource,
    updateEventAttendee,
  } = useEventDetailAttendanceAndCommentForm()

  const { t } = useTranslation()
  const { currentAttendee } = useEventAttendeesContext()
  const { eventId } = useParams()
  const dispatch = useAppDispatch()
  const toast = useToast()

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const canSend = useMemo(
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    [formState]
  )

  //register attendance and comment
  const handleRegisterButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (!eventId || !currentAttendee) return

        const payload = {
          eventId,
          version: currentAttendee.version,
          ...data,
        }

        const result = await updateEventAttendee(payload)

        //trigger update event attendee list
        const objectEventMessage: ObjectEventMessage<EventAttendee> = {
          eventType: NotifyEventType.Updated,
          id: result.eventAttendee.userId,
          object: result.eventAttendee,
        }
        dispatch(notifyEventAttendeeEvent(objectEventMessage))

        toast.success(t('message.meeting.attendanceAndCommentUpdated'))

        //reset form value
        reset()
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    currentAttendee,
    dispatch,
    handleSubmit,
    eventId,
    reset,
    showApiErrors,
    t,
    toast,
    updateEventAttendee,
  ])

  return (
    <form className="flex gap-x-4 items-center">
      {/* Attendance */}
      <CrewRadioGroupField
        control={control}
        name="attendance"
        dataSource={attendanceDataSource}
        layout="horizontal"
        showLabel={false}
        displayExpr="text"
        valueExpr="id"
      />

      {/* Comment */}
      <CrewTextBoxField
        control={control}
        name="comment"
        id="comment"
        className="w-60"
        showLabel={false}
      />

      <CrewButton
        type="primary"
        text={t('action.register')}
        onClick={handleRegisterButtonClick}
        disabled={!canSend}
      />
    </form>
  )
})
