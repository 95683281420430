import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { Gantt } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof Gantt>>
export const CrewGantt = memo(
  forwardRef<Gantt, Props>(({ children, ...rest }, ref) => (
    <Gantt ref={ref} {...rest}>
      {children}
    </Gantt>
  ))
)
