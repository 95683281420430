import {
  getMessaging,
  MessagePayload,
  onMessage,
  isSupported,
} from 'firebase/messaging'
import { useEffectOnce } from '@dx-system/react-use'

import { User } from '@crew/models/domain'

import { LocalStorageKeys } from 'enums/system'
import { firebaseInstance } from 'modules/firebase'

import NotificationIcon from 'assets/images/png/logo-symbol-192.png'

// プッシュ通知内に含まれるpayload.dataを扱いやすくするためのtype定義
type MessagePayloadData = {
  title?: string
  message: string
  issuerUserId: string
}

// 受け取ったプッシュ通知を表示する必要があるかどうか制御する関数
// true: 表示が必要、false: 表示が不要
const isNeededToBeDisplayed = (publisherUserId: string | undefined) => {
  if (publisherUserId === undefined) {
    return false
  }

  // 複数タブ開いているとき複数通知が発火するのを防ぐため、アクティブでないタブの場合は通知は出さない
  if (document.visibilityState !== 'visible') {
    return false
  }

  // 自分自身が発行したプッシュの場合、通知は出さない
  const userJson = localStorage.getItem(LocalStorageKeys.LoggedInUser)
  if (!userJson) {
    return false
  }
  const user = JSON.parse(userJson) as User
  if (publisherUserId === user.id) {
    return false
  }

  // 上記すべての条件に該当しない場合はプッシュ通知を表示する必要がある
  return true
}

// フォアグラウンドでプッシュ通知を受け取るhook
export const useForegroundPushNotification = () => {
  useEffectOnce(() => {
    ;(async () => {
      // iOSはWebプッシュ通知をサポートしていないため、処理しない
      const supported = await isSupported()
      if (!supported) {
        console.info(
          '[firebase] This environment is not supported for Web Push Notification.'
        )
        return
      }

      // 念のためインスタンスの有無をチェックし、存在しない場合はreturnする
      if (!firebaseInstance) {
        console.log('[firebase] Messaging instance must be set.')
        return
      }

      // フォアグラウンドでプッシュ受信時に発火する
      const messaging = getMessaging(firebaseInstance)
      onMessage(messaging, (payload: MessagePayload) => {
        // 特定環境下でプッシュ通知を受け取れない場合、デバッグしづらいためconsole.debugする
        // console.debugは不要になったら削除する
        console.debug('[firebase] foreground:onMessage', payload)
        // 以降の処理をしやすいように変換する
        const pushedMessage = payload.data as MessagePayloadData | undefined

        // プッシュ通知発行者が特定できないことはあり得ないが、不正なデータの可能性があるので通知を表示しない
        if (!pushedMessage) {
          return
        }

        // プッシュ通知の表示が必要かどうかチェックし、不要なら表示しない
        if (!isNeededToBeDisplayed(pushedMessage.issuerUserId)) {
          return
        }

        const sanitizedMessage = pushedMessage.message

        // 通知を表示する
        const notification = new Notification(pushedMessage.title ?? '', {
          body: sanitizedMessage,
          data: pushedMessage,
          icon: NotificationIcon,
        })

        // 通知をクリックしたら指定のurlを開く
        notification.onclick = (e: Event) => {
          // console.debugは不要になったら削除する
          console.debug('[firebase] foreground:onclick notification', e)
          e.preventDefault()
          const target = e.target as Notification
          if (target.data.url) {
            // フルパスにしないと挙動がおかしくなることがあるためフルパス化
            const fullPath = new URL(target.data.url, window.location.origin)
              .href
            window.open(fullPath, '_blank')
          }
        }
      })
    })()
  })
}
