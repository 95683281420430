import { FC, memo, useCallback, useState } from 'react'
import { DateBox } from 'devextreme-react/date-box'
import { useValueChangeEffect } from '@crew/hooks'
import { useTranslation } from '@crew/modules/i18n'

export type DateRange = {
  startDate: Date | string | number | undefined
  endDate: Date | string | number | undefined
}

export type DateRangeBoxProps = {
  value: DateRange
  onValueChange: (value: DateRange) => void
}

export const DateRangeBox: FC<DateRangeBoxProps> = memo(
  ({ value, onValueChange }) => {
    const { t } = useTranslation()
    const [startDate, setStartDate] = useState<
      Date | string | number | undefined
    >(value.startDate)
    const [endDate, setEndDate] = useState<Date | string | number | undefined>(
      value.endDate
    )

    // Custom hook that updates the end date when the start date or end date changes
    useValueChangeEffect(
      () => {
        setEndDate(value.endDate) // Update the end date
      },
      [value.startDate, value.endDate],
      value.startDate // Only run the effect if the start date has changed
    )

    // Callback for handling changes to the start date
    const handleStartDateChange = useCallback(
      (newDate: Date | string | number | undefined) => {
        setStartDate(newDate) // Update the start date
        if (!endDate) {
          setEndDate(newDate) // If there's no end date, set it to the new start date
        }
        onValueChange({ startDate: newDate, endDate }) // Call the onValueChange function with the new dates
      },
      [endDate, onValueChange]
    )

    // Callback for handling changes to the end date
    const handleEndDateChange = useCallback(
      (newDate: Date | string | number | undefined) => {
        setEndDate(newDate) // Update the end date
        onValueChange({ startDate, endDate: newDate }) // Call the onValueChange function with the new dates
      },
      [onValueChange, startDate]
    )

    return (
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <label className="whitespace-nowrap">{t('label.startDate')}: </label>
          <DateBox
            type="date"
            value={startDate}
            onValueChanged={(e) => handleStartDateChange(e.value)}
            width={200}
          />
        </div>
        <div className="flex gap-2 items-center">
          <label className="whitespace-nowrap">{t('label.endDate')}: </label>
          <DateBox
            type="date"
            value={endDate}
            onValueChanged={(e) => handleEndDateChange(e.value)}
            min={startDate}
            width={200}
          />
        </div>
      </div>
    )
  }
)
