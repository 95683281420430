import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  LexicalCommand,
  createCommand,
  COMMAND_PRIORITY_EDITOR,
  $getSelection,
  $isRangeSelection,
  LexicalNode,
  $createParagraphNode,
  $createTextNode,
} from 'lexical'
import { $insertNodeToNearestRoot } from '@lexical/utils'
import { $findMatchingParent } from '@lexical/utils'
import { $isAutoLinkNode } from '@lexical/link'
import { mergeRegister } from '@lexical/utils'
import { $createAutoLinkNode } from '@lexical/link'
import { $createLinkCardNode, LinkCardNode } from '../linkCardNode/linkCardNode'

export const CONVERT_TO_LINK_CARD_COMMAND: LexicalCommand<string> =
  createCommand('CONVERT_TO_LINK_CARD_COMMAND')

export type ReplaceLinkCommandPayload = {
  url: string
  anchorNode: LexicalNode
}

export const CONVERT_TO_LINK_COMMAND: LexicalCommand<ReplaceLinkCommandPayload> =
  createCommand('CONVERT_TO_LINK_COMMAND')

export const LinkCardPlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([LinkCardNode])) {
      throw new Error('LinkCardNode is not registered on editor')
    }

    return mergeRegister(
      editor.registerCommand<string>(
        CONVERT_TO_LINK_CARD_COMMAND,
        (url) => {
          console.log('CONVERT_TO_LINK_CARD_COMMAND', url)
          const linkCardNode = $createLinkCardNode(url)

          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()
            const linkNode = $findMatchingParent(anchorNode, (e) => {
              return $isAutoLinkNode(e)
            })

            console.log('replace linkNode', linkNode)

            if (linkNode) {
              linkNode.selectNext()
              $insertNodeToNearestRoot(linkCardNode)
              linkNode.remove()
              linkCardNode.selectNext()
            }
          }
          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<ReplaceLinkCommandPayload>(
        CONVERT_TO_LINK_COMMAND,
        ({ url, anchorNode }) => {
          console.log('CONVERT_TO_LINK_COMMAND', url, anchorNode)
          const paragraphNode = $createParagraphNode()

          const linkNode = $createAutoLinkNode(url)
          const textNode = $createTextNode(url)

          linkNode.append(textNode)
          paragraphNode.append(linkNode)

          anchorNode.replace(paragraphNode)

          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor])

  return null
}
