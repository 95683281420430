import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { Sortable } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof Sortable>>

export const CrewSortable = memo(
  forwardRef<Sortable, Props>((props, ref) => {
    const { children, itemOrientation, ...rest } = props
    return (
      <Sortable ref={ref} {...rest}>
        {children}
      </Sortable>
    )
  })
)
