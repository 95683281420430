import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type DirectChannelEntryDialogStateType = {
  userSearchKeyword: string
}

const initialDirectChannelEntryDialogState: DirectChannelEntryDialogStateType =
  {
    userSearchKeyword: '',
  }

export const directChannelEntryDialogSlice = createSlice({
  name: 'directChannelEntryDialog',
  initialState: initialDirectChannelEntryDialogState,
  reducers: {
    setUserSearchKeyword: (state, action: PayloadAction<string>) => {
      state.userSearchKeyword = action.payload
    },
  },
})

export const { setUserSearchKeyword } = directChannelEntryDialogSlice.actions
