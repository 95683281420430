import { CrewButton } from 'components/elements/crewButton/crewButton'
import { memo, useCallback } from 'react'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import EventAvailable from '~icons/material-symbols/event-available'
import { TaskSearch } from './components/taskSearch/taskSearch'
import { useTranslation } from '@crew/modules/i18n'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useModal } from 'components/layouts/modal/useModal'
import { getDefaultTabValue } from '@crew/utils/enum'
import { TaskDetailListTabs } from 'enums/app'

export const TaskListToolbar = memo(() => {
  const { t } = useTranslation()

  const { navigate } = useCrewNavigate()

  const [isTaskEntryDialogOpen, openTaskEntryDialog, closeTaskEntryDialog] =
    useModal()

  // タスク登録完了時
  const handleTaskRegistered = useCallback(
    (taskId: string) => {
      closeTaskEntryDialog()

      // タスク詳細画面に遷移
      navigate(`/tasks/${taskId}/${getDefaultTabValue(TaskDetailListTabs)}`)
    },
    [closeTaskEntryDialog, navigate]
  )

  return (
    <>
      <div className="w-full flex gap-x-2.5 justify-between items-center">
        {/* ページタイトル */}
        <div className="flex gap-2 items-center h-8 text-crew-gray-3-light dark:text-crew-gray-2-dark">
          <EventAvailable width={24} height={24} />
          <p className="font-bold">{t('label.task') + t('label.list')}</p>
        </div>
        <div className="flex gap-x-1 ml-auto">
          {/* 新規タスクボタン */}
          <div className="flex flex-1">
            <CrewButton
              type="primary"
              className="ml-auto"
              text={t('action.newTask')}
              onClick={openTaskEntryDialog}
            />
          </div>
        </div>
      </div>
      <TaskEntryDialog
        isEditMode={false}
        title={t('label.addTaskTitle')}
        onSubmit={handleTaskRegistered}
        isOpen={isTaskEntryDialogOpen}
        onClose={closeTaskEntryDialog}
      />

      <div className="flex-1 flex gap-x-2.5 items-center">
        <TaskSearch />
      </div>
    </>
  )
})
