import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@crew/modules/i18n'
import { FileUploadConfirmFormProps } from './fileUploadConfirmForm'
import { ValidateRules } from '@crew/utils/form'

export type FormValues = {
  description: string
  needNotification: boolean
}

export const useFileUploadConfirmForm = (props: FileUploadConfirmFormProps) => {
  const { t } = useTranslation()

  const { formState, control, reset, handleSubmit } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      // 変更内容
      description: {
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.description'),
            value: 500,
          }),
        },
      },
      needNotification: {},
    }),
    [t]
  )

  return {
    formState,
    control,
    reset,
    handleSubmit,
    validateRules,
  }
}
