import {
  RefObject,
  UIEventHandler,
  createRef,
  forwardRef,
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CrewTextBox } from '../crewTextBox'
import {
  Virtuoso,
  VirtuosoGrid,
  VirtuosoGridHandle,
  VirtuosoHandle,
} from 'react-virtuoso'
import { EmojiCategoryRow } from './components/emojiCategoryRow'
import { EmojiCategoryItem } from './components/emojiCategoryItem'
import { EmojiButton } from './components/emoijButton'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { TextBox } from 'devextreme-react'
import { Emoji, emojiCategoryLookUp } from '@crew/emoji-data'
import { useFrequentlyUsedEmoji } from './hooks/useFrequentlyUsedEmoji'
import { useTranslation } from '@crew/modules/i18n'

// Frequently used items category key
export const FREQUENTLY_USED_EMOJI = 'Recently used items'

export const EmojiByCategory = Array.from(emojiCategoryLookUp).map(
  ([categoryKey, emojis]) => {
    return { key: categoryKey, emojis: emojis }
  }
)

export type CrewEmojiPickerProps = {
  reference: Element
  onClose: () => void
  onPickEmoji: (emoji: string) => void
}

export const CrewEmojiPicker = memo((props: CrewEmojiPickerProps) => {
  const { t } = useTranslation()

  const { frequentlyUsedEmojis, addFrequentlyUsedEmoji } =
    useFrequentlyUsedEmoji()
  const emojiPickerRef = useRef(null)

  // 絵文字カテゴリの一覧
  const emojiCategories = useMemo(
    () => [
      {
        key: FREQUENTLY_USED_EMOJI,
        emojis: frequentlyUsedEmojis,
      },
      ...EmojiByCategory,
    ],
    [frequentlyUsedEmojis]
  )

  //close emoji picker when click outside
  useOutsideClick(emojiPickerRef, props.onClose)

  const [currentCategory, setCurrentCategory] = useState(FREQUENTLY_USED_EMOJI)
  const [searchResults, setSearchResults] = useState<Emoji[]>([])
  const [searching, setSearching] = useState(false)

  // 絵文字カテゴリのrefのリスト
  const categoryRowRefs = useRef<{ [key: string]: RefObject<HTMLDivElement> }>(
    {}
  )
  // 絵文字カテゴリのrefのリストに各refを作成
  Object.values(emojiCategories).forEach((c) => {
    categoryRowRefs.current[c.key] = createRef<HTMLDivElement>()
  })

  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const virtuosoGridRef = useRef<VirtuosoGridHandle>(null)

  // scroll量を取得するためのscrollerのref
  const virtuosoScrollerRefObject = useRef<HTMLElement | null>(null)
  const virtuosoScrollerRef = useCallback((el: HTMLElement | Window | null) => {
    if (!(el instanceof Window)) {
      virtuosoScrollerRefObject.current = el
    }
  }, [])

  // 絵文字一覧のスクロールイベントハンドラ
  const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>(() => {
    // NOTE: scrollイベントはdom再描画前に発生してしまうが、
    //       現在表示中のカテゴリを判定するのに必要な、各カテゴリのoffsetTopはdom再描画後に取得する必要がある。
    //       そのため処理をsetTimeoutでdom描画後まで遅延させる
    window.setTimeout(() => {
      const scrollTop = virtuosoScrollerRefObject.current?.scrollTop || 0

      // 絵文字カテゴリのoffset一覧を取得
      const categoryOffsets = Object.entries(categoryRowRefs.current).map(
        ([key, el]) => ({ key, offset: el.current?.offsetTop })
      )

      // 現在表示中のカテゴリを判定
      const currentCategory = categoryOffsets
        // offsetTが存在し、且つそれがscrollTop以下のものを抽出
        .filter(
          (c): c is { key: string; offset: number } =>
            c.offset !== undefined && c.offset <= scrollTop
        )
        // offsetが最も大きいものを表示中のカテゴリとして抽出(降順ソートして先頭を取り出す)
        .sort((a, b) => b.offset - a.offset)
        .shift()

      if (!currentCategory) {
        return
      }

      setCurrentCategory(currentCategory.key)
    }, 0)
  }, [categoryRowRefs])

  //go to category when click on category item
  const handleGoToCategory = useCallback((categoryIndex: number) => {
    virtuosoRef.current?.scrollToIndex({ index: categoryIndex })
  }, [])

  //selected emoji event
  const handleSelectEmoji = useCallback(
    (emoji: string) => {
      // 絵文字を選択したら、その絵文字を親コンポーネントに通知する
      props.onPickEmoji(emoji)

      // 絵文字を選択したら、その絵文字を最近使用した絵文字として登録する
      addFrequentlyUsedEmoji(emoji)

      // 絵文字を選択したら、絵文字Pickerを閉じる
      props.onClose()
    },
    [addFrequentlyUsedEmoji, props]
  )

  // キーワード検索用のTextBoxのref
  const keywordTextBoxRef = useRef<TextBox>(null)

  //search emoji by keyword
  const handleKeywordTextBoxInput = useCallback(() => {
    // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
    const input = keywordTextBoxRef.current?.instance.option('text')
    if (input && input !== '') {
      let searchResult: Emoji[] = []
      emojiCategories.forEach((category) => {
        const emojis = category.emojis.filter((emoji) =>
          emoji.short_name.toLowerCase().includes(input.toLowerCase())
        )
        searchResult.push(...emojis)
      })

      setSearchResults(searchResult)
      setCurrentCategory('')
      setSearching(true)
    } else {
      setCurrentCategory(FREQUENTLY_USED_EMOJI)
      setSearchResults([])
      setSearching(false)
    }
  }, [emojiCategories])

  //set emoji picker position
  useLayoutEffect(() => {
    if (!props.reference) {
      return
    }

    if (emojiPickerRef.current) {
      const anchorRect = props.reference.getBoundingClientRect() // where on the screen is the target
      const emojiRef = emojiPickerRef.current as HTMLDivElement

      // center align the tooltip by taking both the target and tooltip widths into account
      emojiRef.style.left =
        anchorRect.left + anchorRect.width / 2 - emojiRef.offsetWidth / 2 + 'px'
      // make sure it doesn't poke off the left side of the page
      emojiRef.style.left = Math.max(emojiRef.offsetLeft, 0) + 'px'
      //or off the right
      emojiRef.style.left =
        Math.min(
          emojiRef.offsetLeft,
          document.body.clientWidth - emojiRef.offsetWidth
        ) + 'px'

      if (anchorRect.top < window.innerHeight / 2) {
        // the top half of the page
        // when on the top half of the page, position the top of the tooltip just below the target (it will stretch downwards)
        emojiRef.style.top = anchorRect.top + anchorRect.height + 'px'
        //reset bottom in case we've switched from bottom half
        emojiRef.style.bottom = 'auto'
      } else {
        // the bottom half of the page
        // when on the bottom half, position the bottom of the tooltip just above the target (it will stretch upwards)
        emojiRef.style.bottom = window.innerHeight - anchorRect.top + 'px'
        //reset top in case we've switched from top half
        emojiRef.style.top = 'auto'
      }
    }
  }, [props.reference])

  //VirtuosoGrid list wrapper
  const SearchingResultWrapper = memo(
    forwardRef((props, ref: React.Ref<HTMLDivElement>) => {
      return <div ref={ref} {...props} className="flex flex-wrap" />
    })
  )

  return (
    <div
      // 絵文字Pickerの表示位置を調整するためabsoluteを使用
      // ダイアログ(z-60)より上に表示する必要があるため、z-70を指定
      className="flex flex-col gap-y-3 absolute z-70 crew-bg-default rounded-md shadow-md p-5"
      ref={emojiPickerRef}
    >
      {/* Search box */}
      <CrewTextBox
        ref={keywordTextBoxRef}
        mode="search"
        placeholder={t('label.search')}
        valueChangeEvent="input change"
        onInput={handleKeywordTextBoxInput}
      />

      {/* Category tab list */}
      <div className="flex justify-between">
        {emojiCategories.map((category, index) => (
          <EmojiCategoryItem
            key={category.key}
            category={category.key}
            active={category.key === currentCategory}
            index={index}
            handleGoToCategory={handleGoToCategory}
            icon={
              category.key === FREQUENTLY_USED_EMOJI
                ? 'clock3'
                : category.emojis[0].short_name
            }
          />
        ))}
      </div>

      {/* Emoji list */}
      {searching ? (
        <VirtuosoGrid
          ref={virtuosoGridRef}
          style={{
            height: '300px',
            width: '330px',
          }}
          totalCount={searchResults.length}
          components={{
            List: SearchingResultWrapper,
          }}
          itemContent={(index) => {
            const emoji = searchResults[index] || {}
            return (
              <EmojiButton
                key={emoji.short_name}
                shortName={emoji.short_name}
                onChooseEmoji={handleSelectEmoji}
              />
            )
          }}
        />
      ) : (
        <Virtuoso
          id="virtuoso"
          ref={virtuosoRef}
          scrollerRef={virtuosoScrollerRef}
          totalCount={emojiCategories.length}
          data={emojiCategories}
          style={{
            height: '300px',
            width: '330px',
          }}
          overscan={100}
          onScroll={handleScroll}
          itemContent={(_, data) => (
            <EmojiCategoryRow
              categoryKey={data.key}
              handleSelectEmoji={handleSelectEmoji}
              ref={categoryRowRefs.current[data.key]}
              {...data}
            />
          )}
        />
      )}
    </div>
  )
})
