import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  createCommand,
  LexicalCommand,
  LexicalNode,
} from 'lexical'
import { useCallback, useEffect, useState } from 'react'
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin'
import { $isLinkNode, $isAutoLinkNode, LinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { getDOMRangeRect } from '../../utils/lexical'
import { LinkEditor } from '../linkEditor/linkEditor'

export const INSERT_LINK_COMMAND: LexicalCommand<string> = createCommand(
  'INSERT_LINK_COMMAND'
)

export function LinkEditorPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  const [isEditorOpened, setIsEditorOpened] = useState(false)
  const [anchorNode, setAnchorNode] = useState<LexicalNode | undefined>(
    undefined
  )
  const [anchorElement, setAnchorElement] = useState<Element | undefined>(
    undefined
  )
  const [url, setUrl] = useState<string>('https://')

  const closeEditor = useCallback(() => {
    setIsEditorOpened(false)
  }, [])

  useEffect(() => {
    return editor.registerCommand(
      INSERT_LINK_COMMAND,
      () => {
        const rootElement = editor.getRootElement()
        if (document.activeElement !== rootElement) {
          console.log('not focused')
          return false
        }

        const selection = $getSelection()
        if (!$isRangeSelection(selection)) {
          console.log('not range selection')
          return false
        }

        const node = selection.anchor.getNode()

        // When editor is focused and empty, the anchor node is a root node
        // In this case, we should not open the link editor
        if (node.__parent === 'root') {
          console.log('not link node')
          return false
        }

        setAnchorNode(node)

        // 表示位置は選択範囲の位置にする(長いTextNodeの一部を選択しているときなど、選択範囲の位置に表示したい)
        const nativeSelection = window.getSelection()
        console.log('nativeSelection', nativeSelection)
        if (rootElement !== null && nativeSelection !== null) {
          // https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/utils/getDOMRangeRect.ts#L8
          // https://github.com/facebook/lexical/blob/main/packages/lexical-selection/src/utils.ts#L124
          const rangeRect = getDOMRangeRect(nativeSelection, rootElement)
          console.log('rangeRect', rangeRect)
          setAnchorElement({
            getBoundingClientRect: () => rangeRect,
          } as Element)
        }

        setUrl('https://')
        setIsEditorOpened(true)

        return true
      },
      COMMAND_PRIORITY_LOW
    )
  }, [editor])

  return (
    <>
      <NodeEventPlugin
        // NodeEventPlugin側でeditor.update()内で呼ばれる
        eventListener={(e, _editor, nodeKey) => {
          console.log('LinkEditorPlugin eventListener', e.target, nodeKey)

          const node = $getNodeByKey(nodeKey)

          if ($isLinkNode(node) && !$isAutoLinkNode(node)) {
            console.log('LinkEditorPlugin LinkNode Click', node, node.getURL())
            setAnchorNode(node)

            // 表示位置はLinkNodeの位置でよい
            setAnchorElement(undefined)

            setUrl(node.getURL())
            setIsEditorOpened(true)
          }
        }}
        eventType="click"
        nodeType={LinkNode}
      />

      {anchorNode && (
        <LinkEditor
          isOpen={isEditorOpened}
          anchorNode={anchorNode}
          anchorElement={anchorElement}
          url={url}
          onClose={closeEditor}
        />
      )}
    </>
  )
}
