import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { useTranslation } from '@crew/modules/i18n'
import AddCircleOutline from '~icons/material-symbols/add-circle-outline'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  FormValues,
  useTaskDetailDependencyPredecessorTask,
} from './useTaskDetailDependencyPredecessorTask'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { TaskDetailDependencyItem } from '../taskDetailDependencyItem/taskDetailDependencyItem'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { Task } from '@crew/models/domain'

type ItemType = {
  id: string
  name: string
  description: string
}

// Dropdown item component
const renderDropdownItem = ({ data }: { data: ItemType }) => {
  return <span>{data.description}</span>
}

type TaskDependency = {
  id: string
  predecessorTask: Task
  version: number
}

type TaskDetailDependencyPredecessorTaskProps = {
  taskDependencies: TaskDependency[]
}

export const TaskDetailDependencyPredecessorTask: FC<TaskDetailDependencyPredecessorTaskProps> =
  memo((props) => {
    const {
      control,
      formState,
      validateRules,
      reset,
      setError,
      handleSubmit,
      taskDependencyDataSource,
      insertTaskDependency,
      dependencyTypeDataSource,
      deleteTaskDependency,
    } = useTaskDetailDependencyPredecessorTask()
    const { t } = useTranslation()
    const { taskId } = useParams()
    const { hasPrjTaskEditPermission } = useProjectPermissions(
      EntityType.Task,
      taskId
    )
    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()
    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    const [showAddDependencyTask, setShowAddDependencyTask] = useState(false)

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      // check permission create task
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    // Click the show add child task form button
    const handleShowAddPredecessorTaskFormButtonClick = useCallback(() => {
      setShowAddDependencyTask(true)
    }, [])

    // キャンセルボタンクリック
    const handleCancelButtonClick = useCallback(() => {
      // reset form
      reset()

      setShowAddDependencyTask(false)
    }, [reset])

    // Click the add predecessor task button
    const handleAddPredecessorTaskButtonClick = useCallback(async () => {
      const onSubmit = async (data: FormValues) => {
        try {
          await insertTaskDependency(data)
          // reset form
          reset()
          setShowAddDependencyTask(false)
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [handleSubmit, insertTaskDependency, reset, showApiErrors])

    const [taskDependencyId, setTaskDependencyId] = useState<string>()
    const [taskDependencyVersion, setTaskDependencyVersion] = useState<number>()

    // Click the delete predecessor task button
    const handleDeleteTaskDependency = useCallback(
      (id: string, version: number) => {
        setTaskDependencyId(id)
        setTaskDependencyVersion(version)
        openConfirmDialog()
      },
      [openConfirmDialog]
    )

    // 削除確認ダイアログ OKボタン
    const handleDeletePermitButtonClick = useCallback(async () => {
      closeConfirmDialog()

      try {
        if (!taskDependencyId || !taskDependencyVersion) return
        await deleteTaskDependency(taskDependencyId, taskDependencyVersion)
      } catch (err) {
        showApiErrors(err)
      }
    }, [
      closeConfirmDialog,
      deleteTaskDependency,
      showApiErrors,
      taskDependencyId,
      taskDependencyVersion,
    ])

    return (
      <>
        <p className="flex items-center">{t('label.predecessorTask')}</p>

        <div className="flex items-center">
          {hasPrjTaskEditPermission && (
            <CrewButton
              stylingMode="text"
              onClick={handleShowAddPredecessorTaskFormButtonClick}
              icon={<AddCircleOutline width={20} height={20} />}
              size="sm"
            />
          )}
        </div>

        <div className="col-span-2 flex flex-col gap-2.5">
          {showAddDependencyTask && (
            <form>
              <div className="flex flex-row items-center gap-2.5">
                <CrewSelectBoxField
                  control={control}
                  id="dependencyType"
                  name="dependencyType"
                  dataSource={dependencyTypeDataSource}
                  displayExpr="name"
                  valueExpr="id"
                  placeholder=""
                  rules={validateRules.dependencyType}
                  itemComponent={renderDropdownItem}
                />
                {/* Search */}
                <div className="flex-1">
                  <CrewTagBoxField
                    control={control}
                    id="predecessorTaskIds"
                    name="predecessorTaskIds"
                    dataSource={taskDependencyDataSource}
                    displayExpr="subject"
                    valueExpr="id"
                    searchEnabled={true}
                    showLabel={false}
                    placeholder=""
                    rules={validateRules.predecessorTaskIds}
                    maxDisplayedTags={3}
                    popupSearchEnabled={true}
                    showMaskMode="always"
                  />
                </div>

                {/* 追加 */}
                <CrewButton
                  text={t('label.add')}
                  type="primary"
                  disabled={!canSend}
                  onClick={handleAddPredecessorTaskButtonClick}
                />
                {/* キャンセル */}
                <CrewButton
                  text={t('action.cancel')}
                  type="normal"
                  stylingMode="outlined"
                  onClick={handleCancelButtonClick}
                />
              </div>
            </form>
          )}

          {/* dependency tasks */}
          {props.taskDependencies.map((taskDependency) => (
            <TaskDetailDependencyItem
              key={taskDependency.id}
              task={{
                ...taskDependency.predecessorTask,
                dependencyId: taskDependency.id,
                dependencyVersion: taskDependency.version,
              }}
              onDelete={handleDeleteTaskDependency}
            />
          ))}

          {/* 削除確認ダイアログ */}
          <CrewConfirmDialog
            isOpen={isConfirmDialogOpen}
            message={t('message.general.confirmMessage.delete')}
            onPermitButtonClick={handleDeletePermitButtonClick}
            onCancelButtonClick={closeConfirmDialog}
          />
        </div>
      </>
    )
  })
