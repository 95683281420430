import dayjs from '@crew/modules/dayjs'
import { DateFormat, JsonDateFormat } from '@crew/enums/system'
import {
  MINUTES_PER_WEEK,
  MINUTES_PER_DAY,
  MINUTES_PER_HOUR,
} from '@crew/configs/constants'

// Convert the datetime to UTC and format it with specified format
export const formatDateTimeToUtc = (str: string, format: DateFormat) => {
  if (!str) {
    return ''
  }
  return dayjs(str).utc().format(format)
}

// Convert the datetime to local time and format it with specified format
export const formatDateTimeToLocalTime = (str: string, format: DateFormat) => {
  if (!str) {
    return ''
  }
  return dayjs(str).local().format(format)
}

export const regexFormatWorkTime = (str: string | null) => {
  //Regex time format ex: 2w 4d 6h 45m (w = weeks, d = days, h = hours, m = minutes)
  const regexWorkTime = new RegExp(
    '^([0-9]+)w\\s(([0-9]+)d)\\s(([0-9]+)(h|m))$|^([0-9]+)w\\s(([0-9]+)h)\\s(([0-9]+)m)$|^([0-9]+)w\\s(([0-9]+)d)\\s(([0-9]+)h)\\s(([0-9]+)m)$|^([0-9]+)d\\s(([0-9]+)(h|m))$|^([0-9]+)d\\s(([0-9]+)h)\\s(([0-9]+)m)$|^([0-9]+)w\\s(([0-9]+)(d|h|m))$|^([0-9]+)d\\s(([0-9]+)(h|m))$|^([0-9]+)h\\s(([0-9]+)m)$|^([0-9]+)(w|d|h|m)$'
  )

  if (!str || (str && regexWorkTime.test(str))) {
    return true
  }

  return false
}

// convert minutes to work time string
export const convertMinutesToWorkTime = (value: number | null) => {
  if (value) {
    let workTimeStr = ''
    const weeks = Math.floor(value / MINUTES_PER_WEEK)
    const days = Math.floor(
      (value - weeks * MINUTES_PER_WEEK) / MINUTES_PER_DAY
    )
    const hours = Math.floor(
      (value - weeks * MINUTES_PER_WEEK - days * MINUTES_PER_DAY) /
        MINUTES_PER_HOUR
    )
    const minutes = Math.floor(
      value -
        weeks * MINUTES_PER_WEEK -
        days * MINUTES_PER_DAY -
        hours * MINUTES_PER_HOUR
    )
    if (weeks > 0) {
      workTimeStr += `${weeks}w ` //w = weeks
    }
    if (days > 0) {
      workTimeStr += `${days}d ` //d = days
    }
    if (hours > 0) {
      workTimeStr += `${hours}h ` //h = hours
    }
    if (minutes > 0) {
      workTimeStr += `${minutes}m ` //m = minutes
    }
    return workTimeStr.trim()
  }

  if (value === 0) return '0m' //if minutes = 0 set string to 0m (0 minutes)

  return null
}

// convert work time string to minutes
export const convertWorkTimeToMinutes = (value: string | null) => {
  if (value) {
    let minutes = 0

    value.split(' ').forEach((part) => {
      //get number before 'w' character
      if (part[part.length - 1] === 'w') {
        minutes += MINUTES_PER_WEEK * Number(part.replace('w', ''))
      }

      //get number before 'd' character
      if (part[part.length - 1] === 'd') {
        minutes += MINUTES_PER_DAY * Number(part.replace('d', ''))
      }

      //get number before 'h' character
      if (part[part.length - 1] === 'h') {
        minutes += MINUTES_PER_HOUR * Number(part.replace('h', ''))
      }

      //get number before 'm' character
      if (part[part.length - 1] === 'm') {
        minutes += Number(part.replace('m', ''))
      }
    })

    return minutes
  }

  return undefined
}

// check date is sunday or saturday
export const isWeekend = (date: Date) => {
  const day = date.getDay()
  return day === 0 || day === 6
}

// 終了日の場合（23:59分締めと考える）
// 時刻の部分がAM
// 前日の日付で登録
// 例）Dragして離した結果、2024/04/03 9:00だった⇒2024/04/02として登録
// 時刻の部分がPM
// 当日の日付で登録
// 例）Dragして離した結果、2024/04/03 17:30だった⇒2024/04/03として登録
export const adjustTimeAndFormatEndDate = (date: string) => {
  let dateObj = dayjs(date)

  // Check if the time is AM or PM
  const isAM = dateObj.hour() < 12

  if (isAM) {
    // For AM, use the previous day
    dateObj = dateObj.subtract(1, 'day').startOf('day')
  } else {
    // For PM, use the same day
    dateObj = dateObj.startOf('day')
  }

  return dateObj.format(JsonDateFormat.YYYYMMDD)
}

// 開始日の場合（00:00開始と考える）
// 時刻の部分がAM
// 当日の日付で登録
// 例）Dragして離した結果、2024/04/03 9:00だった⇒2024/04/03として登録
// 時刻の部分がPM
// 翌日の日付で登録
// 例）Dragして離した結果、2024/04/03 17:30だった⇒2024/04/04として登録
export const adjustTimeAndFormatStartDate = (date: string) => {
  let dateObj = dayjs(date)

  // Check if the time is AM or PM
  const isAM = dateObj.hour() < 12

  if (isAM) {
    // For AM, use the same day
    dateObj = dateObj.startOf('day')
  } else {
    // For PM, use the next day
    dateObj = dateObj.add(1, 'day').startOf('day')
  }

  return dateObj.format(JsonDateFormat.YYYYMMDD)
}
