import { EntityType } from '@crew/enums/domain'
import { useInsertTagMutation } from '@crew/apis/file/fileApis'
import {
  useLazyGetTagQuery,
  useLazyGetTagsQuery,
} from '@crew/apis/lookup/lookupApis'
import { Tag } from '@crew/models/domain'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { useDataSource } from './useDataSource'

//TODO: 共通化のやり方がわからないので、別々に実装
export const useTagDataSource = (entityType: EntityType) => {
  const [lazyGetTagsQuery] = useLazyGetTagsQuery()
  const [insertTagMutation] = useInsertTagMutation()

  const [lazyGetTagQuery] = useLazyGetTagQuery()

  const insertTagDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!loadOptions.searchValue && !loadOptions.filter) {
          return []
        }

        if (loadOptions.searchValue) {
          const response = await lazyGetTagsQuery({
            entityType: entityType,
            name: loadOptions.searchValue,
            tagIds: undefined,
          }).unwrap()

          return response.tags
        } else if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したタグIDのフィルタリング
          const filteringTagIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )
          const response = await lazyGetTagsQuery({
            entityType: entityType,
            name: undefined,
            tagIds: filteringTagIds,
          }).unwrap()

          return response.tags
        }
        return []
      },
      insert: async (values: Tag) => {
        const tag = {
          entityType: entityType,
          name: values.name,
        }
        // 入力されたタグ名称に該当するtagIdを取得
        const response = await insertTagMutation({ tag }).unwrap()
        if (!response.tag) {
          return []
        }

        // NOTE: insertの戻り値もany型
        const result: any = { id: response.tag.id, name: response.tag.name }

        return result
      },
    }),
    [entityType, insertTagMutation, lazyGetTagsQuery]
  )

  const selectTagDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!loadOptions.searchValue && !loadOptions.filter) {
          return []
        }

        if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したタグIDのフィルタリング
          const filteringTagIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )
          const response = await lazyGetTagsQuery({
            entityType: entityType,
            name: undefined,
            tagIds: filteringTagIds,
          }).unwrap()

          return response.tags
        } else {
          // インクリメンタルサーチ
          const response = await lazyGetTagsQuery({
            entityType: entityType,
            name: loadOptions.searchValue,
            tagIds: undefined,
          }).unwrap()

          return response.tags
        }
      },
      byKey: async (tagId: string) => {
        const response = await lazyGetTagQuery({
          tagId,
        }).unwrap()
        return response.tag
      },
    }),
    [entityType, lazyGetTagQuery, lazyGetTagsQuery]
  )

  return {
    insertTagDataSource,
    selectTagDataSource,
  }
}
