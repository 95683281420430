import { useCallback } from 'react'
import {
  useDeleteEventKindMutation,
  useReorderEventKindMutation,
} from '@crew/apis/project/projectApis'
import { ReorderEventKindRequest } from '@crew/apis/project/models/reorderEventKind/request'
import { useAppDispatch } from 'states/hooks'
import {
  notifyEventKindEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { EventKind } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'

export type SelectedEventKind = {
  eventKindId: string
  version: number
}

export const useEventKindListGrid = () => {
  const dispatch = useAppDispatch()

  // Get functions for update event type
  const [reorderEventKindMutation] = useReorderEventKindMutation()
  const [deleteEventKindMutation] = useDeleteEventKindMutation()

  // Delete event type process
  const deleteEventKind = useCallback(
    async (eventKindId: string, version: number) => {
      await deleteEventKindMutation({
        eventKindId,
        version,
      }).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<EventKind> = {
        eventType: NotifyEventType.Deleted,
        id: eventKindId,
        object: undefined,
      }
      dispatch(notifyEventKindEvent(objectEventMessage))
    },
    [deleteEventKindMutation, dispatch]
  )

  // Reorder event type process
  const reorderEventKind = useCallback(
    async (eventKindId: string, targetPositionEventKindId: string) => {
      const request: ReorderEventKindRequest = {
        eventKindId,
        targetPositionEventKindId,
      }

      await reorderEventKindMutation(request).unwrap()
    },
    [reorderEventKindMutation]
  )

  return { deleteEventKind, reorderEventKind }
}
