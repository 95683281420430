import { FC, memo, useMemo } from 'react'

import { useTranslation } from '@crew/modules/dist/i18n'
import { selectUnreadCountTotal, useUserSetting } from '@crew/states'
import type { SelectUnreadCountResult } from '@crew/states'

import { AttentionTabType } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useAppSelector } from 'states/hooks'

import {
  BadgeColor,
  CrewNavTabProps,
} from 'components/elements/crewNavTabs/components/crewNavTab'
import { CrewNavTabs } from 'components/elements/crewNavTabs/crewNavTabs'
import {
  AttentionDisplayGroup,
  NewsMentionAndReplies,
  SettingKeyType,
} from '@crew/enums/app'
import { assertNever } from '@crew/utils'

/**
 * 「メンションと返信」画面のタブに表示するバッジの表示情報を生成する
 * @param type タブの種類
 * @param unread 未読情報
 * @returns
 */
const generateBadgeInfo = (
  type: AttentionDisplayGroup,
  unread: SelectUnreadCountResult,
  newsMentionAndReplies: string | number | null
): { count: number | undefined; color: BadgeColor | undefined } => {
  switch (type) {
    case AttentionTabType.All.value:
      return {
        count: unread.attention,
        color:
          unread.mentionToMe > 0
            ? 'Red'
            : unread.mentionToGroup > 0
            ? 'Orange'
            : 'Blue',
      }
    case AttentionTabType.Mention.value:
      return {
        count: unread.mentionTotal,
        color:
          unread.mentionToMe > 0
            ? 'Red'
            : unread.mentionToGroup > 0
            ? 'Orange'
            : 'Blue',
      }
    case AttentionTabType.Reply.value:
      return {
        count:
          newsMentionAndReplies !== NewsMentionAndReplies.Mention.value
            ? unread.reply
            : 0, // 「メンションと返信」の通知する内容が「メンションのみ」の場合は未読数を0にする
        color: 'Blue',
      }
    case AttentionTabType.Reaction.value:
      // リアクションはバッジを表示しない
      return {
        count: undefined,
        color: undefined,
      }
    default:
      // enumから値を取得しているので、上記の条件に一致しない場合は考えられないが、念のためにエラーを出力する
      assertNever(type, 'AttentionTabs: invalid tab value')
  }
}

export const AttentionTabs: FC = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  // 「メンションと返信」に通知する内容の個人設定値
  const newsMentionAndReplies = useUserSetting(
    SettingKeyType.NewsMentionAndReplies
  )

  const unread = useAppSelector(selectUnreadCountTotal(newsMentionAndReplies))

  // タブアイテム
  const displayTabItems: CrewNavTabProps[] = useMemo(() => {
    return Object.values(AttentionTabType).map((item) => {
      const badgeInfo = generateBadgeInfo(
        item.value,
        unread,
        newsMentionAndReplies
      )
      return {
        onClick: () => {
          // タブに対応するURLへ遷移（相対パス指定）
          navigate(item.value)
        },
        to: item.value,
        text: t(item.text),
        icon: item.icon,
        badgeCount: badgeInfo.count,
        badgeColor: badgeInfo.color,
      }
    })
  }, [navigate, newsMentionAndReplies, t, unread])

  return <CrewNavTabs tabItems={displayTabItems} />
})
