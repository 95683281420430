import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { ScrollView } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof ScrollView>>

export const CrewScrollView = memo(
  forwardRef<ScrollView, Props>((props, ref) => {
    const { children, showScrollbar, ...rest } = props
    return (
      <ScrollView ref={ref} {...rest} showScrollbar={showScrollbar ?? 'always'}>
        {children}
      </ScrollView>
    )
  })
)
