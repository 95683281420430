import { $getNodeByKey, LexicalNode } from 'lexical'
import { useCallback, useState } from 'react'
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin'
import { $isLinkCardNode, LinkCardNode } from '../linkCardNode/linkCardNode'
import { LinkCardEditor } from '../linkCardEditor/linkCardEditor'

export function LinkCardEditorPlugin(): JSX.Element | null {
  const [isEditorOpened, setIsEditorOpened] = useState(false)
  const [anchorNode, setAnchorNode] = useState<LexicalNode | undefined>(
    undefined
  )
  const [anchorElement, setAnchorElement] = useState<Element | undefined>(
    undefined
  )
  const [url, setUrl] = useState<string>('https://')

  const closeEditor = useCallback(() => {
    setIsEditorOpened(false)
  }, [])

  return (
    <>
      <NodeEventPlugin
        // NodeEventPlugin側でeditor.update()内で呼ばれる
        eventListener={(e, _editor, nodeKey) => {
          console.log('LinkCardEditorPlugin eventListener', e.target, nodeKey)

          const node = $getNodeByKey(nodeKey)

          if ($isLinkCardNode(node)) {
            console.log(
              'LinkCardEditorPlugin LinkCardNode Click',
              node,
              node.getURL()
            )
            setAnchorNode(node)

            // 表示位置はLinkNodeの位置でよい
            setAnchorElement(undefined)

            setUrl(node.getURL())
            setIsEditorOpened(true)
          }
        }}
        eventType="click"
        nodeType={LinkCardNode}
      />

      {anchorNode && (
        <LinkCardEditor
          isOpen={isEditorOpened}
          anchorNode={anchorNode}
          anchorElement={anchorElement}
          url={url}
          onClose={closeEditor}
        />
      )}
    </>
  )
}
