import { memo } from 'react'
import { InviteNewUsersToolbar } from './components/inviteNewUsersToolbar/inviteNewUsersToolbar'
import { InviteNewUserListPanel } from './components/inviteNewUserListPanel/inviteNewUserListPanel'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

export const InviteNewUsers = memo(() => {
  return (
    <>
      <CrewErrorBoundary>
        <InviteNewUsersToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <InviteNewUserListPanel />
      </CrewErrorBoundary>
    </>
  )
})
