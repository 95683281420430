import { FC, memo, useCallback, useMemo } from 'react'
import { ValueChangedEvent as TextBoxValueChangedEvent } from 'devextreme/ui/text_box'
import { ValueChangedEvent as SelectBoxValueChangedEvent } from 'devextreme/ui/select_box'
import { ValueChangedEvent as TagBoxValueChangedEvent } from 'devextreme/ui/tag_box'
import _ from 'lodash'
import {
  ParamValue,
  SearchFilter,
  dateFilterValueToString,
  stringToDateFilterValue,
} from 'utils/filter'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { assertNever } from '@crew/utils'
import { DetailFileSearchOptions, DisplayAnonymousFile } from 'enums/app'
import { CrewTagBox } from 'components/devextreme/crewTagBox'
import { useTagDataSource } from 'hooks/dataSource/useTagDataSource'
import { EntityType } from '@crew/enums/domain'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import {
  CrewDateFilterBox,
  CrewDateFilterBoxValueChangedEvent,
} from 'components/devextreme/crewDateFilterBox'
import { useMemberDataSource } from 'hooks/dataSource/useMemberDataSource'
import { CrewDropDownTreeViewBox } from 'components/devextreme/crewDropDownTreeViewBox'
import { useFolderDataSource } from 'hooks/dataSource/useFolderDataSource'
import { useTranslation } from '@crew/modules/i18n'

type DetailFileSearchInputProps = {
  filter: SearchFilter
  updateSearchValue: (filter: SearchFilter, value: ParamValue) => void
  entityType: EntityType
  entityRecordId: string | undefined
}

export const CrewDetailFileSearchInput: FC<DetailFileSearchInputProps> = memo(
  ({ filter, updateSearchValue, entityType, entityRecordId }) => {
    const { t } = useTranslation()
    //set data source for tag dropdown
    const { selectTagDataSource } = useTagDataSource(EntityType.File)
    const tagDataSource = selectTagDataSource

    // custom data source for created by user
    const createdByUserDataSource = useMemberDataSource(
      entityType,
      entityRecordId,
      undefined,
      true
    )

    // custom data source for updated by user
    const updatedByUserDataSource = useMemberDataSource(
      entityType,
      entityRecordId,
      undefined,
      true
    )

    // フォルダのDataSource
    const folderDataSource = useFolderDataSource(
      entityType,
      entityRecordId ?? null
    )

    // 匿名ファイル表示のDataSource
    const anonymousFileDataSource = useMemo(() => {
      return Object.values(DisplayAnonymousFile).map((display) => {
        return {
          id: display.value,
          name: t(display.text),
        }
      })
    }, [t])

    // キーワードの変更
    const handleKeywordChanged = useCallback(
      (e: TextBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value === '') {
          updateSearchValue(filter, undefined)
          return
        }
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // change tag
    const handleTagChanged = useCallback(
      (e: TagBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 作成者の変更
    const handleCreatedByChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 更新者の変更
    const handleUpdatedByChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 作成日の変更
    const handleCreatedAtChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // 更新日の変更
    const handleUpdatedAtChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // フォルダの変更
    const handleFolderChanged = useCallback(
      (value: string | null) => {
        if (!value) {
          updateSearchValue(filter, undefined)
          return
        }

        updateSearchValue(filter, value)
      },
      [filter, updateSearchValue]
    )

    // 匿名ファイル表示の変更
    const handleAnonymousFileChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    switch (filter.field) {
      case DetailFileSearchOptions.Keyword.id:
        return (
          <CrewTextBox
            id={DetailFileSearchOptions.Keyword.id}
            name={DetailFileSearchOptions.Keyword.name}
            value={filter.value as string}
            showClearButton={true}
            mode="search"
            onValueChanged={handleKeywordChanged}
          />
        )
      case DetailFileSearchOptions.Tag.id:
        return (
          <CrewTagBox
            id={DetailFileSearchOptions.Tag.id}
            name={DetailFileSearchOptions.Tag.name}
            displayExpr="name"
            valueExpr="id"
            dataSource={tagDataSource}
            searchEnabled={true}
            popupSearchEnabled={true}
            searchMode="contains"
            searchExpr="name"
            minSearchLength={0}
            maxDisplayedTags={2}
            value={filter.value as string[]}
            onValueChanged={handleTagChanged}
          />
        )
      case DetailFileSearchOptions.CreatedById.id:
        return (
          <CrewSelectBox
            id={DetailFileSearchOptions.CreatedById.id}
            name={DetailFileSearchOptions.CreatedById.name}
            displayExpr="displayName"
            valueExpr="id"
            dataSource={createdByUserDataSource}
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleCreatedByChanged}
            searchEnabled={true}
            searchExpr="displayName"
          />
        )
      case DetailFileSearchOptions.UpdatedById.id:
        return (
          <CrewSelectBox
            id={DetailFileSearchOptions.UpdatedById.id}
            name={DetailFileSearchOptions.UpdatedById.name}
            displayExpr="displayName"
            valueExpr="id"
            dataSource={updatedByUserDataSource}
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleUpdatedByChanged}
            searchEnabled={true}
            searchExpr="displayName"
          />
        )
      case DetailFileSearchOptions.CreatedAt.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleCreatedAtChanged}
          />
        )
      case DetailFileSearchOptions.UpdatedAt.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleUpdatedAtChanged}
          />
        )
      case DetailFileSearchOptions.Folder.id:
        return (
          <CrewDropDownTreeViewBox
            dataSource={folderDataSource}
            displayExpr="name"
            valueExpr="id"
            parentIdExpr="parentFolderId"
            showClearButton={true}
            value={filter.value}
            onValueChange={handleFolderChanged}
          />
        )
      case DetailFileSearchOptions.AnonymousFile.id:
        return (
          <CrewSelectBox
            id={DetailFileSearchOptions.AnonymousFile.id}
            name={DetailFileSearchOptions.AnonymousFile.name}
            displayExpr="name"
            valueExpr="id"
            dataSource={anonymousFileDataSource}
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleAnonymousFileChanged}
            searchEnabled={false}
          />
        )

      default:
        // assertNever has value type never, so we need to cast filter.field to never
        assertNever(filter.field as never, 'Invalid filter field')
    }
  }
)
