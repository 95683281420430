import { useNavigate } from 'react-router-dom'
import qs from 'qs'
import { useCallback } from 'react'

// Crew内で使用するNavigate用hook
export const useCrewNavigate = () => {
  const navigate = useNavigate()

  // react-router-domのnavigate関数ラッパー
  const navigateWrapper = useCallback(
    (pathname: string, params?: any, replace?: boolean) =>
      navigate({
        pathname,
        search:
          params &&
          `?${qs.stringify(params, {
            // encodeValuesOnly: true,
            arrayFormat: 'repeat',
          })}`,
      },
        { replace: replace ?? false }),
    [navigate]
  )

  // ヒストリーバックを行うための関数
  const historyBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return {
    navigate: navigateWrapper,
    historyBack,
  }
}
