import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { FileDetailTab } from 'features/file/components/fileDetailPage/components/fileDetailTab/fileDetailTab'
import { FileDetailPanel } from 'features/file/components/fileDetailPage/components/fileDetailPanel/fileDetailPanel'
import { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { FileDetailListTabs } from 'enums/app'
import { FileDetailPreview } from 'features/file/components/fileDetailPage/components/fileDetailPreview/fileDetailPreview'
import { useFileDetailPage } from './useFileDetailPage'
import { CrewFileUploader } from 'components/devextreme/crewFileUploader/crewFileUploader'
import { FileUploadConfirmDialog } from 'features/file/components/fileUploadConfirmDialog/fileUploadConfirmDialog'
import { FileHistoryTable } from './components/fileDetailUpdatedHistory/components/fileHistoryTable/fileHistoryTable'
import { useGetChatRoomByEntityQuery } from '@crew/apis/chat/chatApis'
import { GetFileRequest } from '@crew/apis/dist/file/models/getFile/request'
import { useGetFileQuery } from '@crew/apis/file/fileApis'
import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { useChatCurrentService } from '@crew/states'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { UploadFile } from 'models/domain/uploadFile'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import type { FormValues } from '../fileUploadConfirmDialog/components/fileUploadConfirmForm/useFileUploadConfirmForm'
import { useUnmount } from '@dx-system/react-use'

export const FileDetailPage = memo(() => {
  const { updateFile } = useFileDetailPage()

  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { fileId } = useParams()

  // Sliceの操作を行うためのServiceを取得
  const chatCurrentService = useChatCurrentService(dispatch)

  const [generalShowApiErrors] = useShowApiErrors()

  const fileEventMessage = useAppSelector((state) => state.app.fileEventMessage)

  // ファイル詳細の取得（アップロード確認ダイアログからの改版に使用）
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getFileParam: GetFileRequest | undefined = fileId
    ? {
        fileId,
      }
    : undefined
  const { data: getFileResult, refetch: getFileRefetch } = useGetFileQuery(
    getFileParam ?? skipToken
  )

  // アップロード済みのファイル一覧
  const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])

  // refresh data file
  useEffect(() => {
    fileId && getFileRefetch()
  }, [fileEventMessage, fileId, getFileRefetch])

  const [
    isFileUploadConfirmDialogOpen,
    openFileUploadConfirmDialog,
    closeFileUploadConfirmDialog,
  ] = useModal()

  // アップロード完了後
  const handleUploaded = useCallback(
    (file: UploadFile) => {
      // アップロードしたファイル情報を配列に格納
      setUploadedFileList((baseData) => {
        const index = baseData.findIndex((item) => item.name === file.name)
        if (index === -1) {
          return [...baseData, file]
        } else {
          // replace the file with the same name
          baseData[index] = file
          return [...baseData]
        }
      })

      // 確認ダイアログを表示
      openFileUploadConfirmDialog()
    },
    [openFileUploadConfirmDialog]
  )

  // アップロードファイル確認ダイアログのキャンセル時
  const handleCancel = useCallback(() => {
    // アップロード済ファイル情報のクリア
    setUploadedFileList([])

    // ダイアログをクローズ
    closeFileUploadConfirmDialog()
  }, [closeFileUploadConfirmDialog])

  // アップロードファイル確認ダイアログの登録ボタン押下後の更新処理（改版）
  const handleSubmit = useCallback(
    async (data: FormValues) => {
      try {
        // NOTE: アップロードしたファイル情報はuploadedFileListから取得
        if (fileId && getFileResult?.file) {
          //In case of file update, only 1 file can be updated, so index 0
          const uploadFile = uploadedFileList[0]

          await updateFile(
            getFileResult.file,
            data.description,
            data.needNotification,
            uploadFile
          )

          toast.success(t('message.file.fileUpdated'))
        }

        // アップロード済ファイル情報のクリア
        setUploadedFileList([])

        // ダイアログをクローズ
        closeFileUploadConfirmDialog()
      } catch (err) {
        generalShowApiErrors(err)
      }
    },
    [
      closeFileUploadConfirmDialog,
      fileId,
      generalShowApiErrors,
      getFileResult?.file,
      t,
      toast,
      updateFile,
      uploadedFileList,
    ]
  )

  // ファイルに紐づくチャットルームIDを取得する
  const { data: getChatRoomResponse } = useGetChatRoomByEntityQuery({
    entityType: EntityType.File,
    entityRecordId: fileId as string,
  })

  // 取得したチャットルームIDに紐づくチャットメッセージを取得する
  useEffect(
    () => {
      if (getChatRoomResponse?.chatRoom && fileId) {
        // 表示するチャットルームを設定する
        chatCurrentService.setCurrentChatRoomAndRestoreThread({
          id: getChatRoomResponse.chatRoom.id,
          entityType: EntityType.File,
          entityRecordId: fileId,
        })
      }
    },
    // マウント時のみ値を格納とするので依存は不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getChatRoomResponse?.chatRoom?.id]
  )

  // アンマウント時、現在のチャットルーム情報をリセット
  useUnmount(() => {
    chatCurrentService.resetCurrentChatRoom()
  })

  // アップロードエラー時
  const handleUploadError = useCallback(
    (file: UploadFile) => {
      // uploadedFileListに格納している該当ファイル情報を削除する
      setUploadedFileList((baseData) =>
        baseData.filter((item) => item.name !== file.name)
      )

      // アップロード済ファイル情報が0件の場合、確認ダイアログをクローズ
      if (uploadedFileList.length === 0) {
        closeFileUploadConfirmDialog()
      }
    },
    [closeFileUploadConfirmDialog, uploadedFileList.length]
  )

  return (
    <div className="flex flex-1 gap-2 grow min-h-0">
      <main className="grow flex flex-col h-full w-2/3 overflow-y-auto">
        <CrewErrorBoundary>
          <div className="flex flex-col">
            <FileDetailPanel />
          </div>
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <div className="min-h-0 flex-1 flex flex-col grow w-full gap-y-2.5">
            {/* ファイル詳細タブ */}
            <div className="px-2">
              <FileDetailTab />
            </div>
            <div className="grow min-h-0 overflow-y-auto">
              <CrewFileUploader
                onUploaded={handleUploaded}
                onUploadError={handleUploadError}
                isCancelUpload={!isFileUploadConfirmDialogOpen}
              >
                {/* URLに応じて表示するコンポーネントを切り替える */}
                <Routes>
                  {/* 
                    TODO: 一時的にデフォルトルート（index）を除去している
                    以下タスク対応時にデフォルトルートを設定する予定
                    https://break-tmc.atlassian.net/browse/CREW-9163
                  */}
                  <Route
                    path={FileDetailListTabs.UpdatedHistory.value}
                    element={<FileHistoryTable />}
                  />
                  <Route
                    path={FileDetailListTabs.Preview.value}
                    element={<FileDetailPreview />}
                  />
                </Routes>
              </CrewFileUploader>
            </div>

            <FileUploadConfirmDialog
              title={t('label.fileUpload')}
              isOpen={isFileUploadConfirmDialogOpen}
              onClose={handleCancel}
              onSubmit={handleSubmit}
              uploadFiles={uploadedFileList}
            />
          </div>
        </CrewErrorBoundary>
      </main>
    </div>
  )
})
