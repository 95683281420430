import React from 'react'
import { createRoot } from 'react-dom/client'

import { configureApiBaseUrl } from '@crew/configs/constants'
import '@crew/modules/i18n'

import { App } from './features/app/app'

import './index.css'
import * as serviceWorker from './serviceWorker'
import './tailwind.css'
//import './initModules'

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'yet-another-react-lightbox/plugins/captions.css'

import config from 'devextreme/core/config'
import { licenseKey } from './devextreme-license'

// Mock Service Worker（開発用）
if (process.env.REACT_APP_USE_MSW === 'true') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

config({ licenseKey })

// URLからテナントIDとそれ以外に分割して、テナントIDをサブドメインとしてAPIのベースURLに組み込む
const subDomains = window.location.host.split('.', 2)
// サブドメインが含まれていない場合は空文字を設定
const tenantId = subDomains.length > 1 ? subDomains[0] : ''
configureApiBaseUrl(tenantId)

const container = document.getElementById('root')
if (!container) throw new Error('root element not found')
const root = createRoot(container)
root.render(
  // TODO: CREW-9326 エラーが解消出来たらONにする
  // https://break-tmc.atlassian.net/browse/CREW-9326
  //  <React.StrictMode>
  <App />
  //  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
