import { ProjectType } from '@crew/enums/domain';
export const IsDirectChannel = (v) => {
    if (typeof v !== 'object' || v === null)
        return false;
    const m = v;
    // TODO:厳密化する
    // https://break-tmc.atlassian.net/browse/CREW-4249
    if (typeof m.id !== 'string')
        return false;
    if (m.projectType !== ProjectType.DirectChannel)
        return false;
    return true;
};
