import MoreHoriz from '~icons/material-symbols/more-horiz'
import FolderOutline from '~icons/material-symbols/folder-outline'
import { FC, memo, useCallback, useState } from 'react'
import { UnsortedFolder } from 'enums/app'
import { CrewSortable } from 'components/devextreme/crewSortable'
import { useFolderItem } from './useFolderItem'
import { AddEvent, DragEndEvent, DragStartEvent } from 'devextreme/ui/sortable'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/dist/i18n'

type Folder = {
  id: string
  name: string
  version: number
}

type FolderItemProps = {
  folder: Folder
  onOpenFolderEntryDialog: () => void
  onOpenContextMenu: (folder: Folder) => void
  onDragEnd: (event: DragEndEvent) => void
  showContextMenu?: boolean
  disableDragAndDrop?: boolean
}

export const FolderItem: FC<FolderItemProps> = memo((props) => {
  const { t } = useTranslation()
  const { success, warn } = useToast()

  const { moveFileToFolder } = useFolderItem()

  const [showApiErrors] = useShowApiErrors()

  const [hover, setHover] = useState(false)

  // ホバーメニューを表示
  const handleMouseEnter = useCallback(() => {
    setHover(true)
  }, [])

  // ホバーメニューを非表示
  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  // Event handler for opening the context menu
  const handleOpenContextMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      props.onOpenContextMenu(props.folder)

      // stop parent event
      event.stopPropagation()
    },
    [props]
  )

  // Event handle for moving a file to a folder
  const handleMoveFileToFolder = useCallback(
    async (event: AddEvent) => {
      try {
        // Prevent moving to the same folder
        if (
          event.toData.id === event.fromData.folderId ||
          (event.toData.id === UnsortedFolder.value && !event.fromData.folderId)
        )
          return

        const result = await moveFileToFolder(
          props.folder.id,
          event.fromData.id,
          event.fromData.version
        )

        if (result.duplicateFileNames && result.duplicateFileNames.length > 0) {
          warn(
            t('message.file.moveDuplicatedFiles', {
              duplicateFileNames: result.duplicateFileNames.join('\n'),
            })
          )
        } else {
          success(t('message.file.moveFilesSuccess'))
        }
      } catch (err) {
        showApiErrors(err)
      }
    },
    [moveFileToFolder, props.folder.id, showApiErrors, success, t, warn]
  )

  // Event handler for when a folder item is dragged
  const handleFolderDragStart = useCallback(
    (event: DragStartEvent) => {
      // Prevent moving the unsorted folder and disable drag and drop
      if (
        event.fromData.id === UnsortedFolder.value ||
        props.disableDragAndDrop
      ) {
        event.cancel = true
      }
    },
    [props.disableDragAndDrop]
  )

  // Event handler for when a folder is dropped
  const handleFolderDragEnd = useCallback(
    async (event: DragEndEvent) => {
      // Prevent onAdd event
      event.cancel = true

      props.onDragEnd(event)
    },
    [props]
  )

  return (
    <CrewSortable
      group="shared"
      data={props.folder}
      allowDropInsideItem={true}
      allowReordering={false}
      onAdd={handleMoveFileToFolder}
      onDragStart={handleFolderDragStart}
      onDragEnd={handleFolderDragEnd}
    >
      <div
        // Add padding right to the folder name for the context menu button
        className="flex gap-1.5 w-full items-center relative pr-6 py-1.5"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FolderOutline width={24} height={24} className="shrink-0" />
        <span className="line-clamp-1"> {props.folder.name}</span>

        {/* Menu context button */}
        {props.folder.id !== UnsortedFolder.value &&
          hover &&
          props.showContextMenu && (
            <span
              className="ml-auto absolute right-0"
              id={`context-menu-${props.folder.id}`}
              onClick={handleOpenContextMenu}
            >
              <MoreHoriz width={20} height={20} />
            </span>
          )}
      </div>
    </CrewSortable>
  )
})
