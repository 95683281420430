import { useTranslation } from '@crew/modules/dist/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import useToggleLocalMute from 'modules/amazon-chime-sdk-component-library-devextreme/hooks/sdk/useToggleLocalMute'
import { FC, memo } from 'react'
import Mic from '~icons/material-symbols/mic'
import MicOff from '~icons/material-symbols/mic-off'

// マイクのミュート切り替えボタン
export const CrewMicrophoneMuteToggleButton: FC = memo(() => {
  const { muted, toggleMute } = useToggleLocalMute()
  const { t } = useTranslation()

  return (
    <CrewButton
      onClick={toggleMute}
      text={t('label.microphone')}
      type={muted ? 'danger' : 'normal'}
      stylingMode={muted ? 'contained' : 'outlined'}
      icon={
        muted ? (
          <MicOff width={24} height={24} />
        ) : (
          <Mic width={24} height={24} />
        )
      }
    />
  )
})
