import { useCallback } from 'react'
import { NotifyEventType } from 'enums/app'
import { useTenantSettingUserStateFilterDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useAppDispatch } from 'states/hooks'
import {
  ObjectEventMessage,
  notifyTenantSettingUserEventMessageEvent,
} from 'features/app/states/appSlice'
import { TenantSettingMember } from '@crew/apis/dist/tenantSetting/models/getTenantSettingMembers/response'
import { useDeleteUserPendingsInvitationMutation } from '@crew/apis/dist/tenantSetting/tenantSettingApis'
import { SelectedUserType } from '../../useUserList'

export const useUserListToolbar = () => {
  const dispatch = useAppDispatch()

  const stateFilterDataSource = useTenantSettingUserStateFilterDataSource()

  // Get function for delete pending users
  const [deleteUserPendingsInvitationMutation] =
    useDeleteUserPendingsInvitationMutation()

  // Delete pending users process
  const deletePendingUsers = useCallback(
    async (selectedUsers: SelectedUserType[]) => {
      // Call Api cancel member
      await deleteUserPendingsInvitationMutation({
        userPendingIds: selectedUsers.map((user) => user.userId),
      }).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TenantSettingMember> = {
        eventType: NotifyEventType.Deleted,
        id: '',
        object: undefined,
      }

      //trigger event refresh tenant user list
      dispatch(notifyTenantSettingUserEventMessageEvent(objectEventMessage))
    },
    [deleteUserPendingsInvitationMutation, dispatch]
  )

  return {
    stateFilterDataSource,
    deletePendingUsers,
  }
}
