import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { memo, FC } from 'react'
import { FeedMessageList } from './components/feedMessageList/feedMessageList'

/**
 * 新着
 */
export const FeedPage: FC = memo(() => {
  return (
    <CrewErrorBoundary>
      <FeedMessageList />
    </CrewErrorBoundary>
  )
})
