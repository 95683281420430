// 引数で受け取った値の型をAttentionItemに確定させる
export const IsAttentionItem = (value) => {
    var _a;
    if (typeof value !== 'object' || value === null)
        return false;
    const partialAttentionItem = value;
    // TODO:厳密化する
    // https://break-tmc.atlassian.net/browse/CREW-4249
    if (typeof ((_a = partialAttentionItem.chatMessage) === null || _a === void 0 ? void 0 : _a.id) !== 'string')
        return false;
    return true;
};
