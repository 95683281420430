import { useCallback } from 'react'

import { useAsyncStateToValueConnector } from './useAsyncStateToValueConnector'

/**
 * Chime SDKの内部stateなど非同期的に更新されるstateと、DevExtremeのUIコンポーネントのvalueを接続するためのカスタムフック。stateとvalueの型が一致する場合に使用する。
 * 1. stateの更新があった場合はUIに即座に反映する
 * 1. 値更新イベントハンドラが呼ばれた場合は、UIに即座に反映し、非同期関数を呼び出す
 * 1. 非同期関数の内部で非同期にstateの更新を行う
 * 1. stateとUIを非同期に更新するため値が一致しないタイミングがあるので、その期間はisChangingAsyncStateがtrueになる
 * @param currentAsyncState 現在の非同期state値
 * @param asyncStateUpdater 非同期stateを更新する非同期関数
 * @returns UIコンポーネントに接続するためのvalueとevent callback
 */
export const useAsyncStateConnector = <TValue extends any>(
  currentAsyncState: TValue | undefined,
  asyncStateUpdater: (
    newAsyncState: TValue,
    currentAsyncState: TValue | undefined
  ) => Promise<void>
) => {
  const stateToValue = useCallback((param: any) => param, [])

  const { value, handleValueChanged, isChangingAsyncState } =
    useAsyncStateToValueConnector<TValue, TValue>(
      currentAsyncState,
      asyncStateUpdater,
      stateToValue
    )

  return { value, handleValueChanged, isChangingAsyncState }
}
