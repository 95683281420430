import { memo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { TaskKindEntryDialog } from '../taskKindEntryDialog/taskKindEntryDialog'

export const TaskKindListToolbar = memo(() => {
  const { t } = useTranslation()

  const [
    isTaskKindEntryDialogOpen,
    openTaskKindEntryDialog,
    closeTaskKindEntryDialog,
  ] = useModal()

  return (
    <>
      <div className="w-full flex">
        <div className="flex flex-1">
          <CrewButton
            type="primary"
            className="ml-auto"
            text={t('action.newTaskKind')}
            onClick={openTaskKindEntryDialog}
          />
        </div>
      </div>

      <TaskKindEntryDialog
        isEditMode={false}
        title={t('label.taskKindAdd')}
        isOpen={isTaskKindEntryDialogOpen}
        onClose={closeTaskKindEntryDialog}
      />
    </>
  )
})
