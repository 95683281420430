import type { EditorThemeClasses } from 'lexical'
import './fontStyleTheme.css'

export const FontStyleTheme: EditorThemeClasses = {
  text: {
    bold: 'CrewEditorTheme__textBold',
    code: 'CrewEditorTheme__textCode',
    italic: 'CrewEditorTheme__textItalic',
    strikethrough: 'CrewEditorTheme__textStrikethrough',
    underline: 'CrewEditorTheme__textUnderline',
    underlineStrikethrough: 'CrewEditorTheme__textUnderlineStrikethrough',
  },
  quote: 'CrewEditorTheme__quote',
  heading: {
    h1: 'CrewEditorTheme__h1',
    h2: 'CrewEditorTheme__h2',
    h3: 'CrewEditorTheme__h3',
  },
  code: 'CrewEditorTheme__code',
  codeHighlight: {
    atrule: 'CrewEditorTheme__tokenAttr',
    attr: 'CrewEditorTheme__tokenAttr',
    boolean: 'CrewEditorTheme__tokenProperty',
    builtin: 'CrewEditorTheme__tokenSelector',
    cdata: 'CrewEditorTheme__tokenComment',
    char: 'CrewEditorTheme__tokenSelector',
    class: 'CrewEditorTheme__tokenFunction',
    'class-name': 'CrewEditorTheme__tokenFunction',
    comment: 'CrewEditorTheme__tokenComment',
    constant: 'CrewEditorTheme__tokenProperty',
    deleted: 'CrewEditorTheme__tokenProperty',
    doctype: 'CrewEditorTheme__tokenComment',
    entity: 'CrewEditorTheme__tokenOperator',
    function: 'CrewEditorTheme__tokenFunction',
    important: 'CrewEditorTheme__tokenVariable',
    inserted: 'CrewEditorTheme__tokenSelector',
    keyword: 'CrewEditorTheme__tokenAttr',
    namespace: 'CrewEditorTheme__tokenVariable',
    number: 'CrewEditorTheme__tokenProperty',
    operator: 'CrewEditorTheme__tokenOperator',
    prolog: 'CrewEditorTheme__tokenComment',
    property: 'CrewEditorTheme__tokenProperty',
    punctuation: 'CrewEditorTheme__tokenPunctuation',
    regex: 'CrewEditorTheme__tokenVariable',
    selector: 'CrewEditorTheme__tokenSelector',
    string: 'CrewEditorTheme__tokenSelector',
    symbol: 'CrewEditorTheme__tokenProperty',
    tag: 'CrewEditorTheme__tokenProperty',
    url: 'CrewEditorTheme__tokenOperator',
    variable: 'CrewEditorTheme__tokenVariable',
  },
  link: 'CrewEditorTheme__link',
}
