import { apis } from '../apiBase/apiBase';
export const userApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        insertCustomScheduleMembers: builder.mutation({
            query: (params) => ({
                url: `custom-schedule-members/bulk-insert`,
                method: `POST`,
                body: params,
            }),
        }),
        deleteCustomScheduleMember: builder.mutation({
            query: (params) => ({
                url: `custom-schedule-members/delete`,
                method: `POST`,
                body: params,
            }),
        }),
        getCustomScheduleMembers: builder.query({
            query: () => ({
                url: `custom-schedule-members`,
                method: `GET`,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useInsertCustomScheduleMembersMutation, useDeleteCustomScheduleMemberMutation, useGetCustomScheduleMembersQuery, useLazyGetCustomScheduleMembersQuery, } = userApis;
