import classNames from 'classnames'
import { closeLeftSideBar } from 'features/app/states/appSlice'
import { FC, memo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { LeftSidePanel } from './components/leftSidePanel/leftSidePanel'

export const AppLeftSideBar: FC = memo(() => {
  const leftSideBarOpened = useAppSelector(
    (state) => state.app.leftSideBarOpened
  )

  const dispatch = useAppDispatch()

  const handleDrawerOverlayClick = () => {
    dispatch(closeLeftSideBar())
  }

  return (
    <>
      <div
        id="app-left-side-bar"
        data-testid="app-left-side-bar"
        className={classNames(
          // 画面縮小した際に、他要素の上に表示させるためにabsoluteとz-50を指定
          // サイドバーの幅を相対単位"vw"で制限し、サイドバーが正しく表示され、開閉できるようにする。
          'flex flex-col flex-none h-full w-[25vw] lg:w-[15vw] crew-text-gray-4 crew-bg-gray-1 z-50',
          'border-r crew-border-gray',
          'ease-in-out duration-300',
          'max-lg:absolute max-lg:top-0 max-lg:left-0',
          {
            'translate-x-0': leftSideBarOpened,
            '-translate-x-full': !leftSideBarOpened,
            'ml-[-25vw] lg:ml-[-15vw]': !leftSideBarOpened,
          }
        )}
      >
        <LeftSidePanel />
      </div>
      {leftSideBarOpened && (
        <div
          // 画面縮小した際に、左側のメニューの下かつ、他要素の上に表示させるためにz-40を指定
          className="hidden max-lg:flex max-lg:flex-row max-lg:flex-1 absolute h-full w-full z-40 bg-crew-gray-500/50"
          onClick={handleDrawerOverlayClick}
        />
      )}
    </>
  )
})
