// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Modifications copyright (C) 2023 TechnoMindCorp. / Y.K.
// SPDX-License-Identifier: Apache-2.0

import type { AudioInputDevice } from 'amazon-chime-sdk-js'
import React from 'react'

import { AudioInputProvider, useAudioInputs } from './AudioInputProvider'
import { AudioOutputProvider, useAudioOutputs } from './AudioOutputProvider'
import { useVideoInputs, VideoInputProvider } from './VideoInputProvider'

interface Props {
  onDeviceReplacement?: (
    nextDevice: string,
    currentDevice: AudioInputDevice | undefined
  ) => Promise<AudioInputDevice>
}

export const DevicesProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onDeviceReplacement,
}) => (
  <AudioInputProvider onDeviceReplacement={onDeviceReplacement}>
    <AudioOutputProvider>
      <VideoInputProvider>{children}</VideoInputProvider>
    </AudioOutputProvider>
  </AudioInputProvider>
)

export { useAudioInputs, useAudioOutputs, useVideoInputs }
