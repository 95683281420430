import {
  useLocalVideo,
  useVideoInputs,
} from 'modules/amazon-chime-sdk-component-library-devextreme'
import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import { useLogger } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/LoggerProvider'
import { FC, memo, useEffect, useRef } from 'react'

type Props = BaseSdkProps

export const CrewPreviewVideo: FC<Props> = memo((props) => {
  const logger = useLogger()
  const audioVideo = useAudioVideo()
  const { selectedDevice } = useVideoInputs()
  const videoEl = useRef<HTMLVideoElement>(null)
  const { isVideoEnabled } = useLocalVideo()

  useEffect(() => {
    const videoElement = videoEl.current
    return () => {
      if (videoElement) {
        audioVideo?.stopVideoPreviewForVideoInput(videoElement)
      }
    }
  }, [audioVideo])

  useEffect(() => {
    async function startPreview(): Promise<void> {
      if (!audioVideo || !selectedDevice || !videoEl.current) {
        return
      }

      try {
        audioVideo.startVideoPreviewForVideoInput(videoEl.current)
      } catch (error) {
        logger.error('Failed to start video preview')
      }
    }

    if (isVideoEnabled) {
      startPreview()
    }
  }, [audioVideo, isVideoEnabled, logger, selectedDevice])

  return <video className="h-auto static" {...props} ref={videoEl} />
})
