import { FC, memo, useMemo } from 'react'

import { CrewSelectBox } from 'components/devextreme/crewSelectBox'

import { useCrewBackgroundEffectSelectBox } from './useCrewBackgroundEffectSelectBox'
import { useAsyncStateConnector } from '../hooks/useAsyncStateConnector'

/**
 * 背景エフェクトの種類を選択するセレクトボックス
 */
export const CrewBackgroundEffectSelectBox: FC = memo(() => {
  const {
    backgroundEffect,
    selectBackgroundEffect,
    effectList,
    isBackgroundEffectSupported,
  } = useCrewBackgroundEffectSelectBox()

  const { value, handleValueChanged, isChangingAsyncState } =
    useAsyncStateConnector(backgroundEffect, selectBackgroundEffect)

  // エフェクト未サポートの場合と切り替え中は変更不可とする
  const disabled = useMemo(
    () => !isBackgroundEffectSupported || isChangingAsyncState,
    [isBackgroundEffectSupported, isChangingAsyncState]
  )

  return (
    <CrewSelectBox
      disabled={disabled}
      valueExpr="value"
      displayExpr="display"
      dataSource={effectList}
      value={value}
      onValueChanged={handleValueChanged}
      searchEnabled={false}
      showClearButton={false}
    />
  )
})
