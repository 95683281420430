import { Options } from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { useMemo } from 'react'

export const useDataSource: <TItem = any, TKey = any>(
  getParams: () => Options<TItem, TKey> & { paginate?: boolean },
  deps: React.DependencyList
) => DataSource<TItem, TKey> = (getParams, deps) => {
  return useMemo(
    () => new DataSource({ paginate: false, ...getParams() }),
    // マウント時のみ値を格納とするので依存は不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )
}
