import { useChatMessage } from '@crew/states'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useCallback, useState } from 'react'

export const useBookmarkMessageListItem = (chatMessageId: string) => {
  const dispatch = useAppDispatch()
  const { message, isError } = useChatMessage(
    chatMessageId,
    dispatch,
    useAppSelector
  )

  // アクションメニュー表示状態
  const [visibleActionMenu, setVisibleActionMenu] = useState<boolean>(false)

  // マウスホバー時にアクションメニューを表示する
  const showActionMenu = useCallback(() => {
    setVisibleActionMenu(true)
  }, [])

  // マウスが離れたらアクションメニューを非表示にする
  const hideActionMenu = useCallback(() => {
    setVisibleActionMenu(false)
  }, [])

  return {
    message,
    isError,
    visibleActionMenu,
    showActionMenu,
    hideActionMenu,
  }
}
