import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FC, memo, useCallback, useMemo } from 'react'

import BaselineDelete from '~icons/ic/baseline-delete'
import ArrowRight from '~icons/material-symbols/arrow-right'
import { FormValues, Worktime } from '../../useWorkingTimeEntryForm'
import { CrewTimePickerField } from 'components/forms/crewTimePickerField'
import { useTranslation } from '@crew/modules/i18n'
import { TaskInfo } from '../taskInfo/taskInfo'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { Control } from 'react-hook-form'

type WorkTimesFormItemProps = {
  task: Worktime
  control: Control<FormValues, any>
  index: number
  onValidateTimeRange: (formValues: FormValues, index: number) => boolean
  onValidateActualWorkTimes: (value: string) => boolean
  onValidateActualWorkTimesDuration: (
    value: string,
    formValues: FormValues,
    index: number
  ) => boolean
  onValidateRequiredWorkTimes: (
    value: string,
    formValues: FormValues,
    index: number
  ) => boolean
  onDeleteButtonClick: (index: number) => void
}

export const WorkTimesFormItem: FC<WorkTimesFormItemProps> = memo((props) => {
  const { t } = useTranslation()

  // Validation time range
  const validateTimeRange = useCallback(
    (formValues: FormValues) => {
      return props.onValidateTimeRange(formValues, props.index)
    },
    [props]
  )

  // Validation actual work times
  const validateActualWorkTimes = useCallback(
    (value: string) => {
      return props.onValidateActualWorkTimes(value)
    },
    [props]
  )

  // Validation actual work times duration
  const validateActualWorkTimesDuration = useCallback(
    (value: string, formValues: FormValues) => {
      return props.onValidateActualWorkTimesDuration(
        value,
        formValues,
        props.index
      )
    },
    [props]
  )

  // Validation required work times
  const validateRequiredWorkTimes = useCallback(
    (value: string, formValues: FormValues) => {
      return props.onValidateRequiredWorkTimes(value, formValues, props.index)
    },
    [props]
  )

  // Event handler for delete button click
  const handleDeleteButtonClick = useCallback(() => {
    props.onDeleteButtonClick(props.index)
  }, [props])

  const validateRules = useMemo(() => {
    return {
      startTime: {
        validate: {
          checkTime: (value: Date | null, formValues: FormValues) =>
            validateTimeRange(formValues) ||
            t('message.taskComment.invalidTimeRange'),
        },
      },
      endTime: {
        validate: {
          checkTime: (value: Date | null, formValues: FormValues) =>
            validateTimeRange(formValues) ||
            t('message.taskComment.invalidTimeRange'),
        },
      },
      actualWorkTimes: {
        validate: {
          format: (value: string) => {
            return (
              validateActualWorkTimes(value) ||
              t('message.task.invalidFormatTime')
            )
          },
          duration: (value: string, formValues: FormValues) =>
            validateActualWorkTimesDuration(value, formValues) ||
            t('message.taskComment.invalidActualWorkTimesDuration'),
          required: (value: string, formValues: FormValues) =>
            validateRequiredWorkTimes(value, formValues) ||
            t('message.general.required'),
        },
      },
    }
  }, [
    t,
    validateActualWorkTimes,
    validateActualWorkTimesDuration,
    validateRequiredWorkTimes,
    validateTimeRange,
  ])

  return (
    <div className="flex flex-row items-start py-1.5">
      {/* タスク */}
      <div className="px-1 flex-1">
        <TaskInfo
          subject={props.task.subject}
          assignToUser={props.task.assignToUser}
          project={props.task.project}
        />
      </div>

      {/* 開始時刻 */}
      <div className="px-1 w-24">
        <CrewTimePickerField
          id={`worktimes.${props.index}.startTime`}
          control={props.control}
          name={`worktimes.${props.index}.startTime`}
          className="w-full"
          showLabel={false}
          interval={30}
          rules={validateRules.startTime}
        />
      </div>

      {/* arrow */}
      <ArrowRight width={24} height={24} className="mt-1.5" />

      {/* 終了時刻 */}
      <div className="px-1 w-24">
        <CrewTimePickerField
          id={`worktimes.${props.index}.endTime`}
          control={props.control}
          name={`worktimes.${props.index}.endTime`}
          className="w-full"
          showLabel={false}
          interval={30}
          rules={validateRules.endTime}
        />
      </div>

      {/* 作業時間 */}
      <div className="px-1 w-28">
        <CrewTextBoxField
          id={`worktimes.${props.index}.actualWorkTimes`}
          control={props.control}
          name={`worktimes.${props.index}.actualWorkTimes`}
          className="w-full"
          showLabel={false}
          rules={validateRules.actualWorkTimes}
        />
      </div>

      {/* Action buttons */}
      <div className="px-1 w-8 flex items-center h-full">
        <CrewButton
          icon={<BaselineDelete width={20} height={20} />}
          stylingMode="text"
          size="xs"
          className="mt-1.5"
          onClick={handleDeleteButtonClick}
        />
      </div>
    </div>
  )
})
