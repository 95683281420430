import {
  useLazyGetLookupUserQuery,
  useLazyGetLookupUsersQuery,
} from '@crew/apis/lookup/lookupApis'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { useDataSource } from './useDataSource'
import { MAX_INITIAL_RECORDS } from 'configs/constants'
import { SelfKey, SelfName } from 'utils/filter'
import { useTranslation } from '@crew/modules/i18n'

export const useUserDataSource = (
  addMe?: boolean,
  excludeExternalUser?: boolean
) => {
  const { t } = useTranslation()
  const [lazyGetUsersQuery] = useLazyGetLookupUsersQuery()
  const [lazyGetUserQuery] = useLazyGetLookupUserQuery()

  return useDataSource(
    () => ({
      key: 'id',

      load: async (loadOptions) => {
        // search with mySelf
        const mySelf = addMe ? [{ id: SelfKey, displayName: t(SelfName) }] : []

        if (!loadOptions.searchValue && !loadOptions.filter) {
          const response = await lazyGetUsersQuery({
            keyword: loadOptions.searchValue,
            userId: undefined,
            limit: MAX_INITIAL_RECORDS,
            excludeExternalUser,
          }).unwrap()
          return [...mySelf, ...response.users]
        }

        if (loadOptions.searchValue) {
          // インクリメンタルサーチ
          const response = await lazyGetUsersQuery({
            keyword: loadOptions.searchValue,
            userId: undefined,
            limit: undefined,
            excludeExternalUser,
          }).unwrap()

          return response.users
        } else if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したユーザIDのフィルタリング
          const filteringUserIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetUsersQuery({
            keyword: undefined,
            userId: filteringUserIds,
          }).unwrap()

          return response.users
        }

        return mySelf
      },
      byKey: async (userId) => {
        if (userId === SelfKey) return { id: SelfKey, displayName: t(SelfName) }

        const response = await lazyGetUserQuery({ userId }).unwrap()
        return response.user
      },
    }),
    [addMe, t, lazyGetUsersQuery, excludeExternalUser, lazyGetUserQuery]
  )
}
