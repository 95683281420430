import { EntityType } from '@crew/enums/domain'
import {
  useLazyGetLookupFilterQuery,
  useLazyGetLookupFiltersQuery,
} from '@crew/apis/lookup/lookupApis'
import { useTranslation } from '@crew/modules/i18n'
import { useDataSource } from './useDataSource'
import { FilterType } from '@crew/enums/app'
import { FilterRef } from '@crew/models/refs'

type FilterData = FilterRef & {
  label: string
}

// constant for built-in filters
const FILTER_TYPE_BUILT_IN = 'built-in'

export const useFilterDataSource = (entityType: EntityType) => {
  const [lazyGetFiltersQuery] = useLazyGetLookupFiltersQuery()
  const [lazyGetFilterQuery] = useLazyGetLookupFilterQuery()

  const { t } = useTranslation()

  return useDataSource(
    () => ({
      key: 'id',
      load: async (): Promise<FilterData[]> => {
        const response = await lazyGetFiltersQuery({
          entityType: entityType,
        }).unwrap()

        const filters = response.filters
          .map((filter) => {
            return {
              ...filter,
              // Convert name of builtin filter to localized
              label:
                filter.filterType === FilterType.BuiltIn
                  ? t('label.' + filter.name)
                  : filter.name,
            }
          })
          .sort((a, b) => {
            // If b is "built-in" and a is not, b should come first
            if (
              a.filterType === FILTER_TYPE_BUILT_IN &&
              b.filterType !== FILTER_TYPE_BUILT_IN
            )
              return -1

            // If a is "built-in" and b is not, a should come first
            if (
              a.filterType !== FILTER_TYPE_BUILT_IN &&
              b.filterType === FILTER_TYPE_BUILT_IN
            )
              return 1

            // If both are "built-in", sort by name alphabetically
            if (
              a.filterType === FILTER_TYPE_BUILT_IN &&
              b.filterType === FILTER_TYPE_BUILT_IN
            ) {
              return a.name.localeCompare(b.name)
            }

            // If neither are "built-in", maintain original order
            return 0
          })

        return filters
      },
      byKey: async (selected: string) => {
        const response = await lazyGetFilterQuery({
          filterId: selected,
        }).unwrap()

        const filter = response.filter
        return {
          ...filter,
          // Convert name of builtin filter to localized
          label:
            filter?.filterType === FilterType.BuiltIn
              ? t('label.' + filter?.name)
              : filter?.name,
        }
      },
    }),
    [entityType, lazyGetFilterQuery, lazyGetFiltersQuery, t]
  )
}
