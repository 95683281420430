import { FC, memo } from 'react'
import { CrewNavTabs } from 'components/elements/crewNavTabs/crewNavTabs'
import { PersonalSettingTabs } from 'enums/app'
import { useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { CrewNavTabProps } from 'components/elements/crewNavTabs/components/crewNavTab'

/**
 * PersonalSettingTab user setting tab
 * @date 7/18/2023 - 10:55:08 AM
 *
 * @type {FC}
 */
export const PersonalSettingTab: FC = memo(() => {
  const { t } = useTranslation()

  const { navigate } = useCrewNavigate()

  // タブアイテム
  const displayTabItems: CrewNavTabProps[] = useMemo(() => {
    const items = Object.values(PersonalSettingTabs).map((item) => {
      return {
        onClick: () => {
          // タブに対応するURLへ遷移（相対パス指定）
          navigate(item.value)
        },
        to: item.value,
        text: t(item.text),
        icon: undefined,
      }
    })

    return items

    // props.tabsSelectionChangedが変更されることはないので依存から除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, navigate])

  return <CrewNavTabs tabItems={displayTabItems} />
})
