import { memo } from 'react'
import { TaskDetailCommentListToolbar } from './components/taskDetailCommentListToolbar/taskDetailCommentListToolbar'
import { TaskDetailCommentListPanel } from './components/taskDetailCommentListPanel/taskDetailCommentListPanel'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

export const TaskDetailCommentList = memo(() => {
  return (
    <>
      <CrewErrorBoundary>
        <TaskDetailCommentListToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <div className="h-full grow min-h-0 p-2">
          <TaskDetailCommentListPanel />
        </div>
      </CrewErrorBoundary>
    </>
  )
})
