import { memo, useCallback } from 'react'
import { ProjectDetailTaskListDataGrid } from './components/projectDetailTaskListDataGrid/projectDetailTaskListDataGrid'
import { ProjectDetailTaskListTimelineScheduler } from './components/projectDetailTaskListTimelineScheduler/projectDetailTaskListTimelineScheduler'
import { TaskDisplayModeButtonGroup } from 'enums/app'
import { ProjectDetailTaskListCalendar } from './components/projectDetailTaskListCalendar/projectDetailTaskListCalendar'
import { ProjectDetailTaskListKanban } from 'features/project/components/projectDetailPage/components/projectDetailTaskList/components/projectDetailTaskListPanel/components/projectDetailTaskListKanban/projectDetailTaskListKanban'
import { useAppSelector } from 'states/hooks'
import { ProjectDetailTaskListGantt } from './components/projectDetailTaskListGantt/projectDetailTaskListGantt'
import { ProjectDetailTaskListWBSEditor } from './components/projectDetailTaskListWBSEditor/projectDetailTaskListWBSEditor'
import { ProjectDetailTaskListEvm } from './components/projectDetailTaskListEvm/projectDetailTaskListEvm'

export const ProjectDetailTaskListPanel = memo(() => {
  const selectedTaskListDisplayMode = useAppSelector(
    (state) => state.projectDetail.selectedTaskListDisplayMode
  )

  const isShowEditWBS = useAppSelector(
    (state) => state.projectDetail.isShowEditWBS
  )

  // タスクリストの表示モードによって表示を切り替える
  const renderTaskList = useCallback(() => {
    switch (selectedTaskListDisplayMode) {
      case TaskDisplayModeButtonGroup.List.id:
        return (
          <div className="px-2 flex-1">
            <ProjectDetailTaskListDataGrid />
          </div>
        )
      case TaskDisplayModeButtonGroup.Kanban.id:
        return (
          <div className="px-2 flex-1">
            <ProjectDetailTaskListKanban />
          </div>
        )
      case TaskDisplayModeButtonGroup.Calendar.id:
        return (
          <div className="px-2 flex-1 pb-2.5">
            <ProjectDetailTaskListCalendar />
          </div>
        )
      case TaskDisplayModeButtonGroup.Timeline.id:
        return (
          <div className="px-2 flex-1">
            <ProjectDetailTaskListTimelineScheduler />
          </div>
        )
      case TaskDisplayModeButtonGroup.Gantt.id:
        return (
          <div className="px-2 flex-1 overflow-y-hidden pb-2.5">
            {isShowEditWBS ? (
              <ProjectDetailTaskListWBSEditor />
            ) : (
              <ProjectDetailTaskListGantt />
            )}
          </div>
        )
      case TaskDisplayModeButtonGroup.Evm.id:
        return (
          <div className="px-2 flex-1">
            <ProjectDetailTaskListEvm />
          </div>
        )
      default:
        return null
    }
  }, [isShowEditWBS, selectedTaskListDisplayMode])

  // task list
  return renderTaskList()
})
