import { UserSetting } from '@crew/apis/dist/setting/models/updateUserEmailAddress/request'
import {
  useSendConfirmationEmailToChangeEmailAddressMutation,
  useUpdateUserEmailAddressMutation,
} from '@crew/apis/setting/settingApis'
import { useTranslation } from '@crew/modules/i18n'
import { isValidEmailAddress } from '@crew/utils'
import { ValidateRules } from '@crew/utils/dist/form'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyMyAccountEvent,
} from 'features/app/states/appSlice'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'

export type FormValues = {
  emailAddress: string
  verificationCode: string
}
export const formInitialValues: FormValues = {
  emailAddress: '',
  verificationCode: '',
}

export const useChangeMailForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, getValues, reset } = useForm<FormValues>({
    // show first error in form when empty submit
    criteriaMode: 'firstError',
    defaultValues: formInitialValues,
  })

  // Get functions for send confirmation email and update user email address
  const [sendConfirmationEmailToChangeEmailAddress] =
    useSendConfirmationEmailToChangeEmailAddressMutation()
  const [updateUserEmailAddress] = useUpdateUserEmailAddressMutation()

  // Send confirmation email process
  const sendConfirmationEmail = useCallback(
    async (emailAddress: string) => {
      await sendConfirmationEmailToChangeEmailAddress({
        emailAddress: emailAddress,
      }).unwrap()
    },
    [sendConfirmationEmailToChangeEmailAddress]
  )

  // Update email address process
  const updateEmailAddress = useCallback(
    async (data: FormValues) => {
      const params = {
        emailAddress: data.emailAddress,
        verificationCode: data.verificationCode,
      }
      await updateUserEmailAddress({
        userSetting: params,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<UserSetting> = {
        eventType: NotifyEventType.Updated,
        id: '',
        object: params,
      }
      dispatch(notifyMyAccountEvent(objectEventMessage))
    },
    [dispatch, updateUserEmailAddress]
  )

  // Check valid email
  const checkValidEmailAddress = useCallback(() => {
    return isValidEmailAddress(getValues('emailAddress'))
  }, [getValues])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      emailAddress: {
        required: t('message.general.isRequired', {
          name: t('label.mailAddress'),
        }),
        validate: {
          checkValidEmailAddress: () =>
            checkValidEmailAddress() || t('message.general.invalidEmail'),
        },
      },
      verificationCode: {
        required: t('message.general.isRequired', {
          name: t('label.verificationCode'),
        }),
      },
    }),
    [t, checkValidEmailAddress]
  )

  return {
    control,
    handleSubmit,
    getValues,
    reset,
    validateRules,
    sendConfirmationEmail,
    updateEmailAddress,
  }
}
