import { EntityType, ProjectType } from '@crew/enums/domain'
import { useInsertTagMutation } from '@crew/apis/file/fileApis'
import { Tag } from '@crew/models/domain'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { useDataSource } from './useDataSource'
import { MAX_INITIAL_RECORDS } from 'configs/constants'
import { useLazyGetProjectTagsQuery } from '@crew/apis/project/projectApis'

export const useProjectTagDataSource = (
  entityType: EntityType,
  projectType: ProjectType | undefined,
  projectIds: string[] | undefined
) => {
  const [lazyGetProjectTagsQuery] = useLazyGetProjectTagsQuery()
  const [insertTagMutation] = useInsertTagMutation()

  const insertProjectTagDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!projectType && !projectIds) {
          return []
        }

        if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したタグIDのフィルタリング
          const filteringTagIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )
          const response = await lazyGetProjectTagsQuery({
            entityType,
            projectIds,
            projectType,
            name: undefined,
            tagIds: filteringTagIds,
            limit: MAX_INITIAL_RECORDS,
          }).unwrap()

          return response.projectTags
        } else {
          // インクリメンタルサーチ
          const response = await lazyGetProjectTagsQuery({
            entityType,
            projectIds,
            projectType,
            name: loadOptions.searchValue,
            tagIds: undefined,
            limit: MAX_INITIAL_RECORDS,
          }).unwrap()

          return response.projectTags
        }
      },
      insert: async (values: Tag) => {
        const tag = {
          entityType: EntityType.File,
          name: values.name,
        }
        // 入力されたタグ名称に該当するtagIdを取得
        const response = await insertTagMutation({ tag }).unwrap()
        if (!response.tag) {
          return []
        }

        // NOTE: insertの戻り値もany型
        const result: any = { id: response.tag.id, name: response.tag.name }

        return result
      },
    }),
    [
      entityType,
      insertTagMutation,
      lazyGetProjectTagsQuery,
      projectIds,
      projectType,
    ]
  )

  return {
    insertProjectTagDataSource,
  }
}
