import { memo } from 'react'
import { AuthorityListPanel } from '../authorityListPanel/authorityListPanel'
import { RoleType } from '@crew/enums/dist/domain'
import { Role } from '../../useAuthoritySettingPanel'

type Props = {
  newRole: Role | null
  onClearNewRole: () => void
  onDeleteTempRole?: (roleName: string) => void
}

export const AuthorityProjectSettingPanel = memo((props: Props) => {
  return (
    <AuthorityListPanel
      roleType={RoleType.Project}
      newRole={props.newRole}
      onClearNewRole={props.onClearNewRole}
      onDeleteTempRole={props.onDeleteTempRole}
    />
  )
})
