import { useTranslation } from '@crew/modules/i18n'
import { FC, memo } from 'react'

type TimeCellProps = {
  date: Date
  displayTime: boolean
}

// TODO: https://break-tmc.atlassian.net/browse/CREW-16760
// Web: Schedulerがカスタムコンポーネント化されていないのでCrewSchedulerとして実装し、既存のSchedulerを直接使用している箇所を差し替える
export const TimeCell: FC<TimeCellProps> = memo((props) => {
  const { t } = useTranslation()

  return props.displayTime ? (
    <div>
      {t('format.shorttime', {
        value: props.date,
      })}
    </div>
  ) : null
})
