import CustomStore, { Options } from 'devextreme/data/custom_store'
import { useMemo } from 'react'

// DevExtremeのDataGridで、既存のDataSourceを使用するとE1016のエラーが発生する
// https://js.devexpress.com/jQuery/Documentation/22_2/ApiReference/UI_Components/Errors_and_Warnings/#E1016
// そのため、以下を参考にして別途hookを用意する必要がある
// https://js.devexpress.com/React/Documentation/22_2/Guide/UI_Components/DataGrid/How_To/Bind_a_Lookup_Column_to_a_Custom_Data_Source/
export const useLookupDataSource: <TItem = any, TKey = any>(
  getParams: () => Options<TItem, TKey> & { paginate?: boolean },
  deps: React.DependencyList
) => CustomStore<TItem, TKey> = (getParams, deps) => {
  return useMemo(
    () => new CustomStore({ paginate: false, ...getParams() }),
    // マウント時のみ値を格納とするので依存は不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )
}
