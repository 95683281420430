import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { memo, useCallback } from 'react'
import { EventDetailTaskSearch } from '../eventDetailTaskSearch/eventDetailTaskSearch'
import { useModal } from 'components/layouts/modal/useModal'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@crew/modules/i18n'
import { getDefaultTabValue } from '@crew/utils/enum'
import { TaskDetailListTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'

export const EventDetailTaskListToolbar = memo(() => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { navigate } = useCrewNavigate()

  /** ダイアログ */
  // タスク登録ダイアログ
  const [
    isProjectDetailTaskEntryDialogOpen,
    openProjectDetailTaskEntryDialog,
    closeProjectDetailTaskEntryDialog,
  ] = useModal()

  const { hasPrjTaskCreatePermission } = useProjectPermissions(
    EntityType.Project,
    projectId
  )

  // タスク登録完了
  const handleTaskRegistered = useCallback(
    (taskId: string) => {
      // タスク詳細画面に遷移
      navigate(`/tasks/${taskId}/${getDefaultTabValue(TaskDetailListTabs)}`)

      //close project detail task entry dialog
      closeProjectDetailTaskEntryDialog()
    },
    [navigate, closeProjectDetailTaskEntryDialog]
  )

  return (
    <div className="p-2.5">
      {hasPrjTaskCreatePermission && (
        <div className="text-right pb-2.5">
          {/* 新規タスクボタン */}
          <CrewButton
            type="primary"
            className="ml-auto"
            onClick={openProjectDetailTaskEntryDialog}
            text={t('action.newTask')}
          />
        </div>
      )}

      {/* 検索条件 */}
      <EventDetailTaskSearch />

      {/* タスク登録ダイアログ */}
      <TaskEntryDialog
        isEditMode={false}
        title={t('label.addTaskTitle')}
        onSubmit={handleTaskRegistered}
        isOpen={isProjectDetailTaskEntryDialogOpen}
        onClose={closeProjectDetailTaskEntryDialog}
        projectId={projectId}
      />
    </div>
  )
})
