import classNames from 'classnames'
import { memo } from 'react'

/**
 * CrewRequireDot
 * @date 8/26/2023 - 11:20:19 AM
 *
 * @type {*}
 */
export const CrewRequireDot = memo(() => {
  return (
    <span
      className={classNames(
        'text-crew-red-3-light',
        'dark:text-crew-red-2-dark',
        'ml-1'
      )}
    >
      *
    </span>
  )
})
