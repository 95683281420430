import { CrewAccordion } from 'components/elements/crewAccordion/crewAccordion'
import { FC, memo } from 'react'
import { FavoriteProjectList } from './components/favoriteProjectList/favoriteProjectList'
import { useTranslation } from '@crew/modules/i18n'

// お気に入りプロジェクトを表示するパネル
export const FavoriteProjectPanel: FC = memo((props) => {
  const { t } = useTranslation()

  return (
    <CrewAccordion
      title={t('label.project')}
      content={<FavoriteProjectList />}
    />
  )
})
