import { FC, memo } from 'react'

import { useChatThreadPanel } from './useChatThreadPanel'
import { RightSideBarDisplayType } from 'enums/app'
import { ChatThread } from '../chatThread/chatThread'

export type ChatThreadPanelProps = {
  displayType: RightSideBarDisplayType
}

export const ChatThreadPanel: FC<ChatThreadPanelProps> = memo((props) => {
  const { topicMessage, canAccessChatRoom } = useChatThreadPanel(
    props.displayType
  )

  // トピックが存在しない、またはチャットルームにアクセス権限がない場合は何も表示しない
  if (!topicMessage || !canAccessChatRoom) {
    return null
  }

  return (
    <div className="flex flex-col w-full h-full gap-1 overflow-hidden">
      <ChatThread />
    </div>
  )
})
