import { LocalStorageKeys } from 'enums/system'
import { useCallback } from 'react'

import { resetReduxState } from 'states/store'

/**
 * フロントエンドをログアウト状態にするカスタムフック
 * @returns フロントエンドをログアウト状態にする関数。バックエンドのログアウトは別途行う事。
 */
export const useLogoutFrontend = () => {
  const logout = useCallback(() => {
    // Remove logged in user information from local storage
    localStorage.removeItem(LocalStorageKeys.LoggedInUser)
    // reduxを初期化
    resetReduxState()
  }, [])

  return logout
}
