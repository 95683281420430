import {
  useLazyGetFileHistoryS3SignedUrlQuery,
  useLazyGetFileS3SignedUrlQuery,
  useLazyGetFileHistoryS3SignedUrlForThumbnailQuery,
  useLazyGetFileS3SignedUrlForThumbnailQuery,
  useLazyGetS3SignedUrlForOfficePreviewFileQuery,
} from '@crew/apis/file/fileApis'
import { useCallback } from 'react'

// TODO: web側とmobile側で同じ内容となっているので、以下タスクで共通化する
// https://break-tmc.atlassian.net/browse/CREW-14486
export const useFileSignedUrl = () => {
  // ファイル実体取得用
  const [lazyGetFileS3SignedUrlQuery] = useLazyGetFileS3SignedUrlQuery()
  const [lazyGetFileHistoryS3SignedUrlQuery] =
    useLazyGetFileHistoryS3SignedUrlQuery()

  // サムネイル取得用
  const [lazyGetFileS3SignedUrlForThumbnailQuery] =
    useLazyGetFileS3SignedUrlForThumbnailQuery()
  const [lazyGetFileHistoryS3SignedUrlForThumbnailQuery] =
    useLazyGetFileHistoryS3SignedUrlForThumbnailQuery()

  const [lazyGetS3SignedUrlForOfficePreviewFileQuery] =
    useLazyGetS3SignedUrlForOfficePreviewFileQuery()

  // ファイルの署名付きURLを取得
  const getFileSignedUrl = useCallback(
    async (fileId: string) => {
      const result = await lazyGetFileS3SignedUrlQuery({ fileId }).unwrap()

      return result.url ?? ''
    },
    [lazyGetFileS3SignedUrlQuery]
  )

  // ファイル履歴の署名付きURLを取得
  const getFileHistorySignedUrl = useCallback(
    async (fileHistoryId: string) => {
      const result = await lazyGetFileHistoryS3SignedUrlQuery({
        fileHistoryId,
      }).unwrap()

      return result.url ?? ''
    },
    [lazyGetFileHistoryS3SignedUrlQuery]
  )

  // ファイルサムネイルの署名付きURLを取得
  const getFileSignedUrlForThumbnail = useCallback(
    async (fileId: string) => {
      const result = await lazyGetFileS3SignedUrlForThumbnailQuery({
        fileId,
      }).unwrap()

      return result.url ?? ''
    },
    [lazyGetFileS3SignedUrlForThumbnailQuery]
  )

  // ファイル履歴サムネイルの署名付きURLを取得
  const getFileHistorySignedUrlForThumbnail = useCallback(
    async (fileHistoryId: string) => {
      const result = await lazyGetFileHistoryS3SignedUrlForThumbnailQuery({
        fileHistoryId,
      }).unwrap()

      return result.url ?? ''
    },
    [lazyGetFileHistoryS3SignedUrlForThumbnailQuery]
  )

  // Get signed url of file preview for office file
  const getFileSignedUrlForOfficePreview = useCallback(
    async (fileId: string) => {
      const result = await lazyGetS3SignedUrlForOfficePreviewFileQuery({
        fileId,
      }).unwrap()

      return result.url ?? ''
    },
    [lazyGetS3SignedUrlForOfficePreviewFileQuery]
  )

  return {
    getFileSignedUrl,
    getFileHistorySignedUrl,
    getFileSignedUrlForThumbnail,
    getFileHistorySignedUrlForThumbnail,
    getFileSignedUrlForOfficePreview,
  }
}
