import { useCallback } from 'react'
import { useUploadMeetingBackgroundMutation } from '@crew/apis/meeting/meetingApis'

export const useCrewBackgroundEffectFileInput = () => {
  const [uploadMeetingBackgroundMutation] = useUploadMeetingBackgroundMutation()

  // ファイルをアップロード
  const uploadBackgroundImage = useCallback(
    async (file: File) => {
      await uploadMeetingBackgroundMutation({ file })
    },
    [uploadMeetingBackgroundMutation]
  )

  return {
    uploadBackgroundImage,
  }
}
