import { FC, memo, useCallback, useState } from 'react'
import { FileUploader } from 'devextreme-react'
import { useCrewBackgroundEffectFileInput } from './useCrewBackgroundEffectFileInput'
import { ValueChangedEvent } from 'devextreme/ui/file_uploader'
import { useToast } from 'hooks/useToast'
import { useBackgroundEffect } from '../backgroundEffectProvider'
import { useTranslation } from '@crew/modules/i18n'
import { isValidMeetingBackgroundFile } from '@crew/utils/file'

/**
 * 背景エフェクトの画像ファイルを選択するファイルインプット
 */
export const CrewBackgroundEffectFileInput: FC = memo(() => {
  const { uploadBackgroundImage } = useCrewBackgroundEffectFileInput()

  const { t } = useTranslation()
  const { error } = useToast()

  const [backgroundFileName, setBackgroundFileName] = useState('')
  const { isBackgroundEffectSupported, loadBackgroundImage } =
    useBackgroundEffect()

  // 背景ファイル選択後
  const handleBackgroundFileValueChanged = useCallback(
    async (e: ValueChangedEvent) => {
      if (!e.value || e.value.length === 0) {
        return
      }

      // NOTE: e.value[0]がファイル情報
      //       https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxFileUploader/Configuration/#onValueChanged
      const file = e.value[0]

      if (!isValidMeetingBackgroundFile(file.name)) {
        error(t('message.file.invalidFileExtension'))
        return
      }

      try {
        // Upload background image file
        await uploadBackgroundImage(file)

        setBackgroundFileName(file.name)

        // Load latest background image
        loadBackgroundImage()
      } catch (err) {
        error(t('message.file.uploadFailed'))
      }
    },
    [error, loadBackgroundImage, t, uploadBackgroundImage]
  )

  return (
    <div className="flex items-center justify-between">
      <div className="flex-1 truncate">{backgroundFileName}</div>
      <FileUploader
        selectButtonText={t('label.selectFile')}
        showFileList={false}
        labelText=""
        accept="image/*"
        uploadMode="useForm"
        width={160}
        height={65}
        onValueChanged={handleBackgroundFileValueChanged}
        disabled={!isBackgroundEffectSupported} // エフェクト未サポートの場合は変更不可とする
      />
    </div>
  )
})
