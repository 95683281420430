import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from 'lexical'

export type UploadFilePayload = Readonly<{
  file: File
}>
export const UPLOAD_FILE_COMMAND: LexicalCommand<UploadFilePayload> =
  createCommand('UPLOAD_FILE_COMMAND')

// 以下PlaygroundソースのImagesPluginを参考に実装した
// https://github.com/facebook/lexical/tree/main/packages/lexical-playground

type FileUploadPluginProps = {
  uploadFile?: (file: File) => void // ファイルアップロード処理をpropsとして受け取る
  fileUploaderDisabled?: boolean
}

const FileUploadPlugin = (props: FileUploadPluginProps) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<UploadFilePayload>(
        UPLOAD_FILE_COMMAND,
        (payload) => {
          // ファイルアップロード禁止の場合は処理しない
          if (props.fileUploaderDisabled) {
            return false
          }

          // アップロード処理を実行
          const uploadTargetFile = payload.file
          props.uploadFile?.(uploadTargetFile)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor, props])

  return null
}

export default FileUploadPlugin
