import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { FC, memo } from 'react'
import { useCrewSpeakerSelectBox } from './useCrewSpeakerSelectBox'
import { useAsyncStateConnector } from '../hooks/useAsyncStateConnector'

type Props = BaseSdkProps

export const CrewSpeakerSelectBox: FC<Props> = memo(() => {
  const { deviceList, selectedDevice, updateAudioOutputDeviceAsync } =
    useCrewSpeakerSelectBox()

  // TODO: AudioOutputのみnullを許容する。useAsyncStateConnector側にnullを許容するよう変更すると
  //       Video関連で変更が多発するため、AudioOutput側でnullをundefinedに変換して使用するようにする。
  const { value, handleValueChanged, isChangingAsyncState } =
    useAsyncStateConnector(
      selectedDevice || undefined,
      updateAudioOutputDeviceAsync
    )

  return (
    <CrewSelectBox
      disabled={isChangingAsyncState} // 変更中は使用不可
      dataSource={deviceList}
      displayExpr="name"
      valueExpr="id"
      value={value}
      showClearButton={false}
      searchEnabled={false}
      onValueChanged={handleValueChanged}
    />
  )
})
