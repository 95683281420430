import {
  ComponentProps,
  forwardRef,
  memo,
  useImperativeHandle,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react'
import type { MutableRefObject } from 'react'

import {
  CLEAR_EDITOR_COMMAND,
  Klass,
  LexicalEditor,
  LexicalNode,
  TextNode,
  KEY_ENTER_COMMAND,
  COMMAND_PRIORITY_LOW,
} from 'lexical'
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer'
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'

import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { HashtagNode } from '@lexical/hashtag'
import { ListItemNode, ListNode } from '@lexical/list'
import { MarkNode } from '@lexical/mark'
import { OverflowNode } from '@lexical/overflow'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'

import classNames from 'classnames'

import { ImportDefaultValuePlugin as ImportDefaultHtmlPlugin } from './plugins/importDefaultValuePlugin'
import {
  OnChangeHtmlPlugin,
  ValueChangeEvent,
} from './plugins/onChangeHtmlPlugin'
import { ToolbarPluginTop, ToolbarPluginBottom } from './plugins/toolbarPlugin'

import { PlaceHolder } from './components/placeholder'
import { MentionNode } from './nodes/mentionNode'
import { MentionsPlugin } from './plugins/mentionsPlugin'
import FileUploadPlugin from './plugins/fileUploadPlugin'
import DragDropPastePlugin from './plugins/dragDropPastePlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'

// TODO: tailwindで書き直す予定
// https://break-tmc.atlassian.net/browse/CREW-6748
import './index.css'
import { ListTheme } from './plugins/listPlugin'
import { TableContext, TableTheme } from './plugins/tablePlugin'
import TableCellActionMenuPlugin from './plugins/tableActionMenuPlugin'
import { FontStyleTheme } from './theme/fontStyleTheme'
import { EmojiNode } from './nodes/emojiNode'
import { EmojiPickerPlugin } from './plugins/emojiPickerPlugin'
import { EmojiPlugin } from './plugins/emojiPlugin'
import { ExtendedTextNode } from './nodes/extendedTextNode'

import { CrewFileUploadDropZone } from '../../devextreme/crewFileUploader/components/crewFileUploadDropZone'
import { useDropzone } from 'react-dropzone'
import { UploadFile } from 'models/domain/uploadFile'
import { CrewUploadedFileList } from '../../devextreme/crewFileUploader/components/crewUploadedFileList'
import { useCrewFileUpload } from '../../devextreme/crewFileUploader/hooks/useCrewFileUpload'
import CodeHighlightPlugin from './plugins/codeHighlightPlugin'
import TableCellResizer from './plugins/tableCellResizer'
import { EntityType } from '@crew/enums/domain'
import { isImageFile } from '@crew/utils/dist/chat'
import LinkPlugin from './plugins/linkPlugin'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { LinkEditorPlugin } from './plugins/linkEditorPlugin/linkEditorPlugin'
import { AutoLinkEditorPlugin } from './plugins/autoLinkEditorPlugin/autoLinkEditorPlugin'
import { LinkCardPlugin } from './plugins/linkCardPlugin/linkCardPlugin'
import { LinkCardEditorPlugin } from './plugins/linkCardEditorPlugin/linkCardEditorPlugin'
import { LinkCardNode } from './plugins/linkCardNode/linkCardNode'
import { CustomAutoLinkPlugin } from './plugins/autoLinkPlugin/autoLinkPlugin'

export const CAN_USE_DOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'

/**
 * Lexicalで利用するノードを全て列挙する
 */
const UsingNodes: Array<
  | Klass<LexicalNode>
  | {
      replace: typeof TextNode
      with: (node: TextNode) => ExtendedTextNode
      withKlass: typeof ExtendedTextNode
    }
> = [
  // TextNodeはプレーンテキストを含むLexicalの基本のノードとなっており、
  // リッチテキストを扱う場合は拡張ノードを作成するよう公式で案内がある。以下URLを参考にしている。
  // https://lexical.dev/docs/concepts/serialization#handling-extended-html-styling
  ExtendedTextNode,
  {
    replace: TextNode,
    with: (node: TextNode) => new ExtendedTextNode(node.__text),
    withKlass: ExtendedTextNode,
  },
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  HashtagNode,
  CodeHighlightNode,
  OverflowNode,
  HorizontalRuleNode,
  MarkNode,
  EmojiNode,
  MentionNode,
  LinkNode,
  AutoLinkNode,
  LinkCardNode,
]

/**
 *  Lexicalのエラーハンドラ。例外をthrowしない=回復をLexicalに任せる
 * @param error
 */
const onError: InitialConfigType['onError'] = (error) => {
  console.error(error)
}

type Props = ComponentProps<'div'> & {
  entityType?: EntityType
  entityRecordId?: string
  isValid?: boolean
  defaultValue?: string
  valueChangeEvent?: ValueChangeEvent
  onValueChange?: (value: string) => void
  onMentionTargetChange?: (mentionTargetId: string[]) => void
  onSubmit?: () => void
  fileUploaderDisabled?: boolean
  /**
   * 右下に差し込みで表示するコンポーネント。送信ボタン系を想定
   */
  appendButtons?: React.ReactNode
  disabledMention?: boolean
  // エディタの最小の高さ
  minHeight?: string
  /**
   * 呼び出し元から渡されるファイル情報一覧
   */
  uploadedFileList?: UploadFile[]
  /**
   * 呼び出し元からの渡される削除イベントハンドラ
   */
  onDeleteUploadedFile?: (file: UploadFile) => void
  /**
   * 呼び出し元からの渡されるアップロードイベントハンドラ
   */
  onUploaded?: (file: UploadFile) => void
}

export type CrewHtmlEditor = {
  focus: () => void
  clear: () => void
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrewHtmlEditor = memo(
  forwardRef<CrewHtmlEditor, Props>(
    (
      {
        entityType,
        entityRecordId,
        isValid = true,
        defaultValue: defaultHtml,
        valueChangeEvent = ValueChangeEvent.change,
        onValueChange,
        onMentionTargetChange,
        onSubmit,
        className,
        fileUploaderDisabled,
        appendButtons,
        disabledMention,
        minHeight,
        uploadedFileList,
        onDeleteUploadedFile,
        onUploaded,
        ...rest
      },
      ref
    ) => {
      const initialConfig = {
        namespace: 'CrewHtmlEditor',
        onError,
        nodes: UsingNodes,
        theme: {
          list: ListTheme,
          ...TableTheme,
          ...FontStyleTheme,
        },
      }

      // 外部からrefで取得出来るCrewHtmlEditorコンポーネントへの参照に対して、特殊な操作を行うメソッドを追加する。
      // クラスコンポーネントのパブリックメソッドに相当する
      useImperativeHandle<CrewHtmlEditor, CrewHtmlEditor>(ref, () => ({
        // ref経由でlexicalのfocusを呼ぶ
        focus: () => lexicalEditorRef.current?.focus(),
        // ref経由でlexicalに対してクリアコマンドをdispatchする
        clear: () =>
          lexicalEditorRef.current?.dispatchCommand(
            CLEAR_EDITOR_COMMAND,
            undefined
          ),
      }))

      // フローティングエディタの親要素
      // createPortalのでフローティングエディタを表示しているため、DOMツリー上は親子になっていないが、表示位置の計算のためにRefを持つ
      const [contentContainerRef, setContentContainerRef] =
        useState<HTMLDivElement | null>(null)

      // 要素が存在するかどうかをチェック
      const onContentContainerRef = (_contentContainerRef: HTMLDivElement) => {
        if (_contentContainerRef !== null) {
          setContentContainerRef(_contentContainerRef)
        }
      }

      const lexicalEditorRef = useRef<LexicalEditor | null>(null)

      // Ctrl + EnterまたはAlt + Enterによる送信処理を登録
      useEffect(() => {
        return lexicalEditorRef.current?.registerCommand(
          KEY_ENTER_COMMAND,
          (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.altKey) && onSubmit) {
              onSubmit()
              return true
            }
            return false
          },
          COMMAND_PRIORITY_LOW
        )
      }, [onSubmit])

      const editorContainerRef = useRef<HTMLDivElement>(null)

      // ドロップ領域の表示制御
      const { getRootProps, isDragActive } = useDropzone({ noClick: true })

      // CrewFileUploaderにあるアップロード用カスタムフック
      const { uploadFile, abortControllers } = useCrewFileUpload()

      // ファイルアップロード実行処理
      const handleFileUpload = useCallback(
        (file: File) => {
          let previewUrl: string | undefined
          if (isImageFile(file.name)) {
            // create preview url
            previewUrl = URL.createObjectURL(file)
          }

          const uploadedFile: UploadFile = {
            name: file.name,
            size: file.size,
            keyName: '',
            previewUrl,
          }

          // アップロード処理自体はカスタムフック側で行い、呼び出し側で用意した後続処理をCallbackで渡す
          return uploadFile(
            file,
            // upload success
            (res) => {
              uploadedFile.keyName = res.key
              uploadedFile.progress = undefined
              onUploaded?.(uploadedFile)
            },
            // upload progress
            (progress) => {
              uploadedFile.progress = progress
              onUploaded?.(uploadedFile)
            },
            // upload error
            (err) => {
              onDeleteUploadedFile?.(uploadedFile)
            }
          )
        },
        [onDeleteUploadedFile, onUploaded, uploadFile]
      )

      // アップロードファイル一覧の削除ボタン押下時
      const handleDeleteFile = useCallback(
        (file: UploadFile) => {
          onDeleteUploadedFile?.(file)

          if (!file.keyName) {
            abortControllers[file.name].abort() // Abort the specific file upload
          }
        },
        [abortControllers, onDeleteUploadedFile]
      )

      // アンマウント時にアップロード処理をキャンセル
      useEffect(() => {
        return () => {
          Object.values(abortControllers).forEach((controller) => {
            controller.abort()
          })
        }
      }, [abortControllers])

      return (
        <LexicalComposer initialConfig={initialConfig}>
          <TableContext>
            <div
              className={classNames(
                'max-h-96 h-full',
                className,
                'flex flex-col gap-1',
                {
                  'dx-invalid': !isValid,
                }
              )}
              {...rest}
            >
              <ToolbarPluginTop />

              <div
                ref={editorContainerRef}
                className={classNames(
                  'relative', //Placeholderの配置基準用
                  'editor-dropzone', //style when dragover
                  'grow-1 shrink-1 h-full overflow-y-auto',
                  'border-crew-gray-2-light focus-within:border-crew-blue-3-light border',
                  'rounded'
                )}
              >
                <div {...getRootProps()}>
                  <CrewFileUploadDropZone
                    isShowDropZone={isDragActive}
                    uploadedFileList={uploadedFileList}
                    fileUploaderDisabled={fileUploaderDisabled}
                  >
                    <RichTextPlugin
                      contentEditable={
                        <div ref={onContentContainerRef} className="h-full p-1">
                          <ContentEditable
                            className={classNames(
                              'outline-none editor-input h-full'
                              // FIXME: tailwindで指定するとminHeightが反映されない
                              // minHeight && 'min-h-[' + minHeight + ']'
                            )}
                            // FIXME: tailwindで指定するとminHeightが反映されないため、styleで指定
                            style={{ minHeight: minHeight }}
                          />
                        </div>
                      }
                      placeholder={
                        <PlaceHolder
                          // HTMLエディタ内にプレースホルダーを表示するためabsoluteを使用
                          className={
                            'absolute top-1 left-1 text-crew-gray-3-light z-0 pointer-events-none'
                          }
                        />
                      }
                      ErrorBoundary={LexicalErrorBoundary}
                    />
                  </CrewFileUploadDropZone>
                </div>

                {/* アップロードファイル一覧 */}
                {uploadedFileList && uploadedFileList.length > 0 && (
                  // 「アップロードファイルがある」場合はアップロードファイル一覧を表示する
                  <CrewUploadedFileList
                    uploadedFileList={uploadedFileList}
                    onDeleteFile={handleDeleteFile}
                    showThumbnail={true}
                    fillIcon={true}
                    direction="horizontal"
                  />
                )}
              </div>
              <div className="h-8 shrink-0 flex flex-row justify-between items-center">
                <ToolbarPluginBottom
                  uploadFile={handleFileUpload}
                  fileUploaderDisabled={fileUploaderDisabled}
                />
                {appendButtons}
              </div>
            </div>
            <HistoryPlugin />
            <EditorRefPlugin
              /**
               * TODO: CREW-9944 公式プラグインのバグのため型変換が必要。解消されたら削除する
               * https://github.com/facebook/lexical/commit/8a299876d921c24dbfacee570dd599d97f54127f
               * */
              editorRef={lexicalEditorRef as MutableRefObject<LexicalEditor>}
            />
            <ImportDefaultHtmlPlugin defaultValue={defaultHtml} />
            <OnChangeHtmlPlugin
              valueChangeEvent={valueChangeEvent}
              onValueChange={onValueChange}
              onMentionTargetChange={onMentionTargetChange}
            />
            <EmojiPickerPlugin />
            <EmojiPlugin />
            <ListPlugin />
            <TablePlugin />
            <TableCellResizer />
            <CheckListPlugin />
            <MentionsPlugin
              entityType={entityType}
              entityRecordId={entityRecordId}
              disabled={disabledMention ?? false}
              editorContainerRef={editorContainerRef}
            />
            <ClearEditorPlugin />
            <DragDropPastePlugin />
            <FileUploadPlugin
              uploadFile={handleFileUpload}
              fileUploaderDisabled={fileUploaderDisabled}
            />

            <LinkPlugin />
            <CustomAutoLinkPlugin />
            <LinkEditorPlugin />
            <AutoLinkEditorPlugin />

            <LinkCardPlugin />

            <LinkCardEditorPlugin />

            <NodeEventPlugin
              eventListener={(e, _editor, nodeKey) => {
                const linkElement = e.target
                if (linkElement === null) return
              }}
              eventType="click"
              nodeType={LinkNode}
            />

            {contentContainerRef && (
              <TableCellActionMenuPlugin anchorElem={contentContainerRef} />
            )}

            <CodeHighlightPlugin />
            <TabIndentationPlugin />
          </TableContext>
        </LexicalComposer>
      )
    }
  )
)
