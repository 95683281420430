import { createContext, Dispatch, SetStateAction } from 'react'

/** Context用の型 */
type TaskDetailCommentListPageContextType = {
  /** current edit item id */
  editItemId: string | null
  /** Update current edit item id */
  setEditItemId: Dispatch<SetStateAction<string | null>>
}

/** Context */
export const TaskDetailCommentListPageContext =
  createContext<TaskDetailCommentListPageContextType>(undefined!)

export const useTaskDetailCommentListPanel = () => {}
