import { ComponentProps, useMemo } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { genericMemo } from 'utils'

import { CrewDateBox } from 'components/devextreme/crewDateBox'
import { CrewErrorMessages } from 'components/forms/crewErrorMessages'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'

export type DateBoxFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  ComponentProps<typeof CrewDateBox>,
  'type' | 'ref' | 'name' | 'value' | 'onValueChange' | 'onFocusOut' | 'isValid' //これらはcontrollerが提供するのでpropsから除外する
> & {
  id: string
  required?: boolean
  label?: string
  showLabel?: boolean
} & UseControllerProps<TFieldValues, TName>

/**
 * useController対応版TimePicker
 * TODO: コピペ時に自動成型できないためCREW-1022で対応する
 */

// Genericsを使うためにReact.FC<>は使用出来ない
// https://kray.jp/blog/dont-have-to-use-react-fc-and-react-vfc/
export const CrewTimePickerField = genericMemo(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
    // useController用
    name,
    rules,
    control,
    shouldUnregister,
    defaultValue,
    // ラベル用
    id,
    required = false,
    label,
    showLabel = true,
    // その他
    children,
    ...rest
  }: DateBoxFieldProps<TFieldValues, TName>) => {
    const { field, fieldState, formState } = useController({
      name,
      rules,
      shouldUnregister,
      defaultValue,
      control,
    })

    // 要素にid属性を付与するためのオブジェクト
    // data-testidのようなカスタム属性はinputAttrで指定する必要がある
    // https://supportcenter.devexpress.com/ticket/details/t1052998/form-unable-to-pass-custom-attributes-to-items
    const inputAttr = useMemo(() => {
      return { id: id, 'data-testid': field.name }
    }, [field.name, id])

    return (
      <div className="flex flex-col gap-1">
        {showLabel && (
          <CrewFieldLabel text={label} required={required} htmlFor={id} />
        )}
        <CrewDateBox
          {...rest}
          type="time"
          inputAttr={inputAttr}
          name={field.name}
          value={field.value}
          onValueChange={field.onChange}
          onFocusOut={field.onBlur}
          isValid={!fieldState.error}
          // 指定がない場合はデフォルトでuseMaskBehaviorをtrueに設定する（displayFormatの形式を強制する）
          // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/#useMaskBehavior
          useMaskBehavior={rest.useMaskBehavior ?? true}
        >
          {children}
        </CrewDateBox>
        <CrewErrorMessages
          isValid={!fieldState.error}
          errors={formState.errors}
          field={field}
        />
      </div>
    )
  }
)
