import { FC, memo, useCallback } from 'react'

import File3Line from '~icons/ri/file-3-line'
import FileExcel2Fill from '~icons/ri/file-excel-2-fill'
import FilePpt2Fill from '~icons/ri/file-ppt-2-fill'
import FileTextLine from '~icons/ri/file-text-line'
import FileWord2Fill from '~icons/ri/file-word-2-fill'
import FileMusicLine from '~icons/ri/file-music-line'
import FileVideoLine from '~icons/ri/file-video-line'
import { FileExtensions } from '@crew/enums/app'
import classNames from 'classnames'

type FileIconItemProps = {
  fileName: string
}

export const FileIconItem: FC<FileIconItemProps> = memo((props) => {
  const renderFileIconItem = useCallback(() => {
    const fileExtension = props.fileName.toLowerCase().split('.').pop()
    // ファイルのサムネイルが存在しない場合は、ファイルの拡張子に応じたアイコンを表示する
    switch (fileExtension) {
      // TODO: ファイルのサムネイル表示/プレビューに対応する
      // https://break-tmc.atlassian.net/browse/CREW-14880
      case FileExtensions.Doc:
      case FileExtensions.Docx:
        return (
          <div className="w-1/2 flex items-center mx-auto h-full">
            <FileWord2Fill
              className={classNames(
                'w-full h-full',
                // ファイルの拡張子に応じた色を指定
                'text-crew-blue-600'
              )}
            />
          </div>
        )

      case FileExtensions.Xls:
      case FileExtensions.Xlsx:
        return (
          <div className="w-1/2 flex items-center mx-auto h-full">
            <FileExcel2Fill
              className={classNames(
                'w-full h-full',
                // ファイルの拡張子に応じた色を指定
                'text-crew-green-600'
              )}
            />
          </div>
        )
      case FileExtensions.Ppt:
      case FileExtensions.Pptx:
        return (
          <div className="w-1/2 flex items-center mx-auto h-full">
            <FilePpt2Fill
              className={classNames(
                'w-full h-full',
                // ファイルの拡張子に応じた色を指定
                'text-crew-orange-600'
              )}
            />
          </div>
        )
      case FileExtensions.Txt:
        return (
          <div className="w-1/2 flex items-center mx-auto h-full">
            <FileTextLine
              className={classNames(
                'w-full h-full',
                // ファイルの拡張子に応じた色を指定
                'text-crew-gray-500'
              )}
            />
          </div>
        )
      case FileExtensions.Mp3:
        return (
          <div className="w-1/2 flex items-center mx-auto h-full">
            <FileMusicLine
              className={classNames(
                'w-full h-full',
                // ファイルの拡張子に応じた色を指定
                'text-crew-gray-500'
              )}
            />
          </div>
        )
      case FileExtensions.Mov:
      case FileExtensions.Mpg:
      case FileExtensions.Mpeg:
      case FileExtensions.Wmv:
      case FileExtensions.Avi:
      case FileExtensions.Flv:
        return (
          <div className="w-1/2 flex items-center mx-auto h-full">
            <FileVideoLine
              className={classNames(
                'w-full h-full',
                // ファイルの拡張子に応じた色を指定
                'text-crew-gray-500'
              )}
            />
          </div>
        )

      default:
        return (
          <div className="w-1/2 flex items-center mx-auto h-full">
            <File3Line
              className={classNames(
                'w-full h-full',
                // ファイルの拡張子に応じた色を指定
                'text-crew-gray-500'
              )}
            />
          </div>
        )
    }
  }, [props.fileName])

  return renderFileIconItem()
})
