import { useInsertChatReactionMutation } from '@crew/apis/chatReaction/chatReactionApis'
import { useCallback } from 'react'

export const useCrewChatMessageItemAction = (chatMessageId: string) => {
  const [insertChatReactionMutation] = useInsertChatReactionMutation()

  // 絵文字選択時
  const chooseEmoji = useCallback(
    async (shortName: string) => {
      // リアクション登録
      await insertChatReactionMutation({
        chatMessageId,
        reaction: shortName,
      }).unwrap()
    },
    [insertChatReactionMutation, chatMessageId]
  )

  return {
    chooseEmoji,
  }
}
