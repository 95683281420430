import { useTranslation } from '@crew/modules/dist/i18n'
import { ValidateRules } from '@crew/utils/dist/form'
import { ValidationStatus } from 'devextreme/common'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  useLazyCheckDuplicateLoginIdQuery,
  useLoginMutation,
  useNewUserJoinTenantMutation,
} from '@crew/apis/dist/app/appApis'
import { UserPending } from '@crew/models/domain'

export type UserPendingInfo = UserPending & {
  destinationEmail: string
}

export type FormValues = {
  loginId: string
  fullName: string
  password: string
  passwordConfirm: string
  agree: boolean
}

export const useSignupNewAccount = (userPending: UserPendingInfo | null) => {
  const { t } = useTranslation()

  const [loginIdValidationStatus, setLoginIdValidationStatus] =
    useState<ValidationStatus>('valid')

  // フォームの初期値
  // fullNameにpropsから渡される値を設定するためフック内に定義する
  const formInitialValues: FormValues = {
    loginId: '',
    fullName: userPending?.displayName || '', // パラメータとして渡されてきている場合は初期値として設定する
    password: '',
    passwordConfirm: '',
    agree: false,
  }

  // react-hook-formの各種データを取得
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm<FormValues>({
    defaultValues: formInitialValues,
    criteriaMode: 'all',
  })

  // Get functions for join tenant
  const [newUserJoinTenantMutation] = useNewUserJoinTenantMutation()
  const [loginMutation] = useLoginMutation()

  const [lazyCheckDuplicateLoginIdQuery] = useLazyCheckDuplicateLoginIdQuery()

  // Join tenant process
  const joinTenant = useCallback(
    async (data: FormValues, invitationToken: string) => {
      const payload = {
        ...data,
        displayName: data.fullName,
        invitationToken,
      }

      await newUserJoinTenantMutation({
        joinTenant: payload,
      }).unwrap()
    },
    [newUserJoinTenantMutation]
  )

  // Login process
  const login = useCallback(
    async (loginId: string, password: string) => {
      // ログイン
      const result = await loginMutation({
        loginId: loginId,
        password: password,
      }).unwrap()

      return result
    },
    [loginMutation]
  )

  // validate confirm password equal password
  const checkPasswordConfirm = useCallback(() => {
    return getValues('password') === getValues('passwordConfirm')
  }, [getValues])

  // Check require login ID and update validation state
  const checkLoginIdEmpty = useCallback(() => {
    const loginId = getValues('loginId')
    if (loginId.length === 0) {
      setLoginIdValidationStatus('invalid')
      return false
    }
    return true
  }, [getValues])

  // 以下、入力コンポーネントのルールとレンダリングを定義
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      loginId: {
        validate: {
          checkLoginIdEmpty: () =>
            checkLoginIdEmpty() ||
            t('message.general.isRequired', {
              name: t('label.loginId'),
            }),
        },
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.loginId'),
            value: 500,
          }),
        },
      },
      fullName: {
        required: t('message.general.isRequired', {
          name: t('label.displayName'),
        }),
      },
      password: {
        required: t('message.general.isRequired', {
          name: t('label.password'),
        }),
        // TODO: 暫定で6文字以上かのみチェック。恒久対応はCREW-10657で対応する。
        // https://break-tmc.atlassian.net/browse/CREW-10657
        minLength: {
          value: 6,
          message: '6文字以上で入力してください', // サインアップ画面はi18nが使えないかつ暫定実装のため、日本語で固定表示
        },
      },
      passwordConfirm: {
        required: t('message.general.isRequired', {
          name: t('label.confirmPassword'),
        }),
        validate: {
          checkPasswordConfirm: () =>
            checkPasswordConfirm() ||
            t('message.signup.register.invalidFormatConfirmPassword'),
        },
      },
      agree: {},
    }),
    [checkLoginIdEmpty, checkPasswordConfirm, t]
  )

  //  Check the existence of loginId
  const verifyLoginId = useCallback(
    async (loginId: string) => {
      const response = await lazyCheckDuplicateLoginIdQuery({
        loginId,
      }).unwrap()

      // if duplicate login ID then set error
      if (response.duplicated) {
        setLoginIdValidationStatus('invalid')
        setError('loginId', {
          type: 'manual',
          message: t('message.signup.register.loginIdAlreadyExists'),
        })
      } else {
        // if not duplicate login ID then set valid
        setLoginIdValidationStatus('valid')
        clearErrors('loginId')
      }
    },
    [clearErrors, lazyCheckDuplicateLoginIdQuery, setError, t]
  )

  return {
    control,
    handleSubmit,
    formState,
    setError,
    clearErrors,
    validateRules,
    watch,
    trigger,

    loginIdValidationStatus,
    setLoginIdValidationStatus,
    joinTenant,
    login,

    verifyLoginId,
  }
}
