import { FC, memo } from 'react'
import { CrewNavTabs } from 'components/elements/crewNavTabs/crewNavTabs'
import { useTranslation } from '@crew/modules/i18n'
import { ProjectSettingTabs } from 'enums/app'
import { useMemo } from 'react'
import { CrewNavTabProps } from 'components/elements/crewNavTabs/components/crewNavTab'
import { useNavigate } from 'react-router-dom'

export const ProjectSettingTab: FC = memo(() => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  // タブアイテム
  const displayTabItems: CrewNavTabProps[] = useMemo(() => {
    const items = Object.values(ProjectSettingTabs).map((item) => {
      return {
        onClick: () => {
          // タブに対応するURLへ遷移（相対パス指定）
          navigate(item.value)
        },
        to: item.value,
        text: t(item.text),
        icon: undefined,
      }
    })

    return items
  }, [t, navigate])

  return <CrewNavTabs tabItems={displayTabItems} />
})
