import { apis } from '../apiBase/apiBase';
export const tenantApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getTenantsByIds: builder.query({
            query: (params) => {
                return {
                    url: `tenants`,
                    method: `GET`,
                    params: params,
                };
            },
        }),
        checkStorageCapacityExceeded: builder.query({
            query: () => {
                return {
                    url: `tenants/storage-capacity-exceeded`,
                    method: `GET`,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetTenantsByIdsQuery, useLazyGetTenantsByIdsQuery, useCheckStorageCapacityExceededQuery, useLazyCheckStorageCapacityExceededQuery, } = tenantApis;
