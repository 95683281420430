import { ChatMessage } from '@crew/apis/chat/models/getChatRoomMessages/response'
import { MESSAGE_AREA_MIN_HEIGHT } from '@crew/configs/constants'
import { TimelineDirection } from '@crew/enums/app'
import { useFetchLimit } from '@crew/hooks'
import { useCallback, useMemo } from 'react'

import { Feed, useChatMessageService, useFeedService } from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'

import { IsApiErrorJwtInvalidError } from '@crew/apis/errors'
import { useLazyGetFeedQuery } from '@crew/apis/feed/feedApis'
import { getWindowDimensions } from 'utils'

/** メッセージアイテムの型 */
export type DisplayMessageItem = {
  id: string
  messageId: string // メッセージID
  handleAdditionalLoading: (() => void) | undefined // 追加読込処理
}

/** 複数メッセージ追加時のパラメータ */
type AddMessagesParams = {
  chatMessages: ChatMessage[]
  criterionMessageId: string | undefined
  direction: TimelineDirection
  isLoadedOfEndOfMessages: boolean
}

/** フィードをロードするためのパラメータ */
type LoadFeedsParams = {
  criterionMessageId: string | undefined
  direction: TimelineDirection
}

/**
 * 引数のmessagesから表示用のアイテムリストを生成する関数
 * @param messages ViewModelから取得したFeedのmessages
 * @param lastReadMessageId 最終既読メッセージID
 * @param isSuccessLastReadFetch バックエンドからの既読情報の取得が成功したかどうか
 * @param loadFeeds フィードをロードする関数
 * @param delayedUpdateLastReadMessageId 既読更新を遅延実行させるための関数
 * @returns 表示用アイテムリストと更新後の最終既読メッセージID
 */
const convertMessagesToDisplayItems = (
  messages: Feed.FeedMessage[],
  loadFeeds: (params: LoadFeedsParams) => void
): DisplayMessageItem[] => {
  const items: DisplayMessageItem[] = []

  const reversedMessages = [...messages].reverse()
  // 未読ラインの処理を簡易化するため、メッセージを逆順に(古い方から)処理する
  reversedMessages.forEach((messageItem, index) => {
    const messageId = messageItem.chatMessageId

    // 追加読込の方向を決定する。必要無ければundefined
    const additionalLoadingDirection =
      messageItem.hasMorePrev && messageItem.hasMoreNext // 時間軸上で両方向の隣のメッセージが未読込だった場合はboth
        ? TimelineDirection.BothNewerAndOlder
        : messageItem.hasMorePrev // 時間軸上でprev方向の隣のメッセージが未読込だった場合はolder
        ? TimelineDirection.Older
        : messageItem.hasMoreNext // 時間軸上でnext方向の隣のメッセージが未読込だった場合はnewer
        ? TimelineDirection.Newer
        : undefined // 両方向の隣のメッセージが読込済だった場合は追加読込は不要

    // 追加読込処理を行う関数を生成する。必要無ければundefined
    const handleAdditionalLoading = additionalLoadingDirection
      ? () =>
          loadFeeds({
            criterionMessageId: messageId,
            direction: additionalLoadingDirection,
          })
      : undefined

    // 逆順に処理しているため、unshiftで先頭に追加する
    items.unshift({
      id: messageId, // フィードには現状チャットメッセージしか表示されないため、フィードIDとメッセージIDは同じにしておく
      messageId: messageId,
      handleAdditionalLoading,
    })
  })

  return items
}

export const useFeedMessageList = () => {
  const dispatch = useAppDispatch()

  // 取得したチャットメッセージをSliceにキャッシュする関数を取得
  const chatMessageService = useChatMessageService(dispatch)

  // Sliceの操作を行うためのServiceを取得
  const feedService = useFeedService(dispatch)

  // フィード取得用クエリ
  const [lazyGetFeedQuery] = useLazyGetFeedQuery()

  // Reduxに格納されているフィードのメッセージのDictionaryを取得する
  const feedMessageDictionary = useAppSelector(
    (state) => state.message.feed.messages.entities
  )
  // Reduxに格納されているフィードのメッセージのIDリストを取得する
  // Reduxに格納されている順番で取得する必要があるので、entitiesだけでなくidsも取得しておく必要がある
  const feedMessageIds = useAppSelector(
    (state) => state.message.feed.messages.ids
  )
  // 表示対象のメッセージを返す
  const feedMessages = useMemo(() => {
    if (!feedMessageIds || !feedMessageDictionary) {
      return []
    }
    // id順にメッセージを設定する
    return feedMessageIds.reduce((result: Feed.FeedMessage[], id) => {
      const message = feedMessageDictionary[id]
      if (message) {
        result.push(message)
      }
      return result
    }, [])
  }, [feedMessageDictionary, feedMessageIds])

  // 一度にfetchするサイズ
  const fetchLimit = useFetchLimit(MESSAGE_AREA_MIN_HEIGHT, getWindowDimensions)

  /**
   * 複数アイテムをViewModelに追加する
   * @param params
   */
  const addItems = useCallback(
    (params: AddMessagesParams) => {
      // ViewModelへデータを追加
      const parameter = {
        messages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfMessages,
      }
      feedService.addFeedMessages(parameter)
    },
    [feedService]
  )

  /**
   * API経由で取得したチャットメッセージをメッセージのキャッシュとViewModelに追加する
   */
  const addMessages = useCallback(
    (params: AddMessagesParams) => {
      // キャッシュへ追加
      chatMessageService.addChatMessagesToCache({
        chatMessages: params.chatMessages,
      })
      // ViewModelへ追加
      addItems({
        chatMessages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfMessages: params.isLoadedOfEndOfMessages,
      })
    },
    [addItems, chatMessageService]
  )

  /**
   * フィードをロードするメソッド
   * @param params 対象メッセージを絞り込むためのパラメータ
   * @returns
   */
  const loadFeeds = useCallback(
    async (params: LoadFeedsParams) => {
      const request = {
        criterionMessageId: params.criterionMessageId ?? undefined,
        direction: params.direction,
        limit: fetchLimit,
      }

      try {
        const data = await lazyGetFeedQuery(request).unwrap()

        // 取得した件数がlimitより少ない場合は、これ以上取得する必要がないため表示アイテムにフラグを付与する
        const isLoadedOfEndOfMessages = request.limit > data.feedItems.length

        const addMessagesPayload: AddMessagesParams = {
          chatMessages: data.feedItems,
          ...request, // 型が違うがduck typeによって代入可能
          isLoadedOfEndOfMessages,
        }

        addMessages(addMessagesPayload)
      } catch (err: any) {
        if (!err?.data || !IsApiErrorJwtInvalidError(err.data)) {
          // TODO: CREW-13720の対応で、プロジェクトから退出した際にエラートーストが出てしまう問題が発生し、トースト表示をコメントアウトする暫定対応を行った
          // ただ、これにより追加ロード時にエラーがあっても画面上表示されないという状態であるため、以下タスクで恒久対応を行う
          // 現時点ではコンソールにエラーを表示するにとどめる
          // https://break-tmc.atlassian.net/browse/CREW-13724
          // toast.error(t('message.general.failedToRetrieveData'))
          console.error(err)
        }
      }
    },
    [fetchLimit, lazyGetFeedQuery, addMessages]
  )

  // 表示用アイテムリスト
  const displayItems = useMemo(
    () => convertMessagesToDisplayItems(feedMessages, loadFeeds),
    [feedMessages, loadFeeds]
  )

  // アイテムが領域内に表示された
  const messageInView = useCallback(
    (id: string, messageId: string) => {
      // スクロール位置を戻すための対象messageIdを保持しておく
      feedService.setFeedScrollToMessageId({
        chatMessageId: messageId,
      })
    },
    [feedService]
  )

  return {
    displayItems,
    loadFeeds,
    messageInView,
  }
}
