import { memo, FC } from 'react'
import { ProjectListToolbar } from './components/projectListToolbar/projectListToolbar'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { ProjectTable } from './components/projectTable/projectTable'

export const ProjectListPage: FC = memo(() => {
  return (
    <div className="min-h-0 flex-1 flex flex-col grow w-full">
      <div className="min-h-0 flex flex-col gap-4 p-2">
        {/* Project list toolbar */}
        <CrewErrorBoundary>
          <ProjectListToolbar />
        </CrewErrorBoundary>
        {/* 活動リストパネル */}
        <CrewErrorBoundary>
          <ProjectTable />
        </CrewErrorBoundary>
      </div>
    </div>
  )
})
