import { useDataSource } from './useDataSource'
import {
  useLazyGetGroupScheduleQuery,
  useUpdateEventDurationMutation,
} from '@crew/apis/project/projectApis'
import { GroupScheduleFilterType } from '@crew/enums/domain'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/system'

export const useScheduleGroupMonthDataSource = (
  targetDate: Date,
  groupScheduleFilterType: GroupScheduleFilterType,
  projectId: string | undefined
) => {
  const [lazyGetGroupScheduleQuery] = useLazyGetGroupScheduleQuery()
  const [updateEventDurationMutation] = useUpdateEventDurationMutation()

  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        const result = await lazyGetGroupScheduleQuery({
          filterType:
            groupScheduleFilterType ===
            GroupScheduleFilterType.GroupScheduleCustomFilter
              ? GroupScheduleFilterType.GroupScheduleCustomFilter
              : GroupScheduleFilterType.GroupScheduleProjectFilter,
          // If the selected project is "Custom", set the projectId to undefined
          projectId,
          // get first day of month
          startDate: dayjs(targetDate)
            .startOf('month')
            .format(JsonDateFormat.YYYYMMDD),
          // get last day of month
          endDate: dayjs(targetDate)
            .endOf('month')
            .format(JsonDateFormat.YYYYMMDD),
        }).unwrap()

        return result.events
      },
      update: async (key, values) => {
        await updateEventDurationMutation({
          event: {
            id: key,
            startDatetime: dayjs(values.startDatetime).format(
              JsonDateFormat.YYYYMMDDHHmmss
            ),
            endDatetime: dayjs(values.endDatetime).format(
              JsonDateFormat.YYYYMMDDHHmmss
            ),
            version: values.version,
          },
        })
      },
    }),
    [
      groupScheduleFilterType,
      lazyGetGroupScheduleQuery,
      projectId,
      targetDate,
      updateEventDurationMutation,
    ]
  )
}
