import { FC, memo, useMemo } from 'react'
import { DirectMessageUserListItem } from './components/directMessageUserListItem/directMessageUserListItem'
import { useAppSelector } from 'states/hooks'
import {
  ActiveChatRoomInfo,
  selectAlreadyReadFavoriteDirectChannelActiveChatRooms,
} from '@crew/states'
import { itemComparer } from '@crew/utils'

// アコーディオン内に表示されるアイテム表示
export const DirectMessageUserList: FC = memo(() => {
  // 未読のないお気に入りダイレクトチャンネルのチャットルーム
  const alreadyReadDirectChannelChatRooms = useAppSelector(
    selectAlreadyReadFavoriteDirectChannelActiveChatRooms
  )

  // 表示名の昇順でソートする
  const sortedAlreadyReadDirectChannelChatRooms = useMemo(() => {
    return [...alreadyReadDirectChannelChatRooms].sort(
      itemComparer<ActiveChatRoomInfo>('asc', 'name')
    )
  }, [alreadyReadDirectChannelChatRooms])

  return (
    <ul className="flex-1 mb-2">
      {sortedAlreadyReadDirectChannelChatRooms.map((chatRoom) => (
        <DirectMessageUserListItem
          key={chatRoom.chatRoomId}
          chatRoom={chatRoom}
        />
      ))}
    </ul>
  )
})
