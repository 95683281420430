import { useGetMyAccountQuery } from '@crew/apis/user/userApis'
import { SettingKeyType } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'states/hooks'
import { useUserSetting } from '@crew/states'

export const usePersonalSettingSecurityPanel = () => {
  const { t } = useTranslation()
  const myAccountEventMessage = useAppSelector(
    (state) => state.app.myAccountEventMessage
  )
  const [isChangeEmailFormOpen, setIsChangeEmailFormOpen] = useState(false)
  const [isChangePasswordFormOpen, setIsChangePasswordFormOpen] =
    useState(false)
  const [isTwoFactorFormOpen, setIsTwoFactorFormOpen] = useState(false)

  const { data: getMyAccount, refetch: reloadMyAccount } =
    useGetMyAccountQuery()

  // get user setting data
  const defaultSecurityAuthenticationType = useUserSetting(
    SettingKeyType.SecurityAuthenticationType
  )
  const defaultSecurityTwoFactorAuthDestination = useUserSetting(
    SettingKeyType.SecurityTwoFactorAuthDestination
  )
  const defaultSecurityTwoFactorAuthCountryCode = useUserSetting(
    SettingKeyType.SecurityTwoFactorAuthCountryCode
  )

  const [securityAuthenticationType, setSecurityAuthenticationType] = useState<
    string | null
  >(null)

  const [
    securityTwoFactorAuthDestination,
    setSecurityTwoFactorAuthDestination,
  ] = useState<string | undefined>(undefined)

  const [
    securityTwoFactorAuthCountryCode,
    setSecurityTwoFactorAuthCountryCode,
  ] = useState<string | undefined>(undefined)

  // refetch my account
  useEffect(() => {
    reloadMyAccount()
  }, [myAccountEventMessage, reloadMyAccount])

  //get security authentication type
  useEffect(() => {
    setSecurityAuthenticationType(
      defaultSecurityAuthenticationType
        ? String(defaultSecurityAuthenticationType)
        : null
    )
    setSecurityTwoFactorAuthDestination(
      defaultSecurityTwoFactorAuthDestination
        ? String(defaultSecurityTwoFactorAuthDestination)
        : undefined
    )
    setSecurityTwoFactorAuthCountryCode(
      defaultSecurityTwoFactorAuthCountryCode
        ? String(defaultSecurityTwoFactorAuthCountryCode)
        : undefined
    )
  }, [
    defaultSecurityAuthenticationType,
    defaultSecurityTwoFactorAuthCountryCode,
    defaultSecurityTwoFactorAuthDestination,
  ])

  //close password form
  const handleClosePasswordForm = useCallback(() => {
    setIsChangePasswordFormOpen(false)
  }, [])

  // close change email form and refetch account info
  const handleCloseChangeMailForm = useCallback(() => {
    setIsChangeEmailFormOpen(false)
  }, [])

  // submit two factor form
  const handleSubmitTwoFactorForm = useCallback(() => {
    // close two factor form
    setIsTwoFactorFormOpen(false)
  }, [])

  // メールアドレス変更ボタンクリック時の処理
  const handleChangeEmailButtonClick = useCallback(() => {
    // Open change email form
    setIsChangeEmailFormOpen(true)
    // Close another items
    setIsChangePasswordFormOpen(false)
    setIsTwoFactorFormOpen(false)
  }, [])

  // パスワード変更ボタンクリック時の処理
  const handleChangePasswordButtonClick = useCallback(() => {
    // Open change password form
    setIsChangePasswordFormOpen(true)
    // Close another items
    setIsChangeEmailFormOpen(false)
    setIsTwoFactorFormOpen(false)
  }, [])

  // 2要素認証変更ボタンクリック時の処理
  const handleChangeTwoFactorButtonClick = useCallback(() => {
    // Open two factor form
    setIsTwoFactorFormOpen(true)
    // Close another items
    setIsChangeEmailFormOpen(false)
    setIsChangePasswordFormOpen(false)
  }, [])

  return {
    t,
    isChangeEmailFormOpen,
    handleChangeEmailButtonClick,
    isChangePasswordFormOpen,
    handleChangePasswordButtonClick,
    isTwoFactorFormOpen,
    handleChangeTwoFactorButtonClick,
    account: getMyAccount?.myAccount,
    securityAuthenticationType,
    handleCloseChangeMailForm,
    handleClosePasswordForm,
    handleSubmitTwoFactorForm,
    securityTwoFactorAuthDestination,
    securityTwoFactorAuthCountryCode,
  }
}
