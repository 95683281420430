import { useTranslation } from '@crew/modules/i18n'
import { useMemo } from 'react'
import { UnsortedFolder } from 'enums/app'
import { useDataSource } from './useDataSource'
import { EntityType } from '@crew/enums/domain'
import {
  useLazyGetLookupFolderQuery,
  useLazyGetLookupFoldersQuery,
} from '@crew/apis/lookup/lookupApis'
import { cloneDeep } from 'lodash'

export const useFolderDataSource = (
  entityType: EntityType | null,
  entityRecordId: string | null
) => {
  const { t } = useTranslation()

  const [lazyGetLookupFoldersQuery] = useLazyGetLookupFoldersQuery()
  const [lazyGetLookupFolderQuery] = useLazyGetLookupFolderQuery()

  // enumsのUnsortedFolderをベースにリスト出力用データを用意（nameを翻訳する必要があるので）
  const folderBase = useMemo(() => {
    return {
      id: UnsortedFolder.value,
      name: t(UnsortedFolder.text),
    }
  }, [t])

  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!entityRecordId || !entityType) return [folderBase]

        const result = await lazyGetLookupFoldersQuery({
          entityType,
          entityRecordId,
        }).unwrap()

        // Clone the folder data because the original data is read-only
        const folders = cloneDeep(result.folders)

        return [folderBase, ...folders]
      },
      byKey: async (folderId: string) => {
        if (folderId === UnsortedFolder.value) {
          return folderBase
        }

        const result = await lazyGetLookupFolderQuery({
          folderId,
        }).unwrap()

        return result.folder
      },
    }),
    [
      entityRecordId,
      entityType,
      folderBase,
      lazyGetLookupFolderQuery,
      lazyGetLookupFoldersQuery,
    ]
  )
}
