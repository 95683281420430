import {
  useLazyGetEventKindsQuery,
  useLazyGetMyScheduleQuery,
  useUpdateEventDurationMutation,
} from '@crew/apis/project/projectApis'
import { JsonDateFormat } from '@crew/enums/system'
import { EntityType } from '@crew/enums/domain'
import dayjs from '@crew/modules'
import {
  CrewBadgeInvertedColorClass,
  CrewBadgeInvertedColorToHex,
} from 'enums/color'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { TimePeriod } from 'enums/app'
import { RefObject, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { Scheduler } from 'devextreme-react'

export const useScheduleMonthly = (schedulerRef: RefObject<Scheduler>) => {
  const { t } = useTranslation()

  const [lazyGetEventKindsQuery] = useLazyGetEventKindsQuery()
  const [lazyGetMyScheduleQuery] = useLazyGetMyScheduleQuery()
  const [updateEventDurationMutation] = useUpdateEventDurationMutation()

  const eventDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!schedulerRef.current) return []

        // Get the first day in calendar month page
        const startDate = schedulerRef.current.instance.getStartViewDate()
        // Get the last day in calendar month page
        const endDate = schedulerRef.current.instance.getEndViewDate()

        const result = await lazyGetMyScheduleQuery({
          startDate: dayjs(startDate).format(JsonDateFormat.YYYYMMDD),
          endDate: dayjs(endDate).format(JsonDateFormat.YYYYMMDD),
        }).unwrap()

        return result.events
      },
      update: async (key, values) => {
        await updateEventDurationMutation({
          event: {
            id: key,
            startDatetime: dayjs(values.startDatetime).format(
              JsonDateFormat.YYYYMMDDHHmmss
            ),
            endDatetime: dayjs(values.endDatetime).format(
              JsonDateFormat.YYYYMMDDHHmmss
            ),
            version: values.version,
          },
        })
      },
    }),
    [lazyGetMyScheduleQuery, schedulerRef, updateEventDurationMutation]
  )

  //Get event kinds
  const eventKindDataSource = useDataSource(
    () => ({
      loadMode: 'raw',
      key: 'id',
      load: async (loadOptions) => {
        const response = await lazyGetEventKindsQuery({
          entityType: EntityType.Project,
          entityRecordId: undefined,
        }).unwrap()

        return response.eventKinds.map((item) => {
          return {
            ...item,
            displayColor:
              CrewBadgeInvertedColorToHex[
                item.displayColor as CrewBadgeInvertedColorClass
              ],
          }
        })
      },
    }),
    [lazyGetEventKindsQuery]
  )

  const timePeriodDataSource = useMemo(() => {
    return Object.values(TimePeriod).map((time) => {
      return {
        id: time.value,
        name: t(time.text),
      }
    })
  }, [t])

  return {
    eventKindDataSource,
    eventDataSource,
    timePeriodDataSource,
  }
}
