import { memo, useRef } from 'react'
import { useSearchChatResultList } from './useSearchChatResultList'
import { SearchChatResultListItem } from './components/searchChatResultListItem/searchChatResultListItem'

export const SearchChatResultList = memo(() => {
  const { keyword, displayItems } = useSearchChatResultList()

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  return (
    // 検索結果のメッセージ一覧(上から新しいメッセージ→古いメッセージで表示)
    <div ref={itemsScrollableDivRef} className="grow flex overflow-y-scroll">
      <div className="grow flex flex-col">
        {displayItems.map((item) => (
          <SearchChatResultListItem
            key={item.id}
            id={item.id}
            chatMessageId={item.messageId}
            highlightKeyword={keyword ?? ''}
            onAdditionalLoading={item.handleAdditionalLoading}
            container={itemsScrollableDivRef}
          />
        ))}
      </div>
    </div>
  )
})
