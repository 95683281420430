import { FC, MouseEventHandler, memo, useCallback, useMemo } from 'react'
import { ChatMessageReference } from '@crew/models/domain'
import { CrewLoadingText } from 'components/elements/crewLoading/crewLoadingText/crewLoadingText'
import classNames from 'classnames'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { getDefaultTabValue } from '@crew/utils/enum'
import { FileDetailListTabs } from 'enums/app'
import { CrewAttachmentThumbnails } from 'components/elements/crewAttachmentThumbnails/crewAttachmentThumbnails'
import { isImageFile } from '@crew/utils/chat'
import { EntityType } from '@crew/enums/domain'
import { useGetFileHistoryQuery } from '@crew/apis/file/fileApis'
import { skipToken } from '@reduxjs/toolkit/query'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { GetFileHistoryRequest } from '@crew/apis/dist/file/models/getFileHistory/request'
import { sanitizeHtmlAllowNothing } from 'utils/html'

export type CrewFileNotificationChatMessageProps = {
  chatMessageReferences: ChatMessageReference[]
  canDownload: boolean
  truncateMessage: boolean
}

export const CrewFileNotificationChatMessage: FC<CrewFileNotificationChatMessageProps> =
  memo((props) => {
    // 現時点では、chat_message_referencesのデータをもとに最新のレコードを取得する仕様
    // このためファイル更新を行った後で「ファイル登録時」の自動投稿メッセージには、更新後の件名と説明が表示される（2022/11/10プロダクトオーナー確認済み）

    // ファイル詳細画面へのリンククリックイベント
    const handleLinkClick = useCallback<MouseEventHandler>((event) => {
      // スレッド表示への切替を防止するため、stopPropagationする
      event.stopPropagation()
      // 画面遷移自体はLinkタグの機能で行われるため、処理を書く必要はない
    }, [])

    // referencesからファイルに紐づくデータを取得
    const fileReference = useMemo(() => {
      const reference = props.chatMessageReferences.find((data) => {
        return data.entityType === EntityType.FileHistories
      })

      // 自動投稿メッセージの場合、必ず1つのデータが存在するはずなので、存在しない場合は異常なデータなので何も処理しない
      if (!reference) {
        return
      }

      return reference
    }, [props.chatMessageReferences])

    // Create param to get file history
    const getFileHistoryParam: GetFileHistoryRequest | undefined = fileReference
      ? {
          fileHistoryId: fileReference.entityRecordId,
        }
      : undefined
    // Get created message user
    const { data: getFileHistoryResult } = useGetFileHistoryQuery(
      getFileHistoryParam ?? skipToken
    )

    const description = useMemo(
      () =>
        getFileHistoryResult?.fileHistory?.description &&
        (props.truncateMessage
          ? sanitizeHtmlAllowNothing(
              getFileHistoryResult.fileHistory.description
            )
          : getFileHistoryResult.fileHistory.description),
      [getFileHistoryResult?.fileHistory?.description, props.truncateMessage]
    )

    // ロードされるまでは表示しない
    if (!getFileHistoryResult?.fileHistory) {
      return <CrewLoadingText className="w-full h-4" />
    }

    return (
      <>
        {/** ファイルへのリンクを出力 */}
        {/* Linkをインラインとするためのdivタグ（flex-colの対象をこのdivタグにする） */}
        <div>
          <CrewLink
            to={`/files/${
              getFileHistoryResult.fileHistory.fileId
            }/${getDefaultTabValue(FileDetailListTabs)}`}
            title={getFileHistoryResult.fileHistory.file.name}
            onClick={handleLinkClick}
            className="line-clamp-2"
          >
            {getFileHistoryResult.fileHistory.file.name}
          </CrewLink>

          {/* For images, display thumbnails */}
          {/* メッセージの詳細を表示しない場合に添付ファイルを表示しない */}
          {!props.truncateMessage &&
            isImageFile(getFileHistoryResult.fileHistory.fileName) && (
              <CrewAttachmentThumbnails
                showDeleteButton={false}
                canDownload={props.canDownload}
                attachmentThumbnails={[getFileHistoryResult.fileHistory]}
              />
            )}
        </div>

        {/**
         * ファイルの説明を出力
         * TODO: 改行も含めすべての説明が表示されるため、表示仕様については以下で議論予定
         * https://break-tmc.atlassian.net/browse/CREW-4208
         */}
        {description && (
          <div
            className={classNames(
              'text-crew-slate-4-light dark:text-crew-slate-1-dark overflow-hidden text-ellipsis',
              HTMLEDITOR_VIEW_STYLE,
              props.truncateMessage && 'truncate'
            )}
          >
            <CrewHtmlContent html={description} />
          </div>
        )}
      </>
    )
  })
