import { EntityType } from '@crew/enums/domain'
import { CrewSaveFilterForm } from 'components/elements/crewSaveFilterDialog/components/crewSaveFilterForm/CrewSaveFilterForm'
import { Modal } from 'components/layouts/modal/modal'
import { FC, memo } from 'react'

export type CrewSaveFilterDialogProps = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (id?: string) => void
  definition: string
  entityType: EntityType
  filterId?: string
}

export const CrewSaveFilterDialog: FC<CrewSaveFilterDialogProps> = memo(
  (props) => {
    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <CrewSaveFilterForm
          isEditMode={props.isEditMode}
          onCancel={props.onClose}
          onSubmit={props.onSubmit}
          definition={props.definition}
          entityType={props.entityType}
          filterId={props.filterId}
        />
      </Modal>
    )
  }
)
