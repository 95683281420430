import { TaskOnlyState } from '@crew/apis/task/models/updateTaskOnlyState/request'
import { useUpdateTaskOnlyStateMutation } from '@crew/apis/task/taskApis'
import { Task } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'
import {
  notifyProjectTaskEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'

export const useProjectDetailTaskListKanban = () => {
  const dispatch = useAppDispatch()

  const [updateTaskOnlyState] = useUpdateTaskOnlyStateMutation()

  const updateTaskState = useCallback(
    async (taskId: string, taskStateId: string, version: number) => {
      const payload: TaskOnlyState = {
        taskId,
        taskStateId,
        version,
      }

      // Call API to update task state
      await updateTaskOnlyState({ task: payload }).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Updated,
        id: payload.taskId,
        object: payload ?? undefined,
      }

      dispatch(notifyProjectTaskEvent(objectEventMessage))
    },
    [dispatch, updateTaskOnlyState]
  )

  return {
    updateTaskState,
  }
}
