import { memo, useCallback, useEffect, useState } from 'react'
import { CrewAttachmentItem } from './components/crewAttachmentItem/crewAttachmentItem'
import { FileHistory, File } from '@crew/models/domain'

export type CrewAttachmentsProps = {
  attachments: File[] | FileHistory[]
  showDeleteButton: boolean
  onFileDeleted?: () => void // ファイル削除後のイベント
  canDownload: boolean
}

export const CrewAttachments = memo((props: CrewAttachmentsProps) => {
  const [attachments, setAttachments] = useState<(File | FileHistory)[]>(
    props.attachments
  )

  // 他ユーザによる添付ファイル削除でwebsocket経由でファイル情報が更新されたら添付ファイル表示に反映
  useEffect(() => {
    setAttachments(props.attachments)
  }, [props.attachments])

  // ファイル削除後にattachmentsから該当項目を削除して表示上から対象ファイル表記を消す
  const handleDeleteAttachmentItem = useCallback(
    (id: string) => {
      // attachmentsに格納しているファイル情報を削除する
      setAttachments((baseData) => baseData.filter((item) => item.id !== id))

      // ファイル削除後のイベントが設定されている場合は実行
      props.onFileDeleted && props.onFileDeleted()
    },
    [props]
  )

  return props.attachments.length > 0 ? (
    <div className="flex flex-col gap-1">
      {attachments.map((attachment) => (
        <CrewAttachmentItem
          attachment={attachment}
          key={attachment.id}
          showDeleteButton={props.showDeleteButton}
          onDeleteAttachmentItem={handleDeleteAttachmentItem}
          canDownload={props.canDownload}
        />
      ))}
    </div>
  ) : (
    <></>
  )
})
