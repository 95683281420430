import { useTranslation } from '@crew/modules/i18n'
import KeyboardArrowLeft from '~icons/material-symbols/keyboard-arrow-left'
import KeyboardArrowRight from '~icons/material-symbols/keyboard-arrow-right'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewCheckBox } from 'components/devextreme/crewCheckBox'
import { CrewDateBox } from 'components/devextreme/crewDateBox'
import { DatePickerDateFormat } from 'enums/system'
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { FormValues, useWorkingTimeEntryForm } from './useWorkingTimeEntryForm'
import { useGetTasksForWorkTimesRegisterQuery } from '@crew/apis/task/taskApis'

import dayjs from '@crew/modules'
import { DateFormat, JsonDateFormat } from '@crew/enums/system'
import {
  convertMinutesToHHMM,
  convertScheduledTimeToMinutes,
} from '@crew/utils'
import { useToast } from 'hooks/useToast'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { SearchTasksForWorkTimesPanel } from './components/searchTasksForWorkTimesPanel/searchTasksForWorkTimesPanel'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { useValueChangeEffect } from '@crew/hooks'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { SubmitHandler } from 'react-hook-form'
import { WorkTimesFormItem } from './components/workTimesFormItem/workTimesFormItem'
import { ProjectRef, UserRef } from '@crew/models/refs'
import { WorkingTimeEntryFormHeader } from './components/workTimesFormHeader/workTimesFormHeader'

type DeleteWorkTime = {
  taskWorkId: string
  version: number
}

type Task = {
  taskId: string
  subject: string
  parentTaskId: string | undefined
  version: number
  children: Task[] | undefined
  assignToUser: UserRef | undefined
  project: ProjectRef
}

type Action = 'Copy' | 'NextDate' | 'PrevDate' | 'Cancel'

type WorkingTimeEntryFormProps = {
  onCancel: () => void
  targetDate: Date
}

export const WorkingTimeEntryForm: FC<WorkingTimeEntryFormProps> = memo(
  (props) => {
    const { t } = useTranslation()
    const toast = useToast()
    const [showApiErrors] = useShowApiErrors()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    const {
      control,
      fields,
      replace,
      append,
      remove,
      handleSubmit,
      validateActualWorkTimes,
      validateActualWorkTimesDuration,
      validateTimeRange,
      validateRequiredWorkTimes,
      watch,

      bulkChangeWorkTimes,
      copyTasksForWorkTimes,
      getNextDate,
      getPreviousDate,
    } = useWorkingTimeEntryForm()

    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()

    const userAction = useRef<Action | null>(null)

    const [targetDate, setTargetDate] = useState(props.targetDate ?? new Date())
    const [targetCopyDate, setTargetCopyDate] = useState(() => {
      const date = new Date(props.targetDate)
      date.setDate(date.getDate() - 1)
      return date
    })

    const deletedWorkTimes = useRef<DeleteWorkTime[]>([])

    const formValues = watch('worktimes')

    // Calculate the total worktimes
    // https://github.com/orgs/react-hook-form/discussions/10929
    const totalWorkTimes = formValues
      ? formValues.reduce((acc, cur) => {
          const workTime = convertScheduledTimeToMinutes(
            cur.actualWorkTimes ?? 0
          )
          return acc + (workTime || 0)
        }, 0)
      : 0

    const [isRegisterConsecutively, setIsRegisterConsecutively] =
      useState(false)

    const { data: getTasksForWorkTimesRegisterResult } =
      useGetTasksForWorkTimesRegisterQuery({
        workDate: dayjs(targetDate).format(JsonDateFormat.YYYYMMDD),
      })

    const formIsDirty =
      formValues?.some((formValue) => {
        // check if the row is new
        if (!formValue.workTimesId) {
          if (
            formValue.startTime ||
            formValue.endTime ||
            formValue.actualWorkTimes
          ) {
            return true
          }
        }

        const task = getTasksForWorkTimesRegisterResult?.tasks?.find(
          (task) =>
            task.taskId === formValue.taskId &&
            task.workTimesId === formValue.workTimesId
        )

        if (task) {
          const startTimeFormated = task.startTime
            ? t('format.datetime', {
                value: task.startTime,
                timeZone: defaultUserProfileRegion,
              })
            : null
          const endTimeFormated = task.endTime
            ? t('format.datetime', {
                value: task.endTime,
                timeZone: defaultUserProfileRegion,
              })
            : null

          const formStartTimeFormated = formValue.startTime
            ? dayjs(formValue.startTime).format(DateFormat.YYYYMMDDHHmm)
            : null
          const formEndTimeFormated = formValue.endTime
            ? dayjs(formValue.endTime).format(DateFormat.YYYYMMDDHHmm)
            : null

          // compare startTime
          if (startTimeFormated !== formStartTimeFormated) {
            return true
          }

          // compare endTime
          if (endTimeFormated !== formEndTimeFormated) {
            return true
          }

          // compare actualWorkTimes
          if (
            (task.actualWorkTimes ?? undefined) !==
            convertScheduledTimeToMinutes(formValue.actualWorkTimes)
          ) {
            return true
          }
        }

        return false
      }) ||
      // check if the row is deleted
      (getTasksForWorkTimesRegisterResult?.tasks &&
        formValues &&
        getTasksForWorkTimesRegisterResult.tasks.length > formValues?.length)

    // Update the form values when the data is fetched
    useValueChangeEffect(
      () => {
        if (getTasksForWorkTimesRegisterResult?.tasks) {
          const data = getTasksForWorkTimesRegisterResult.tasks.map((task) => {
            const startTimeFormated = task.startTime
              ? t('format.datetime', {
                  value: task.startTime,
                  timeZone: defaultUserProfileRegion,
                })
              : null

            const endTimeFormated = task.endTime
              ? t('format.datetime', {
                  value: task.endTime,
                  timeZone: defaultUserProfileRegion,
                })
              : null

            return {
              ...task,
              startTime: startTimeFormated
                ? dayjs(startTimeFormated).toDate()
                : null,
              endTime: endTimeFormated ? dayjs(endTimeFormated).toDate() : null,
              actualWorkTimes:
                typeof task.actualWorkTimes === 'number'
                  ? convertMinutesToHHMM(task.actualWorkTimes)
                  : '',
            }
          })
          replace(data)
        }
      },
      [
        getTasksForWorkTimesRegisterResult?.tasks,
        replace,
        t,
        defaultUserProfileRegion,
      ],
      getTasksForWorkTimesRegisterResult?.tasks
    )

    // Event handler for when the previous date button is clicked
    const handlePrevDateButtonClick = useCallback(() => {
      // Set the current action to "ChangeTargetDate"
      userAction.current = 'PrevDate'

      if (formIsDirty) {
        openConfirmDialog()
      } else {
        const prevDate = getPreviousDate(targetDate)
        setTargetDate(prevDate)

        // update the target copy date
        setTargetCopyDate((prev) => {
          const date = new Date(prevDate)
          date.setDate(date.getDate() - 1)
          return date
        })
      }
    }, [formIsDirty, openConfirmDialog, getPreviousDate, targetDate])

    // Event handler for when the next date button is clicked
    const handleNextDateButtonClick = useCallback(() => {
      // Set the current action to "ChangeTargetDate"
      userAction.current = 'NextDate'

      if (formIsDirty) {
        openConfirmDialog()
      } else {
        const nextDate = getNextDate(targetDate)
        setTargetDate(nextDate)

        // update the target copy date
        setTargetCopyDate((prev) => {
          const date = new Date(nextDate)
          date.setDate(date.getDate() - 1)
          return date
        })
      }
    }, [formIsDirty, getNextDate, openConfirmDialog, targetDate])

    // Event handler for when the tree view item is clicked
    const handleSelectedTask = useCallback(
      (selectedTask: Task) => {
        // 右側の開始時刻、終了時刻が両方未入力の場合：追加しない（現状のまま）
        // 右側の開始時刻、終了時刻のいずれかが入力済みの場合：追加する
        const task = formValues.find(
          (item) =>
            item.taskId === selectedTask.taskId &&
            !item.startTime &&
            !item.endTime
        )
        if (!task) {
          const appendData = {
            ...selectedTask,
            workTimesId: undefined,
            version: undefined,
            startTime: null,
            endTime: null,
            actualWorkTimes: '',
          }

          append(appendData)
        }
      },
      [append, formValues]
    )

    // Event handler for when the submit button is clicked
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit: SubmitHandler<FormValues> = async (values) => {
        try {
          await bulkChangeWorkTimes(
            targetDate,
            values,
            deletedWorkTimes.current
          )

          toast.success(t('message.task.workTimesRegistered'))

          if (isRegisterConsecutively) {
            // If the "Register consecutively" checkbox is checked, increment the target date by 1
            setTargetDate((prev) => {
              const next = new Date(prev)
              next.setDate(next.getDate() + 1)
              return next
            })
          } else {
            props.onCancel()
          }
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [
      handleSubmit,
      bulkChangeWorkTimes,
      targetDate,
      toast,
      t,
      isRegisterConsecutively,
      props,
      showApiErrors,
    ])

    // Event handler for when the target copy date is changed
    const handleTargetCopyDateChange = useCallback(
      async (value: string | number | Date) => {
        setTargetCopyDate(new Date(value))
      },
      []
    )

    // Event handler for when the copy button is clicked
    const handleCopyButtonClick = useCallback(async () => {
      // Set the current action to "Copy"
      userAction.current = 'Copy'

      // If there is data that has been edited, open the confirm dialog
      if (formIsDirty) {
        openConfirmDialog()
      } else {
        const tasks = await copyTasksForWorkTimes(targetCopyDate)
        const data = tasks.map((task) => {
          const startTimeFormated = task.startTime
            ? t('format.datetime', {
                value: task.startTime,
                timeZone: defaultUserProfileRegion,
              })
            : null

          const endTimeFormated = task.endTime
            ? t('format.datetime', {
                value: task.endTime,
                timeZone: defaultUserProfileRegion,
              })
            : null

          return {
            ...task,
            startTime: startTimeFormated
              ? dayjs(startTimeFormated).toDate()
              : null,
            endTime: endTimeFormated ? dayjs(endTimeFormated).toDate() : null,
            actualWorkTimes: task.actualWorkTimes
              ? convertMinutesToHHMM(task.actualWorkTimes)
              : '',
          }
        })
        replace(data)
      }
    }, [
      copyTasksForWorkTimes,
      defaultUserProfileRegion,
      formIsDirty,
      openConfirmDialog,
      replace,
      t,
      targetCopyDate,
    ])

    // Event handler for when the register consecutively checkbox value is changed
    const handleRegisterConsecutiveChange = useCallback(
      (value: boolean | null) => {
        setIsRegisterConsecutively(value ?? false)
      },
      []
    )

    // Event handler for when the cancel button is clicked
    const handleCancelButtonClick = useCallback(() => {
      // Set the current action to "Cancel"
      userAction.current = 'Cancel'

      // If there is data that has been edited, open the confirm dialog
      if (formIsDirty) {
        openConfirmDialog()
      } else {
        props.onCancel()
      }
    }, [formIsDirty, openConfirmDialog, props])

    // Event handler for when the confirm button is clicked unregister action
    const handleConfirmButtonClick = useCallback(async () => {
      closeConfirmDialog()

      switch (userAction.current) {
        case 'Cancel':
          props.onCancel()
          break

        case 'Copy':
          const tasks = await copyTasksForWorkTimes(targetCopyDate)
          const data = tasks.map((task) => {
            const startTimeFormated = task.startTime
              ? t('format.datetime', {
                  value: task.startTime,
                  timeZone: defaultUserProfileRegion,
                })
              : null

            const endTimeFormated = task.endTime
              ? t('format.datetime', {
                  value: task.endTime,
                  timeZone: defaultUserProfileRegion,
                })
              : null

            return {
              ...task,
              startTime: startTimeFormated
                ? dayjs(startTimeFormated).toDate()
                : null,
              endTime: endTimeFormated ? dayjs(endTimeFormated).toDate() : null,
              actualWorkTimes: task.actualWorkTimes
                ? convertMinutesToHHMM(task.actualWorkTimes)
                : '',
            }
          })

          replace(data)
          break
        case 'NextDate':
          const nextDate = getNextDate(targetDate)
          setTargetDate(nextDate)
          break
        case 'PrevDate':
          const prevDate = getPreviousDate(targetDate)
          setTargetDate(prevDate)
          break
      }
    }, [
      closeConfirmDialog,
      copyTasksForWorkTimes,
      defaultUserProfileRegion,
      getNextDate,
      getPreviousDate,
      props,
      replace,
      t,
      targetCopyDate,
      targetDate,
    ])

    // Event handler for when the item delete button is clicked
    const handleItemDelete = useCallback(
      (index: number) => {
        // find the task by index
        const task = fields[index]

        // If the task has a workTimesId, add it to the deletedWorkTimes array
        if (task.workTimesId) {
          deletedWorkTimes.current.push({
            taskWorkId: task.workTimesId,
            version: task.version ?? 0,
          })
        }

        remove(index)
      },
      [fields, remove]
    )

    const isDisableRegisterButton = useMemo(
      () =>
        (!formValues || formValues.length === 0) &&
        deletedWorkTimes.current.length === 0,
      [formValues]
    )

    return (
      <div className="flex flex-col gap-5 max-h-full h-full">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-2.5">
            {/* preve date button */}
            <CrewButton
              stylingMode="text"
              icon={<KeyboardArrowLeft width={24} height={24} />}
              size="xs"
              onClick={handlePrevDateButtonClick}
            />

            {/* display date */}
            <span>
              {t('format.dateWithText', {
                value: targetDate,
              })}
            </span>

            {/* next date button */}
            <CrewButton
              stylingMode="text"
              icon={<KeyboardArrowRight width={24} height={24} />}
              size="xs"
              onClick={handleNextDateButtonClick}
            />
          </div>

          <p className="flex flex-row items-center gap-2">
            <span>{t('label.total')}</span>
            <span className="font-bold text-lg">
              {convertMinutesToHHMM(totalWorkTimes)}
            </span>
          </p>
        </div>

        <div className="flex flex-row gap-2.5 overflow-hidden">
          {/* 検索条件 */}
          <SearchTasksForWorkTimesPanel onTaskSelected={handleSelectedTask} />

          {/* Worktimes form */}
          <div className="flex-1 overflow-auto">
            {/* Form header */}
            <WorkingTimeEntryFormHeader />

            {/* Form input list */}
            <div className="flex flex-col">
              {fields.map((field, index) => (
                <WorkTimesFormItem
                  key={field.id}
                  control={control}
                  index={index}
                  task={field}
                  onValidateTimeRange={validateTimeRange}
                  onValidateActualWorkTimes={validateActualWorkTimes}
                  onValidateActualWorkTimesDuration={
                    validateActualWorkTimesDuration
                  }
                  onValidateRequiredWorkTimes={validateRequiredWorkTimes}
                  onDeleteButtonClick={handleItemDelete}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center gap-2.5">
          {/* 指定した日付からタスクをコピーする。 */}
          <div className="flex flex-row gap-2.5 items-center">
            {/* Date picker */}
            <CrewDateBox
              type="date"
              displayFormat={DatePickerDateFormat.YYYYMMDD}
              onValueChange={handleTargetCopyDateChange}
              value={targetCopyDate}
            />

            {/* コピー */}
            <CrewButton
              text={t('action.copy')}
              type="normal"
              stylingMode="outlined"
              onClick={handleCopyButtonClick}
            />
          </div>

          <div className="flex flex-row items-center gap-2.5">
            {/* 連続して登録する */}
            <CrewCheckBox
              text={t('label.registerConsecutively')}
              value={isRegisterConsecutively}
              onValueChange={handleRegisterConsecutiveChange}
            />

            {/* 登録 */}
            <CrewButton
              text={t('action.register')}
              type="primary"
              onClick={handleSubmitButtonClick}
              disabled={isDisableRegisterButton}
            />

            {/* キャンセル */}
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={handleCancelButtonClick}
            />
          </div>
        </div>

        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={t('message.task.confirmUnregisteredAction')}
          onPermitButtonClick={handleConfirmButtonClick}
          onCancelButtonClick={closeConfirmDialog}
        />
      </div>
    )
  }
)
