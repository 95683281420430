import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { RoleRef } from '@crew/models/refs'

export type FormValues = {
  name: string
  originalRoleId: string | null
}
const formInitialValues: FormValues = {
  name: '',
  originalRoleId: null,
}

export const useAuthoritySettingRoleEntryForm = (roles: RoleRef[]) => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, setValue, getValues, handleSubmit } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Check duplicate role name in existing user defined roles
  const checkDuplicateRoleName = useCallback(() => {
    const name = getValues('name')
    let isValid = true
    roles.forEach((role) => {
      if (role.name === name && role.isUserDefined) {
        isValid = false
      }
    })

    return isValid
  }, [roles, getValues])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      name: {
        required: t('message.general.required'),
        validate: {
          always: () =>
            checkDuplicateRoleName() || t('message.tenant.roleNameDuplicated'),
        },
      },
      originalRoleId: {},
    }),
    [checkDuplicateRoleName, t]
  )

  return {
    control,
    setValue,
    handleSubmit,
    validateRules,
  }
}
