import { memo, FC } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { ProjectDetailMemberEntryForm } from './components/projectSettingMemberEntryForm/projectDetailMemberEntryForm'

type PropsMode = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  userId: string | null
}

export const ProjectDetailMemberEntryDialog: FC<PropsMode> = memo((props) => {
  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <ProjectDetailMemberEntryForm
        isEditMode={props.isEditMode}
        userId={props.userId}
        onSubmit={props.onClose}
        onCancel={props.onClose}
      />
    </Modal>
  )
})
