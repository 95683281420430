import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_HIGH, KEY_ESCAPE_COMMAND, LexicalNode } from 'lexical'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as React from 'react'
import { TOGGLE_LINK_COMMAND } from '@lexical/link'
import { usePopper } from 'react-popper'
import classNames from 'classnames'
import Check from '~icons/material-symbols/check'
import Close from '~icons/material-symbols/close'
import LinkOff from '~icons/material-symbols/link-off'
import { createPortal } from 'react-dom'
import { sanitizeUrl } from '../../utils/lexical'

type LinkEditorProps = {
  isOpen: boolean
  anchorNode: LexicalNode
  anchorElement?: Element
  url: string
  onClose: () => void
}

export function LinkEditor({
  isOpen,
  anchorNode,
  anchorElement,
  url,
  onClose,
}: LinkEditorProps) {
  const [editor] = useLexicalComposerContext()
  const inputRef = useRef<HTMLInputElement>(null)
  const [editedLinkUrl, setEditedLinkUrl] = useState(url)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const referenceElement = useMemo(
    () => anchorElement ?? editor.getElementByKey(anchorNode.getKey()),
    [anchorElement, anchorNode, editor]
  )
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  })

  console.log('LinkEditor', isOpen, anchorNode, anchorElement, url)

  const handleApplyButtonClick = useCallback(() => {
    console.log('handleApplyButtonClick', sanitizeUrl(editedLinkUrl), editor)
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl))
    editor.focus()
    setEditedLinkUrl('https://')
    onClose()
  }, [editedLinkUrl, editor, onClose])

  const handleLinkOffButtonClick = useCallback(() => {
    console.log('handleLinkOffButtonClick')
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    editor.focus()
    setEditedLinkUrl('https://')
    onClose()
  }, [editor, onClose])

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          console.log('KEY_ESCAPE_COMMAND')
          onClose()
          return true
        },
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [editor, onClose])

  useEffect(() => {
    console.log('LinkEditor useEffect')
    if (isOpen && inputRef.current) {
      console.log('LinkEditor useEffect focus')
      inputRef.current.focus()
    }
    setEditedLinkUrl(url)
  }, [url, isOpen])

  const monitorInputInteraction = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleApplyButtonClick()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      onClose()
    }
  }

  return (
    <>
      {isOpen &&
        createPortal(
          <div className="relative">
            <div className="fixed inset-0 z-10" onClick={onClose}></div>
            <div
              style={{ ...styles.popper }}
              {...attributes.popper}
              className={classNames(
                'flex overflow-hidden rounded items-center p-1 text-sm shadow crew-shadow-gray crew-bg-default',
                // because modal has z-60, so we need to set z-70 to make sure it's on top of modal
                'z-70'
              )}
              ref={setPopperElement}
            >
              <input
                ref={inputRef}
                className="crew-input w-60 mr-1"
                value={editedLinkUrl}
                onChange={(event) => {
                  setEditedLinkUrl(event.target.value)
                }}
                onKeyDown={(event) => {
                  monitorInputInteraction(event)
                }}
              />
              <div
                className="link-confirm flex items-center p-1 rounded crew-hover-gray-3"
                role="button"
                tabIndex={0}
                onMouseDown={(event) => event.preventDefault()}
                onClick={handleApplyButtonClick}
              >
                <Check width={24} height={24} />
              </div>
              <div
                className="link-confirm flex items-center p-1 rounded crew-hover-gray-3"
                role="button"
                tabIndex={0}
                onMouseDown={(event) => event.preventDefault()}
                onClick={handleLinkOffButtonClick}
              >
                <LinkOff width={24} height={24} />
              </div>
              <div
                className="link-cancel flex items-center p-1 rounded crew-hover-gray-3"
                role="button"
                tabIndex={0}
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                  onClose()
                }}
              >
                <Close width={24} height={24} />
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  )
}
