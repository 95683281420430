import { useEffect, useRef } from 'react';
// 未初期化を表すsymbolとその型。
// 意図的に初期値としてundefinedを使うパターンもありうるため、symbolを使って唯一無二の値を生成する
/**
 * 未初期化
 */
// eslint-dis
export const Uninitialized = Symbol('uninitialized');
/**
 * 特定の変数の変更を検出して、その時だけ処理を実行するカスタムフック
 * @param callback 実行したい処理
 * @param deps 依存配列
 * @param value 監視したい変数
 * @param skipOnInit 初期化時に処理をスキップするかどうか。デフォルトはfalse（スキップしない）
 * @param compareFn オプションの比較関数
 */
export function useValueChangeEffect(callback, deps = [], value, skipOnInit, compareFn) {
    // 前回の値を保持するref。
    // 初期化時に処理を実行する場合はUninitializedを入れる。
    const prevValueRef = useRef(skipOnInit === undefined || skipOnInit === false //省略時はfalse（スキップしない）とみなす
        ? Uninitialized
        : value);
    useEffect(() => {
        // 値が変更されたかを確認する
        const hasChanged = 
        // 前回の値が初期化値の場合は必ず変更されているとみなす
        prevValueRef.current === Uninitialized ||
            // compareFnが指定されている場合はそれを使って値の変更を検知する
            (compareFn
                ? !compareFn(prevValueRef.current, value)
                : prevValueRef.current !== value);
        // 値が変更されていたらコールバックを実行する
        if (hasChanged) {
            callback(prevValueRef.current, value);
            prevValueRef.current = value;
        }
        // 引数のdepsの要素を依存に入れる必要があるが、スプレッドで書くと警告が出るため回避する
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, compareFn, callback, ...deps]);
}
