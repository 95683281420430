import { StripeError } from '@stripe/stripe-js'
import React, { memo } from 'react'

import { t } from '@crew/modules/i18n'
import classNames from 'classnames'

interface Props {
  stripeError: StripeError | undefined
  className?: string | undefined
}

const CardErrorCodeWithDedicatedMessage = [
  // 支払い拒否
  'card_declined',
  // 期限切れ
  'expired_card',
  // CVC不一致
  'incorrect_cvc',
  // 処理エラー。リトライすればよいので標準エラーメッセージで巻き取る
  // 'processing_error',
  // カード番号誤り
  'incorrect_number',
]

const format = (error: StripeError): string => {
  if (
    error.type === 'card_error' &&
    error.code !== undefined &&
    CardErrorCodeWithDedicatedMessage.includes(error.code)
  ) {
    return t(
      `message.contract.registerCreditCardErrorWithDedicatedMessage.${error.code}`
    )
  }
  return t('message.contract.registerCreditCardGeneralError')
}

export const StripeErrorFormat: React.FC<Props> = memo((props) => {
  if (!props.stripeError) {
    return null
  }

  return (
    <p className={classNames('crew-error-text', props.className)}>
      {format(props.stripeError)}
    </p>
  )
})
