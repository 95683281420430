import { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import {
  FolderMenuContextItems,
  NotifyEventType,
  UnsortedFolder,
  isFolderContextMenuItem,
} from 'enums/app'
import { FolderEntryDialog } from './components/folderEntryDialog/folderEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { ContextMenu } from 'devextreme-react'
import { useTranslation } from '@crew/modules/i18n'
import TreeView from 'devextreme-react/tree-view'
import { ContentReadyEvent, ItemClickEvent } from 'devextreme/ui/tree_view'
import { FolderItem } from './components/folderItem/folderItem'
import { CrewContextMenu } from 'components/devextreme/crewContextMenu'
import { ComponentCallbackHandler } from '@crew/utils'
import { CrewConfirmDialog } from '../crewConfirmDialog/crewConfirmDialog'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { ShowingEvent } from 'devextreme/ui/context_menu'
import { EntityType } from '@crew/enums/domain'
import { useGetFolderTreeQuery } from '@crew/apis/folder/folderApis'
import { GetFolderTreeResponse_Tree } from '@crew/apis/folder/models/getFolderTree/response'
import { useCrewFolderList } from './useCrewFolderList'
import { useToast } from 'hooks/useToast'
import { cloneDeep } from 'lodash'
import { useAppSelector } from 'states/hooks'
import { DragEndEvent } from 'devextreme/ui/sortable'
import { useValueChangeEffect } from '@crew/hooks'
import { useProjectPermissions } from '@crew/hooks'
import { CrewTreeView } from 'components/devextreme/CrewTreeView'

// Function to remove a specific folder from a hierarchy of folders
const removeItemFromHierarchy = (
  folders: GetFolderTreeResponse_Tree[],
  folderId: string
) => {
  // Create a copy of the current folders array
  const updatedFolders = [...folders]

  // Define a helper function to remove the target folder from a folder
  const removeItem = (folder: GetFolderTreeResponse_Tree) => {
    // Filter out the target folder from the children of the current folder
    folder.children =
      folder.children &&
      folder.children.filter((child) => {
        // If the child folder is the target folder, exclude it from the new children array
        if (child.id === folderId) {
          return false
        }

        // If the child folder has its own children, recursively call the helper function on it
        if (child.children) {
          removeItem(child)
        }

        // If the child folder is not the target folder, include it in the new children array
        return true
      })
  }

  // Call the helper function for each folder in the hierarchy
  const filtered = updatedFolders.filter((folder) => {
    if (folder.id === folderId) {
      return false
    } else {
      removeItem(folder)
    }

    return true
  })

  // Return the updated hierarchy of folders
  return filtered
}

// Function to insert a target folder into a hierarchy of folders
const insertItemToHierarchy = (
  folders: GetFolderTreeResponse_Tree[],
  targetFolder: GetFolderTreeResponse_Tree,
  parentFolderId: string | undefined
) => {
  // Create a copy of the current folders array
  const updatedFolders = [...folders]

  // Define a helper function to insert the target folder into a folder
  const insertItem = (folder: GetFolderTreeResponse_Tree) => {
    // Check if the current folder is the parent folder
    if (folder.id === parentFolderId) {
      // If the folder doesn't have any children yet, initialize an empty array
      if (!folder.children) {
        folder.children = []
      }

      // Add the target folder to the children of the parent folder
      folder.children.push({
        ...targetFolder,
        parentFolderId,
        // この処理は、親フォルダ更新APIを呼び出す処理の前に実行されるため、手動でバージョンアップする必要がある。
        version: targetFolder.version + 1, // Increment the version number
      })
    } else {
      // If the current folder is not the parent folder, recursively call the helper function for each child folder
      folder.children &&
        folder.children.forEach((child) => {
          insertItem(child)
        })
    }
  }

  if (!parentFolderId) {
    // If the parent folder id is not specified, add the target folder to the root folder
    updatedFolders.push({
      ...targetFolder,
      // この処理は、親フォルダ更新APIを呼び出す処理の前に実行されるため、手動でバージョンアップする必要がある。
      version: targetFolder.version + 1, // Increment the version number
    })
  } else {
    // Call the helper function for each folder in the hierarchy
    updatedFolders.forEach((folder) => {
      insertItem(folder)
    })
  }

  // Return the updated hierarchy of folders
  return updatedFolders
}

// check valid drag event, we cant move parent folder to child folder hierarchy
const isDraggedFolderNotDescendant = (
  folders: GetFolderTreeResponse_Tree[],
  fromFolderId: string,
  toFolderId: string
) => {
  let isValid = false

  // Define a helper function to check if the target folder is not a descendant of the current folder
  // toFolder parentFolderId === fromFolderId then return false
  const checkValid = (folder: GetFolderTreeResponse_Tree) => {
    if (folder.id === toFolderId) {
      isValid = folder.parentFolderId !== fromFolderId
    }

    // If the current folder has children, recursively call the helper function for each child folder
    if (folder.children) {
      folder.children.forEach((child) => {
        checkValid(child)
      })
    }
  }

  // Call the helper function for each folder in the hierarchy
  folders.forEach((folder) => {
    checkValid(folder)
  })

  return isValid
}

// Function to update the tree data with the updated folder data
const updateTreeData = (
  folders: GetFolderTreeResponse_Tree[],
  folderId: string,
  updatedFolder: GetFolderTreeResponse_Tree
) => {
  const updatedFolders: GetFolderTreeResponse_Tree[] = folders.map((folder) => {
    // If the current folder is the target folder, replace it with the updated folder
    if (folder.id === folderId) {
      return {
        ...folder,
        name: updatedFolder.name,
        version: updatedFolder.version,
      }
    }

    // If the current folder has children, recursively call the function on each child folder
    if (folder.children) {
      return {
        ...folder,
        // Update the children array with the updated child folder
        children: updateTreeData(folder.children, folderId, updatedFolder),
      }
    }

    return folder
  })

  return updatedFolders
}

type TargetFolder = {
  id: string
  name: string
  version: number
}

type CrewFolderListProps = {
  entityType: EntityType
  entityRecordId: string
  onFolderItemClick?: (folderId: string) => void
  showContextMenu?: boolean
  disableDragAndDrop?: boolean
}

export const CrewFolderList: FC<CrewFolderListProps> = memo((props) => {
  const { t } = useTranslation()
  const [showApiErrors] = useShowApiErrors()
  const toast = useToast()

  const folderEventMessage = useAppSelector(
    (state) => state.app.folderEventMessage
  )

  const { deleteFolder, moveFolderToFolder } = useCrewFolderList()

  const contextMenuRef = useRef<ContextMenu>(null)
  const treeViewRef = useRef<TreeView<GetFolderTreeResponse_Tree> | null>(null)
  const [isFolderEditMode, setFolderEditMode] = useState(false)
  const [selectedFolderId, setSelectedFolderId] = useState<string>(
    UnsortedFolder.value
  )

  // Get permission to delete folder for logged in user
  const {
    hasPrjFolderDeletePermission,
    hasPrjFolderEditPermission,
    hasPrjFolderCreatePermission,
  } = useProjectPermissions(EntityType.Folders, props.entityRecordId)

  const [
    isFolderEntryDialogOpen,
    openFolderEntryDialog,
    closeFolderEntryDialog,
  ] = useModal()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const { data: getFolderTreeResult, refetch: getFolderTreeRefetch } =
    useGetFolderTreeQuery({
      entityType: props.entityType,
      entityRecordId: props.entityRecordId,
    })

  // Refetch the folder tree
  const handleRefetchFolderTree = useCallback(() => {
    getFolderTreeRefetch()
  }, [getFolderTreeRefetch])

  const [folders, setFolders] = useState<GetFolderTreeResponse_Tree[]>([])

  // フォルダの登録・更新・削除のイベントが発生した際に、フォルダ一覧を再読み込みするための処理
  useValueChangeEffect(
    () => {
      if (!folderEventMessage) return

      if (folderEventMessage?.eventType === NotifyEventType.Inserted) {
        // If the folder is inserted, add it to the folder tree
        const latestItems = insertItemToHierarchy(
          folders,
          {
            id: folderEventMessage.id,
            name: String(folderEventMessage.object?.name),
            parentFolderId: folderEventMessage.object?.parentFolderId ?? null,
            // この処理の他に、insertItemToHierarchy()メソッドはhandleFolderItemDragEnd()の処理でも呼び出されている
            // handleFolderItemDragEnd()のロジックでは、insertItemToHierarchy()メソッドでバージョンの値を1増やしている
            // そのため、このでバージョンを0とする必要がある
            version: 0,
            children: [],
          },
          folderEventMessage.object?.parentFolderId ?? undefined
        )
        setFolders(latestItems)
      } else if (folderEventMessage.eventType === NotifyEventType.Updated) {
        // If the folder is updated, update the folder tree data
        const updatedFolderData = {
          id: folderEventMessage.id,
          name: String(folderEventMessage.object?.name),
          version: Number(folderEventMessage.object?.version),
          parentFolderId: folderEventMessage.object?.parentFolderId ?? null,
          // we don't need to update children
          children: [],
        }

        const updatedItems = updateTreeData(
          folders,
          folderEventMessage.id,
          updatedFolderData
        )
        setFolders(updatedItems)
      } else if (folderEventMessage.eventType === NotifyEventType.Deleted) {
        // If the folder is deleted, remove it from the folder tree
        const updatedItems = removeItemFromHierarchy(
          folders,
          folderEventMessage.id
        )

        setFolders(updatedItems)
      } else {
        // If the event type is not Inserted, Updated, or Deleted, refetch the folder tree data
        handleRefetchFolderTree()
      }
    },
    [folderEventMessage, folders, handleRefetchFolderTree],
    folderEventMessage
  )

  useValueChangeEffect(
    () => {
      const defaultFolderTree = [
        {
          id: UnsortedFolder.value,
          name: t(UnsortedFolder.text),
          children: [],
          version: 0,
          parentFolderId: null,
        },
      ]

      if (!getFolderTreeResult?.folderTree) {
        setFolders(defaultFolderTree)
      } else {
        // Clone the folder tree data because the original data is read-only
        const folderTree = cloneDeep(getFolderTreeResult.folderTree)

        setFolders([...defaultFolderTree, ...folderTree])
      }
    },
    [getFolderTreeResult?.folderTree, t],
    getFolderTreeResult?.folderTree
  )

  // Event handler for when a folder item is dragged and dropped
  const handleFolderItemDragEnd = useCallback(
    async (event: DragEndEvent) => {
      // Prevent moving to the unsorted folder or the same folder
      if (
        event.toData.id === UnsortedFolder.value ||
        event.fromData.id === event.toData.id
      )
        return

      if (
        !isDraggedFolderNotDescendant(
          folders,
          event.fromData.id,
          event.toData.id
        )
      ) {
        toast.error(t('message.apiError.notAllowMoveParentFolderIntoChild'))
        return
      }

      // Check from data has parent folder remove item from the parent folder data
      const updatedFolders = removeItemFromHierarchy(folders, event.fromData.id)

      // Add the item to the destination folder
      const latestFolders = insertItemToHierarchy(
        updatedFolders,
        event.fromData,
        // If the dropped item is outside another item, move it to same level of target item
        event.dropInsideItem ? event.toData.id : event.toData.parentFolderId
      )

      setFolders(latestFolders)

      try {
        await moveFolderToFolder(
          event.fromData.id,
          // If the dropped item is outside another item, set its parent same the parent of the target item
          event.dropInsideItem ? event.toData.id : event.toData.parentFolderId,
          event.fromData.version
        )
      } catch (err) {
        // Refetch the folder tree data when an error occurs
        handleRefetchFolderTree()
        showApiErrors(err)
      }
    },
    [
      folders,
      handleRefetchFolderTree,
      moveFolderToFolder,
      showApiErrors,
      t,
      toast,
    ]
  )

  // Event handler for when a folder item is clicked
  const handleFolderItemClick = useCallback(
    (event: ItemClickEvent) => {
      // If there is no item data, exit the function
      if (!event.itemData) return

      // Select the clicked item
      event.component.selectItem(event.itemData.id)

      // Set the selected folder id
      setSelectedFolderId(event.itemData.id as string)

      // Call the onFolderItemClick event handler
      props.onFolderItemClick?.(event.itemData.id as string)
    },
    [props]
  )

  const [targetFolder, setTargetFolder] = useState<TargetFolder | null>(null)
  const targetFolderMenuContextRef = useRef<TargetFolder | null>(null)

  // open context menu when click three dots icon
  const handleOpenContextMenu = useCallback(
    (conextMenuTarget: TargetFolder) => {
      // set target folder id
      targetFolderMenuContextRef.current = conextMenuTarget

      // set target element for context menu
      contextMenuRef.current?.instance.option(
        'target',
        `#context-menu-${conextMenuTarget.id}`
      )

      // show context menu
      contextMenuRef.current?.instance.show()
    },
    []
  )

  // render folder item
  const renderFolder = useCallback(
    (folder: GetFolderTreeResponse_Tree) => {
      return (
        <FolderItem
          folder={folder}
          onOpenFolderEntryDialog={openFolderEntryDialog}
          onOpenContextMenu={handleOpenContextMenu}
          showContextMenu={props.showContextMenu}
          disableDragAndDrop={props.disableDragAndDrop}
          onDragEnd={handleFolderItemDragEnd}
        />
      )
    },
    [
      handleOpenContextMenu,
      openFolderEntryDialog,
      props.disableDragAndDrop,
      props.showContextMenu,
      handleFolderItemDragEnd,
    ]
  )

  // context menu items
  const contextMenuItems = useMemo(() => {
    const folderMenuContextItems = Object.entries(
      FolderMenuContextItems
    ).filter(([key, item]) => {
      if (
        !hasPrjFolderCreatePermission &&
        item.value === FolderMenuContextItems.CreateSubFolder.value
      ) {
        // Do not show Create subfolder item on context menu if logged in user don't have permission to create folder
        return false
      }

      if (
        !hasPrjFolderDeletePermission &&
        item.value === FolderMenuContextItems.DeleteFolder.value
      ) {
        // Do not show Delete item on context menu if logged in user don't have permission to delete folder
        return false
      }

      if (
        !hasPrjFolderEditPermission &&
        item.value === FolderMenuContextItems.EditFolder.value
      ) {
        // Do not show Edit item on context menu if logged in user don't have permission to edit folder
        return false
      }

      return true
    })

    return folderMenuContextItems.map(([key, item]) => {
      return {
        text: t(item.text),
        value: item.value,
      }
    })
  }, [
    hasPrjFolderCreatePermission,
    hasPrjFolderDeletePermission,
    hasPrjFolderEditPermission,
    t,
  ])

  //call when click item of context menu
  const handleContextMenuItemClick = useCallback<
    ComponentCallbackHandler<typeof CrewContextMenu, 'onItemClick'>
  >(
    (event) => {
      const item = event.itemData
      if (!isFolderContextMenuItem(item)) {
        return
      }

      // set target folder
      setTargetFolder(targetFolderMenuContextRef.current)

      switch (item.value) {
        // create subfolder click
        case FolderMenuContextItems.CreateSubFolder.value:
          setFolderEditMode(false)
          openFolderEntryDialog()
          break
        //edit folder action click
        case FolderMenuContextItems.EditFolder.value:
          setFolderEditMode(true)
          openFolderEntryDialog()
          break
        //delete folder action click
        case FolderMenuContextItems.DeleteFolder.value:
          // Open delete confirmation dialog
          openConfirmDialog()
          break
      }
    },
    [openConfirmDialog, openFolderEntryDialog]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    closeConfirmDialog()

    try {
      if (!targetFolder) return

      await deleteFolder(targetFolder.id, targetFolder.version)

      toast.success(t('message.folder.folderDeleted'))
    } catch (err) {
      showApiErrors(err)
    }
  }, [closeConfirmDialog, deleteFolder, showApiErrors, t, targetFolder, toast])

  // Event handler for when the tree view content is ready
  const onContentReady = useCallback(
    (event: ContentReadyEvent) => {
      event.component.selectItem(selectedFolderId)
    },
    [selectedFolderId]
  )

  // Event handler before the context menu is shown
  const handleMenuContextShowingEvent = useCallback((event: ShowingEvent) => {
    // Stop the default context menu from appearing
    if (!targetFolderMenuContextRef.current) event.cancel = true
  }, [])

  // Event handler after the context menu is hidden
  const handleMenuContextHidingEvent = useCallback(() => {
    // Reset the target folder id
    targetFolderMenuContextRef.current = null
  }, [])

  return (
    <div className="w-full min-w-0">
      <CrewTreeView
        id="treeview"
        ref={treeViewRef}
        dataSource={folders}
        keyExpr="id"
        displayExpr="name"
        selectionMode="single"
        itemsExpr="children"
        dataStructure="tree"
        itemRender={renderFolder}
        onItemClick={handleFolderItemClick}
        focusStateEnabled={false}
        hoverStateEnabled={false}
        onContentReady={onContentReady}
      />

      {/* folder item context menu */}
      <CrewContextMenu
        ref={contextMenuRef}
        dataSource={contextMenuItems}
        onItemClick={handleContextMenuItemClick}
        keyExpr="value"
        displayExpr="text"
        onShowing={handleMenuContextShowingEvent}
        onHiding={handleMenuContextHidingEvent}
      />

      {/* 新規フォルダーの登録/フォルダの編集 */}
      {isFolderEntryDialogOpen && (
        <FolderEntryDialog
          key={isFolderEditMode ? 'edit' : 'register'}
          isOpen={isFolderEntryDialogOpen}
          isEditMode={isFolderEditMode}
          title={
            isFolderEditMode ? t('label.editFolder') : t('label.registerFolder')
          }
          onClose={closeFolderEntryDialog}
          folderId={targetFolder?.id ?? null}
          entityType={props.entityType}
          entityRecordId={props.entityRecordId}
        />
      )}

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={t('message.folder.folderDeleteConfirm')}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </div>
  )
})
