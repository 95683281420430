import {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react'
import Scheduler, { Resource, SchedulerTypes } from 'devextreme-react/scheduler'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { useScheduleMonthly } from './useScheduleMonthly'
import { DateCell } from './components/dateCell/dateCell'
import { DataCell } from './components/dataCell/dataCell'
import { useTranslation } from '@crew/modules/i18n'
import { Appointment } from './components/appointment/appointment'
import {
  CrewEventPopover,
  EventPopover,
} from 'components/elements/crewEventPopover/crewEventPopover'
import { useAppSelector } from 'states/hooks'
import { EventEntryDialog } from 'features/project/components/eventEntryDialog/eventEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/system'
import { useValueChangeEffect } from '@crew/hooks'
import { EventKindRef, UserRef } from '@crew/models/refs'

type Event = {
  id: string | null
  subject: string | null
  description: string | null
  startDatetime: string
  endDatetime: string
  entityRecordId: string | null
  eventKind: EventKindRef | null
  eventAttendees: UserRef[]
}

type AppointmentType = {
  data: { appointmentData: Event }
}

export const ScheduleMonthly: FC = memo(() => {
  const { t } = useTranslation()

  const schedulerRef = useRef<Scheduler>(null)

  const [currentDate, setCurrentDate] = useState(new Date())

  const [isEventEntryDialogOpen, openEventEntryDialog, closeEventEntryDialog] =
    useModal()

  const { eventKindDataSource, eventDataSource } =
    useScheduleMonthly(schedulerRef)

  const eventEventMessage = useAppSelector(
    (state) => state.app.eventEventMessage
  )

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  useValueChangeEffect(
    () => {
      eventDataSource.reload()
    },
    [eventEventMessage, eventDataSource],
    eventEventMessage
  )

  // Customize date navigator display text
  const customizeDateNavigatorText = useCallback(
    (info: SchedulerTypes.DateNavigatorTextInfo) => {
      return t('format.yearMonthWithText', {
        value: info.startDate,
      })
    },
    [t]
  )

  // Event handler for when the current date is changed
  const handleCurrentDateChange = useCallback(
    (value: string | number | Date) => {
      setCurrentDate(new Date(value))
    },
    []
  )

  // Event handler for when an appointment is rendered
  const handleAppointmentRendered = useCallback(
    (e: SchedulerTypes.AppointmentRenderedEvent) => {
      // TODO: イベントカレンダー：カレンダー上のイベントの表示問題
      //    https://break-tmc.atlassian.net/browse/CREW-15911

      // If is private event (id is null), set the background color to gray
      if (!e.appointmentData.id) {
        e.appointmentElement.style.backgroundColor = '#d4d4d4'
        // Add disabled class
        e.appointmentElement.className += ' dx-state-disabled'
      }
    },
    []
  )

  // Event handler for when an appointment is clicked
  const handleAppointmentClick = useCallback(
    (e: SchedulerTypes.AppointmentClickEvent) => {
      // If is private event (id is null), cancel the event
      if (!e.appointmentData.id) {
        e.cancel = true
      }
    },
    []
  )

  // Event handler for when an appointment is double clicked
  const handleAppointmentDblClick = useCallback(
    (e: SchedulerTypes.AppointmentDblClickEvent) => {
      e.cancel = true

      // If is private event (id is null), cancel the event
      if (!e.appointmentData.id) return
    },
    []
  )

  // close appointment tooltip
  const handleCloseTooltip = useCallback(() => {
    schedulerRef.current?.instance.hideAppointmentTooltip()
  }, [])

  // Render appointment tooltip
  const renderAppointmentTooltip = useCallback(
    (item: AppointmentType) => {
      // when the event is private, the appointmentData property is null
      // so return null to prevent the tooltip from being displayed
      if (
        !item.data.appointmentData.id ||
        !item.data.appointmentData.eventKind ||
        !item.data.appointmentData.subject ||
        !item.data.appointmentData.entityRecordId
      ) {
        return null
      }

      const data: EventPopover = {
        id: item.data.appointmentData.id,
        description: item.data.appointmentData.description,
        attendees: item.data.appointmentData.eventAttendees,
        startDatetime: item.data.appointmentData.startDatetime,
        endDatetime: item.data.appointmentData.endDatetime,
        entityRecordId: item.data.appointmentData.entityRecordId,
        eventKind: item.data.appointmentData.eventKind,
        subject: item.data.appointmentData.subject,
      }
      return (
        <CrewEventPopover data={data} onCloseTooltip={handleCloseTooltip} />
      )
    },
    [handleCloseTooltip]
  )

  // Register event finish
  const handleEventRegistered = useCallback(
    (eventId: string, deleteFlg: boolean = false) => {
      // TODO: Web: イベント登録ダイアログを修正する
      // https://break-tmc.atlassian.net/browse/CREW-14998

      // Close entry dialog
      closeEventEntryDialog()
    },
    [closeEventEntryDialog]
  )

  const startDatetime = useRef<Date>()
  const endDatetime = useRef<Date>()

  // 空欄をクリックすると新規イベント登録画面を表示する。
  const handleOpenEventEntryDialog = useCallback(
    (e: SchedulerTypes.AppointmentFormOpeningEvent) => {
      e.cancel = true

      // 開始日/終了日=クリックした日付
      // 開始時刻=システム時刻(分は切り上げ)
      // 終了時刻=開始時刻+1時間
      startDatetime.current = new Date(
        dayjs(e.appointmentData?.startDatetime)
          .tz(String(defaultUserProfileRegion))
          .format(JsonDateFormat.YYYYMMDDHHmmss)
      )
      startDatetime.current.setHours(new Date().getHours() + 1)

      endDatetime.current = new Date(
        dayjs(e.appointmentData?.endDatetime)
          .tz(String(defaultUserProfileRegion))
          .subtract(1, 'day')
          .format(JsonDateFormat.YYYYMMDDHHmmss)
      )
      endDatetime.current.setHours(new Date().getHours() + 1)

      // Open crew event entry dialog
      openEventEntryDialog()
    },
    [defaultUserProfileRegion, openEventEntryDialog]
  )

  // Render date cell
  const renderDateCell = useCallback(({ date }: { date: Date }) => {
    return <DateCell date={date} />
  }, [])

  // render data cell
  const renderDataCell = useCallback(
    (
      props: PropsWithChildren<{
        data: {
          startDate: Date
        }
      }>
    ) => {
      return <DataCell {...props} />
    },
    []
  )

  const [schedulerWidth, setSchedulerWidth] = useState<number>()

  // Listen layout change to update schedule width
  // Cell item position/width will be recalculated by the devextreme after update schedule width
  const schedulerWrapperRef = useCallback((node: HTMLDivElement) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() =>
      setSchedulerWidth(node.offsetWidth)
    )
    resizeObserver.observe(node)
  }, [])

  return (
    <div className="flex-1" ref={schedulerWrapperRef}>
      <Scheduler
        id="myMonthlyScheduler"
        // Set the `custom-scheduler` class  to display Scheduler like Figma
        className="custom-scheduler"
        timeZone={defaultUserProfileRegion as string}
        ref={schedulerRef}
        dataSource={eventDataSource}
        defaultCurrentView="month"
        defaultCurrentDate={currentDate}
        currentView={'month'}
        startDateExpr="startDatetime"
        endDateExpr="endDatetime"
        dateCellRender={renderDateCell}
        dataCellComponent={renderDataCell}
        onCurrentDateChange={handleCurrentDateChange}
        customizeDateNavigatorText={customizeDateNavigatorText}
        showCurrentTimeIndicator={false}
        appointmentComponent={Appointment}
        onAppointmentRendered={handleAppointmentRendered}
        onAppointmentClick={handleAppointmentClick}
        onAppointmentDblClick={handleAppointmentDblClick}
        height="100%"
        appointmentTooltipComponent={renderAppointmentTooltip}
        onAppointmentFormOpening={handleOpenEventEntryDialog}
        allDayExpr="isAllDay"
        width={schedulerWidth}
      >
        <Resource
          dataSource={eventKindDataSource}
          fieldExpr="eventKind.id"
          useColorAsDefault={true}
          colorExpr="displayColor"
        />
      </Scheduler>

      <EventEntryDialog
        isEditMode={false}
        projectId={''}
        title={t('label.registerNewMeeting')}
        isOpen={isEventEntryDialogOpen}
        onClose={closeEventEntryDialog}
        onSubmit={handleEventRegistered}
        startDatetime={startDatetime.current}
        endDatetime={endDatetime.current}
      />
    </div>
  )
})
