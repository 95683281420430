import { FC, memo, useCallback, useMemo, useState } from 'react'
import { Path, useLocation, useParams } from 'react-router-dom'

import { isApiErrorResult } from '@crew/apis/errors'
import { HttpStatusCode } from '@crew/enums/system'
import { useTranslation } from '@crew/modules/i18n'

import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useAppSelector } from 'states/hooks'

import {
  FormValues,
  useAppLoginVerifyCodePage,
} from './useAppLoginVerifyCodePage'

// referrerの型はuseCrewNavigate.tsのnavigateWrapperの第一引数と揃えること
type LocationState = { referrer: Partial<Path> } | undefined

export const AppLoginVerifyCodePage: FC = memo(() => {
  const { control, handleSubmit, validateRules, verifyAuthCode } =
    useAppLoginVerifyCodePage()

  const { token } = useParams()

  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()
  const locationState = useLocation().state as LocationState
  const [serverErrorMessage, setServerErrorMessage] = useState('')
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  const handleFormSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      e.preventDefault()
      const onSubmit = async (values: FormValues) => {
        if (!token) {
          setServerErrorMessage(t('message.auth.failedToLogin'))
          return
        }
        try {
          // verify login code
          await verifyAuthCode(values, token)

          // リダイレクト
          //     遷移元の情報がある場合：遷移元に遷移
          //     上記以外             ：ルート(ホーム画面)へ遷移
          navigate(locationState?.referrer.pathname ?? '/')
        } catch (err) {
          if (isApiErrorResult(err) && err.status === HttpStatusCode.Locked) {
            setServerErrorMessage(t('message.auth.lockedUser'))
          } else {
            setServerErrorMessage(t('message.auth.failedToLogin'))
          }
        }
      }
      handleSubmit(onSubmit)()
    },
    [
      handleSubmit,
      token,
      t,
      verifyAuthCode,
      navigate,
      locationState?.referrer.pathname,
    ]
  )

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  return (
    // Login Step 2 form
    <div className="flex flex-row h-screen pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-sm">
        <div className="flex flex-row items-center sm:mb-6">
          {/* ロゴ */}
          <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto" />
        </div>
        <div className="flex flex-col items-center justify-center">
          <span>{t('label.authenticationCodeHint.line1')}</span>
          <span>{t('label.authenticationCodeHint.line2')}</span>
        </div>
        <form onSubmit={handleFormSubmit} className="flex flex-col gap-2.5">
          <div className="flex flex-col gap-2">
            {/* サーバーエラーメッセージ */}
            <div
              className="crew-error-text text-center"
              data-testid="login-form-server-error-message"
            >
              {serverErrorMessage}
            </div>
            {/* 認証コード */}
            <div>
              <CrewTextBoxField
                control={control}
                id="loginCode"
                name="loginCode"
                placeholder={t('label.authenticationCode')}
                labelMode="floating"
                showLabel={false}
                rules={validateRules.loginCode}
              />
            </div>
          </div>

          {/* Login button */}
          <div className="flex flex-row justify-center">
            <CrewButton
              className="grow"
              type="primary"
              useSubmitBehavior={true}
              text={t('action.login')}
            />
          </div>
        </form>
      </div>
    </div>
  )
})
