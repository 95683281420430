import { useCallback } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { MultipleFieldErrors, UseFormSetError } from 'react-hook-form'

import { useToast } from 'hooks/useToast'
import {
  IsApiErrorJwtMissingError,
  isApiErrorResult,
  IsApiErrorResultValidationFailed,
} from '@crew/apis/errors'
import { convertApiErrorValidationFailed } from '@crew/utils/form'

/**
 * ApiErrorをトーストに表示するカスタムフック
 * @returns
 */
export const useShowApiErrors = () => {
  const toast = useToast()
  const [t] = useTranslation()

  const showApiErrors = useCallback(
    (error: unknown) => {
      // ApiErrorの場合はcodeから文字列リソースを取得する
      const decodedError = isApiErrorResult(error)

      if (decodedError) {
        // JWTがないエラーの場合のみtoast表示しない
        const isJwtMissing = IsApiErrorJwtMissingError(error.data)
        if (!isJwtMissing) {
          toast.error(
            t(
              `message.apiError.${error.data.code}`,
              error.data as { [key: string]: any }
            )
          ) // errorの内容をformatして表示出来るようにerror自体をmapとして渡す
        }
      } else {
        // ApiErrorでない場合はエラー自体が不明エラーを表示する
        toast.error(
          t('message.apiError.unknownResult', {
            error: JSON.stringify(error),
          })
        )
      }
    },
    [t, toast]
  )

  return [showApiErrors]
}

/**
 * ApiErrorがバリデートエラーだったらformにsetErrorし、そうでなかったらトーストに表示するカスタムフック
 * @param setError
 * @returns
 */
export const useShowApiErrorsWithForm = (setError: UseFormSetError<any>) => {
  const [generalShowApiErrors] = useShowApiErrors()

  const showApiErrors = useCallback(
    (error: unknown) => {
      if (
        isApiErrorResult(error) &&
        IsApiErrorResultValidationFailed(error.data)
      ) {
        // ValidateErrorの場合はformにset errorする
        convertApiErrorValidationFailed(error.data).forEach((e) => {
          setError(e.field as any, {
            types: e.types as MultipleFieldErrors | undefined,
          })
        })
      } else {
        // そうでなければ標準のShowApiErrorsに処理を委譲する
        generalShowApiErrors(error)
      }
    },
    [generalShowApiErrors, setError]
  )

  return [showApiErrors]
}
