import { apis } from '../apiBase/apiBase';
export const fileApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getFiles: builder.query({
            query: (params) => ({
                url: `files`,
                method: 'GET',
                params: params,
            }),
        }),
        getFile: builder.query({
            query: (params) => {
                return {
                    url: `files/${params.fileId}`,
                    method: 'GET',
                };
            },
        }),
        uploadFile: builder.mutation({
            query: (params) => {
                // リクエストのファイルをformDataに格納して送信
                const formData = new FormData();
                formData.append('file', params.file);
                return {
                    url: `files/upload`,
                    method: `POST`,
                    body: formData,
                };
            },
        }),
        getFileHistories: builder.query({
            query: (params) => {
                return {
                    url: `files/${params.fileId}/file-histories`,
                    method: 'GET',
                    params: { sort: params.sort },
                };
            },
        }),
        bulkInsertFiles: builder.mutation({
            query: (params) => ({
                url: `files/bulk-insert`,
                method: `POST`,
                body: params,
            }),
        }),
        insertFile: builder.mutation({
            query: (params) => ({
                url: `files/insert`,
                method: `POST`,
                body: params,
            }),
        }),
        updateFile: builder.mutation({
            query: (params) => ({
                url: `files/update`,
                method: `POST`,
                body: params,
            }),
        }),
        insertFileTags: builder.mutation({
            query: (params) => ({
                url: `files/insert-tags`,
                method: `POST`,
                body: params,
            }),
        }),
        deleteFile: builder.mutation({
            query: (params) => ({
                url: `files/delete`,
                method: `POST`,
                body: params,
            }),
        }),
        bulkDeleteFile: builder.mutation({
            query: (params) => ({
                url: `files/bulk-delete`,
                method: `POST`,
                body: params,
            }),
        }),
        insertTag: builder.mutation({
            query: (params) => ({
                url: `tags/insert`,
                method: `POST`,
                body: params,
            }),
        }),
        deleteFileTag: builder.mutation({
            query: (params) => ({
                url: `files/tag/delete`,
                method: `POST`,
                body: params,
            }),
        }),
        deleteFileHistory: builder.mutation({
            query: (params) => ({
                url: 'file-histories/delete',
                method: 'POST',
                body: params,
            }),
        }),
        getFileHistory: builder.query({
            query: (params) => {
                return {
                    url: `file-histories/${params.fileHistoryId}`,
                    method: 'GET',
                };
            },
        }),
        getFileS3SignedUrl: builder.query({
            query: (params) => {
                return {
                    url: `files/${params.fileId}/signed-url`,
                    method: 'GET',
                };
            },
        }),
        getFileHistoryS3SignedUrl: builder.query({
            query: (params) => {
                return {
                    url: `file-histories/${params.fileHistoryId}/signed-url`,
                    method: 'GET',
                };
            },
        }),
        getFileS3SignedUrls: builder.query({
            query: (params) => {
                return {
                    url: `files/signed-urls`,
                    method: 'GET',
                    params,
                };
            },
        }),
        getFileHistoryS3SignedUrls: builder.query({
            query: (params) => {
                return {
                    url: `file-histories/signed-urls`,
                    method: 'GET',
                    params,
                };
            },
        }),
        getFileS3SignedUrlForThumbnail: builder.query({
            query: (params) => {
                return {
                    url: `files/${params.fileId}/thumbnail/signed-url`,
                    method: 'GET',
                };
            },
        }),
        getFileHistoryS3SignedUrlForThumbnail: builder.query({
            query: (params) => {
                return {
                    url: `file-histories/${params.fileHistoryId}/thumbnail/signed-url`,
                    method: 'GET',
                };
            },
        }),
        updateFolder: builder.mutation({
            query: (params) => ({
                url: 'files/update-folder',
                method: 'POST',
                body: params,
            }),
        }),
        downloadFiles: builder.query({
            query: (params) => ({
                url: `files/bulk-download/signed-url`,
                method: 'GET',
                params: params,
            }),
        }),
        getS3SignedUrlForOfficePreviewFile: builder.query({
            query: (params) => {
                return {
                    url: `files/${params.fileId}/preview/signed-url`,
                    method: 'GET',
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetFileQuery, useLazyGetFileQuery, useGetFilesQuery, useLazyGetFilesQuery, useUploadFileMutation, useGetFileHistoriesQuery, useLazyGetFileHistoriesQuery, useBulkInsertFilesMutation, useInsertFileMutation, useUpdateFileMutation, useInsertFileTagsMutation, useDeleteFileMutation, useBulkDeleteFileMutation, useInsertTagMutation, useDeleteFileTagMutation, useDeleteFileHistoryMutation, useGetFileHistoryQuery, useLazyGetFileHistoryQuery, useGetFileS3SignedUrlQuery, useLazyGetFileS3SignedUrlQuery, useGetFileHistoryS3SignedUrlQuery, useLazyGetFileHistoryS3SignedUrlQuery, useGetFileS3SignedUrlsQuery, useLazyGetFileS3SignedUrlsQuery, useGetFileHistoryS3SignedUrlsQuery, useLazyGetFileHistoryS3SignedUrlsQuery, useGetFileS3SignedUrlForThumbnailQuery, useLazyGetFileS3SignedUrlForThumbnailQuery, useGetFileHistoryS3SignedUrlForThumbnailQuery, useLazyGetFileHistoryS3SignedUrlForThumbnailQuery, useUpdateFolderMutation, useDownloadFilesQuery, useLazyDownloadFilesQuery, useGetS3SignedUrlForOfficePreviewFileQuery, useLazyGetS3SignedUrlForOfficePreviewFileQuery, } = fileApis;
