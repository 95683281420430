import { ComponentProps, useMemo } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { genericMemo } from 'utils'
import { CrewErrorMessages } from 'components/forms/crewErrorMessages'

import { CrewSwitch } from 'components/devextreme/crewSwitch'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  ComponentProps<typeof CrewSwitch>,
  'ref' | 'name' | 'value' | 'onValueChange' | 'isValid' //これらはcontrollerが提供するのでpropsから除外する
> & {
  required?: boolean
  label?: string
  showLabel?: boolean
} & UseControllerProps<TFieldValues, TName>

export const CrewSwitchField = genericMemo(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
    // useController用
    name,
    rules,
    control,
    shouldUnregister,
    defaultValue,
    // ラベル用
    required = false,
    label,
    showLabel = true,
    // その他
    children,
    ...rest
  }: Props<TFieldValues, TName>) => {
    const { field, fieldState, formState } = useController({
      name,
      control,
      rules,
      shouldUnregister,
      defaultValue,
    })

    // 要素にid属性を付与するためのオブジェクト
    // data-testidのようなカスタム属性はelementAttrで指定する必要がある
    // https://supportcenter.devexpress.com/ticket/details/t1052998/form-unable-to-pass-custom-attributes-to-items
    const elementAttr = useMemo(() => {
      return { 'data-testid': field.name }
    }, [field.name])

    return (
      <div className="flex flex-col gap-1">
        {showLabel && <CrewFieldLabel text={''} required={required} />}
        <CrewSwitch
          {...rest}
          elementAttr={elementAttr}
          ref={field.ref}
          name={field.name}
          value={field.value}
          onValueChange={field.onChange}
          isValid={!fieldState.error}
        >
          {children}
        </CrewSwitch>
        <CrewErrorMessages
          isValid={!fieldState.error}
          errors={formState.errors}
          field={field}
        />
      </div>
    )
  }
)
