import {
  useInsertFolderMutation,
  useUpdateFolderNameMutation,
} from '@crew/apis/folder/folderApis'
import { EntityType } from '@crew/enums/dist/domain'
import { Folder } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyFolderEvent,
} from 'features/app/states/appSlice'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'

export type FormValues = {
  folderName: string
}

export const useFolderEntryDialog = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // react-hook-formの各種データを取得
  const { handleSubmit, formState, control, reset } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: {
      folderName: '',
    },
  })

  const [insertFolderMutation] = useInsertFolderMutation()
  const [updateFolderNameMutation] = useUpdateFolderNameMutation()

  // Process to update folder name
  const updateFolderName = useCallback(
    async (folderId: string, folderName: string, version: number) => {
      // Call API to update folder name
      const response = await updateFolderNameMutation({
        folder: {
          id: folderId,
          name: folderName,
          version: version,
        },
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Folder> = {
        eventType: NotifyEventType.Updated,
        id: response.folder?.id as string,
        object: response.folder ?? undefined,
      }

      dispatch(notifyFolderEvent(objectEventMessage))

      // clear form
      reset()
    },
    [dispatch, reset, updateFolderNameMutation]
  )

  // Process to create folder
  const createFolder = useCallback(
    async (
      folderName: string,
      parentFolderId: string | null,
      entityType: EntityType,
      entityRecordId: string
    ) => {
      // Call API to create sub folder
      const response = await insertFolderMutation({
        folder: {
          name: folderName,
          entityType,
          entityRecordId,
          parentFolderId: parentFolderId ?? undefined, // create root folder if parentFolderId is null
        },
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Folder> = {
        eventType: NotifyEventType.Inserted,
        id: response.folder?.id as string,
        object: response.folder ?? undefined,
      }

      dispatch(notifyFolderEvent(objectEventMessage))

      // clear form
      reset()
    },
    [dispatch, insertFolderMutation, reset]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      folderName: { required: t('message.general.required') },
    }),
    [t]
  )

  return {
    control,
    validateRules,
    updateFolderName,
    createFolder,
    formState,
    handleSubmit,
    reset,
  }
}
