import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
} from 'lexical'

import { $applyNodeReplacement, TextNode } from 'lexical'

import { getEmojiByName, tag as emojiTag } from '@crew/emoji-data'

/**
 * shortNameから文字を返す
 * @param shortName
 * @returns
 */
const convertCodeUnits = (shortName: string) => {
  const emoji = getEmojiByName(shortName)
  return emoji ? emoji.char : '〓'
}

/**
 * DOM要素を絵文字ノードに変換する
 * @param domNode
 * @returns
 */
const convertEmojiElement = (
  domNode: HTMLElement
): DOMConversionOutput | null => {
  const shortName = domNode.dataset[emojiTag.shortNameCustomDataAttributeName]
  if (!shortName) {
    // DOM要素に絵文字を示すカスタムデータがない場合は何もしない
    return null
  }
  const node = $createEmojiNode(shortName)
  return { node }
}

/**
 * 絵文字表示ノードをLexicalの内部表現用JSONで表現する場合の型
 */
export type SerializedEmojiNode = Spread<
  {
    shortName: string
    type: typeof emojiTag.className
    version: 1
  },
  SerializedTextNode
>

/**
 * 絵文字表示ノード
 */
export class EmojiNode extends TextNode {
  static getType(): string {
    return emojiTag.className
  }

  static clone(node: EmojiNode): EmojiNode {
    return new EmojiNode(node.shortName, node.__key)
  }

  constructor(private readonly shortName: string, key?: NodeKey) {
    super(convertCodeUnits(shortName), key)
  }

  /**
   * この絵文字表示ノードを表示するためのDOM要素を生成する
   * @returns
   */
  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config)
    dom.classList.add(emojiTag.className)
    dom.dataset[emojiTag.shortNameCustomDataAttributeName] = this.shortName
    return dom
  }

  /**
   * このメンションノードのvalue用DOM要素での表現を取得する
   * @returns
   */
  exportDOM(): DOMExportOutput {
    const element = document.createElement(emojiTag.name)
    element.classList.add(emojiTag.className)
    element.dataset[emojiTag.shortNameCustomDataAttributeName] = this.shortName
    element.textContent = convertCodeUnits(this.shortName) // css無効時の代替としてunicode絵文字をセットしておく

    return { element }
  }

  /**
   * DOM表現を絵文字表示ノードに変換するためのコンバータを返す
   * @returns
   */
  static importDOM(): DOMConversionMap | null {
    return {
      [emojiTag.name.toLowerCase()]: (domNode: HTMLElement) => {
        // 絵文字表示用のカスタムデータがないか、絵文字表示用を示すクラスが無い場合はnullを返す=変換対象ではない
        if (
          domNode.dataset[emojiTag.shortNameCustomDataAttributeName] ===
            undefined ||
          !domNode.classList.contains(emojiTag.className)
        ) {
          return null
        }

        return {
          conversion: convertEmojiElement,
          // 同じタグに対して複数の変換が定義されている場合に、どの変換を優先するかを指定できる。
          // 「デフォルトの変換が存在するが、特定のattributeを持っているタグだけ特殊な変換をしたい」場合に設定する。
          // 今回の絵文字はspanタグの特殊な変換となるので、デフォルト(=0)より高めの値を定義した。
          priority: 2,
        }
      },
    }
  }

  /**
   * Lexicalの内部表現用JSONを絵文字表示ノードに変換する
   * @param _serializedNode
   * @returns
   */
  static importJSON(serializedNode: SerializedEmojiNode): EmojiNode {
    const node = $createEmojiNode(serializedNode.shortName)
    node.setFormat(serializedNode.format)
    node.setDetail(serializedNode.detail)
    node.setMode(serializedNode.mode)
    node.setStyle(serializedNode.style)
    return node
  }

  /**
   * この絵文字表示ノードのLexicalの内部表現用JSONでの表現を取得する
   * @returns
   */
  exportJSON(): SerializedEmojiNode {
    return {
      ...super.exportJSON(),
      shortName: this.shortName,
      type: emojiTag.className,
      version: 1,
    }
  }
}

/**
 * 指定したノードが絵文字表示ノードか否かを判定する
 * @param node
 * @returns
 */
export function $isEmojiNode(
  node: LexicalNode | null | undefined
): node is EmojiNode {
  return node instanceof EmojiNode
}

/**
 * shortName表現から絵文字表示ノードを生成する
 * @param shortName
 * @returns
 */
export function $createEmojiNode(shortName: string): EmojiNode {
  const node = new EmojiNode(shortName).setMode('token')
  return $applyNodeReplacement(node)
}
