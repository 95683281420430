import { useTranslation } from '@crew/modules/i18n'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { TimePeriod } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export const WorkingTimeToolbar: FC = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  // URLからパラメータを取得
  const { '*': remainingPath } = useParams()

  const [timePeriod, setTimePeriod] = useState<string>(TimePeriod.Weekly.value)

  useEffect(() => {
    setTimePeriod(remainingPath ?? TimePeriod.Weekly.value)
  }, [remainingPath])

  const timePeriodDataSource = useMemo(() => {
    return Object.values(TimePeriod).map((time) => {
      return {
        id: time.value,
        name: t(time.text),
      }
    })
  }, [t])

  // Event handler for time period change
  const handleTimePeriodChange = useCallback(
    (value: string) => {
      setTimePeriod(value)
      navigate(value)
    },
    [navigate]
  )

  return (
    <CrewSelectBox
      dataSource={timePeriodDataSource}
      valueExpr="id"
      displayExpr="name"
      searchEnabled={false}
      minSearchLength={0}
      width={200}
      value={timePeriod}
      showClearButton={false}
      onValueChange={handleTimePeriodChange}
    />
  )
})
