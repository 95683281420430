import { memo } from 'react'
import { OrganizationSettingForm } from './components/organizationSettingForm/organizationSettingForm'

export const OrganizationSettingPanel = memo(() => {
  return (
    <div className="max-w-2xl mx-auto w-full">
      <OrganizationSettingForm />
    </div>
  )
})
