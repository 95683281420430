import { memo } from 'react'
import { AddExistingUserToolbar } from './components/addExistingUserToolbar/addExistingUserToolbar'
import { AddExistingUserListPanel } from './components/addExistingUserListPanel/addExistingUserListPanel'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

export const AddExistingUser = memo(() => {
  return (
    <>
      <CrewErrorBoundary>
        <AddExistingUserToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <AddExistingUserListPanel />
      </CrewErrorBoundary>
    </>
  )
})
