import { apis } from '../apiBase/apiBase';
export const settingApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        insertOrReplaceSetting: builder.mutation({
            query: (params) => ({
                url: 'user-settings/replace',
                method: 'POST',
                body: params,
            }),
        }),
        getUserSettings: builder.query({
            query: (params) => {
                return {
                    url: `user-settings`,
                    params: params,
                };
            },
        }),
        updateUserSettings: builder.mutation({
            query: (params) => ({
                url: `user-settings/update`,
                method: `POST`,
                body: params,
            }),
        }),
        sendConfirmationEmailToChangeEmailAddress: builder.mutation({
            query: (params) => ({
                url: 'user-settings/send-confirmation-email-to-change-email-address',
                method: 'POST',
                body: params,
            }),
        }),
        getTenantSettings: builder.query({
            query: (params) => {
                return {
                    url: `tenant-settings`,
                    params: params,
                };
            },
        }),
        updateUserPassword: builder.mutation({
            query: (params) => ({
                url: 'user-settings/update-password',
                method: 'POST',
                body: params,
            }),
        }),
        updateUserEmailAddress: builder.mutation({
            query: (params) => ({
                url: 'user-settings/update-email-address',
                method: 'POST',
                body: params,
            }),
        }),
        updateTenantSettings: builder.mutation({
            query: (params) => ({
                url: 'tenant-settings/update',
                method: 'POST',
                body: params,
            }),
        }),
        sendAuthenticationCode: builder.mutation({
            query: (params) => ({
                url: 'user-settings/send-authentication-code',
                method: 'POST',
                body: params,
            }),
        }),
        updateTwoFactorAuth: builder.mutation({
            query: (params) => ({
                url: 'user-settings/two-factory/update',
                method: 'POST',
                body: params,
            }),
        }),
        deleteTwoFactorAuth: builder.mutation({
            query: () => ({
                url: 'user-settings/two-factory/delete',
                method: 'POST',
            }),
        }),
        updateProjectSettings: builder.mutation({
            query: (params) => ({
                url: 'project-settings/update',
                method: 'POST',
                body: params,
            }),
        }),
        getProjectSettings: builder.query({
            query: (params) => {
                return {
                    url: `project-settings`,
                    params: params,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useInsertOrReplaceSettingMutation, useGetUserSettingsQuery, useLazyGetUserSettingsQuery, useUpdateUserSettingsMutation, useSendConfirmationEmailToChangeEmailAddressMutation, useGetTenantSettingsQuery, useLazyGetTenantSettingsQuery, useUpdateUserPasswordMutation, useUpdateUserEmailAddressMutation, useUpdateTenantSettingsMutation, useSendAuthenticationCodeMutation, useUpdateTwoFactorAuthMutation, useDeleteTwoFactorAuthMutation, useUpdateProjectSettingsMutation, useGetProjectSettingsQuery, useLazyGetProjectSettingsQuery, } = settingApis;
