import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { TaskListToolbar } from 'features/task/components/taskListPage/components/taskListToolbar/taskListToolbar'
import { FC, memo } from 'react'
import { TaskTable } from './components/taskTable/taskTable'

export const TaskListPage: FC = memo(() => {
  return (
    <div className="min-h-0 flex-1 flex flex-col grow w-full">
      <div className="min-h-0 flex flex-col gap-4 p-2">
        <CrewErrorBoundary>
          <TaskListToolbar />
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <TaskTable />
        </CrewErrorBoundary>
      </div>
    </div>
  )
})
