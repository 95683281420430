import { EntityType } from '@crew/enums/dist/domain'
import { useProjectPermissions } from '@crew/hooks'
import {
  useChatCurrentService,
  useChatMessage,
  useChatThreadService,
} from '@crew/states'
import { ShowReactionType, ShowReplyButtonType } from '@crew/utils/dist/chat'
import classNames from 'classnames'
import { AvatarPosition } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewNormalMessageItem } from 'components/elements/crewMessageItem/components/crewNormalMessageItem/crewNormalMessageItem'
import { ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN } from 'configs/constants'
import { toggleRightSideBar } from 'features/app/states/appSlice'
import { FC, RefObject, memo, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAppDispatch, useAppSelector } from 'states/hooks'

export type ChatSearchMessageListItemProps = {
  id: string
  chatMessageId: string
  container: RefObject<HTMLDivElement>
  highlightKeyword: string
  onAdditionalLoading: (() => void) | undefined // 追加読み込みの関数
}

export const SearchChatResultListItem: FC<ChatSearchMessageListItemProps> =
  memo((props) => {
    const dispatch = useAppDispatch()
    const { message, isError } = useChatMessage(
      props.chatMessageId,
      dispatch,
      useAppSelector
    )

    // Sliceの操作を行うためのServiceを取得
    const chatCurrentService = useChatCurrentService(dispatch)
    const chatThreadService = useChatThreadService(dispatch)

    // 右パネルが開いているかどうか
    // 返信ボタンクリック時に、右パネルが開いていなければ開くために使用
    const rightSideBarOpened = useAppSelector(
      (state) => state.app.rightSideBarOpened
    )

    // アイテムが表示領域に近づいたら追加ロードイベントハンドラを呼ぶ
    const { ref: loadingTriggerRef } = useInView({
      rootMargin: ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN, // 表示領域に「近づいた」をトリガーにするため、領域の外側にマージンを付与する
      root: props.container.current,
      onChange: (inView) => {
        // このイベントは、アイテムと判定領域の重なり方の割合がthreshold(デフォルト: 0)を越えた場合に発火する。
        // 近づいた場合と離れた場合のどちらも発火するが、inViewの値で方向を判定することができる。
        //   近づいた場合: true 離れた場合: false
        if (inView) {
          // アイテムが近づいてきた場合、追加読込を行う
          props.onAdditionalLoading?.()
        }
      },
    })

    const { hasPrjFileDownloadPermission } = useProjectPermissions(
      message?.chatRoom.entityType
        ? (message.chatRoom.entityType as EntityType)
        : undefined,
      message?.chatRoom.entityRecordId ?? undefined
    )

    // メッセージをクリック時の処理
    const handleClickItem = useCallback(() => {
      if (message) {
        const topicId = message.parentChatMessageId ?? message.id

        // スレッド表示を行うためトピックIDをセットする
        chatCurrentService.setCurrentChatThread({
          topicId: topicId,
          chatRoomId: message.chatRoomId,
          entityType: message.chatRoom.rootEntityType, // ヘッダ部にはプロジェクトのリンクを表示するため、rootEntityTypeを設定する
          entityRecordId: message.chatRoom.rootEntityRecordId, // ヘッダ部にはプロジェクトのリンクを表示するため、rootEntityRecordIdを設定する
        })

        // 選択したメッセージにスクロールして表示するため、メッセージIDをセットする
        chatThreadService.setChatThreadSelectedMessageId({
          topicId: topicId,
          chatMessageId: message.id,
        })
      }

      // 右サイドバーを開く
      !rightSideBarOpened && dispatch(toggleRightSideBar())
    }, [
      chatCurrentService,
      chatThreadService,
      dispatch,
      message,
      rightSideBarOpened,
    ])

    // エラーが発生している場合はエラーが発生した旨を表示する
    if (isError) {
      return <></>
    }

    // 表示に必要なデータがない場合は何も表示しない
    // 当初「読み込み中」を表示しようとしていたが、メッセージごとにその表示が出てしまうと見栄えが悪かったので表示しないようにした
    if (!message) {
      return <></>
    }

    return (
      <div
        id={message.id}
        className={classNames(
          'flex flex-col crew-border-gray relative',
          'cursor-pointer'
        )}
        // 選択したメッセージを検索元のビューで選択させるために親のメッセージIdも一緒に返す
        onClick={handleClickItem}
      >
        {props.onAdditionalLoading && (
          <div
            ref={loadingTriggerRef}
            className="absolute left-0 top-0 right-0 bottom-0 -z-10"
          />
        )}
        {/* 検索結果は通常メッセージ固定 */}
        <CrewNormalMessageItem
          message={message}
          showRelatedLink // 関連先リンクを表示する
          showDeleteAttachmentButton={false} // 検索結果の表示では添付ファイルの削除ボタンを常に表示しない
          canDownloadAttachment={hasPrjFileDownloadPermission}
          highlightKeyword={props.highlightKeyword}
          isLargeAvatar={true} // アバターを大きく表示する
          avatarPosition={AvatarPosition.Center} // アバターを中央に表示する
          showReplyButtonType={ShowReplyButtonType.None} // 「返信」ボタンは常に非表示
          showReactionsAndReactionButton={ShowReactionType.ShowOnly} // リアクションは表示のみ
          replyCount={undefined} // 返信件数は表示しないのでundefinedを指定する
          onReplyClick={undefined}
          onAttachmentFileDeleted={undefined}
          onClick={undefined}
        />
      </div>
    )
  })
