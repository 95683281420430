import { useDataSource } from 'hooks/dataSource/useDataSource'
import {
  useLazyGetLookupTaskStatesQuery,
  useLazyGetLookupProjectMembersQuery,
} from '@crew/apis/lookup/lookupApis'
import { useRef } from 'react'
import { Scheduler } from 'devextreme-react'
import { TaskDuration } from '@crew/apis/task/models/updateTaskDuration/request'
import dayjs from '@crew/modules/dayjs'
import { JsonDateFormat } from '@crew/enums/system'
import { useUpdateTaskDurationMutation } from '@crew/apis/task/taskApis'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { useAppSelector } from 'states/hooks'
import { GetProjectTasksRequest } from '@crew/apis/project/models/getProjectTasks/request'
import { useLazyGetProjectTasksQuery } from '@crew/apis/project/projectApis'
import { EntityType } from '@crew/enums/domain'
import { CrewBadgeColorClass, CrewBadgeColorToHex } from 'enums/color'

export const useProjectDetailTaskListTimelineScheduler = () => {
  const { projectId } = useParams()
  const [lazyGetLookupTaskStatesQuery] = useLazyGetLookupTaskStatesQuery()
  const [lazyGetLookupProjectMembersQuery] =
    useLazyGetLookupProjectMembersQuery()

  const tasksSchedulerRef = useRef<Scheduler>(null)
  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)

  const [updateTaskDurationMutation] = useUpdateTaskDurationMutation()

  const [lazyGetProjectTasksQuery] = useLazyGetProjectTasksQuery()

  //Get project tasks
  const tasksDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!projectId) return []

        const startDateFrom =
          tasksSchedulerRef.current?.instance.getStartViewDate()
        const startDateTo = tasksSchedulerRef.current?.instance.getEndViewDate()

        const params: GetProjectTasksRequest = {
          projectId,
          keyword: undefined,
          taskKindIds: undefined,
          assignToUser: undefined,
          taskStateIds: undefined,
          taskStateTypes: undefined,
          taskPriorities: undefined,
          taskCategoryIds: undefined,
          startDate: undefined,
          dueDate: undefined,
          createdById: undefined,
          updatedById: undefined,
          createdAt: undefined,
          updatedAt: undefined,
          targetDateFrom: dayjs(startDateFrom).format(JsonDateFormat.YYYYMMDD),
          targetDateTo: dayjs(startDateTo).format(JsonDateFormat.YYYYMMDD),
        }

        // フィルターが設定されている場合、パラメータに設定する
        if (loadOptions.filter) {
          params.keyword = loadOptions.filter.keyword
          params.taskKindIds = loadOptions.filter.taskKindIds
          params.assignToUser = loadOptions.filter.assignToUser
          params.taskStateIds = loadOptions.filter.taskStateIds
          params.taskStateTypes = loadOptions.filter.taskStateTypes
          params.taskPriorities = loadOptions.filter.taskPriorities
          params.taskCategoryIds = loadOptions.filter.taskCategoryIds
          params.startDate = loadOptions.filter.startDate
          params.dueDate = loadOptions.filter.dueDate
          params.createdById = loadOptions.filter.createdById
          params.updatedById = loadOptions.filter.updatedById
          params.createdAt = loadOptions.filter.createdAt
          params.updatedAt = loadOptions.filter.updatedAt
          params.targetDateFrom = dayjs(startDateFrom).format(
            JsonDateFormat.YYYYMMDD
          )
          params.targetDateTo = dayjs(startDateTo).format(
            JsonDateFormat.YYYYMMDD
          )
        }

        const response = await lazyGetProjectTasksQuery(params).unwrap()

        // response.tasksは読み取り専用のオブジェクトのため、カレンダー上にタスクをドラグしたときresponse.tasks内の項目を直接編集することができない。
        // そのため、別のオブジェクトにresponse.tasksをクローンし編集できるようにする。
        const tasksClone = cloneDeep(response.tasks)

        // Reset the value of startDate to display Task on Calendar
        const tasks = tasksClone.map((task) => {
          const tempTask = Object.assign({}, task)

          // 開始日が未設定・期日が設定済みの場合
          // Figmaに書いてあるとおり、期日指定されている一日で表示する
          if (!task.startDate && task.dueDate) {
            return {
              ...tempTask,
              startDate: task.dueDate,
            }
          }

          // 開始日が設定済み・期日が未設定の場合
          // 表示しない
          if (task.startDate && !task.dueDate) {
            return {
              ...tempTask,
              startDate: null,
            }
          }

          return {
            ...tempTask,
          }
        })

        return tasks
      },
      update: async (key, values) => {
        const payload: TaskDuration = {
          taskId: key,
          startDate: dayjs(values.startDate).format(JsonDateFormat.YYYYMMDD),
          dueDate: dayjs(values.dueDate).format(JsonDateFormat.YYYYMMDD),
          assignToUserId: values.assignToUser
            ? values.assignToUser.id
            : undefined,
          version: values.version,
        }

        // Call API update task duration
        await updateTaskDurationMutation({
          task: {
            ...payload,
          },
        }).unwrap()
      },
    }),
    [lazyGetProjectTasksQuery, projectId, updateTaskDurationMutation]
  )

  //Get list project members
  const usersDataSource = useDataSource(
    () => ({
      loadMode: 'raw',
      key: 'id',
      load: async (loadOptions) => {
        if (!projectId) return []

        const response = await lazyGetLookupProjectMembersQuery({
          projectId,
          userId: undefined,
          keyword: undefined,
          excludeUserId: undefined,
          // Show only enabled user
          isEnabledUser: true,
        }).unwrap()

        return response.projectMembers
      },
    }),
    [lazyGetLookupProjectMembersQuery, projectId]
  )

  //Get project task state
  const taskStateDataSource = useDataSource(
    () => ({
      loadMode: 'raw',
      key: 'id',
      load: async (loadOptions) => {
        const response = await lazyGetLookupTaskStatesQuery({
          entityType: EntityType.Project,
          entityRecordId: projectId,
          name: undefined,
          taskStateIds: undefined,
        }).unwrap()
        const taskStates = response.taskStates.map((item) => {
          return {
            ...item,
            displayColor:
              CrewBadgeColorToHex[item.displayColor as CrewBadgeColorClass],
          }
        })
        return taskStates
      },
    }),
    [lazyGetLookupTaskStatesQuery, projectId]
  )

  return {
    usersDataSource,
    tasksDataSource,
    taskStateDataSource,
    tasksSchedulerRef,
    taskEventMessage,
  }
}
