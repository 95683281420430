import { FC, memo } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { FileEntryForm } from './components/fileEntryForm/fileEntryForm'
import { EntityType } from '@crew/enums/domain'

type FileDialogProps = {
  isEditMode: boolean
  isOpen: boolean
  title: string
  fileId?: string
  entityRecordId?: string
  entityType?: EntityType
  folderId?: string
  onClose: () => void
  onRegistered?: (fileId: string) => void
  onUpdated?: () => void
  onDeleted?: () => void
}

export const FileEntryDialog: FC<FileDialogProps> = memo((props) => {
  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <FileEntryForm
        isEditMode={props.isEditMode}
        fileId={props.fileId}
        onCancel={props.onClose}
        entityRecordId={props.entityRecordId}
        entityType={props.entityType}
        onRegistered={props.onRegistered}
        onUpdated={props.onUpdated}
        onDeleted={props.onDeleted}
      />
    </Modal>
  )
})
