import { FC, memo } from 'react'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { TaskStateListToolbar } from './components/taskStateListToolbar/taskStateListToolbar'
import { TaskStateListGrid } from './components/taskStateListGrid/taskStateListGrid'

export const ProjectSettingTaskStateList: FC = memo(() => {
  return (
    <div className="flex flex-col gap-2">
      <CrewErrorBoundary>
        <TaskStateListToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <TaskStateListGrid />
      </CrewErrorBoundary>
    </div>
  )
})
