import {
  useDeleteTaskDependencyMutation,
  useInsertTaskDependencyMutation,
  useLazyGetTaskDependenciesQuery,
  useLazyGetTaskDependencyQuery,
} from '@crew/apis/task/taskApis'
import { useDataSource } from './useDataSource'
import { InsertTaskDependencyRequest } from '@crew/apis/task/models/insertTaskDependency/request'
import { GetTaskDependenciesResponse_TaskDependency } from '@crew/apis/task/models/getTaskDependencies/response'
import { EntityType, TaskDependencyType } from '@crew/enums/domain'
import { GetTaskDependenciesRequest } from '@crew/apis/task/models/getTaskDependencies/request'

// A map object to convert from number to string
const TypeNumToStr: Record<number, TaskDependencyType> = {
  0: 'fs',
  1: 'ss',
  2: 'ff',
  3: 'sf',
}

export const useTaskDependencyDataSource = (
  entityType: EntityType,
  entityRecordId: string,
  taskId: string | undefined
) => {
  const [lazyGetTaskDependenciesQuery] = useLazyGetTaskDependenciesQuery()
  const [lazyGetTaskDependencyQuery] = useLazyGetTaskDependencyQuery()

  const [insertTaskDependencyMutation] = useInsertTaskDependencyMutation()
  const [deleteTaskDependencyMutation] = useDeleteTaskDependencyMutation()

  const taskDependencyDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!entityType || !entityRecordId) return []

        const params: GetTaskDependenciesRequest = {
          entityType,
          entityRecordId,
          taskId,
          keyword: undefined,
          taskKindIds: undefined,
          assignToUser: undefined,
          taskStateIds: undefined,
          taskStateTypes: undefined,
          taskPriorities: undefined,
          taskCategoryIds: undefined,
          startDate: undefined,
          dueDate: undefined,
          createdById: undefined,
          updatedById: undefined,
          createdAt: undefined,
          updatedAt: undefined,
        }

        // フィルターが設定されている場合、パラメータに設定する
        if (loadOptions.filter) {
          params.keyword = loadOptions.filter.keyword
          params.taskKindIds = loadOptions.filter.taskKindIds
          params.assignToUser = loadOptions.filter.assignToUser
          params.taskStateIds = loadOptions.filter.taskStateIds
          params.taskStateTypes = loadOptions.filter.taskStateTypes
          params.taskPriorities = loadOptions.filter.taskPriorities
          params.taskCategoryIds = loadOptions.filter.taskCategoryIds
          params.startDate = loadOptions.filter.startDate
          params.dueDate = loadOptions.filter.dueDate
          params.createdById = loadOptions.filter.createdById
          params.updatedById = loadOptions.filter.updatedById
          params.createdAt = loadOptions.filter.createdAt
          params.updatedAt = loadOptions.filter.updatedAt
        }

        const response = await lazyGetTaskDependenciesQuery(params).unwrap()
        return response.taskDependencies
      },
      byKey: async (id) => {
        const response = await lazyGetTaskDependencyQuery({
          taskDependencyId: id,
        }).unwrap()
        return response.taskDependency
      },
      insert: async (dependency) => {
        if (typeof dependency.dependencyType === 'number') {
          dependency.dependencyType = TypeNumToStr[dependency.dependencyType]
        }

        const payload: InsertTaskDependencyRequest = {
          taskDependency: {
            predecessorTaskID: dependency.predecessorTaskId,
            successorTaskID: dependency.successorTaskId,
            dependencyType: dependency.dependencyType,
          },
        }
        const result = await insertTaskDependencyMutation(payload).unwrap()
        return result.taskDependency
      },
      remove: async (id) => {
        const taskDependency = (await taskDependencyDataSource
          .store()
          .byKey(id)) as GetTaskDependenciesResponse_TaskDependency
        await deleteTaskDependencyMutation({
          taskDependencyId: id,
          version: taskDependency.version,
        }).unwrap()
      },
    }),
    [
      deleteTaskDependencyMutation,
      entityRecordId,
      entityType,
      insertTaskDependencyMutation,
      lazyGetTaskDependenciesQuery,
      lazyGetTaskDependencyQuery,
      taskId,
    ]
  )

  return taskDependencyDataSource
}
