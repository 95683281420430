import { UpdateTaskRequest } from '@crew/apis/task/models/updateTask/request'
import {
  useLazyGetSuggestionParentTasksQuery,
  useUpdateTaskMutation,
} from '@crew/apis/task/taskApis'
import { JsonDateFormat } from '@crew/enums/system'
import {
  TaskCategoryRef,
  TaskKindRef,
  TaskStateRef,
  UserRef,
} from '@crew/models/refs'
import dayjs from '@crew/modules'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTaskEvent,
} from 'features/app/states/appSlice'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'

export type Task = {
  id: string
  subject: string
  description: string | null
  startDate: string | null
  dueDate: string | null
  assignToUser: UserRef | null
  taskPriority: number
  taskKind: TaskKindRef
  taskCategory: TaskCategoryRef | null
  taskState: TaskStateRef
  estimatedWorkTimes: number | null
  actualProgress: number | null
  isProgressManagementDisabled: boolean
  parentTask: Task | null
  version: number
}

export type FormValues = {
  parentTaskId: string
}

export const useTaskDetailDependencyParentTask = (
  taskId: string | undefined
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  // react-hook-formの各種データを取得
  const { handleSubmit, formState, control, setError, reset } =
    useForm<FormValues>({
      criteriaMode: 'all',
    })

  const [updateTaskMutation] = useUpdateTaskMutation()
  const [lazyGetSuggestionParentTasksQuery] =
    useLazyGetSuggestionParentTasksQuery()

  const parentTaskDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (loadOptions.filter) {
          if (!taskId) {
            return []
          }

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // Get selected task ids
          const selectedTaskIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetSuggestionParentTasksQuery({
            taskId,
            keyword: loadOptions.searchValue,
            selectedTaskIds,
          }).unwrap()

          return response.tasks
        } else {
          if (!loadOptions.searchValue || !taskId) {
            return []
          }

          const response = await lazyGetSuggestionParentTasksQuery({
            taskId,
            keyword: loadOptions.searchValue,
            selectedTaskIds: undefined,
          }).unwrap()

          return response.tasks
        }
      },
    }),
    [lazyGetSuggestionParentTasksQuery, taskId]
  )

  // 親タスクを更新する
  const updateParentTask = useCallback(
    async (task: Task, parentTaskId: string | undefined) => {
      const payload: UpdateTaskRequest = {
        task: {
          id: task.id,
          taskKindId: task.taskKind.id,
          subject: task.subject,
          startDate: task.startDate
            ? dayjs(task.startDate).format(JsonDateFormat.YYYYMMDD)
            : undefined,
          dueDate: task.dueDate
            ? dayjs(task.dueDate).format(JsonDateFormat.YYYYMMDD)
            : undefined,
          taskPriority: task.taskPriority,
          taskCategoryId: task.taskCategory?.id ?? undefined,
          assignToUserId: task.assignToUser?.id ?? undefined,
          description: task.description ?? undefined,
          estimatedWorkTimes: task.estimatedWorkTimes ?? undefined,
          notificationUserIds: undefined,
          needNotification: false,
          version: task.version,
          parentTaskId,
          isProgressManagementDisabled: task.isProgressManagementDisabled,
          actualProgress: task.actualProgress ?? undefined,
        },
      }

      const response = await updateTaskMutation(payload).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Updated,
        id: response.task?.id as string,
        object: response.task ?? undefined,
      }

      dispatch(notifyTaskEvent(objectEventMessage))
    },
    [dispatch, updateTaskMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      parentTaskId: {
        required: t('message.general.required'),
      },
    }),
    [t]
  )

  return {
    control,
    formState,
    validateRules,
    reset,
    setError,
    handleSubmit,
    updateParentTask,
    parentTaskDataSource,
  }
}
