import { useGetMyActualWorkTimesQuery } from '@crew/apis/task/taskApis'
import { JsonDateFormat } from '@crew/enums/system'
import dayjs from '@crew/modules'
import { useTranslation } from '@crew/modules/i18n'
import { convertMinutesToHHMM } from '@crew/utils'
import classNames from 'classnames'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { isWeekend } from 'utils/date'
import KeyboardArrowLeft from '~icons/material-symbols/keyboard-arrow-left'
import KeyboardArrowRight from '~icons/material-symbols/keyboard-arrow-right'
import { DateCellData } from './components/dateCellData/dateCellData'
import { GetMyActualWorkTimesResponse_WorkTime } from '@crew/apis/task/models/getMyActualWorkTimes/response'
import { WorkingTimeEntryDialog } from '../workingTimeEntryDialog/workingTimeEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useValueChangeEffect } from '@crew/hooks'
import { useAppSelector } from 'states/hooks'

export const WorkingTimeMonth: FC = memo(() => {
  const { t } = useTranslation()

  const [targetDate, setTargetDate] = useState(new Date())
  const [worktimeDialogTargetDate, setWorktimeDialogTargetDate] = useState(
    new Date()
  )

  const [
    isWorkTimeEntryDialogOpen,
    openWorkTimeEntryDialog,
    closeWorkTimeEntryDialog,
  ] = useModal()

  // Event handler for when the previous date button is clicked
  const handlePrevMonthButtonClick = useCallback(() => {
    setTargetDate((prev) => {
      const next = new Date(prev)
      next.setMonth(prev.getMonth() - 1)
      return next
    })
  }, [])

  // Event handler for when the next date button is clicked
  const handleNextMonthButtonClick = useCallback(() => {
    setTargetDate((prev) => {
      const next = new Date(prev)
      next.setMonth(prev.getMonth() + 1)
      return next
    })
  }, [])

  // データ取得対象期間From
  const targetDateFrom = useMemo(() => {
    const startOfMonth = dayjs(targetDate).startOf('month') // 月の初日
    // Get the first day in month page
    const startOfCalendarMonth = startOfMonth.startOf('week')

    return startOfCalendarMonth.format(JsonDateFormat.YYYYMMDD)
  }, [targetDate])

  // データ取得対象期間To
  const targetDateTo = useMemo(() => {
    const endOfMonth = dayjs(targetDate).endOf('month') // 月の最終日
    // Get the last day in month page
    const endOfCalendarMonth = endOfMonth.endOf('week')

    return endOfCalendarMonth.format(JsonDateFormat.YYYYMMDD)
  }, [targetDate])

  // days of week
  const daysOfWeek = useMemo(() => {
    // Create a Date object for the corresponding day of the week
    return [...Array(7).keys()].map((i) => new Date(1970, 0, 4 + i)) // 1970-01-04 is a Sunday
  }, [])

  // 日付リスト (カレンダー表示用)
  const listDates = useMemo(() => {
    const startOfMonth = dayjs(targetDate).startOf('month') // 月の初日
    const endOfMonth = dayjs(targetDate).endOf('month') // 月の最終日

    const startOfCalendarMonth = startOfMonth.startOf('week')
    const endOfCalendarMonth = endOfMonth.endOf('week')

    const listDates = []
    let date = startOfCalendarMonth
    while (date.isBefore(endOfCalendarMonth)) {
      listDates.push(date)
      date = date.add(1, 'day')
    }

    return listDates
  }, [targetDate])

  const taskWorkEventMessage = useAppSelector(
    (state) => state.app.taskWorkEventMessage
  )

  const {
    data: getMyActualWorkTimesResult,
    refetch: refetchMyActualWorkTimes,
  } = useGetMyActualWorkTimesQuery({
    startDate: targetDateFrom,
    endDate: targetDateTo,
  })

  // Refetch worktimes when taskWorkEventMessage changes
  useValueChangeEffect(
    () => {
      refetchMyActualWorkTimes()
    },
    [refetchMyActualWorkTimes],
    taskWorkEventMessage
  )

  const totalWorkTimes = useMemo(() => {
    if (!getMyActualWorkTimesResult?.workTimes) return 0

    return getMyActualWorkTimesResult.workTimes.reduce(
      (accumulator, currentValue) => {
        // この数値には、前月や翌月の作業時間は含めない
        if (dayjs(currentValue.workDate).month() === targetDate.getMonth()) {
          return accumulator + (currentValue.actualWorkTimes ?? 0)
        } else {
          return accumulator
        }
      },
      0
    )
  }, [getMyActualWorkTimesResult?.workTimes, targetDate])

  // Map the work time data to the day
  const mapDayToWorkTimes = useMemo(() => {
    const map = new Map<string, GetMyActualWorkTimesResponse_WorkTime[]>()
    listDates.forEach((date) => {
      map.set(date.format(JsonDateFormat.YYYYMMDD), [])
    })
    // Group the work time data by day
    getMyActualWorkTimesResult?.workTimes.forEach((workTime) => {
      const workDate = dayjs(workTime.workDate).format(JsonDateFormat.YYYYMMDD)
      const workTimes = map.get(workDate)
      if (workTimes) {
        workTimes.push(workTime)
      }
    })

    return map
  }, [getMyActualWorkTimesResult?.workTimes, listDates])

  // Event handler for opening the work time entry dialog
  const handleOpenWorkTimeEntryDialog = useCallback(
    (workDate: Date) => {
      setWorktimeDialogTargetDate(workDate)
      openWorkTimeEntryDialog()
    },
    [openWorkTimeEntryDialog]
  )

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2.5">
          {/* prev month button */}
          <CrewButton
            stylingMode="text"
            icon={<KeyboardArrowLeft width={24} height={24} />}
            size="xs"
            onClick={handlePrevMonthButtonClick}
          />

          {/* display month */}
          <span>
            {t('format.yearMonthWithText', {
              value: targetDate,
            })}
          </span>

          {/* next month button */}
          <CrewButton
            stylingMode="text"
            icon={<KeyboardArrowRight width={24} height={24} />}
            size="xs"
            onClick={handleNextMonthButtonClick}
          />
        </div>

        {/* total work time */}
        <p className="flex flex-row items-center gap-2">
          <span>{t('label.total')}</span>
          <span className="font-bold text-lg">
            {convertMinutesToHHMM(totalWorkTimes)}
          </span>
        </p>
      </div>

      {/* calendar */}
      <div className="mt-2.5 grow flex flex-col overflow-auto">
        {/* days of week */}
        <div className="grid grid-cols-7 border border-crew-gray-300 border-r-0">
          {daysOfWeek.map((dayOfWeek, index) => (
            <div
              key={index}
              className={classNames(
                'text-center border-r border-crew-gray-300 py-1',
                isWeekend(dayOfWeek) && 'crew-bg-gray-1'
              )}
            >
              <span>
                {t('format.weekday', {
                  value: dayOfWeek,
                })}
              </span>
            </div>
          ))}
        </div>

        {/* days of month */}
        <div className="grid grid-cols-7 border-l border-crew-gray-300 flex-1 auto-rows-fr">
          {listDates.map((date) => (
            <div
              key={date.format(JsonDateFormat.YYYYMMDD)}
              className={classNames(
                'flex border border-crew-gray-300 border-l-0 border-t-0 p-0.5',
                isWeekend(date.toDate()) &&
                  'bg-crew-gray-50 dark:bg-crew-gray-800/50'
              )}
            >
              <DateCellData
                date={date.toDate()}
                isOtherMonth={date.month() !== targetDate.getMonth()}
                data={
                  mapDayToWorkTimes.get(date.format(JsonDateFormat.YYYYMMDD)) ??
                  []
                }
                onDateCellClick={handleOpenWorkTimeEntryDialog}
              />
            </div>
          ))}
        </div>
      </div>

      <WorkingTimeEntryDialog
        title={t('label.registerWorkingTime')}
        isOpen={isWorkTimeEntryDialogOpen}
        onClose={closeWorkTimeEntryDialog}
        targetDate={worktimeDialogTargetDate}
      />
    </div>
  )
})
