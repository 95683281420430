import { FC, memo } from 'react'
import MicOff from '~icons/material-symbols/mic-off'
import useAttendeeAudioStatus from 'modules/amazon-chime-sdk-component-library-devextreme/hooks/sdk/useAttendeeAudioStatus'
import classNames from 'classnames'
import { useContentShareState } from 'modules/amazon-chime-sdk-component-library-devextreme'

type CrewMicrophoneMuteAndNameProps = {
  name: string
  chimeAttendeeId?: string
}

export const CrewShowMicrophoneMuteAndName: FC<CrewMicrophoneMuteAndNameProps> =
  memo((props) => {
    const { muted } = useAttendeeAudioStatus(props.chimeAttendeeId ?? '')
    // 画面共有状態に応じて表示切替を行うため画面共有しているAttendeeIdを取得する
    const { sharingAttendeeId } = useContentShareState()

    return (
      // Web会議パネル内の下部参加者モニター左下に表示させるためabsoluteを使用
      <div className="flex justify-between items-center absolute bottom-0 right-0 left-0 pl-0.5">
        {/* ユーザー名 */}
        <div
          className={classNames(
            ' text-crew-gray-200 rounded-md truncate self-end',
            sharingAttendeeId !== null
              ? 'px-1 py-0.5 text-xs bg-crew-gray-900/40'
              : 'backdrop-blur-md px-2 py-1 bg-crew-gray-900/60'
          )}
        >
          {props.name}
        </div>
        {/* show icon mute when props muted is true */}
        {muted && (
          <div className="crew-badge-icon-red">
            <MicOff width={24} height={24} />
          </div>
        )}
      </div>
    )
  })
