import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ValidateRules } from '@crew/utils/form'
import { useUpdatePasswordMutation } from '@crew/apis/dist/app/appApis'
import { UpdatePasswordRequest } from '@crew/apis/dist/app/models/updatePassword/request'

export type FormValues = {
  password: string
  confirmPassword: string
}

export const useAppResetPasswordPage = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, formState, getValues } = useForm<FormValues>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    criteriaMode: 'all',
  })

  // Get function for  update password
  const [updatePasswordMutation] = useUpdatePasswordMutation()

  // Update password process
  const updatePassword = useCallback(
    async (data: FormValues, token: string) => {
      let payload: UpdatePasswordRequest = {
        resetPasswordToken: token,
        password: data.password,
      }
      await updatePasswordMutation(payload).unwrap()
    },
    [updatePasswordMutation]
  )

  // Validate confirm password equal password
  const checkPasswordConfirm = useCallback(() => {
    return getValues('password') === getValues('confirmPassword')
  }, [getValues])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      password: {
        required: t('message.general.isRequired', {
          name: t('label.password'),
        }),
        // TODO: 暫定で6文字以上かのみチェック。恒久対応はCREW-10657で対応する。
        // https://break-tmc.atlassian.net/browse/CREW-10657
        minLength: {
          value: 6,
          message: '6文字以上で入力してください', // パスワードリセット画面はi18nが使えないかつ暫定実装のため、日本語で固定表示
        },
      },
      confirmPassword: {
        required: t('message.general.isRequired', {
          name: t('label.confirmPassword'),
        }),
        validate: {
          checkPasswordConfirm: () =>
            checkPasswordConfirm() ||
            t('message.signup.register.invalidFormatConfirmPassword'),
        },
      },
    }),
    [checkPasswordConfirm, t]
  )

  return {
    control,
    handleSubmit,
    formState,
    validateRules,
    updatePassword,
  }
}
