import { createContext, FC, useCallback, useEffect, useState } from 'react'

type ModalContextType = {
  modals: string[]
  register(key: string): void
  unregister(key: string): void
}

type ModalContextProps = {
  children: React.ReactNode
}

// export const AppContext = createContext<Partial<AppContextType>>({})
// https://www.carlrippon.com/react-context-with-typescript-p4/
// https://qiita.com/johnmackay150/items/88654e5064290c24a32a
export const ModalContext = createContext<ModalContextType>(undefined!)

export const ModalProvider: FC<ModalContextProps> = (props) => {
  const [modals, setModals] = useState<string[]>([])

  const register = useCallback(
    (key: string) =>
      setModals((modals) => {
        const exists = modals.indexOf(key) >= 0
        if (exists) {
          return modals
        }
        return [...modals, key]
      }),
    []
  )
  const unregister = useCallback(
    (key: string) =>
      setModals((modals) => {
        const exists = modals.indexOf(key) >= 0
        if (!exists) {
          return modals
        }
        const newModals = modals.filter((x) => x !== key)
        return newModals
      }),
    []
  )

  useEffect(() => {
    console.log('modals', modals)
    const hasModal = Object.keys(modals).length > 0
    if (hasModal) {
      console.log('document.body.style.overflow', 'hidden')
      document.body.style.overflow = 'hidden'
    } else {
      console.log('document.body.style.overflow', 'unset')
      document.body.style.overflow = 'unset'
    }
  }, [modals])

  return (
    // <ModalContext.Provider value={contextValue}>
    <ModalContext.Provider value={{ modals, register, unregister }}>
      {props.children}
    </ModalContext.Provider>
  )
}
