import { memo } from 'react'
import { ProjectSettingTaskCategoryListPanel } from './components/projectSettingTaskCategoryListPanel/projectSettingTaskCategoryListPanel'
import { ProjectSettingTaskCategoryListToolbar } from './components/projectSettingTaskCategoryListToolbar/projectSettingTaskCategoryListToolbar'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

export const ProjectSettingTaskCategoryList = memo(() => {
  return (
    <div className="flex flex-col gap-2.5">
      <CrewErrorBoundary>
        <ProjectSettingTaskCategoryListToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <ProjectSettingTaskCategoryListPanel />
      </CrewErrorBoundary>
    </div>
  )
})
