import { useTranslation } from '@crew/modules/i18n'
import { ColumnDef, TableOptions, getCoreRowModel } from '@tanstack/react-table'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useGetFiltersQuery } from '@crew/apis/filter/filterApis'
import { EntityType } from '@crew/enums/domain'
import { Filter } from '@crew/models/domain'
import { FilterType, FilterScope } from '@crew/enums/app'
import Check from '~icons/material-symbols/check'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineCreate from '~icons/ic/baseline-create'
import { CrewSaveFilterDialog } from 'components/elements/crewSaveFilterDialog/crewSaveFilterDialog'
import { useAppSelector } from 'states/hooks'
import { useModal } from 'components/layouts/modal/useModal'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useFilterTable } from './useFilterTable'
import { useToast } from 'hooks/useToast'

type FilterTableProps = {
  entityType: EntityType
}

type FilterTableActionProps = {
  filterId: string
  version: number
  onOpenEditFilterDialog: (filterId: string) => void
  onDeleteFilter: (filterId: string, version: number) => void
  displayDeleteButton: boolean
}
const FilterTableAction: FC<FilterTableActionProps> = (props) => {
  // open edit filter dialog
  const handleEditButtonClick = useCallback(() => {
    props.onOpenEditFilterDialog(props.filterId)
  }, [props])

  // Action when delete button is clicked
  const handleDeleteButtonClick = useCallback(() => {
    props.onDeleteFilter(props.filterId, props.version)
  }, [props])

  return (
    <div className="flex gap-2">
      {/* edit action */}
      <span className="cursor-pointer" onClick={handleEditButtonClick}>
        <BaselineCreate width={20} height={20} />
      </span>

      {/* delete action */}
      {props.displayDeleteButton && (
        <span className="cursor-pointer" onClick={handleDeleteButtonClick}>
          <BaselineDelete width={20} height={20} />
        </span>
      )}
    </div>
  )
}

export const FilterTable: FC<FilterTableProps> = memo((props) => {
  const { t } = useTranslation()
  const toast = useToast()
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const { deleteFilter } = useFilterTable()

  const [filterId, setFilterId] = useState('')
  const [version, setVersion] = useState(0)
  const [
    isCrewSaveFilterDialogOpen,
    openCrewSaveFilterDialog,
    closeCrewSaveFilterDialog,
  ] = useModal()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const [showApiErrors] = useShowApiErrors()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const filterEventMessage = useAppSelector(
    (state) => state.app.filterEventMessage
  )

  // open save filter dialog
  const handleOpenSaveFilterDialog = useCallback(
    (filterId: string) => {
      setFilterId(filterId)
      openCrewSaveFilterDialog()
    },
    [openCrewSaveFilterDialog]
  )

  const handleDeleteButtonClick = useCallback(
    (filterId: string, version: number) => {
      setFilterId(filterId)
      setVersion(version)
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  const { data: getFiltersResult, refetch: refetchFilters } =
    useGetFiltersQuery({
      entityType: props.entityType,
    })

  // refresh filters
  useEffect(() => {
    refetchFilters()
  }, [filterEventMessage, refetchFilters])

  const [columnPinning] = useState({
    right: ['action'],
  })

  const columns = useMemo<ColumnDef<Filter>[]>(
    () => [
      {
        id: 'name',
        header: () => t('label.filterNameLabel'),
        cell: ({ row }) => (
          <div className="truncate">
            {/* Convert name of builtin filter to localized */}
            {row.original.filterType === FilterType.BuiltIn
              ? t(`label.${row.original.name}`)
              : row.original.name}
          </div>
        ),
        size: 350,
        minSize: 50,
      },
      {
        id: 'publicScope',
        header: () => t('label.publicScopeLabel'),
        cell: ({ row }) => (
          <div className="truncate">
            {row.original.filterType === FilterType.BuiltIn
              ? t('label.builtIn')
              : row.original.scope === FilterScope.Public.key
              ? t('label.public')
              : t('label.private')}
          </div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'default',
        header: () => t('label.defaultLabel'),
        cell: ({ row }) => (
          <div className="text-left">
            {row.original.default && <Check width={20} height={20} />}
          </div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => (
          <FilterTableAction
            filterId={row.original.id}
            version={row.original.version}
            onOpenEditFilterDialog={handleOpenSaveFilterDialog}
            onDeleteFilter={handleDeleteButtonClick}
            // Display delete button only for filter and created by logged in user
            displayDeleteButton={
              loggedInUser?.id === row.original.createdById &&
              row.original.filterType === FilterType.Custom
            }
          />
        ),
        size: 120,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [handleDeleteButtonClick, handleOpenSaveFilterDialog, loggedInUser?.id, t]
  )

  const tableOptions: TableOptions<Filter> = {
    data: getFiltersResult?.filters ?? [],
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning,
    },
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  // Action when save filter dialog is submitted
  const handleSubmitCrewSaveFilter = useCallback(() => {
    // Close save filter dialog
    closeCrewSaveFilterDialog()
  }, [closeCrewSaveFilterDialog])

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    closeConfirmDialog()

    try {
      await deleteFilter(filterId, version)

      toast.success(t('message.filter.filterDeleted'))
    } catch (err) {
      showApiErrors(err)
    }
  }, [
    closeConfirmDialog,
    deleteFilter,
    filterId,
    showApiErrors,
    t,
    toast,
    version,
  ])

  return (
    <div className="max-h-96 flex">
      <div className="grow overflow-hidden">
        {/* filter table */}
        <CrewTable
          tableOptions={tableOptions}
          showPager={false}
          showColumnSelector={false}
        />
      </div>

      <CrewSaveFilterDialog
        isEditMode={true}
        definition=""
        entityType={props.entityType}
        isOpen={isCrewSaveFilterDialogOpen}
        onClose={closeCrewSaveFilterDialog}
        title={t('label.filterEditTitle')}
        filterId={filterId}
        onSubmit={handleSubmitCrewSaveFilter}
      />

      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </div>
  )
})
