import { useCallback } from 'react'
import {
  useInsertChatReactionMutation,
  useDeleteChatReactionMutation,
} from '@crew/apis/chatReaction/chatReactionApis'

export const useCrewReactionBadge = () => {
  const [insertChatReaction] = useInsertChatReactionMutation()
  const [deleteChatReaction] = useDeleteChatReactionMutation()

  // delete chat reaction
  const deleteReaction = useCallback(
    async (chatMessageId: string, reaction: string) => {
      await deleteChatReaction({
        chatMessageId,
        reaction,
      }).unwrap()
    },
    [deleteChatReaction]
  )

  // insert chat reaction
  const insertReaction = useCallback(
    async (chatMessageId: string, reaction: string) => {
      await insertChatReaction({
        chatMessageId,
        reaction,
      }).unwrap()
    },
    [insertChatReaction]
  )

  return { deleteReaction, insertReaction }
}
