import {
  getMessaging,
  getToken,
  Messaging,
  isSupported,
} from 'firebase/messaging'

import { firebaseInstance } from 'modules/firebase'

/**
 * 通知の許可やデバイストークンはDOMの世界の値でReactの管轄外であり、またアプリ全体でユニークである必要がある。
 * これら値はブラウザに紐付いておりユーザには直接紐付いていないため、アプリが主体的に管理を行うreduxやcontextで取り扱うのは適切ではない。
 * そのため、このファイルに閉じた範囲で独自に管理を行う。reactからはcustom hooksを通じてアクセスする。
 */

/**
 * 通知の許可をhookに通知するイベントリスナ
 */
export const eventChangePermissions = new Set<
  (permission: NotificationPermission) => void
>()

/**
 * token更新をhookに通知するイベントリスナ
 */
export const eventChangeToken = new Set<
  (deviceToken: string | undefined) => void
>()

/**
 * 現在のパーミッション。hookの初期値や、permissionが変更されたかの判定に使う。
 */
export let currentPermission: NotificationPermission | undefined = undefined

/**
 * 現在のデバイストークン。hookのstateの初期値として使う
 */
export let currentToken: string | undefined

// デバイストークンをリトライ込みで取得するヘルパ関数
const getTokenWithRetry = (messaging: Messaging) => {
  // setTimeoutでリトライするため、Promiseでラップする
  return new Promise<string>((resolve) => {
    const attempt = () => {
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
      })
        .then(resolve)
        .catch((err) => {
          console.warn(
            '[firebase] An error occurred while retrieving token. ',
            err
          )
          // エラー時はリトライする
          // （Firebase公式にリトライ間隔のサンプルがなかったため、10秒間隔で設定）
          window.setTimeout(() => {
            attempt()
          }, 10000)
        })
    }
    attempt()
  })
}

// デバイストークン取得処理。通知が許可されたら呼び出す
const getDeviceToken = async () => {
  const messaging = getMessaging(firebaseInstance)
  const deviceToken = await getTokenWithRetry(messaging)
  if (deviceToken) {
    // このあとmountされるhookの初期値としてtokenを保存する
    currentToken = deviceToken
    // 既にmount済のhookへtokenの変更を通知する
    eventChangeToken.forEach((listener) => listener(deviceToken))
  } else {
    console.info(
      '[firebase] No registration token available. Request permission to generate one.'
    )
  }
}

// パーミッションを要求する。許可されたらデバイストークンを取得しつつ、hookに通知する
export const requestPermissionAsync = async () => {
  const supported = await isSupported()

  // Webプッシュ通知をサポートしていない場合、パーミッション要求をスキップする
  if (!supported) {
    console.info(
      '[firebase] iOS does not support FCM token retrieval. Skipping request permission.'
    )
    return
  }

  if (Notification.permission !== 'granted') {
    console.info('[firebase] Requesting notification permission...')

    Notification.requestPermission().then((newPermission) => {
      // 新しいパーミッションが許可で現在のパーミッションが許可でない場合、たった今許可されたことを示す
      if (newPermission === 'granted' && currentPermission !== 'granted') {
        // 新たにデバイストークンを取得する
        getDeviceToken()
      }
      currentPermission = newPermission

      // 全てのuseNotificationPermissionへpermissionの変更を通知する
      eventChangePermissions.forEach((listener) => listener(newPermission))
    })
  }
}

// 初期化処理。asyncが必要なため通常関数でwrapし、即時実行する
const init = () => {
  const initAsync = async () => {
    const supported = await isSupported()

    if (!supported) {
      console.info(
        '[firebase] iOS does not support FCM token retrieval. Skipping request permission.'
      )
    }

    currentPermission = Notification.permission

    // 初期状態で通知が許可済の場合、即座にデバイストークンを取得する
    if (currentPermission === 'granted') {
      getDeviceToken()
    }
  }
  initAsync()
}
init()
