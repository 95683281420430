import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { useTranslation } from '@crew/modules/i18n'
import {
  apiBaseUrl,
  MAX_NUMBER_OF_USERS_FOR_FREE_PLAN,
} from '@crew/configs/constants'
import { FileUploader } from 'devextreme-react'
import classNames from 'classnames'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { VerifyBulkInvitationResponse } from '@crew/apis/dist/tenantSetting/models/verifyBulkInvitation/response'
import { useToast } from 'hooks/useToast'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useTenantUserBulkInviteDialog } from './useTenantUserBulkInviteDialog'
import { BulkInviteUserTable } from './components/bulkInviteUserTable/bulkInviteUserTable'
import {
  useGetInviteableExternalUsersQuery,
  useGetInviteableUsersForFreePlanQuery,
} from '@crew/apis/user/userApis'
import { InvitationRoleType, ContractPlan } from '@crew/enums/app'
import { useAppSelector } from 'states/hooks'

export type PropsMode = {
  title: string
  isOpen: boolean
  onClose: () => void
}

export const TenantUserBulkInviteDialog = memo((props: PropsMode) => {
  const { t } = useTranslation()

  const toast = useToast()

  const tenantSettingUserEventMessage = useAppSelector(
    (state) => state.app.tenantSettingUserEventMessage
  )
  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  const { verifyBulkInvitation, bulkInviteUser, isLoadingBulkInviteUser } =
    useTenantUserBulkInviteDialog()

  const [fileName, setFileName] = useState<string>()
  const [showApiErrors] = useShowApiErrors()

  const {
    data: getInviteableExternalUsersResult,
    refetch: refetchInviteableExternalUsersResult,
  } = useGetInviteableExternalUsersQuery(undefined, {
    skip: currentPlan === ContractPlan.Free,
  })

  const {
    data: getInviteableUsersResult,
    refetch: refetchInviteableUsersResult,
  } = useGetInviteableUsersForFreePlanQuery(undefined, {
    skip: currentPlan !== ContractPlan.Free,
  })

  // refetch inviteable users/external users when tenant setting member updated
  useEffect(() => {
    if (currentPlan === ContractPlan.Free) {
      refetchInviteableUsersResult()
    } else {
      refetchInviteableExternalUsersResult()
    }
  }, [
    tenantSettingUserEventMessage,
    refetchInviteableExternalUsersResult,
    currentPlan,
    refetchInviteableUsersResult,
  ])

  // get remaining inviteable external users
  const remainingInviteableExternalUsers = useMemo(
    () =>
      getInviteableExternalUsersResult
        ? getInviteableExternalUsersResult.inviteableExternalUsers -
          getInviteableExternalUsersResult.currentExternalUsers
        : 0,
    [getInviteableExternalUsersResult]
  )

  const [verifyBulkInvitationResult, setVerifyBulkInvitationResult] =
    useState<VerifyBulkInvitationResponse>()

  // ダウンロードCSVテンプレート
  const handleDownloadCsvTemplate = useCallback(() => {
    window.location.href = `${apiBaseUrl()}api/v1/user-pendings/download-bulk-invitation-csv-template`
  }, [])

  // ファイルアップロード
  const handleFileUpload = useCallback(
    async (file: File) => {
      setFileName(file.name)
      try {
        const result = await verifyBulkInvitation(file)

        setVerifyBulkInvitationResult(result)
      } catch (error) {
        showApiErrors(error)
      }
    },
    [showApiErrors, verifyBulkInvitation]
  )

  // Event handler when close dialog
  const handleCloseDialog = useCallback(() => {
    props.onClose()
    setFileName(undefined)
    setVerifyBulkInvitationResult(undefined)
  }, [props])

  // Event handler when click invite users button
  const handleInviteUsersButtonClick = useCallback(async () => {
    try {
      if (!verifyBulkInvitationResult) return

      if (currentPlan === ContractPlan.Free) {
        if (
          verifyBulkInvitationResult.data.length >
          (getInviteableUsersResult?.inviteableUsers ?? 0)
        ) {
          toast.error(
            t('message.apiError.tenantUsersExceedLimit', {
              remainingUser: getInviteableUsersResult?.inviteableUsers,
            })
          )
          return
        }
      } else {
        // Count the number of external users in the verified result
        const externalUsersCount = verifyBulkInvitationResult.data.filter(
          (user) => user.roleCode.value === InvitationRoleType.External.key
        ).length

        // if the number of selected new users is greater than the remaining inviteable external users, return
        if (externalUsersCount > remainingInviteableExternalUsers) {
          toast.error(
            t('message.apiError.inviteExternalUserLimitExceeded', {
              inviteableExternalUserCount:
                getInviteableExternalUsersResult?.inviteableExternalUsers,
              currentExternalUserCount:
                getInviteableExternalUsersResult?.currentExternalUsers,
              exceededExternalUserCount:
                externalUsersCount - remainingInviteableExternalUsers,
            })
          )
          return
        }
      }

      await bulkInviteUser(verifyBulkInvitationResult)

      // Close dialog
      handleCloseDialog()

      toast.success(t('message.tenant.inviteUserSuccess'))
    } catch (error) {
      showApiErrors(error)
    }
  }, [
    bulkInviteUser,
    currentPlan,
    getInviteableExternalUsersResult?.currentExternalUsers,
    getInviteableExternalUsersResult?.inviteableExternalUsers,
    getInviteableUsersResult?.inviteableUsers,
    handleCloseDialog,
    remainingInviteableExternalUsers,
    showApiErrors,
    t,
    toast,
    verifyBulkInvitationResult,
  ])

  return (
    <Modal
      title={props.title}
      isOpen={props.isOpen}
      onClose={handleCloseDialog}
    >
      {/* モーダルの最小幅を制限し、画面の各入力項目が正しく表示されるようにする */}
      <div className="p-2.5 flex flex-col gap-y-2.5 min-w-[800px]">
        <p>{t('label.bulkInviteUsersDescription')}</p>

        {/* CSVファイルのテンプレートをダウンロード */}
        <p className="crew-link" onClick={handleDownloadCsvTemplate}>
          {t('label.downloadCsvTemplate')}
        </p>

        {/* Note */}
        <div>
          {currentPlan !== ContractPlan.Free ? (
            <>
              <p>{t('label.csvTemplateNote1')}</p>
              <p>{t('label.csvTemplateNote2')}</p>
            </>
          ) : (
            <p>{t('label.csvTemplateNote')}</p>
          )}
        </div>

        {/* ファイルドロップ領域 */}
        <div
          id="dropZone"
          className={classNames(
            'h-24 flex items-center justify-center',
            'border-2 border-dashed', // 線
            'bg-crew-gray-1-light dark:bg-crew-gray-4-dark' // 背景色
          )}
        >
          <p>{fileName ?? t('label.dragAndDropCsvFile')}</p>
        </div>

        {/* ファイルアップローダー */}
        <FileUploader
          dropZone="#dropZone"
          visible={false} //カスタムドロップ領域のみ表示するため、FileUploader本体は表示しない
          uploadMode="instantly"
          uploadFile={handleFileUpload}
          dialogTrigger="#dropZone"
          // FIXME: 現時点で不要なプロパティの扱いをどうするか改めて確認
          //        https://break-tmc.atlassian.net/browse/CREW-5026
          // 以下プロパティは、FileUploader非表示のため不要なプロパティだが、表示する場合は必要になるため念の為設定しておく
          selectButtonText={t('label.selectFile')}
          labelText={t('label.dragDropOrClickFile')}
          uploadedMessage={t('label.uploaded')}
          uploadFailedMessage={t('label.uploadFailed')}
          uploadAbortedMessage={t('label.uploadAborted')}
          invalidMinFileSizeMessage={t('message.invalidMinFileSize')}
          invalidMaxFileSizeMessage={t('message.invalidMaxFileSize')}
          invalidFileExtensionMessage={t('message.invalidFileExtension')}
          allowedFileExtensions={['.csv']}
          accept="text/csv"
        />

        {/* ユーザー一覧 */}
        <div className="flex-1 mt-3">
          {
            // 招待内容の確認でエラーが発生した場合にメッセージを表示する。
            verifyBulkInvitationResult?.errorCount &&
            verifyBulkInvitationResult.errorCount > 0 ? (
              <p className="mb-1 text-sm crew-error-text">
                {t('message.tenant.batchInviteError', {
                  count: verifyBulkInvitationResult.errorCount,
                })}
              </p>
            ) : (
              <></>
            )
          }

          {
            // 「組織のドメインと異なるユーザーが管理者・一般ユーザーとして追加されます。」警告が発生した場合にメッセージを表示する。
            verifyBulkInvitationResult?.hasWarning && (
              <p className="mb-1 text-sm crew-warning-text">
                {t('message.tenant.external_user')}
              </p>
            )
          }

          {
            // 「既に組織に登録済みのユーザーがいます。そのユーザーへの招待は行われません。」警告が発生した場合にメッセージを表示する。
            verifyBulkInvitationResult?.hasRegisterdUser && (
              <p className="mb-1 text-sm crew-warning-text">
                {t('message.tenant.existRegisteredUser')}
              </p>
            )
          }

          <BulkInviteUserTable
            inviteUsers={verifyBulkInvitationResult?.data ?? []}
          />
        </div>

        <div className="flex flex-row gap-x-2.5 items-center">
          <p className="mr-auto">
            {currentPlan === ContractPlan.Free
              ? t('label.hintForAllowInviteableUsers', {
                  remaining: getInviteableUsersResult?.inviteableUsers ?? 0,
                  total: MAX_NUMBER_OF_USERS_FOR_FREE_PLAN,
                })
              : t('label.hintForAllowInviteableExternalUsers', {
                  remaining: remainingInviteableExternalUsers,
                  total:
                    getInviteableExternalUsersResult?.inviteableExternalUsers ??
                    0,
                })}
          </p>

          {/* ユーザーを招待ボタン */}
          <CrewButton
            text={t('action.inviteUser')}
            type="primary"
            onClick={handleInviteUsersButtonClick}
            disabled={
              !verifyBulkInvitationResult?.data ||
              verifyBulkInvitationResult.errorCount > 0 ||
              isLoadingBulkInviteUser
            }
          />

          {/* キャンセルボタン */}
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCloseDialog}
          />
        </div>
      </div>
    </Modal>
  )
})
