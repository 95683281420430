import { FC, memo } from 'react'

import { CrewSelectBox } from 'components/devextreme/crewSelectBox'

import { useCrewCameraSelectBox } from './useCrewCameraSelectBox'
import { useAsyncStateToValueConnector } from '../hooks/useAsyncStateToValueConnector'
import { Device } from 'amazon-chime-sdk-js'

/**
 * カメラを選択するセレクトボックス
 */
export const CrewCameraSelectBox: FC = memo(() => {
  const {
    currentVideoDevice,
    updateVideoDeviceAsync,
    stateToValue,
    deviceList,
  } = useCrewCameraSelectBox()

  const { value, handleValueChanged, isChangingAsyncState } =
    useAsyncStateToValueConnector<Device, string>(
      currentVideoDevice,
      updateVideoDeviceAsync,
      stateToValue
    )

  return (
    <CrewSelectBox
      disabled={isChangingAsyncState} //device切り替え中は変更不可
      valueExpr="id"
      displayExpr="name"
      dataSource={deviceList}
      value={value}
      onValueChanged={handleValueChanged}
      searchEnabled={false}
      showClearButton={false}
    />
  )
})
