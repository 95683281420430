import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { useUserListSearchPanel } from './useUserListSearchPanel'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { UserFilters } from 'enums/app'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import qs from 'qs'
import { TextBox } from 'devextreme-react'
import { ComponentCallbackHandler, isEqualParams } from '@crew/utils'
import { debounce } from 'lodash'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'

export const UserListSearchPanel = memo(() => {
  const { filterDataSource } = useUserListSearchPanel()

  const [searchParams] = useSearchParams()

  const { navigate } = useCrewNavigate()

  const location = useLocation()

  const [filter, setFilter] = useState<UserFilters>(UserFilters.AllUsers)

  const [keyword, setKeyword] = useState<string>('')

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  // クエリーパラメータが変わったら、検索条件に反映する
  useEffect(() => {
    let currentParams: any = qs.parse(searchParams.toString())
    // update filter
    setFilter(currentParams.filter || UserFilters.AllUsers)
    // update keyword
    setKeyword(currentParams.keyword || '')
  }, [searchParams])

  //キーワード検索
  const keywordTextBoxRef = useRef<TextBox>(null)

  // Update selected filter
  const handleFilterChange = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBox, 'onValueChange'>
  >(
    async (value: string) => {
      const currentParams: any = qs.parse(searchParams.toString())

      // nullや空文字はundefinedに置き換える
      const newParams = {
        filter: value || undefined,
        keyword: keyword || undefined,
        // ページ数：1ページ目を指定
        pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
        // ページサイズ：現在の値を引き継ぐ
        // 現在値がない場合は、ユーザー設定の値を使用し、それも無ければデフォルト値を使用する
        pageSize: currentParams.pageSize ?? Number(defaultListDisplayNumber),
      }

      // 現在のクエリパラメータと同じ場合は検索しない
      if (!isEqualParams(currentParams, newParams)) {
        navigate(location.pathname, newParams, true)
      }
    },
    [
      searchParams,
      keyword,
      defaultListDisplayNumber,
      navigate,
      location.pathname,
    ]
  )

  // Delay search when keyup keyword search after 500ms
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        const currentParams: any = qs.parse(searchParams.toString())
        // nullや空文字はundefinedに置き換える
        const newParams = {
          filter: filter || undefined,
          keyword: value || undefined,
          // ページ数：1ページ目を指定
          pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
          // ページサイズ：現在の値を引き継ぐ
          // 現在値がない場合は、ユーザー設定の値を使用し、それも無ければデフォルト値を使用する
          pageSize: currentParams.pageSize ?? Number(defaultListDisplayNumber),
        }

        // 現在のクエリパラメータと同じ場合は検索しない
        if (!isEqualParams(currentParams, newParams)) {
          navigate(location.pathname, newParams, true)
        }
      }, SEARCH_TIMEOUT_MSEC),
    [
      searchParams,
      filter,
      defaultListDisplayNumber,
      navigate,
      location.pathname,
    ]
  )

  // Update keyword value
  const handleKeywordTextBoxValueChanged = useCallback(() => {
    // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
    const input = keywordTextBoxRef.current?.instance.option('text')
    if (input !== undefined && input !== keyword) {
      setKeyword(input)
      debouncedSearch(input)
    }
  }, [debouncedSearch, keyword])

  return (
    <div className="flex-1 flex gap-x-2.5 items-center">
      <CrewSelectBox
        value={filter}
        dataSource={filterDataSource}
        displayExpr="name"
        valueExpr="id"
        searchEnabled={false}
        showClearButton={false}
        onValueChange={handleFilterChange}
      />
      <CrewTextBox
        value={keyword}
        valueChangeEvent="input change"
        onValueChanged={handleKeywordTextBoxValueChanged}
        showClearButton={true}
        ref={keywordTextBoxRef}
        mode="search"
      />
    </div>
  )
})
