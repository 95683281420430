import { FC, memo } from 'react'
import { TagRef } from '@crew/models/refs'

export type CrewFileTagProps = {
  tag: TagRef
}

export const CrewFileTag: FC<CrewFileTagProps> = memo((props) => {
  return (
    <span
      className={
        'flex px-2 py-1 h-6 rounded-full items-center gap-1 crew-border-gray crew-hover-gray-2 border out-set max-w-full'
      }
    >
      <span className="flex-1 truncate text-base leading-4">
        {props.tag.name}
      </span>
    </span>
  )
})
