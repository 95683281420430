import classNames from 'classnames'
import { FC, SVGProps, memo } from 'react'
import { NavLink } from 'react-router-dom'

import { CrewFeedUnreadBadge } from 'components/elements/crewFeedUnreadBadge/crewFeedUnreadBadge'
import { CrewAttentionUnreadBadge } from 'components/elements/crewAttentionUnreadBadge/crewAttentionUnreadBadge'
import { HomeMenuItems } from 'enums/app'

export type SideMenuItemProps = {
  icon: (props: SVGProps<SVGSVGElement>) => React.ReactElement
  label: string
  to: string
  value: HomeMenuItems['value']
}

// メニューの基本スタイル
const baseClassNames =
  'flex flex-row h-full w-full items-center p-2 gap-2 hover:font-bold [&:has(.has-unread)]:font-bold group-hover:font-bold'

// 選択メニューのアクティブ状態スタイル（ハイライト）
const activeClassNames = 'font-bold crew-text-gray-1 crew-bg-gray-2'

export const SideMenuItem: FC<SideMenuItemProps> = memo((props) => {
  const SvgIcon = props.icon
  return (
    <li
      // Warning表示回避のkey指定
      key={props.value}
      className="flex flex-row group relative"
    >
      <NavLink
        to={props.to}
        className={({ isActive }) =>
          classNames(baseClassNames, isActive && activeClassNames)
        }
      >
        <span className="flex shrink-0 items-center justify-center h-6 w-6">
          <SvgIcon width={24} height={24} />
        </span>
        <span>{props.label}</span>
        {/* 未読件数のバッジ */}
        {props.value === HomeMenuItems.MentionAndReply.value && (
          <CrewAttentionUnreadBadge />
        )}
        {props.value === HomeMenuItems.NewArrival.value && (
          <CrewFeedUnreadBadge />
        )}
      </NavLink>
    </li>
  )
})
