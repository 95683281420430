import { FC, memo } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { TaskEntryForm } from 'features/task/components/taskEntryDialog/components/taskEntryForm/taskEntryForm'

type TaskDialogProps = {
  isEditMode: boolean
  title: string
  taskId?: string
  projectId?: string
  parentTaskId?: string
  onSubmit: (taskId: string) => void
  isOpen: boolean
  onClose: () => void
  onDeleted?: (taskId: string) => void
}

export const TaskEntryDialog: FC<TaskDialogProps> = memo((props) => {
  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <TaskEntryForm
        isEditMode={props.isEditMode}
        taskId={props.taskId}
        onCancel={props.onClose}
        onSubmit={props.onSubmit}
        onDeleted={props.onDeleted}
        projectId={props.projectId}
        parentTaskId={props.parentTaskId}
      />
    </Modal>
  )
})
