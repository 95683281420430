import {
  useLazyGetLookupUsersQuery,
  useLazyGetMemberQuery,
  useLazyGetMembersQuery,
} from '@crew/apis/dist/lookup/lookupApis'
import { useDataSource } from './useDataSource'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { MAX_INITIAL_RECORDS } from 'configs/constants'
import { EntityType } from '@crew/enums/domain'

export const useNotificationRecipientDataSource = (
  entityType: EntityType | null | undefined,
  entityRecordId: string | null | undefined,
  isEnabledUser?: boolean
) => {
  const [lazyGetMembersQuery] = useLazyGetMembersQuery()
  const [lazyGetMemberQuery] = useLazyGetMemberQuery()
  const [lazyGetLookupUsersQuery] = useLazyGetLookupUsersQuery()
  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したユーザIDのフィルタリング
          const filteringUserIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetLookupUsersQuery({
            keyword: undefined,
            userId: filteringUserIds,
          }).unwrap()

          return response.users
        } else {
          // インクリメンタルサーチ
          if (!entityType || !entityRecordId) {
            return []
          }

          const response = await lazyGetMembersQuery({
            entityType: entityType,
            entityRecordId: entityRecordId as string,
            keyword: loadOptions.searchValue,
            limit: MAX_INITIAL_RECORDS,
            isEnabledUser,
          }).unwrap()

          return response.projectMembers
        }
      },
      byKey: async (userId) => {
        if (!entityType || !entityRecordId) {
          return null
        }
        const response = await lazyGetMemberQuery({
          entityType: entityType,
          entityRecordId: entityRecordId,
          userId: userId,
          isEnabledUser: undefined,
        }).unwrap()

        return response.projectMember
      },
    }),
    [
      entityRecordId,
      entityType,
      isEnabledUser,
      lazyGetLookupUsersQuery,
      lazyGetMemberQuery,
      lazyGetMembersQuery,
    ]
  )
}
