import { apis } from '../apiBase/apiBase';
export const eventApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query({
            query: (params) => {
                return {
                    url: `events`,
                    method: 'GET',
                    params: params,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetEventsQuery, useLazyGetEventsQuery } = eventApis;
