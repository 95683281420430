import { TaskStateRef, TaskKindRef, UserRef } from '@crew/models/refs'
import { useTranslation } from '@crew/modules/i18n'
import { getDefaultTabValue } from '@crew/utils/enum'
import BaselineDelete from '~icons/ic/baseline-delete'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { CrewTaskPriority } from 'components/elements/crewTaskPriority/crewTaskPriority'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { TaskDetailListTabs } from 'enums/app'
import { FC, memo, useCallback } from 'react'

export type DependencyTask = {
  id: string
  subject: string
  dueDate: string | null
  assignToUser: UserRef | null
  taskPriority: number
  taskKind: TaskKindRef
  taskState: TaskStateRef
  version: number
}

type TaskDetailParentItemProps = {
  task: DependencyTask
  onDelete: (task: DependencyTask) => void
}

export const TaskDetailChildParentItem: FC<TaskDetailParentItemProps> = memo(
  (props) => {
    const { t } = useTranslation()

    // Click the delete button
    const handleDeleteButtonClick = useCallback(() => {
      props.onDelete(props.task)
    }, [props])

    return (
      <div className="flex flex-row items-center gap-2.5">
        {/* タスク種別バッジ */}
        <CrewBadge displayColor={props.task.taskKind.displayColor}>
          {props.task.taskKind.name}
        </CrewBadge>

        {/* Subject */}
        <CrewLink
          to={`/tasks/${props.task.id}/${getDefaultTabValue(
            TaskDetailListTabs
          )}`}
          title={props.task.subject}
          className="line-clamp-2 break-all flex-1"
        >
          {props.task.subject}
        </CrewLink>

        {/* Due Date */}
        <p>
          {t('format.date', {
            value: props.task.dueDate,
          })}
        </p>

        {/* Priority */}
        <CrewTaskPriority taskPriority={props.task.taskPriority} />

        {/* Assign to user */}
        {props.task.assignToUser && (
          <div className="min-w-0 flex-1">
            <CrewUserAvatar
              userId={props.task.assignToUser.id}
              displayName={props.task.assignToUser.displayName}
              size={CrewAvatarSize.xs}
              showLabel={true}
              cacheValue={
                props.task.assignToUser.id + props.task.assignToUser.version
              }
            />
          </div>
        )}

        {/* Task state */}
        <CrewBadge displayColor={props.task.taskState.displayColor}>
          {props.task.taskState.name}
        </CrewBadge>

        {/* action delete */}
        <CrewButton
          stylingMode="text"
          onClick={handleDeleteButtonClick}
          icon={<BaselineDelete width={20} height={20} />}
          size="sm"
        />
      </div>
    )
  }
)
