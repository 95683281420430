import { memo, FC } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { ProjectSettingTaskCategoryEntryForm } from './components/projectSettingTaskCategoryEntryForm/projectSettingTaskCategoryEntryForm'

type PropsMode = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  taskCategoryId: string | null
  version: number | null
}

export const ProjectSettingTaskCategoryEntryDialog: FC<PropsMode> = memo(
  (props) => {
    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <ProjectSettingTaskCategoryEntryForm
          taskCategoryId={props.taskCategoryId}
          isEditMode={props.isEditMode}
          onSubmit={props.onClose}
          onCancel={props.onClose}
        />
      </Modal>
    )
  }
)
