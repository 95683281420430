import { FC, memo, useCallback, useMemo } from 'react'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useAppResetPasswordPage } from 'features/app/components/appResetPasswordPage/useAppResetPasswordPage'
import { useTranslation } from '@crew/modules/i18n'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { LoadIndicator } from 'devextreme-react'
import { TenantNotExist } from '../appLoginPage/components/tenantNotExists/tenantNotExists'
import { useAppSelector } from 'states/hooks'
import { CheckDuplicateTenantIdRequest } from '@crew/apis/dist/app/models/checkDuplicateTenantId/request'
import { useParams } from 'react-router-dom'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useCheckDuplicateTenantIdQuery } from '@crew/apis/app/appApis'
import { SubmitHandler } from 'react-hook-form'
import { FormValues } from './useAppResetPasswordPage'
export const AppResetPasswordPage: FC = memo(() => {
  const { control, handleSubmit, formState, validateRules, updatePassword } =
    useAppResetPasswordPage()

  const { t } = useTranslation()
  const { success } = useToast()
  const { token } = useParams()
  const themeMode = useAppSelector((state) => state.app.currentTheme)
  const [showApiErrors] = useShowApiErrors()
  const { navigate } = useCrewNavigate()

  // TODO: 重複確認APIを存在確認とする汎化の対応を行う
  // https://break-tmc.atlassian.net/browse/CREW-8631
  // Get check duplicate tenant ID
  const getCheckDuplicateTenantIdParams:
    | CheckDuplicateTenantIdRequest
    | undefined = {
    tenantId: window.location.host.split('.').slice(0, 1).join(),
  }
  const {
    data: getCheckDuplicateTenantIdResult,
    isLoading: isLoadingCheckDuplicateTenantId,
    isError: isErrorCheckDuplicateTenantId,
  } = useCheckDuplicateTenantIdQuery(getCheckDuplicateTenantIdParams)

  // handle reset password
  const handleFormSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      // stop form submit redirect
      e.preventDefault()

      const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        try {
          if (token) {
            await updatePassword(data, token)

            success(t('message.resetPassword.resetPasswordSuccess'))
            navigate('/login')
          }
        } catch (error) {
          showApiErrors(error)
        }
      }

      handleSubmit(onSubmit)()
    },
    [handleSubmit, token, updatePassword, success, t, navigate, showApiErrors]
  )

  const canSend = useMemo(
    // formState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () => formState.isDirty && Object.keys(formState.errors).length === 0,
    [
      // https://react-hook-form.com/api/useform/formstate // formStateはproxyなのでformState自体をlistenする必要がある
      formState,
    ]
  )

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  if (isErrorCheckDuplicateTenantId) {
    return <></>
  }

  if (isLoadingCheckDuplicateTenantId) {
    return <LoadIndicator height="1em" width="1em" />
  }

  // Checking exists tenant
  return getCheckDuplicateTenantIdResult?.duplicated ? (
    // reset password form
    <div className="flex flex-row h-screen pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-sm">
        <div className="flex flex-row items-center sm:mb-6">
          {/* ロゴ */}
          <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto" />
        </div>
        <div className="flex flex-row items-center justify-center">
          <span>{t('label.resetPasswordHint')}</span>
        </div>

        <form onSubmit={handleFormSubmit} className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            {/* Password */}
            <div>
              <CrewTextBoxField
                id="password"
                name="password"
                control={control}
                placeholder={t('label.password')}
                labelMode="floating"
                rules={validateRules.password}
                mode="password"
                inputAttr={{
                  'data-testid': 'password',
                }}
                showLabel={false}
              />
            </div>
            {/* Password Confirm */}
            <div>
              <CrewTextBoxField
                id="confirmPassword"
                name="confirmPassword"
                control={control}
                placeholder={t('label.confirmPassword')}
                labelMode="floating"
                rules={validateRules.confirmPassword}
                mode="password"
                inputAttr={{
                  'data-testid': 'confirmPassword',
                }}
                showLabel={false}
              />
            </div>
          </div>
          {/* change password button */}
          <div className="flex flex-row justify-center">
            <CrewButton
              className="grow"
              type="primary"
              useSubmitBehavior={true}
              disabled={!canSend}
              data-testid="resetPassword"
              text={t('action.resetPassword')}
            />
          </div>
        </form>
      </div>
    </div>
  ) : (
    // Warning tenant doesn't exist
    <TenantNotExist />
  )
})
