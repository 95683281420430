import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { FC, MouseEventHandler, memo, useCallback, useMemo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { TaskStateRef, TaskKindRef, UserRef } from '@crew/models/refs'
import { CrewTaskPriority } from '../crewTaskPriority/crewTaskPriority'
import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useModal } from 'components/layouts/modal/useModal'
import { getDefaultTabValue } from '@crew/utils/enum'
import { TaskDetailListTabs } from 'enums/app'
import { CrewUserAvatar } from '../crewUserAvatar/crewUserAvatar'
import { useProjectPermissions } from '@crew/hooks'
import { CrewHtmlContent } from '../crewHtmlContent/crewHtmlContent'
import { convertMinutesToHHMM } from '@crew/utils'
import { CrewLink } from '../crewLink/crewLink'
import classNames from 'classnames'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import dayjs from '@crew/modules'
import { DateFormat } from '@crew/enums/system'

export type TaskTooltip = {
  id: string
  subject: string
  description: string | null
  startDate: string | null
  dueDate: string | null
  assignToUser: UserRef | null
  taskPriority: number
  taskKind: TaskKindRef
  taskState: TaskStateRef
  estimatedWorkTimes: number | null
  actualWorkTimes: number | null
  remainingWorkTimes: number | null
}

export type CrewTaskTooltipProps = {
  data: TaskTooltip
}

export const CrewTaskTooltip: FC<CrewTaskTooltipProps> = memo((props) => {
  const { hasPrjTaskEditPermission } = useProjectPermissions(
    EntityType.Task,
    props.data.id
  )

  const { t } = useTranslation()

  const [isTaskEntryDialogOpen, openTaskEntryDialog, closeTaskEntryDialog] =
    useModal()

  // Get task data from tooltip event
  const task = props.data

  const { navigate } = useCrewNavigate()

  // 詳細ボタンをクリックしたら、タスク詳細に遷移する
  const handleDetailButtonClick = useCallback(() => {
    navigate(`/tasks/${task.id}/${getDefaultTabValue(TaskDetailListTabs)}`)
  }, [navigate, task.id])

  // タスク編集ダイアログの編集完了後
  const handleTaskUpdated = useCallback(() => {
    closeTaskEntryDialog()
  }, [closeTaskEntryDialog])

  // タスク詳細画面へのリンククリックイベント
  const handleLinkClick = useCallback<MouseEventHandler>((event) => {
    // スレッド表示への切替を防止するため、stopPropagationする
    event.stopPropagation()
    // 画面遷移自体はLinkタグの機能で行われるため、処理を書く必要はない
  }, [])

  const displayWorkTime = useMemo(() => {
    let displayStr = ''
    // actual work time
    if (typeof task.actualWorkTimes === 'number') {
      displayStr +=
        t('label.actual') + convertMinutesToHHMM(task.actualWorkTimes)
    }

    // if both estimated and actual work time are set, add a slash
    if (
      typeof task.estimatedWorkTimes === 'number' &&
      typeof task.actualWorkTimes === 'number'
    ) {
      displayStr += '/'
    }

    // estimated work time
    if (typeof task.estimatedWorkTimes === 'number') {
      displayStr +=
        t('label.planned') + convertMinutesToHHMM(task.estimatedWorkTimes)
    }

    return displayStr
  }, [t, task.actualWorkTimes, task.estimatedWorkTimes])

  return (
    <div
      // DevExtremeの仕様でツールチップをクリックしたときに勝手に背景色と文字色が変わるので、暫定対応として「crew-bg-default」「crew-text-default」で上書き
      // TODO: 上記の他にツールチップ内をドラッグするとエラーになるので、これらの調査をCREW-8318で行う
      //       https://break-tmc.atlassian.net/browse/CREW-8318
      className="flex flex-col gap-y-2.5 p-1.5 items-start crew-bg-default crew-text-default"
    >
      <div className="w-full flex gap-2.5 items-center">
        <CrewBadge displayColor={task.taskKind.displayColor}>
          {task.taskKind.name}
        </CrewBadge>

        {/* subject */}
        <CrewLink
          to={`/tasks/${task.id}/${getDefaultTabValue(TaskDetailListTabs)}`}
          title={task.subject}
          onClick={handleLinkClick}
          className="line-clamp-2 whitespace-normal text-left break-all"
        >
          {task.subject}
        </CrewLink>
      </div>

      {/* description */}
      {props.data.description && (
        <div
          className={classNames(
            'line-clamp-2 whitespace-normal text-left break-all',
            HTMLEDITOR_VIEW_STYLE
          )}
        >
          <CrewHtmlContent html={props.data.description} />
        </div>
      )}

      <div className="flex items-center gap-2.5 w-full">
        {/* task kind */}
        <CrewBadge displayColor={task.taskState.displayColor}>
          {task.taskState.name}
        </CrewBadge>

        {/* task priority */}
        <CrewTaskPriority taskPriority={task.taskPriority} />

        {/* 期限 */}
        {task.dueDate && (
          <p className="ml-auto">
            {`${t('label.dueDate')}: ${dayjs(task.dueDate).format(
              DateFormat.YYYYMMDD
            )}`}
          </p>
        )}
      </div>

      <div className="flex justify-between items-center w-full">
        {/* work times */}
        <p>{displayWorkTime}</p>

        {/* assign to user */}
        {task.assignToUser?.displayName && (
          <CrewUserAvatar
            userId={task.assignToUser.id}
            displayName={task.assignToUser.displayName}
            size={CrewAvatarSize.xs}
            cacheValue={task.assignToUser.id + task.assignToUser.version}
            showLabel
          />
        )}
      </div>

      <div className="flex place-content-between items-center w-full">
        {/* 詳細 */}
        <CrewButton
          text={t('action.detail')}
          type="normal"
          stylingMode="outlined"
          onClick={handleDetailButtonClick}
        />
        {/* 編集 */}
        {hasPrjTaskEditPermission && (
          <CrewButton
            text={t('action.edit')}
            type="primary"
            onClick={openTaskEntryDialog}
          />
        )}
      </div>

      <TaskEntryDialog
        isOpen={isTaskEntryDialogOpen}
        isEditMode={true}
        title={t('label.editTaskTitle')}
        taskId={task.id}
        onSubmit={handleTaskUpdated}
        onClose={closeTaskEntryDialog}
      />
    </div>
  )
})
