import { FC, memo, useCallback } from 'react'

import { ComponentCallbackHandler } from '@crew/utils'

import { CrewSwitch } from 'components/devextreme/crewSwitch'
import { useVoiceFocus } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/VoiceFocusProvider'
import { useMicrophoneBindVoiceFocus } from './microphoneBindVoiceFocusProvider'

// ノイズ除去スイッチ
export const CrewNoiseReductionSwitch: FC = memo(() => {
  const { isVoiceFocusEnabled, setStartDeviceAndAddVoiceFocusByVoiceFocus } =
    useMicrophoneBindVoiceFocus()
  const { isVoiceFocusSupported } = useVoiceFocus()

  // スイッチ切換え
  const handleCheckValueChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSwitch, 'onValueChanged'>
  >(
    (e) => {
      // デバイスの切換えとViceFocusの挟み込み
      setStartDeviceAndAddVoiceFocusByVoiceFocus(e.value)
    },
    [setStartDeviceAndAddVoiceFocusByVoiceFocus]
  )

  return (
    <CrewSwitch
      onValueChanged={handleCheckValueChanged}
      defaultValue={isVoiceFocusEnabled}
      disabled={!isVoiceFocusSupported}
    />
  )
})
