export const IsDeletedAttentionItem = (v) => {
    if (typeof v !== 'object' || v === null)
        return false;
    const m = v;
    if (typeof m.chatMessageId !== 'string')
        return false;
    if (typeof m.entityRecordId !== 'string')
        return false;
    return true;
};
