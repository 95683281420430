import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { useModal } from 'components/layouts/modal/useModal'
import { EventTimePeriod } from 'enums/app'
import { EventEntryDialog } from 'features/project/components/eventEntryDialog/eventEntryDialog'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export const ScheduleToolbar: FC = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  // URLからパラメータを取得
  const { '*': remainingPath } = useParams()

  // イベント登録ダイアログ
  const [isEventEntryDialogOpen, openEventEntryDialog, closeEventEntryDialog] =
    useModal()

  const [timePeriod, setTimePeriod] = useState<string>(
    EventTimePeriod.GroupMonthly.value
  )

  useEffect(() => {
    setTimePeriod(remainingPath ?? EventTimePeriod.GroupMonthly.value)
  }, [remainingPath])

  const timePeriodDataSource = useMemo(() => {
    return Object.values(EventTimePeriod).map((time) => {
      return {
        id: time.value,
        name: t(time.text),
      }
    })
  }, [t])

  // Event handler for time period change
  const handleTimePeriodChange = useCallback(
    (value: string) => {
      setTimePeriod(value)
      navigate(value)
    },
    [navigate]
  )

  // Handle new event button click
  const handleNewEventButtonClick = useCallback(() => {
    openEventEntryDialog()
  }, [openEventEntryDialog])

  // Register event finish
  const handleEventRegistered = useCallback(
    (eventId: string, deleteFlg: boolean = false) => {
      // Close entry dialog
      closeEventEntryDialog()
    },
    [closeEventEntryDialog]
  )

  return (
    <div className="flex items-center justify-between">
      <CrewSelectBox
        dataSource={timePeriodDataSource}
        valueExpr="id"
        displayExpr="name"
        searchEnabled={false}
        minSearchLength={0}
        width={200}
        value={timePeriod}
        showClearButton={false}
        onValueChange={handleTimePeriodChange}
      />

      <CrewButton
        type="primary"
        text={t('action.newMeeting')}
        onClick={handleNewEventButtonClick}
      />

      <EventEntryDialog
        isEditMode={false}
        title={t('label.registerNewMeeting')}
        isOpen={isEventEntryDialogOpen}
        onClose={closeEventEntryDialog}
        onSubmit={handleEventRegistered}
      />
    </div>
  )
})
