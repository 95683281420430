import {
  CrewAvatar,
  CrewAvatarShapeType,
} from 'components/elements/crewAvatar/crewAvatar'
import OutlineSettings from '~icons/ic/outline-settings'
import { memo } from 'react'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import { SettingKeyType } from '@crew/enums/app'
import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { useMemo } from 'react'
import { useGetTenantQuery } from '@crew/apis/dist/tenantSetting/tenantSettingApis'

type ReduceReturnType = {
  [key in SettingKeyType]?: string | null
}

/**
 * Contract Head Panel
 * @date 8/15/2023 - 1:08:12 PM
 *
 * @type {*}
 */
export const ContractHeadPanel = memo(() => {
  const { t } = useTranslation()

  const getTenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [SettingKeyType.OrganizationName],
  }
  // get tenant setting data
  const { data: getTenantSettings } = useGetTenantSettingsQuery(
    getTenantSettingsRequestParams
  )

  // テナント情報取得（テナントアバター用にバージョンが必要なため）
  const { data: getTenant } = useGetTenantQuery()

  // tenant name
  const tenantName = useMemo(() => {
    //convert tenant settings to object
    const tenantSettings =
      getTenantSettings?.tenantSettings.reduce<ReduceReturnType>(
        (tenantSetting, currentValue) => ({
          ...tenantSetting,
          [currentValue.key]: currentValue.value,
        }),
        {}
      )
    return tenantSettings?.[SettingKeyType.OrganizationName]
  }, [getTenantSettings])

  // tenant avatar url
  // TODO: Web: generateImageAvatarUrlのリファクタリング
  // https://break-tmc.atlassian.net/browse/CREW-9777
  const tenantAvatarUrl = generateImageAvatarUrl(EntityType.Tenant)

  return (
    <>
      <div className="w-full flex justify-between items-center h-9">
        {/* ページタイトル */}
        <div className="flex gap-2 items-center h-8 text-crew-gray-3-light dark:text-crew-gray-2-dark">
          <OutlineSettings width={24} height={24} />
          <p className="font-bold">{t('label.contract')}</p>
        </div>
      </div>
      <div className="flex flex-row justify-between items-start border-b crew-border-gray gap-2.5">
        <div className="flex flex-row items-center gap-2.5 pb-2.5">
          {/* Tenant Avatar */}

          <CrewAvatar
            displayName={tenantName ?? ''}
            shape={CrewAvatarShapeType.Square}
            imageURL={tenantAvatarUrl}
            cacheValue={
              // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
              getTenant?.tenant
                ? getTenant.tenant.id + getTenant.tenant.version
                : ''
            }
          />

          <div className="flex flex-col">
            {/* Tenant name */}
            <div className="font-normal text-xl" title={tenantName ?? ''}>
              {tenantName}
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
