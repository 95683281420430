import { CrewSwitch } from 'components/devextreme/crewSwitch'
import useToggleLocalMute from 'modules/amazon-chime-sdk-component-library-devextreme/hooks/sdk/useToggleLocalMute'
import { FC, memo } from 'react'

// マイクの使用切り替えスイッチ
export const CrewMicrophoneSwitch: FC = memo(() => {
  const { muted, toggleMute } = useToggleLocalMute()

  return <CrewSwitch onValueChanged={toggleMute} defaultValue={!muted} />
})
