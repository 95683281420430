// タイムゾーン関連の処理を追加　https://day.js.org/docs/en/plugin/timezone
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
// dayjs.tz.guess()で「guessできず取得できなかった場合」のデフォルト値を設定
// NOTE 仮値。問題あれば修正
dayjs.tz.setDefault('Asia/Tokyo');
export default dayjs;
