import { FC, memo, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

type Props = {
  to: string
  icon: React.ReactNode
  children?: ReactNode
}

export const BarNavLink: FC<Props> = memo((props) => {
  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        classNames(
          'flex flex-row items-center h-full gap-1 px-2 hover:font-bold',
          {
            'font-bold rounded-t-md border-b-2 text-crew-gray-900 dark:text-crew-gray-50 crew-bg-default border-b-crew-cyan-600 dark:border-b-crew-cyan-600 border-x border-x-crew-gray-200 dark:border-x-crew-gray-700':
              isActive,
          }
        )
      }
    >
      {props.icon}
      <span className="whitespace-nowrap">{props.children}</span>
    </NavLink>
  )
})
