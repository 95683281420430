// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { AudioInputDevice } from 'amazon-chime-sdk-js'
import React, { createContext, useContext, useState } from 'react'

import { AudioVideoProvider } from '../AudioVideoProvider'
import { ContentShareProvider } from '../ContentShareProvider'
import { DevicesProvider } from '../DevicesProvider'
import { FeaturedVideoTileProvider } from '../FeaturedVideoTileProvider'
import { LocalAudioOutputProvider } from '../LocalAudioOutputProvider'
import { LocalVideoProvider } from '../LocalVideoProvider'
import { useLogger } from '../LoggerProvider'
import { MeetingEventProvider } from '../MeetingEventProvider'
import { RemoteVideoTileProvider } from '../RemoteVideoTileProvider'
import { RosterProvider } from '../RosterProvider'
import MeetingManager from './MeetingManager'

interface Props {
  onDeviceReplacement?: (
    nextDevice: string,
    currentDevice: AudioInputDevice | undefined
  ) => Promise<AudioInputDevice>
  /** Pass a `MeetingManager` instance if you want to share this instance
   * across multiple different `MeetingProvider`s. This approach has limitations.
   * Check `meetingManager` prop documentation for more information.
   */
  meetingManager?: MeetingManager
}

export const MeetingContext = createContext<MeetingManager | null>(null)

export const MeetingProvider: React.FC<React.PropsWithChildren<Props>> = ({
  onDeviceReplacement,
  meetingManager: meetingManagerProp,
  children,
}) => {
  const logger = useLogger()
  const [meetingManager] = useState(
    () => meetingManagerProp || new MeetingManager(logger)
  )

  return (
    <MeetingContext.Provider value={meetingManager}>
      <MeetingEventProvider>
        <AudioVideoProvider>
          <DevicesProvider onDeviceReplacement={onDeviceReplacement}>
            <RosterProvider>
              <RemoteVideoTileProvider>
                <LocalVideoProvider>
                  <LocalAudioOutputProvider>
                    <ContentShareProvider>
                      <FeaturedVideoTileProvider>
                        {children}
                      </FeaturedVideoTileProvider>
                    </ContentShareProvider>
                  </LocalAudioOutputProvider>
                </LocalVideoProvider>
              </RemoteVideoTileProvider>
            </RosterProvider>
          </DevicesProvider>
        </AudioVideoProvider>
      </MeetingEventProvider>
    </MeetingContext.Provider>
  )
}

export const useMeetingManager = (): MeetingManager => {
  const meetingManager = useContext(MeetingContext)

  if (!meetingManager) {
    throw new Error('useMeetingManager must be used within MeetingProvider')
  }

  return meetingManager
}
