import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { ContextMenu } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof ContextMenu>>

/**
 *  ContextMenu内のプロパティのtargetもしくはposition.ofプロパティに、ContextMenu表示を制御したい要素の"#{対象要素のID}"を入れる
 * targetのみもしくはposition.of指定だと、マウスカーソル位置にMenuが出てしまうので、positionプロパティで制御する
 *
 * positionの指定方法
 * position={{
 *            offset: { y: 5 },
 *            at: 'right bottom',
 *            my: 'right top',
 *            collision: 'flip',
 *          }}
 * 参考：https://js.devexpress.com/Documentation/ApiReference/Common/Object_Structures/PositionConfig/
 * node_modules\devextreme\animation\position.d.ts
 * */

export const CrewContextMenu = memo(
  forwardRef<ContextMenu, Props>(({ children, ...rest }, ref) => (
    <ContextMenu ref={ref} {...rest}>
      {children}
    </ContextMenu>
  ))
)
