import { memo } from 'react'
import { useCallback } from 'react'
import { useFileSignedUrl } from 'hooks/useFileSignedUrl'
import { downloadFileFromUrl } from 'utils'
import BaselineSaveAlt from '~icons/ic/baseline-save-alt'

export type FileListActionMenuProps = {
  fileId: string
  fileName: string
  showDownload: boolean
}

export const FileListActionMenu = memo((props: FileListActionMenuProps) => {
  const { getFileSignedUrl } = useFileSignedUrl()

  // TODO: ファイルリンクのコピーが機能していないため一時的に非表示。CREW-13731で恒久対応予定
  //      https://break-tmc.atlassian.net/browse/CREW-13731
  // fileIdをクリップボードにコピーする。コピーされる文字列は「[{{entityType}}:{{ID}}]」。
  // const handleCopyButtonClick = useCallback(() => {
  //   navigator.clipboard.writeText(`[${EntityType.File}:${props.fileId}]`)
  //   toast.success(t('message.file.copySuccess'))
  // }, [props.fileId, t, toast])

  //Click button download request to link download file
  const handleDownloadButtonClick = useCallback(async () => {
    const url = await getFileSignedUrl(props.fileId)

    // download file from signed url
    downloadFileFromUrl(url, props.fileName)
  }, [props.fileId, props.fileName, getFileSignedUrl])

  return (
    <div className="gap-x-3 flex">
      {props.showDownload && (
        <BaselineSaveAlt
          onClick={handleDownloadButtonClick}
          className="cursor-pointer"
        />
      )}
      {/* TODO: ファイルリンクのコピーが機能していないため一時的に非表示。CREW-13731で恒久対応予定
              https://break-tmc.atlassian.net/browse/CREW-13731 */}
      {/* <LinkM onClick={handleCopyButtonClick} className="cursor-pointer" /> */}
    </div>
  )
})
