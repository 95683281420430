import { memo, useCallback, useState } from 'react'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { useProjectDetailMemberList } from './useProjectDetailMemberList'
import { ProjectDetailMemberListToolbar } from './components/projectDetailMemberListToolbar/projectDetailMemberListToolbar'
import { ProjectDetailMemberListPanel } from './components/projectDetailMemberListPanel/projectDetailMemberListPanel'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useToast } from 'hooks/useToast'
import { useAppSelector } from 'states/hooks'
import { useModal } from 'components/layouts/modal/useModal'
import { RowSelectionState } from '@tanstack/react-table'

export const ProjectDetailMemberList = memo(() => {
  const {
    deleteProjectMembers,
    bulkApproveProjectMemberPending,
    bulkRejectProjectMemberPending,
    bulkCancelInvitationProjectMembers,
  } = useProjectDetailMemberList()

  const { projectId } = useParams()

  const { t } = useTranslation()
  const toast = useToast()

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const userIds = useAppSelector((state) => state.projectDetail.selectedUserIds)

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  //handle remove multiple members when click button delete on toolbar
  const handleDeleteMultipleButtonClick = useCallback(() => {
    setConfirmMessage(t('message.general.confirmMessage.delete'))
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    try {
      closeConfirmDialog()

      if (!projectId) return

      // Execute delete project members process
      await deleteProjectMembers(projectId, userIds)

      toast.success(t('message.project.projectMemberDeleted'))

      // reset row selection
      setRowSelection({})
    } catch (err) {
      setErrorMessage(t('message.general.errorMessage.delete'))
      openErrorDialog()
    }
  }, [
    closeConfirmDialog,
    deleteProjectMembers,
    openErrorDialog,
    projectId,
    t,
    toast,
    userIds,
  ])

  // handle approve multiple request when click
  const handleApproveRequestBulkButtonClick = useCallback(async () => {
    try {
      if (!projectId) return

      // Execute approve project member pending in bulk process
      await bulkApproveProjectMemberPending(projectId, userIds)

      toast.success(t('message.project.approveSuccess'))

      // reset row selection
      setRowSelection({})
    } catch (err) {
      setErrorMessage(t('message.general.errorMessage.approve'))
      openErrorDialog()
    }
  }, [
    bulkApproveProjectMemberPending,
    openErrorDialog,
    projectId,
    t,
    toast,
    userIds,
  ])

  // handle reject multiple request when click
  const handleRejectRequestBulkButtonClick = useCallback(async () => {
    try {
      if (!projectId) return

      // Execute reject project member pending in bulk process
      await bulkRejectProjectMemberPending(userIds)

      toast.success(t('message.project.rejectSuccess'))

      // reset row selection
      setRowSelection({})
    } catch (err) {
      setErrorMessage(t('message.general.errorMessage.reject'))
      openErrorDialog()
    }
  }, [
    bulkRejectProjectMemberPending,
    openErrorDialog,
    projectId,
    t,
    toast,
    userIds,
  ])

  // handle cancel multiple request when click
  const handleCancelInvitationButtonClick = useCallback(async () => {
    try {
      if (!projectId) return

      // Execute cancel invitation project member in bulk process
      await bulkCancelInvitationProjectMembers(userIds)

      toast.success(t('message.project.cancelSuccess'))

      // reset row selection
      setRowSelection({})
    } catch (err) {
      setErrorMessage(t('message.general.errorMessage.cancel'))
      openErrorDialog()
    }
  }, [
    bulkCancelInvitationProjectMembers,
    openErrorDialog,
    projectId,
    t,
    toast,
    userIds,
  ])

  return (
    <div className="flex flex-col h-full w-full overflow-scroll">
      <CrewErrorBoundary>
        <ProjectDetailMemberListToolbar
          onDelete={handleDeleteMultipleButtonClick}
          onApproveRequest={handleApproveRequestBulkButtonClick}
          onCancelInvitation={handleCancelInvitationButtonClick}
          onRejectRequest={handleRejectRequestBulkButtonClick}
        />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <div className="px-2">
          <ProjectDetailMemberListPanel
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </div>
      </CrewErrorBoundary>

      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />

      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />
    </div>
  )
})
