import { FC, memo, ReactNode } from 'react'

import { BackgroundEffectProvider } from 'components/chime/backgroundEffectProvider'
import { MeetingProvider } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/MeetingProvider'
import { JoinMeetingInfo } from 'modules/amazon-chime-sdk-component-library-devextreme/types'
import { VoiceFocusModelName } from 'amazon-chime-sdk-js'
import { VoiceFocusProvider } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/VoiceFocusProvider'
import { MicrophoneBindVoiceFocusProvider } from './microphoneBindVoiceFocusProvider'

type Props = { children: ReactNode }

// ***** VoiceFocusを利用するための設定 *****
const joinInfo: JoinMeetingInfo = {
  Meeting: {
    MeetingFeatures: { Audio: {} },
  },
  Attendee: '',
}

function voiceFocusName(name: string): VoiceFocusModelName {
  if (name && ['default', 'ns_es'].includes(name)) {
    return name as VoiceFocusModelName
  }
  return 'default'
}

function getVoiceFocusSpecName(): VoiceFocusModelName {
  if (
    joinInfo &&
    joinInfo.Meeting?.MeetingFeatures?.Audio?.EchoReduction === 'AVAILABLE'
  ) {
    // Echo Reduction
    return voiceFocusName('ns_es')
  }
  // Amazon Voice Focus
  return voiceFocusName('default')
}

const voicefocusConfigValue = {
  spec: { name: getVoiceFocusSpecName() },
  createMeetingResponse: joinInfo,
}

// ***** VoiceFocusを利用するための設定ここまで *****

/**
 * Chime系機能をまとめて提供するプロバイダ。アプリ全体で単一とすること
 */
export const ChimeProvider: FC<Props> = memo((props) => {
  return (
    <VoiceFocusProvider {...voicefocusConfigValue}>
      <MeetingProvider>
        <BackgroundEffectProvider>
          <MicrophoneBindVoiceFocusProvider>
            {props.children}
          </MicrophoneBindVoiceFocusProvider>
        </BackgroundEffectProvider>
      </MeetingProvider>
    </VoiceFocusProvider>
  )
})
