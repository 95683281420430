import {
  useLazyGetLookupProjectGroupQuery,
  useLazyGetLookupProjectGroupsQuery,
} from '@crew/apis/lookup/lookupApis'
import { useDataSource } from './useDataSource'
import { MAX_INITIAL_RECORDS } from 'configs/constants'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'

export const useProjectGroupDataSource = () => {
  const [lazyGetProjectGroupsQuery] = useLazyGetLookupProjectGroupsQuery()
  const [lazyGetProjectGroupQuery] = useLazyGetLookupProjectGroupQuery()

  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したタグIDのフィルタリング
          const filteringProjectGroupIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetProjectGroupsQuery({
            name: undefined,
            projectGroupIds: filteringProjectGroupIds,
          }).unwrap()

          return response.projectGroups
        } else {
          // インクリメンタルサーチ
          const response = await lazyGetProjectGroupsQuery({
            name: loadOptions.searchValue,
            projectGroupIds: undefined,
            limit: MAX_INITIAL_RECORDS,
          }).unwrap()
          return response.projectGroups
        }
      },
      byKey: async (projectGroupId) => {
        const response = await lazyGetProjectGroupQuery({
          projectGroupId,
        }).unwrap()
        return response.projectGroup
      },
    }),
    [lazyGetProjectGroupsQuery, lazyGetProjectGroupQuery]
  )
}
