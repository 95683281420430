import { CrewFileUploader } from 'components/devextreme/crewFileUploader/crewFileUploader'
import { UploadFile } from 'models/domain/uploadFile'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { FileUploadConfirmDialog } from 'features/file/components/fileUploadConfirmDialog/fileUploadConfirmDialog'
import { memo, useCallback, useState } from 'react'
import { EventDetailFileListToolbar } from './components/eventDetailFileListToolbar/eventDetailFileListToolbar'
import { useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/dist/domain'
import { useParams } from 'react-router-dom'
import { FileTable } from './components/fileTable/fileTable'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyFileEvent,
} from 'features/app/states/appSlice'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useAppDispatch } from 'states/hooks'
import { FormValues } from 'features/file/components/fileUploadConfirmDialog/components/fileUploadConfirmForm/useFileUploadConfirmForm'
import { useInsertEventFilesMutation } from '@crew/apis/project/projectApis'

export const EventDetailFileList = memo(() => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const { success, error } = useToast()

  const { eventId } = useParams()

  // アップロード済みのファイル一覧
  const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])

  const [insertEventFilesMutation] = useInsertEventFilesMutation()

  const [
    isFileUploadConfirmDialogOpen,
    openFileUploadConfirmDialog,
    closeFileUploadConfirmDialog,
  ] = useModal()

  const [generalShowApiErrors] = useShowApiErrors()

  // アップロードファイル確認ダイアログのキャンセル時
  const handleCancel = useCallback(() => {
    // アップロード済ファイル情報のクリア
    setUploadedFileList([])

    // ダイアログをクローズ
    closeFileUploadConfirmDialog()
  }, [closeFileUploadConfirmDialog])

  const handleSubmit = useCallback(
    async (data: FormValues) => {
      try {
        if (!eventId) {
          return
        }

        // Because uploadFileList type is different from Files type in bulk insert file request
        // it is necessary to map the data type of files
        const files = uploadedFileList.map((file) => {
          return {
            name: file.name,
            keyName: file.keyName ?? '',
          }
        })
        try {
          // NOTE: アップロードしたファイル情報はuploadedFileListから取得
          await insertEventFilesMutation({
            eventId,
            fileHistoryDescription: data.description,
            files,
            needNotification: data.needNotification,
          }).unwrap()

          const objectEventMessage: ObjectEventMessage<File> = {
            eventType: NotifyEventType.Inserted,
            id: files[0].keyName,
            object: undefined,
          }
          //Refresh file list
          dispatch(notifyFileEvent(objectEventMessage))

          success(t('message.file.uploadSuccess'))

          // アップロード済ファイル情報のクリア
          setUploadedFileList([])
        } catch (err) {
          error(t('message.file.uploadFailed'))
        }

        // ダイアログをクローズ
        closeFileUploadConfirmDialog()
      } catch (err) {
        generalShowApiErrors(err)
      }
    },
    [
      eventId,
      uploadedFileList,
      closeFileUploadConfirmDialog,
      insertEventFilesMutation,
      dispatch,
      success,
      t,
      error,
      generalShowApiErrors,
    ]
  )

  // アップロード完了後
  const handleUploaded = useCallback(
    (file: UploadFile) => {
      // アップロードしたファイル情報を配列に格納
      setUploadedFileList((baseData) => {
        const index = baseData.findIndex((item) => item.name === file.name)
        if (index === -1) {
          return [...baseData, file]
        } else {
          // replace the file with the same name
          baseData[index] = file
          return [...baseData]
        }
      })

      // 確認ダイアログを表示
      openFileUploadConfirmDialog()
    },
    [openFileUploadConfirmDialog]
  )

  const { hasPrjFileCreatePermission } = useProjectPermissions(
    EntityType.Event,
    eventId
  )

  // アップロードエラー時
  const handleUploadError = useCallback(
    (file: UploadFile) => {
      // uploadedFileListに格納している該当ファイル情報を削除する
      setUploadedFileList((baseData) =>
        baseData.filter((item) => item.name !== file.name)
      )

      // アップロード済ファイル情報が0件の場合、確認ダイアログをクローズ
      if (uploadedFileList.length === 0) {
        closeFileUploadConfirmDialog()
      }
    },
    [closeFileUploadConfirmDialog, uploadedFileList.length]
  )

  return (
    <>
      <CrewErrorBoundary>
        <EventDetailFileListToolbar />
      </CrewErrorBoundary>
      <CrewErrorBoundary>
        <CrewFileUploader
          multiple={true}
          onUploaded={handleUploaded}
          onUploadError={handleUploadError}
          disabled={!hasPrjFileCreatePermission}
          isCancelUpload={!isFileUploadConfirmDialogOpen}
        >
          <div className="px-2.5">
            <FileTable />
          </div>
        </CrewFileUploader>
        <FileUploadConfirmDialog
          title={t('label.fileUpload')}
          isOpen={isFileUploadConfirmDialogOpen}
          onClose={handleCancel}
          onSubmit={handleSubmit}
          uploadFiles={uploadedFileList}
        />
      </CrewErrorBoundary>
    </>
  )
})
