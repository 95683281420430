import { useTranslation } from '@crew/modules/dist/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { memo, useCallback, useEffect } from 'react'
import {
  FormValues,
  useContractChangeContactInformationPage,
} from './useContractChangeContactInformationPage'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useGetContactQuery } from '@crew/apis/dist/contract/contractApis'
import { useToast } from 'hooks/useToast'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'

// 「連絡先情報の変更」画面
export const ContractChangeContactInformationPage = memo(() => {
  const {
    control,
    validateRules,
    handleSubmit,
    setError,
    reset,
    updateContact,
  } = useContractChangeContactInformationPage()
  const { t } = useTranslation()
  const toast = useToast()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const { historyBack, navigate } = useCrewNavigate()

  const { data: getContactResult, isFetching } = useGetContactQuery()

  // フォーム初期化処理関数
  const initializeForm = useCallback(async () => {
    if (getContactResult) {
      reset({
        contactName: getContactResult.contactName ?? undefined,
        companyName: getContactResult.companyName,
        departmentName: getContactResult.departmentName ?? undefined,
        officialPosition: getContactResult.officialPosition ?? undefined,
        tel: getContactResult.tel ?? undefined,
      })
    }
  }, [getContactResult, reset])

  // フォーム初期化処理
  useEffect(() => {
    initializeForm()
  }, [initializeForm])

  // 登録するボタン押下時
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // 連絡先情報を更新
        await updateContact(data)

        toast.success(t('message.contract.updateContactSuccess'))

        // 契約情報画面に遷移
        navigate('/contract/portal')
      } catch (error) {
        showApiErrors(error)
      }
    }

    handleSubmit(onSubmit)()
  }, [handleSubmit, updateContact, toast, t, navigate, showApiErrors])

  // キャンセルボタンクリック時
  const handleCancelButtonClick = useCallback(() => {
    // 前のページに戻る
    historyBack()
  }, [historyBack])

  return (
    <form className="flex-col justify-start items-center inline-flex">
      <div className="flex-col justify-start items-start gap-2.5 flex w-full max-w-2xl">
        <div className="py-2.5 w-full flex-col justify-start items-start gap-2.5 flex">
          {/* タイトル */}
          <span className="text-md font-bold">
            {t('label.changeContactInformation')}
          </span>

          {/* 会社名 */}
          <div className="w-full">
            <CrewTextBoxField
              id="companyName"
              name="companyName"
              control={control}
              label={t('label.companyName')}
              placeholder={t('label.companyNameSample')}
              rules={validateRules.companyName}
              required
            />
            <span className="text-sm text-crew-red-3-light dark:text-crew-red-2-dark">
              {t('label.companyNameNotice')}
            </span>
          </div>

          {/* 氏名 */}
          <div className="w-full">
            <CrewTextBoxField
              id="contactName"
              name="contactName"
              control={control}
              label={t('label.fullName')}
              placeholder={t('label.contactNameSample')}
              rules={validateRules.contactName}
              required
            />
            <span className="text-sm text-crew-red-3-light dark:text-crew-red-2-dark">
              {t('label.contactNameNotice')}
            </span>
          </div>

          {/* 部門名 */}
          <div className="w-full">
            <CrewTextBoxField
              id="departmentName"
              name="departmentName"
              control={control}
              label={t('label.departmentName')}
              placeholder={t('label.departmentNameSample')}
              rules={validateRules.departmentName}
            />
          </div>

          {/* 役職 */}
          <div className="w-full">
            <CrewTextBoxField
              id="officialPosition"
              name="officialPosition"
              control={control}
              label={t('label.officialPosition')}
              placeholder={t('label.officialPositionSample')}
              rules={validateRules.officialPosition}
            />
          </div>
          {/* 電話番号 */}
          <div className="w-full">
            <CrewTextBoxField
              id="tel"
              name="tel"
              control={control}
              label={t('label.tel')}
              placeholder={t('label.telSample')}
              rules={validateRules.tel}
              required
            />
          </div>
        </div>

        <div className="justify-start items-start gap-2.5 inline-flex">
          {/* 登録ボタン */}
          <CrewButton
            text={t('action.register2')}
            type="primary"
            className="mr-auto"
            onClick={handleSubmitButtonClick}
            disabled={isFetching}
          />
          {/* キャンセルボタン */}
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            className="mr-auto"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </form>
  )
})
