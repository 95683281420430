import { PRICE_URL } from '@crew/configs/dist/constants'
import { BillingCycle } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import Check from '~icons/material-symbols/check'
import classNames from 'classnames'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { PlanId } from 'enums/app'
import { FC, memo } from 'react'
type PlanSelectItemProps = {
  onTryFreeNowButtonClick: (plan: Plan) => void
  plan: Plan
  billingCycle: BillingCycle
}
export type Plan = {
  name: string
  description: string[]
  price: {
    annual: string
    monthly: string
  }
  priceMonthly: string
  feature: string[]
  id: PlanId
}

/**
 * PlanSelectItem
 * @date 8/17/2023 - 10:36:33 AM
 *
 * @type {FC<PlanSelectItemProps>}
 */
export const PlanSelectItem: FC<PlanSelectItemProps> = memo((props) => {
  const { onTryFreeNowButtonClick } = props
  const { name, description, price, priceMonthly, feature, id } = props.plan
  const { t } = useTranslation()
  // 年額契約かどうか
  const isAnnual = props.billingCycle === BillingCycle.Year

  return (
    <div
      className={classNames(
        'w-full p-5 bg-crew-blue-100 rounded-2xl flex-col justify-start items-start gap-5 inline-flex',
        id === PlanId.Starter && 'crew-bg-gray-2'
      )}
    >
      {/** plan name */}
      <div className="self-stretch justify-start items-center inline-flex">
        <span
          className={classNames(
            'text-crew-blue-600 dark:text-crew-blue-400 text-2xl font-bold leading-tight',
            {
              'crew-text-gray-3': id === PlanId.Starter,
            }
          )}
        >
          {name}
        </span>
      </div>
      {/** plan price */}
      <div className="self-stretch justify-start inline-flex flex-col">
        <div className="text-2xl">{t('label.planPricePerUser')}</div>
        <div className="self-stretch justify-start items-end inline-flex">
          <span className="text-5xl font-bold leading-9">
            {isAnnual ? price.annual : price.monthly}
          </span>
          <span className="text-5xl font-bold leading-9">{t('label.yen')}</span>
          <span className="text-2xl leading-tight">
            {isAnnual
              ? t('label.planPriceAnnual')
              : t('label.planPriceMonthly')}
          </span>
        </div>
        {isAnnual ? (
          <div className="self-stretch justify-start items-end inline-flex mt-2 crew-text-gray-5">
            <span className="text-3xl font-bold">{priceMonthly}</span>
            <span className="text-2xl font-bold">{t('label.yen')}</span>
            <span className="text-2xl">{t('label.planPricePerMonth')}</span>
          </div>
        ) : (
          <div className="h-10"></div>
        )}
      </div>

      {/** try it free now button */}
      <div className="self-stretch justify-start items-center inline-flex">
        <CrewButton
          className={classNames(
            'grow shrink basis-0 p-0.5',
            // Starterプランの場合のみ、特例的にCrewButtonで定義されているtype以外の色を使用したいため、個別に色を指定
            id === PlanId.Starter &&
              '!bg-crew-gray-500 dark:!bg-crew-gray-400 !text-crew-white hover:!bg-crew-gray-600 hover:dark:!bg-crew-gray-500'
          )}
          text={t('label.tryForFree')}
          type={id === PlanId.Starter ? 'normal' : 'primary'}
          onClick={() => {
            onTryFreeNowButtonClick(props.plan)
          }}
        />
      </div>
      {/** plan description */}
      <div className="self-stretch justify-start items-start inline-flex flex-col">
        {description.map((item, index) => {
          return (
            <span
              key={index}
              className="grow shrink basis-0 crew-text-gray-4 text-2xl leading-tight"
            >
              {item}
            </span>
          )
        })}
      </div>
      {/** plan feature */}
      <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-2.5 flex">
        {feature.map((item, index) => {
          return (
            <div
              key={index}
              className="self-stretch px-2.5 justify-start items-start gap-2.5 inline-flex"
            >
              <Check
                width={24}
                height={24}
                className="text-crew-blue-600 dark:text-crew-blue-400"
              />
              <span className="text-2xl leading-tight">{item}</span>
            </div>
          )
        })}
      </div>
      {/** plan compare more*/}
      <div className="self-stretch justify-center items-center inline-flex">
        <CrewLink to={PRICE_URL} target="_blank">
          {t('label.compareMorePlan')}
        </CrewLink>
      </div>
    </div>
  )
})
