import { GanttTask } from '@crew/apis/task/models/getGanttTasks/response'
import { useTranslation } from '@crew/modules/i18n'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import {
  TreeList,
  Editing,
  Column,
  RequiredRule,
  Lookup,
  RowDragging,
  CustomRule,
  Toolbar,
  Item,
  Scrolling,
} from 'devextreme-react/tree-list'
import {
  setIsShowEditWBS,
  setIsWbsNumberRenumbering,
} from 'features/project/components/projectDetailPage/states/projectDetailSlice'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useProjectDetailTaskListWBSEdit } from './useProjectDetailTaskListWBSEdit'
import {
  useGetGanttTasksQuery,
  useUpdateWbsTaskMutation,
} from '@crew/apis/task/taskApis'
import { useParams } from 'react-router-dom'
import { GetGanttTasksRequest } from '@crew/apis/task/models/getGanttTasks/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { RowUpdatedInfo } from 'devextreme/common/grids'
import { cloneDeep } from 'lodash'
import {
  convertMinutesToHHMM,
  convertScheduledTimeToMinutes,
  isRegexFormatScheduledTime,
} from '@crew/utils'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import dayjs from '@crew/modules'
import { UserRef } from '@crew/models/refs'
import { WbsUpdatableColumn } from '@crew/enums/domain'
import { JsonDateFormat } from '@crew/enums/system'
import { RenumberWbsNumberRequest_Task } from '@crew/apis/dist/task/models/renumberWbsNumber/request'
import { useRenumberWbsNumberMutation } from '@crew/apis/dist/task/taskApis'
import { useToast } from 'hooks/useToast'
import { EditingStartEvent, Node } from 'devextreme/ui/tree_list'
import EventAvailable from '~icons/material-symbols/event-available'
import AccountTreeOutline from '~icons/material-symbols/account-tree-outline'
import BaselineDelete from '~icons/ic/baseline-delete'
import FormatIndentDecrease from '~icons/material-symbols/format-indent-decrease'
import FormatIndentIncrease from '~icons/material-symbols/format-indent-increase'
import { useModal } from 'components/layouts/modal/useModal'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { useValueChangeEffect } from '@crew/hooks'
import { NotifyEventType } from 'enums/app'
import { compareWBSNumber } from 'utils'

// calculate parent estimated work times
const calculateParentEstimatedWorkTimes = (
  tasks: GanttTask[]
): number | null => {
  const estimatedWorkTimes = tasks.reduce((total: number | null, task) => {
    if (typeof task.estimatedWorkTimes === 'number') {
      return (total ?? 0) + task.estimatedWorkTimes
    } else {
      if (isRegexFormatScheduledTime(String(task.estimatedWorkTimes))) {
        const minutes = convertScheduledTimeToMinutes(
          String(task.estimatedWorkTimes)
        )

        if (typeof minutes === 'number') {
          return (total ?? 0) + minutes
        }

        return total
      }

      return total
    }
  }, null)

  return estimatedWorkTimes
}

// Get the minimum start date from a list of tasks, optionally comparing it to a target start date.
const getMinStartDate = (tasks: GanttTask[]) => {
  // Reduce the tasks array to find the earliest start date
  const date = tasks.reduce((minStartDate: Date | null, task) => {
    if (!task.startDate) return minStartDate // If task start date is not set, return the current

    const startDate = dayjs(task.startDate).toDate() // Convert task start date to Date object
    if (!minStartDate) return startDate // If minStartDate is not set, use the current startDate

    // Return the earlier of the two dates
    return startDate < minStartDate ? startDate : minStartDate
  }, null)

  return date
}

// Get the maximum due date from a list of tasks, optionally comparing it to a target end date.
const getMaxDueDate = (tasks: GanttTask[]) => {
  // Reduce the tasks array to find the latest due date
  const date = tasks.reduce((maxDueDate: Date | null, task) => {
    if (!task.dueDate) return maxDueDate // If task due date is not set, return the current

    const dueDate = dayjs(task.dueDate).toDate() // Convert task due date to Date object
    if (!maxDueDate) return dueDate // If maxDueDate is not set, use the current dueDate

    // Return the later of the two dates
    return dueDate > maxDueDate ? dueDate : maxDueDate
  }, null)

  return date
}

// update parent task start date
const updateParentTasks = (
  node: Node<GanttTask, any>,
  targetTask: GanttTask,
  actionType: 'update' | 'delete' | 'insert'
): GanttTask[] => {
  const newTask = {
    ...targetTask,
    startDate: actionType === 'delete' ? null : targetTask.startDate,
    dueDate: actionType === 'delete' ? null : targetTask.dueDate,
    estimatedWorkTimes:
      actionType === 'delete' ? 0 : targetTask.estimatedWorkTimes,
  }
  const updatedTasks: GanttTask[] = []
  // Get the parent node of the current node
  const updateParentTask = (
    parentNode: Node<GanttTask, any>,
    task: GanttTask
  ) => {
    // Get the child tasks of the parent node, excluding the current node
    const childTasks = parentNode.children?.map((childNode) => {
      if (childNode.key === task.id) return task

      return childNode.data as GanttTask
    })

    // add target task to child tasks if actionType is insert
    if (actionType === 'insert' && targetTask.parentTaskId === parentNode.key) {
      childTasks?.push(targetTask)
    }

    // Get the minimum start date of the child tasks
    const minStartDate = getMinStartDate(childTasks ?? [])
    // Get the maximum due date of the child tasks
    const maxDueDate = getMaxDueDate(childTasks ?? [])

    if (parentNode.data) {
      parentNode.data.startDate = minStartDate
        ? dayjs(minStartDate).format(JsonDateFormat.YYYYMMDD)
        : minStartDate

      parentNode.data.dueDate = maxDueDate
        ? dayjs(maxDueDate).format(JsonDateFormat.YYYYMMDD)
        : maxDueDate

      parentNode.data.estimatedWorkTimes = calculateParentEstimatedWorkTimes(
        childTasks ?? []
      )

      updatedTasks.push(parentNode.data)
    }

    if (parentNode.parent && parentNode.data?.parentTaskId) {
      // Continue to the parent node
      updateParentTask(parentNode.parent, parentNode.data as GanttTask)
    }
  }

  // Start updating the parent task
  updateParentTask(node, newTask)

  return updatedTasks
}

// update old direct parent task
const updateOldDirectParentTask = (
  directParentTaskNode: Node<GanttTask, any>,
  targetTaskId: string
) => {
  const updatedTask = cloneDeep(directParentTaskNode.data as GanttTask)

  // Get the child tasks of the parent node, excluding the current node
  const childTasks = directParentTaskNode.children
    ?.filter((childNode) => childNode.data?.id !== targetTaskId)
    .map((childNode) => childNode.data as GanttTask)

  // Get the minimum start date of the child tasks
  const minStartDate = getMinStartDate(childTasks ?? [])
  // Get the maximum due date of the child tasks
  const maxDueDate = getMaxDueDate(childTasks ?? [])
  // calculate parent estimated work times
  const estimatedWorkTimes = calculateParentEstimatedWorkTimes(childTasks ?? [])

  updatedTask.startDate = minStartDate
    ? dayjs(minStartDate).format(JsonDateFormat.YYYYMMDD)
    : minStartDate
  updatedTask.dueDate = maxDueDate
    ? dayjs(maxDueDate).format(JsonDateFormat.YYYYMMDD)
    : maxDueDate
  updatedTask.estimatedWorkTimes = estimatedWorkTimes

  return updatedTask
}

// update new direct parent task
const updateNewDirectParentTask = (
  newDirectParentTaskNode: Node<GanttTask, any>,
  targetTask: GanttTask
) => {
  const updatedTask = cloneDeep(newDirectParentTaskNode.data as GanttTask)

  // Get the child tasks of the parent node, excluding the current node
  const childTasks = newDirectParentTaskNode.children?.map(
    (childNode) => childNode.data as GanttTask
  )
  childTasks?.push(targetTask)

  // Get the minimum start date of the child tasks
  const minStartDate = getMinStartDate(childTasks ?? [])
  // Get the maximum due date of the child tasks
  const maxDueDate = getMaxDueDate(childTasks ?? [])
  // calculate parent estimated work times
  const estimatedWorkTimes = calculateParentEstimatedWorkTimes(childTasks ?? [])

  updatedTask.startDate = minStartDate
    ? dayjs(minStartDate).format(JsonDateFormat.YYYYMMDD)
    : minStartDate
  updatedTask.dueDate = maxDueDate
    ? dayjs(maxDueDate).format(JsonDateFormat.YYYYMMDD)
    : maxDueDate
  updatedTask.estimatedWorkTimes = estimatedWorkTimes

  return updatedTask
}

// get all children tasks keys
const getAllChildrenTaskKeys = (node: Node<GanttTask, any>): string[] => {
  const childrenTaskKeys: string[] = []

  const getChildrenTaskKeys = (childNode: Node<GanttTask, any>) => {
    if (!childNode.children) return

    childNode.children.forEach((child) => {
      childrenTaskKeys.push(child.key)
      getChildrenTaskKeys(child)
    })
  }

  getChildrenTaskKeys(node)

  return childrenTaskKeys
}

// render assignToUser
const renderAssignToUser = ({ value }: { value: UserRef }): React.ReactNode => {
  if (!value) return null

  return (
    <CrewUserAvatar
      userId={value.id}
      displayName={value.displayName}
      cacheValue={value.id + value.version}
      disableClick
      showLabel
      size={CrewAvatarSize.xs}
    />
  )
}

// render progress rate
const renderProgressRate = ({
  value,
}: {
  value: number | null
}): React.ReactNode => {
  if (!value) return null

  return `${value}%`
}

// render task state
const renderTaskState = ({ data }: { data: GanttTask }): React.ReactNode => {
  return (
    <div className="w-full text-center">
      <CrewBadge displayColor={data.taskState.displayColor}>
        {data.taskState.name}
      </CrewBadge>
    </div>
  )
}

// calculate estimated work times
const calculateEstimatedWorkTimes = (task: GanttTask): React.ReactNode => {
  if (isRegexFormatScheduledTime(String(task.estimatedWorkTimes)))
    return task.estimatedWorkTimes

  return typeof task.estimatedWorkTimes === 'number'
    ? convertMinutesToHHMM(task.estimatedWorkTimes)
    : ''
}

// Event handle when the node is dragged
// `any` type is used because the event type is not defined in the devextreme-react library
const onDragChange = (e: any) => {
  // The visible rows
  const visibleRows = e.component.getVisibleRows()

  // The node is dragged
  const sourceNode = e.component.getNodeByKey(e.itemData.id)

  // The node located at the position of the dragged node is dropped
  let targetNode = visibleRows[e.toIndex].node

  while (targetNode && targetNode.data) {
    if (targetNode.data.id === sourceNode.data.id) {
      // This prevents the item from being dropped onto itself or its descendants
      e.cancel = true
      break
    }

    // Move up the tree to the parent node to check if dropping is valid in the next iteration
    targetNode = targetNode.parent
  }
}

// render actual work times
const renderActualWorkTimes = ({
  value,
}: {
  value: number | null
}): React.ReactNode => {
  if (!value) return null

  return convertMinutesToHHMM(value)
}

// Update tree list node data when drag and drop task
const updateNodeWhenDragDropTask = (
  targetTask: GanttTask,
  oldParentTaskId: string | null,
  nodeRef: TreeList<GanttTask, any>
) => {
  const changedTasks: GanttTask[] = [targetTask]
  let oldDirectParentTask: GanttTask | undefined
  let newDirectParentTask: GanttTask | undefined

  // Get old direct parent task node
  const oldDirectParentTaskNode = nodeRef.instance.getNodeByKey(oldParentTaskId)
  // Get new direct parent task node
  const newDirectParentTaskNode = nodeRef.instance.getNodeByKey(
    targetTask.parentTaskId
  )

  if (oldDirectParentTaskNode) {
    oldDirectParentTask = updateOldDirectParentTask(
      oldDirectParentTaskNode,
      targetTask.id
    )

    changedTasks.push(oldDirectParentTask)
  }

  if (newDirectParentTaskNode) {
    // expand new direct parent task node
    nodeRef.instance.expandRow(newDirectParentTaskNode.key)

    newDirectParentTask = updateNewDirectParentTask(
      newDirectParentTaskNode,
      targetTask
    )

    changedTasks.push(newDirectParentTask)
  }

  let updatedParentTasks: GanttTask[] = []

  if (oldDirectParentTask?.parentTaskId === newDirectParentTask?.id) {
    if (oldDirectParentTaskNode?.parent && oldDirectParentTask) {
      // update old direct parent task parent tasks children data
      const updatedOldDirectParentTask = {
        ...oldDirectParentTaskNode.parent,
        children: oldDirectParentTaskNode.parent.children?.map((child) => {
          if (child.key === oldDirectParentTask?.id) {
            return {
              ...child,
              data: oldDirectParentTask,
            }
          }

          return child
        }),
      }

      // update old direct parent task parent tasks
      updatedParentTasks = updateParentTasks(
        updatedOldDirectParentTask,
        targetTask,
        'insert'
      )
    }
  } else if (oldDirectParentTask?.id === newDirectParentTask?.parentTaskId) {
    if (newDirectParentTaskNode?.parent && newDirectParentTask) {
      // remove target task from new direct parent task children
      const updatedNewDirectParentTask = {
        ...newDirectParentTaskNode.parent,
        children: newDirectParentTaskNode.parent.children?.filter(
          (child) => child.key !== targetTask.id
        ),
      }

      // update new direct parent task parent tasks
      updatedParentTasks = updateParentTasks(
        updatedNewDirectParentTask,
        newDirectParentTask,
        'update'
      )
    }
  } else {
    if (oldDirectParentTaskNode?.parent && oldDirectParentTask) {
      // update old direct parent task parent tasks
      const updatedParentTasks = updateParentTasks(
        oldDirectParentTaskNode.parent,
        oldDirectParentTask,
        'update'
      )

      changedTasks.push(...updatedParentTasks)
    }

    if (newDirectParentTaskNode?.parent && newDirectParentTask) {
      // update new direct parent task parent tasks
      const updatedParentTasks = updateParentTasks(
        newDirectParentTaskNode.parent,
        newDirectParentTask,
        'update'
      )

      changedTasks.push(...updatedParentTasks)
    }
  }

  // push updated parent tasks to changed tasks, update if updated parent task is already in changed tasks
  updatedParentTasks.forEach((updatedTask) => {
    const index = changedTasks.findIndex((task) => task.id === updatedTask.id)

    if (index >= 0) {
      changedTasks[index] = updatedTask
    } else {
      changedTasks.push(updatedTask)
    }
  })

  return changedTasks.map((task) => {
    return {
      ...task,
      version: task.version + 1, // increment version
    }
  })
}

export const ProjectDetailTaskListWBSEditor = memo(() => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { projectId } = useParams()

  const toast = useToast()
  const [showApiErrors] = useShowApiErrors()

  const [isTaskEntryDialogOpen, openTaskEntryDialog, closeTaskEntryDialog] =
    useModal()
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const [parentTaskId, setParentTaskId] = useState<string>()

  const [tasks, setTasks] = useState<GanttTask[]>([])

  const [confirmMessage, setConfirmMessage] = useState('')

  const tasksRef = useRef<GanttTask[]>(tasks)
  tasksRef.current = tasks

  const [renumberWbsNumberMutation] = useRenumberWbsNumberMutation()
  const [updateWbsTaskMutation] = useUpdateWbsTaskMutation()

  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)

  const getTasksParams: GetGanttTasksRequest | undefined = projectId
    ? {
        projectId,
      }
    : undefined
  const { data: getTasksResult, refetch: getTasksRefetch } =
    useGetGanttTasksQuery(getTasksParams ?? skipToken)

  const treeListRef = useRef<TreeList<GanttTask, any>>(null)

  // refetch tasks data
  const refetchTasks = useCallback(() => {
    getTasksRefetch()
  }, [getTasksRefetch])

  // タスクの登録・更新・削除のイベントが発生した際に、タスク一覧を再読み込みするための処理
  useValueChangeEffect(
    () => {
      if (!taskEventMessage) return

      let updatedParentTasks: GanttTask[] = []

      if (taskEventMessage?.eventType === NotifyEventType.Inserted) {
        const targetTask = cloneDeep(taskEventMessage.object) as GanttTask

        if (targetTask.parentTaskId) {
          const node = treeListRef.current?.instance.getNodeByKey(
            targetTask.parentTaskId
          )

          if (node) {
            // If the start date is updated, update the start date of the parent tasks
            updatedParentTasks = updateParentTasks(node, targetTask, 'insert')
          }
        }

        const updatedTasks = tasks.map((task) => {
          // update parent tasks
          const parentTask = updatedParentTasks.find(
            (parentTask) => parentTask.id === task.id
          )

          if (parentTask) {
            return {
              ...parentTask,
              version: parentTask.version + 1, // increment version
            }
          }

          return task
        })

        const focusedRowKey =
          treeListRef.current?.instance.option('focusedRowKey')

        const focusedIndex = updatedTasks.findIndex(
          (task) => task.id === focusedRowKey
        )

        // get the index of the focused row
        // when target task is not the child of the focused row, add new task after the focused row
        const targetTaskIndex =
          focusedIndex !== -1 && targetTask.parentTaskId !== focusedRowKey
            ? focusedIndex + 1
            : updatedTasks.length + 1

        // add new task after the focused row
        updatedTasks.splice(targetTaskIndex, 0, {
          ...targetTask,
          version: targetTask.version + 1,
        })

        // update tasks state
        setTasks(updatedTasks)
      } else {
        // If the event type is not Inserted refetch the gantt
        refetchTasks()
      }
    },
    [refetchTasks, taskEventMessage, tasks],
    taskEventMessage,
    true
  )

  // get tasks data source for WBS editor
  useValueChangeEffect(
    () => {
      if (getTasksResult?.tasks) {
        const cloneTasks = cloneDeep(getTasksResult.tasks)
        setTasks(cloneTasks)
      } else {
        setTasks([])
      }
    },
    [getTasksResult?.tasks],
    getTasksResult?.tasks
  )

  const { memberDataSource, deleteTask } = useProjectDetailTaskListWBSEdit()

  useEffect(() => {
    // close WBS editor
    return () => {
      dispatch(setIsShowEditWBS(false))
    }
  }, [dispatch])

  const [editColumnName, setEditColumnName] = useState<string>()

  // Process handling when a row is updated
  const handleRowUpdated = useCallback(
    async (event: RowUpdatedInfo<GanttTask>) => {
      if (!editColumnName) return

      let updatedValue
      let updatedParentTasks: GanttTask[] = []

      switch (editColumnName) {
        case WbsUpdatableColumn.Subject:
          updatedValue = event.data.subject
          break
        case WbsUpdatableColumn.WbsNumber:
          updatedValue = event.data.wbsNumber ?? undefined
          break
        case WbsUpdatableColumn.StartDate:
          updatedValue = event.data.startDate
            ? dayjs(event.data.startDate).format(JsonDateFormat.YYYYMMDD)
            : undefined
          const nodeStartDate = treeListRef.current?.instance.getNodeByKey(
            event.key
          )
          if (nodeStartDate?.parent) {
            // If the start date is updated, update the start date of the parent tasks
            updatedParentTasks = updateParentTasks(
              nodeStartDate.parent,
              event.data,
              'update'
            )
          }

          break
        case WbsUpdatableColumn.DueDate:
          updatedValue = event.data.dueDate
            ? dayjs(event.data.dueDate).format(JsonDateFormat.YYYYMMDD)
            : undefined

          const nodeDueDate = treeListRef.current?.instance.getNodeByKey(
            event.key
          )
          if (nodeDueDate?.parent) {
            // If the due date is updated, update the due date of the parent tasks
            updatedParentTasks = updateParentTasks(
              nodeDueDate.parent,
              event.data,
              'update'
            )
          }
          break
        case WbsUpdatableColumn.AssignToUserId:
          updatedValue = event.data.assignToUser?.id ?? undefined
          break
        case WbsUpdatableColumn.EstimatedWorkTimes:
          updatedValue = event.data.estimatedWorkTimes
            ? String(
                convertScheduledTimeToMinutes(
                  String(event.data.estimatedWorkTimes)
                )
              )
            : undefined

          const nodeEstimatedWorkTimes =
            treeListRef.current?.instance.getNodeByKey(event.key)
          if (nodeEstimatedWorkTimes?.parent) {
            // If the due date is updated, update the due date of the parent tasks
            updatedParentTasks = updateParentTasks(
              nodeEstimatedWorkTimes.parent,
              event.data,
              'update'
            )
          }
          break
        default: // 上記に合致しない場合は何もしない
          return
      }

      // update tasks state
      const updatedTasks = tasks.map((task) => {
        if (task.id === event.data.id) {
          return {
            ...task,
            version: task.version + 1, // increment version
          }
        }

        // update parent tasks
        const parentTask = updatedParentTasks.find(
          (parentTask) => parentTask.id === task.id
        )

        if (parentTask) {
          return {
            ...parentTask,
            version: parentTask.version + 1, // increment version
          }
        }

        return task
      })

      // update tasks state
      setTasks(updatedTasks)

      try {
        await updateWbsTaskMutation({
          task: {
            id: event.data.id,
            columnName: editColumnName,
            value: updatedValue,
            version: event.data.version,
          },
        }).unwrap()
        // タスク更新の都度レンダリングは行わないのでEventMessageは生成しない
      } catch (error) {
        // get tasks data again when error
        refetchTasks()

        showApiErrors(error)
      }
    },
    [editColumnName, tasks, updateWbsTaskMutation, refetchTasks, showApiErrors]
  )

  // drag and drop reorder rows
  // any type is used because the event type is not defined in the devextreme-react library
  const onReorder = useCallback(
    async (event: any) => {
      if (!treeListRef.current) return

      const visibleRows = event.component.getVisibleRows()

      // Get the data of the item being moved
      let sourceData = event.itemData

      // Create a copy of the current tasks array
      const updatedTasks = [...tasksRef.current]

      // Find the index of the item being moved in the tasks array
      const sourceIndex = updatedTasks.indexOf(sourceData)

      // Item is dropped inside another item
      if (event.dropInsideItem) {
        // Set the parentTaskId of the source data to the key of the item at the drop target index
        sourceData = {
          ...sourceData,
          parentTaskId: visibleRows[event.toIndex].key,
        }

        // Remove the source item from its original position in the updatedTasks array
        updatedTasks.splice(sourceIndex, 1)

        // Insert the source item into the new position (toIndex) in the updatedTasks array
        updatedTasks.splice(event.toIndex, 0, sourceData)
      } else {
        // Item is dropped between items
        // Calculate the toIndex, adjusting for the drag direction
        const toIndex =
          event.fromIndex > event.toIndex ? event.toIndex - 1 : event.toIndex

        // Get the target data based on toIndex. If toIndex is negative, set targetData to null
        let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null

        // Check if target data exists and the row is expanded
        if (targetData && event.component.isRowExpanded(targetData.id)) {
          // Set the parentTaskId of the source data to the ID of the expanded target data
          sourceData = {
            ...sourceData,
            parentTaskId: targetData.id,
          }

          // Clear targetData as we already used its ID
          targetData = null
        } else {
          // Determine the parentTaskId for the source data based on targetData's parentTaskId or -1 if no target data
          const parentTaskId = targetData ? targetData.parentTaskId : -1

          // Update the parentTaskId of the source data if it's different from the calculated parentTaskId
          if (sourceData.parentTaskId !== parentTaskId) {
            sourceData = {
              ...sourceData,
              parentTaskId: parentTaskId === -1 ? null : parentTaskId,
            }
          }
        }

        // Remove the source item from its original position in the updatedTasks array
        updatedTasks.splice(sourceIndex, 1)

        // Determine the target index to insert the source item after the targetData
        const targetIndex = updatedTasks.indexOf(targetData) + 1

        // Insert the source item into the new position in the updatedTasks array
        updatedTasks.splice(targetIndex, 0, sourceData)
      }

      // get changed tasks when drag and drop task
      const changedTasks = updateNodeWhenDragDropTask(
        sourceData,
        event.itemData.parentTaskId,
        treeListRef.current
      )

      // merge changed tasks and updated tasks
      const mergedTasks = updatedTasks.map((task) => {
        const changedTask = changedTasks.find(
          (changedTask) => changedTask.id === task.id
        )

        if (changedTask) {
          return changedTask
        }

        return task
      })

      // Update the tasks state with the reordered tasks array
      setTasks(mergedTasks)

      try {
        // call update task API
        await updateWbsTaskMutation({
          task: {
            id: sourceData.id,
            columnName: WbsUpdatableColumn.ParentTaskId,
            value: sourceData.parentTaskId,
            version: sourceData.version,
          },
        }).unwrap()

        // Update treeList renumber WBS number state
        dispatch(setIsWbsNumberRenumbering(true))
      } catch (error) {
        // get tasks data again when error
        refetchTasks()

        showApiErrors(error)
      }
    },
    [dispatch, refetchTasks, showApiErrors, updateWbsTaskMutation]
  )

  // validation start date
  const validationStartDate = useCallback(
    (options: {
      column: Record<string, any>
      data: Record<string, any>
      formItem: Record<string, any>
      rule: Record<string, any>
      validator: Record<string, any>
      value: string | number
    }) => {
      // If the start date is not set, it is always valid
      if (!options.value) return true

      let dateValue = dayjs(options.value).toDate()

      if (
        options.data.dueDate &&
        dateValue > dayjs(options.data.dueDate).toDate()
      ) {
        return false
      }
      return true
    },
    []
  )

  // validation due date
  const validationDueDate = useCallback(
    (options: {
      column: Record<string, any>
      data: Record<string, any>
      formItem: Record<string, any>
      rule: Record<string, any>
      validator: Record<string, any>
      value: string | number
    }) => {
      // If the due date is not set, it is always valid
      if (!options.value) return true

      let dateValue = dayjs(options.value).toDate()

      if (
        options.data.startDate &&
        dateValue < dayjs(options.data.startDate).toDate()
      ) {
        return false
      }
      return true
    },
    []
  )

  // validation wbs number
  const validationWbsNumber = useCallback(
    (options: {
      column: Record<string, any>
      data: Record<string, any>
      formItem: Record<string, any>
      rule: Record<string, any>
      validator: Record<string, any>
      value: string | number
    }) => {
      if (!options.value) return true

      // check if the WBS number is in the correct format x, x.x, x.x.x ... (x is a number)
      const wbsRegex = /^\d+(\.\d+)*$/
      return wbsRegex.test(options.value.toString())
    },
    []
  )

  // Renumber WBS number
  const renumberWbsNumber = useCallback(async () => {
    if (!projectId) {
      return
    }

    try {
      const sortedTasks: RenumberWbsNumberRequest_Task[] = []
      treeListRef.current?.instance.forEachNode(
        (node: Node<GanttTask, string>) => {
          sortedTasks.push({
            id: node.key,
            parentTaskId: node.data?.parentTaskId ?? undefined,
            version: node.data?.version ?? 0,
          })
        }
      )

      // Call API renumber WBS number
      await renumberWbsNumberMutation({
        projectId,
        tasks: sortedTasks,
      }).unwrap()

      // Set the WBS renumbering state to false
      dispatch(setIsWbsNumberRenumbering(false))

      // Refresh task list to get the latest version information
      getTasksRefetch()

      toast.success(t('message.wbs.wbsNumberHasBeenAssigned'))
    } catch (error) {
      showApiErrors(error)
      return
    }
  }, [
    dispatch,
    getTasksRefetch,
    projectId,
    renumberWbsNumberMutation,
    showApiErrors,
    t,
    toast,
  ])

  // Event handler when editing start
  const handleEditingStart = useCallback((event: EditingStartEvent) => {
    const node = event.component.getNodeByKey(event.key)

    // Prevent editing of the start date and due date columns for parent tasks
    if (
      node.hasChildren &&
      (event.column.name === WbsUpdatableColumn.StartDate ||
        event.column.name === WbsUpdatableColumn.DueDate ||
        event.column.name === WbsUpdatableColumn.EstimatedWorkTimes)
    ) {
      event.cancel = true
    }
  }, [])

  // Expand all button click event
  const handleExpandAllButtonClick = useCallback(() => {
    if (treeListRef.current) {
      treeListRef.current.instance.option('expandedRowKeys', [])
      treeListRef.current.instance.option('autoExpandAll', false)
    }
  }, [])

  const expandAllButtonOptions = {
    icon: 'dx-icon dx-gantt-i dx-gantt-i-expand',
    stylingMode: 'text',
    hint: t('action.expandAll'),
    onClick: handleExpandAllButtonClick,
  }

  // Collapse all button click event
  const handleCollapseAllButtonClick = useCallback(() => {
    if (treeListRef.current) {
      treeListRef.current.instance.option('expandedRowKeys', [])
      treeListRef.current.instance.option('autoExpandAll', true)
    }
  }, [])

  const collapseAllButtonOptions = {
    icon: 'dx-icon dx-gantt-i dx-gantt-i-collapse',
    stylingMode: 'text',
    hint: t('action.closeAll'),
    onClick: handleCollapseAllButtonClick,
  }

  // Event handler when the add subtask button is clicked
  const handleAddSubtaskButtonClick = useCallback(() => {
    const focusedRowKey = treeListRef.current?.instance.option('focusedRowKey')

    // Set the parent task ID to the focused row key
    setParentTaskId(focusedRowKey)

    // Open the task entry dialog
    openTaskEntryDialog()
  }, [openTaskEntryDialog])

  // custom render add subtask button
  const renderAddSubtaskButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          icon={<AccountTreeOutline width={20} height={20} />}
          text={t('action.addSubtask')}
          stylingMode="outlined"
          disabled={disabled}
          onClick={handleAddSubtaskButtonClick}
        />
      )
    },
    [handleAddSubtaskButtonClick, t]
  )

  // Event handler when the add task button is clicked
  const handleAddTaskButtonClick = useCallback(() => {
    const focusedRowKey = treeListRef.current?.instance.option('focusedRowKey')
    const selectedTask =
      treeListRef.current?.instance.getNodeByKey(focusedRowKey)?.data

    // 選択されたタスクがある場合は、そのタスクと同じ階層に新規タスクを作成する
    setParentTaskId(selectedTask?.parentTaskId ?? undefined)

    // Open the task entry dialog
    openTaskEntryDialog()
  }, [openTaskEntryDialog])

  // custom render add task button
  const renderAddTaskButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          icon={<EventAvailable width={20} height={20} />}
          text={t('action.addTask')}
          stylingMode="outlined"
          disabled={disabled}
          onClick={handleAddTaskButtonClick}
        />
      )
    },
    [handleAddTaskButtonClick, t]
  )

  // Event handler when the delete task button is clicked
  const handleDeleteButtonClick = useCallback(() => {
    const focusedRowKey = treeListRef.current?.instance.option('focusedRowKey')

    const selectedNode =
      treeListRef.current?.instance.getNodeByKey(focusedRowKey)
    if (!selectedNode) return

    // If the selected node has children, show confirm delete children dialog
    if (selectedNode.hasChildren) {
      setConfirmMessage(t('message.task.confirmDeleteLinkedSubtasks'))
    } else {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
    }

    openConfirmDialog()
  }, [openConfirmDialog, t])

  // custom render delete task button
  const renderDeleteTaskButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          icon={<BaselineDelete width={20} height={20} />}
          text={t('action.deleteTask')}
          stylingMode="outlined"
          disabled={disabled}
          onClick={handleDeleteButtonClick}
        />
      )
    },
    [handleDeleteButtonClick, t]
  )

  // custom render renumber WBS number button
  const renderRenumberWbsNumberButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          text={t('action.renumberWBSNumber')}
          stylingMode="outlined"
          type="normal"
          disabled={disabled}
          onClick={renumberWbsNumber}
        />
      )
    },
    [renumberWbsNumber, t]
  )

  // indent button click event
  const handleIndentButtonClick = useCallback(async () => {
    if (!treeListRef.current) return

    const focusedRowKey = treeListRef.current.instance.option('focusedRowKey')

    const selectedNode =
      treeListRef.current.instance.getNodeByKey(focusedRowKey)

    // do nothing if the selected node is the root node or not found
    if (!selectedNode || !selectedNode.parent) {
      return
    }

    const selectedNodeIndexFromParent = selectedNode.parent.children?.findIndex(
      (node) => node.key === selectedNode.key
    )

    // do nothing if the selected node is not found or the first child
    if (!selectedNodeIndexFromParent || selectedNodeIndexFromParent === 0) {
      return
    }

    const previousNode =
      selectedNode.parent.children?.[selectedNodeIndexFromParent - 1]

    // do nothing if the previous node is not found
    if (!previousNode) return

    // update selected node parent task id
    const targetTask = cloneDeep(selectedNode.data as GanttTask)
    targetTask.parentTaskId = previousNode.data?.id as string

    // get changed tasks when drag and drop task
    const changedTasks = updateNodeWhenDragDropTask(
      targetTask,
      selectedNode.data?.parentTaskId ?? null,
      treeListRef.current
    )

    // merge changed tasks and updated tasks
    const mergedTasks = tasks.map((task) => {
      const changedTask = changedTasks.find(
        (changedTask) => changedTask.id === task.id
      )

      if (changedTask) {
        return changedTask
      }

      return task
    })

    // Update the tasks state with the reordered tasks array
    setTasks(mergedTasks)

    try {
      // call update task API
      await updateWbsTaskMutation({
        task: {
          id: selectedNode.key,
          columnName: WbsUpdatableColumn.ParentTaskId,
          value: previousNode.data?.id,
          version: selectedNode.data?.version ?? 0,
        },
      }).unwrap()
    } catch (error) {
      // get tasks data again when error
      refetchTasks()

      showApiErrors(error)
    }
  }, [refetchTasks, showApiErrors, tasks, updateWbsTaskMutation])

  // custom render indent button
  const renderIndentButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          icon={<FormatIndentIncrease width={20} height={20} />}
          stylingMode="text"
          disabled={disabled}
          className="!px-2"
          onClick={handleIndentButtonClick}
        />
      )
    },
    [handleIndentButtonClick]
  )

  // outdent button click event
  const handleOutdentButtonClick = useCallback(async () => {
    if (!treeListRef.current) return

    const focusedRowKey = treeListRef.current.instance.option('focusedRowKey')

    const selectedNode =
      treeListRef.current.instance.getNodeByKey(focusedRowKey)

    // do nothing if the selected node is the root node or not found
    if (
      !selectedNode ||
      !selectedNode.parent ||
      selectedNode.parent.level === -1
    ) {
      return
    }

    const parentOfParentNode = selectedNode.parent.parent
    // do nothing if the parent of the parent node is not found
    if (!parentOfParentNode) return

    // update selected node parent task id
    const targetTask = cloneDeep(selectedNode.data as GanttTask)
    targetTask.parentTaskId = parentOfParentNode.data?.id as string

    // get changed tasks when drag and drop task
    const changedTasks = updateNodeWhenDragDropTask(
      targetTask,
      selectedNode.data?.parentTaskId ?? null,
      treeListRef.current
    )

    // merge changed tasks and updated tasks
    const mergedTasks = tasks.map((task) => {
      const changedTask = changedTasks.find(
        (changedTask) => changedTask.id === task.id
      )

      if (changedTask) {
        return changedTask
      }

      return task
    })

    // Update the tasks state with the reordered tasks array
    setTasks(mergedTasks)

    try {
      // call update task API
      await updateWbsTaskMutation({
        task: {
          id: selectedNode.key,
          columnName: WbsUpdatableColumn.ParentTaskId,
          value: parentOfParentNode.data?.id,
          version: selectedNode.data?.version ?? 0,
        },
      }).unwrap()
    } catch (error) {
      // get tasks data again when error
      refetchTasks()

      showApiErrors(error)
    }
  }, [refetchTasks, showApiErrors, tasks, updateWbsTaskMutation])

  // custom render outdent button
  const renderOutdentButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          icon={<FormatIndentDecrease width={20} height={20} />}
          stylingMode="text"
          disabled={disabled}
          className="!px-2"
          onClick={handleOutdentButtonClick}
        />
      )
    },
    [handleOutdentButtonClick]
  )

  // タスク登録・編集ダイアログのsubmit処理
  const handleTaskEntryDialogSubmit = useCallback(
    async (taskId: string) => {
      closeTaskEntryDialog()

      if (parentTaskId) {
        // Expand the parent task
        treeListRef.current?.instance.expandRow(parentTaskId)
      }
    },
    [closeTaskEntryDialog, parentTaskId]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    closeConfirmDialog()

    try {
      const focusedRowKey =
        treeListRef.current?.instance.option('focusedRowKey')

      if (!focusedRowKey) return

      const focusedNode =
        treeListRef.current?.instance.getNodeByKey(focusedRowKey)

      if (!focusedNode) return

      const deleteTaskKeys = [focusedRowKey]

      // If the selected node has children, delete all children
      if (focusedNode.children && focusedNode.children.length > 0) {
        const childrenTaskKeys = getAllChildrenTaskKeys(focusedNode)

        deleteTaskKeys.push(...childrenTaskKeys)
      }

      let updatedParentTasks: GanttTask[] = []
      // If the selected node has a parent, update the parent tasks
      if (focusedNode.parent) {
        updatedParentTasks = updateParentTasks(
          focusedNode.parent,
          focusedNode.data as GanttTask,
          'delete'
        )
      }

      // update tasks state and remove tasks data
      const updatedTasks = tasks
        .filter((task) => !deleteTaskKeys.includes(task.id))
        .map((task) => {
          // update parent tasks
          const parentTask = updatedParentTasks.find(
            (parentTask) => parentTask.id === task.id
          )

          if (parentTask) {
            return {
              ...parentTask,
              version: parentTask.version + 1, // increment version
            }
          }

          return task
        })

      setTasks(updatedTasks)

      // call delete task API
      await deleteTask(focusedRowKey, focusedNode.data?.version ?? 0)
      toast.success(t('message.task.taskDeleted'))
    } catch (err) {
      showApiErrors(err)

      // get tasks data again when error
      refetchTasks()
    }
  }, [
    closeConfirmDialog,
    deleteTask,
    refetchTasks,
    showApiErrors,
    t,
    tasks,
    toast,
  ])

  return (
    <div className="h-full">
      <TreeList
        id="treeListtasks"
        dataSource={tasks}
        columnAutoWidth={true}
        wordWrapEnabled={true}
        keyExpr="id"
        parentIdExpr="parentTaskId"
        showRowLines={true}
        onRowUpdated={handleRowUpdated}
        repaintChangesOnly={true}
        ref={treeListRef}
        onEditingStart={handleEditingStart}
        focusedRowEnabled={true}
        autoExpandAll={true}
        height="100%"
      >
        <Scrolling mode="standard" />
        <RowDragging
          onDragChange={onDragChange}
          allowDropInsideItem={true}
          allowReordering={true}
          showDragIcons={true}
          onReorder={onReorder}
        />
        <Editing
          allowUpdating={true}
          mode="cell"
          onEditColumnNameChange={setEditColumnName}
        />

        {/* タスク件名 */}
        <Column
          minWidth={250}
          dataField="subject"
          name={WbsUpdatableColumn.Subject}
          caption={t('label.taskSubject')}
        >
          <RequiredRule />
        </Column>

        {/* WBS番号 */}
        <Column
          width={100}
          dataField="wbsNumber"
          name={WbsUpdatableColumn.WbsNumber}
          caption={t('label.wbsNumber')}
          sortingMethod={compareWBSNumber}
        >
          <CustomRule
            type="custom"
            message={t('message.task.invalidWbsNumber')}
            validationCallback={validationWbsNumber}
          />
        </Column>

        {/* 担当者 */}
        <Column
          minWidth={170}
          dataField="assignToUser"
          name={WbsUpdatableColumn.AssignToUserId}
          caption={t('label.assignedUser')}
          cellRender={renderAssignToUser}
        >
          <Lookup dataSource={memberDataSource} displayExpr="displayName" />
        </Column>

        {/* 予定開始日 */}
        <Column
          minWidth={120}
          dataField="startDate"
          name={WbsUpdatableColumn.StartDate}
          caption={t('label.scheduledStartDate')}
          dataType="date"
        >
          <CustomRule
            type="custom"
            message={t('message.task.invalidTaskStartDate')}
            validationCallback={validationStartDate}
          />
        </Column>

        {/* 予定終了日 */}
        <Column
          minWidth={120}
          dataField="dueDate"
          name={WbsUpdatableColumn.DueDate}
          caption={t('label.scheduledEndDate')}
          dataType="date"
        >
          <CustomRule
            type="custom"
            reevaluate={true}
            message={t('message.task.invalidTaskDueDate')}
            validationCallback={validationDueDate}
          />
        </Column>

        {/* 予定作業時間 */}
        <Column
          minWidth={120}
          dataField="estimatedWorkTimes"
          name={WbsUpdatableColumn.EstimatedWorkTimes}
          caption={t('label.scheduledWorkingTime')}
          alignment="right"
          calculateCellValue={calculateEstimatedWorkTimes}
        />

        {/* 実績開始日 */}
        <Column
          minWidth={120}
          dataField="actualStartDate"
          caption={t('label.actualStartDate')}
          dataType="date"
          allowEditing={false}
          cssClass="disable-cell-editing"
        />

        {/* 実績終了日 */}
        <Column
          minWidth={120}
          dataField="actualEndDate"
          caption={t('label.actualEndDate')}
          dataType="date"
          allowEditing={false}
          cssClass="disable-cell-editing"
        />

        {/* 実績作業時間 */}
        <Column
          minWidth={120}
          dataField="actualWorkTimes"
          caption={t('label.actualWorkingTime')}
          alignment="right"
          allowEditing={false}
          cssClass="disable-cell-editing"
          cellRender={renderActualWorkTimes}
        />

        {/* 進捗率 */}
        <Column
          minWidth={120}
          dataField="actualProgress"
          caption={t('label.progressRate')}
          alignment="right"
          allowEditing={false}
          cellRender={renderProgressRate}
          cssClass="disable-cell-editing"
        />

        {/* ステータス */}
        <Column
          minWidth={120}
          dataField="taskState.id"
          caption={t('label.state')}
          allowEditing={false}
          cellRender={renderTaskState}
          cssClass="disable-cell-editing"
        />

        <Toolbar>
          {/* すべて閉じる */}
          <Item
            widget="dxButton"
            options={collapseAllButtonOptions}
            location="before"
          />

          {/* すべて展開 */}
          <Item
            widget="dxButton"
            options={expandAllButtonOptions}
            location="before"
          />

          {/* アウトデント */}
          <Item render={renderOutdentButton} location="before" />

          {/* インデント */}
          <Item render={renderIndentButton} location="before" />

          <Item render={renderAddTaskButton} location="before" />
          <Item render={renderAddSubtaskButton} location="before" />
          <Item render={renderDeleteTaskButton} location="before" />

          {/* WBS番号を再付番する */}
          <Item render={renderRenumberWbsNumberButton} location="before" />
        </Toolbar>
      </TreeList>

      <TaskEntryDialog
        isEditMode={false}
        title={t('label.addTaskTitle')}
        onSubmit={handleTaskEntryDialogSubmit}
        isOpen={isTaskEntryDialogOpen}
        onClose={closeTaskEntryDialog}
        projectId={projectId}
        parentTaskId={parentTaskId}
      />

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </div>
  )
})
