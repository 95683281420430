import { useTaskKindDataSource } from 'hooks/dataSource/useTaskKindDataSource'

export const useMyTaskListSearchPanel = () => {
  // 実際にタスク種別のリストに表示されるデータ
  const taskKindDataSource = useTaskKindDataSource(
    // Get task types of all projects
    undefined,
    undefined,
    true, // hasBaseFilter
    true, // isGrouped
    false // multiSelect
  )

  return {
    taskKindDataSource,
  }
}
