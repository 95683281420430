import {
  useLazyGetLookupRelatedItemQuery,
  useLazyGetLookupRelatedItemsQuery,
} from '@crew/apis/lookup/lookupApis'
import { useDataSource } from './useDataSource'
import { MAX_INITIAL_RECORDS } from 'configs/constants'
import { EntityType } from '@crew/enums/domain'

export const useEntityRecordDataSource = (
  entityType: EntityType | null | undefined
) => {
  const [lazyGetLookupRelatedItemsQuery] = useLazyGetLookupRelatedItemsQuery()
  const [lazyGetLookupRelatedItemQuery] = useLazyGetLookupRelatedItemQuery()

  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!entityType) {
          return []
        }

        if (!loadOptions.searchValue) {
          const response = await lazyGetLookupRelatedItemsQuery({
            entityType,
            name: undefined,
            limit: MAX_INITIAL_RECORDS,
          }).unwrap()
          return response.relatedItems
        }

        const response = await lazyGetLookupRelatedItemsQuery({
          entityType,
          name: loadOptions.searchValue,
        }).unwrap()
        return response.relatedItems
      },
      byKey: async (entityRecordId: string) => {
        if (!entityType) {
          return null
        }

        const response = await lazyGetLookupRelatedItemQuery({
          entityType,
          id: entityRecordId,
        }).unwrap()

        return response.relatedItem
      },
    }),
    [entityType, lazyGetLookupRelatedItemQuery, lazyGetLookupRelatedItemsQuery]
  )
}
