import classNames from 'classnames'
import { FC, memo } from 'react'
import { isWeekend } from 'utils/date'

type DataCellProps = React.PropsWithChildren<{
  data: {
    startDate: Date
  }
}>

export const DataCell: FC<DataCellProps> = memo((props) => {
  return (
    <div
      className={classNames(
        'w-full h-full',
        isWeekend(props.data.startDate) &&
          'bg-crew-gray-50 dark:bg-crew-gray-800/50'
      )}
    >
      {props.children}
    </div>
  )
})
