import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CommentButtonGroup,
  FileListDisplayMode,
  SortCommentButtonGroup,
} from 'enums/app'

type TaskDetailStateType = {
  selectedCommentButtonGroupItem: string
  taskId: string | undefined
  projectId: string | undefined
  selectedSortCommentButtonGroupItem: string
  isExpandedDescription: boolean
  selectedFileListDisplayMode: number
}

const initialTaskDetailState: TaskDetailStateType = {
  selectedCommentButtonGroupItem: CommentButtonGroup.CommentAndHistory.value,
  taskId: undefined,
  projectId: undefined,
  selectedSortCommentButtonGroupItem: SortCommentButtonGroup.Ascending.value,
  isExpandedDescription: false,
  selectedFileListDisplayMode: FileListDisplayMode.List.id,
}

export const taskDetailSlice = createSlice({
  name: 'taskDetail',
  initialState: initialTaskDetailState,
  reducers: {
    commentButtonGroupIndexUpdated: (state, action: PayloadAction<string>) => {
      state.selectedCommentButtonGroupItem = action.payload
    },

    setTaskId: (state, action: PayloadAction<string | undefined>) => {
      state.taskId = action.payload
    },
    setProjectId: (state, action: PayloadAction<string | undefined>) => {
      state.projectId = action.payload
    },

    sortCommentButtonGroupIndexUpdated: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedSortCommentButtonGroupItem = action.payload
    },
    updateIsExpandedDescription: (state, action: PayloadAction<boolean>) => {
      state.isExpandedDescription = action.payload
    },
    setSelectedFileListDisplayMode: (state, action: PayloadAction<number>) => {
      state.selectedFileListDisplayMode = action.payload
    },
  },
})

export const {
  commentButtonGroupIndexUpdated,
  setTaskId,
  setProjectId,
  sortCommentButtonGroupIndexUpdated,
  updateIsExpandedDescription,
  setSelectedFileListDisplayMode,
} = taskDetailSlice.actions
