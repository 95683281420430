import {
  ComponentProps,
  forwardRef,
  memo,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react'
import { DropDownBox, TreeView } from 'devextreme-react'
import {
  OptionChangedEvent,
  ValueChangedEvent,
} from 'devextreme/ui/drop_down_box'
import { ContentReadyEvent } from 'devextreme/ui/tree_view'
import { useValueChangeEffect } from '@crew/hooks'
import { CrewTreeView } from './CrewTreeView'

type Props = PropsWithChildren<ComponentProps<typeof DropDownBox>> & {
  parentIdExpr?: string
  onValueChange?: (value: string | null) => void
}

/**
 * DropDownBoxのラッパー（react-hook-form非対応版）
 */
export const CrewDropDownTreeViewBox = memo(
  forwardRef<DropDownBox, Props>(({ children, dataSource, ...rest }, ref) => {
    const treeViewRef = useRef<TreeView | null>(null)
    const [treeBoxValue, setTreeBoxValue] = useState(rest.value)
    const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false)

    useValueChangeEffect(
      () => {
        setTreeBoxValue(rest.value)
      },
      [rest.value],
      rest.value
    )

    // Handle the click event in the tree view
    const onTreeItemClick = useCallback(() => {
      setIsTreeBoxOpened(false)
    }, [])

    // Handle the content ready event in the tree view
    const treeViewOnContentReady = useCallback(
      (e: ContentReadyEvent) => {
        e.component.selectItem(treeBoxValue)

        rest.onValueChange?.(treeBoxValue)
      },
      [rest, treeBoxValue]
    )

    // Handle the selection change in the tree view
    const treeViewItemSelectionChanged = useCallback(
      // any type because the type definition of the event is any
      (e: { component: { getSelectedNodeKeys: () => any } }) => {
        setTreeBoxValue(e.component.getSelectedNodeKeys()[0])

        // Call the onValueChange event, getSelectedNodeKeys returns an array of selected keys so we get the first one
        rest.onValueChange?.(e.component.getSelectedNodeKeys()[0])
      },
      [rest]
    )

    // Sync the selected item in the tree view with the value of the DropDownBox
    const syncTreeViewSelection = useCallback(
      (e: ValueChangedEvent) => {
        setTreeBoxValue(e.value)

        rest.onValueChange?.(e.value)

        if (!treeViewRef.current) return

        if (!e.value) {
          treeViewRef.current.instance.unselectAll()
        } else {
          treeViewRef.current.instance.selectItem(e.value)
        }
      },
      [rest]
    )

    // Render the tree view
    const treeViewRender = useCallback(
      () => (
        <CrewTreeView
          dataSource={dataSource}
          ref={treeViewRef}
          dataStructure="plain"
          keyExpr={rest.valueExpr}
          selectionMode="single"
          displayExpr={rest.displayExpr}
          parentIdExpr={rest.parentIdExpr}
          selectByClick={true}
          onContentReady={treeViewOnContentReady}
          onItemClick={onTreeItemClick}
          onItemSelectionChanged={treeViewItemSelectionChanged}
        />
      ),
      [
        dataSource,
        rest.valueExpr,
        rest.displayExpr,
        rest.parentIdExpr,
        treeViewOnContentReady,
        onTreeItemClick,
        treeViewItemSelectionChanged,
      ]
    )

    // Handle the opened state of the DropDownBox
    const onTreeBoxOpened = useCallback((e: OptionChangedEvent) => {
      if (e.name === 'opened') {
        setIsTreeBoxOpened(e.value)
      }
    }, [])

    return (
      <DropDownBox
        {...rest}
        ref={ref}
        value={treeBoxValue}
        opened={isTreeBoxOpened}
        valueExpr={rest.valueExpr}
        displayExpr={rest.displayExpr}
        dataSource={dataSource}
        onValueChanged={syncTreeViewSelection}
        onOptionChanged={onTreeBoxOpened}
        contentRender={treeViewRender}
      >
        {children}
      </DropDownBox>
    )
  })
)
