import { CrewButton } from 'components/elements/crewButton/crewButton'
import { EventEntryDialog } from 'features/project/components/eventEntryDialog/eventEntryDialog'
import { FC, memo, useCallback, useMemo } from 'react'
import { EntityType } from '@crew/enums/dist/domain'
import { useProjectPermissions } from '@crew/hooks'
import { ProjectDetailEventSearch } from '../projectDetailEventSearch/projectDetailEventSearch'
import { useTranslation } from '@crew/modules/dist/i18n'
import { getDefaultTabValue } from '@crew/utils/dist/enum'
import { useModal } from 'components/layouts/modal/useModal'
import { EventDetailTabs, ProjectDetailEventListDisplayMode } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { setSelectedEventListDisplayMode } from 'features/project/components/projectDetailPage/states/projectDetailSlice'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'

type ButtonGroupInfo = {
  index: number
  text: string | undefined
  icon: JSX.Element | undefined
}

export type ProjectDetailEventListToolbarProps = {}

export const ProjectDetailEventListToolbar: FC<ProjectDetailEventListToolbarProps> =
  memo(() => {
    const { t } = useTranslation()
    const { navigate } = useCrewNavigate()
    const { projectId } = useParams()
    const dispatch = useAppDispatch()

    const selectedEventListDisplayMode = useAppSelector(
      (state) => state.projectDetail.selectedEventListDisplayMode
    )

    // イベント登録ダイアログ
    const [
      isEventEntryDialogOpen,
      openEventEntryDialog,
      closeEventEntryDialog,
    ] = useModal()

    // handle open new event modal
    const handleOpenNewEventModal = useCallback(() => {
      openEventEntryDialog()
    }, [openEventEntryDialog])

    // Register event finish
    const handleEventRegistered = useCallback(
      (eventId: string, deleteFlg: boolean = false) => {
        // Navigate to event detail
        navigate(
          `/projects/${projectId}/events/${eventId}/${getDefaultTabValue(
            EventDetailTabs
          )}`
        )

        // Close entry dialog
        closeEventEntryDialog()
      },
      [closeEventEntryDialog, navigate, projectId]
    )

    const { hasPrjEventCreatePermission } = useProjectPermissions(
      EntityType.Project,
      projectId
    )

    // 表示形式のボタングループ
    const eventDisplayModeButtonGroup = useMemo(() => {
      const items = Object.keys(ProjectDetailEventListDisplayMode).map(
        (key) => {
          const item =
            ProjectDetailEventListDisplayMode[
              key as keyof typeof ProjectDetailEventListDisplayMode
            ]

          const SvgIcon = item.icon
          return {
            index: item.id,
            text: undefined,
            icon: <SvgIcon width={24} height={24} />,
          }
        }
      )

      return items
    }, [])

    // 表示形式の切替ボタンクリック時
    const handleEventDisplayModeItemClick = useCallback(
      (itemData: ButtonGroupInfo) => {
        // 表示形式を更新
        dispatch(setSelectedEventListDisplayMode(itemData.index))
      },
      [dispatch]
    )

    return (
      <div className="py-2.5 flex flex-col gap-2.5">
        <div className="flex flex-row items-center justify-between">
          <CrewButtonGroup
            items={eventDisplayModeButtonGroup}
            keyExpr="index"
            textExpr="text"
            iconExpr="icon"
            stylingMode="text"
            selectedItemKey={selectedEventListDisplayMode}
            onItemClick={handleEventDisplayModeItemClick}
          />

          {/* 新規イベントボタン */}
          {hasPrjEventCreatePermission && (
            // 「イベントの作成」権限を持っている場合　：表示する
            <CrewButton
              type="primary"
              onClick={handleOpenNewEventModal}
              text={t('action.newMeeting')}
            />
          )}
        </div>

        {selectedEventListDisplayMode ===
          ProjectDetailEventListDisplayMode.List.id && (
          // 検索条件
          <ProjectDetailEventSearch />
        )}

        {/* イベント登録ダイアログ */}
        {projectId && (
          <EventEntryDialog
            isEditMode={false}
            projectId={projectId}
            title={t('label.registerNewMeeting')}
            isOpen={isEventEntryDialogOpen}
            onClose={closeEventEntryDialog}
            onSubmit={handleEventRegistered}
          />
        )}
      </div>
    )
  })
