import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { Switch } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof Switch>>

export const CrewSwitch = memo(
  forwardRef<Switch, Props>(({ children, ...rest }, ref) => (
    <Switch ref={ref} {...rest}>
      {children}
    </Switch>
  ))
)
