import { useCallback } from 'react'
import {
  useDeleteFollowMutation,
  useInsertFollowMutation,
  useLazyGetFollowParentQuery,
  useLazyGetFollowQuery,
} from '@crew/apis/follow/followApis'
import { EntityType } from '@crew/enums/domain'

export type EntityKey = {
  entityType: EntityType
  entityRecordId: string
}

export const useCrewFollowerUs = () => {
  const [insertFollowMutation] = useInsertFollowMutation()
  const [deleteFollowMutation] = useDeleteFollowMutation()
  const [lazyGetFollowQuery] = useLazyGetFollowQuery()
  const [lazyGetFollowParentQuery] = useLazyGetFollowParentQuery()

  // check if the user is following the entity
  const checkFollowEntity = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      // 自Entityのフォローを確認
      const response = await lazyGetFollowQuery({
        entityType,
        entityRecordId,
      }).unwrap()

      return response.follow
    },
    [lazyGetFollowQuery]
  )

  // check if the user is following the parent entity
  const checkFollowParent = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      // 親Entityのフォローを確認
      const response = await lazyGetFollowParentQuery({
        entityType,
        entityRecordId,
      }).unwrap()

      return response.follow
    },
    [lazyGetFollowParentQuery]
  )

  // follow
  const insertFollow = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      const response = await insertFollowMutation({
        entityType,
        entityRecordId,
      }).unwrap()

      return response.follow
    },
    [insertFollowMutation]
  )

  // unfollow
  const deleteFollow = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      await deleteFollowMutation({
        entityType,
        entityRecordId,
      }).unwrap()
    },
    [deleteFollowMutation]
  )

  return {
    insertFollow,
    deleteFollow,
    checkFollowEntity,
    checkFollowParent,
  }
}
