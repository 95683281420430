import {
  DefaultMeetingReadinessChecker,
  DefaultMeetingSession,
  TimeoutScheduler,
} from 'amazon-chime-sdk-js'
import { useLogger } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/LoggerProvider'
import { useCallback } from 'react'

export const useCrewSpeakerTestButton = () => {
  const logger = useLogger()

  // check audio output
  const checkAudioOutput = useCallback(
    async (meetingSession: DefaultMeetingSession, deviceId: string) => {
      const meetingReadinessChecker = new DefaultMeetingReadinessChecker(
        logger,
        meetingSession
      )

      // 5秒間ブザー音を慣らす
      await meetingReadinessChecker.checkAudioOutput(deviceId, () => {
        return new Promise((resolve) => {
          const scheduler = new TimeoutScheduler(5000)
          scheduler.start(() => {
            resolve(true)
          })
        })
      })
    },
    [logger]
  )

  return { checkAudioOutput }
}
