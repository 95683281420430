import { FC, memo, useCallback } from 'react'
import BaselineSaveAlt from '~icons/ic/baseline-save-alt'
import { downloadFileFromUrl } from 'utils'

type CrewLightboxDownloadIconProps = {
  url: string
  fileName: string
}

// Lightbox内に表示するダウンロードアイコン
export const CrewLightboxDownloadIcon: FC<CrewLightboxDownloadIconProps> = memo(
  (props) => {
    const handleDownload = useCallback(() => {
      // 公式に記載がある通り、ChromeでCORSエラーが発生する可能性がある
      // 公式の例だと、固有のクエリパラメータを付与する対応が記載されている
      // https://yet-another-react-lightbox.com/plugins/download#Cross-OriginImages
      // しかし、ダウンロード時にS3署名付きURLを使用するため、クエリパラメータを付与すると403エラーが発生してしまい、ダウンロードに失敗する
      // この問題が起きるのは、Lightboxに表示するときのURLとダウンロード時のURLが同じであり、
      // キャッシュされている影響でOriginヘッダーが付与されないことが原因である
      // そのため、ダウンロード時にキャッシュを使用しないことでこのエラーを回避する
      downloadFileFromUrl(props.url, props.fileName, false)
    }, [props.fileName, props.url])

    return (
      <span
        onClick={handleDownload}
        className="inline-block text-slate-200 pt-3 cursor-pointer"
      >
        <BaselineSaveAlt width={24} height={24} />
      </span>
    )
  }
)
