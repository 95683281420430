import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { RadioGroup } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof RadioGroup>>

export const CrewRadioGroup = memo(
  forwardRef<RadioGroup, Props>(({ children, ...rest }, ref) => (
    <RadioGroup ref={ref} {...rest}>
      {children}
    </RadioGroup>
  ))
)
