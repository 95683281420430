import { EventDetailTaskListToolbar } from 'features/project/components/eventDetailPage/components/eventDetailTaskList/components/eventDetailTaskListToolbar/eventDetailTaskListToolbar'
import { memo } from 'react'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { EventTaskTable } from './components/eventTaskTable/eventTaskTable'

export const EventDetailTaskList = memo(() => {
  return (
    <>
      <CrewErrorBoundary>
        <EventDetailTaskListToolbar />
      </CrewErrorBoundary>
      <CrewErrorBoundary>
        <div className="px-2">
          <EventTaskTable />
        </div>
      </CrewErrorBoundary>
    </>
  )
})
