import { memo, useMemo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FileEntryDialog } from 'features/file/components/fileEntryDialog/fileEntryDialog'
import OutlineInsertDriveFile from '~icons/ic/outline-insert-drive-file'
import { FileSearch } from './components/fileSearch/fileSearch'
import { useCallback } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { getDefaultTabValue } from '@crew/utils/dist/enum'
import { FileDetailListTabs, FileListDisplayMode } from 'enums/app'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { setSelectedFileListDisplayMode } from '../../states/fileListSlice'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'

type ButtonGroupInfo = {
  index: number
  text: string | undefined
  icon: JSX.Element | undefined
}

export const FileListToolbar = memo(() => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { navigate } = useCrewNavigate()

  const [isOpenFileEntryDialog, openFileEntryDialog, closeFileEntryDialog] =
    useModal()

  // 選択中の表示形式
  const selectedFileListDisplayMode = useAppSelector(
    (state) => state.fileList.selectedFileListDisplayMode
  )

  // ファイル登録後はファイル詳細画面に遷移
  const handleRegisteredFile = useCallback(
    (fileId: string) => {
      closeFileEntryDialog()

      navigate(`/files/${fileId}/${getDefaultTabValue(FileDetailListTabs)}`)
    },
    [closeFileEntryDialog, navigate]
  )

  // 表示形式のボタングループ
  const fileDisplayModeButtonGroup: ButtonGroupInfo[] = useMemo(() => {
    const items = Object.keys(FileListDisplayMode).map((key) => {
      const item = FileListDisplayMode[key as keyof typeof FileListDisplayMode]

      const SvgIcon = item.icon
      return {
        index: item.id,
        text: undefined,
        icon: <SvgIcon width={24} height={24} />,
      }
    })

    return items
  }, [])

  // 表示形式の切替ボタンクリック時
  const handleFileDisplayModeItemClick = useCallback(
    (itemData: ButtonGroupInfo) => {
      // 表示形式を更新
      dispatch(setSelectedFileListDisplayMode(itemData.index))
    },
    [dispatch]
  )

  return (
    <>
      <div className="w-full flex gap-x-2.5 justify-between items-center">
        {/* ページタイトル */}
        <div className="flex gap-2 items-center h-8 text-crew-gray-3-light dark:text-crew-gray-2-dark">
          <OutlineInsertDriveFile width={24} height={24} />
          <p className="font-bold">{t('label.file') + t('label.list')}</p>
        </div>
        <div className="flex gap-x-1 ml-auto">
          {/* 新規ファイルボタン */}
          <div className="flex flex-1">
            <CrewButton
              type="primary"
              className="ml-auto"
              text={t('action.newFile')}
              onClick={openFileEntryDialog}
            />
          </div>
        </div>
      </div>

      <div className="flex-1 flex gap-x-2.5 items-start">
        {/* 表示切替ボタングループ */}
        <CrewButtonGroup
          items={fileDisplayModeButtonGroup}
          keyExpr="index"
          textExpr="text"
          iconExpr="icon"
          stylingMode="text"
          selectedItemKey={selectedFileListDisplayMode}
          onItemClick={handleFileDisplayModeItemClick}
        />

        <FileSearch />
      </div>

      <FileEntryDialog
        isEditMode={false}
        title={t('label.registerNewFile')}
        isOpen={isOpenFileEntryDialog}
        onClose={closeFileEntryDialog}
        onRegistered={handleRegisteredFile}
      />
    </>
  )
})
