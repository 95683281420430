import { UpdateTenantSettingsRequest } from '@crew/apis/dist/setting/models/updateTenantSettings/request'
import { useUploadFileMutation } from '@crew/apis/file/fileApis'
import { useUpdateTenantSettingsMutation } from '@crew/apis/setting/settingApis'
import { DEFAULT_PASSWORD_MIN_LENGTH } from '@crew/configs/constants'
import { ContractPlan, SettingKeyType } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { AddExternalUserOptions, NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTenantSettingEvent,
} from 'features/app/states/appSlice'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'states/hooks'

export type FormValues = {
  name: string
  domain: string | null
  externalUser: string
  externalUserExpirationDate: string
  passwordLength: string
  forceComplexPassword: boolean
  forceTwoFactorAuthentication: boolean
  restrictDomains: boolean
  avatarKey: string | undefined
}
export const formInitialValues: FormValues = {
  name: '',
  domain: '',
  externalUser: AddExternalUserOptions.Allow.value, //Default value is 「許可」(Allow)
  externalUserExpirationDate: '90', //Temporarily displaying 「90日」(90 days) according to Figma
  passwordLength: String(DEFAULT_PASSWORD_MIN_LENGTH),
  forceComplexPassword: false, //default is off
  forceTwoFactorAuthentication: false, //default is off
  restrictDomains: false, //default is off
  avatarKey: undefined,
}

export const useOrganizationSettingForm = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  // Get functions for update tenant setting information
  const [updateTenantSettingsMutation] = useUpdateTenantSettingsMutation()
  const [uploadFileMutation] = useUploadFileMutation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, setValue, reset, getValues } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Update tenant settings process
  const updateTenantSettings = useCallback(
    async (data: FormValues) => {
      // tenant setting params
      const tenantSettingData: UpdateTenantSettingsRequest = {
        tenantSettings: {
          avatarKey: data.avatarKey ?? '',
          settings: [
            {
              key: SettingKeyType.OrganizationName,
              value: data.name ?? '',
            },
            {
              key: SettingKeyType.OrganizationDomain,
              value: data.domain ?? '',
            },
            {
              key: SettingKeyType.OrganizationExternalUser,
              value: data.externalUser ?? '',
            },
            {
              key: SettingKeyType.OrganizationExternalUserExpirationDate,
              value: data.externalUserExpirationDate ?? '',
            },
            {
              key: SettingKeyType.OrganizationPasswordLength,
              value: data.passwordLength ?? '',
            },
            {
              key: SettingKeyType.OrganizationForceComplexPassword,
              value: String(data.forceComplexPassword) ?? 'false',
            },
            {
              key: SettingKeyType.OrganizationForceTwoFactorAuthentication,
              value: String(data.forceTwoFactorAuthentication) ?? 'false',
            },
            {
              key: SettingKeyType.OrganizationRestrictDomains,
              value: String(data.restrictDomains) ?? 'false',
            },
          ],
        },
      }

      // call api save setting tenant
      await updateTenantSettingsMutation(tenantSettingData).unwrap()

      const objectEventMessage: ObjectEventMessage<UpdateTenantSettingsRequest> =
        {
          eventType: NotifyEventType.Updated,
          id: '',
          object: tenantSettingData,
        }

      dispatch(notifyTenantSettingEvent(objectEventMessage))
    },
    [dispatch, updateTenantSettingsMutation]
  )

  // Upload file processs
  const uploadFile = useCallback(
    async (file: File) => {
      // 一時領域にファイルをupload
      const result = await uploadFileMutation({ file }).unwrap()
      return result
    },
    [uploadFileMutation]
  )

  // check valid domain
  const checkValidDomain = useCallback(() => {
    const domain = getValues('domain')
    if (!domain) return true

    const domainParts = domain.split(';')
    // Define a regular expression pattern for a valid domain name
    const pattern =
      /^(?:(?![0-9-])[A-Za-z0-9-]{1,63}(?<!-)\.?)+(?:[A-Za-z]{2,})$/

    // Check if every domain part matches the pattern
    return domainParts.every((domainPart) => pattern.test(domainPart))
  }, [getValues])

  // The validator to check domain value
  const organizationDomainRequired = useCallback(() => {
    if (getValues('restrictDomains')) {
      // In case「ドメインを制限する」is true, then 「組織のドメイン」 is required.
      if (!getValues('domain')) return false
    }

    return true
  }, [getValues])

  // The validator to check external user expiration date
  const checkRequiredExternalUserExpirationDate = useCallback(() => {
    // In case the current plan is not free, then set it to required.
    if (
      !getValues('externalUserExpirationDate') &&
      currentPlan !== ContractPlan.Free
    ) {
      return false
    }

    return true
  }, [currentPlan, getValues])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      name: {
        required: t('message.general.required'),
      },
      avatarKey: {},
      domain: {
        validate: {
          always: () => checkValidDomain() || t('message.tenant.invalidDomain'),
          checkOrganizationDomain: () =>
            organizationDomainRequired() ||
            t('message.general.isRequired', {
              name: t('label.restrictDomains'),
            }),
        },
      },
      externalUser: { required: t('message.general.required') },
      externalUserExpirationDate: {
        validate: {
          always: () =>
            checkRequiredExternalUserExpirationDate() ||
            t('message.general.required'),
        },
      },
      forceComplexPassword: { required: t('message.general.required') },
      restrictDomains: { required: t('message.general.required') },
      forceTwoFactorAuthentication: { required: t('message.general.required') },
      passwordLength: { required: t('message.general.required') },
    }),
    [
      checkRequiredExternalUserExpirationDate,
      checkValidDomain,
      organizationDomainRequired,
      t,
    ]
  )

  return {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    validateRules,

    updateTenantSettings,
    uploadFile,
  }
}
