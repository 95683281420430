import { FC, memo } from 'react'

import LoadIndicator from 'devextreme-react/load-indicator'

type Props = {
  visible: boolean
}

export const CrewLoadingSpinner: FC<Props> = memo((props) => {
  return props.visible ? (
    // 表示枠内中央および、前面にスピナーを表示させるためabsoluteを使用
    <div className="absolute flex items-center justify-center dx-overlay-wrapper dx-overlay-shader">
      <div className="dx-loadpanel-content">
        <div className="dx-loadpanel-content-wrapper">
          <LoadIndicator />
          <div className="dx-loadpanel-message">読込中</div>
        </div>
      </div>
    </div>
  ) : null
})
