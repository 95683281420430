import { InsertTaskKindRequest } from '@crew/apis/task/models/insertTaskKind/request'
import { UpdateTaskKindRequest } from '@crew/apis/task/models/updateTaskKind/request'
import {
  useInsertTaskKindMutation,
  useUpdateTaskKindMutation,
} from '@crew/apis/task/taskApis'
import { TaskKind } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { NotifyEventType } from 'enums/app'
import { CrewBadgeInvertedColorClass } from 'enums/color'
import {
  notifyTaskKindEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'
import { EntityType } from '@crew/enums/domain'
import { useParams } from 'react-router-dom'

export type FormValues = {
  id: string | null
  name: string
  displayColor: string | null
  initialValue: boolean
  version: number | null
}

// ダイアログ初期値
const formInitialValues: FormValues = {
  id: null,
  name: '',
  displayColor: CrewBadgeInvertedColorClass.Red, //add color default is red
  initialValue: false,
  version: null,
}

export const useTaskKindEntryForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectId } = useParams()

  // Get functions for update task type information
  const [insertTaskKindMutation] = useInsertTaskKindMutation()
  const [updateTaskKindMutation] = useUpdateTaskKindMutation()

  // react-hook-formの各種データを取得
  const { control, reset, formState, handleSubmit, clearErrors, setError } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Insert task type process
  const insertTaskKind = useCallback(
    async (data: FormValues) => {
      if (!projectId) return

      const request: InsertTaskKindRequest = {
        taskKind: {
          entityType: EntityType.Project,
          entityRecordId: projectId,
          name: data.name,
          displayColor: data.displayColor ?? '',
          initialValue: data.initialValue,
        },
      }

      const result = await insertTaskKindMutation(request).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TaskKind> = {
        eventType: NotifyEventType.Inserted,
        id: result.taskKind?.id ?? '',
        object: result.taskKind ?? undefined,
      }

      dispatch(notifyTaskKindEvent(objectEventMessage))

      return result
    },
    [dispatch, insertTaskKindMutation, projectId]
  )

  // Update task type process
  const updateTaskKind = useCallback(
    async (data: FormValues) => {
      const request: UpdateTaskKindRequest = {
        taskKind: {
          id: data.id as string,
          name: data.name,
          displayColor: data.displayColor ?? '',
          initialValue: data.initialValue,
          version: data.version as number,
        },
      }

      const result = await updateTaskKindMutation(request).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TaskKind> = {
        eventType: NotifyEventType.Updated,
        id: result.taskKind?.id ?? '',
        object: result.taskKind ?? undefined,
      }

      dispatch(notifyTaskKindEvent(objectEventMessage))

      return result
    },
    [dispatch, updateTaskKindMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      name: {
        required: t('message.general.isRequired', {
          name: t('label.name'),
        }),
      },
      displayColor: {
        required: t('message.general.isRequired', {
          name: t('label.displayColor'),
        }),
      },
      // not validate below
      id: {},
      version: {},
      initialValue: {},
    }),
    [t]
  )

  return {
    control,
    reset,
    formState,
    handleSubmit,
    clearErrors,
    setError,

    validateRules,

    insertTaskKind,
    updateTaskKind,
  }
}
