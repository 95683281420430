import { memo } from 'react'
import { PersonalSettingNotificationForm } from './components/personalSettingNotificationForm/personalSettingNotificationForm'

/**
 * Personal Setting Notification Panel
 * @date 8/3/2023 - 1:15:24 PM
 *
 * @type {*}
 */
export const PersonalSettingNotificationPanel = memo(() => {
  return (
    <div className="max-w-2xl mx-auto w-full">
      <PersonalSettingNotificationForm />
    </div>
  )
})
