import { useCallback, useEffect, useRef, useState } from 'react';
/**
 * 指定した関数を、指定した回数のReactレンダリング分遅延させてから実行するcustom hook
 * @param func 遅延実行する関数
 * @returns trigger 遅延実行を開始する関数。引数に遅延回数を指定する
 */
export const useProcessInLaterRendering = (func, deps) => {
    // 遅延実行カウンタ
    const [delayCounter, setDelayCounter] = useState();
    // 外部から渡されるfuncをメモ化するため、depsもセットで貰ってここでuseCallbackする
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedFunc = useCallback(func, deps);
    /**
     * 遅延実行をトリガーする
     * @param delay 遅延させるReactレンダリング回数
     */
    const trigger = useCallback((delay) => {
        setDelayCounter(delay);
    }, []);
    useEffect(() => {
        if (delayCounter === undefined) {
            // カウンタ未設定、何もしない
            return;
        }
        if (delayCounter > 0) {
            // カウンタが残っているので、デクリメントして何もしない
            setDelayCounter(delayCounter - 1);
            return;
        }
        // カウンタが0になったので、処理を実行してカウンタをリセットする
        memoizedFunc();
        setDelayCounter(undefined);
    }, [delayCounter, memoizedFunc]);
    return trigger;
};
/**
 * 指定した関数を、指定した回数のReactレンダリング分遅延させてから実行するcustom hook
 * @param func 遅延実行する関数
 * @param deps funcの依存配列
 * @param delay 遅延させるReactレンダリング回数
 * @returns trigger 遅延実行を開始する関数。引数に遅延回数を指定する
 */
export const useProcessInLaterRenderingWithParams = (func, deps, delay) => {
    // 遅延実行カウンタ
    const [delayCounter, setDelayCounter] = useState();
    // 外部から渡されるfuncをメモ化するため、depsもセットで貰ってここでuseCallbackする
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedFunc = useCallback(func, deps);
    const memoizedFuncParams = useRef();
    /**
     * 遅延実行をトリガーする
     * @param delay 遅延させるReactレンダリング回数
     */
    const trigger = useCallback((...args) => {
        memoizedFuncParams.current = args;
        setDelayCounter(delay);
    }, [delay]);
    useEffect(() => {
        var _a;
        if (delayCounter === undefined) {
            // カウンタ未設定、何もしない
            return;
        }
        if (delayCounter > 0) {
            // カウンタが残っているので、デクリメントして何もしない
            setDelayCounter(delayCounter - 1);
            return;
        }
        // カウンタが0になったので、処理を実行してカウンタをリセットする
        memoizedFunc(...((_a = memoizedFuncParams.current) !== null && _a !== void 0 ? _a : []));
        setDelayCounter(undefined);
    }, [delayCounter, memoizedFunc]);
    return trigger;
};
