import useLocalAudioInputActivityPreview from 'modules/amazon-chime-sdk-component-library-devextreme/hooks/sdk/useLocalAudioInputActivityPreview'
import { FC, memo, useRef } from 'react'

// マイクのシグナル強度を表示するコンポーネント
export const CrewMicrophoneActivityPreview: FC = memo(() => {
  const elementRef = useRef<HTMLDivElement>(null)
  useLocalAudioInputActivityPreview(elementRef)

  return (
    <div className="w-full h-2.5 bg-crew-blue-1-light rounded">
      <div
        className="h-2.5 bg-crew-blue-3-light rounded scale-x-0 origin-left transition-transform ease-in-out will-change"
        ref={elementRef}
      ></div>
    </div>
  )
})
