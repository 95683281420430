import { useTranslation } from '@crew/modules/i18n'
import { useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { ValidateRules } from '@crew/utils/form'
import { useAppDispatch } from 'states/hooks'
import {
  useUpdateUserRoleMutation,
  useUpdateUsersRoleMutation,
} from '@crew/apis/user/userApis'
import { UpdateUserRoleRequest } from '@crew/apis/user/models/updateUserRole/request'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTenantSettingUserEventMessageEvent,
} from 'features/app/states/appSlice'
import { TenantSettingMember } from '@crew/apis/dist/tenantSetting/models/getTenantSettingMembers/response'
import { UpdateUsersRoleRequest } from '@crew/apis/dist/user/models/updateUsersRole/request'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/dist/system'
import { SelectedUserType } from '../../../../useUserList'

export type FormValues = {
  roleId: string
  isEnabled: boolean
  expirationDatetime: Date | null
}

export const useEditTenantUserEntryForm = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, formState, handleSubmit, reset, getValues } =
    useForm<FormValues>({
      criteriaMode: 'all',
    })

  const dispatch = useAppDispatch()

  // Get functions for update user role
  const [updateUserRoleMutation] = useUpdateUserRoleMutation()
  const [updateUsersRoleMutation] = useUpdateUsersRoleMutation()

  // Update user role process
  const updateUserRole = useCallback(
    async (userId: string, data: FormValues, version: number) => {
      // Update `role` and `status` of single user
      const params: UpdateUserRoleRequest = {
        user: {
          userId,
          isEnabled: data.isEnabled,
          roleId: data.roleId,
          expirationDatetime: data.expirationDatetime
            ? dayjs(data.expirationDatetime).format(JsonDateFormat.YYYYMMDD)
            : undefined,

          version,
        },
      }
      const result = await updateUserRoleMutation(params).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TenantSettingMember> = {
        eventType: NotifyEventType.Updated,
        id: result.user.id,
        object: undefined,
      }
      //trigger event refresh user list
      dispatch(notifyTenantSettingUserEventMessageEvent(objectEventMessage))
    },
    [dispatch, updateUserRoleMutation]
  )

  // Update users role process
  const updateUsersRole = useCallback(
    async (updateUsers: SelectedUserType[], data: FormValues) => {
      // Update `role` and `status` of bulk user
      const params: UpdateUsersRoleRequest = {
        users: updateUsers,
        isEnabled: data.isEnabled,
        roleId: data.roleId,
        expirationDatetime: data.expirationDatetime
          ? dayjs(data.expirationDatetime).format(JsonDateFormat.YYYYMMDD)
          : undefined,
      }

      await updateUsersRoleMutation(params).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TenantSettingMember> = {
        eventType: NotifyEventType.Updated,
        id: '',
        object: undefined,
      }
      //trigger event refresh user list
      dispatch(notifyTenantSettingUserEventMessageEvent(objectEventMessage))
    },
    [dispatch, updateUsersRoleMutation]
  )

  //check is date when user enter in input datepicker
  const validateIsDate = useCallback((value: Date | null) => {
    //when date enter invalid date, datepicker response undefined
    if (typeof value === 'undefined') {
      return false
    }

    return true
  }, [])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      roleId: {
        required: t('message.general.required'),
      },
      expirationDatetime: {
        validate: {
          checkDate: () =>
            validateIsDate(getValues('expirationDatetime')) ||
            t('message.general.invalidDate'),
        },
      },
      isEnabled: {},
    }),
    [getValues, t, validateIsDate]
  )

  return {
    control,
    formState,
    handleSubmit,
    reset,

    validateRules,

    updateUserRole,
    updateUsersRole,
  }
}
