import {
  useLazyGetLookupTaskStateQuery,
  useLazyGetLookupTaskStatesQuery,
} from '@crew/apis/lookup/lookupApis'
import { useDataSource } from './useDataSource'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { EntityType } from '@crew/enums/domain'

export const useTaskStateDataSource = (
  entityType: EntityType | undefined,
  entityRecordId: string | undefined,
  isGrouped: boolean = false
) => {
  const [lazyGetTaskStatesQuery] = useLazyGetLookupTaskStatesQuery()
  const [lazyGetTaskStateQuery] = useLazyGetLookupTaskStateQuery()

  return useDataSource(
    () => ({
      key: 'id',
      // Using 'raw' load mode to avoid grouping issue
      // https://supportcenter.devexpress.com/ticket/details/t1017195/datasource-grouping-not-rendering-correctly-using-custom-datasource
      loadMode: isGrouped ? 'raw' : undefined,
      load: async (loadOptions) => {
        if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // filtering selected task state IDs
          const filteringTaskStateIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetTaskStatesQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            name: undefined,
            taskStateIds: filteringTaskStateIds,
          }).unwrap()

          return response.taskStates
        } else {
          // インクリメンタルサーチ
          const response = await lazyGetTaskStatesQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            name: loadOptions.searchValue,
            taskStateIds: undefined,
          }).unwrap()

          return response.taskStates
        }
      },
      byKey: async (taskStateId) => {
        const response = await lazyGetTaskStateQuery({ taskStateId }).unwrap()
        return response.taskState
      },
      group: isGrouped ? 'projectId' : null,
    }),
    [
      entityRecordId,
      entityType,
      isGrouped,
      lazyGetTaskStateQuery,
      lazyGetTaskStatesQuery,
    ]
  )
}
