import { useFreshdeskMutation } from '@crew/apis/dist/sso/ssoApis'
import { useEffectOnce } from '@dx-system/react-use'
import { memo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const AppSsoFreshDesk = memo(() => {
  const [searchParams] = useSearchParams()
  const [freshdeskMutation] = useFreshdeskMutation()

  const clientId = searchParams.get('client_id')
  const scope = searchParams.get('scope')
  const state = searchParams.get('state')
  const redirectUri = searchParams.get('redirect_uri')
  const registrationId = searchParams.get('registration_id')
  const nonce = searchParams.get('nonce')

  useEffectOnce(() => {
    const ssoAuth = async () => {
      // freshdesk側からのリクエストパラメータが不足している場合は何もしない
      if (
        !clientId ||
        !scope ||
        !state ||
        !redirectUri ||
        !registrationId ||
        !nonce
      ) {
        console.warn('Invalid request parameters')
        return
      }

      const response = await freshdeskMutation({
        clientId: Number(clientId),
        scope: scope,
        state: state,
        redirectUri: redirectUri,
        registrationId: Number(registrationId),
        nonce: nonce,
      }).unwrap()

      if (response) {
        // FreshDeskのSSO URLにリダイレクト
        const redirectUrl = `${response.redirectUrl}?state=${response.state}&id_token=${response.idToken}`
        window.location.replace(redirectUrl)
      }
    }

    ssoAuth()
  })

  return <></>
})
