import { useEffect } from 'react';
import { useLazyGetActiveChatRoomsQuery } from '@crew/apis/chat/chatApis';
import { useActiveChatRoomService } from '@crew/states';
/**
 * activeChatRoomsを取得してReduxに格納するcustom hook
 */
export const useFetchActiveChatRooms = () => {
    const activeChatRoomService = useActiveChatRoomService();
    const [fetchActiveChatRooms, { data, isError, isLoading, isSuccess, isFetching },] = useLazyGetActiveChatRoomsQuery();
    // activeChatRoomsをReduxに格納する
    useEffect(() => {
        if (!isFetching && isSuccess && !isError && data) {
            const activeChatRooms = data.chatRooms;
            const params = activeChatRooms.map((chatRoom) => {
                var _a;
                return ({
                    // 基本情報
                    chatRoomId: chatRoom.chatRoomId,
                    parentChatRoomId: chatRoom.parentChatRoomId,
                    referenceEntityType: chatRoom.referenceEntityType,
                    referenceEntityRecordId: chatRoom.referenceEntityRecordId,
                    // projectの場合の追加情報
                    isFavorite: chatRoom.isFavorite,
                    projectName: chatRoom.projectName,
                    projectScope: chatRoom.projectScope,
                    projectType: chatRoom.projectType,
                    projectVersion: chatRoom.projectVersion,
                    // 未読情報
                    unreadCount: {
                        total: chatRoom.totalUnreadCountTotal,
                        normal: chatRoom.totalUnreadCountNormal,
                        reply: chatRoom.totalUnreadCountReply,
                        mentionToGroup: chatRoom.totalUnreadCountMentionToGroup,
                        mentionToMe: chatRoom.totalUnreadCountMentionToMe,
                    },
                    updateSerial: chatRoom.updateSerial,
                    lastReadMessageId: (_a = chatRoom.lastReadMessageId) !== null && _a !== void 0 ? _a : undefined,
                });
            });
            activeChatRoomService.setActiveChatRoomsWithUnreadCount({
                activeChatRooms: params,
            });
        }
        // 依存配列にisFetchingは必須：APIの戻り値が前回実行時と差分がなくてもReduxの更新処理を行うため
        // ⇒未読件数の更新はWebSocket経由でも行われるため、APIの戻り値が前回実行時と差分がなくてもReduxの更新は行う必要がある
        //  （アクティブチャットルームの戻り値は常に最新のものであるため）
    }, [activeChatRoomService, data, isError, isSuccess, isFetching]);
    return { fetchActiveChatRooms, isLoading };
};
