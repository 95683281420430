import { FC, memo } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { useTranslation } from '@crew/modules/i18n'
import { WebMeetingSettingForm } from 'features/webMeeting/components/webMeetingSettingDialog/components/webMeetingSettingForm/webMeetingSettingForm'

type WebMeetingSettingDialogProps = {
  isOpen: boolean
  onClose: () => void
}

export const WebMeetingSettingDialog: FC<WebMeetingSettingDialogProps> = memo(
  (props) => {
    const { t } = useTranslation()

    return (
      <Modal
        title={t('label.webMeetingSetting')}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <div className="flex flex-col gap-y-5 h-full">
          <WebMeetingSettingForm onClose={props.onClose} />
        </div>
      </Modal>
    )
  }
)
