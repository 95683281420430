import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { DropDownButton } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof DropDownButton>>

export const CrewDropDownButton = memo(
  forwardRef<DropDownButton, Props>(
    ({ children, dropDownOptions, ...rest }, ref) => (
      <DropDownButton
        ref={ref}
        {...rest}
        // Add this option to make the text in the items fully visible
        dropDownOptions={{
          width: 'auto',
          wrapperAttr: {
            class: 'crew-dropdown-button',
          },
          ...dropDownOptions,
        }}
      >
        {children}
      </DropDownButton>
    )
  )
)
