import { Modal } from 'components/layouts/modal/modal'
import { memo } from 'react'
import { AuthoritySettingRoleEntryForm } from './components/authoritySettingRoleEntryForm/authoritySettingRoleEntryForm'
import { useTranslation } from '@crew/modules/i18n'
import { Role } from '../../useAuthoritySettingPanel'
import { RoleRef } from '@crew/models/refs'

export type PropsMode = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (role: Role) => void
  roles: RoleRef[]
}

export const AuthoritySettingRoleEntryDialog = memo((props: PropsMode) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('label.addRole')}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <AuthoritySettingRoleEntryForm
        onClose={props.onClose}
        onSubmit={props.onSubmit}
        roles={props.roles}
      />
    </Modal>
  )
})
