import { useEffect, useState } from 'react'

import { currentToken, eventChangeToken } from './pushNotifcation'

/**
 * デバイストークンを取得するカスタムフック。取得リクエストはpermissionに応じて自動的に行われる
 * @returns
 */
export const useDeviceToken = () => {
  const [deviceToken, setDeviceToken] = useState<string | undefined>(
    currentToken
  )

  // token取得はreactの管理外で行われるため、イベントリスナで検知する
  useEffect(() => {
    eventChangeToken.add(setDeviceToken)
    return () => {
      // クリーンアップ時にイベントリスナ配列から削除する
      eventChangeToken.delete(setDeviceToken)
    }
  }, [])

  return deviceToken
}
