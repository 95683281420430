import { useTranslation } from '@crew/modules/i18n'
import { useForm } from 'react-hook-form'
import { ValidateRules } from '@crew/utils/dist/form'
import { useCallback, useMemo } from 'react'
import { useTwoFactorAuthMutation } from '@crew/apis/dist/app/appApis'
import { setLoggedInUser } from 'features/app/states/appSlice'
import { useAppDispatch } from 'states/hooks'
import { resetReduxState } from 'states/store'

export type FormValues = {
  loginCode: string
}

export const useAppLoginVerifyCodePage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // react-hook-formの各種データを取得
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      loginCode: '',
    },
    criteriaMode: 'all',
  })

  // Get verify auth code function
  const [twoFactorAuthMutation] = useTwoFactorAuthMutation()

  // Verify authentication code
  const verifyAuthCode = useCallback(
    async (data: FormValues, token: string) => {
      // Call verify auth code api
      const result = await twoFactorAuthMutation({
        authCode: data.loginCode,
        token: token,
      }).unwrap()

      // reduxを初期化
      resetReduxState()

      // 秘匿ではないuser情報を保存
      dispatch(setLoggedInUser(result.data))
    },
    [dispatch, twoFactorAuthMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      loginCode: {
        required: t('message.general.isRequired', {
          name: t('label.authenticationCode'),
        }),
      },
    }),
    [t]
  )

  return {
    control,
    handleSubmit,
    validateRules,
    verifyAuthCode,
  }
}
