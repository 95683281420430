import notify from 'devextreme/ui/notify'

type Types = 'success' | 'info' | 'warning' | 'error'

const show = (type: Types, message: string, displayTime = 3000) => {
  notify(
    {
      message,
      minWidth: 230,
      width: 'auto',
      position: 'top right',
      type,
    },
    type,
    displayTime
  )
}

//show toast error message
const error = (message: string) => {
  show('error', message)
}

//show toast warning message
const warn = (message: string) => {
  show('warning', message)
}

//show toast info message
const info = (message: string) => {
  show('info', message)
}

//show toast success message
const success = (message: string) => {
  show('success', message)
}

const toast = { error, success, info, warn }

export const useToast = () => {
  return toast
}
