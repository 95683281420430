import { Outlet } from 'react-router-dom'
import classNames from 'classnames'
import { FC, memo } from 'react'

export const AppContentPanel: FC = memo(() => {
  return (
    <div
      id="app-content-panel"
      data-testid="app-content-panel"
      className={classNames('flex flex-col flex-auto overflow-y-auto')}
    >
      <Outlet />
    </div>
  )
})
