import { memo, FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'states/hooks'
export const AppRequireAuthRouteElement: FC = memo(() => {
  const location = useLocation()

  // ユーザー情報
  // ログアウト -> 別ユーザーでログインしたという動きをuseEffectで検知するため
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  return Boolean(loggedInUser) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ referrer: location }} replace />
  )
})
