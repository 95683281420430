import { memo } from 'react'
import { TwoFactorVerifyCode } from '../twoFactorVerifyCode/twoFactorVerifyCode'
import { TwoFactorAuthenticationType } from 'enums/app'
import { apiBaseUrl } from '@crew/configs/dist/constants'
import { useTranslation } from '@crew/modules/dist/i18n'

type TwoFactorAppProps = {
  onSubmit: () => void
}

export const TwoFactorApp = memo((props: TwoFactorAppProps) => {
  const { t } = useTranslation()

  return (
    <div className="mt-2.5">
      <div className="flex items-center gap-x-2.5">
        <img
          src={`${apiBaseUrl()}api/v1/users/qr-code`}
          alt="QRCode"
          className="h-28"
        />
        <div>
          <span className="crew-text-gray-4">
            {t('message.personalSetting.twoFactorAppNote')}
          </span>
          <div className="mt-2.5">
            <TwoFactorVerifyCode
              onSubmit={props.onSubmit}
              twoFactorAuthenticationType={
                TwoFactorAuthenticationType.App.value
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
})
