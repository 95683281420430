import { Chat, useChatCurrentService } from '@crew/states'
import { useEffectOnce } from '@dx-system/react-use'
import { useAppDispatch } from 'states/hooks'

export const useProjectDetailChatPanel = () => {
  const dispatch = useAppDispatch()

  // Sliceの操作を行うためのServiceを取得
  const chatCurrentService = useChatCurrentService(dispatch)

  // このチャットパネル（チャットタブ）が開かれている場合は2ペイン表示にする
  useEffectOnce(() => {
    chatCurrentService.setChatCurrentStyle(Chat.Style.Full)
    return () => {
      chatCurrentService.setChatCurrentStyle(Chat.Style.Compact)
    }
  })
}
