import { useCallback } from 'react'
import {
  ObjectEventMessage,
  notifyTenantSettingUserEventMessageEvent,
} from 'features/app/states/appSlice'
import { useAppDispatch } from 'states/hooks'
import { NotifyEventType } from 'enums/app'
import { TenantSettingMember } from '@crew/apis/dist/tenantSetting/models/getTenantSettingMembers/response'
import { useDeleteUserPendingMutation } from '@crew/apis/dist/tenantSetting/tenantSettingApis'

export const useTenantUserTable = () => {
  const dispatch = useAppDispatch()

  // Get function for delete pending user
  const [deleteUserPendingMutation] = useDeleteUserPendingMutation()

  // Delete pending user process
  const deletePendingUser = useCallback(
    async (tenantMemberPendingId: string) => {
      // Call Api cancel member
      await deleteUserPendingMutation({
        userPendingId: tenantMemberPendingId,
      }).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TenantSettingMember> = {
        eventType: NotifyEventType.Deleted,
        id: tenantMemberPendingId,
        object: undefined,
      }

      //trigger event refresh user list
      dispatch(notifyTenantSettingUserEventMessageEvent(objectEventMessage))
    },
    [deleteUserPendingMutation, dispatch]
  )

  return {
    deletePendingUser,
  }
}
