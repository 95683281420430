import { FC, memo, useEffect, useMemo } from 'react'
import { MyTaskTable } from './components/myTaskTable/myTaskTable'
import { useGetMyTasksQuery } from '@crew/apis/task/taskApis'
import { useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { useAppSelector } from 'states/hooks'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import {
  DEFAULT_PAGING_PARAMS,
  TASK_DEFAULT_SORT_COLUMN,
} from 'configs/constants'
import { PaginationState } from '@tanstack/react-table'
import { getParamAsString } from 'utils'
import { TaskKindFilters } from 'enums/app'
import { GetMyTasksRequest } from '@crew/apis/task/models/getMyTasks/request'
import { skipToken } from '@reduxjs/toolkit/query'

export const MyTaskListDataGrid: FC = memo(() => {
  const [searchParams] = useSearchParams()

  const params = qs.parse(searchParams.toString())

  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  // TaskKind条件の準備
  // Assign the value 'undefined' to taskKind when value taskKindId = allTypes
  const currentTaskKind = useMemo(() => {
    return searchParams.get('taskKindId') !== TaskKindFilters.AllTaskKinds
      ? searchParams.get('taskKindId')
      : undefined
  }, [searchParams])

  // ソート順。指定されていない場合はIDの降順
  const sortParams = useMemo(() => {
    const sortParams = searchParams.getAll('sort')
    return sortParams.length > 0 ? sortParams : TASK_DEFAULT_SORT_COLUMN
  }, [searchParams])

  const getMyTasksParams: GetMyTasksRequest = {
    keyword: searchParams.get('keyword') || undefined,
    taskKindId: currentTaskKind || undefined,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    sort: sortParams,
  }

  const { data: getMyTasksResult, refetch: getMyTasksRefetch } =
    useGetMyTasksQuery(getMyTasksParams ?? skipToken)

  // refresh my task list
  useEffect(() => {
    getMyTasksRefetch()
  }, [getMyTasksRefetch, taskEventMessage])

  return (
    <div className="flex h-full overflow-y-hidden">
      {/* My task table */}
      <MyTaskTable
        myTasks={getMyTasksResult?.tasks ?? []}
        totalCount={getMyTasksResult?.totalCount ?? 0}
        pagination={pagination}
      />
    </div>
  )
})
