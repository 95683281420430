import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewRadioGroupField } from 'components/forms/crewRadioGroupField'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { CrewTextAreaField } from 'components/forms/crewTextAreaField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useProjectEntryForm } from 'features/project/components/projectEntryDialog/components/projectEntryForm/useProjectEntryForm'
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { OwnerFieldRender } from 'features/project/components/projectEntryDialog/components/projectEntryForm/components/ownerFieldRender/ownerFieldRender'
import { OwnerItemRender } from 'features/project/components/projectEntryDialog/components/projectEntryForm/components/ownerItemRender/ownerItemRender'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import {
  CrewAvatarShapeType,
  CrewAvatarSize,
  CrewAvatar,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { useFocusInput } from 'hooks/useFocusInput'
import { CrewFieldDescriptionLabel } from 'components/elements/crewFieldDescriptionLabel'
import { useSystemPermissions } from '@crew/hooks'
import { useToast } from 'hooks/useToast'
import { useAppSelector } from 'states/hooks'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useTranslation } from '@crew/modules/dist/i18n'
import { Project } from '@crew/apis/project/models/getProject/response'
import { GetProjectRequest } from '@crew/apis/project/models/getProject/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetProjectQuery } from '@crew/apis/dist/project/projectApis'
import { ProjectScope } from 'enums/app'
import { generateImageAvatarUrl } from '@crew/utils/dist/avatar'
import { EntityType } from '@crew/enums/domain'
import { FormValues } from './useProjectEntryForm'
import { convertToByte } from '@crew/utils/file'

export type ProjectFormProps = {
  isEditMode: boolean
  projectId?: string
  onSubmit: (projectId: string) => void
  onCancel: () => void
  onDeleted?: () => void
}

type ProjectOwnerFieldProps =
  | {
      id?: string
      displayName?: string
    }
  | undefined

// select box project owner user render field
// renderとして使うのでmemo不可
const ProjectOwnerField: FC<ProjectOwnerFieldProps> = (props) => (
  <OwnerFieldRender ownerId={props?.id} ownerName={props?.displayName} />
)

type ProjectOwnerItemProps =
  | {
      id?: string
      displayName?: string
    }
  | undefined

// select box project owner user render item
// renderとして使うのでmemo不可
const ProjectOwnerItem: FC<ProjectOwnerItemProps> = (props) => (
  <OwnerItemRender ownerId={props?.id} ownerName={props?.displayName} />
)

// render content
// renderとして使うのでmemo不可
const ProjectRadioItem: FC<{
  key: string
  name: string
}> = (props) => (
  <div>
    <p id={`radioId-${props.key}`} className="crew-text-default">
      {props.name}
    </p>
  </div>
)

const MAX_FILE_SIZE = 1 // 1MB
export const ProjectEntryForm: FC<ProjectFormProps> = memo((props) => {
  const {
    handleSubmit,
    clearErrors,
    formState,
    reset,
    control,
    setError,
    setValue,
    getValues,
    watch,

    convertBase64,

    projectScopeDataSource,
    ownerDataSource,
    memberDataSource,
    projectGroupDataSource,
    validateRules,

    deleteProject,
    updateProject,
    insertProject,
    uploadFile,
  } = useProjectEntryForm()

  useFocusInput('subject')

  const toast = useToast()

  const { t } = useTranslation()

  const imageRef = useRef<HTMLInputElement>(null)

  const [selectedImage, setSelectedImage] = useState<string | undefined>()
  const [isShowApprovalToJoin, setIsShowApprovalToJoin] =
    useState<boolean>(false)
  const [project, setProject] = useState<Project>()

  const [displayName, setDisplayName] = useState<string>('P')
  const [initialAvatarStyle, setInitialAvatarStyle] = useState<string>('')

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  // プロジェクト詳細の取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const useGetProjectParam: GetProjectRequest | undefined = props.projectId
    ? {
        projectId: props.projectId,
      }
    : undefined

  const { data: getProjectResult } = useGetProjectQuery(
    useGetProjectParam ?? skipToken
  )

  const imageUrl = generateImageAvatarUrl(
    EntityType.Project,
    getProjectResult?.project?.id
  )

  // set image when changed
  const handleImageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        try {
          const file = event.target.files[0]
          // validate file size
          if (file.size > convertToByte(MAX_FILE_SIZE, 'MB')) {
            toast.error(t('message.file.invalidMaxFileSize'))
            // make reselect file
            if (imageRef.current) {
              imageRef.current.value = ''
            }
            return
          }
          // 一時領域にファイルをupload
          const result = await uploadFile(file)
          setValue('avatarKey', result.key)
          const base64 = await convertBase64(file)
          setSelectedImage(base64 as string)

          // 初期スタイルが不要になるため空にする
          setInitialAvatarStyle('')
        } catch (err: any) {
          toast.error(t('message.file.uploadFailed'))
        }
      }
    },
    [convertBase64, setValue, t, toast, uploadFile]
  )

  // Check scope to show/hide approval to join
  const handleProjectScopeChange = useCallback((value: string) => {
    setIsShowApprovalToJoin(ProjectScope.Public.key === value)
  }, [])

  // フォーム初期化処理関数
  const initializeForm = useCallback(() => {
    // 編集時
    if (props.isEditMode && getProjectResult?.project) {
      // Check scope to show/hide approval to join
      handleProjectScopeChange(getProjectResult.project.scope)

      reset({
        subject: getProjectResult.project.subject,
        description: getProjectResult.project.description ?? undefined,
        scope: getProjectResult.project.scope,
        approvalToJoin: getProjectResult.project.approvalToJoin,
        ownerUserId: getProjectResult.project.ownerUser.id,
        projectGroupId: getProjectResult.project.projectGroup?.id,
        archived: getProjectResult.project.archived,
      })

      setProject(getProjectResult.project ?? undefined)

      // 登録されている表示名・初期スタイル・バージョンのセット
      if (getProjectResult.project) {
        // 件名
        setDisplayName(getProjectResult.project.subject ?? 'p')
        // 既存データ表示時は初期スタイル不要
        setInitialAvatarStyle('')
      }
    } else {
      const initialScope = ProjectScope.Private.key
      // Check scope to show/hide approval to join
      handleProjectScopeChange(initialScope)

      // 新規登録時
      const loggedInUserId = loggedInUser?.id as string
      reset({
        scope: initialScope,
        approvalToJoin: false,
        ownerUserId: loggedInUserId,
        projectMemberIds: [loggedInUserId],
      })
      // アバターの初期スタイルとして枠線を表示
      setInitialAvatarStyle('crew-avatar-xl border rounded-md')
    }
  }, [
    getProjectResult?.project,
    handleProjectScopeChange,
    loggedInUser?.id,
    props.isEditMode,
    reset,
  ])

  // 初期化処理をuseEffect化
  // TODO: 本当はuseEffectを外したいのだが、レンダリングが大量に走ってしまうためこのようにしている。以下タスクで調査・対応予定
  // https://break-tmc.atlassian.net/browse/CREW-6028
  useEffect(() => {
    // フォーム初期化を実行
    initializeForm()
  }, [initializeForm])

  const canSend = useMemo(
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    [formState]
  )

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // upload file image
  const handleFileUpload = useCallback(() => {
    imageRef.current?.click()
  }, [])
  // end action
  const handleDeleteButtonClick = useCallback(() => {
    setConfirmMessage(t('message.general.confirmMessage.delete'))
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    closeConfirmDialog()

    if (!props.projectId || !project) {
      return
    }

    try {
      await deleteProject(props.projectId, project.version)

      toast.success(t('message.project.projectDeleted'))
      props.onSubmit(props.projectId ?? '')
      props.onDeleted && props.onDeleted()
    } catch (err) {
      showApiErrors(err)
    }
  }, [
    closeConfirmDialog,
    props,
    project,
    deleteProject,
    toast,
    t,
    showApiErrors,
  ])

  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (props.isEditMode && props.projectId) {
          // Execute update project process
          const result = await updateProject(
            props?.projectId,
            project?.version ?? 0,
            data,
            isShowApprovalToJoin ? data.approvalToJoin : false
          )

          props.onSubmit(result.project.id)
          toast.success(t('message.project.projectUpdated'))
        } else {
          // Execute insert project process
          const result = await insertProject(
            data,
            isShowApprovalToJoin ? data.approvalToJoin : false
          )

          // 登録結果のレスポンスがない場合
          if (!result || !result.project) {
            console.log('project not found')
            return
          }

          toast.success(t('message.project.projectRegistered'))
          props.onSubmit(result.project.id)
        }
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    props,
    updateProject,
    project?.version,
    isShowApprovalToJoin,
    toast,
    t,
    insertProject,
    showApiErrors,
  ])

  // handle close project dialog
  const handleCancelButtonClick = useCallback(() => {
    reset()
    clearErrors()
    props.onCancel && props.onCancel()
  }, [clearErrors, props, reset])

  const ownerUserId = watch('ownerUserId')
  const projectMemberIds = watch('projectMemberIds')
  // add owner member to project member list
  useEffect(() => {
    // check ownerUserId and projectMemberIds not null and projectMemberIds don't have ownerUserId
    if (
      ownerUserId &&
      projectMemberIds &&
      projectMemberIds.findIndex((item) => item === ownerUserId) === -1
    ) {
      // add ownerUserId to projectMemberIds
      setValue('projectMemberIds', [...projectMemberIds, ownerUserId])
    }
  }, [ownerUserId, projectMemberIds, setValue])

  const { hasSysProjectCreateDeletePermission } = useSystemPermissions()

  return (
    <>
      <form className="flex flex-col gap-y-5 h-full">
        <CrewScrollView>
          <div className="space-y-5 ">
            <div className="flex flex-col gap-y-2.5">
              <div className="flex gap-x-2.5">
                <div className="text-center">
                  <CrewAvatar
                    cacheValue={
                      props.isEditMode && getProjectResult?.project
                        ? getProjectResult.project.id +
                          getProjectResult.project.version // 編集時：プロジェクトID + バージョン
                        : getValues('subject') // 新規登録時：件名
                    }
                    // 画像が変更されている場合は変更後の画像、変更されていない場合は登録済みの画像を表示
                    imageURL={selectedImage ? undefined : imageUrl}
                    avatarImage={selectedImage}
                    displayName={displayName}
                    size={CrewAvatarSize.xl}
                    shape={CrewAvatarShapeType.Square}
                    className={initialAvatarStyle}
                  />
                  <div className="text-center">
                    <input
                      ref={imageRef}
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <span
                      className="cursor-pointer leading-5"
                      onClick={handleFileUpload}
                    >
                      {t('label.change')}
                    </span>
                  </div>
                </div>
                <div className="flex-auto">
                  <CrewTextBoxField
                    id="subject"
                    name="subject"
                    control={control}
                    rules={validateRules.subject}
                    labelMode="hidden"
                    label={t('label.projectName')}
                    required={true}
                  />
                </div>
              </div>
              <div>
                <CrewFieldLabel text={t('label.scope')} required={true} />
                <div className="flex gap-x-5">
                  <CrewRadioGroupField
                    id="radioId"
                    name="scope"
                    control={control}
                    layout="horizontal"
                    dataSource={projectScopeDataSource}
                    valueExpr="id"
                    displayExpr="name"
                    rules={validateRules.scope}
                    itemRender={ProjectRadioItem}
                    showLabel={false}
                    onValueChanged={(e) => handleProjectScopeChange(e.value)}
                  />
                  {isShowApprovalToJoin && (
                    <CrewCheckBoxField
                      name="approvalToJoin"
                      control={control}
                      rules={validateRules.approvalToJoin}
                      label={t('label.requiredApprovalToJoin')}
                      className="crew-text-default"
                    />
                  )}
                </div>
              </div>
              <div className="flex gap-x-2.5">
                <div className="flex-initial w-56">
                  {/* user id */}
                  <CrewSelectBoxField
                    id="ownerUserId"
                    name="ownerUserId"
                    control={control}
                    dataSource={ownerDataSource}
                    valueExpr="id"
                    displayExpr="displayName"
                    searchEnabled={true}
                    searchExpr="displayName"
                    fieldRender={ProjectOwnerField}
                    itemRender={ProjectOwnerItem}
                    rules={validateRules.ownerUserId}
                    labelMode="hidden"
                    label={t('label.owner')}
                    minSearchLength={0}
                    required={true}
                    showClearButton={false}
                  />
                </div>
                <div className="flex-initial w-72">
                  {/* project group */}
                  <CrewSelectBoxField
                    id="projectGroupId"
                    name="projectGroupId"
                    control={control}
                    dataSource={projectGroupDataSource}
                    valueExpr="id"
                    displayExpr="name"
                    searchTimeout={SEARCH_TIMEOUT_MSEC}
                    labelMode="hidden"
                    label={t('label.projectGroup')}
                    minSearchLength={0}
                    searchExpr="name"
                  />
                </div>
                <div className="flex-initial w-64"></div>
              </div>
              <div>
                {/* 編集モードの際は非表示にしてメンバーの編集を不可にする */}
                {!props.isEditMode && (
                  <CrewTagBoxField
                    id="projectMemberIds"
                    name="projectMemberIds"
                    control={control}
                    displayExpr="displayName"
                    valueExpr="id"
                    dataSource={memberDataSource}
                    searchEnabled={true}
                    searchMode="contains"
                    searchExpr="displayName"
                    searchTimeout={SEARCH_TIMEOUT_MSEC}
                    minSearchLength={0}
                    labelMode="hidden"
                    label={t('label.member')}
                  />
                )}
              </div>
              <div>
                {/* description */}
                <CrewTextAreaField
                  id="description"
                  name="description"
                  control={control}
                  height={150}
                  labelMode="hidden"
                  label={t('label.description')}
                  rules={validateRules.description}
                />
              </div>
              {props.isEditMode && (
                <div className="flex flex-col gap-y-1">
                  {/* Archived */}
                  <CrewCheckBoxField
                    id="archived"
                    name="archived"
                    control={control}
                    rules={validateRules.archived}
                    label={t('label.archiveProject')}
                    className="crew-text-default"
                  />
                  {/* アーカイブした場合の説明内容 */}
                  <CrewFieldDescriptionLabel
                    text={t('label.archiveProjectDescription')}
                  />
                </div>
              )}
            </div>
            <CrewErrorSummary formState={formState} />
          </div>
        </CrewScrollView>
        <div className="flex justify-between items-center">
          {props.isEditMode && hasSysProjectCreateDeletePermission && (
            <CrewButton
              text={t('action.delete')}
              type="danger"
              onClick={handleDeleteButtonClick}
            />
          )}

          <div className="ml-auto flex gap-x-5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              onClick={handleSubmitButtonClick}
              disabled={!canSend}
            />
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={handleCancelButtonClick}
            />
          </div>
        </div>
      </form>
      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </>
  )
})
