import { createUninitializedContext } from '@crew/utils/dist/context'
import { UserType } from '@crew/enums/domain'

export type SelectedUserType = {
  userId: string
  version: number
  userType: UserType | null
}

/** Context用の型 */
type TenantUserListContext = {
  selectedUsers: SelectedUserType[]
  setSelectedUsers: (selectedUsers: SelectedUserType[]) => void
}

/** Context */
const {
  context: tenantUserListContext,
  useInitializedContext: useTenantUserListContext,
} = createUninitializedContext<TenantUserListContext>()

export { useTenantUserListContext }

export const useUserList = () => {
  return {
    tenantUserListContext,
  }
}
