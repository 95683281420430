import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewSortable } from 'components/devextreme/crewSortable'
import { ProjectDetailTaskListKanbanCard } from 'features/project/components/projectDetailPage/components/projectDetailTaskList/components/projectDetailTaskListPanel/components/projectDetailTaskListKanban/components/ProjectDetailTaskListKanbanCard/ProjectDetailTaskListKanbanCard'
import { useProjectDetailTaskListKanban } from 'features/project/components/projectDetailPage/components/projectDetailTaskList/components/projectDetailTaskListPanel/components/projectDetailTaskListKanban/useProjectDetailTaskListKanban'
import { memo, useRef } from 'react'
import { GetProjectTasksRequest } from '@crew/apis/project/models/getProjectTasks/request'
import { useGetLookupTaskStatesQuery } from '@crew/apis/lookup/lookupApis'
import { ProjectTask } from '@crew/apis/project/models/getProjectTasks/response'
import { useGetProjectTasksQuery } from '@crew/apis/project/projectApis'
import { skipToken } from '@reduxjs/toolkit/query'
import type { AddEvent, ReorderEvent } from 'devextreme/ui/sortable'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { getParamAsArray, getParamAsDate, getParamAsString } from 'utils'
import { DetailTaskSearchOptions } from 'enums/app'
import { useShowApiErrors } from 'hooks/useShowApiErrors'

import { EntityType } from '@crew/enums/domain'
import { GetTaskStatesRequest } from '@crew/apis/dist/lookup/models/getTaskStates/request'
import { TaskStateRef } from '@crew/models/refs'
import { useProjectPermissions } from '@crew/hooks'
import { useTranslation } from '@crew/modules/i18n'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'

// Function to remove an item from the tasks array at the specified index.
const removeItem = (tasks: ProjectTask[], removeIdx: number) => {
  // Return a new array excluding the item at removeIdx.
  return tasks.filter((_, idx) => idx !== removeIdx)
}

// Function to insert an item into the tasks array at the specified index.
const insertItem = (
  tasks: ProjectTask[],
  task: ProjectTask,
  insertIdx: number
) => {
  // Create a copy of the tasks array to avoid mutating the original array.
  const newArray = [...tasks]
  // Insert the new task at the specified index.
  newArray.splice(insertIdx, 0, task)

  return newArray
}

// get task lists by task state
const getTaskLists = (states: TaskStateRef[], taskArray: ProjectTask[]) => {
  // Reduce the taskArray to create a mapping of task states to tasks.
  const tasksMap = taskArray.reduce(
    (result: { [key: string]: ProjectTask[] }, task: ProjectTask) => {
      // Check if the task state ID already exists in the result map.
      if (result[task.taskState.id]) {
        // If it exists, push the task to the existing array.
        result[task.taskState.id].push(task)
      } else {
        // If it doesn't exist, create a new array with the task.
        result[task.taskState.id] = [task]
      }

      return result
    },
    {}
  )

  // Map the states array to an array of task lists.
  return states.map((status: TaskStateRef) => tasksMap[status.id] || [])
}

export const ProjectDetailTaskListKanban = memo(() => {
  const { t } = useTranslation()

  const [showApiError] = useShowApiErrors()

  const { updateTaskState } = useProjectDetailTaskListKanban()

  const [searchParams] = useSearchParams()
  const params = qs.parse(searchParams.toString())
  const { projectId } = useParams()

  const { hasPrjTaskEditPermission } = useProjectPermissions(
    EntityType.Project,
    projectId
  )

  const [isTaskEntryDialogOpen, openTaskEntryDialog, closeTaskEntryDialog] =
    useModal()

  const getLookupTaskStatesParams: GetTaskStatesRequest | undefined = projectId
    ? {
        entityType: EntityType.Project,
        entityRecordId: projectId,
        name: undefined,
        taskStateIds: undefined,
      }
    : undefined
  // タスク状態取得（ボード構成用）
  const { data: getLookupTaskStatesResult } = useGetLookupTaskStatesQuery(
    getLookupTaskStatesParams ?? skipToken
  )

  // プロジェクトタスク一覧取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getProjectTasksParams: GetProjectTasksRequest | undefined = projectId
    ? {
        projectId,
        keyword: getParamAsString(DetailTaskSearchOptions.Keyword.id, params),
        taskKindIds: getParamAsArray(
          DetailTaskSearchOptions.TaskKindId.id,
          params
        ),
        assignToUser: getParamAsString(
          DetailTaskSearchOptions.AssignToUser.id,
          params
        ),
        taskStateIds: getParamAsArray(
          DetailTaskSearchOptions.TaskStateId.id,
          params
        ),
        taskStateTypes: getParamAsArray(
          DetailTaskSearchOptions.TaskStateType.id,
          params
        ),
        taskPriorities: getParamAsArray(
          DetailTaskSearchOptions.TaskPriority.id,
          params
        )?.map((taskPriority) => Number(taskPriority)), // string[] -> number[]
        taskCategoryIds: getParamAsArray(
          DetailTaskSearchOptions.TaskCategoryId.id,
          params
        ),
        startDate: getParamAsDate(DetailTaskSearchOptions.StartDate.id, params),
        dueDate: getParamAsDate(DetailTaskSearchOptions.DueDate.id, params),
        createdById: getParamAsString(
          DetailTaskSearchOptions.CreatedById.id,
          params
        ),
        updatedById: getParamAsString(
          DetailTaskSearchOptions.UpdatedById.id,
          params
        ),
        createdAt: getParamAsDate(DetailTaskSearchOptions.CreatedAt.id, params),
        updatedAt: getParamAsDate(DetailTaskSearchOptions.UpdatedAt.id, params),
        targetDateFrom: undefined,
        targetDateTo: undefined,
      }
    : undefined

  const { data: getProjectTasksResult, refetch: getProjectTasksRefetch } =
    useGetProjectTasksQuery(getProjectTasksParams ?? skipToken)

  // プロジェクトタスク一覧格納用ステータス
  // NOTE: ボード間のドラッグによるカンバン移動後に一覧を再構成し、それを格納するためuseStateが必要
  const [taskList, setTaskList] = useState<Array<ProjectTask[]>>([])

  // setTaskListでタスク一覧を格納するためuseEffectが必要
  useEffect(() => {
    // タスク状態（ボード）別にタスク一覧を配列化
    if (getProjectTasksResult?.tasks && getLookupTaskStatesResult?.taskStates) {
      setTaskList(
        getTaskLists(
          getLookupTaskStatesResult.taskStates,
          getProjectTasksResult.tasks
        )
      )
    }
  }, [getLookupTaskStatesResult?.taskStates, getProjectTasksResult?.tasks])

  // refetch tasks data
  const refetchProjectTasks = useCallback(() => {
    getProjectTasksRefetch()
  }, [getProjectTasksRefetch])

  // Handle event task item is dropped
  const handleTaskItemDrop = useCallback(
    async (event: ReorderEvent | AddEvent) => {
      if (!getLookupTaskStatesResult?.taskStates) return

      const updatedLists = [...taskList]

      // get item from the source list
      const item = updatedLists[event.fromData][event.fromIndex]

      // remove item from the source list
      updatedLists[event.fromData] = removeItem(
        updatedLists[event.fromData],
        event.fromIndex
      )

      // insert item to the destination list
      updatedLists[event.toData] = insertItem(
        updatedLists[event.toData],
        {
          ...item,
          taskState: getLookupTaskStatesResult.taskStates[event.toData], // update task state
          // increment version if the task is dropped in the different list
          version:
            event.fromData === event.toData ? item.version : item.version + 1, // increment version
        },
        event.toIndex
      )

      setTaskList(updatedLists)

      // Do nothing if the task is dropped in the same list
      if (event.fromData === event.toData) return

      // Execute update task state process
      try {
        await updateTaskState(
          item.id,
          getLookupTaskStatesResult.taskStates[event.toData].id,
          item.version
        )
      } catch (error) {
        // refetch tasks data when error
        refetchProjectTasks()

        showApiError(error)
        return
      }
    },
    [
      getLookupTaskStatesResult?.taskStates,
      refetchProjectTasks,
      showApiError,
      taskList,
      updateTaskState,
    ]
  )

  const editTaskId = useRef<string>()
  const handleEditTask = useCallback(
    (taskId: string) => {
      editTaskId.current = taskId
      openTaskEntryDialog()
    },
    [openTaskEntryDialog]
  )

  // タスク編集ダイアログの編集完了後
  const handleTaskUpdated = useCallback(() => {
    closeTaskEntryDialog()

    // refetch tasks data
    refetchProjectTasks()
  }, [closeTaskEntryDialog, refetchProjectTasks])

  // タスク編集ダイアログの削除完了後
  const handleTaskDeleted = useCallback(() => {
    closeTaskEntryDialog()

    // refetch tasks data
    refetchProjectTasks()
  }, [closeTaskEntryDialog, refetchProjectTasks])

  return (
    <>
      <CrewScrollView direction="horizontal" showScrollbar="always">
        <CrewSortable itemOrientation="horizontal" handle=".list-title">
          <div className="flex gap-x-3.5 p-2">
            {taskList.map((tasks, index) => {
              const state = getLookupTaskStatesResult?.taskStates[index]
              return (
                <div
                  key={state?.id}
                  className="crew-task-list-kanban w-80 bg-crew-gray-2-light dark:bg-crew-gray-4-dark rounded-xl"
                >
                  <div className="my-2.5 text-center">{state?.name}</div>
                  <CrewScrollView
                    className="py-2.5"
                    direction="vertical"
                    showScrollbar="never"
                  >
                    {/* 列の最小幅を制限し、タスク一覧が正しく表示されるようにする */}
                    <CrewSortable
                      className="min-h-[50vh] flex flex-col gap-y-1.5 mx-2.5"
                      group="cardsGroup"
                      data={index}
                      onReorder={handleTaskItemDrop}
                      onAdd={handleTaskItemDrop}
                    >
                      {tasks.map((task) => (
                        <ProjectDetailTaskListKanbanCard
                          key={task.id}
                          item={task}
                          hasPrjTaskEditPermission={hasPrjTaskEditPermission}
                          onEditTask={handleEditTask}
                        />
                      ))}
                    </CrewSortable>
                  </CrewScrollView>
                </div>
              )
            })}
          </div>
        </CrewSortable>
      </CrewScrollView>

      <TaskEntryDialog
        isEditMode={true}
        title={t('label.editTaskTitle')}
        onSubmit={handleTaskUpdated}
        onDeleted={handleTaskDeleted}
        isOpen={isTaskEntryDialogOpen}
        onClose={closeTaskEntryDialog}
        taskId={editTaskId.current}
        projectId={projectId}
      />
    </>
  )
})
