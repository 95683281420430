import { Modal } from 'components/layouts/modal/modal'
import { ProjectGroupEntryForm } from './components/projectGroupEntryForm/projectGroupEntryForm'
import { useCallback } from 'react'
import { FC, memo } from 'react'

export type ProjectGroupDialogProps = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  projectGroupId?: string
}

export const ProjectGroupEntryDialog: FC<ProjectGroupDialogProps> = memo(
  (props) => {
    // 登録ボタン押下
    const handleSubmitButtonClick = useCallback(() => {
      props.onClose()
    }, [props])

    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <ProjectGroupEntryForm
          isEditMode={props.isEditMode}
          projectGroupId={props.projectGroupId}
          onSubmit={handleSubmitButtonClick}
          onClose={props.onClose}
        />
      </Modal>
    )
  }
)
