import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { NumberBox } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof NumberBox>>

export const CrewNumberBox = memo(
  forwardRef<NumberBox, Props>(({ children, ...rest }, ref) => (
    <NumberBox ref={ref} {...rest}>
      {children}
    </NumberBox>
  ))
)
