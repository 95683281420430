import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { Modal } from 'components/layouts/modal/modal'
import { FC, memo, useCallback, useRef } from 'react'
import { CrewFolderList } from 'components/elements/crewFolderList/crewFolderList'
import { EntityType } from '@crew/enums/domain'
import { useParams } from 'react-router-dom'
import { UnsortedFolder } from 'enums/app'

type ProjectDetailFileMoveDialogProps = {
  isOpen: boolean
  onClose: () => void
  onMove: (folderId: string) => void
}

export const ProjectDetailFileMoveDialog: FC<ProjectDetailFileMoveDialogProps> =
  memo((props) => {
    const { t } = useTranslation()
    const { projectId } = useParams()

    const selectedFolderRef = useRef<string>(UnsortedFolder.value)

    // Event handler for the move button
    const handleMoveButtonClick = useCallback(() => {
      props.onMove(selectedFolderRef.current)

      // Close the dialog
      props.onClose()
    }, [props])

    // Event handler for clicking a folder item
    const handleFolderItemClick = useCallback((folderId: string) => {
      selectedFolderRef.current = folderId
    }, [])

    return (
      <Modal
        title={t('label.movingFiles')}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        {/* Limit the minimum width of the modal to ensure the folder list is displayed correctly  */}
        <div className="flex flex-col gap-2.5 min-w-[600px]">
          <div className="grow flex flex-col gap-2.5">
            <p>{t('label.selectTheDestinationFolder')}</p>

            {projectId && (
              <CrewFolderList
                entityType={EntityType.Project}
                entityRecordId={projectId}
                showContextMenu={false}
                onFolderItemClick={handleFolderItemClick}
                disableDragAndDrop={true}
              />
            )}
          </div>

          <div className="flex gap-2.5 justify-end">
            {/* 移動 */}
            <CrewButton
              text={t('action.move')}
              type="primary"
              onClick={handleMoveButtonClick}
            />

            {/* キャンセル */}
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={props.onClose}
            />
          </div>
        </div>
      </Modal>
    )
  })
