import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useContractUpgradePlanPage } from './useContractUpgradePlanPage'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { PlanInterval, ContractPlan } from '@crew/enums/app'
import { useSystemPermissions } from '@crew/hooks'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useGetUpgradePlanQuery } from '@crew/apis/dist/contract/contractApis'
import { CrewRadioGroup } from 'components/devextreme/crewRadioGroup'
import { ComponentCallbackHandler } from '@crew/utils'
import { NextPlan } from '@crew/apis/dist/contract/models/getUpgradePlan/response'

// 選択対象のプランの型
type SelectPlan = NextPlan & {
  name: string
}

/**
 * Contract Upgrade Plan Page
 * @date 8/16/2023 - 9:14:14 AM
 *
 * @type {*}
 */
export const ContractUpgradePlanPage = memo(() => {
  const { updatePlan } = useContractUpgradePlanPage()

  const { historyBack, navigate } = useCrewNavigate()
  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrors()

  const [selectedPlan, setSelectedPlan] = useState<ContractPlan | undefined>(
    undefined
  )
  const [isUpgrade, setIsUpgrade] = useState<boolean>(false)

  // go back prev screen
  const handleCancelButtonClick = useCallback(() => {
    historyBack()
  }, [historyBack])

  const { data: getUpgradePlanData, isFetching } = useGetUpgradePlanQuery()

  // 変更対象のプラン一覧
  const nextPlans = useMemo(() => {
    if (!getUpgradePlanData) {
      return []
    }

    return Object.values(getUpgradePlanData.nextPlans).map((item) => {
      const plan: SelectPlan = {
        ...item,
        name: t(`label.planType.${item.contractPlan}`) + t(`label.plan`),
      }
      return plan
    })
  }, [getUpgradePlanData, t])

  // 変更後のプラン取得時、ラジオボタンの初期値を設定
  useEffect(() => {
    setSelectedPlan(getUpgradePlanData?.nextPlans[0]?.contractPlan)
  }, [getUpgradePlanData?.nextPlans])

  // ラジオボタンのプラン変更時
  const handleSelectPlanChanged = useCallback<
    ComponentCallbackHandler<typeof CrewRadioGroup, 'onValueChanged'>
  >(
    (event) => {
      let selectedPlanInfo = nextPlans.find(
        (item) => item.contractPlan === event.value
      )

      // 選択中のプラン情報が取得できない場合はないが、型制約の関係でチェック処理を入れておく
      if (!selectedPlanInfo) {
        return
      }

      // 選択中のプラン、アップグレードか否か、を更新
      setSelectedPlan(event.value)
      setIsUpgrade(selectedPlanInfo.isUpgrade)
    },
    [nextPlans]
  )

  // 「変更する」ボタン押下時
  const handleChangePlanButtonClick = useCallback(async () => {
    if (selectedPlan) {
      try {
        // プランを更新する
        await updatePlan(selectedPlan)

        success(t('message.contract.upgradePlanSuccess'))
        // go contract portal page
        navigate('/contract/portal')
      } catch (error) {
        showApiErrors(error)
      }
    }
  }, [selectedPlan, updatePlan, success, t, navigate, showApiErrors])

  const { hasSysContractViewEditPermission } = useSystemPermissions()

  // ラジオボタンのプラン情報のレンダリング
  const renderRadioGroupItem = useCallback(
    (item: SelectPlan) => {
      return (
        <div
          id={`radioId-${item.contractPlan}`}
          className="w-full h-10 justify-start items-center inline-flex"
        >
          {/* プラン名 */}
          <div className="w-40 justify-start items-center gap-2.5 flex">
            <div className="crew-text-gray-4 text-md ">
              {!isFetching ? item.name : '-'}
            </div>
          </div>
          {/* 金額 */}
          <div className="w-52 justify-end items-center gap-2.5 flex">
            <div className=" text-blue-500 text-md ">
              {!isFetching
                ? `${item.amount.toLocaleString()}${
                    item.planInterval === PlanInterval.PlanIntervalMonth
                      ? t('label.priceMonth')
                      : t('label.priceAnnual')
                  }`
                : '-'}
            </div>
          </div>
        </div>
      )
    },
    [isFetching, t]
  )

  return hasSysContractViewEditPermission ? (
    <div className="flex-col justify-start items-center inline-flex">
      <div className="flex-col justify-start items-start gap-2.5 flex w-full max-w-2xl">
        <div className="py-2.5 flex-col justify-start items-start gap-2.5 flex">
          <span className="text-md font-bold">
            {t('label.contractPlanChange')}
          </span>
          <div className="flex-col justify-start items-start inline-flex pt-3">
            {/* 現在 */}
            <div className="justify-start items-center gap-2.5 inline-flex">
              <div className="w-24 justify-start items-center flex">
                <div className="crew-text-gray-4 text-md ">
                  {t('label.contractNow')}
                </div>
              </div>
              {/* プラン名 */}
              <div className="w-40 justify-start items-center gap-2.5 flex">
                <div className="crew-text-gray-4 text-md">
                  {!isFetching && getUpgradePlanData
                    ? getUpgradePlanData.currentPlan.contractPlan ===
                      ContractPlan.Professional
                      ? t('label.planType.professional')
                      : t('label.planType.standard')
                    : '-'}
                </div>
              </div>
              {/* 金額 */}
              <div className="w-52 justify-end items-center gap-2.5 flex ml-5">
                <div className=" text-blue-500 text-md ">
                  {!isFetching && getUpgradePlanData
                    ? `${getUpgradePlanData.currentPlan.amount.toLocaleString()}${
                        getUpgradePlanData.currentPlan.planInterval ===
                        PlanInterval.PlanIntervalMonth
                          ? t('label.priceMonth')
                          : t('label.priceAnnual')
                      }`
                    : '-'}
                </div>
              </div>
            </div>

            {/* 変更後 */}
            <div className="justify-start items-center gap-2.5 inline-flex">
              <div className="w-24 justify-start items-center gap-2.5 flex">
                <div className="crew-text-gray-4 text-md ">
                  {t('label.contractAfterChange')}
                </div>
              </div>

              <CrewRadioGroup
                layout="vertical"
                disabled={isFetching} // フェッチ中は選択不可
                dataSource={nextPlans}
                valueExpr="contractPlan"
                displayExpr="name"
                value={selectedPlan}
                itemRender={renderRadioGroupItem}
                onValueChanged={handleSelectPlanChanged}
              />
            </div>

            {/* 変更内容のメッセージ表示 */}
            <div className="h-12 text-crew-red-3-light dark:text-crew-red-3-dark">
              {!isFetching && selectedPlan && (
                <div>
                  {isUpgrade
                    ? t('message.contract.upgradePlanInfo')
                    : t('message.contract.downgradePlanInfo')}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="justify-start items-start gap-2.5 inline-flex">
          {/* 変更するボタン */}
          <CrewButton
            disabled={selectedPlan === undefined || isFetching}
            text={t('action.change')}
            type="primary"
            className="mr-auto"
            onClick={handleChangePlanButtonClick}
          />
          {/* Cancel button */}
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            className="mr-auto"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
})
