import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { DateBox } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof DateBox>>

export const CrewDateBox = memo(
  forwardRef<DateBox, Props>(({ children, ...rest }, ref) => (
    <DateBox ref={ref} {...rest}>
      {children}
    </DateBox>
  ))
)
