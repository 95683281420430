import { useLazyGetLookupProjectMembersQuery } from '@crew/apis/lookup/lookupApis'
import { useDataSource } from './useDataSource'
import { MAX_INITIAL_RECORDS } from 'configs/constants'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'

export const useProjectMemberDataSource = (projectId: string | undefined) => {
  const [lazyGetProjectMembersQuery] = useLazyGetLookupProjectMembersQuery()

  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!projectId) return []

        if (!loadOptions.searchValue && !loadOptions.filter) {
          // インクリメンタルサーチ
          const response = await lazyGetProjectMembersQuery({
            projectId,
            keyword: undefined,
            userId: undefined,
            excludeUserId: undefined,
            limit: MAX_INITIAL_RECORDS,
            isEnabledUser: true,
          }).unwrap()

          return response.projectMembers
        }

        if (loadOptions.searchValue) {
          // インクリメンタルサーチ
          const response = await lazyGetProjectMembersQuery({
            projectId: projectId as string,
            keyword: loadOptions.searchValue,
            userId: undefined,
            excludeUserId: undefined,
            isEnabledUser: true,
          }).unwrap()

          return response.projectMembers
        } else if (loadOptions.filter) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // 選択したユーザIDのフィルタリング
          const filteringUserIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetProjectMembersQuery({
            projectId,
            keyword: undefined,
            userId: filteringUserIds,
            excludeUserId: undefined,
            isEnabledUser: true,
          }).unwrap()

          return response.projectMembers
        }

        return []
      },
    }),
    [lazyGetProjectMembersQuery, projectId]
  )
}
