import { CrewLoadingEffect } from 'components/elements/crewLoading/crewLoadingEffect/crewLoadingEffect'
import { ComponentProps, FC, memo } from 'react'

/**
 * テキストのロード中表示を行うコンポーネント。テキストの代わりに設置する
 */
export const CrewLoadingText: FC<ComponentProps<'div'>> = memo((props) => (
  <div {...props}>
    <div className="p-0.5 w-full h-full">
      <div className="w-full h-full rounded overflow-hidden">
        <CrewLoadingEffect />
      </div>
    </div>
  </div>
))
