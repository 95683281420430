import { FC, memo, useMemo, useCallback } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useTranslation } from '@crew/modules/i18n'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { useAppForgotPasswordPage } from './useForgotPasswordPage'
import { useAppSelector } from 'states/hooks'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { FormValues } from './useForgotPasswordPage'
export const AppForgotPasswordPage: FC = memo(() => {
  const {
    control,
    handleSubmit,
    formState,
    reset,
    validateRules,
    resetPassword,
  } = useAppForgotPasswordPage()

  const { t } = useTranslation()
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  const { success } = useToast()

  const [showApiErrors] = useShowApiErrors()

  // handle send email reset password
  const handleFormSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      // stop form submit redirect
      e.preventDefault()

      const onSubmit = async (values: FormValues) => {
        try {
          await resetPassword(values.loginIdOrEmailAddress)

          success(t('message.resetPassword.sendMailSuccess'))
          // clear form data
          reset()
        } catch (error) {
          showApiErrors(error)
        }
      }

      handleSubmit(onSubmit)()
    },
    [handleSubmit, resetPassword, success, t, reset, showApiErrors]
  )

  const canSend = useMemo(
    // formState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () => formState.isDirty && Object.keys(formState.errors).length === 0,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState]
  )

  return (
    // Reset password form
    <div className="flex flex-row h-screen pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-sm">
        <div className="flex flex-row items-center sm:mb-6">
          {/* ロゴ */}
          <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto" />
        </div>
        <form onSubmit={handleFormSubmit} className="flex flex-col gap-2.5">
          <div className="flex flex-col gap-2.5">
            <div className="flex flex-col gap-1">
              <p className="text-center">
                {t('message.auth.enterYourLoginIdOrEmail')}
              </p>
              <p className="text-center">
                {t('message.auth.sendEmailToResetPassword')}
              </p>
            </div>
            {/* Login ID or email address */}
            <CrewTextBoxField
              id="loginIdOrEmailAddress"
              name="loginIdOrEmailAddress"
              control={control}
              placeholder={t('label.userIdOrEmailAddress')}
              labelMode="floating"
              rules={validateRules.loginIdOrEmailAddress}
              inputAttr={{
                'data-testid': 'username',
              }}
              showLabel={false}
            />
          </div>
          {/* Send email button */}
          <div className="flex flex-row justify-center">
            <CrewButton
              className="grow"
              type="primary"
              useSubmitBehavior={true}
              disabled={!canSend}
              data-testid="send-email"
              text={t('action.sendEmail')}
            />
          </div>
        </form>
      </div>
    </div>
  )
})
