import { Device, isAudioTransformDevice } from 'amazon-chime-sdk-js'
import { useMeetingManager } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { useLogger } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/LoggerProvider'
import { useVoiceFocus } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/VoiceFocusProvider'
import { FC, useCallback, useState } from 'react'
import { createUninitializedContext } from '@crew/utils/context'

type InnerProps = BaseSdkProps & { children?: React.ReactNode }

interface MicrophoneBindVoiceFocusType {
  isVoiceFocusEnabled: boolean
  setIsVoiceFocusEnabled: any
  currentAudioDevice: Device | undefined | null
  setCurrentAudioDevice: any
  setStartDeviceAndAddVoiceFocusByDevice: any
  setStartDeviceAndAddVoiceFocusByVoiceFocus: any
}

const {
  context: microphoneBindVoiceFocusContext,
  useInitializedContext: useMicrophoneBindVoiceFocus,
} = createUninitializedContext<MicrophoneBindVoiceFocusType>()

// オーディオ入力デバイスの選択とノイズ除去ON/OFFタイミングで
// デバイスの切換えと、VoiceFocusの挟み込みをいいようにやってくれるProvider
const MicrophoneBindVoiceFocusProvider: FC<InnerProps> = ({ children }) => {
  const logger = useLogger()
  const meetingManager = useMeetingManager()
  const { isVoiceFocusSupported, addVoiceFocus } = useVoiceFocus()
  const [isVoiceFocusEnabled, setIsVoiceFocusEnabled] = useState(true)

  // 現在のオーディオデバイス
  // selectedDevice はそのままオーディオソースとしては利用できない、オーディオ変換デバイス(AudioTransformDevice)を指す場合があるため
  const [currentAudioDevice, setCurrentAudioDevice] = useState<
    Device | undefined | null
  >(undefined)

  // オーディオデバイスにノイズ除去設定を行う
  const setInternalStartDeviceAndAddVoiceFocus = useCallback(
    async (
      useVoiceFocus: boolean,
      currentDevice: Device | null | undefined
    ) => {
      if (!currentDevice) {
        logger.error('No device selected.')
        return
      }

      let newDevice = currentDevice
      // 現在cdkに接続されているオーディオ入力デバイスがオーディオ変換デバイスの場合、そのまま活かす
      if (isAudioTransformDevice(currentDevice)) {
        newDevice = await currentDevice.intrinsicDevice()
      }

      try {
        // VoiceFocusをサポートしている場合かつノイズ除去チェックON
        if (useVoiceFocus && isVoiceFocusSupported) {
          // VoiceFocus を使用する
          const currentDevice = await addVoiceFocus(newDevice)
          await meetingManager.startAudioInputDevice(currentDevice)
        } else {
          // VoiceFocus を使用しない
          await meetingManager.startAudioInputDevice(newDevice)
        }
      } catch (error) {
        logger.error('MicrophoneSelection failed to select microphone.')
      }
    },
    [addVoiceFocus, isVoiceFocusSupported, logger, meetingManager]
  )

  // ノイズ除去ON/OFF(ON/OFFの値は外部から取得)
  const setStartDeviceAndAddVoiceFocusByVoiceFocus = useCallback(
    (value: boolean) => {
      setInternalStartDeviceAndAddVoiceFocus(value, currentAudioDevice)
    },
    [currentAudioDevice, setInternalStartDeviceAndAddVoiceFocus]
  )

  // Device変更時（選択されたデバイスの値は外部から取得）
  const setStartDeviceAndAddVoiceFocusByDevice = useCallback(
    (value: Device | null | undefined) => {
      setInternalStartDeviceAndAddVoiceFocus(isVoiceFocusEnabled, value)
    },
    [isVoiceFocusEnabled, setInternalStartDeviceAndAddVoiceFocus]
  )

  const value: MicrophoneBindVoiceFocusType = {
    isVoiceFocusEnabled,
    setIsVoiceFocusEnabled,
    currentAudioDevice,
    setCurrentAudioDevice,
    setStartDeviceAndAddVoiceFocusByDevice,
    setStartDeviceAndAddVoiceFocusByVoiceFocus,
  }

  return (
    <microphoneBindVoiceFocusContext.Provider value={value}>
      {children}
    </microphoneBindVoiceFocusContext.Provider>
  )
}

export { MicrophoneBindVoiceFocusProvider, useMicrophoneBindVoiceFocus }
