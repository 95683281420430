import { useTranslation } from '@crew/modules/dist/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { memo, useCallback, useEffect, useMemo } from 'react'
import {
  FormValues,
  useContractChangeBillingAddressPage,
} from './useContractChangeBillingAddressPage'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useGetBillingInformationQuery } from '@crew/apis/dist/contract/contractApis'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { useToast } from 'hooks/useToast'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'

// 都道府県リスト
// Stripeへ住所登録時は日本語で登録する必要があるため、valueは言語設定に関わらず日本語にする
const StateList = {
  Hokkaido: {
    text: 'label.stateList.hokkaido',
    value: '北海道',
  },
  Aomori: {
    text: 'label.stateList.aomori',
    value: '青森県',
  },
  Iwate: {
    text: 'label.stateList.iwate',
    value: '岩手県',
  },
  Miyagi: {
    text: 'label.stateList.miyagi',
    value: '宮城県',
  },
  Akita: {
    text: 'label.stateList.akita',
    value: '秋田県',
  },
  Yamagata: {
    text: 'label.stateList.yamagata',
    value: '山形県',
  },
  Fukushima: {
    text: 'label.stateList.fukushima',
    value: '福島県',
  },
  Ibaraki: {
    text: 'label.stateList.ibaraki',
    value: '茨城県',
  },
  Tochigi: {
    text: 'label.stateList.tochigi',
    value: '栃木県',
  },
  Gunma: {
    text: 'label.stateList.gunma',
    value: '群馬県',
  },
  Saitama: {
    text: 'label.stateList.saitama',
    value: '埼玉県',
  },
  Chiba: {
    text: 'label.stateList.chiba',
    value: '千葉県',
  },
  Tokyo: {
    text: 'label.stateList.tokyo',
    value: '東京都',
  },
  Kanagawa: {
    text: 'label.stateList.kanagawa',
    value: '神奈川県',
  },
  Niigata: {
    text: 'label.stateList.niigata',
    value: '新潟県',
  },
  Toyama: {
    text: 'label.stateList.toyama',
    value: '富山県',
  },
  Ishikawa: {
    text: 'label.stateList.ishikawa',
    value: '石川県',
  },
  Fukui: {
    text: 'label.stateList.fukui',
    value: '福井県',
  },
  Yamanashi: {
    text: 'label.stateList.yamanashi',
    value: '山梨県',
  },
  Nagano: {
    text: 'label.stateList.nagano',
    value: '長野県',
  },
  Gifu: {
    text: 'label.stateList.gifu',
    value: '岐阜県',
  },
  Shizuoka: {
    text: 'label.stateList.shizuoka',
    value: '静岡県',
  },
  Aichi: {
    text: 'label.stateList.aichi',
    value: '愛知県',
  },
  Mie: {
    text: 'label.stateList.mie',
    value: '三重県',
  },
  Shiga: {
    text: 'label.stateList.shiga',
    value: '滋賀県',
  },
  Kyoto: {
    text: 'label.stateList.kyoto',
    value: '京都府',
  },
  Osaka: {
    text: 'label.stateList.osaka',
    value: '大阪府',
  },
  Hyogo: {
    text: 'label.stateList.hyogo',
    value: '兵庫県',
  },
  Nara: {
    text: 'label.stateList.nara',
    value: '奈良県',
  },
  Wakayama: {
    text: 'label.stateList.wakayama',
    value: '和歌山県',
  },
  Tottori: {
    text: 'label.stateList.tottori',
    value: '鳥取県',
  },
  Shimane: {
    text: 'label.stateList.shimane',
    value: '島根県',
  },
  Okayama: {
    text: 'label.stateList.okayama',
    value: '岡山県',
  },
  Hiroshima: {
    text: 'label.stateList.hiroshima',
    value: '広島県',
  },
  Yamaguchi: {
    text: 'label.stateList.yamaguchi',
    value: '山口県',
  },
  Tokushima: {
    text: 'label.stateList.tokushima',
    value: '徳島県',
  },
  Kagawa: {
    text: 'label.stateList.kagawa',
    value: '香川県',
  },
  Ehime: {
    text: 'label.stateList.ehime',
    value: '愛媛県',
  },
  Kochi: {
    text: 'label.stateList.kochi',
    value: '高知県',
  },
  Fukuoka: {
    text: 'label.stateList.fukuoka',
    value: '福岡県',
  },
  Saga: {
    text: 'label.stateList.saga',
    value: '佐賀県',
  },
  Nagasaki: {
    text: 'label.stateList.nagasaki',
    value: '長崎県',
  },
  Kumamoto: {
    text: 'label.stateList.kumamoto',
    value: '熊本県',
  },
  Oita: {
    text: 'label.stateList.oita',
    value: '大分県',
  },
  Miyazaki: {
    text: 'label.stateList.miyazaki',
    value: '宮崎県',
  },
  Kagoshima: {
    text: 'label.stateList.kagoshima',
    value: '鹿児島県',
  },
  Okinawa: {
    text: 'label.stateList.okinawa',
    value: '沖縄県',
  },
} as const

export const ContractChangeBillingAddressPage = memo(() => {
  const {
    control,
    validateRules,
    handleSubmit,
    setError,
    reset,
    updateBillingInformation,
  } = useContractChangeBillingAddressPage()
  const { t } = useTranslation()
  const toast = useToast()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const { historyBack, navigate } = useCrewNavigate()

  const { data: getBillingInformationResult, isFetching } =
    useGetBillingInformationQuery()

  // 都道府県リスト
  const stateList = useMemo(() => {
    return Object.values(StateList).map((item) => {
      return {
        value: item.value,
        text: t(item.text),
      }
    })
  }, [t])

  // フォーム初期化処理関数
  const initializeForm = useCallback(async () => {
    if (getBillingInformationResult) {
      reset({
        companyName: getBillingInformationResult.name,
        postalCode: getBillingInformationResult.address.postalCode,
        state: getBillingInformationResult.address.state,
        city: getBillingInformationResult.address.city,
        line1: getBillingInformationResult.address.line1,
        line2: getBillingInformationResult.address.line2,
      })
    }
  }, [getBillingInformationResult, reset])

  // フォーム初期化処理
  useEffect(() => {
    initializeForm()
  }, [initializeForm])

  // 登録するボタン押下時
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // 請求先情報は必ず取得できる想定だが、型エラーが発生するためチェック処理実行
        if (!getBillingInformationResult) return

        // 請求先情報を更新
        await updateBillingInformation(
          data,
          getBillingInformationResult.address.country
        )

        toast.success(t('message.contract.registerBillingInformationSuccess'))

        // 契約情報画面に遷移
        navigate('/contract/portal')
      } catch (error) {
        showApiErrors(error)
      }
    }

    // 請求先情報を更新
    handleSubmit(onSubmit)()
  }, [
    getBillingInformationResult,
    handleSubmit,
    navigate,
    showApiErrors,
    toast,
    t,
    updateBillingInformation,
  ])

  // キャンセルボタンクリック時
  const handleCancelButtonClick = useCallback(() => {
    // 前のページに戻る
    historyBack()
  }, [historyBack])

  return (
    <form className="flex-col justify-start items-center inline-flex">
      <div className="flex-col justify-start items-start gap-2.5 flex w-full max-w-2xl">
        <div className="py-2.5 w-full flex-col justify-start items-start gap-2.5 flex">
          {/* タイトル */}
          <span className="text-md font-bold">
            {t('label.changeBillingAddress')}
          </span>

          {/* 会社名 */}
          <div className="w-full">
            <CrewTextBoxField
              id="companyName"
              name="companyName"
              control={control}
              label={t('label.companyName')}
              rules={validateRules.companyName}
              required
            />
          </div>
          {/* 郵便番号 */}
          <div className="w-full">
            <CrewTextBoxField
              id="postalCode"
              name="postalCode"
              control={control}
              label={t('label.postalCode')}
              rules={validateRules.postalCode}
            />
          </div>
          {/* 都道府県 */}
          <div className="w-full">
            <CrewSelectBoxField
              control={control}
              id="state"
              name="state"
              label={t('label.stateProvince')}
              items={stateList}
              valueExpr="value"
              displayExpr="text"
              minSearchLength={0}
              showClearButton={false}
              rules={validateRules.state}
            />
          </div>
          {/* 市区町村 */}
          <div className="w-full">
            <CrewTextBoxField
              id="city"
              name="city"
              control={control}
              label={t('label.city')}
              rules={validateRules.city}
            />
          </div>
          {/* 住所1 */}
          <div className="w-full">
            <CrewTextBoxField
              id="line1"
              name="line1"
              control={control}
              label={t('label.line1')}
              rules={validateRules.line1}
            />
          </div>
          {/* 住所2 */}
          <div className="w-full">
            <CrewTextBoxField
              id="line2"
              name="line2"
              control={control}
              label={t('label.line2')}
              rules={validateRules.line2}
            />
          </div>
        </div>

        <div className="justify-start items-start gap-2.5 inline-flex">
          {/* 登録ボタン */}
          <CrewButton
            text={t('action.register2')}
            type="primary"
            className="mr-auto"
            onClick={handleSubmitButtonClick}
            disabled={isFetching}
          />
          {/* キャンセルボタン */}
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            className="mr-auto"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </form>
  )
})
