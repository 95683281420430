import { FC, memo, useEffect, useRef } from 'react'

import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { useLocalVideo } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'

import { VideoTile } from 'components/chime/components/videoTile'
import classNames from 'classnames'

type Props = BaseSdkProps & {
  /**
   * Video tileのelement id
   */
  id?: string
  /**
   * 表示する名前
   */
  name?: string
  /**
   * Id member attendee
   */
  chimeAttendeeId?: string
}

/**
 * ローカルビデオを表示するビデオタイル
 */
export const CrewLocalVideoTile: FC<Props> = memo(
  ({ id, className, chimeAttendeeId, ...rest }) => {
    const { tileId, isVideoEnabled } = useLocalVideo()

    const audioVideo = useAudioVideo()
    const videoElementRef = useRef<HTMLVideoElement>(null)

    // 以下条件の場合、既存のstreamをcloneしてvideoElementのsrcObjectへ設定する
    //  ・audioVideoが初期化済み
    //  ・対象のvideoElementが存在する
    //  ・tileIdが設定済み
    //
    // 以下を参考にした
    // https://github.com/aws/amazon-chime-sdk-js/issues/976#issuecomment-754261685
    if (audioVideo && videoElementRef.current && tileId) {
      //
      const currentTile = audioVideo.getVideoTile(tileId)
      const clonedStream = currentTile?.state().boundVideoStream?.clone()
      if (clonedStream) {
        videoElementRef.current.srcObject = clonedStream
        videoElementRef.current.play()
      }
    }

    useEffect(() => {
      if (
        !audioVideo ||
        !tileId ||
        !videoElementRef.current ||
        !isVideoEnabled
      ) {
        return
      }

      audioVideo.startLocalVideoTile()
      audioVideo.bindVideoElement(tileId, videoElementRef.current)
      const videoElement = videoElementRef.current

      return () => {
        const tile = audioVideo.getVideoTile(tileId)
        if (tile) {
          // TODO: 以下のunbindVideoElementを行わないことで、会議開始時にダイアログ内でカメラON状態でもカメラを起動した状態で会議を開始できたが、
          // 「会議開始後に設定ダイアログでカメラON・OFFを切り替えると参加者一覧側の映像が表示されなくなる」と「ぼかしOFF時の処理が遅い」という課題が残っている
          // https://break-tmc.atlassian.net/browse/CREW-6232

          // 以下を参考に、unbindVideoElementは行わずsrcObjectにnullをセットした
          // https://github.com/aws/amazon-chime-sdk-js/issues/627#issuecomment-736107449
          // unbindVideoElementをはずすことでダイアログを閉じた際にも参加者一覧の方の映像が残るようになっているようだった

          // audioVideo.unbindVideoElement(tileId)
          videoElement.srcObject = null
        }
      }
    }, [audioVideo, tileId, isVideoEnabled])

    return (
      <VideoTile
        objectFit="cover" // ボックスサイズに合わせて縦横比を維持しながらリサイズして、トリミングしてはめ込む
        ref={videoElementRef}
        className={classNames(className, !isVideoEnabled && 'hidden')}
        {...rest}
        chimeAttendeeId={chimeAttendeeId}
      />
    )
  }
)
