import { ProjectRef, UserRef } from '@crew/models/refs'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { FC } from 'react'

type TaskInfoProps = {
  subject: string
  project: ProjectRef
  assignToUser: UserRef | undefined
}

export const TaskInfo: FC<TaskInfoProps> = (props) => {
  return (
    // worktimes task info
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col">
        {/* task name */}
        <span className="font-bold line-clamp-1">{props.subject}</span>

        {/* project name */}
        <span className="line-clamp-1 crew-text-gray-4 text-sm">
          {props.project.subject}
        </span>
      </div>

      {/* assign to user */}
      {props.assignToUser && (
        <CrewUserAvatar
          userId={props.assignToUser.id}
          displayName={props.assignToUser.displayName}
          size={CrewAvatarSize.xs}
          cacheValue={props.assignToUser.id + props.assignToUser.version}
          showLabel
          disableClick
        />
      )}
    </div>
  )
}
