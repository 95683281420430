import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useCallback } from 'react'
import {
  useLazyGetTaskCategoriesQuery,
  useDeleteTaskCategoryMutation,
  useReorderTaskCategoryMutation,
} from '@crew/apis/task/taskApis'
import { useAppSelector, useAppDispatch } from 'states/hooks'
import { EntityType } from '@crew/enums/domain'
import { ReorderTaskCategoryRequest } from '@crew/apis/task/models/reorderTaskCategory/request'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyProjectSettingTaskCategoryEvent,
} from 'features/app/states/appSlice'
import { useParams } from 'react-router-dom'
import { useValueChangeEffect } from '@crew/hooks'

export type TaskCategory = {
  taskCategoryId: string
  version: number
}
export const useProjectSettingTaskCategoryListPanel = () => {
  const dispatch = useAppDispatch()
  const { projectId } = useParams()

  // Get functions for change the task category infomation
  const [deleteTaskCategoryMutation] = useDeleteTaskCategoryMutation()
  const [reorderTaskCategoryMutation] = useReorderTaskCategoryMutation()

  const [lazyGetTaskCategoriesQuery] = useLazyGetTaskCategoriesQuery()

  const projectTaskCategoriesDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!projectId) return

        const result = await lazyGetTaskCategoriesQuery({
          entityType: EntityType.Project,
          entityRecordId: projectId,
        }).unwrap()

        return {
          data: result.taskCategories,
          totalCount: result.totalCount,
        }
      },
    }),
    [projectId, lazyGetTaskCategoriesQuery]
  )

  // Delete task category process
  const deleteTaskCategory = useCallback(
    async (taskCategory: TaskCategory) => {
      await deleteTaskCategoryMutation(taskCategory).unwrap()

      //trigger event refresh project setting task category list
      const objectEventMessage: ObjectEventMessage<void> = {
        eventType: NotifyEventType.Deleted,
        id: taskCategory.taskCategoryId,
        object: undefined,
      }
      dispatch(notifyProjectSettingTaskCategoryEvent(objectEventMessage))
    },
    [deleteTaskCategoryMutation, dispatch]
  )

  // Reorder task category process
  const reorderTaskCategory = useCallback(
    async (taskCategoryId: string, targetPositionTaskCategoryId: string) => {
      const request: ReorderTaskCategoryRequest = {
        taskCategoryId,
        targetPositionTaskCategoryId,
      }

      await reorderTaskCategoryMutation(request).unwrap()

      // Trigger event refresh event category list
      const objectEventMessage: ObjectEventMessage<void> = {
        eventType: NotifyEventType.Updated,
        id: taskCategoryId,
        object: undefined,
      }
      dispatch(notifyProjectSettingTaskCategoryEvent(objectEventMessage))
    },
    [dispatch, reorderTaskCategoryMutation]
  )

  // projectSettingTaskCategoryEventMessageに変化があったらprojectTaskCategoriesDataSourceをリロードする
  const projectSettingTaskCategoryEventMessage = useAppSelector(
    (state) => state.app.projectSettingTaskCategoryEventMessage
  )
  useValueChangeEffect(
    () => {
      projectTaskCategoriesDataSource.reload()
    },
    [projectTaskCategoriesDataSource],
    projectSettingTaskCategoryEventMessage,
    true
  )

  return {
    projectTaskCategoriesDataSource,
    deleteTaskCategory,
    reorderTaskCategory,
  }
}
