import { ComponentProps } from 'react'

import { genericMemo } from 'utils'
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { CrewErrorMessages } from 'components/forms/crewErrorMessages'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { CrewDropDownTreeViewBox } from 'components/devextreme/crewDropDownTreeViewBox'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  ComponentProps<typeof CrewDropDownTreeViewBox>,
  'ref' | 'name' | 'value' | 'onFocusOut' | 'isValid' //これらはcontrollerが提供するのでpropsから除外する
> & {
  id: string
  required?: boolean
  label?: string
} & UseControllerProps<TFieldValues, TName>

/**
 * DropDownBoxのラッパー（react-hook-form対応版）
 */
export const CrewDropDownBoxTreeField = genericMemo(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
    // useController用
    name,
    rules,
    control,
    shouldUnregister,
    defaultValue,
    // ラベル用
    id,
    required = false,
    label,
    // その他
    children,
    ...rest
  }: Props<TFieldValues, TName>) => {
    const { field, fieldState, formState } = useController({
      name,
      control,
      rules,
      shouldUnregister,
      defaultValue,
    })

    return (
      <div className="flex flex-col gap-1">
        {label && (
          <CrewFieldLabel text={label} required={required} htmlFor={id} />
        )}
        <CrewDropDownTreeViewBox
          {...rest}
          name={field.name}
          value={field.value}
          onValueChange={field.onChange}
          onFocusOut={field.onBlur}
          isValid={!fieldState.error}
        >
          {children}
        </CrewDropDownTreeViewBox>
        <CrewErrorMessages
          isValid={!fieldState.error}
          errors={formState.errors}
          field={field}
        />
      </div>
    )
  }
)
