import { useState } from 'react';
import { useValueChangeEffect } from './useValueChangeEffect';
/**
 * ウィンドウサイズからFetchLimitを自動計算するカスタムフック
 * @param minHeight 描画される要素の最小の高さ。この値とウィンドウサイズをもとに取得件数を計算する
 * @param getWindowDimensions ウィンドウサイズを取得する関数(WebとMobileでそれぞれ実装が異なるため、呼び出し元で関数を渡す)
 * @returns
 */
export const useFetchLimit = (minHeight, getWindowDimensions) => {
    // 引数の関数を実行してウィンドウサイズを取得する
    const windowDimensions = getWindowDimensions();
    // 一度にfetchするサイズ
    const [fetchLimit, setFetchLimit] = useState(Math.ceil(windowDimensions.height / minHeight));
    // ウィンドウの縦幅を元に一度にfetchするサイズを決定する
    // 過剰な再fetchや再レンダリングを避けるため、縮小はさせない
    useValueChangeEffect(() => {
        const newLimit = Math.ceil(windowDimensions.height / minHeight);
        setFetchLimit((prev) => Math.max(prev, newLimit));
    }, [minHeight, windowDimensions.height], windowDimensions.height);
    return fetchLimit;
};
