import { Ref, ComponentPropsWithoutRef, useMemo } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { genericMemo } from '../../utils'

import { CrewFieldLabel } from '../elements/crewFieldLabel'
import { CrewErrorMessages } from './crewErrorMessages'
import { CrewTagBox, ICrewTagBox } from 'components/devextreme/crewTagBox'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  ComponentPropsWithoutRef<typeof CrewTagBox>,
  'name' | 'value' | 'onValueChange' | 'onFocusOut' | 'isValid' //これらはcontrollerが提供するのでpropsから除外する
> & {
  id: string
  required?: boolean
  label?: string
  showLabel?: boolean
  customRef?: Ref<ICrewTagBox>
} & UseControllerProps<TFieldValues, TName>

export const CrewTagBoxField = genericMemo(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    props: Props<TFieldValues, TName>
  ) => {
    const {
      // useController用
      name,
      rules,
      control,
      shouldUnregister,
      defaultValue,
      // ラベル用
      id,
      required = false,
      label,
      showLabel = true,
      // その他
      children,
      customRef,
      ...rest
    } = props

    const { field, fieldState, formState } = useController({
      name,
      control,
      rules,
      shouldUnregister,
      defaultValue,
    })

    // 要素にid属性を付与するためのオブジェクト
    // data-testidのようなカスタム属性はinputAttrで指定する必要がある
    // https://supportcenter.devexpress.com/ticket/details/t1052998/form-unable-to-pass-custom-attributes-to-items
    const inputAttr = useMemo(() => {
      return { id: id, 'data-testid': field.name }
    }, [field.name, id])

    // CrewTagBoxに渡すTagBoxのid
    // CrewTagBoxのポップアップ表示対象を特定するために使用する
    const tagBoxId = useMemo(() => {
      return 'tagbox-' + id
    }, [id])

    return (
      <div className="flex flex-col gap-1">
        {showLabel && (
          <CrewFieldLabel text={label} required={required} htmlFor={id} />
        )}
        <CrewTagBox
          {...rest}
          ref={(r) => {
            field.ref(r)
            if (customRef) {
              // 型の問題でcurrentに代入するためにanyを使用(暫定)
              ;(customRef as any).current = r
            }
          }}
          // id={id}で渡していないのは、useFocusInputでinputAttr.idを使用しており、tagBox側のidと重複させると正しく動作しないため
          // 一方、CrewTagBoxのポップアップ表示には表示先を特定するidが必要なため、tagBoxIdとして別途渡している
          tagBoxId={tagBoxId}
          inputAttr={inputAttr}
          name={field.name}
          value={field.value}
          onValueChange={field.onChange}
          onFocusOut={field.onBlur}
          isValid={!fieldState.error}
        >
          {children}
        </CrewTagBox>
        <CrewErrorMessages
          isValid={!fieldState.error}
          errors={formState.errors}
          field={field}
        />
      </div>
    )
  }
)
