import { apis } from '../apiBase/apiBase';
const bookmarkApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        insertBookmark: builder.mutation({
            query: (params) => ({
                url: `bookmarks/insert`,
                method: `POST`,
                body: params,
            }),
        }),
        deleteBookmark: builder.mutation({
            query: (params) => ({
                url: `bookmarks/delete`,
                method: `POST`,
                body: params,
            }),
        }),
        // FIXME: ブックマーク一覧取得リクエストのエンドポイントについて確認
        //        https://break-tmc.atlassian.net/browse/CREW-4037
        getBookmarks: builder.query({
            query: (params) => ({
                url: `bookmarks`,
                params: params,
                method: 'GET',
            }),
        }),
        updateBookmarkArchive: builder.mutation({
            query: (params) => ({
                url: `/bookmarks/archive/update`,
                method: `POST`,
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useUpdateBookmarkArchiveMutation, useInsertBookmarkMutation, useDeleteBookmarkMutation, useLazyGetBookmarksQuery, } = bookmarkApis;
