import { RoleType } from '@crew/enums/domain'
import {
  useLazyGetLookupRoleQuery,
  useLazyGetLookupRolesQuery,
} from '@crew/apis/lookup/lookupApis'
import { useDataSource } from './useDataSource'
import { useTranslation } from '@crew/modules/i18n'
import { camelCase } from 'lodash'
import { Roles } from '@crew/enums/app'

export const useRoleDataSource = (
  roleType: RoleType,
  excludeRoles?: Roles[],
  isIncludeUserDefineRole?: boolean
) => {
  const [lazyGetRolesQuery] = useLazyGetLookupRolesQuery()
  const [lazyGetRoleQuery] = useLazyGetLookupRoleQuery()

  const { t } = useTranslation()

  return useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        const response = await lazyGetRolesQuery({
          roleType,
        }).unwrap()

        let roles = response.roles.filter((item) => {
          // Exclude roles if specified
          if (excludeRoles?.some((role) => role.value === item.roleCode)) {
            return false
          }
          // Exclude user-defined roles if specified
          if (!isIncludeUserDefineRole && item.isUserDefined) {
            return false
          }
          return true
        })

        return roles.map((role) => {
          if (role.roleCode) {
            return {
              ...role,
              name: t(`label.roles.${camelCase(role.roleCode)}`),
            }
          } else {
            return role
          }
        })
      },
      byKey: async (roleId: string) => {
        const response = await lazyGetRoleQuery({
          roleId,
        }).unwrap()

        if (response.role?.roleCode) {
          return {
            ...response.role,
            name: t(`label.roles.${camelCase(response.role.roleCode)}`),
          }
        } else {
          return response.role
        }
      },
    }),
    [
      lazyGetRolesQuery,
      roleType,
      excludeRoles,
      isIncludeUserDefineRole,
      t,
      lazyGetRoleQuery,
    ]
  )
}
