import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@crew/modules/i18n'
import { useFilterScopeDataSource } from 'hooks/dataSource/useResourceDataSource'
import {
  useReplaceFilterMutation,
  useUpdateFilterMutation,
} from '@crew/apis/filter/filterApis'
import { Filter } from '@crew/apis/filter/models/replaceFilter/request'
import { NotifyEventType, ProjectScope } from 'enums/app'
import { UpdateFilterRequest } from '@crew/apis/filter/models/updateFilter/request'
import { useAppDispatch } from 'states/hooks'
import {
  ObjectEventMessage,
  notifyFilterEvent,
} from 'features/app/states/appSlice'
import { Filter as FilterEvent } from '@crew/models/domain'

export type FormValues = {
  name: string
  scope: string
  default: boolean
}

const formInitialValues: FormValues = {
  name: '',
  scope: ProjectScope.Public.key,
  default: false,
}

export const useCrewSaveFilterForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [replaceFilterMutation] = useReplaceFilterMutation()
  const [updateFilterMutation] = useUpdateFilterMutation()

  const {
    control,
    reset,
    clearErrors,
    handleSubmit,
    formState,
    setError,
    getValues,
  } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Set data source for Scope item
  const filterScopeDataSource = useFilterScopeDataSource()

  // Call API to save filter
  const saveFilter = useCallback(
    async (payload: Filter) => {
      // フィルターの登録・更新処理を実行
      // 戻り値からIDを取得し、フィルタの選択状態に反映する
      const response = await replaceFilterMutation({
        filter: payload,
      }).unwrap()

      return response.filter
    },
    [replaceFilterMutation]
  )

  // call api update filter
  const updateFilter = useCallback(
    async (data: FormValues, version: number, filterId: string) => {
      console.log(data)
      // Update filter request payload
      const payload: UpdateFilterRequest = {
        filter: {
          id: filterId,
          name: data.name,
          scope: data.scope,
          default: data.default ?? false,
          version,
        },
      }
      const response = await updateFilterMutation(payload).unwrap()

      const objectEventMessage: ObjectEventMessage<FilterEvent> = {
        eventType: NotifyEventType.Updated,
        id: response.filter.id,
        object: response.filter,
      }
      dispatch(notifyFilterEvent(objectEventMessage))

      return response.filter
    },
    [dispatch, updateFilterMutation]
  )

  // Validation for Save filter dialog
  const validateRules = useMemo(
    () => ({
      name: {
        required: t('message.general.required'),
      },
      scope: {
        required: t('message.general.required'),
      },
      default: {},
    }),
    [t]
  )

  return {
    t,
    control,
    formState,
    filterScopeDataSource,
    validateRules,
    getValues,
    reset,
    clearErrors,
    setError,
    handleSubmit,
    saveFilter,
    updateFilter,
  }
}
