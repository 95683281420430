import { ColumnDef, TableOptions, getCoreRowModel } from '@tanstack/react-table'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { memo, useMemo } from 'react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { ExistingUserType } from 'features/project/components/projectMemberEntryDialog/useProjectMemberEntryDialog'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewUserItem } from 'components/elements/crewUserItem/crewUserItem'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useState } from 'react'
import { useModal } from 'components/layouts/modal/useModal'
import { useProjectMemberEntryContext } from 'features/project/components/projectMemberEntryDialog/useProjectMemberEntryDialog'
import BaselineDelete from '~icons/ic/baseline-delete'

export type AddExistingUserTableProps = {
  selectedExistingUsers: ExistingUserType[]
}

export const AddExistingUserTable = memo((props: AddExistingUserTableProps) => {
  const { t } = useTranslation()

  const { setSelectedExistingUsers } = useProjectMemberEntryContext()

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    right: ['action'],
  })

  const [removeUserId, setRemoveUserId] = useState('')
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  //Remove button click
  const handleRemoveButtonClick = useCallback(
    (userId: string) => {
      setRemoveUserId(userId)
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    //remove selected user from selectedExistingUsers
    const newSelectedExistingUsers = props.selectedExistingUsers.filter(
      (user) => user.id !== removeUserId
    )
    //update selectedExistingUsers
    setSelectedExistingUsers(newSelectedExistingUsers)

    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    props.selectedExistingUsers,
    removeUserId,
    setSelectedExistingUsers,
  ])

  const columns = useMemo<ColumnDef<ExistingUserType>[]>(
    () => [
      {
        id: 'user',
        header: () => t('label.user'),
        cell: ({ row }) => (
          <div className="truncate">
            <CrewUserItem
              displayName={row.original.displayName}
              id={row.original.id}
              version={row.original.version}
            />
          </div>
        ),
        size: 500,
        minSize: 50,
      },
      {
        id: 'role',
        header: () => t('label.role'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.role.name}</div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => (
          <CrewButton
            stylingMode="text"
            icon={<BaselineDelete width={20} height={20} />}
            onClick={() => handleRemoveButtonClick(row.original.id)}
          />
        ),
        size: 80,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [handleRemoveButtonClick, t]
  )

  const tableOptions: TableOptions<ExistingUserType> = {
    data: props.selectedExistingUsers,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnPinning,
    },
    onColumnVisibilityChange: setColumnVisibility,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  return (
    <>
      {/* invite new user list table */}
      <CrewTable
        tableOptions={tableOptions}
        showPager={false}
        showColumnSelector={false}
      />

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </>
  )
})
