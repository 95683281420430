import { memo } from 'react'
import { PersonalSettingProfileForm } from './components/personalSettingProfileForm/personalSettingProfileForm'

export const PersonalSettingProfilePanel = memo(() => {
  return (
    <div className="max-w-2xl mx-auto w-full">
      <PersonalSettingProfileForm />
    </div>
  )
})
