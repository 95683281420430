import {
  COMMAND_PRIORITY_NORMAL,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type ElementFormatType,
  type LexicalEditor,
  type LexicalNode,
  type NodeKey,
  type Spread,
} from 'lexical'

import { DecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'

import type { SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import { LinkCard } from '../linkCard/linkCard'

type LinkCardAttributes = {
  url: string
  type: 'linkcard'
}

type SerializedLinkCardNode = Spread<
  LinkCardAttributes,
  SerializedDecoratorBlockNode
>

export class LinkCardNode extends DecoratorBlockNode {
  __url: string
  static getType(): string {
    return 'linkcard'
  }

  constructor(url: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key)
    this.__url = url
  }

  static clone(node: LinkCardNode): LinkCardNode {
    return new LinkCardNode(node.__url, node.__format, node.__key)
  }

  getURL(): string {
    return this.__url
  }

  createDOM(): HTMLElement {
    const element = document.createElement('div')
    element.setAttribute('data-linkcard', 'true')
    // EditorConfigを受け取れないので、ここでクラスを設定する
    // addClassNamesToElement(element, config.theme.link)
    element.classList.add('editor-linkcard')
    return element
  }

  updateDOM(): false {
    return false
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor)
    const element_ = element as HTMLDivElement
    element_.setAttribute('data-linkcard-url', this.__url)
    element_.textContent = this.__url
    return {
      element,
    }
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: () => ({
        conversion: convertLinkCardElement,
        priority: COMMAND_PRIORITY_NORMAL,
      }),
    }
  }

  decorate(): JSX.Element {
    return <LinkCard url={this.__url} nodeKey={this.__key} />
  }

  exportJSON(): SerializedLinkCardNode {
    return {
      ...super.exportJSON(),
      url: this.__url,
      type: 'linkcard',
      version: 1,
    }
  }

  isInline(): false {
    return false
  }

  static importJSON(
    serializedLinkPreviewNode: SerializedLinkCardNode
  ): LinkCardNode {
    const node = $createLinkCardNode(serializedLinkPreviewNode.url)
    node.setFormat(serializedLinkPreviewNode.format)
    return node
  }
}

function convertLinkCardElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLDivElement) {
    if (!domNode.hasAttribute('data-linkcard')) {
      return null
    }
    if (!domNode.hasAttribute('data-linkcard-url')) {
      return null
    }
    const dataUrl = domNode.getAttribute('data-linkcard-url')
    if (!dataUrl) {
      return null
    }
    const node = $createLinkCardNode(dataUrl)
    return { node }
  }
  return null
}

export function $createLinkCardNode(url: string): LinkCardNode {
  // return $applyNodeReplacement(new LinkCardNode(url))
  return new LinkCardNode(url)
}

export function $isLinkCardNode(
  node: LexicalNode | null | undefined
): node is LinkCardNode {
  return node instanceof LinkCardNode
}
