import { useTranslation } from '@crew/modules/i18n'
import classNames from 'classnames'
import { FC, memo } from 'react'
import { isWeekend } from 'utils/date'

type DateCellProps = {
  date: Date
}

export const DateCell: FC<DateCellProps> = memo((props) => {
  const { t } = useTranslation()
  return (
    <div
      className={classNames(
        'w-full h-full flex items-center justify-center',
        isWeekend(props.date) && 'crew-bg-gray-1'
      )}
    >
      {t('format.dayOfWeekShort', {
        value: props.date,
      })}
    </div>
  )
})
