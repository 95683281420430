import { FC, memo, useCallback } from 'react'
import classNames from 'classnames'
import { ChatMessage } from '@crew/models/domain'
import {
  AvatarPosition,
  CrewChatMessageItemAvatar,
} from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageItemHeader } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemHeader/crewChatMessageItemHeader'
import { ShowReplyButtonType, ShowReactionType } from '@crew/utils/dist/chat'
import { useTranslation } from '@crew/modules/dist/i18n'
import { MessageType } from '@crew/enums/dist/domain'
import { CrewTaskNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewTaskNotificationChatMessage/crewTaskNotificationChatMessage'
import { CrewFileNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewFileNotificationChatMessage/crewFileNotificationChatMessage'
import { CrewProjectMemberNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewProjectMemberNotificationChatMessage/crewProjectMemberNotificationChatMessage'
import { CrewEventNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewEventNotificationChatMessage/crewEventNotificationChatMessage'
import { CrewReplyAndReactionButton } from '../crewReplyAndReactionButton/crewReplyAndReactionButton'

export type CrewNotificationMessageItemProps = {
  message: ChatMessage // 投稿メッセージデータ
  showRelatedLink: boolean // 投稿に関連先リンクを表示するかどうか
  canDownloadAttachment: boolean // 添付ファイルをダウンロードできるかどうか（ボタン表示制御に使用する）
  omitUserAvatar: boolean // アバターを表示しない場合はtrue
  showReplyButtonType: ShowReplyButtonType // 返信ボタンをどう表示するか
  showReactionsAndReactionButton: ShowReactionType // リアクションとリアクションボタンを表示するかどうか
  replyCount: number | undefined // 「○件の返信」と表示する場合のみ値を設定し、他はundefined
  customHeaderContent?: React.ReactNode | JSX.Element // ヘッダー部分をカスタマイズする場合は指定する
  truncateMessage: boolean // メッセージテキストを省略表示するかどうか
  onReplyClick: (() => void) | undefined // 返信ボタンを表示しない場合はundefined
  onClick: (() => void) | undefined // メッセージ全体をクリックした際のイベントコールバック
}

/**
 * 自動投稿メッセージ
 */
export const CrewNotificationMessageItem: FC<CrewNotificationMessageItemProps> =
  memo((props) => {
    const { t } = useTranslation()

    // messageTypeに応じて自動投稿メッセージの表示を切り替える
    const renderNotificationMessage = useCallback(() => {
      switch (props.message.messageType) {
        case MessageType.TaskAdded:
        case MessageType.TaskUpdated:
        case MessageType.TaskDeleted:
        case MessageType.TaskCommentUpdated:
          return (
            <CrewTaskNotificationChatMessage
              messageType={props.message.messageType}
              chatMessageReferences={props.message.references}
              truncateMessage={props.truncateMessage}
            />
          )
        case MessageType.FileAdded:
        case MessageType.FileUpdated:
          return (
            <CrewFileNotificationChatMessage
              chatMessageReferences={props.message.references}
              canDownload={props.canDownloadAttachment}
              truncateMessage={props.truncateMessage}
            />
          )
        case MessageType.ProjectMemberJoined:
        case MessageType.ProjectMemberLeft:
        case MessageType.ProjectMemberRequested:
          return (
            <CrewProjectMemberNotificationChatMessage
              messageType={props.message.messageType}
              chatMessageReferences={props.message.references}
              truncateMessage={props.truncateMessage}
            />
          )
        case MessageType.EventAdded:
        case MessageType.EventUpdated:
        case MessageType.EventDeleted:
        case MessageType.EventJoined:
          return (
            <CrewEventNotificationChatMessage
              messageType={props.message.messageType}
              chatMessageReferences={props.message.references}
              truncateMessage={props.truncateMessage}
            />
          )
        default:
          return null
      }
    }, [
      props.canDownloadAttachment,
      props.truncateMessage,
      props.message.messageType,
      props.message.references,
    ])

    return (
      <div
        className="flex flex-row gap-2 p-2 crew-hover-gray-1"
        onClick={props.onClick}
      >
        {/* アバター */}
        <CrewChatMessageItemAvatar
          isLargeAvatar={true}
          user={props.message.createdBy}
          position={AvatarPosition.Center} // 自動投稿メッセージのため中央に表示する
          omitUserAvatar={props.omitUserAvatar}
        />
        <div className="flex flex-grow flex-col gap-1 min-w-0">
          {/* messageTypeに応じて帯にテキストを表示 */}
          <div
            className={classNames(
              'px-1 py-0.5 rounded-md text-sm font-bold',
              'text-crew-red-600 bg-crew-red-100'
            )}
          >
            {t(`message.chat.${props.message.messageType}`)}
          </div>

          {/* カスタムヘッダー */}
          {props.customHeaderContent && props.customHeaderContent}

          {/* ユーザー名、投稿日、関連先リンク */}
          <CrewChatMessageItemHeader
            userName={props.message.createdBy.displayName}
            userId={props.message.createdBy.id}
            timestamp={props.message.updatedAt}
            isShowChatRoomName={props.showRelatedLink}
            entityType={props.message.chatRoom.entityType}
            entityRecordId={props.message.chatRoom.entityRecordId}
            rootEntityType={props.message.chatRoom.rootEntityType}
            rootEntityRecordId={props.message.chatRoom.rootEntityRecordId}
          />

          {/* notification message */}
          {renderNotificationMessage()}

          {/* 返信ボタンとリアクションボタンを条件に応じて表示する */}
          {(props.showReplyButtonType !== ShowReplyButtonType.None ||
            props.showReactionsAndReactionButton !== ShowReactionType.None) && (
            <CrewReplyAndReactionButton
              message={props.message}
              onReplyClick={props.onReplyClick}
              showReplyButtonType={props.showReplyButtonType}
              showReactionsAndReactionButton={
                props.showReactionsAndReactionButton
              }
              replyCount={props.replyCount}
            />
          )}
        </div>
      </div>
    )
  })
