import { AttentionType } from '@crew/enums/dist/domain'
import { useTranslation } from '@crew/modules/dist/i18n'
import { FC, memo, useCallback } from 'react'

type Props = {
  attentionType: AttentionType
  attentionUserName: string
}

export const CrewChatMessageAttentionTypeHeader: FC<Props> = memo((props) => {
  const { t } = useTranslation()

  // Change the text displayed depending on the attention type
  const renderAttentionType = useCallback(() => {
    switch (props.attentionType) {
      case AttentionType.Mention:
        return (
          <div>
            {t('message.chat.mentioned', {
              name: props.attentionUserName,
            })}
          </div>
        )
      case AttentionType.Reaction:
        return (
          <div>
            {t('message.chat.reacted', {
              name: props.attentionUserName,
            })}
          </div>
        )
      case AttentionType.Reply:
        return (
          <div>
            {t('message.chat.replied', {
              name: props.attentionUserName,
            })}
          </div>
        )
      default:
        return null
    }
  }, [props.attentionType, props.attentionUserName, t])

  return (
    <div className="text-sm text-crew-slate-4-light dark:text-crew-slate-1-dark">
      {/* attention type */}
      {renderAttentionType()}
    </div>
  )
})
