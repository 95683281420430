import { FC, memo, useEffect } from 'react'

import { CrewSwitch } from 'components/devextreme/crewSwitch'

import { useAsyncStateConnector } from './hooks/useAsyncStateConnector'
import {
  BackgroundEffect,
  useBackgroundEffect,
} from './backgroundEffectProvider'

/**
 * 背景エフェクトの有効・無効を切り替えるスイッチ
 */
export const CrewBackgroundEffectSwitch: FC = memo(() => {
  const {
    isBackgroundEffectSupported,
    isBackgroundEffectEnabled,
    enableBackgroundEffect,
    backgroundEffect,
    loadBackgroundImage,
  } = useBackgroundEffect()

  const { value, handleValueChanged, isChangingAsyncState } =
    useAsyncStateConnector(isBackgroundEffectEnabled, enableBackgroundEffect)

  // Refresh meeting background image when current backgroundEffect is `image` and turn on background image effect
  useEffect(() => {
    backgroundEffect === BackgroundEffect.replacement &&
      value &&
      loadBackgroundImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundEffect, value])

  return (
    <CrewSwitch
      disabled={!isBackgroundEffectSupported || isChangingAsyncState} // エフェクト未サポートの場合と切り替え中は変更不可とする
      value={value}
      onValueChanged={handleValueChanged}
    />
  )
})
