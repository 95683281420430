import { FC, memo } from 'react'

import { useTranslation } from '@crew/modules/i18n'

type Props = {
  topicId: string
  onClick: () => void
}

export const CrewMessageReplyButton: FC<Props> = memo((props) => {
  const { t } = useTranslation()

  return (
    <span
      className="crew-action-normal crew-button-sm cursor-pointer break-normal"
      onClick={props.onClick}
    >
      {t('action.reply')}
    </span>
  )
})
