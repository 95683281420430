import { useCallback } from 'react'
import { useUpgradePlanMutation } from '@crew/apis/contract/contractApis'
import { ContractPlan } from '@crew/enums/app'

export const useContractUpgradePlanPage = () => {
  // Get upgrade plan function
  const [upgradePlanMutation] = useUpgradePlanMutation()

  // プランを更新する
  const updatePlan = useCallback(
    async (contractPlan: ContractPlan) => {
      await upgradePlanMutation({
        contractPlanToUpgrade: contractPlan,
      }).unwrap()
    },
    [upgradePlanMutation]
  )

  return {
    updatePlan,
  }
}
