import {
  useDeleteProjectMutation,
  useInsertProjectMutation,
  useUpdateProjectMutation,
} from '@crew/apis/project/projectApis'
import { Project as ProjectEntry } from '@crew/models/domain'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'
import {
  notifyProjectEvent,
  notifyProjectSettingMemberEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { NotifyEventType, ProjectScope } from 'enums/app'
import { ValidateRules } from '@crew/utils/form'
import { useProjectScopeDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useUserDataSource } from 'hooks/dataSource/useUserDataSource'
import { useProjectGroupDataSource } from 'hooks/dataSource/useProjectGroupDataSource'
import { useUploadFileMutation } from '@crew/apis/file/fileApis'
import { ProjectMember } from '@crew/apis/dist/lookup/models/getProjectMembers/response'
import { useTranslation } from '@crew/modules/dist/i18n'

export type FormValues = {
  id: string | null
  ownerUserId: string | null
  subject: string
  scope: string
  approvalToJoin: boolean
  avatarKey: string | null
  projectGroupId: string | null
  description: string
  projectMemberIds: string[]
  archived: boolean
}
const formInitialValues: FormValues = {
  id: null,
  subject: '',
  description: '',
  scope: ProjectScope.Private.key,
  approvalToJoin: false,
  ownerUserId: null,
  projectGroupId: null,
  projectMemberIds: [],
  avatarKey: null,
  archived: false,
}

export const useProjectEntryForm = () => {
  const { t } = useTranslation()

  // Get functions for Add/Edit/Delete project
  const [insertProjectMutation] = useInsertProjectMutation()
  const [deleteProjectMutation] = useDeleteProjectMutation()
  const [updateProjectMutation] = useUpdateProjectMutation()
  const [uploadFileMutation] = useUploadFileMutation()
  const dispatch = useAppDispatch()

  // set cover base64
  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  // react-hook-formの各種データを取得
  const {
    handleSubmit,
    clearErrors,
    formState,
    reset,
    control,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  //set data source for project scope
  const projectScopeDataSource = useProjectScopeDataSource()

  //set data source for owner dropdown
  const ownerDataSource = useUserDataSource(false, true) // dont add label addMe and exclude external user

  //set data source for member dropdown
  const memberDataSource = useUserDataSource()

  //set data source for project group dropdown
  const projectGroupDataSource = useProjectGroupDataSource()

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      subject: {
        required: t('message.general.required'),
        maxLength: {
          value: 100,
          message: t('message.general.maxLength', {
            name: t('label.subject'),
            value: 100,
          }),
        },
      },
      key: {
        required: t('message.general.required'),
        maxLength: {
          value: 10,
          message: t('message.general.maxLength', {
            name: t('label.key'),
            value: 10,
          }),
        },
      },
      scope: {
        required: t('message.general.required'),
      },
      approvalToJoin: {},
      ownerUserId: {
        required: t('message.general.required'),
      },
      // not validate below
      id: {},
      avatarKey: {},
      description: {
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.subject'),
            value: 500,
          }),
        },
      },
      projectGroupId: {},
      projectMemberIds: {},
      archived: {},
    }),
    [t]
  )

  // Delete project process
  const deleteProject = useCallback(
    async (projectId: string, version: number) => {
      await deleteProjectMutation({
        projectId,
        version,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<ProjectEntry> = {
        eventType: NotifyEventType.Deleted,
        id: projectId,
        object: undefined,
      }
      dispatch(notifyProjectEvent(objectEventMessage))
    },
    [deleteProjectMutation, dispatch]
  )

  // Update project process
  const updateProject = useCallback(
    async (
      projectId: string,
      version: number,
      data: FormValues,
      isShowApprovalToJoin: boolean
    ) => {
      const result = await updateProjectMutation({
        project: {
          id: projectId,
          subject: data.subject as string,
          description: data.description as string,
          scope: data.scope,
          approvalToJoin: isShowApprovalToJoin,
          avatarKey: data.avatarKey ?? undefined,
          projectGroupId: data.projectGroupId ?? undefined,
          ownerUserId: data.ownerUserId ?? undefined,
          archived: data.archived,
          version: version,
        },
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<ProjectEntry> = {
        eventType: NotifyEventType.Updated,
        id: result.project.id,
        object: result.project,
      }
      dispatch(notifyProjectEvent(objectEventMessage))

      const objectEventMessageProjectSetting: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Updated,
          id: result.project.projectMembers[0].id,
          object: undefined,
        }
      dispatch(
        notifyProjectSettingMemberEvent(objectEventMessageProjectSetting)
      )

      return result
    },
    [dispatch, updateProjectMutation]
  )

  // Insert project process
  const insertProject = useCallback(
    async (data: FormValues, isShowApprovalToJoin: boolean) => {
      const result = await insertProjectMutation({
        project: {
          subject: data.subject as string,
          description: data.description ?? undefined,
          scope: data.scope,
          approvalToJoin: isShowApprovalToJoin,
          avatarKey: data.avatarKey ?? undefined,
          projectGroupId: data.projectGroupId ?? undefined,
          ownerUserId: data.ownerUserId as string,
          projectMemberIds: data.projectMemberIds ?? undefined,
        },
      }).unwrap()

      // 登録結果のレスポンスがない場合
      if (!result.project) {
        console.log('project not found')
        return
      }

      const objectEventMessage: ObjectEventMessage<ProjectEntry> = {
        eventType: NotifyEventType.Inserted,
        id: result?.project.id,
        object: result?.project,
      }
      dispatch(notifyProjectEvent(objectEventMessage))

      return result
    },
    [dispatch, insertProjectMutation]
  )

  // Upload file process
  const uploadFile = useCallback(
    async (file: File) => {
      const result = await uploadFileMutation({ file }).unwrap()

      return result
    },
    [uploadFileMutation]
  )

  return {
    handleSubmit,
    clearErrors,
    formState,
    reset,
    control,
    setError,
    setValue,
    getValues,
    watch,

    convertBase64,

    projectScopeDataSource,
    ownerDataSource,
    memberDataSource,
    projectGroupDataSource,
    validateRules,

    deleteProject,
    updateProject,
    insertProject,
    uploadFile,
  }
}
