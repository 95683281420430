// TODO: 一時的に無効化 CREW-9236 で再度有効化する
// https://break-tmc.atlassian.net/browse/CREW-9236

// import { useCallback, useEffect, useState } from 'react'

// const GOOGLE_RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js'

// //show recaptcha badge
// const showBadge = () => {
//   if (!window.grecaptcha) return
//   window.grecaptcha.ready(() => {
//     const badge = document.getElementsByClassName(
//       'grecaptcha-badge'
//     )[0] as HTMLElement
//     if (!badge) return
//     badge.style.display = 'block'
//   })
// }

// //hide recaptcha badge
// const hideBadge = () => {
//   if (!window.grecaptcha) return
//   window.grecaptcha.ready(() => {
//     const badge = document.getElementsByClassName(
//       'grecaptcha-badge'
//     )[0] as HTMLElement
//     if (!badge) return
//     badge.style.display = 'none'
//   })
// }

// https://stackoverflow.com/questions/53832882/react-and-recaptcha-v3
// https://medium.com/@alexjamesdunlop/unnecessary-packages-b3623219d86
export const useReCaptcha = () => {
  // TODO: 一時的に無効化 CREW-9236 で再度有効化する
  // https://break-tmc.atlassian.net/browse/CREW-9236
  // const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false)

  // // Load ReCaptcha script
  // useEffect(() => {
  //   if (typeof window === 'undefined' || reCaptchaLoaded) return
  //   if (window.grecaptcha) {
  //     showBadge()
  //     setReCaptchaLoaded(true)
  //     return
  //   }
  //   const script = document.createElement('script')
  //   script.async = true
  //   script.defer = true
  //   script.src = `${GOOGLE_RECAPTCHA_URL}?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3}`
  //   script.addEventListener('load', () => {
  //     setReCaptchaLoaded(true)
  //     showBadge()
  //   })
  //   document.body.appendChild(script)
  // }, [reCaptchaLoaded])

  // // Hide badge when unmount
  // useEffect(() => hideBadge, [])

  // // Get token
  // const generateReCaptchaToken = (action: string): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     if (!reCaptchaLoaded) return reject(new Error('ReCaptcha not loaded'))
  //     if (typeof window === 'undefined' || !window.grecaptcha) {
  //       setReCaptchaLoaded(false)
  //       return reject(new Error('ReCaptcha not loaded'))
  //     }
  //     window.grecaptcha.ready(() => {
  //       window.grecaptcha
  //         .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3 as string, {
  //           action,
  //         })
  //         .then((token: string) => {
  //           resolve(token)
  //         })
  //     })
  //   })
  // }

  // const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false)
  // // Load ReCaptcha v2 script
  // const loadRecaptchaV2 = () => {
  //   if (typeof window === 'undefined') return

  //   const script = document.createElement('script')
  //   script.async = true
  //   script.defer = true
  //   script.src = GOOGLE_RECAPTCHA_URL
  //   document.body.appendChild(script)
  // }

  // //callback when recaptcha v2 is verified
  // const handleReCaptchaV2Callback = useCallback(() => {
  //   setIsReCaptchaVerified(true)
  // }, [])
  // //add callback to window
  // window.handleReCaptchaV2Callback = handleReCaptchaV2Callback

  // //callback when recaptcha v2 is expired
  // const handleReCaptchaV2ExpiredCallback = useCallback(() => {
  //   setIsReCaptchaVerified(false)
  // }, [])
  // //add callback to window
  // window.handleReCaptchaV2ExpiredCallback = handleReCaptchaV2ExpiredCallback

  // return {
  //   reCaptchaLoaded,
  //   generateReCaptchaToken,
  //   loadRecaptchaV2,
  //   isReCaptchaVerified,
  //   updateRecaptchaVerified: setIsReCaptchaVerified,
  // }
  return {
    isReCaptchaVerified: true,
  }
}
