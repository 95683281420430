import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { FC, memo } from 'react'
import { EventListToolbar } from './components/eventListToolbar/eventListToolbar'
import { EventListPanel } from './components/eventListPanel/eventListPanel'

export const EventListPage: FC = memo(() => {
  return (
    <div className="min-h-0 flex-1 flex flex-col grow w-full">
      <div className="min-h-0 flex flex-col gap-4 p-2">
        <CrewErrorBoundary>
          <EventListToolbar />
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <EventListPanel />
        </CrewErrorBoundary>
      </div>
    </div>
  )
})
