import { memo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { ProjectGroupEntryDialog } from '../projectGroupEntryDialog/projectGroupEntryDialog'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useModal } from 'components/layouts/modal/useModal'

export const ProjectGroupListToolbar = memo(() => {
  const { t } = useTranslation()

  const [
    isProjectGroupEntryDialogOpen,
    openProjectGroupEntryDialog,
    closeProjectGroupEntryDialog,
  ] = useModal()

  return (
    <>
      <div className="w-full flex">
        <div className="flex flex-1">
          <CrewButton
            type="primary"
            className="ml-auto"
            text={t('action.newProjectGroup')}
            onClick={openProjectGroupEntryDialog}
          />
        </div>
      </div>

      <ProjectGroupEntryDialog
        isEditMode={false}
        title={t('label.projectGroupAdd')}
        isOpen={isProjectGroupEntryDialogOpen}
        onClose={closeProjectGroupEntryDialog}
      />
    </>
  )
})
