import classNames from 'classnames'
import { CrewAvatar } from 'components/elements/crewAvatar/crewAvatar'
import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { FC, memo, useMemo } from 'react'
import { CrewLocalVideoTile } from '../crewLocalVideoTile'
import { CrewRemoteVideoTile } from '../crewRemoteVideoTile'
import { useCrewAttendees } from './useCrewAttendees'
import { CrewShowMicrophoneMuteAndName } from 'components/elements/crewShowMicrophoneMuteAndName/crewShowMicrophoneMuteAndName'
import { generateImageAvatarUrl } from '@crew/utils/dist/avatar'
import { EntityType } from '@crew/enums/dist/domain'
import { UserRef } from '@crew/models/refs'

type Props = BaseSdkProps & {
  id?: string
  /**
   * 1行に表示する列数。指定しない場合は無制限に横に並ぶ
   */
  colCount?: number
}

// アクティブスピーカーの際に付与するクラス群
const ACTIVE_SPEAKER_CLASS =
  '!border-crew-yellow-3-light !dark:border-crew-yellow-3-dark'

// ※使用する際はclassNameにwidthを指定すること
//      使用例：<CrewAttendees className="w-72" />
export const CrewAttendees: FC<Props> = memo(
  ({ className, colCount, ...rest }) => {
    const {
      isVideoEnabled,
      attendeeUsers,
      attendeeIdToTileId,
      localAttendeeUser,
      activeSpeakerAttendeeId,
    } = useCrewAttendees()

    const isColsCountFixed = colCount !== undefined

    const classes = useMemo(
      () =>
        isColsCountFixed
          ? {
              //列数を指定する場合、固定幅で並べる
              tileContainer: classNames('p-1 justify-center max-h-full', {
                [`w-1/${colCount}`]: colCount > 1,
                'w-full': colCount === 1,
              }),
              tile: 'aspect-video max-h-full max-w-full h-full m-auto border-transparent border-solid border-4',
            }
          : {
              //列数を制限しない場合、タイル内部をflexにして良い感じに横に並べる
              tileContainer: 'p-1 justify-center flex max-h-full h-16 w-auto',
              tile: 'aspect-video h-full w-auto border-transparent border-solid border-4',
            },
      [colCount, isColsCountFixed]
    )

    return (
      <div className={className}>
        <div className={classes.tileContainer}>
          {/* ローカル */}
          {isVideoEnabled ? (
            // ビデオON
            <CrewLocalVideoTile
              key={localAttendeeUser?.id as string}
              className={classNames(
                classes.tile,
                localAttendeeUser?.chimeAttendeeId ===
                  activeSpeakerAttendeeId && ACTIVE_SPEAKER_CLASS
              )}
              name={localAttendeeUser?.displayName}
              chimeAttendeeId={localAttendeeUser?.chimeAttendeeId}
            />
          ) : (
            // ビデオOFF
            localAttendeeUser && (
              <AttendeeTile
                chimeAttendeeId={localAttendeeUser.chimeAttendeeId}
                key={localAttendeeUser.id as string}
                user={localAttendeeUser}
                className={classNames(
                  classes.tile,
                  localAttendeeUser.chimeAttendeeId ===
                    activeSpeakerAttendeeId && ACTIVE_SPEAKER_CLASS
                )}
              />
            )
          )}
        </div>
        {/* リモート */}
        {attendeeUsers.map((user) => {
          const tileId = attendeeIdToTileId[user.chimeAttendeeId]

          // リモート参加者をループして、アクティブスピーカーのAttendeeIdと比較して一致すればそのユーザがアクティブスピーカーである
          const isActiveSpeaker =
            user.chimeAttendeeId === activeSpeakerAttendeeId

          return (
            <div className={classes.tileContainer} key={user.id}>
              {tileId ? (
                // ビデオON
                <CrewRemoteVideoTile
                  key={user.id}
                  tileId={tileId}
                  name={user.displayName}
                  className={classNames(
                    classes.tile,
                    isActiveSpeaker && ACTIVE_SPEAKER_CLASS
                  )}
                  chimeAttendeeId={user.chimeAttendeeId}
                />
              ) : (
                // ビデオOFF
                <AttendeeTile
                  chimeAttendeeId={user.chimeAttendeeId}
                  key={user.id}
                  user={user}
                  className={classNames(
                    classes.tile,
                    isActiveSpeaker && ACTIVE_SPEAKER_CLASS
                  )}
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }
)

// ビデオOFF時の枠表示
type AttendeeTileProps = {
  chimeAttendeeId: string
  user: UserRef
  className?: string
}
const AttendeeTile: FC<AttendeeTileProps> = memo((props) => {
  return (
    <div
      className={classNames(
        props.className,
        'bg-crew-slate-3-dark text-white',
        'flex justify-center items-center',
        'relative'
      )}
    >
      <CrewAvatar
        displayName={props.user.displayName}
        className="aspect-square"
        imageURL={generateImageAvatarUrl(EntityType.User, props.user.id)}
        cacheValue={props.user.id + props.user.version}
      />
      {/* Show name Attended member and icon mute */}
      <CrewShowMicrophoneMuteAndName
        chimeAttendeeId={props.chimeAttendeeId}
        name={props.user.displayName}
      />
    </div>
  )
})
