import { apis, commonApis } from '../apiBase/apiBase';
// 認証API(テナント用)
const authApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (params) => ({
                url: 'login',
                method: 'POST',
                body: params,
            }),
        }),
        twoFactorAuth: builder.mutation({
            query: (params) => ({
                url: 'two-factor-auth',
                method: 'POST',
                body: params,
            }),
        }),
        getRefresh: builder.query({
            query: (params) => ({
                url: 'refresh',
                method: 'GET',
                params,
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: 'logout',
                method: 'POST',
            }),
        }),
        requestAccountRegistration: builder.mutation({
            query: (params) => ({
                url: 'account/request',
                method: 'POST',
                body: params,
            }),
        }),
        signup: builder.mutation({
            query: (params) => ({
                url: 'signup/register',
                method: 'POST',
                body: params,
            }),
        }),
        checkDuplicateTenantId: builder.query({
            query: (params) => ({
                url: `check-duplicate-tenant-id`,
                method: 'GET',
                params: params,
            }),
        }),
        checkDuplicateLoginId: builder.query({
            query: (params) => ({
                url: `check-duplicate-login-id`,
                method: 'GET',
                params: params,
            }),
        }),
        getUserPending: builder.query({
            query: (params) => ({
                url: `user-pendings`,
                method: 'GET',
                params: params,
            }),
        }),
        newUserJoinTenant: builder.mutation({
            query: (params) => ({
                url: 'user-pendings/join',
                method: 'POST',
                body: params,
            }),
        }),
        reCaptchaVerify: builder.mutation({
            query: (params) => ({
                url: 'recaptcha/verify',
                method: 'POST',
                body: params,
            }),
        }),
        resetPassword: builder.mutation({
            query: (params) => ({
                url: 'reset-password',
                method: 'POST',
                body: params,
            }),
        }),
        updatePassword: builder.mutation({
            query: (params) => ({
                url: 'update-password',
                method: 'POST',
                body: params,
            }),
        }),
        verifyUserPendings: builder.mutation({
            query: (params) => ({
                url: 'user-pendings/insert/verify',
                method: 'POST',
                body: params,
            }),
        }),
        GetCommonRequestAuthzProtectedAccessToken: builder.query({
            query: () => ({
                url: 'common-request-authz-protected-access-token',
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});
// 認証API(共通API用)
const authCommonApis = commonApis.injectEndpoints({
    endpoints: (builder) => ({
        AuthorizeCommonRequestAuthzProtectedAccessToken: builder.mutation({
            query: (params) => ({
                url: 'authorize-common-request-authz-protected-access-token',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useLoginMutation, useTwoFactorAuthMutation, useLogoutMutation, useRequestAccountRegistrationMutation, useLazyGetRefreshQuery, useSignupMutation, useCheckDuplicateTenantIdQuery, useLazyCheckDuplicateTenantIdQuery, useLazyCheckDuplicateLoginIdQuery, useGetUserPendingQuery, useNewUserJoinTenantMutation, useReCaptchaVerifyMutation, useResetPasswordMutation, useUpdatePasswordMutation, useVerifyUserPendingsMutation, useGetCommonRequestAuthzProtectedAccessTokenQuery, useLazyGetCommonRequestAuthzProtectedAccessTokenQuery, } = authApis;
export const { useAuthorizeCommonRequestAuthzProtectedAccessTokenMutation } = authCommonApis;
