import { FC, MouseEventHandler, memo, useCallback, useRef } from 'react'
import { useCrewChatMessageItemAction } from './useCrewChatMessageItemAction'
import { ReactComponent as EmoticonHappyPlusOutline } from 'assets/icons/emoticonHappyPlusOutline.svg'
import { useCrewEmojiPicker } from 'components/elements/crewEmojiPicker/crewEmojiPickerContext'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'

export type CrewChatMessageItemActionProps = {
  chatMessageId: string
}

export const CrewChatMessageItemAction: FC<CrewChatMessageItemActionProps> =
  memo((props) => {
    const { chooseEmoji } = useCrewChatMessageItemAction(props.chatMessageId)

    const { t } = useTranslation()
    const toast = useToast()

    const emojiButtonRef = useRef<HTMLSpanElement>(null)

    const { open: openEmojiPicker } = useCrewEmojiPicker()

    //Open emoji picker
    const handleOpenEmojiPicker: MouseEventHandler<HTMLSpanElement> =
      useCallback(
        (event) => {
          if (!emojiButtonRef?.current) {
            return
          }

          // クリックイベントはここで処理が完了するので、親要素に伝播させない
          event.stopPropagation()

          openEmojiPicker(emojiButtonRef.current, async (emoji: string) => {
            try {
              await chooseEmoji(emoji)
            } catch (err) {
              console.error(
                '[useCrewChatMessageActionMenu] chat reaction insert error.',
                err
              )
              toast.error(t('message.chat.failedToReaction'))
            }
          })
        },
        [chooseEmoji, openEmojiPicker, t, toast]
      )

    return (
      <>
        <span
          className="flex flex-row items-center crew-reaction relative"
          id={`showEmojiDialog_${props.chatMessageId}`}
          onClick={handleOpenEmojiPicker}
          ref={emojiButtonRef}
        >
          <EmoticonHappyPlusOutline />
        </span>
      </>
    )
  })
