import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'

export type FormValues = {
  companyName: string
  contactName: string
  departmentName: string
  officialPosition: string
  tel: string
}
const formInitialValues: FormValues = {
  companyName: '',
  contactName: '',
  departmentName: '',
  officialPosition: '',
  tel: '',
}

export const useContractRegisterCreditCardForm = (
  showContactInfomation: boolean
) => {
  // react-hook-formの各種データを取得
  const { control, formState, getValues, clearErrors, reset, handleSubmit } =
    useForm<FormValues>({
      defaultValues: formInitialValues,
      criteriaMode: 'all',
    })

  const { t } = useTranslation()

  // 契約者氏名の有無をチェック
  const checkRequiredContactName = useCallback(() => {
    if (showContactInfomation) {
      const contactName = getValues('contactName')
      // 連絡先情報を表示する場合は必須チェック
      if (contactName.trim().length === 0) {
        return false
      }
    }
    return true
  }, [getValues, showContactInfomation])

  // 電話番号の有無をチェック
  const checkRequiredTel = useCallback(() => {
    if (showContactInfomation) {
      const tel = getValues('tel')
      // 連絡先情報を表示する場合は必須チェック
      if (tel.trim().length === 0) {
        return t('message.general.isRequired', {
          name: t('label.tel'),
        })
      }

      // 空でなかった場合、[数字]-[数字]-[数字]の形式であることをチェック
      const regex = /^\d{1,4}-\d{1,4}-\d{1,4}$/
      if (!regex.test(tel)) {
        return t('message.general.invalidTel')
      }
    }
    return true
  }, [getValues, showContactInfomation, t])

  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      companyName: {
        required: t('message.general.isRequired', {
          name: t('label.companyName'),
        }),
      },
      contactName: {
        validate: {
          checkRequiredContactName: () =>
            checkRequiredContactName() ||
            t('message.general.isRequired', {
              name: t('label.contactName'),
            }),
        },
      },
      departmentName: {},
      officialPosition: {},
      tel: {
        validate: {
          checkRequiredTel: () => checkRequiredTel(),
        },
      },
    }),
    [checkRequiredContactName, checkRequiredTel, t]
  )

  return {
    control,
    formState,
    getValues,
    clearErrors,
    validateRules,
    reset,
    handleSubmit,
  }
}
