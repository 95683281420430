import classNames from 'classnames'
import { TimePeriod } from 'enums/app'
import { FC, memo } from 'react'
import { isWeekend } from 'utils/date'

type DataCellProps = React.PropsWithChildren<{
  data: {
    startDate: Date
  }
  currentView: string
}>

export const DataCell: FC<DataCellProps> = memo((props) => {
  return (
    <div
      className={classNames(
        'w-full h-full',
        isWeekend(props.data.startDate) &&
          'bg-crew-gray-50 dark:bg-crew-gray-800/50'
      )}
    >
      {props.currentView === TimePeriod.Monthly.value &&
        props.data.startDate.getDate()}
    </div>
  )
})
