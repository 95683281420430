import { useTranslation } from '@crew/modules/i18n'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { memo, useCallback, useEffect } from 'react'
import {
  FormValues,
  useProjectSettingAdvancedSetting,
} from './useProjectSettingAdvancedSetting'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useToast } from 'hooks/useToast'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useParams } from 'react-router-dom'
import { useProjectAdvancedSettings } from '@crew/hooks'
import { useAppSelector } from 'states/hooks'
import { ContractPlan } from '@crew/enums/app'

export const ProjectSettingAdvancedSetting = memo(() => {
  const { t } = useTranslation()
  const toast = useToast()
  const { projectId } = useParams()
  const { control, setError, handleSubmit, reset, updateProjectSettings } =
    useProjectSettingAdvancedSetting()

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  const { doTaskManagement, doEventManagement, doProjectManagement } =
    useProjectAdvancedSettings(projectId)

  useEffect(() => {
    //set value to form
    reset({
      doEventManagement,
      doTaskManagement,
      doProjectManagement,
    })
  }, [doEventManagement, doProjectManagement, doTaskManagement, reset])

  // Event handle when save button is clicked
  const handleSaveButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (!projectId) return

        await updateProjectSettings(projectId, data)
        toast.success(t('message.project.saveProjectSettingSuccess'))
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [handleSubmit, projectId, showApiErrors, t, toast, updateProjectSettings])
  return (
    <div className="max-w-2xl mx-auto flex flex-col gap-y-2.5 w-full">
      <p className="font-bold">{t('label.feature')}</p>
      <form>
        <div className="flex flex-col gap-y-2.5 pb-5 border-b crew-border-gray">
          {/** イベント管理  */}
          <div className="flex items-center justify-between">
            <label className="flex-1 crew-text-gray-4">
              {t('label.eventManagement')}
            </label>
            <div className="flex-1">
              <CrewCheckBoxField
                control={control}
                name="doEventManagement"
                label={t('label.eventManagementNote')}
              />
            </div>
          </div>

          {/** タスク管理 */}
          <div className="flex items-center justify-between">
            <label className="flex-1 crew-text-gray-4">
              {t('label.taskManagement')}
            </label>
            <div className="flex-1">
              <CrewCheckBoxField
                control={control}
                name="doTaskManagement"
                label={t('label.taskManagementNote')}
              />
            </div>
          </div>

          {/** プロジェクト管理 */}
          {currentPlan === ContractPlan.Professional && (
            <div className="flex items-center justify-between">
              <label className="flex-1 crew-text-gray-4">
                {t('label.projectManagement')}
              </label>
              <div className="flex-1">
                <CrewCheckBoxField
                  control={control}
                  name="doProjectManagement"
                  label={t('label.projectManagementNote')}
                />
              </div>
            </div>
          )}
        </div>

        <CrewButton
          text={t('action.toSave')}
          type="primary"
          className="mt-5"
          onClick={handleSaveButtonClick}
        />
      </form>
    </div>
  )
})
