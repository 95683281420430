import { FC, memo } from 'react'
import DocViewer, {
  DocViewerRenderers,
  IDocument,
} from '@cyntler/react-doc-viewer'

type CrewDocViewerProps = {
  documents: IDocument[]
}

export const CrewDocViewer: FC<CrewDocViewerProps> = memo((props) => {
  return (
    <DocViewer
      documents={props.documents}
      initialActiveDocument={props.documents[0]}
      pluginRenderers={DocViewerRenderers}
      prefetchMethod="GET"
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
        },
      }}
      className="crew-bg-default"
    />
  )
})
