import { memo } from 'react'

import { useTranslation } from '@crew/modules/i18n'

export type CrewUnreadLineProps = {
  show: boolean
}

export const CrewUnreadLine = memo((props: CrewUnreadLineProps) => {
  const { t } = useTranslation()

  return props.show ? (
    <div className="flex items-center h-4">
      <div className="flex-1 h-0 border-b-crew-red-2-light dark:border-b-crew-red-2-dark border-b"></div>
      <div className="text-sm text-crew-red-3-light dark:text-crew-red-2-dark pl-2">
        {t('label.unreadFromHere')}
      </div>
    </div>
  ) : null
})
