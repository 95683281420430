import { memo, useCallback } from 'react'
import Refresh from '~icons/material-symbols/refresh'
import ArrowBack from '~icons/material-symbols/arrow-back'
import ArrowForward from '~icons/material-symbols/arrow-forward'
import { CrewButton } from 'components/elements/crewButton/crewButton'
/**
 * ButtonNavigation
 * @date 9/21/2023 - 4:19:16 PM
 *
 * @type {*}
 */
export const ButtonNavigation = memo(() => {
  // handle window back
  const handleHistoryBackClick = useCallback(() => {
    window.history.back()
  }, [])

  // handle window forward
  const handleHistoryForwardClick = useCallback(() => {
    window.history.forward()
  }, [])

  // handle window reload
  const handleHistoryReloadClick = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <div className="flex flex-row gap-2.5">
      {/* back button */}
      <CrewButton
        stylingMode="text"
        icon={<ArrowBack width={24} height={24} />}
        onClick={handleHistoryBackClick}
      />

      {/* forward button */}
      <CrewButton
        stylingMode="text"
        icon={<ArrowForward width={24} height={24} />}
        onClick={handleHistoryForwardClick}
      />

      {/* reload button */}
      <CrewButton
        stylingMode="text"
        icon={<Refresh width={24} height={24} />}
        onClick={handleHistoryReloadClick}
      />
    </div>
  )
})
