import { forwardRef, memo } from 'react'
import { TreeView } from 'devextreme-react'
import { ITreeViewOptions } from 'devextreme-react/cjs/tree-view'

export const CrewTreeView = memo(
  forwardRef<TreeView, ITreeViewOptions>(({ children, ...rest }, ref) => (
    <TreeView ref={ref} {...rest}>
      {children}
    </TreeView>
  ))
)
