import { SettingKeyType } from '@crew/enums/app';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetProjectSettingsQuery } from '@crew/apis/setting/settingApis';
import { useMemo } from 'react';
/**
 * get setting value
 * @param settingResult
 * @param settingKey
 * @returns
 */
const getSettingValue = (settingResult, settingKey) => {
    var _a;
    return (((_a = settingResult === null || settingResult === void 0 ? void 0 : settingResult.projectSettings.find((setting) => setting.key === settingKey)) === null || _a === void 0 ? void 0 : _a.value) === 'true');
};
export const useProjectAdvancedSettings = (projectId) => {
    const projectSettingsRequestParams = projectId
        ? {
            projectId,
            keys: [
                SettingKeyType.DoProjectManagement,
                SettingKeyType.DoTaskManagement,
                SettingKeyType.DoEventManagement,
            ],
        }
        : undefined;
    const { data: getProjectSettings } = useGetProjectSettingsQuery(projectSettingsRequestParams !== null && projectSettingsRequestParams !== void 0 ? projectSettingsRequestParams : skipToken);
    // プロジェクト管理を行うか
    const doProjectManagement = getSettingValue(getProjectSettings, SettingKeyType.DoProjectManagement);
    // プロジェクト設定からタスク管理、イベント管理の設定を取得する
    const doTaskManagement = getSettingValue(getProjectSettings, SettingKeyType.DoTaskManagement);
    // プロジェクト設定からイベント管理の設定を取得する
    const doEventManagement = getSettingValue(getProjectSettings, SettingKeyType.DoEventManagement);
    return useMemo(() => ({
        doProjectManagement,
        doTaskManagement,
        doEventManagement,
    }), [doEventManagement, doProjectManagement, doTaskManagement]);
};
