import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from '@crew/modules/i18n'
import { UploadFile } from 'models/domain/uploadFile'
import { CrewUploadedFileList } from './crewUploadedFileList'

type CrewFileUploadDropZoneProps = {
  children: React.ReactNode
  dropZoneId?: string // ドロップ領域のID（DevExtremeのFileUploader用。CrewHtmlEditorでは不要）
  isShowDropZone: boolean
  isShowDropZoneAlways?: boolean | undefined // 常時ドロップ領域表示フラグ
  uploadedFileList: UploadFile[] | undefined
  fileUploaderDisabled?: boolean
}

export const CrewFileUploadDropZone: FC<CrewFileUploadDropZoneProps> = memo(
  (props) => {
    const { t } = useTranslation()

    const editorDropzoneRef = useRef<HTMLDivElement>(null)
    const [editorDropzoneHeight, setEditorDropzoneHeight] = useState<number>(0)

    // TODO: ドロップ領域の高さを自動調整する方法の調査
    // https://break-tmc.atlassian.net/browse/CREW-8427
    useEffect(() => {
      if (!editorDropzoneRef.current) {
        return
      }

      //update editorDropzoneHeight when editor height changed
      setEditorDropzoneHeight(editorDropzoneRef.current.clientHeight)
    }, [editorDropzoneRef.current?.clientHeight])

    //show dropzone when editor file uploader disabled is false and (isShowDropZone or isShowDropZoneAlways) is true
    const isVisibleDropzone = useMemo(() => {
      return (
        (props.isShowDropZoneAlways || props.isShowDropZone) &&
        !props.fileUploaderDisabled
      )
    }, [
      props.fileUploaderDisabled,
      props.isShowDropZone,
      props.isShowDropZoneAlways,
    ])

    return (
      <div
        id={props.dropZoneId}
        className="relative h-full"
        ref={editorDropzoneRef}
      >
        {/* ファイルドロップのマウスカーソルがエディタに乗るタイミングでアップローダーの機能を有効にするためz-indexを使用 */}
        <div
          className={classNames('h-full', {
            'opacity-0 z-10 relative editor-dropzone-wrapper':
              isVisibleDropzone,
          })}
          //set min-height to prevent editor height from changing when dropzone is shown and reset it when dropzone is hidden
          style={{
            minHeight: isVisibleDropzone
              ? editorDropzoneHeight + 'px'
              : 'initial',
          }}
        >
          {props.children}
        </div>

        {/* 「ドロップ領域を常時表示ON」または「ドロップ時のみ」ドロップ領域表示 */}
        {isVisibleDropzone && (
          <div
            className={classNames(
              'absolute top-0 left-0', // 子コンポーネントの上に重ねて表示するため、absolute
              'w-full h-full',
              'border-2 border-dashed', // 線
              'bg-crew-gray-1-light dark:bg-crew-gray-4-dark', // 背景色
              'flex items-center justify-center overflow-y-auto',
              // 通常時のドラッグ領域
              {
                'dark:border-crew-gray-3-dark': !props.isShowDropZone,
              },
              // ファイルドラッグ時のドラッグ領域
              {
                'border-crew-blue-3-light dark:border-crew-blue-3-dark':
                  props.isShowDropZone,
              }
            )}
          >
            {props.isShowDropZoneAlways &&
            props.uploadedFileList &&
            props.uploadedFileList.length > 0 ? (
              // 「ドロップ領域を常時表示」かつ「アップロード済みのファイルがある」場合にアップロード済みファイル一覧を表示
              <CrewUploadedFileList
                uploadedFileList={props.uploadedFileList}
                direction="vertical"
              />
            ) : (
              <>
                {/* ドロップ領域の常時表示有無によるラベル表記 */}
                {props.isShowDropZoneAlways
                  ? t('label.dragDropOrClickFile')
                  : t('label.dropFileHere')}
              </>
            )}
          </div>
        )}
      </div>
    )
  }
)
