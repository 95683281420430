import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import Web from '~icons/mdi/web'
import { useGetOgpDataQuery } from '@crew/apis/ogp/ogpApis'

export const LinkCard: React.FC<{ url: string; nodeKey: string }> = ({
  url,
  nodeKey,
}) => {
  const { data: ogpData, isError } = useGetOgpDataQuery({ url })

  if (isError || !ogpData) {
    return (
      <BlockWithAlignableContents
        format={''}
        nodeKey={nodeKey}
        className={{
          base: 'relative w-full flex',
          focus: 'relative w-full flex outline outline-crew-blue-200',
        }}
      >
        <div className="w-full flex">
          <div className="w-full flex items-center rounded-md border p-2 h-20 sm:h-24 hover:shadow crew-shadow-gray overflow-hidden line-clamp-2 break-all">
            {url}
          </div>
        </div>
      </BlockWithAlignableContents>
    )
  }

  if (ogpData.title) {
    if (ogpData.image && ogpData.image.length > 0) {
      const imageUrl = ogpData.image?.[0]?.url || ''
      return (
        <BlockWithAlignableContents
          format={''}
          nodeKey={nodeKey}
          className={{
            base: 'relative w-full flex',
            focus: 'relative w-full flex outline outline-crew-blue-200',
          }}
        >
          <div className="w-full flex">
            <div className="w-full flex justify-around items-center rounded-md border h-20 sm:h-24 hover:shadow crew-shadow-gray">
              <div className="flex h-full w-40 sm:w-48">
                <img
                  src={imageUrl}
                  alt={ogpData.title}
                  className="object-cover h-full w-full"
                />
              </div>
              <div className="flex-1 flex flex-col justify-start p-2 sm:p-4 gap-1">
                <div
                  className="font-bold line-clamp-1 sm:line-clamp-2"
                  title={ogpData.title}
                >
                  {ogpData.title}
                </div>
                <div
                  className="crew-text-gray-4 text-sm line-clamp-2"
                  title={ogpData.description}
                >
                  {ogpData.description}
                </div>
              </div>
            </div>
          </div>
        </BlockWithAlignableContents>
      )
    } else {
      return (
        <BlockWithAlignableContents
          format={''}
          nodeKey={nodeKey}
          className={{
            base: 'relative w-full flex',
            focus: 'relative w-full flex outline outline-crew-blue-200',
          }}
        >
          <div className="w-full flex">
            <div className="w-full flex justify-around items-center rounded-md border h-20 sm:h-24 hover:shadow crew-shadow-gray">
              <div className="flex h-full items-center justify-center w-40 sm:w-48 text-crew-blue-500 dark:text-crew-blue-600 bg-crew-blue-100 dark:bg-crew-blue-200">
                <Web width={48} height={48} />
              </div>
              <div className="flex-1 flex flex-col justify-start p-2 sm:p-4 gap-1">
                <div
                  className="font-bold text-black line-clamp-2 break-all"
                  title={ogpData.title}
                >
                  {ogpData.title}
                </div>
              </div>
            </div>
          </div>
        </BlockWithAlignableContents>
      )
    }
  } else {
    return (
      <BlockWithAlignableContents
        format={''}
        nodeKey={nodeKey}
        className={{
          base: 'relative w-full flex',
          focus: 'relative w-full flex outline outline-crew-blue-200',
        }}
      >
        <div className="w-full flex">
          <div className="w-full flex items-center rounded-md border p-2  h-20 sm:h-24 hover:shadow crew-shadow-gray overflow-hidden line-clamp-2 break-all">
            {url}
          </div>
        </div>
      </BlockWithAlignableContents>
    )
  }
}
