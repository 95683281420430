import { apis, commonApis } from '../apiBase/apiBase';
// SSO API(テナント用)
export const ssoApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        startSsoFreshdesk: builder.mutation({
            query: () => ({
                url: 'start-sso-freshdesk',
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
});
// SSO API(共通API用)
export const ssoCommonApis = commonApis.injectEndpoints({
    endpoints: (builder) => ({
        freshdesk: builder.mutation({
            query: (params) => ({
                url: 'sso/freshdesk',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useStartSsoFreshdeskMutation } = ssoApis;
export const { useFreshdeskMutation } = ssoCommonApis;
