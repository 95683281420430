import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TaskKindFilters } from 'enums/app'

type MyTaskStateType = {
  myTaskListSearchParams: {
    keyword: string
    taskKindId: string
  }
}

const initialMyTaskState: MyTaskStateType = {
  myTaskListSearchParams: {
    keyword: '',
    taskKindId: TaskKindFilters.AllTaskKinds,
  },
}

export const myTaskSlice = createSlice({
  name: 'myTask',
  initialState: initialMyTaskState,
  reducers: {
    myTaskListSearchKeywordUpdated: (state, action: PayloadAction<string>) => {
      state.myTaskListSearchParams.keyword = action.payload
    },
    myTaskListSearchTaskKindUpdated: (state, action: PayloadAction<string>) => {
      state.myTaskListSearchParams.taskKindId = action.payload
    },
  },
})

export const {
  myTaskListSearchKeywordUpdated,
  myTaskListSearchTaskKindUpdated,
} = myTaskSlice.actions
