import { memo, useCallback, useEffect, useState } from 'react'
import { CrewAttachmentThumbnailItem } from './components/crewAttachmentThumbnailItem/crewAttachmentThumbnailItem'
import { CrewImagePreviewLightbox } from '../crewImagePreviewLightbox/crewImagePreviewLightbox'
import { FileHistory, File } from '@crew/models/domain'
import { isFileHistory, ulidItemComparer } from '@crew/utils'
import {
  useLazyGetFileHistoryS3SignedUrlsQuery,
  useLazyGetFileS3SignedUrlsQuery,
} from '@crew/apis/dist/file/fileApis'
import { Slide } from 'yet-another-react-lightbox'
import { CrewLightboxDownloadIcon } from '../crewImagePreviewLightbox/components/crewLightboxDownloadIcon'
import { formatByteSize } from '@crew/utils/dist/number'
import { useValueChangeEffect } from '@crew/hooks'
import { isImageFile, isVideoFile } from '@crew/utils/dist/chat'
import classNames from 'classnames'

export type CrewAttachmentThumbnailsProps = {
  attachmentThumbnails: File[] | FileHistory[]
  showDeleteButton: boolean
  onFileDeleted?: () => void // ファイル削除後のイベント
  canDownload: boolean
}

export const CrewAttachmentThumbnails = memo(
  (props: CrewAttachmentThumbnailsProps) => {
    const [attachmentThumbnails, setAttachmentThumbnails] = useState<
      (File | FileHistory)[]
    >(props.attachmentThumbnails)
    const [slideIndex, setSildeIndex] = useState(0)

    const [isOpenPreview, setOpenPreview] = useState(false)
    const [fileIds, setFileIds] = useState<string[]>([])
    const [fileHistoryIds, setFileHistoryIds] = useState<string[]>([])
    const [slides, setSlides] = useState<Slide[]>([])

    // idで昇順ソートするための比較関数を取得
    const sortAscById = ulidItemComparer('asc')

    useValueChangeEffect(
      () => {
        props.attachmentThumbnails
          .sort((a, b) => {
            // サムネイル表示順とLightbox上の表示順を合わせるため、id順にソートする
            return sortAscById(a, b)
          })
          .forEach((attachment) => {
            if (isFileHistory(attachment)) {
              setFileHistoryIds((prev) => [...prev, attachment.id])
            } else {
              setFileIds((prev) => [...prev, attachment.id])
            }
          })
      },
      [sortAscById, props.attachmentThumbnails],
      props.attachmentThumbnails
    )

    const [lazyGetFileS3SignedUrlsQuery] = useLazyGetFileS3SignedUrlsQuery()
    const [lazyGetFileHistoryS3SignedUrlsQuery] =
      useLazyGetFileHistoryS3SignedUrlsQuery()

    // 他ユーザによる添付ファイル削除でwebsocket経由でファイル情報が更新されたら添付ファイル表示に反映
    useEffect(() => {
      setAttachmentThumbnails(props.attachmentThumbnails)
    }, [props.attachmentThumbnails])

    // ファイル削除後にattachmentsから該当項目を削除して表示上から対象ファイル表記を消す
    const handleDeleteAttachmentItem = useCallback(
      (fileId: string) => {
        // attachmentsに格納しているファイル情報を削除する
        const newAttachmentThumbnails = attachmentThumbnails.filter(
          (attachment) => attachment.id !== fileId
        )

        setAttachmentThumbnails(newAttachmentThumbnails)
        // ファイル削除後のイベントが設定されている場合は実行
        props.onFileDeleted && props.onFileDeleted()
      },
      [attachmentThumbnails, props]
    )

    // 画像プレビューLightboxを表示
    const handleOpenImagePreviewLightbox = useCallback(
      async (index: number) => {
        setSildeIndex(index)
        setOpenPreview(true)

        // Get slide images for lightbox
        let tempSlides: Slide[] = []
        if (fileHistoryIds.length > 0) {
          // FileHistoryの場合
          const response = await lazyGetFileHistoryS3SignedUrlsQuery({
            fileHistoryIds,
          }).unwrap()

          // response.resultがreadonlyのため、sortできるように別配列として生成
          const s3SignedUrls = [...response.result]

          s3SignedUrls
            .sort((a, b) => {
              // サムネイル表示順とLightbox上の表示順を合わせるため、id順にソートする
              return sortAscById(a, b)
            })
            .forEach((file) => {
              if (isImageFile(file.fileName)) {
                tempSlides.push({
                  src: file.url,
                  title: `${file.fileName} (${formatByteSize(file.fileSize)})`,
                  description: props.canDownload ? (
                    <CrewLightboxDownloadIcon
                      key={file.id}
                      url={file.url}
                      fileName={file.fileName}
                    />
                  ) : (
                    <></>
                  ),
                })
              }

              if (isVideoFile(file.fileName)) {
                tempSlides.push({
                  type: 'video',
                  title: `${file.fileName} (${formatByteSize(file.fileSize)})`,
                  description: props.canDownload ? (
                    <CrewLightboxDownloadIcon
                      key={file.id}
                      url={file.url}
                      fileName={file.fileName}
                    />
                  ) : (
                    <></>
                  ),
                  // ダウンロード不可の場合、chromeのコントロール中のダウンロードボタンを非表示にする
                  // Firefoxは元々ダウンロードボタンがない
                  controlsList: classNames({
                    nodownload: !props.canDownload,
                  }),
                  autoPlay: true,
                  sources: [
                    {
                      src: file.url,
                      type: 'video/mp4',
                    },
                  ],
                  // ビデオプレイヤーが全画面表示になった時、下のdivタグ（ダウンロードボタンを含むdivタグ）が重なって表示される。
                  // この問題を回避するには、ビデオプレーヤーの幅と高さを設定する。
                  // ブラウザーのサイズが設定したサイズより小さい場合、ビデオプレーヤーは自動的にリサイズされる。
                  width: 1280,
                  height: 720,
                })
              }
            })
        }
        if (fileIds.length > 0) {
          // Fileの場合
          const response = await lazyGetFileS3SignedUrlsQuery({
            fileIds,
          }).unwrap()

          // response.resultがreadonlyのため、sortできるように別配列として生成
          const s3SignedUrls = [...response.result]

          s3SignedUrls
            .sort((a, b) => {
              // サムネイル表示順とLightbox上の表示順を合わせるため、id順にソートする
              return sortAscById(a, b)
            })
            .forEach((file) => {
              console.log(file)
              if (isImageFile(file.fileName)) {
                tempSlides.push({
                  src: file.url,
                  title: `${file.fileName} (${formatByteSize(file.fileSize)})`,
                  description: props.canDownload ? (
                    <CrewLightboxDownloadIcon
                      key={file.id}
                      url={file.url}
                      fileName={file.fileName}
                    />
                  ) : (
                    <></>
                  ),
                })
              }

              if (isVideoFile(file.fileName)) {
                tempSlides.push({
                  type: 'video',
                  title: `${file.fileName} (${formatByteSize(file.fileSize)})`,
                  description: props.canDownload ? (
                    <CrewLightboxDownloadIcon
                      key={file.id}
                      url={file.url}
                      fileName={file.fileName}
                    />
                  ) : (
                    <></>
                  ),
                  // ダウンロード不可の場合、chromeのコントロール中のダウンロードボタンを非表示にする
                  // Firefoxは元々ダウンロードボタンがない
                  controlsList: classNames({
                    nodownload: !props.canDownload,
                  }),
                  autoPlay: true,
                  sources: [
                    {
                      src: file.url,
                      type: 'video/mp4',
                    },
                  ],
                  // ビデオプレイヤーが全画面表示になった時、下のdivタグ（ダウンロードボタンを含むdivタグ）が重なって表示される。
                  // この問題を回避するには、ビデオプレーヤーの幅と高さを設定する。
                  // ブラウザーのサイズが設定したサイズより小さい場合、ビデオプレーヤーは自動的にリサイズされる。
                  width: 1280,
                  height: 720,
                })
              }
            })
        }

        setSlides(tempSlides)
      },
      [
        fileHistoryIds,
        fileIds,
        sortAscById,
        lazyGetFileHistoryS3SignedUrlsQuery,
        lazyGetFileS3SignedUrlsQuery,
        props.canDownload,
      ]
    )

    // 画像プレビューLightboxを閉じる
    const handleClosedImagePreviewLightbox = useCallback(() => {
      setOpenPreview(false)
    }, [])

    return attachmentThumbnails.length > 0 ? (
      <div className="flex flex-row items-center justify-start flex-wrap">
        {/* 添付画像サムネイル表示 */}
        {attachmentThumbnails.map((attachment, index) => (
          <CrewAttachmentThumbnailItem
            key={attachment.id}
            attachment={attachment}
            showDeleteButton={props.showDeleteButton}
            onDeleteAttachmentItem={handleDeleteAttachmentItem}
            onOpenImagePreview={() => handleOpenImagePreviewLightbox(index)}
          />
        ))}

        {/* 画像プレビュー用Lightbox */}
        <CrewImagePreviewLightbox
          slides={slides}
          slideIndex={slideIndex}
          isOpen={isOpenPreview}
          onClosed={handleClosedImagePreviewLightbox}
          canDownload={props.canDownload}
        />
      </div>
    ) : (
      <></>
    )
  }
)
