import { useInsertProjectForDirectChannelMutation } from '@crew/apis/dist/project/projectApis'
import { useCallback } from 'react'

export const useDirectMessageUserPanel = () => {
  // Get function for insert direct chanel
  const [insertProjectForDirectChannelMutation] =
    useInsertProjectForDirectChannelMutation()

  // Insert direct chanel process
  const insertDirectChannel = useCallback(
    async (targetUserId: string) => {
      await insertProjectForDirectChannelMutation({ targetUserId }).unwrap()
    },
    [insertProjectForDirectChannelMutation]
  )

  return {
    insertDirectChannel,
  }
}
