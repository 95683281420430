import { useTranslation } from '@crew/modules/i18n'
import { useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import {
  useInsertProjectGroupMutation,
  useUpdateProjectGroupMutation,
} from '@crew/apis/project/projectApis'
import { useAppDispatch } from 'states/hooks'
import {
  notifyProjectGroupEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { InsertProjectGroupRequest } from '@crew/apis/project/models/insertProjectGroup/request'
import { NotifyEventType } from 'enums/app'
import { ProjectGroup } from '@crew/models/domain'
import { UpdateProjectGroupRequest } from '@crew/apis/project/models/updateProjectGroup/request'
import { ValidateRules } from '@crew/utils/form'

export type FormValues = {
  id: string | null
  name: string
  version: number | null
}

// ダイアログ初期値
const formInitialValues: FormValues = {
  id: null,
  name: '',
  version: null,
}

export const useProjectGroupEntryForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // Get functions for update project group information
  const [insertProjectGroupMutation] = useInsertProjectGroupMutation()
  const [updateProjectGroupMutation] = useUpdateProjectGroupMutation()

  // react-hook-formの各種データを取得
  const { control, reset, formState, handleSubmit, clearErrors, setError } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Insert project group process
  const insertProjectGroup = useCallback(
    async (data: FormValues) => {
      const request: InsertProjectGroupRequest = {
        projectGroup: {
          name: data.name,
        },
      }

      let result = await insertProjectGroupMutation(request).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<ProjectGroup> = {
        eventType: NotifyEventType.Inserted,
        id: result.projectGroup?.id ?? '',
        object: result.projectGroup ?? undefined,
      }

      dispatch(notifyProjectGroupEvent(objectEventMessage))

      return result
    },
    [dispatch, insertProjectGroupMutation]
  )

  // Insert project group process
  const updateProjectGroup = useCallback(
    async (data: FormValues) => {
      const request: UpdateProjectGroupRequest = {
        projectGroup: {
          id: data.id as string,
          name: data.name,
          version: data.version as number,
        },
      }

      let result = await updateProjectGroupMutation(request).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<ProjectGroup> = {
        eventType: NotifyEventType.Updated,
        id: result.projectGroup?.id ?? '',
        object: result.projectGroup ?? undefined,
      }

      dispatch(notifyProjectGroupEvent(objectEventMessage))

      return result
    },
    [dispatch, updateProjectGroupMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      name: {
        required: t('message.general.isRequired', {
          name: t('label.name'),
        }),
      },
      // not validate below
      id: {},
      version: {},
    }),
    [t]
  )

  return {
    control,
    reset,
    formState,
    handleSubmit,
    clearErrors,
    setError,

    validateRules,

    insertProjectGroup,
    updateProjectGroup,
  }
}
