import dayjs from '@crew/modules/dayjs'
import {
  apis,
  commonApis,
  Event,
  EventHandler,
  setEventHandler,
  setTimezoneGenerator,
} from '@crew/apis/apiBase'

import { LocalStorageKeys } from 'enums/system'
import { store } from 'states/store'

import { setLoggedOutUser } from 'features/app/states/appSlice'

const handleRefreshFailed: EventHandler = () => {
  store.dispatch(setLoggedOutUser())
}

/**
 * バージョンが古く更新が必要なときに呼ぶイベントハンドラ
 */
const handleUpgradeRequired: EventHandler = () => {
  // リロード実行
  // ⇒index.htmlがキャッシュ無効化されているため、リロードすると新しいindex.htmlが読み込まれる(=javascriptも最新バージョンが読み込まれる)
  if (typeof window !== 'undefined') {
    window.location.reload()
  }
}

setEventHandler(Event.RefreshFailed, handleRefreshFailed)
setEventHandler(Event.UpgradeRequired, handleUpgradeRequired)
setTimezoneGenerator(
  () => localStorage.getItem(LocalStorageKeys.TimezoneId) ?? dayjs.tz.guess()
)

export { apis, commonApis }
