import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { Popover } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof Popover>>
export const CrewPopover = memo(
  forwardRef<Popover, Props>(({ children, ...rest }, ref) => (
    <Popover ref={ref} {...rest}>
      {children}
    </Popover>
  ))
)
