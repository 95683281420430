import { ComponentProps, useMemo } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { genericMemo } from 'utils'
import { CrewCheckBox } from 'components/devextreme/crewCheckBox'
import { CrewErrorMessages } from 'components/forms/crewErrorMessages'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  //ref～isValidは、controllerが提供するのでpropsから除外する
  //textは、テキスト表示をlabelプロパティで行うためpropsから除外する
  ComponentProps<typeof CrewCheckBox>,
  'ref' | 'name' | 'value' | 'onValueChange' | 'isValid' | 'text'
> & {
  required?: boolean
  label?: string
  description?: string
  showLabel?: boolean
} & UseControllerProps<TFieldValues, TName>

/**
 * CheckBoxのラッパー（react-hook-form対応版）
 */
export const CrewCheckBoxField = genericMemo(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
    // useController用
    name,
    rules,
    control,
    shouldUnregister,
    defaultValue,
    // ラベル用
    required = false,
    label,
    showLabel = true,
    // その他
    children,
    ...rest
  }: Props<TFieldValues, TName>) => {
    const { field, fieldState, formState } = useController({
      name,
      control,
      rules,
      shouldUnregister,
      defaultValue,
    })

    // 要素にid属性を付与するためのオブジェクト
    // data-testidのようなカスタム属性はelementAttrで指定する必要がある
    // https://supportcenter.devexpress.com/ticket/details/t1052998/form-unable-to-pass-custom-attributes-to-items
    const elementAttr = useMemo(() => {
      return { 'data-testid': field.name }
    }, [field.name])

    return (
      <div className="flex flex-col gap-1">
        <div className="flex flex-row gap-2 items-center cursor-pointer">
          <CrewCheckBox
            {...rest}
            elementAttr={elementAttr}
            ref={field.ref}
            name={field.name}
            value={field.value}
            onValueChange={field.onChange}
            isValid={!fieldState.error}
            text={showLabel ? label : ''} // このtext指定がないと、label部分をクリックしたときに切り替えられない
          >
            {children}
          </CrewCheckBox>
          {showLabel && label && (
            // CrewCheckBox側のtext属性がラベルクリック時の動作に必要で、
            // ラベル表示ありでrequired指定の場合は"*"を表示したいのでCrewFieldLabelを置いている。
            // text属性側で指定したものがラベルとして表示されるため、
            // CrewFieldLabelのtext属性は指定しない。
            <CrewFieldLabel required={required} />
          )}
        </div>
        <CrewErrorMessages
          isValid={!fieldState.error}
          errors={formState.errors}
          field={field}
        />
      </div>
    )
  }
)
