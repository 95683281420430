import { memo } from 'react'
import { ProjectTaskTable } from './components/projectTaskTable/projectTaskTable'

export const ProjectDetailTaskListDataGrid = memo(() => {
  return (
    <div className="flex-1 overflow-y-hidden">
      {/* Project task table */}
      <ProjectTaskTable />
    </div>
  )
})
