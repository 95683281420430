import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { CrewTaskSearchInput } from 'components/elements/crewSearchInput/components/crewTaskSearchInput/crewTaskSearchInput'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import ArrayStore from 'devextreme/data/array_store'
import { ItemClickEvent } from 'devextreme/ui/drop_down_button'
import { TaskSearchOptions } from 'enums/app'
import _ from 'lodash'
import qs from 'qs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getParamAsArray, getParamAsString } from 'utils'
import {
  FilterDefinition,
  ParamValue,
  SearchFilter,
  SearchOption,
} from 'utils/filter'
import { useLazyGetLookupFilterQuery } from '@crew/apis/lookup/lookupApis'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewSaveFilterDialog } from 'components/elements/crewSaveFilterDialog/crewSaveFilterDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { TASK_DEFAULT_SORT_COLUMN } from 'configs/constants'
import { CrewDropDownButton } from 'components/devextreme/crewDropDownButton'
import { useFilterDataSource } from 'hooks/dataSource/useFilterDataSource'
import { FilterRef } from '@crew/models/refs'
import { CrewFilterSettingsDialog } from 'components/elements/crewFilterSettingsDialog/crewFilterSettingsDialog'
import { useValueChangeEffect } from '@crew/hooks'
import Save from '~icons/material-symbols/save'
import OutlineSettings from '~icons/ic/outline-settings'
import Close from '~icons/material-symbols/close'

// 検索項目の選択肢
const searchOptions: SearchOption[] = Object.values(TaskSearchOptions).map(
  (option) => {
    return {
      id: option.id,
      name: option.name,
      label: option.label,
      defaultValue: option.defaultValue as ParamValue,
      defaultShown: option.defaultShown,
    }
  }
)

// 指定されたidの検索項目を返す
const findSearchOption = (id: string) => {
  return searchOptions.find((o) => o.id === id)
}

// Parse the query string and return a Map of SearchFilter
const parseFilters = (params: qs.ParsedQs): Map<string, SearchFilter> => {
  const filters = new Map<string, SearchFilter>()

  // 順序を維持するためループ処理する
  for (const param in params) {
    if (param === TaskSearchOptions.Keyword.id) {
      filters.set(TaskSearchOptions.Keyword.id, {
        field: TaskSearchOptions.Keyword.id,
        value: getParamAsString(TaskSearchOptions.Keyword.id, params),
      })
    }

    if (param === TaskSearchOptions.TaskKindId.id) {
      filters.set(TaskSearchOptions.TaskKindId.id, {
        field: TaskSearchOptions.TaskKindId.id,
        value: getParamAsArray(TaskSearchOptions.TaskKindId.id, params),
      })
    }

    if (param === TaskSearchOptions.AssignToUser.id) {
      filters.set(TaskSearchOptions.AssignToUser.id, {
        field: TaskSearchOptions.AssignToUser.id,
        value: getParamAsString(TaskSearchOptions.AssignToUser.id, params),
      })
    }

    if (param === TaskSearchOptions.TaskStateId.id) {
      filters.set(TaskSearchOptions.TaskStateId.id, {
        field: TaskSearchOptions.TaskStateId.id,
        value: getParamAsArray(TaskSearchOptions.TaskStateId.id, params),
      })
    }

    if (param === TaskSearchOptions.TaskStateType.id) {
      filters.set(TaskSearchOptions.TaskStateType.id, {
        field: TaskSearchOptions.TaskStateType.id,
        value: getParamAsArray(TaskSearchOptions.TaskStateType.id, params),
      })
    }

    if (param === TaskSearchOptions.TaskPriority.id) {
      filters.set(TaskSearchOptions.TaskPriority.id, {
        field: TaskSearchOptions.TaskPriority.id,
        value: getParamAsArray(TaskSearchOptions.TaskPriority.id, params),
      })
    }

    if (param === TaskSearchOptions.TaskCategoryId.id) {
      filters.set(TaskSearchOptions.TaskCategoryId.id, {
        field: TaskSearchOptions.TaskCategoryId.id,
        value: getParamAsArray(TaskSearchOptions.TaskCategoryId.id, params),
      })
    }

    if (param === TaskSearchOptions.ProjectId.id) {
      filters.set(TaskSearchOptions.ProjectId.id, {
        field: TaskSearchOptions.ProjectId.id,
        value: getParamAsString(TaskSearchOptions.ProjectId.id, params),
      })
    }

    if (param === TaskSearchOptions.ProjectGroupId.id) {
      filters.set(TaskSearchOptions.ProjectGroupId.id, {
        field: TaskSearchOptions.ProjectGroupId.id,
        value: getParamAsArray(TaskSearchOptions.ProjectGroupId.id, params),
      })
    }

    if (param === TaskSearchOptions.StartDate.id) {
      filters.set(TaskSearchOptions.StartDate.id, {
        field: TaskSearchOptions.StartDate.id,
        value: getParamAsString(TaskSearchOptions.StartDate.id, params), // thatDay:2021-09-01の形式
      })
    }

    if (param === TaskSearchOptions.DueDate.id) {
      filters.set(TaskSearchOptions.DueDate.id, {
        field: TaskSearchOptions.DueDate.id,
        value: getParamAsString(TaskSearchOptions.DueDate.id, params), // thatDay:2021-09-01の形式
      })
    }

    if (param === TaskSearchOptions.CreatedById.id) {
      filters.set(TaskSearchOptions.CreatedById.id, {
        field: TaskSearchOptions.CreatedById.id,
        value: getParamAsString(TaskSearchOptions.CreatedById.id, params),
      })
    }

    if (param === TaskSearchOptions.UpdatedById.id) {
      filters.set(TaskSearchOptions.UpdatedById.id, {
        field: TaskSearchOptions.UpdatedById.id,
        value: getParamAsString(TaskSearchOptions.UpdatedById.id, params),
      })
    }

    if (param === TaskSearchOptions.CreatedAt.id) {
      filters.set(TaskSearchOptions.CreatedAt.id, {
        field: TaskSearchOptions.CreatedAt.id,
        value: getParamAsString(TaskSearchOptions.CreatedAt.id, params), // thatDay:2021-09-01の形式
      })
    }

    if (param === TaskSearchOptions.UpdatedAt.id) {
      filters.set(TaskSearchOptions.UpdatedAt.id, {
        field: TaskSearchOptions.UpdatedAt.id,
        value: getParamAsString(TaskSearchOptions.UpdatedAt.id, params), // thatDay:2021-09-01の形式
      })
    }
  }

  return filters
}

// Parse the query string and return a array of orders
const parseOrders = (params: qs.ParsedQs): string[] => {
  return getParamAsArray('sort', params) || TASK_DEFAULT_SORT_COLUMN
}

// convert params to filter definition
const paramsToFilterDefinition = (params: qs.ParsedQs): FilterDefinition => {
  return {
    filters: Object.fromEntries(parseFilters(params)),
    orders: parseOrders(params),
  }
}

// convert filter definition to params
const filterDefinitionToParams = (
  filterDefinition: FilterDefinition
): qs.ParsedQs => {
  // デフォルトのパラメータ
  const newParams: qs.ParsedQs = {}

  if (filterDefinition.filters) {
    const filters = new Map<string, SearchFilter>(
      Object.entries(filterDefinition.filters)
    )

    filters.forEach((filter) => {
      newParams[filter.field] = filter.value
    })
  }

  newParams['sort'] = filterDefinition.orders
  return newParams
}

// パラメータが変更されたかどうかを判定する
// パラメータの値がundefinedまたは空配列の項目は除外して比較する
const hasParamChanged = (
  params1: qs.ParsedQs,
  params2: qs.ParsedQs
): boolean => {
  const filter1 = Object.fromEntries(parseFilters(params1))
  const filter2 = Object.fromEntries(parseFilters(params2))
  const cleanedFilter1 = _.pickBy(
    filter1,
    (filter) =>
      !(
        filter.value === undefined ||
        (Array.isArray(filter.value) && filter.value.length === 0)
      )
  )
  const cleanedFilter2 = _.pickBy(
    filter2,
    (filter) =>
      !(
        filter.value === undefined ||
        (Array.isArray(filter.value) && filter.value.length === 0)
      )
  )
  const hasChanged = !_.isEqual(cleanedFilter1, cleanedFilter2)
  return hasChanged
}

export const TaskSearch: FC = memo(() => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const params = useMemo(
    () => qs.parse(searchParams.toString()),
    [searchParams]
  )

  const [
    isCrewSaveFilterDialogOpen,
    openCrewSaveFilterDialog,
    closeCrewSaveFilterDialog,
  ] = useModal()

  // フィルタ選択時に該当する検索条件を取得するためのクエリ
  const [lazyGetFilterQuery] = useLazyGetLookupFilterQuery()

  // custom data source for filter
  const filterDataSource = useFilterDataSource(EntityType.Task)
  const [filter, setFilter] = useState<string | undefined>()

  const [activeFilters, setActiveFilters] = useState<Map<string, SearchFilter>>(
    new Map()
  )

  useValueChangeEffect(
    () => {
      // Get initial filter from params or default filter
      const initialFilter = async () => {
        // get filter from params
        const filter = getParamAsString('filter', params)
        if (filter) {
          setFilter(filter)
        } else {
          //Need get filters from filterDataSource
          const filters = (await filterDataSource.store().load()) as FilterRef[]
          const defaultFilter = filters.find((filter) => filter.default)

          setFilter(defaultFilter?.id)
        }
      }

      initialFilter()
    },
    [filterDataSource, params],
    params
  )

  useEffect(() => {
    const parsedFilters = parseFilters(params)

    // 初期表示する検索条件を作成する
    const defaultFilters = new Map<string, SearchFilter>()
    searchOptions
      .filter((o) => o.defaultShown)
      .forEach((o) => {
        defaultFilters.set(o.id, { field: o.id, value: o.defaultValue })
      })

    // defaultFilters を新しい Map にコピー
    const filters = new Map<string, SearchFilter>(defaultFilters)

    // parsedFilters で filters を上書きまたは追加
    parsedFilters.forEach((value, key) => {
      filters.set(key, value)
    })

    setActiveFilters(filters)
  }, [params])

  // 検索条件を追加する
  const addFilter = useCallback((field: string) => {
    const option = searchOptions.find((o) => o.id === field)
    if (!option) return

    setActiveFilters((prevFilters) => {
      // 現在のフィルターを新しい Map にコピー
      const updatedFilters = new Map(prevFilters)

      // 新しいフィルターを追加
      updatedFilters.set(option.id, {
        field: option.id,
        value: option.defaultValue,
      })

      return updatedFilters
    })
  }, [])

  // 検索条件を削除する
  const deleteFilter = useCallback((field: string) => {
    setActiveFilters((prevFilters) => {
      // 現在のフィルターを新しい Map にコピー
      const updatedFilters = new Map(prevFilters)

      // 指定されたキーのフィルターを削除
      updatedFilters.delete(field)

      return updatedFilters
    })
  }, [])

  // フィルターをマージする
  const mergeFilter = useCallback(
    (params: qs.ParsedQs, filters: Map<string, SearchFilter>): qs.ParsedQs => {
      const newParams = { ...params }
      filters.forEach((filter) => {
        newParams[filter.field] = filter.value
      })

      return newParams
    },
    []
  )

  // 検索条件を更新する
  const updateSearchValue = useCallback(
    (filter: SearchFilter, value: ParamValue) => {
      const newFilters = new Map(activeFilters)
      newFilters.set(filter.field, { field: filter.field, value })

      // FIXME: FirefoxやSafariで条件追加が機能しない問題があり、ここでsetActiveFiltersを行うという
      // ワークアラウンドを行っている。以下タスクでReactの仕様も含め調査する予定
      // https://break-tmc.atlassian.net/browse/CREW-13844

      // 検索条件を更新する
      setActiveFilters(newFilters)

      // paramsに新しいフィルタをマージする
      const newParams = mergeFilter(params, newFilters)

      // 変更がない場合はnavigateしない
      if (!hasParamChanged(params, newParams)) return

      // pageIndexを0にする
      newParams['pageIndex'] = '0'
      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })
      navigate(`?${newQueryString}`)
    },
    [activeFilters, mergeFilter, navigate, params]
  )

  // This is a callback function that handles the click event on the field drop-down item.
  const handleFieldDropDownItemClick = useCallback(
    (e: ItemClickEvent) => {
      // すでに同じフィルターがある場合は追加しない
      if (activeFilters.has(e.itemData.id)) return
      addFilter(e.itemData.id)
    },
    [activeFilters, addFilter]
  )

  // This is a callback function that handles the click event on the delete filter button.
  const handleDeleteFilterButtonClick = useCallback(
    (filter: SearchFilter) => {
      deleteFilter(filter.field)

      const newParams = { ...params }
      delete newParams[filter.field]

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })
      navigate(`?${newQueryString}`)
    },
    [deleteFilter, navigate, params]
  )

  // fieldDropDownDataSource is a memoized value that creates a new ArrayStore.
  const fieldDropDownDataSource: ArrayStore = useMemo(() => {
    // Translate the name of each search option
    const translationSearchOptions = searchOptions
      .filter((searchOption) => {
        return !activeFilters.has(searchOption.id)
      })
      .map((searchOption) => ({
        ...searchOption,
        name: t(searchOption.name),
      }))

    // Create a new ArrayStore with key as 'id' and data as the translated search options
    return new ArrayStore({
      key: 'id',
      data: [...translationSearchOptions],
    })
  }, [activeFilters, t])

  // This is a callback function that handles the change event of the filter.
  const handleFilterChanged = useCallback(
    async (filter: FilterRef) => {
      // set filter value
      setFilter(filter.id)

      // reset active filters
      setActiveFilters(new Map())

      let newParams = {}

      // 検索条件変更前の表示件数を引き継ぐ
      const pageSize = getParamAsString('pageSize', params)

      // get filter params
      const result = await lazyGetFilterQuery({
        filterId: filter.id,
      }).unwrap()

      // フィルタの定義が取得できたらその内容を検索条件としてセットする
      if (result.filter?.definition) {
        const filterDefinition: FilterDefinition = JSON.parse(
          result.filter.definition
        )
        newParams = {
          filter: filter.id,
          ...filterDefinitionToParams(filterDefinition),
          pageIndex: '0',
          pageSize,
        }
      }

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [lazyGetFilterQuery, navigate, params]
  )

  // フィルタ保存時、filterの検索条件を引き継ぐ
  // navigate後、filterのフィルタの値をもとに検索される
  const handleSubmitCrewSaveFilter = useCallback(() => {
    // Close save filter dialog
    closeCrewSaveFilterDialog()

    // refresh list filter
    filterDataSource.load()
  }, [closeCrewSaveFilterDialog, filterDataSource])

  const [filterDefinition, setFilterDefinition] = useState('')

  // フィルターを保存する
  const handleSaveFilterClicked = useCallback(() => {
    // convert params to filter definition
    const filterDefinition = paramsToFilterDefinition(params)
    setFilterDefinition(JSON.stringify(filterDefinition))
    openCrewSaveFilterDialog()
  }, [openCrewSaveFilterDialog, params])

  const [isOpenFilterSettingsDialog, setIsOpenFilterSettingsDialog] =
    useState(false)

  // フィルター設定ダイアログを開く
  const handleFilterSettingsButtonClick = useCallback(() => {
    setIsOpenFilterSettingsDialog(true)
  }, [])

  // フィルター設定ダイアログを閉じる
  const handleCloseFilterSettingsDialog = useCallback(() => {
    setIsOpenFilterSettingsDialog(false)
  }, [])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-wrap gap-2 items-center">
        {/* filter */}
        <CrewSelectBox
          id="filter"
          name="filter"
          dataSource={filterDataSource}
          displayExpr="label"
          minSearchLength={0}
          value={filter}
          onValueChange={handleFilterChanged}
          searchEnabled={false}
          showClearButton={false}
        />

        {/* search input */}
        {Array.from(activeFilters).map(([key, filter]) => (
          <div
            key={key}
            className="flex gap-1 items-center px-2 py-1 rounded-lg crew-bg-gray-1"
          >
            <label className="pr-1">
              {t(findSearchOption(filter.field)?.label ?? '')}:
            </label>
            <CrewTaskSearchInput
              filter={filter}
              updateSearchValue={updateSearchValue}
            />
            {!findSearchOption(filter.field)?.defaultShown && (
              <CrewButton
                icon={<Close width={13} height={13} />}
                tabIndex={-1}
                stylingMode="text"
                className="hover:!bg-crew-gray-200 hover:dark:!bg-crew-gray-700"
                onClick={() => handleDeleteFilterButtonClick(filter)}
                size="sm"
              />
            )}
          </div>
        ))}

        {/* add condition */}
        <CrewDropDownButton
          className="h-8"
          text={t('action.addCondition')}
          icon="plus"
          dataSource={fieldDropDownDataSource}
          keyExpr="id"
          displayExpr={(item) => item && t(item.label)} // translate label
          onItemClick={handleFieldDropDownItemClick}
          showArrowIcon={false}
          stylingMode="text"
        />

        {/* 保存 */}
        <CrewButton
          className="h-8"
          icon={<Save width={22} height={22} />}
          stylingMode="text"
          text={t('action.save')}
          onClick={handleSaveFilterClicked}
        />

        {/* Filter settings button */}
        <CrewButton
          className="h-8"
          icon={<OutlineSettings width={22} height={22} />}
          stylingMode="text"
          onClick={handleFilterSettingsButtonClick}
        />
      </div>

      <CrewSaveFilterDialog
        isEditMode={false}
        title={t('label.registerFilter')}
        isOpen={isCrewSaveFilterDialogOpen}
        onClose={closeCrewSaveFilterDialog}
        definition={filterDefinition}
        entityType={EntityType.Task}
        onSubmit={handleSubmitCrewSaveFilter}
      />

      {/* Filter settings dialog */}
      <CrewFilterSettingsDialog
        isOpen={isOpenFilterSettingsDialog}
        onClose={handleCloseFilterSettingsDialog}
        entityType={EntityType.Task}
      />
    </div>
  )
})
