import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import { TextArea } from 'devextreme-react'

type Props = PropsWithChildren<ComponentProps<typeof TextArea>>
export const CrewTextArea = memo(
  forwardRef<TextArea, Props>(({ children, ...rest }, ref) => (
    <TextArea ref={ref} {...rest}>
      {children}
    </TextArea>
  ))
)
