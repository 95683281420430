import { FC, memo, useCallback, useMemo } from 'react'

import { ChatMessage } from '@crew/models/domain'

import { CrewChatMessageItemAction } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAction/crewChatMessageItemAction'
import { CrewMessageReplyButton } from 'components/elements/crewMessageReplyButton/crewMessageReplyButton'
import { CrewMessageReplyWithCountButton } from 'components/elements/crewMessageReplyWithCountButton/crewMessageReplyWithCountButton'
import { CrewReactionBadge } from 'components/elements/crewReactionBadge/crewReactionBadge'

import { ShowReplyButtonType, ShowReactionType } from '@crew/utils/dist/chat'
import { useAppSelector } from 'states/hooks'
import { getChatMessageReactionItems } from '@crew/utils/chat'

export type CrewReplyAndReactionButtonProps = {
  message: ChatMessage // 投稿メッセージデータ
  showReplyButtonType: ShowReplyButtonType // 返信ボタンをどう表示するか
  showReactionsAndReactionButton: ShowReactionType // リアクションとリアクションボタンを表示するかどうか
  replyCount: number | undefined // 「○件の返信」と表示する場合のみ値を設定し、他はundefined
  onReplyClick?: () => void // 返信ボタンを表示しない場合は指定不要
}

/**
 * 返信ボタンとリアクションボタン
 */
export const CrewReplyAndReactionButton: FC<CrewReplyAndReactionButtonProps> =
  memo((props) => {
    // ログインユーザー情報を取得
    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

    // チャットメッセージからリアクション情報を集計して取得する
    const reactionItems = useMemo(() => {
      return getChatMessageReactionItems(props.message, loggedInUser)
    }, [loggedInUser, props.message])

    const topicId = props.message.parentChatMessageId ?? props.message.id

    // render message reply button by showReplyButtonType
    const renderMessageReplyButton = useCallback(() => {
      // dont show reply button if onReplyClick is not defined
      if (!props.onReplyClick) return null

      switch (props.showReplyButtonType) {
        case ShowReplyButtonType.ShowWithoutCount:
          return (
            // 返信ボタン
            <CrewMessageReplyButton
              topicId={topicId}
              onClick={props.onReplyClick}
            />
          )
        case ShowReplyButtonType.ShowWithCount:
          // 「○件の返信」ボタン
          return (
            <CrewMessageReplyWithCountButton
              topicId={topicId}
              replyCount={props.replyCount ?? 0}
              onClick={props.onReplyClick}
            />
          )
        default:
          return null
      }
    }, [
      props.onReplyClick,
      props.replyCount,
      props.showReplyButtonType,
      topicId,
    ])

    return (
      <div className="flex gap-2 w-full items-center">
        {props.showReplyButtonType !== ShowReplyButtonType.None && (
          <div className="flex">
            {/* Reply button */}
            {renderMessageReplyButton()}
          </div>
        )}

        {props.showReactionsAndReactionButton !== ShowReactionType.None && (
          <div className="flex-auto">
            <div className="flex gap-2 flex-wrap">
              {/* リアクションバッジ出力 */}
              {Object.keys(reactionItems).map((key) => (
                <CrewReactionBadge
                  key={key}
                  shortName={key} // 絵文字名（chat_reactions.reaction）
                  chatMessageId={reactionItems[key].chatMessageId}
                  reactionCount={reactionItems[key].reactionCount}
                  reacted={reactionItems[key].reacted}
                  members={reactionItems[key].members}
                  showOnly={
                    props.showReactionsAndReactionButton ===
                    ShowReactionType.ShowOnly
                  }
                />
              ))}

              {/* 絵文字ダイアログ表示ボタン */}
              {props.showReactionsAndReactionButton ===
                ShowReactionType.ShowAndButton && (
                <CrewChatMessageItemAction chatMessageId={props.message.id} />
              )}
            </div>
          </div>
        )}
      </div>
    )
  })
