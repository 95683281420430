import { Modal } from 'components/layouts/modal/modal'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useProjectMemberEntryDialog } from './useProjectMemberEntryDialog'
import { ProjectMemberEntryTab } from './components/projectMemberEntryTab/projectMemberEntryTab'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { ProjectMemberEntryDialogTabs } from 'enums/app'
import { AddExistingUser } from './components/addExistingUser/addExistingUser'
import { InviteNewUsers } from './components/inviteNewUsers/inviteNewUsers'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useTranslation } from '@crew/modules/i18n'
import { useToast } from 'hooks/useToast'
import { useParams } from 'react-router-dom'
import {
  useGetInviteableExternalUsersQuery,
  useGetInviteableUsersForFreePlanQuery,
} from '@crew/apis/user/userApis'
import { InvitationRoleType, ContractPlan } from '@crew/enums/app'
import { useAppSelector } from 'states/hooks'
import { MAX_NUMBER_OF_USERS_FOR_FREE_PLAN } from '@crew/configs/constants'

export type PropsMode = {
  title: string
  isOpen: boolean
  onClose: () => void
}

export const ProjectMemberEntryDialog = memo((props: PropsMode) => {
  const {
    selectedNewUsers,
    setSelectedNewUsers,
    selectedExistingUsers,
    setSelectedExistingUsers,
    insertProjectMembers,
    insertProjectMemberPendings,
    projectMemberEntryDialogContext,
  } = useProjectMemberEntryDialog()

  const { projectId } = useParams()

  const { t } = useTranslation()
  const toast = useToast()
  const [showApiErrors] = useShowApiErrors()

  const projectSettingMemberEventMessage = useAppSelector(
    (state) => state.app.projectSettingMemberEventMessage
  )
  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  // 招待可能な外部ユーザー数を取得
  const {
    data: getInviteableExternalUsersResult,
    refetch: refetchInviteableExternalUsersResult,
  } = useGetInviteableExternalUsersQuery(undefined, {
    // フリープランの場合は招待可能な外部ユーザー数の取得をskipする
    skip: currentPlan === ContractPlan.Free,
  })

  // 招待可能なユーザー数を取得
  const {
    data: getInviteableUsersResult,
    refetch: refetchInviteableUsersResult,
  } = useGetInviteableUsersForFreePlanQuery(undefined, {
    // フリープラン以外の場合は招待可能なユーザー数の取得をskipする
    skip: currentPlan !== ContractPlan.Free,
  })

  // refetch inviteable users/external users when tenant setting member updated
  useEffect(() => {
    if (currentPlan === ContractPlan.Free) {
      refetchInviteableUsersResult()
    } else {
      refetchInviteableExternalUsersResult()
    }
  }, [
    currentPlan,
    projectSettingMemberEventMessage,
    refetchInviteableExternalUsersResult,
    refetchInviteableUsersResult,
  ])

  // get remaining inviteable external users
  const remainingInviteableExternalUsers = useMemo(
    () =>
      getInviteableExternalUsersResult
        ? getInviteableExternalUsersResult.inviteableExternalUsers -
          getInviteableExternalUsersResult.currentExternalUsers
        : 0,
    [getInviteableExternalUsersResult]
  )

  const [selectedIndex, setSelectedIndex] = useState<number>(
    ProjectMemberEntryDialogTabs.ExistingUsers.id
  )

  // タブ選択時、選択状態(LocalState)を更新
  const handleTabsSelectionChanged = useCallback((selectedIndex: number) => {
    setSelectedIndex(selectedIndex)
  }, [])

  //close dialog
  const handleCloseDialog = useCallback(() => {
    props.onClose()

    //clear context state
    setSelectedExistingUsers([])
    setSelectedNewUsers([])
  }, [props, setSelectedExistingUsers, setSelectedNewUsers])

  // Event handle when Add or Invite member is clicked
  const handleAddOrInviteMemberButtonClick = useCallback(async () => {
    if (selectedIndex === ProjectMemberEntryDialogTabs.ExistingUsers.id) {
      //add existing member to project
      try {
        //if project id is null or selected existing users is empty, return
        if (!projectId || selectedExistingUsers.length === 0) return

        // Execute insert project member process
        await insertProjectMembers(projectId)

        toast.success(t('message.project.projectMemberRegistered'))

        //clear context state
        setSelectedExistingUsers([])

        //close dialog
        props.onClose()
      } catch (error) {
        showApiErrors(error)
      }
    } else {
      //invite new member to project
      try {
        //if project id is null or selected new users is empty, return
        if (!projectId || selectedNewUsers.length === 0) return

        if (currentPlan === ContractPlan.Free) {
          if (
            selectedNewUsers.length >
            (getInviteableUsersResult?.inviteableUsers ?? 0)
          ) {
            toast.error(
              t('message.apiError.tenantUsersExceedLimit', {
                remainingUser: getInviteableUsersResult?.inviteableUsers,
              })
            )
            return
          }
        } else {
          // count the number of selected new users is external users
          const externalUsersCount = selectedNewUsers.filter(
            (user) => user.tenantRoleType === InvitationRoleType.External.key
          ).length

          // if the number of selected new users is greater than the remaining inviteable external users, return
          if (externalUsersCount > remainingInviteableExternalUsers) {
            toast.error(
              t('message.apiError.inviteExternalUserLimitExceeded', {
                inviteableExternalUserCount:
                  getInviteableExternalUsersResult?.inviteableExternalUsers,
                currentExternalUserCount:
                  getInviteableExternalUsersResult?.currentExternalUsers,
                exceededExternalUserCount:
                  externalUsersCount - remainingInviteableExternalUsers,
              })
            )
            return
          }
        }

        // Execute insert project member pending process
        await insertProjectMemberPendings(projectId)

        toast.success(t('message.project.projectMemberRegistered'))

        //clear context state
        setSelectedNewUsers([])

        //close dialog
        props.onClose()
      } catch (error) {
        console.log(error)
        showApiErrors(error)
      }
    }
  }, [
    currentPlan,
    getInviteableExternalUsersResult?.currentExternalUsers,
    getInviteableExternalUsersResult?.inviteableExternalUsers,
    getInviteableUsersResult?.inviteableUsers,
    insertProjectMemberPendings,
    insertProjectMembers,
    projectId,
    props,
    remainingInviteableExternalUsers,
    selectedExistingUsers.length,
    selectedIndex,
    selectedNewUsers,
    setSelectedExistingUsers,
    setSelectedNewUsers,
    showApiErrors,
    t,
    toast,
  ])

  // Render Add or Invite member form
  const renderAddOrInviteMemberForm = useCallback(() => {
    switch (selectedIndex) {
      case ProjectMemberEntryDialogTabs.ExistingUsers.id:
        return <AddExistingUser />
      case ProjectMemberEntryDialogTabs.NewUsers.id:
        return <InviteNewUsers />
      default:
        return null
    }
  }, [selectedIndex])

  return (
    <projectMemberEntryDialogContext.Provider
      value={{
        selectedExistingUsers,
        setSelectedExistingUsers,
        selectedNewUsers,
        setSelectedNewUsers,
      }}
    >
      <Modal
        title={props.title}
        isOpen={props.isOpen}
        onClose={handleCloseDialog}
      >
        <div className="flex flex-col gap-y-3 h-full">
          <p className="text-md">{t('label.addOrInviteMemberDescription')}</p>
          {/* モーダルの最小幅を制限し、一覧タブやデータグリッドの各入力項目が正しく表示されるようにする */}
          <div className="overflow-x-auto flex-grow">
            <div className="min-w-[725px] flex flex-col gap-y-2.5 h-full">
              <CrewScrollView>
                <div className="flex">
                  <ProjectMemberEntryTab
                    selectedIndex={selectedIndex}
                    tabsSelectionChanged={handleTabsSelectionChanged}
                  />
                </div>

                {/* タブ選択状態に合わせてタブ表示切り替え */}
                <div className="flex flex-col gap-y-5">
                  {renderAddOrInviteMemberForm()}
                </div>
              </CrewScrollView>
              {/* Dialog action */}
              <div className="flex items-center gap-x-3 mt-5">
                {selectedIndex === ProjectMemberEntryDialogTabs.NewUsers.id && (
                  <p className="mr-auto">
                    {currentPlan === ContractPlan.Free
                      ? // フリープランの場合は招待可能なユーザー数を表示
                        t('label.hintForAllowInviteableUsers', {
                          remaining:
                            getInviteableUsersResult?.inviteableUsers ?? 0,
                          total: MAX_NUMBER_OF_USERS_FOR_FREE_PLAN,
                        })
                      : // フリープラン以外の場合は招待可能な外部ユーザー数を表示
                        t('label.hintForAllowInviteableExternalUsers', {
                          remaining: remainingInviteableExternalUsers,
                          total:
                            getInviteableExternalUsersResult?.inviteableExternalUsers ??
                            0,
                        })}
                  </p>
                )}

                <CrewButton
                  text={
                    selectedIndex ===
                    ProjectMemberEntryDialogTabs.ExistingUsers.id
                      ? t('action.addToProject')
                      : t('action.inviteToProject')
                  }
                  type="primary"
                  onClick={handleAddOrInviteMemberButtonClick}
                  className="ml-auto"
                />

                <CrewButton
                  text={t('action.cancel')}
                  type="normal"
                  stylingMode="outlined"
                  onClick={handleCloseDialog}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </projectMemberEntryDialogContext.Provider>
  )
})
