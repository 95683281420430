/* eslint-disable @typescript-eslint/no-redeclare */
/**
 * 言語
 */
export const Language = {
    Ja: 'ja',
    En: 'en',
};
/**
 * ロケール
 */
export const Locale = {
    JaJp: 'ja-JP',
    EnUs: 'en-US',
};
