import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { FC, memo } from 'react'
import { ProjectMemberEntryDialog } from 'features/project/components/projectMemberEntryDialog/projectMemberEntryDialog'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { useCallback, useMemo, useState, useRef } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useAppSelector } from 'states/hooks'
import { debounce } from 'lodash'
import { useModal } from 'components/layouts/modal/useModal'
import { TextBox } from 'devextreme-react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useProjectMemberStateFilterDataSource } from 'hooks/dataSource/useResourceDataSource'
import { ProjectMemberState } from 'enums/app'
import { ComponentCallbackHandler, isEqualParams } from '@crew/utils'
import qs from 'qs'
import { useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/domain'

type ProjectDetailMemberListToolbarProps = {
  onCancelInvitation: () => void
  onRejectRequest: () => void
  onApproveRequest: () => void
  onDelete: () => void
}

export const ProjectDetailMemberListToolbar: FC<ProjectDetailMemberListToolbarProps> =
  memo((props: ProjectDetailMemberListToolbarProps) => {
    const { t } = useTranslation()

    const [
      isProjectMemberEntryDialogOpen,
      openProjectMemberEntryDialog,
      closeProjectMemberEntryDialog,
    ] = useModal()

    const [searchParams] = useSearchParams()

    const location = useLocation()

    const { navigate } = useCrewNavigate()

    const [keyword, setKeyword] = useState(searchParams.get('keyword') ?? '')

    const { projectId } = useParams()

    const { hasPrjMemberDeletePermission, hasPrjMemberAddPermission } =
      useProjectPermissions(EntityType.Project, projectId)

    const projectMemberState =
      searchParams.get('state') || ProjectMemberState.allStates.value

    // List of user ids of selected members
    const userIds = useAppSelector(
      (state) => state.projectDetail.selectedUserIds
    )

    // disable action delete, approve, reject and cancel button  if no member is selected
    const isDisable = useMemo(() => userIds.length === 0, [userIds])

    //delay search when keyup keyword search after 500ms
    const debouncedSearch = useMemo(
      () =>
        debounce((value) => {
          const newParams = {
            keyword: value || undefined,
          }
          navigate(location.pathname, newParams, true)
        }, SEARCH_TIMEOUT_MSEC),
      [location.pathname, navigate]
    )

    //call function when keyup keyword box search
    const keywordTextBoxRef = useRef<TextBox>(null)
    const handleKeywordTextBoxInput = useCallback(() => {
      // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
      const input = keywordTextBoxRef.current?.instance.option('text')
      if (input !== undefined && input !== keyword) {
        setKeyword(input)
        debouncedSearch(input)
      }
    }, [debouncedSearch, keyword])

    const projectMemberStateDataSource = useProjectMemberStateFilterDataSource()

    //update query string when select project member state
    const handleProjectMemberStateChanged = useCallback<
      ComponentCallbackHandler<typeof CrewSelectBox, 'onValueChanged'>
    >(
      (event) => {
        const currentParams: any = qs.parse(searchParams.toString())
        // nullや空文字はundefinedに置き換える
        const newParams = {
          ...currentParams,
          state: event.value || undefined,
        }
        if (!isEqualParams(currentParams, newParams)) {
          navigate(location.pathname, newParams, true)
        }
      },
      [location.pathname, navigate, searchParams]
    )

    return (
      <div className="flex justify-between items-start p-2 gap-x-2.5">
        <div className="flex gap-x-2.5">
          {/* キーワード */}
          <CrewTextBox
            className="w-40"
            value={keyword}
            valueChangeEvent="input change"
            onInput={handleKeywordTextBoxInput}
            showClearButton={true}
            ref={keywordTextBoxRef}
            mode="search"
          />

          {/* 状態フィルタ */}
          <CrewSelectBox
            className="w-36"
            value={projectMemberState}
            dataSource={projectMemberStateDataSource}
            displayExpr="name"
            valueExpr="id"
            searchEnabled={false}
            minSearchLength={0}
            showClearButton={false}
            onValueChanged={handleProjectMemberStateChanged}
          />
        </div>
        <div className="flex gap-2.5 overflow-x-scroll">
          {hasPrjMemberAddPermission && (
            <>
              {/* 申請を承認 */}
              <CrewButton
                type="normal"
                stylingMode="outlined"
                text={t('action.approveRequest')}
                disabled={isDisable}
                onClick={props.onApproveRequest}
              />
              {/* 申請を却下 */}
              <CrewButton
                type="normal"
                stylingMode="outlined"
                text={t('action.rejectRequest')}
                disabled={isDisable}
                onClick={props.onRejectRequest}
              />
              {/* 招待をキャンセル */}
              <CrewButton
                type="normal"
                stylingMode="outlined"
                text={t('action.cancelInvitation')}
                disabled={isDisable}
                onClick={props.onCancelInvitation}
              />
            </>
          )}

          {/* メンバーを追加 */}
          {hasPrjMemberAddPermission && (
            <CrewButton
              type="primary"
              text={t('action.addMember')}
              onClick={openProjectMemberEntryDialog}
            />
          )}
          {/* メンバーを削除 */}
          {hasPrjMemberDeletePermission && (
            <CrewButton
              type="primary"
              text={t('action.removeMember')}
              disabled={isDisable}
              onClick={props.onDelete}
            />
          )}
        </div>

        <ProjectMemberEntryDialog
          title={t('label.addOrInviteMembers')}
          isOpen={isProjectMemberEntryDialogOpen}
          onClose={closeProjectMemberEntryDialog}
        />
      </div>
    )
  })
