import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewHtmlEditorField } from 'components/forms/crewHtmlEditorField'
import { ChatMessage } from '@crew/models/domain'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import {
  FormValues,
  useCrewChatMessageEditor,
} from './useCrewChatMessageEditor'
import { ToolbarButton } from 'components/elements/crewHtmlEditor/components/toolbarButton'
import { ButtonType } from 'enums/app'
import { EntityType, MessageType, ProjectType } from '@crew/enums/domain'
import { useToast } from 'hooks/useToast'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { UploadFile } from 'models/domain/uploadFile'
import { useAppSelector } from 'states/hooks'
import { sanitizeHtmlAllowNothing } from 'utils/html'
import { useValueChangeEffect } from '@crew/hooks'
import { useMount, useUnmount } from '@dx-system/react-use'
import { useTranslation } from '@crew/modules/i18n'
import { useGetLookupRelatedItemQuery } from '@crew/apis/lookup/lookupApis'
import { skipToken } from '@reduxjs/toolkit/query'
import SendRounded from '~icons/material-symbols/send-rounded'

export type CrewChatMessageEditorProps = {
  message?: ChatMessage // 編集時の編集元になるメッセージ
  isEditMode: boolean
  onEditModeCancel?: () => void
  canUploadFile: boolean

  chatRoomId: string | null
  threadRootMessageId: string | undefined
  entityType: EntityType | null
  entityRecordId: string | null
}

export const CrewChatMessageEditor: FC<CrewChatMessageEditorProps> = memo(
  (props) => {
    const {
      control,
      chatMessageValidateRule,
      uploadedFileList,
      setUploadedFileList,
      currentDraftTargetId,
      setCurrentDraftTargetId,
      reset,
      setError,
      handleSubmit,
      updateMessage,
      insertMessage,
      saveDraft,
      deleteChatDraft,
      formState,
    } = useCrewChatMessageEditor(
      props.threadRootMessageId,
      props.chatRoomId,
      props.message,
      props.isEditMode
    )

    const { t } = useTranslation()
    const toast = useToast()
    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    /**
     * 下書き
     */
    const draft = useAppSelector(
      (state) => state.message.chat.draft.entities[currentDraftTargetId]
    )

    const [initialized, setInitialized] = useState(false)

    const [mentionUserIds, setMentionUserIds] = useState<string[] | null>(null)

    /**
     * 下書きの取得先ID
     * 下書きは新規投稿時のみ保存されるため、トピックIDまたはチャットルームIDを設定する
     *
     *  threadRootMessageIdが設定されている場合：スレッド形式と判断し、threadRootMessageIdを取得先IDとする
     *  threadRootMessageIdが設定されていない場合：タイムライン形式またはスレッドリスト形式と判断し、chatRoomIdを取得先IDとする
     */
    const draftTargetId = (props.threadRootMessageId ??
      props.chatRoomId) as string // 新規投稿時、トピックIDまたはチャットルームIDどちらかは必ず設定される想定のため、as stringでキャスト

    // 現在送信中かそうでないかを保持するローカルステート
    // lexical上でAlt + EnterもしくはCtrl + Enterでの連続送信を防ぐ
    // formState.isSubmittingやcanSendだと制御できなかったため自前で管理する
    const [isSending, setIsSending] = useState(false)

    // ファイルアップローダー使用可否（編集時または権限がない場合は使用不可）
    const isFileUploaderDisabled = useMemo(
      () => props.isEditMode || !props.canUploadFile,
      [props.isEditMode, props.canUploadFile]
    )

    // 投稿可能か
    // TODO:現状、空欄でも投稿可能扱いになってしまう。Lexicalの仕様上、空に見えても<p>などがデフォルトで入力されているため。
    // CREW-3077で文字数制限についてのdiscussionが立っているが、同様のロジックで最小文字数についても明示的に制限が必要
    // https://break-tmc.atlassian.net/browse/CREW-3077
    const canSendMessage = useMemo(
      () => {
        // 新規投稿 かつ 下書きが存在する場合はisDirtyがfalseだが送信を許可する必要があるため、下書きが存在するかも含めて判定する
        const draftMessage = draft?.text ?? ''
        const plainDraftText = sanitizeHtmlAllowNothing(draftMessage)

        // 下書きが存在するか
        const hasDraft = plainDraftText || draft?.files

        return (
          // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
          // 送信可能な条件は以下の通り
          //   変更がある、または編集モードでなく下書きが存在するか添付ファイルが存在する / フォームエラーがない / 送信中でない / チャットルームが存在する
          (formState.isDirty ||
            (!props.isEditMode && (hasDraft || uploadedFileList.length > 0))) &&
          Object.keys(formState.errors).length === 0 &&
          !formState.isSubmitting &&
          props.chatRoomId
        )
      },
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [draft, formState, props.chatRoomId, props.isEditMode, uploadedFileList]
    )

    // 下書きの取得先IDが変更が変更された場合、現在入力されている内容をreduxに下書きとして保存する
    useValueChangeEffect(
      () => {
        // 編集時は何もしない
        if (props.isEditMode) {
          return
        }

        // 取得先IDが変更された場合
        if (currentDraftTargetId !== draftTargetId) {
          // 現在の下書きを保存
          saveDraft()

          // 取得先IDを更新する
          setCurrentDraftTargetId(draftTargetId)
        }
      },
      [
        currentDraftTargetId,
        draftTargetId,
        props.isEditMode,
        saveDraft,
        setCurrentDraftTargetId,
      ],
      draftTargetId
    )

    // 下書きの内容をエディタに反映する
    useValueChangeEffect(
      () => {
        // 編集時は何もしない
        if (props.isEditMode) {
          return
        }

        // 添付ファイル一覧に下書きの内容を設定
        setUploadedFileList(draft?.files ?? [])

        // メッセージ本文を設定
        const message = draft?.text ?? ''
        const plainText = sanitizeHtmlAllowNothing(message)

        // NOTICE: setValueだとエディタに反映されないため、resetを使用
        //         ただしsetValueを使用しないとformState.isDirtyがtrueにならず、送信ボタンが押下できなくなるため、canSendの条件に下書きが存在するかを含めている
        reset({
          chatMessage: plainText ? message : '',
        })
      },
      [draft?.files, draft?.text, props.isEditMode, reset, setUploadedFileList],
      draft
    )

    /**
     * FIXME: 現状下記問題が発生するため、初期化フラグで制御している。下記問題が解消すれば初期化フラグは不要になる想定
     *        ・現状プロジェクト詳細を開いた際にmount→unmount→mountとマウント処理が2回流れる
     *        ・mount時：下書きをエディタに反映、unmount時：下書きをreduxに保存　の処理をそれぞれ実行しているが、
     *          1回目のunmount時にLocalStateの値が正しく取得できないため、uploadedFileListが正しく取得できない（＝下書きに添付ファイルが保存されない）
     *            →おそらくuseEffectのクリーンアップ関数とuseStateでsetするときの遅延処理の処理順の問題？
     *        ・上記を回避するため、LocalStateに初期化フラグを追加し制御している
     */
    // マウント時、初期化フラグをONにする
    useMount(() => {
      if (!initialized) {
        setInitialized(true)
      }
    })

    // アンマウント時、下書きをreduxに保存する
    useUnmount(() => {
      if (initialized) {
        // 下書きを保存
        saveDraft()
      }
    })

    // Event handler when send button is clicked
    const handleSendButtonClick = useCallback(async () => {
      if (!props.chatRoomId) {
        return
      }

      if (isSending) {
        return
      }

      // 投稿先
      const targetId = props.threadRootMessageId
        ? {
            // 返信の場合、今いるチャットルームと返信先メッセージのチャットルームが異なる可能性があるため
            // バックエンド側で返信先メッセージIDから投稿先チャットルームを識別する
            parentChatMessageId: props.threadRootMessageId,
          }
        : {
            chatRoomId: props.chatRoomId,
          }

      // 編集モードかどうかによって制御を振り分ける
      if (props.isEditMode) {
        // 更新
        const message = props.message
        if (!message) {
          return
        }

        // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
        const onSubmit = async (data: FormValues) => {
          try {
            setIsSending(true)

            // ファイル更新
            await updateMessage(
              data,
              message.id,
              message.version,
              mentionUserIds
            )

            // 編集モードを切る
            props.onEditModeCancel && props.onEditModeCancel()

            toast.success(t('message.chat.messageEdited'))
          } catch (err) {
            console.error(
              '[useCrewChatMessageEditor] chat message update error.',
              JSON.stringify(err)
            )
            showApiErrors(err)
          } finally {
            setIsSending(false)
          }
        }
        // ファイル更新実行
        handleSubmit(onSubmit)()
      } else {
        // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
        const onSubmit = async (data: FormValues) => {
          try {
            setIsSending(true)
            await insertMessage(
              data,
              MessageType.MessageNormal,
              targetId.chatRoomId,
              targetId.parentChatMessageId,
              uploadedFileList,
              mentionUserIds
            )

            // 添付ファイル一覧を初期化
            setUploadedFileList([])

            // 下書きを削除
            deleteChatDraft(currentDraftTargetId)

            // フォームを初期化
            reset()
          } catch (err) {
            console.error(
              '[useCrewChatMessageEditor] chat message post error.',
              JSON.stringify(err)
            )
            showApiErrors(err)
          } finally {
            setIsSending(false)
          }
        }
        // ファイル登録実行
        handleSubmit(onSubmit)()
      }
    }, [
      currentDraftTargetId,
      deleteChatDraft,
      handleSubmit,
      insertMessage,
      isSending,
      mentionUserIds,
      props,
      reset,
      setUploadedFileList,
      showApiErrors,
      t,
      toast,
      updateMessage,
      uploadedFileList,
    ])

    // Event handler when cancel button is clicked
    const handleCancelButtonClick = useCallback(() => {
      props.onEditModeCancel && props.onEditModeCancel()
    }, [props])

    // Event handler when mention target is changed
    const handleMentionTargetChange = useCallback(
      (targetUserIds: string[]) =>
        setMentionUserIds(targetUserIds.length === 0 ? null : targetUserIds),
      []
    )

    // ファイルアップロード中かどうか
    const isFileUploading = useMemo(() => {
      return uploadedFileList.some((file) => file.progress)
    }, [uploadedFileList])

    // 添付ファイルアップロード完了後
    const handleUploaded = useCallback(
      (file: UploadFile) => {
        // ファイル一覧にアップロードしたファイルを追加
        setUploadedFileList((baseData) => {
          //replace same file name
          const index = baseData.findIndex((item) => item.name === file.name)
          if (index === -1) {
            return [...baseData, file]
          } else {
            // replace the file with the same name
            baseData[index] = file
            return [...baseData]
          }
        })
      },
      [setUploadedFileList]
    )

    // 添付ファイル削除ボタン押下時
    const handleDeleteFile = useCallback(
      (file: UploadFile) => {
        // uploadedFileListに格納している該当ファイル情報を削除する
        setUploadedFileList((baseData) =>
          baseData.filter((item) => item.name !== file.name)
        )
      },
      [setUploadedFileList]
    )

    // If the isVisibleMention has not been specified as props, determine isVisibleMention based on projects.project_type

    // Get project information base on props.entityType and props.entityRecordId
    // entityTypeがProjectの場合のみ、project_typeを取得し、メンションの非表示 / 表示を判定する必要がある
    const getLookupRelatedItemParams =
      props.entityType === EntityType.Project && props.entityRecordId
        ? {
            entityType: props.entityType,
            id: props.entityRecordId,
          }
        : undefined
    const { data } = useGetLookupRelatedItemQuery(
      getLookupRelatedItemParams ?? skipToken
    )

    // Determines isVisibleMention
    const isVisibleMention = useMemo(() => {
      if (props.entityType === EntityType.Project && data?.relatedItem?.type) {
        // entityTypeがプロジェクトでかつ、関連アイテムのタイプがダイレクトチャンネルでない場合はメンションを表示する
        return data?.relatedItem?.type !== ProjectType.DirectChannel
      }

      return true
    }, [data?.relatedItem?.type, props.entityType])

    return (
      <div className="crew-slim-toolbar-item flex flex-col gap-1 p-2">
        {/* FIXME: CrewHtmlEditor自体にスタイルクラスを組み込むようにするべきか検討する
            https://break-tmc.atlassian.net/browse/CREW-4450 */}
        {/* チャットメッセージ入力エディタ */}
        <CrewHtmlEditorField
          minHeight="2.5rem"
          id="chatMessage"
          name="chatMessage"
          valueChangeEvent="input"
          control={control}
          rules={chatMessageValidateRule}
          onMentionTargetChange={handleMentionTargetChange}
          fileUploaderDisabled={isFileUploaderDisabled} // upload file when you are'nt in mode edit message and have permission create file
          uploadedFileList={uploadedFileList}
          onUploaded={handleUploaded}
          onDeleteUploadedFile={handleDeleteFile}
          onSubmit={handleSendButtonClick}
          entityType={props.entityType ?? undefined}
          entityRecordId={props.entityRecordId ?? undefined}
          showLabel={false}
          reset={reset}
          appendButtons={
            props.isEditMode ? (
              <div className="ml-auto flex flex-row gap-1">
                {/* 保存ボタン */}
                <CrewButton
                  text={t('action.save')}
                  type="primary"
                  className="crew-slim-button"
                  onClick={handleSendButtonClick}
                  disabled={!canSendMessage || isFileUploading}
                />
                {/* キャンセルボタン */}
                <CrewButton
                  text={t('action.cancel')}
                  type="normal"
                  className="crew-slim-button"
                  onClick={handleCancelButtonClick}
                />
              </div>
            ) : (
              // 投稿ボタン
              <ToolbarButton
                type={ButtonType.Action}
                disabled={!canSendMessage || isFileUploading}
                onHandleButtonClick={handleSendButtonClick}
                icon={<SendRounded width={24} height={24} />}
                // 特殊サイズのため、直接pxで指定する
                className="w-8 h-7 flex items-center justify-center p-1"
              />
            )
          }
          disabledMention={!isVisibleMention}
        />
      </div>
    )
  }
)
