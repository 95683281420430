import { FC, memo, useCallback, useRef } from 'react'
import { ChatSearchMessageListItem } from './components/chatSearchMessageListItem'
import { useChatSearchMessageList } from './useChatSearchMessageList'

export const ChatSearchMessageList: FC = memo(() => {
  const { keyword, displayItems, displayTargetChatMessage } =
    useChatSearchMessageList()

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  // 呼び出しもとに戻るときのスクロール位置を保持する処理は、呼び出し元から関数を貰って処理する
  const handleClickItem = useCallback(
    (id: string, parentChatMessageId: string | null) => {
      displayTargetChatMessage(id, parentChatMessageId)
    },
    [displayTargetChatMessage]
  )

  return (
    // ChatSearchMessageList(上から新しいメッセージ→古いメッセージで表示)
    <div ref={itemsScrollableDivRef} className="grow flex overflow-y-scroll">
      <div className="grow flex flex-col">
        {displayItems.map((item) => (
          <ChatSearchMessageListItem
            key={item.id}
            id={item.id}
            chatMessageId={item.messageId}
            highlightKeyword={keyword ?? ''}
            onClickItem={handleClickItem}
            onAdditionalLoading={item.handleAdditionalLoading}
            container={itemsScrollableDivRef}
          />
        ))}
      </div>
    </div>
  )
})
