import { FC, memo } from 'react'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewUserAvatar } from '../crewUserAvatar/crewUserAvatar'

type CrewFileChangerItemProps = {
  displayName: string
  userId: string
  version: number
}
export const CrewFileChangerItem: FC<CrewFileChangerItemProps> = memo(
  (props) => {
    return (
      <CrewUserAvatar
        userId={props.userId}
        displayName={props.displayName}
        size={CrewAvatarSize['2xs']}
        showLabel={true}
        cacheValue={props.userId + props.version}
      />
    )
  }
)
