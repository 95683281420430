import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import dayjs from '@crew/modules/dayjs'
import { DateFormat } from '@crew/enums/system'
import { FC, memo, useCallback, useMemo } from 'react'
import { CrewTaskPriority } from 'components/elements/crewTaskPriority/crewTaskPriority'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { getDefaultTabValue } from '@crew/utils/enum'
import {
  isContextMenuItem,
  TaskContextMenuItems,
  TaskDetailListTabs,
} from 'enums/app'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { TaskKindRef, TaskStateRef, UserRef } from '@crew/models/refs'

import { useTranslation } from '@crew/modules/i18n'
import { ComponentCallbackHandler, convertMinutesToHHMM } from '@crew/utils'
import MoreHoriz from '~icons/material-symbols/more-horiz'
import { CrewContextMenu } from 'components/devextreme/crewContextMenu'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useCrewNavigate } from 'hooks/useCrewNavigate'

type ProjectTask = {
  id: string
  subject: string
  description: string | null
  startDate: string | null
  dueDate: string | null
  assignToUser: UserRef | null
  taskKind: TaskKindRef
  taskPriority: number
  taskState: TaskStateRef
  estimatedWorkTimes: number | null
  actualWorkTimes: number | null
  version: number
}

export type ProjectDetailTaskListKanbanCardProps = {
  item: ProjectTask
  hasPrjTaskEditPermission: boolean
  onEditTask: (id: string) => void
}

export const ProjectDetailTaskListKanbanCard: FC<ProjectDetailTaskListKanbanCardProps> =
  memo((props) => {
    const { t } = useTranslation()
    const { navigate } = useCrewNavigate()

    const displayWorkTime = useMemo(() => {
      let displayStr = ''
      // actual work time
      if (typeof props.item.actualWorkTimes === 'number') {
        displayStr +=
          t('label.actual') + convertMinutesToHHMM(props.item.actualWorkTimes)
      }

      // if both estimated and actual work time are set, add a slash
      if (
        typeof props.item.estimatedWorkTimes === 'number' &&
        typeof props.item.actualWorkTimes === 'number'
      ) {
        displayStr += '/'
      }

      // estimated work time
      if (typeof props.item.estimatedWorkTimes === 'number') {
        displayStr +=
          t('label.planned') +
          convertMinutesToHHMM(props.item.estimatedWorkTimes)
      }

      return displayStr
    }, [props.item.actualWorkTimes, props.item.estimatedWorkTimes, t])

    // ContextMenuに格納するuseDataSource用のデータ
    const contextMenuData = useMemo(() => {
      return (
        Object.values(TaskContextMenuItems)
          //hidden edit item if user has no permission
          .filter(
            (item) =>
              item.action === TaskContextMenuItems.Detail.action ||
              (item.action === TaskContextMenuItems.Edit.action &&
                props.hasPrjTaskEditPermission)
          )
          .map((item) => {
            return { action: item.action, label: t(item.label) }
          })
      )
    }, [props.hasPrjTaskEditPermission, t])

    // 実際にContextMenuに格納するDataSource定義
    const contextMenuDataSource = useDataSource(
      () => ({
        key: 'id',
        load: async (loadOptions) => {
          return contextMenuData
        },
      }),
      [contextMenuData]
    )

    //call when click item of context menu
    const handleContextMenuItemClick = useCallback<
      ComponentCallbackHandler<typeof CrewContextMenu, 'onItemClick'>
    >(
      (event) => {
        const item = event.itemData
        if (!isContextMenuItem(item)) {
          return
        }
        switch (item.action) {
          //detail action click
          case TaskContextMenuItems.Detail.action:
            navigate(
              `/tasks/${props.item.id}/${getDefaultTabValue(
                TaskDetailListTabs
              )}`
            )
            break
          //edit action click
          case TaskContextMenuItems.Edit.action:
            props.onEditTask(props.item.id)
            break
        }
      },
      [navigate, props]
    )

    return (
      <div className="flex flex-col gap-2.5 p-2.5 cursor-pointer crew-bg-default rounded-xl">
        <div className="flex justify-between items-start">
          {/* subject */}
          <CrewLink
            to={`/tasks/${props.item.id}/${getDefaultTabValue(
              TaskDetailListTabs
            )}`}
            className="truncate"
          >
            {props.item.subject}
          </CrewLink>

          {/* context menu  */}
          <MoreHoriz
            id={`context-menu-${props.item.id}`}
            width={20}
            height={20}
            className="crew-text-gray-5 ml-auto"
          />

          <CrewContextMenu
            target={`#context-menu-${props.item.id}`}
            dataSource={contextMenuDataSource}
            position={{
              offset: { y: 5 },
              at: 'right bottom',
              my: 'right top',
              collision: 'flip',
            }}
            displayExpr="label"
            width="130px"
            // ここをdxclickにすると左クリックで表示されるようになる
            showEvent="dxclick"
            onItemClick={handleContextMenuItemClick}
          />
        </div>

        {/* description */}
        <p className="line-clamp-3 break-all">
          {props.item.description && (
            <CrewHtmlContent html={props.item.description} />
          )}
        </p>

        <div className="flex items-center gap-2.5">
          {/* task kind */}
          <CrewBadge displayColor={props.item.taskKind.displayColor}>
            {props.item.taskKind.name}
          </CrewBadge>

          {/* task priority */}
          <CrewTaskPriority taskPriority={props.item.taskPriority} />

          {props.item.dueDate && (
            <p className="ml-auto">
              {`${t('label.dueDate')}: ${dayjs(props.item.dueDate).format(
                DateFormat.YYYYMMDD
              )}`}
            </p>
          )}
        </div>

        <div className="flex justify-between items-center">
          {/* work times */}
          <p>{displayWorkTime}</p>

          {/* assign to user */}
          {props.item.assignToUser?.displayName && (
            <CrewUserAvatar
              userId={props.item.assignToUser.id}
              displayName={props.item.assignToUser.displayName}
              size={CrewAvatarSize.xs}
              cacheValue={
                props.item.assignToUser.id + props.item.assignToUser.version
              }
              showLabel
            />
          )}
        </div>
      </div>
    )
  })
