import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import BaselineNotifications from '~icons/ic/baseline-notifications'
import BaseLineNotificationsOff from '~icons/ic/baseline-notifications-off'
import { useCrewSubscription } from 'components/elements/crewSubscription/useCrewSubscription'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'

import { FC, memo, useCallback, useEffect, useState } from 'react'

export type CrewSubscriptionProps = {
  entityType: EntityType
  entityRecordId: string
  isSubscribed: boolean
  isEnabled: boolean
}

export const CrewSubscription: FC<CrewSubscriptionProps> = memo((props) => {
  const { unsubscribe, subscribe } = useCrewSubscription()

  const { error } = useToast()

  const [generalShowApiErrors] = useShowApiErrors()

  const [t] = useTranslation()

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)

  useEffect(() => {
    setIsSubscribed(props.isSubscribed)
  }, [props.isSubscribed])

  // Event when click bell icon
  const handleToggleSubscription = useCallback(async () => {
    try {
      // If the parent is followed, it is not possible to subscribe/unsubscribe
      if (!props.isEnabled) {
        error(t('message.follow.cannotSubscribeChildEntity'))
        return
      }

      if (isSubscribed) {
        await unsubscribe(props.entityType, props.entityRecordId)
        setIsSubscribed(!isSubscribed)
      } else {
        await subscribe(props.entityType, props.entityRecordId)
        setIsSubscribed(!isSubscribed)
      }
    } catch (err) {
      generalShowApiErrors(err)
    }
  }, [
    props.isEnabled,
    props.entityType,
    props.entityRecordId,
    isSubscribed,
    error,
    t,
    unsubscribe,
    subscribe,
    generalShowApiErrors,
  ])

  return (
    <div className="cursor-pointer" onClick={handleToggleSubscription}>
      {isSubscribed ? (
        <BaselineNotifications width={20} height={20} />
      ) : (
        <BaseLineNotificationsOff width={20} height={20} />
      )}
    </div>
  )
})
