import { Sort } from 'enums/app'

export const DEFAULT_PAGE_SIZE = 100
export const DEFAULT_TOTAL_COUNT = 0
export const DEFAULT_PAGE_EVENT_SIZE = 5
export const DEFAULT_PAGE_EVENT_INDEX = 1
export const LIMIT_INITIAL_RECORD = 3

export const ROWS_PER_PAGE_OPTIONS = [10, 50, 100]

export const HTML_EDITOR_FONT_SIZES = {
  '8px': '8px',
  '10px': '10px',
  '12px': '12px',
  '14px': '14px',
  '18px': '18px',
  '24px': '24px',
  '36px': '36px',
} as const

export const HTML_EDITOR_DEFAULT_FONT_SIZES = HTML_EDITOR_FONT_SIZES['14px']

export const DEFAULT_PAGING_PARAMS = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
}

// HTMLエディタで入力された内容を表示する際に使用するcss
// TODO: HTMLエディタで入力された内容を描画する際に当て込むclassNameの指定だが、
// tailwind標準のclassNameによるstyle適用とcrew独自のstyle適用がmixされており適切な表示を制御できていない
// 以下でCrewHtmlEditorによる入力に対してスタイルを組み込めるようにすべきか検討の後、リファクタを行う予定
// https://break-tmc.atlassian.net/browse/CREW-4450
export const HTMLEDITOR_VIEW_STYLE = 'list crew-html-editor-table break-words'

export const MAXIMUM_CHAT_PANEL = 0.5

//max initial records for combobox autocomplete
export const MAX_INITIAL_RECORDS = 100

//aws chime bot prefix
//https://docs.aws.amazon.com/chime-sdk/latest/APIReference/API_meeting-chime_Attendee.html
export const AWS_CHIME_BOT_PREFIX = 'aws:'

export const SPACE_CHARACTER = ' '

export const LINK_CARD_DEFAULT_FAVICON = '/images/mdiLinkVariant.svg'

export const MAXIMUM_AVATAR_SIZE = 1 // MB

/**
 * 未読件数を数字として表示する時の上限。これ以上の件数は(上限値)+として表示する。
 */
export const LIMIT_FOR_UNREAD_DISPLAY_COUNT = 99

export const PROJECT_DEFAULT_SORT_COLUMN = [`subject.${Sort.Ascending.value}`]

export const FILE_HISTORIES_DEFAULT_SORT_COLUMN = [
  `revision.${Sort.Descending.value}`,
]

export const TASK_DEFAULT_SORT_COLUMN = [`referenceNo.${Sort.Descending.value}`]

/**
 * 追加読込を発火させるのに使うIntersectionObserverのrootMargin。
 * ここで指定したマージンが表示領域の外側に適用され、その範囲にメッセージが差し掛かると追加読込が発火する
 */
export const ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN = '500px 0px'

// Reduxのstate初期化用のアクション名
export const ACTION_RESET_STATE = 'RESET_STATE'

// ファイル検索の検索条件のキー
export const OPERATION_KEY = 'operation'

// Chimesdk realtimeSendDataMessage lifetimeMs
export const LIFE_TIME_MS = 1000

// Chimesdk realtimeSendDataMessage topic
export const TOPIC_MEETING_HOST_CHANGED = 'meetingHostChanged'
export const TOPIC_MEETING_OFF_AUDIO_VIDEO = 'meetingOffAudioVideo'

/**
 * サンクスページへの相対パス
 */
export const THANKS_PAGE_RELATIVE_PATH = '/page/thanks.html'
