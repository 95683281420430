import classNames from 'classnames'
import { FC, RefObject, memo, useCallback, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { AttentionType, EntityType, MessageType } from '@crew/enums/domain'
import { useProjectPermissions, useValueChangeEffect } from '@crew/hooks'
import {
  createSelectIsUnreadMessage,
  useChatCurrentService,
  useChatMessage,
  useChatThreadListService,
  useChatThreadService,
  useChatTimelineService,
  useUserSetting,
} from '@crew/states'
import { ShowReactionType, ShowReplyButtonType } from '@crew/utils/dist/chat'

import { CrewChatMessageActionMenu } from 'components/elements/crewChatMessageItem/components/crewChatMessageActionMenu/crewChatMessageActionMenu'
import { CrewChatMessageAttentionTypeHeader } from 'components/elements/crewChatMessageItem/components/crewChatMessageAttentionTypeHeader/crewChatMessageAttentionTypeHeader'
import { AvatarPosition } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewNormalMessageItem } from 'components/elements/crewMessageItem/components/crewNormalMessageItem/crewNormalMessageItem'
import { CrewNotificationMessageItem } from 'components/elements/crewMessageItem/components/crewNotificationMessageItem/crewNotificationMessageItem'
import { ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN } from 'configs/constants'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { ChatMessage } from '@crew/models/domain'
import { SettingKeyType } from '@crew/enums/dist/app'
import { UserChatSettingDisplayFormat } from 'enums/app'
import FiberManualRecord from '~icons/material-symbols/fiber-manual-record'

type MessageItemProps = {
  message: ChatMessage
  attentionUserName: string
  onClick: (() => void) | undefined // メッセージ全体をクリックした際のイベントコールバック
}

// メッセージ種類に応じてメッセージを表示するサブコンポーネント
const MessageItem = memo((props: MessageItemProps) => {
  const { hasPrjFileDownloadPermission } = useProjectPermissions(
    props.message?.fileHistories?.[0]?.file.entityType
      ? (props.message?.fileHistories?.[0].file.entityType as EntityType)
      : undefined,
    props.message?.fileHistories?.[0]?.file.entityRecordId
  )

  // ヘッダー
  const messageHeaderByAttentionType = useCallback(() => {
    // 〇〇 さんが返信しました。
    return (
      <CrewChatMessageAttentionTypeHeader
        attentionType={AttentionType.Mention}
        attentionUserName={props.attentionUserName}
      />
    )
  }, [props.attentionUserName])

  // messageTypeに応じて表示コンポーネントを出し分ける
  switch (props.message.messageType) {
    // 通常投稿メッセージ
    case MessageType.MessageNormal:
      return (
        <CrewNormalMessageItem
          onClick={props.onClick}
          message={props.message}
          canDownloadAttachment={hasPrjFileDownloadPermission}
          showRelatedLink={true} // Attentionは投稿に関連先リンクを表示する
          highlightKeyword="" // ハイライトは使用しない
          showDeleteAttachmentButton={false} // Attentionではメッセージの編集が不可なので添付ファイルの削除ボタンも表示しない
          isLargeAvatar={true} // アバターサイズ：大
          avatarPosition={AvatarPosition.Center} // アバター位置：中央
          showReplyButtonType={ShowReplyButtonType.None} // 返信ボタンは表示しない
          onReplyClick={undefined} // 返信ボタンは表示しない
          replyCount={undefined} // 返信件数は表示しない
          showReactionsAndReactionButton={ShowReactionType.ShowAndButton} // リアクションとリアクションボタンを表示する
          onAttachmentFileDeleted={undefined} // 添付ファイル削除時ボタンが常時不要なためコールバックも不要
          customHeaderContent={messageHeaderByAttentionType()} // アテンションタイプごとのヘッダーを表示
        />
      )
    // 自動投稿メッセージ
    case MessageType.TaskAdded:
    case MessageType.TaskUpdated:
    case MessageType.TaskDeleted:
    case MessageType.TaskCommentUpdated:
    case MessageType.FileAdded:
    case MessageType.FileUpdated:
    case MessageType.ProjectMemberJoined:
    case MessageType.ProjectMemberLeft:
    case MessageType.ProjectMemberRequested:
    case MessageType.EventAdded:
    case MessageType.EventUpdated:
    case MessageType.EventDeleted:
    case MessageType.EventJoined:
      return (
        <CrewNotificationMessageItem
          onClick={props.onClick}
          message={props.message}
          canDownloadAttachment={hasPrjFileDownloadPermission}
          showRelatedLink={true} // Attentionは投稿に関連先リンクを表示する
          omitUserAvatar={false} // Attentionではアバターを必ず表示する
          showReplyButtonType={ShowReplyButtonType.None} // 返信ボタンは表示しない
          onReplyClick={undefined} // 返信ボタンは表示しない
          replyCount={undefined} // 返信件数は表示しない
          showReactionsAndReactionButton={ShowReactionType.ShowAndButton} // リアクションとリアクションボタンを表示する
          truncateMessage={false} // メッセージテキストを省略表示しない
          customHeaderContent={messageHeaderByAttentionType()} // アテンションタイプごとのヘッダーを表示
        />
      )
    default:
      throw new Error(`Unexpected message type: ${props.message.messageType}`)
  }
})

export type MentionMessageListItemProps = {
  id: string
  entityRecordId: string
  chatMessageId: string
  container: RefObject<HTMLDivElement>
  onAdditionalLoading: (() => void) | undefined
  onMessageInView: (inView: boolean, messageId: string) => void
  attentionUserName: string
  selectedItemId: string | undefined // 選択中のメッセージ。ハイライト用に使用する
  setSelectedItemId: (messageId: string) => void // 選択されたメッセージのsetter
}

export const MentionMessageListItem: FC<MentionMessageListItemProps> = memo(
  (props) => {
    const dispatch = useAppDispatch()

    // チャットの表示方法に関わるユーザ設定を取得する
    const chatDisplayFormat = useUserSetting(SettingKeyType.ChatDisplayFormat)

    // Sliceの操作を行うためのServiceを取得
    const chatCurrentService = useChatCurrentService(dispatch)
    const chatTimelineService = useChatTimelineService(dispatch)
    const chatThreadListService = useChatThreadListService(dispatch)
    const chatThreadService = useChatThreadService(dispatch)

    const { message, isError } = useChatMessage(
      props.chatMessageId,
      dispatch,
      useAppSelector
    )

    // アクションメニュー表示状態
    const [visibleActionMenu, setVisibleActionMenu] = useState<boolean>(false)
    // マウスホバー時にアクションメニューを表示する
    const showActionMenu = useCallback(() => {
      setVisibleActionMenu(true)
    }, [])
    // マウスが離れたらアクションメニューを非表示にする
    const hideActionMenu = useCallback(() => {
      setVisibleActionMenu(false)
    }, [])

    // ハイライト表示するか。選択中メッセージが自メッセージの場合、ハイライト表示する
    const isHighlighted =
      props.selectedItemId && message?.id === props.selectedItemId

    // 上端、下端、メッセージ本体の要素を表示したかどうかのフラグ
    const [isShownTopDiv, setIsShowTopDiv] = useState(false)
    const [isShownBottomDiv, setIsShownBottomDiv] = useState(false)
    const [isShownMessageDiv, setIsShownMessageDiv] = useState(false)

    // ------------------------------ イベントハンドラ ------------------------------
    // propsの関数は代入して使用（useEffect内で使用時、依存配列にpropsが入ってしまうため）
    const handleMessageInView = props.onMessageInView

    // マウスホバー時にアクションメニューを表示する
    const handleMouseEnter = useCallback(() => {
      showActionMenu()
    }, [showActionMenu])
    // マウスが離れたらアクションメニューを非表示にする
    const handleMouseLeave = useCallback(() => {
      hideActionMenu()
    }, [hideActionMenu])

    // メッセージのクリックイベントハンドラ
    const handleClick = useCallback(() => {
      const topicId = message?.parentChatMessageId ?? message?.id
      // 個人設定の表示形式によって、右パネルで使用されている情報を更新する
      // 表示方法（タイムライン表示 / スレッド表示）の切り替えはChatCompactPanel内で制御しているため、ここではstateの更新のみ
      if (
        chatDisplayFormat === UserChatSettingDisplayFormat.Timeline.value ||
        (chatDisplayFormat === UserChatSettingDisplayFormat.Thread.value &&
          !message?.parentChatMessageId)
      ) {
        // 表示形式がタイムライン表示 もしくは スレッド表示の場合でもクリックされた親メッセージのIDが存在しない場合、右パネルで使用されているチャットルーム情報を更新する
        // sliceStateのtopicIdをnullにすることで、タイムライン表示 / スレッドリスト表示になる
        chatCurrentService.setCurrentChatRoom({
          id: message?.chatRoomId ?? '',
          entityType: message?.chatRoom.rootEntityType ?? EntityType.Project,
          entityRecordId: message?.chatRoom.rootEntityRecordId ?? '',
        })

        // 選択したメッセージIDをsliceにセットする
        if (chatDisplayFormat === UserChatSettingDisplayFormat.Timeline.value) {
          // タイムライン
          chatTimelineService.setTimelineSelectedMessageId({
            chatMessageId: message?.id ?? '',
            chatRoomId: message?.chatRoomId ?? '',
          })
        } else {
          // スレッドリスト
          chatThreadListService.setThreadListSelectedMessageId({
            chatMessageId: topicId ?? '',
            chatRoomId: message?.chatRoomId ?? '',
          })
        }
      } else if (
        chatDisplayFormat === UserChatSettingDisplayFormat.Thread.value &&
        message?.parentChatMessageId
      ) {
        // 右パネルの戻るボタン押下時に「スレッド表示」->「チャットルームのスレッドリスト表示」に遷移させるため、currentChatRoomをセットする
        chatCurrentService.setCurrentChatRoom({
          id: message?.chatRoomId ?? '',
          entityType: message?.chatRoom.rootEntityType ?? EntityType.Project,
          entityRecordId: message?.chatRoom.rootEntityRecordId ?? '',
        })
        // 表示形式がスレッド表示かつ、クリックされたメッセージに親が存在する場合、チャットスレッドの情報を更新する
        // topicIdを指定した場合、Inthread表示になる
        chatCurrentService.setCurrentChatThread({
          chatRoomId: message?.chatRoomId ?? '',
          entityType: message?.chatRoom.rootEntityType ?? EntityType.Project,
          entityRecordId: message?.chatRoom.rootEntityRecordId ?? '',
          topicId: message?.parentChatMessageId,
        })

        // 選択したメッセージIDをsliceにセットする
        chatThreadService.setChatThreadSelectedMessageId({
          chatMessageId: message?.id ?? '',
          topicId: topicId ?? '',
        })
      }

      // ハイライト表示するため、選択中メッセージを更新する
      props.setSelectedItemId && message && props.setSelectedItemId(message?.id)
    }, [
      chatCurrentService,
      chatDisplayFormat,
      chatThreadListService,
      chatThreadService,
      chatTimelineService,
      message,
      props,
    ])

    // メッセージの要素が一部でも表示されたらメッセージの表示フラグを更新する
    const { ref: messageRef } = useInView({
      threshold: 0, // 一部でも表示したときに発火する
      root: props.container?.current,
      onChange: (inView) => {
        setIsShownMessageDiv(inView)
        // メッセージの要素が非表示になった場合は、上端・下端の要素も非表示になったとみなす
        if (!inView) {
          setIsShowTopDiv(false)
          setIsShownBottomDiv(false)
        }
      },
    })

    // 上端の要素が表示されたら表示フラグを更新する
    const { ref: topRef } = useInView({
      root: props.container?.current,
      onChange: (inView) => {
        // 上端の要素が非表示になった場合でも、メッセージの要素が表示されている場合は表示したことがあるとみなす
        if (!inView && isShownMessageDiv) {
          return
        }

        setIsShowTopDiv(inView)
      },
    })

    // 下端の要素が表示されたら表示フラグを更新する
    const { ref: bottomRef } = useInView({
      root: props.container?.current,
      onChange: (inView) => {
        // 下端の要素が非表示になった場合でも、メッセージの要素が表示されている場合は表示したことがあるとみなす
        if (!inView && isShownMessageDiv) {
          return
        }
        setIsShownBottomDiv(inView)
      },
    })

    // 表示状態が更新されたら各イベントハンドラを実行する
    useValueChangeEffect(
      () => {
        if (isShownTopDiv && isShownBottomDiv) {
          /* メッセージ表示（上端、下端どちらも表示したことがある） */

          // 既読管理やスクロール位置の保持を行うイベントハンドラ実行
          handleMessageInView(true, props.entityRecordId)
        } else if (!isShownTopDiv && !isShownBottomDiv) {
          /* メッセージ非表示（上端、下端どちらも表示したことがない） */

          // 既読管理やスクロール位置の保持を行うイベントハンドラ実行
          handleMessageInView(false, props.entityRecordId)
        }
      },
      [
        handleMessageInView,
        isShownBottomDiv,
        isShownTopDiv,
        props.entityRecordId,
      ],
      { isShownTopDiv, isShownBottomDiv },
      false,
      (prev, next) =>
        prev.isShownTopDiv === next.isShownTopDiv &&
        prev.isShownBottomDiv === next.isShownBottomDiv
    )

    // アイテムが表示領域に近づいたら追加ロードイベントハンドラを呼ぶ
    const { ref: loadingTriggerRef } = useInView({
      rootMargin: ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN, // 表示領域に「近づいた」をトリガーにするため、領域の外側にマージンを付与する
      root: props.container.current,
      onChange: (inView) => {
        // このイベントは、アイテムと判定領域の重なり方の割合がthreshold(デフォルト: 0)を越えた場合に発火する。
        // 近づいた場合と離れた場合のどちらも発火するが、inViewの値で方向を判定することができる。
        //   近づいた場合: true 離れた場合: false
        if (inView) {
          // アイテムが近づいてきた場合、追加読込を行う
          props.onAdditionalLoading?.()
        }
      },
    })

    // 表示対象のメッセージが未読かどうかを取得する
    const selectIsUnread = useMemo(createSelectIsUnreadMessage, [])
    const isUnreadMessage = useAppSelector((state) =>
      selectIsUnread(state, props.chatMessageId)
    )

    // -----------------------------------------------------------------------------

    // エラーが発生している場合は何も表示しない
    if (isError) {
      return null
    }

    // 表示に必要なデータがない場合は何も表示しない
    // 当初「読み込み中」を表示しようとしていたが、メッセージごとにその表示が出てしまうと見栄えが悪かったので表示しないようにした
    if (!message) {
      return null
    }

    return (
      <div
        id={props.id}
        className={classNames(
          'flex flex-col crew-border-gray relative',
          isHighlighted && 'bg-crew-blue-1-light dark:bg-crew-blue-3-dark'
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* メッセージ上端の要素。既読処理に使用するのみで画面には何も表示しない */}
        <div ref={topRef} className="-z-10"></div>

        {/* 未読メッセージには未読バッジを表示する */}
        {isUnreadMessage && (
          <FiberManualRecord
            width={15}
            height={15}
            className="crew-badge-unread-message absolute top-0 left-0 m-0.5"
          />
        )}

        {props.onAdditionalLoading && (
          <div
            ref={loadingTriggerRef}
            className="absolute left-0 top-0 right-0 bottom-0 -z-10"
          />
        )}

        {/* メッセージ */}
        <div ref={messageRef}>
          <MessageItem
            message={message}
            attentionUserName={props.attentionUserName}
            onClick={handleClick}
          />
        </div>

        {/* ホバーメニュー */}
        {/* メッセージ枠右上にホバーメニューを表示するためabsoluteを使用 */}
        <div className="absolute top-0 right-0">
          <CrewChatMessageActionMenu
            visible={visibleActionMenu}
            isBookmarkedMessage={
              message.bookmarks ? message.bookmarks.length > 0 : false
            }
            bookmarkMessages={message.bookmarks}
            chatMessageId={props.chatMessageId}
            chatMessageVersion={message.version}
            disabledContextMenu={true}
            onEditButtonClick={undefined}
            showArchiveCheckbox={false}
          />
        </div>

        {/* メッセージ下端の要素。既読処理に使用するのみで画面には何も表示しない */}
        <div ref={bottomRef} className="-z-10"></div>
      </div>
    )
  }
)
