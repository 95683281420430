import { useCallback } from 'react'

import { Chat, useChatCurrentService } from '@crew/states'
import { useAppDispatch } from 'states/hooks'

export const useChatTimelineHeader = () => {
  const dispatch = useAppDispatch()

  // Sliceの操作を行うためのServiceを取得
  const chatCurrentService = useChatCurrentService(dispatch)

  // 検索ボタンクリック時に検索機能に切り替える関数
  const changeToSearchMode = useCallback(() => {
    // 検索機能に切り替える
    chatCurrentService.setCurrentMode(Chat.Mode.Search)
  }, [chatCurrentService])

  return {
    changeToSearchMode,
  }
}
