import { createUninitializedContext } from '@crew/utils/context'
import {} from '@reduxjs/toolkit/query'
import { Dispatch, SetStateAction } from 'react'

/** Context用の型 */
type ProjectSettingPageContextType = {
  projectSubject: string
  setProjectSubject: Dispatch<SetStateAction<string>>
}

/** Context */
const {
  context: projectSettingPageContext,
  useInitializedContext: useProjectSettingPageContext,
} = createUninitializedContext<ProjectSettingPageContextType>()

export { useProjectSettingPageContext }

export const useProjectSettingPage = () => {
  return {
    projectSettingPageContext,
  }
}
