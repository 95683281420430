import { ColumnDef, TableOptions, getCoreRowModel } from '@tanstack/react-table'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { FC, memo, useMemo } from 'react'
import BaselineDelete from '~icons/ic/baseline-delete'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useState } from 'react'
import {
  NewUserType,
  useTenantUserEntryContext,
} from '../../../../useTenantUserEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { InvitationRoleType } from '@crew/enums/app'
import { CrewButton } from 'components/elements/crewButton/crewButton'

// renderとして使うのでmemo不可
const UserType: FC<{
  tenantRoleType: string
}> = (props) => {
  const [t] = useTranslation()
  const tenantRole = Object.values(InvitationRoleType).find(
    (roleType) => roleType.key === props.tenantRoleType
  )

  return <>{tenantRole && t(tenantRole.label)}</>
}

export type InviteNewUserTableProps = {
  users: NewUserType[]
}

export const InviteNewUserTable = memo((props: InviteNewUserTableProps) => {
  const { t } = useTranslation()

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
    right: ['action'],
  })

  const [selectedUser, setSelectedUser] = useState<NewUserType>()

  const { selectedNewUsers, setSelectedNewUsers } = useTenantUserEntryContext()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  //Remove button click
  const handleRemoveButtonClick = useCallback(
    (newUser: NewUserType) => {
      setSelectedUser(newUser)
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (selectedUser) {
      //remove selected user from selectedNewUsers
      const filterSelectedNewUsers = selectedNewUsers.filter(
        (user) => user.emailOrLoginID !== selectedUser.emailOrLoginID
      )
      //update selectedNewUsers
      setSelectedNewUsers(filterSelectedNewUsers)
    }

    closeConfirmDialog()
  }, [closeConfirmDialog, selectedNewUsers, selectedUser, setSelectedNewUsers])

  const columns = useMemo<ColumnDef<NewUserType>[]>(
    () => [
      {
        id: 'userIdOrEmailAddress',
        header: () => t('label.userIdOrEmailAddress'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.emailOrLoginID}</div>
        ),
        size: 500,
        minSize: 50,
      },
      {
        id: 'role',
        header: () => t('label.role'),
        cell: ({ row }) => (
          <div className="truncate">
            <UserType
              key={row.original.invitationRoleType}
              tenantRoleType={row.original.invitationRoleType}
            />
          </div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'expirationDate',
        header: () => t('label.expirationDate'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.expirationDatetime}</div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'action',
        header: '',
        cell: ({ row }) => (
          <div className="flex flex-1 justify-center">
            <CrewButton
              icon={<BaselineDelete width={20} height={20} />}
              tabIndex={-1}
              stylingMode="text"
              onClick={() => handleRemoveButtonClick(row.original)}
              size="sm"
            />
          </div>
        ),
        size: 80,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [handleRemoveButtonClick, t]
  )

  const tableOptions: TableOptions<NewUserType> = {
    data: props.users,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnPinning,
    },
    onColumnVisibilityChange: setColumnVisibility,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  return (
    <>
      {/* new users table */}
      <CrewTable
        tableOptions={tableOptions}
        showPager={false}
        showColumnSelector={false}
      />

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </>
  )
})
