import { FC, LabelHTMLAttributes, memo } from 'react'
import classNames from 'classnames'

type Props = LabelHTMLAttributes<HTMLLabelElement> & {
  text?: string
  required?: boolean
}

export const CrewFieldLabel: FC<Props> = memo(
  ({ className, text, required = false, ...rest }) => {
    return (
      <label className={classNames(className ?? 'crew-text-gray-4')} {...rest}>
        {text}
        <span
          className={classNames(
            'text-crew-red-3-light',
            'dark:text-crew-red-2-dark',
            'ml-1'
          )}
        >
          {required ? '*' : null}
        </span>
      </label>
    )
  }
)
