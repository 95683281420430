import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { formatByteSize } from '@crew/utils/number'
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  TableOptions,
  getCoreRowModel,
} from '@tanstack/react-table'
import { CrewFileName } from 'components/elements/crewFileName/crewFileName'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { File } from '@crew/apis/file/models/getFiles/response'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { CrewFileTag } from 'components/elements/crewFileTag/crewFileTag'
import { useGetFilesQuery } from '@crew/apis/file/fileApis'
import { GetFilesRequest } from '@crew/apis/file/models/getFiles/request'
import { Region, SettingKeyType } from '@crew/enums/app'
import { skipToken } from '@reduxjs/toolkit/query'
import { DEFAULT_PAGING_PARAMS, OPERATION_KEY } from 'configs/constants'
import { FileSearchOptions } from 'enums/app'
import { useUserSetting } from '@crew/states'
import qs from 'qs'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getParamAsString, getParamAsArray, getParamsOperator } from 'utils'
import _ from 'lodash'
import { useAppDispatch } from 'states/hooks'
import { fileCountUpdated } from '../../states/searchPageSlice'
import { useValueChangeEffect } from '@crew/hooks'
import { CrewFileChangerItem } from 'components/elements/crewFileChangerItem/crewFileChangerItem'

// renderとして使うのでmemo不可
const SizeText: FC<{ size: number }> = (props) => (
  <>{formatByteSize(props.size)}</>
)

// renderとして使うのでmemo不可
const UpdateAt: FC<{ date: string }> = (props) => {
  const [t] = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  return (
    <>
      {props
        ? t('format.timestamp', {
            value: props.date,
            timeZone: defaultUserProfileRegion,
          })
        : undefined}
    </>
  )
}

export const SearchFileResultList = memo(() => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const params = qs.parse(searchParams.toString())

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  // Keyword params
  const keyword = useMemo(
    () => getParamAsString(FileSearchOptions.Keyword.id, params),
    [params]
  )

  // キーワードフィルタ条件
  const keywordFilterCondition = useMemo(
    () => getParamsOperator(OPERATION_KEY, params),
    [params]
  )

  // Get files by keyword
  const requestParams: GetFilesRequest | undefined = keyword
    ? {
        keyword,
        keywordFilterCondition,
        tagIds: undefined,
        projectId: undefined,
        projectGroupIds: undefined,
        createdById: undefined,
        updatedById: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        sort: searchParams.getAll('sort') || undefined,
        isIncludesAnonymousFiles: undefined,
      }
    : undefined
  const { data: getFilesResult } = useGetFilesQuery(requestParams ?? skipToken)

  const files = useMemo(
    () => getFilesResult?.files ?? [],
    [getFilesResult?.files]
  )

  const totalCount = useMemo(
    () => getFilesResult?.totalCount ?? 0,
    [getFilesResult?.totalCount]
  )

  // update file badge count when total count of files change
  useValueChangeEffect(
    () => {
      dispatch(fileCountUpdated(totalCount))
    },
    [dispatch, totalCount],
    totalCount
  )

  const sorting: SortingState = useMemo(() => {
    const sortArray = getParamAsArray('sort', params) || []
    return sortArray.map((sort) => {
      const [id, direction] = sort.split('.')
      return {
        id,
        desc: direction === 'desc',
      }
    })
  }, [params])

  const pageCount = Math.ceil((totalCount ?? 0) / pagination.pageSize)

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
    right: ['action'],
  })

  // https://github.com/TanStack/table/discussions/3899
  // https://github.com/TanStack/table/discussions/3619
  // https://github.com/infonomic/remix.infonomic.io/blob/d3a7f628d3ad6e1e80cc80d4ac72db74da90e8d6/app/routes/admin%2B/users.tsx#L116
  // Func handle change pagination
  const handlePaginationChange: OnChangeFn<PaginationState> = useCallback(
    (updaterOrValue) => {
      let values: PaginationState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(pagination)
      } else {
        values = updaterOrValue
      }

      const newParams = {
        ...params,
        pageIndex: values.pageIndex,
        pageSize: values.pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, pagination, params]
  )

  // Func handle change sorting
  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      let values: SortingState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(sorting)
      } else {
        values = updaterOrValue
      }

      const sortList = values.map((sort) => {
        return `${sort.id}.${sort.desc ? 'desc' : 'asc'}`
      })

      const newParams = {
        ...params,
        sort: sortList,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, params, sorting]
  )

  const columns = useMemo<ColumnDef<File>[]>(
    () => [
      {
        id: 'relatedItem',
        header: () => t('label.relatedItem'),
        cell: ({ row }) => (
          <div className="w-full">
            <CrewRelatedItemLink
              entityType={row.original.entityType}
              id={row.original.entityRecordId}
              className="line-clamp-2 break-all"
            />
          </div>
        ),
        size: 200,
        minSize: 50,
      },
      {
        id: 'fileName',
        accessorKey: 'fileName',
        header: () => t('label.fileName'),
        cell: ({ row }) => (
          <div className="w-full overflow-hidden">
            <CrewFileName
              fileId={row.original.id}
              fileName={row.original.name}
              entityType={EntityType.File}
              className="line-clamp-2 break-all"
            />
          </div>
        ),
        size: 500,
        minSize: 50,
      },
      {
        id: 'tags',
        header: () => t('label.tag'),
        cell: ({ row }) => (
          // we show only 2 lines of tags so need using max-h-[60px]
          <div className="w-full flex flex-wrap gap-1 text-sm overflow-hidden max-h-[52px]">
            {row.original.tags.map((tag) => (
              <CrewFileTag key={tag.id} tag={tag} />
            ))}
          </div>
        ),
        size: 240,
        minSize: 50,
      },
      {
        id: 'revision',
        accessorKey: 'revision',
        header: () => t('label.version'),
        cell: (ctx) => (
          <div className="text-right w-full">{ctx.getValue() as string}</div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'size',
        accessorKey: 'size',
        header: () => t('label.size'),
        cell: ({ row }) => (
          <div className="text-right w-full truncate">
            <SizeText size={row.original.size} />
          </div>
        ),
        size: 80,
        minSize: 50,
      },
      {
        id: 'updatedBy',
        accessorKey: 'updatedBy',
        header: () => t('label.updatedBy'),
        cell: ({ row }) => (
          <div className="text-right w-full truncate">
            <CrewFileChangerItem
              displayName={row.original.lastUpdatedAttachmentBy.displayName}
              userId={row.original.lastUpdatedAttachmentBy.id}
              version={row.original.lastUpdatedAttachmentBy.version}
            />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'lastUpdatedAttachmentAt',
        accessorKey: 'lastUpdatedAttachmentAt',
        header: () => t('label.updateDatetime'),
        cell: ({ row }) => (
          <div className="truncate">
            <UpdateAt date={row.original.lastUpdatedAttachmentAt} />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
    ],
    [t]
  )

  const tableOptions: TableOptions<File> = {
    data: files,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    pageCount,
    state: {
      pagination,
      sorting,
      columnVisibility,
      columnPinning,
    },
    getRowId: (row) => row.id,
    onPaginationChange: handlePaginationChange,
    onSortingChange: handleSortingChange,
    onColumnVisibilityChange: setColumnVisibility,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    enableRowSelection: true,
    maxMultiSortColCount: 2,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  return (
    <div className="flex-1 overflow-y-hidden">
      {/* files table */}
      <CrewTable tableOptions={tableOptions} />
    </div>
  )
})
