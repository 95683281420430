import {
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  INDENT_CONTENT_COMMAND,
  LexicalEditor,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical'
import { DropDownItem, ToolbarDropDown } from './toolbarDropDown'

import { useTranslation } from '@crew/modules/i18n'
import FormatAlignCenter from '~icons/material-symbols/format-align-center'
import FormatAlignJustify from '~icons/material-symbols/format-align-justify'
import FormatAlignLeft from '~icons/material-symbols/format-align-left'
import FormatAlignRight from '~icons/material-symbols/format-align-right'
import FormatIndentDecrease from '~icons/material-symbols/format-indent-decrease'
import FormatIndentIncrease from '~icons/material-symbols/format-indent-increase'

// 引数のblockTypeによって、表示するアイコンを変える
const valueToIcon = {
  left: <FormatAlignLeft width={24} height={24} />,
  center: <FormatAlignCenter width={24} height={24} />,
  right: <FormatAlignRight width={24} height={24} />,
  justify: <FormatAlignJustify width={24} height={24} />,
  outdent: <FormatIndentDecrease width={24} height={24} />,
  indent: <FormatIndentIncrease width={24} height={24} />,

  // 入力がない時に表示するデフォルトは左寄せ
  '': <FormatAlignLeft width={24} height={24} />,

  // 以下は現状使用されていないがElementFormatTypeに存在しており、
  // iconに指定するにあたりエラーが出てしまうので定義しておく
  start: <FormatAlignLeft width={24} height={24} />,
  end: <FormatAlignRight width={24} height={24} />,
}

export function ToolbarIndentAndAlignChangeDropDown({
  editor,
  value,
  isRTL,
  disabled = false,
}: {
  editor: LexicalEditor
  value: ElementFormatType
  isRTL: boolean
  disabled: boolean
}) {
  const { t } = useTranslation()

  return (
    <ToolbarDropDown
      disabled={disabled}
      icon={valueToIcon[value]}
      buttonAriaLabel="Formatting options for text alignment"
      showDropDownIcon={true}
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
        }}
        className="item"
      >
        <div className="flex justify-center items-center gap-x-1">
          <FormatAlignLeft width={24} height={24} />
          <span className="text">{t('action.lexical.leftAlign')}</span>
        </div>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
        }}
        className="item"
      >
        <div className="flex justify-center items-center gap-x-1">
          <FormatAlignCenter width={24} height={24} />
          <span className="text">{t('action.lexical.centerAlign')}</span>
        </div>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
        }}
        className="item"
      >
        <div className="flex justify-center items-center gap-x-1">
          <FormatAlignRight width={24} height={24} />
          <span className="text">{t('action.lexical.rightAlign')}</span>
        </div>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
        }}
        className="item"
      >
        <div className="flex justify-center items-center gap-x-1">
          <FormatAlignJustify width={24} height={24} />
          <span className="text">{t('action.lexical.justifyAlign')}</span>
        </div>
      </DropDownItem>

      {/* <Divider /> */}

      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
        }}
        className="item"
      >
        <div className="flex justify-center items-center gap-x-1">
          <FormatIndentDecrease width={24} height={24} />
          <span className="text">{t('action.lexical.outdent')}</span>
        </div>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
        }}
        className="item"
      >
        <div className="flex justify-center items-center gap-x-1">
          <FormatIndentIncrease width={24} height={24} />
          <span className="text">{t('action.lexical.indent')}</span>
        </div>
      </DropDownItem>
    </ToolbarDropDown>
  )
}
