import { TaskKindFilters } from 'enums/app'
import { useMemo } from 'react'
import {
  useLazyGetLookupTaskKindQuery,
  useLazyGetLookupTaskKindsQuery,
} from '@crew/apis/lookup/lookupApis'
import { useTranslation } from '@crew/modules/i18n'
import { useDataSource } from './useDataSource'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { EntityType } from '@crew/enums/domain'

export const useTaskKindDataSource = (
  entityType: EntityType | undefined,
  entityRecordId: string | undefined,
  hasBaseFilter: boolean,
  isGrouped: boolean,
  multiSelect: boolean
) => {
  const { t } = useTranslation()
  const [lazyGetTaskKindsQuery] = useLazyGetLookupTaskKindsQuery()
  const [lazyGetTaskKindQuery] = useLazyGetLookupTaskKindQuery()

  // ""全ての種別""をenumにするとpush,unshiftで型エラーになるため定数で作成する
  const taskKindFilterBaseData = useMemo(() => {
    return {
      id: String(TaskKindFilters.AllTaskKinds), //task type id response from api is dynamic (type is string), need convert enum type to string
      name: t('label.' + TaskKindFilters.AllTaskKinds),
      // `projectId` と `projectName` の値は不要
      // ただし、プロジェクトによりグループ化する場合、projectNameがない場合、グループ名が表示される箇所には何も表示されない
      // グループ名が全プロジェクト向けとして表示させるため
      // `すべての種類`が一覧の最初に表示されるように、projectId='AAAAAAAAAAAAAAAAAAAAAAAAAA'を設定する
      // len('AAAAAAAAAAAAAAAAAAAAAAAAAA') = len(ulid)
      projectId: 'AAAAAAAAAAAAAAAAAAAAAAAAAA',
      projectName: t('label.forAllProject'),
    }
  }, [t])

  return useDataSource(
    () => ({
      key: 'id',
      // Using 'raw' load mode to avoid grouping issue
      // https://supportcenter.devexpress.com/ticket/details/t1017195/datasource-grouping-not-rendering-correctly-using-custom-datasource
      loadMode: isGrouped ? 'raw' : undefined,
      load: async (loadOptions) => {
        if (loadOptions.filter && multiSelect) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // Filtering selected task type IDs
          const filteringTaskKindIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetTaskKindsQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            name: undefined,
            taskKindIds: filteringTaskKindIds,
          }).unwrap()

          return response.taskKinds
        } else {
          if (loadOptions.searchValue) {
            // インクリメンタルサーチ
            const response = await lazyGetTaskKindsQuery({
              entityType: entityType,
              entityRecordId: entityRecordId,
              name: loadOptions.searchValue,
              taskKindIds: undefined,
            }).unwrap()

            return response.taskKinds
          }

          const response = await lazyGetTaskKindsQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            name: undefined,
            taskKindIds: undefined,
          }).unwrap()

          const resultData = response.taskKinds.map((taskKind) => {
            return { ...taskKind, name: t(taskKind.name) }
          })

          return hasBaseFilter
            ? [taskKindFilterBaseData, ...resultData]
            : resultData
        }
      },
      byKey: async (taskKindId: string) => {
        // 初期ロード時にセットされているのがすべての種別同値であれば""すべての種別""のBaseDataをセットする
        if (taskKindId === TaskKindFilters.AllTaskKinds) {
          return taskKindFilterBaseData
        }

        const response = await lazyGetTaskKindQuery({
          taskKindId: taskKindId,
        }).unwrap()

        return response.taskKind
      },
      group: isGrouped ? 'projectId' : null,
      // 「すべての種類」が表示される場合、「すべての種類」項目がセレクトボックスの一番目の項目になるように、データを projectId で並べ替える
      sort: hasBaseFilter
        ? [{ selector: isGrouped ? 'projectId' : null, desc: true }]
        : undefined,
    }),
    [
      entityRecordId,
      entityType,
      hasBaseFilter,
      isGrouped,
      lazyGetTaskKindQuery,
      lazyGetTaskKindsQuery,
      multiSelect,
      t,
      taskKindFilterBaseData,
    ]
  )
}
