import { createContext, useContext } from 'react'

type CrewEmojiPickerContextValue = {
  open: (ref: Element, callback: (emoji: string) => void) => void
  isOpen: boolean
  close: () => void
}

export const CrewEmojiPickerContext = createContext<
  CrewEmojiPickerContextValue | undefined
>(undefined)
const useCrewEmojiPickerContext = (): CrewEmojiPickerContextValue => {
  const context = useContext(CrewEmojiPickerContext)
  if (!context) {
    throw new Error('Must be running in EmojiPicker Context')
  }

  return context
}

export const useCrewEmojiPicker = () => useCrewEmojiPickerContext()
