import { useTranslation } from '@crew/modules/i18n'
import BaseOutlineVideocam from '~icons/ic/outline-videocam'
import { FC, memo, useCallback } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useModal } from 'components/layouts/modal/useModal'
import { EventEntryDialog } from 'features/project/components/eventEntryDialog/eventEntryDialog'
import { EventSearch } from './components/eventSearch/eventSearch'

export const EventListToolbar: FC = memo(() => {
  const { t } = useTranslation()

  // イベント登録ダイアログ
  const [isEventEntryDialogOpen, openEventEntryDialog, closeEventEntryDialog] =
    useModal()

  // handle open new event modal
  const handleOpenNewEventModal = useCallback(() => {
    openEventEntryDialog()
  }, [openEventEntryDialog])

  // Register event finish
  const handleEventRegistered = useCallback(
    (eventId: string, deleteFlg: boolean = false) => {
      // Close entry dialog
      closeEventEntryDialog()
    },
    [closeEventEntryDialog]
  )

  return (
    <div className="flex flex-col gap-2.5">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1 crew-text-gray-4">
          <BaseOutlineVideocam width={24} height={24} />
          <p>{t('label.meeting')}</p>
        </div>

        {/* 新規会議 */}
        <CrewButton
          type="primary"
          onClick={handleOpenNewEventModal}
          text={t('action.newMeeting')}
        />
      </div>

      <EventSearch />

      <EventEntryDialog
        isEditMode={false}
        title={t('label.registerNewMeeting')}
        isOpen={isEventEntryDialogOpen}
        onClose={closeEventEntryDialog}
        onSubmit={handleEventRegistered}
      />
    </div>
  )
})
