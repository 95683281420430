import { FC, memo, useMemo } from 'react'
import { UnreadProjectItem } from './components/unreadProjectItem/unreadProjectItem'
import { useAppSelector } from 'states/hooks'
import { ActiveChatRoomInfo, selectUnreadActiveChatRooms } from '@crew/states'
import { itemComparer } from '@crew/utils'

// アコーディオン内に表示されるアイテム表示
export const UnreadProjectList: FC = memo(() => {
  // 未読があるアクティブチャットルーム
  const unreadChatRooms = useAppSelector(selectUnreadActiveChatRooms)

  // 表示名の昇順でソートする
  const sortedUnreadChatRooms = useMemo(() => {
    return [...unreadChatRooms].sort(
      itemComparer<ActiveChatRoomInfo>('asc', 'name')
    )
  }, [unreadChatRooms])

  return (
    <ul className="flex-1 mb-2">
      {sortedUnreadChatRooms.map((chatRoom) => {
        return (
          <UnreadProjectItem key={chatRoom.chatRoomId} chatRoom={chatRoom} />
        )
      })}
    </ul>
  )
})
