import { convertMinutesToHHMM } from '@crew/utils'
import { memo, FC } from 'react'
import { WorkTime } from '../../dateCellData'

type WorkingTimeItemProps = {
  workTime: WorkTime
}

export const WorkingTimeItem: FC<WorkingTimeItemProps> = memo((props) => {
  return (
    <div className="flex flex-row items-end justify-between gap-2.5 p-0.5 bg-crew-blue-100 text-crew-gray-900">
      <div className="flex flex-col">
        {/* task name */}
        <p className="font-bold line-clamp-1 text-sm">
          {props.workTime.subject}
        </p>
        {/* project name */}
        <p className="line-clamp-1 text-xs">{props.workTime.project.subject}</p>
      </div>
      <span className="text-sm">
        {convertMinutesToHHMM(props.workTime.actualWorkTimes)}
      </span>
    </div>
  )
})
