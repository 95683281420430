import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/dist/constants'
import { useBookmarkService } from '@crew/states'
import { ComponentCallbackHandler } from '@crew/utils'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { TextBox } from 'devextreme-react'
import { useBookmarkFilterDataSource } from 'hooks/dataSource/useResourceDataSource'
import { debounce } from 'lodash'
import { memo, useRef, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'

export const BookmarkHeader = memo(() => {
  const dispatch = useAppDispatch()

  // キーワード検索
  const keywordTextBoxRef = useRef<TextBox>(null)
  // アーカイブフィルタDataSource
  const bookmarkFilterDataSource = useBookmarkFilterDataSource()

  // Bookmark関連のSliceへの操作を提供するService
  const bookmarkService = useBookmarkService(dispatch)
  // Reduxに格納されているアーカイブフィルタの値を取得する
  const archiveFilter = useAppSelector(
    (state) => state.message.bookmark.archiveFilter
  )
  // Reduxに格納されているキーワードの値を取得する
  const keyword = useAppSelector((state) => state.message.bookmark.keyword)

  /**
   * アーカイブフィルタ変更時の処理
   */
  const handleArchiveFilterChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBox, 'onValueChange'>
  >(
    (value) => {
      // Reduxに格納されているアーカイブフィルタの値を更新する
      bookmarkService.setBookmarkArchiveFilter({ archiveFilter: value })
    },
    [bookmarkService]
  )

  // キーワード検索の遅延実行
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        // Reduxに格納されているキーワードの値を更新する
        bookmarkService.setBookmarkKeyword({ keyword: value })
      }, SEARCH_TIMEOUT_MSEC),
    [bookmarkService]
  )

  /**
   * キーワードテキストボックスの値変更時の処理
   */
  const handleKeywordValueChanged = useCallback(() => {
    // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
    const input = keywordTextBoxRef.current?.instance.option('text')

    if (input !== undefined && input !== keyword) {
      debouncedSearch(input)
    }
  }, [keyword, debouncedSearch])

  return (
    <>
      <div className="w-full flex gap-x-2.5 mb-4 justify-between p-2">
        <div className="flex-1 flex gap-x-2.5 items-center">
          {/* アーカイブフィルタ */}
          <CrewSelectBox
            defaultValue={archiveFilter}
            dataSource={bookmarkFilterDataSource}
            displayExpr="name"
            valueExpr="id"
            searchEnabled={false}
            minSearchLength={0}
            showClearButton={false}
            onValueChange={handleArchiveFilterChanged}
          />
          {/* キーワード */}
          <CrewTextBox
            valueChangeEvent="input change"
            showClearButton={true}
            ref={keywordTextBoxRef}
            defaultValue={keyword}
            mode="search"
            onInput={handleKeywordValueChanged}
          />
        </div>
      </div>
    </>
  )
})
