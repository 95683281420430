import { memo, useCallback, useMemo, useState } from 'react'
import { useAppSelector } from 'states/hooks'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import {
  CrewAvatar,
  CrewAvatarShapeType,
} from 'components/elements/crewAvatar/crewAvatar'
import classNames from 'classnames'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useSignupExistingAccount } from './useSignupExistingAccount'
import { generateImageAvatarUrl } from '@crew/utils/dist/avatar'
import { EntityType } from '@crew/enums/dist/domain'
import { CrewReCaptcha } from 'components/elements/crewReCaptcha/crewReCaptcha'
import { useLoginMutation } from '@crew/apis/dist/app/appApis'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useWebsocketForceDisconnect } from '@crew/providers/websocket'
import { getDefaultTabValue } from '@crew/utils/dist/enum'
import { ProjectListTabs } from 'enums/app'
import { setLoggedInUser } from 'features/app/states/appSlice'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useReCaptcha } from 'hooks/useReCaptcha'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'states/hooks'
import { FormValues } from './useSignupExistingAccount'
import { resetReduxState } from 'states/store'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewTermsOfServiceAndPrivacyPolicyLink } from 'components/elements/crewTermsOfServiceAndPrivacyPolicyLink/crewTermsOfServiceAndPrivacyPolicyLink'

type Project = {
  id: string
  subject: string
}

type ProjectMemberPending = {
  projectId: string
  project: Project
  destinationEmail: string
}

export type SignupExistingAccountProps = {
  projectMemberPending: ProjectMemberPending | null
}

export const SignupExistingAccount = memo(
  (props: SignupExistingAccountProps) => {
    const themeMode = useAppSelector((state) => state.app.currentTheme)
    const LogoImg = useMemo(
      () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
      [themeMode]
    )

    const errorMessageBaseClassNames = useMemo(() => {
      return 'crew-error-text text-center'
    }, [])

    const {
      control,
      handleSubmit,
      formState,
      validateRules,
      watch,
      joinProject,
    } = useSignupExistingAccount()

    const { t } = useTranslation()
    const { invitationToken } = useParams()
    const { navigate } = useCrewNavigate()
    const dispatch = useAppDispatch()

    const [loginMutation] = useLoginMutation()
    const websocketForceDisconnect = useWebsocketForceDisconnect()

    const [serverErrorMessage, setServerErrorMessage] = useState('')

    const { isReCaptchaVerified } = useReCaptcha()

    // 利用規約とプライバシーポリシーへの同意チェック状態監視
    const agree = watch('agree')

    const canSend = useMemo(
      // formState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      () =>
        formState.isDirty &&
        Object.keys(formState.errors).length === 0 &&
        isReCaptchaVerified,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState, isReCaptchaVerified]
    )

    // 「ログインして参加する」ボタンクリック
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (values: FormValues) => {
        try {
          if (!invitationToken) return

          await joinProject(values, invitationToken)

          //join project success, go to project detail page
          // 念のためログイン前にwebsocketを強制切断しておく
          websocketForceDisconnect()
          // ログイン
          const response = await loginMutation({
            loginId: values.loginId,
            password: values.password,
          }).unwrap()

          // if 2 step login enable so data user may undefined so need check response have data here
          if (!response.data) {
            setServerErrorMessage(t('message.auth.failedToLogin'))
            return
          }

          // reduxを初期化
          resetReduxState()

          // 秘匿ではないuser情報を保存
          dispatch(setLoggedInUser(response.data))

          // Redirect to project detail page
          navigate(
            `/${EntityType.Project}/${
              props.projectMemberPending?.projectId
            }/${getDefaultTabValue(ProjectListTabs)}`
          )
        } catch (err) {
          setServerErrorMessage(t('message.auth.failedToLogin'))
        }
      }
      handleSubmit(onSubmit)()
    }, [
      handleSubmit,
      invitationToken,
      joinProject,
      websocketForceDisconnect,
      loginMutation,
      dispatch,
      navigate,
      props.projectMemberPending?.projectId,
      t,
    ])

    return props.projectMemberPending ? (
      <div className="flex flex-row h-screen pt-4 sm:pt-12 justify-center crew-bg-gray-1">
        <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-sm justify-center">
          <div className="flex flex-col justify-center gap-y-2.5 items-center">
            {/* Logo */}
            <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto" />
            <p className="text-center">
              {t('label.signupExistingUserMessage')}
            </p>

            <div className="flex items-center gap-x-2">
              {/* Project avatar */}
              <CrewAvatar
                displayName={props.projectMemberPending.project.subject}
                imageURL={generateImageAvatarUrl(
                  EntityType.Project,
                  props.projectMemberPending.project.id
                )}
                shape={CrewAvatarShapeType.Square}
                cacheValue={
                  props.projectMemberPending.project.id +
                  props.projectMemberPending.project.subject
                } // キャッシュ対策用の値：PJメンバー保留テーブルはバージョンを持っていないため、「プロジェクトID + プロジェクト名」を使用
              />

              {/* Project subject */}
              <p className="text-2xl">
                {props.projectMemberPending.project.subject}
              </p>
            </div>

            <p className="text-center">
              {t('label.signupExistingUserMessage2', {
                email: props.projectMemberPending.destinationEmail,
              })}
            </p>
          </div>
          <form className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              {/* サーバーエラーメッセージ */}
              <div
                className={classNames(errorMessageBaseClassNames, {
                  'h-4': serverErrorMessage.length > 0,
                })}
                data-testid="login-form-server-error-message"
              >
                {serverErrorMessage}
              </div>
              {/* ログインID */}
              <div>
                <CrewTextBoxField
                  id="loginId"
                  name="loginId"
                  control={control}
                  placeholder={t('label.userIdOrEmailAddress')}
                  labelMode="floating"
                  rules={validateRules.loginId}
                  inputAttr={{
                    'data-testid': 'username',
                  }}
                  showLabel={false}
                />
              </div>

              {/*  氏名 */}
              <div>
                <CrewTextBoxField
                  id="fullName"
                  name="fullName"
                  control={control}
                  placeholder={t('label.displayName')}
                  labelMode="floating"
                  rules={validateRules.fullName}
                  inputAttr={{
                    'data-testid': 'username',
                  }}
                  showLabel={false}
                />
              </div>

              {/* パスワード */}
              <div>
                <CrewTextBoxField
                  id="password"
                  name="password"
                  control={control}
                  placeholder={t('label.password')}
                  labelMode="floating"
                  rules={validateRules.password}
                  mode="password"
                  inputAttr={{
                    'data-testid': 'password',
                  }}
                  showLabel={false}
                />
              </div>

              {/* 「利用規約とプライバシーポリシーに同意します」 */}
              <div className="flex flex-row justify-center gap-3">
                <CrewCheckBoxField
                  id="agree"
                  name="agree"
                  control={control}
                  rules={validateRules.agree}
                />
                <CrewTermsOfServiceAndPrivacyPolicyLink />
              </div>
            </div>

            {/* ログインして参加する */}
            <div className="flex flex-row justify-center">
              <CrewButton
                className="grow"
                type="primary"
                disabled={!canSend || !agree}
                data-testid="loginToJoin"
                text={t('action.loginToJoin')}
                onClick={handleSubmitButtonClick}
              />
            </div>

            {/* Recaptcha */}
            <CrewReCaptcha />
          </form>
        </div>
      </div>
    ) : (
      <></>
    )
  }
)
