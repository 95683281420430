import { useTranslation } from '@crew/modules/i18n'
import { useDataSource } from './useDataSource'
import { useLazyGetMyParticipatedProjectsQuery } from '@crew/apis/project/projectApis'
import { useLazyGetLookupRelatedItemQuery } from '@crew/apis/dist/lookup/lookupApis'
import { EntityType, GroupScheduleFilterType } from '@crew/enums/domain'
import { useMemo } from 'react'

export const useParticipatedProjectDataSource = () => {
  const { t } = useTranslation()

  const [lazyGetMyParticipatedProjectsQuery] =
    useLazyGetMyParticipatedProjectsQuery()
  const [lazyGetLookupRelatedItemQuery] = useLazyGetLookupRelatedItemQuery()

  const builtIn = useMemo(() => {
    return {
      id: GroupScheduleFilterType.GroupScheduleCustomFilter,
      subject: t(`label.${GroupScheduleFilterType.GroupScheduleCustomFilter}`),
    }
  }, [t])

  return useDataSource(
    () => ({
      loadMode: 'raw',
      key: 'id',
      load: async (loadOptions) => {
        const result = await lazyGetMyParticipatedProjectsQuery().unwrap()

        return [builtIn, ...result.projects]
      },
      byKey: async (id: string) => {
        if (id === builtIn.id) {
          return {
            id: builtIn.id,
            subject: builtIn.subject,
          }
        }

        const result = await lazyGetLookupRelatedItemQuery({
          id,
          entityType: EntityType.Project,
        }).unwrap()

        return {
          id: result.relatedItem?.id,
          subject: result.relatedItem?.name,
        }
      },
    }),
    [builtIn, lazyGetLookupRelatedItemQuery, lazyGetMyParticipatedProjectsQuery]
  )
}
