/**
 * DataSourceのFilter式の型定義
 * https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering
 * (c) 2022 TMC
 */

export const BinaryFilterOperator = [
  '=',
  '<>',
  '>',
  '>=',
  '<',
  '<=',
  'startswith',
  'endswith',
  'contains',
  'notcontains',
] as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BinaryFilterOperator = (typeof BinaryFilterOperator)[number]

/**
 * Operatorが'='の場合は省略できる
 */
type BinaryFilterDefault = [string, string | number]
export const IsBinaryFilterDefault = (op: any): op is BinaryFilterDefault =>
  Array.isArray(op) &&
  op.length === 2 &&
  typeof op[0] === 'string' &&
  ['string', 'number'].includes(typeof op[1])

type BinaryFilterComplex = [string, BinaryFilterOperator, string | number]
export const IsBinaryFilterComplex = (op: any): op is BinaryFilterComplex =>
  Array.isArray(op) &&
  op.length === 3 &&
  typeof op[0] === 'string' &&
  BinaryFilterOperator.includes(op[1])

/**
 * Binary Filter
 * https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering/Binary_Filter_Operations
 */
export type BinaryFilter = BinaryFilterDefault | BinaryFilterComplex
export const IsBinaryFilter = (op: any): op is BinaryFilter =>
  IsBinaryFilterComplex(op) || IsBinaryFilterDefault(op)

export const UnaryFilterOperator = ['!'] as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UnaryFilterOperator = (typeof UnaryFilterOperator)[number]

/**
 * Unary Filter
 * https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering/Unary_Filter_Operation
 */
export type UnaryFilter = [UnaryFilterOperator, Filter]
export const IsUnaryFilter = (op: any): op is UnaryFilter =>
  Array.isArray(op) &&
  op.length === 2 &&
  typeof op[0] === 'string' &&
  UnaryFilterOperator.includes(op[0] as any) &&
  IsFilter(op[1])

export const GroupFilterOperator = ['and', 'or'] as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type GroupFilterOperator = (typeof GroupFilterOperator)[number]

/**
 * Group Filter
 * https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering/Group_Filter_Operations
 * 厳密ではないがTypeScriptのtuple型の制約上許容する
 */
export type GroupFilter = [Filter, ...(GroupFilterOperator | Filter)[]]
export const IsGroupFilter = (op: any): op is GroupFilter =>
  Array.isArray(op) &&
  op.length >= 2 &&
  op.every(
    (e, i, op) =>
      IsFilter(e) || // Fiterはどの位置も許容される(opが無い場合はand扱い)
      (i !== 0 && // opは最初でなく、且つ直前の要素がop(string)でない場合に許容される
        typeof op[i - 1] !== 'string' &&
        GroupFilterOperator.includes(e))
  )

/**
 * DevExtreme Data layer filter
 * https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering
 */
export type Filter = BinaryFilter | UnaryFilter | GroupFilter
export const IsFilter = (op: any): op is Filter =>
  Array.isArray(op) &&
  (IsBinaryFilter(op) || IsUnaryFilter(op) || IsGroupFilter(op))

/**
 * タグボックス用カスタムデータソースのloadOptions.filter判定・加工用の型
 */
// ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
type validLoadOptionsFilterElement = ['id', '=', string]
type validLoadOptionsFilter =
  | validLoadOptionsFilterElement
  | [validLoadOptionsFilterElement, ...('or' | validLoadOptionsFilterElement)[]]

/**
 * タグボックス用カスタムデータソースのloadOptions.filterの型チェック
 */
export const isValidLoadOptionsFilter = (f: any): f is validLoadOptionsFilter =>
  (IsBinaryFilter(f) && f[0] === 'id' && (f.length === 2 || f[1] === '=')) ||
  (IsGroupFilter(f) &&
    f.length >= 3 &&
    (f.length - 1) % 2 === 0 &&
    f.every((e, i) => (i % 2 === 0 ? isValidLoadOptionsFilter(e) : e === 'or')))

/**
 * タグボックス用カスタムデータソースのloadOptions.filterのid値の配列加工
 */
export const pickupIdsFromLoadOptionsFilter = function func(
  f: validLoadOptionsFilter
): string[] {
  return IsBinaryFilter(f)
    ? [String([...f].pop())]
    : f
        .filter((e, i): e is validLoadOptionsFilterElement => i % 2 === 0)
        .map(func)
        .flat()
}
