import {
  AudioInputDevice,
  Device,
  isAudioTransformDevice,
} from 'amazon-chime-sdk-js'
import { useAudioInputs } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useCallback, useEffect, useMemo } from 'react'
import { useMicrophoneBindVoiceFocus } from '../microphoneBindVoiceFocusProvider'

//TODO: カメラと統一できそうなので後ほどリファクタリング
/**
 * デバイスからIDを取得する。変換デバイスには対応しない。統一的にデバイスIDを取得する方法がないか、そもそもIDが存在しないため
 * @param device IDを取得したいデバイス
 * @returns デバイスID
 */
const getDeviceId = (device: Device | undefined | null): string | undefined => {
  if (device === undefined || device === null) {
    return undefined
  }

  if (typeof device === 'string') {
    return device
  }
  if ('deviceId' in device) {
    // MediaTrackConstraints

    if (typeof device.deviceId === 'string') {
      return device.deviceId
    } else if (device.deviceId && 'exact' in device.deviceId) {
      return (device.deviceId as ConstrainDOMStringParameters).exact as string
    }

    console.warn(
      `Failed to get deviceId from MediaTrackConstraints: ${JSON.stringify(
        device
      )}`
    )
    return undefined
  } else if ('id' in device) {
    // MediaStream

    return device.id
  } else {
    console.warn(
      `Failed to get deviceId from Device: ${JSON.stringify(device)}`
    )
    return undefined
  }
}

/**
 * 指定したオーディオ入力デバイスから大元のデバイスを取得する
 * @param audioInputDevice オーディオ入力デバイス
 * @returns オーディオデバイス
 */
const getAudioDeviceFromAudioInputDevice = async (
  audioInputDevice: AudioInputDevice | undefined
): Promise<Device | undefined | null> => {
  if (isAudioTransformDevice(audioInputDevice)) {
    return getAudioDeviceFromAudioInputDevice(
      await audioInputDevice.intrinsicDevice()
    )
  }
  return audioInputDevice
}

export const useCrewMicrophoneSelectBox = () => {
  const { devices, selectedDevice: selectedAudioInputDevice } = useAudioInputs()
  const {
    currentAudioDevice,
    setCurrentAudioDevice,
    setStartDeviceAndAddVoiceFocusByDevice,
  } = useMicrophoneBindVoiceFocus()

  // 現在選択中のオーディオ入力デバイスからオーディオバイスを取得する
  // 非同期処理になるためuseEffectを使う
  useEffect(() => {
    const updateDevice = async () => {
      const inputDevice = await getAudioDeviceFromAudioInputDevice(
        selectedAudioInputDevice
      )

      setCurrentAudioDevice(inputDevice)
    }

    updateDevice()
  }, [selectedAudioInputDevice, setCurrentAudioDevice])

  // デバイスセレクトボックス選択時
  const updateAudioInputDeviceAsync = useCallback(
    async (
      newAudioDeviceId: string,
      currentAudioDevice: Device | undefined | null
    ) => {
      let newDevice: AudioInputDevice = newAudioDeviceId
      if (getDeviceId(currentAudioDevice) === newAudioDeviceId) {
        // 新たに選択されたデバイスは既に使用中のデバイス、何もしない
        return
      }

      setStartDeviceAndAddVoiceFocusByDevice(newDevice)
    },
    [setStartDeviceAndAddVoiceFocusByDevice]
  )

  const stateToValue = useCallback(
    (state: Device | undefined | null) => getDeviceId(state),
    []
  )

  // 選択可能なオーディオ入力デバイスのリスト
  const deviceList = useMemo(
    () =>
      devices.map((device) => ({
        id: device.deviceId,
        name: device.label,
      })),
    [devices]
  )

  return {
    currentAudioDevice,
    updateAudioInputDeviceAsync,
    stateToValue,
    deviceList,
  }
}
