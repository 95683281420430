import { CrewRadioGroup } from 'components/devextreme/crewRadioGroup'
import { memo } from 'react'
import { OrganizationRole } from 'enums/app'
import { AuthorityProjectSettingPanel } from './components/authorityProjectSettingPanel/authorityProjectSettingPanel'
import { AuthoritySystemSettingPanel } from './components/authoritySystemSettingPanel/authoritySystemSettingPanel'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { AuthoritySettingRoleEntryDialog } from './components/authoritySettingRoleEntryDialog/authoritySettingRoleSentryDialog'
import { useGetLookupRolesQuery } from '@crew/apis/lookup/lookupApis'
import { RoleType } from '@crew/enums/domain'
import { RoleRef } from '@crew/models/refs'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { useOrganizationRoleDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'states/hooks'
import { Role } from './useAuthoritySettingPanel'

export const AuthoritySettingPanel = memo(() => {
  const { t } = useTranslation()
  const [selectedRole, setSelectedRole] = useState<string>(
    OrganizationRole.System.key
  )
  const roleEventMessage = useAppSelector((state) => state.app.roleEventMessage)
  const [isRoleDialogOpen, openRoleEntryDialog, closeRoleEntryDialog] =
    useModal()

  const [newRole, setNewRole] = useState<Role | null>(null)
  const [roles, setRoles] = useState<RoleRef[]>([])

  const { data: getRoles, refetch: getRolesRefetch } = useGetLookupRolesQuery({
    roleType: RoleType.Project,
  })

  // refretch roles
  useEffect(() => {
    getRolesRefetch()
  }, [getRolesRefetch, roleEventMessage])

  useEffect(() => {
    if (getRoles?.roles) {
      setRoles(getRoles.roles)
    }
  }, [getRoles?.roles])

  //set data source for organization role
  const organizationRoleDataSource = useOrganizationRoleDataSource()

  // Change between project and organization permission settings
  const handleOrganizationRoleChanged = useCallback((value: string) => {
    setSelectedRole(value)
  }, [])

  // event handle for submit role entry dialog
  const handleSubmitRoleEntryDialog = useCallback(
    (role: Role) => {
      const userDefinedRoles: RoleRef = {
        id: role.name, // use name as id for user defined role
        name: role.name,
        roleCode: null,
        isUserDefined: true,
      }
      setRoles((currentRoles) => [...currentRoles, userDefinedRoles])

      setNewRole(role)

      closeRoleEntryDialog()
    },
    [closeRoleEntryDialog]
  )

  // event handle for clear new role
  const handleClearNewRole = useCallback(() => {
    setRoles((currentRoles) =>
      currentRoles.filter((currentRole) => currentRole.name !== newRole?.name)
    )
    setNewRole(null)
  }, [newRole?.name])

  // event handle for delete temp role
  const handleDeleteTempRole = useCallback((roleName: string) => {
    setRoles((currentRoles) =>
      currentRoles.filter((currentRole) => currentRole.name !== roleName)
    )
  }, [])

  // Render authority setting panel by selected system or project role
  const renderAuthoritySettingPanel = useCallback(() => {
    switch (selectedRole) {
      case OrganizationRole.System.key:
        return <AuthoritySystemSettingPanel />
      case OrganizationRole.Project.key:
        return (
          <AuthorityProjectSettingPanel
            newRole={newRole}
            onClearNewRole={handleClearNewRole}
            onDeleteTempRole={handleDeleteTempRole}
          />
        )
      default:
        return null
    }
  }, [handleClearNewRole, handleDeleteTempRole, newRole, selectedRole])

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-row items-center justify-between py-2">
        <CrewRadioGroup
          layout="horizontal"
          dataSource={organizationRoleDataSource}
          valueExpr="id"
          displayExpr="name"
          value={selectedRole}
          onValueChanged={({ value }) => handleOrganizationRoleChanged(value)}
        />
        {/* プロジェクト権限の場合：「役割を追加」ボタンを表示 */}
        {selectedRole === OrganizationRole.Project.key && (
          <div className="flex flex-1">
            <CrewButton
              type="primary"
              className="ml-auto"
              text={t('label.addRole')}
              onClick={openRoleEntryDialog}
            />
          </div>
        )}
      </div>

      {/* authority setting */}
      <div>{renderAuthoritySettingPanel()}</div>

      {/* Role dialog */}
      <AuthoritySettingRoleEntryDialog
        isOpen={isRoleDialogOpen}
        onClose={closeRoleEntryDialog}
        onSubmit={handleSubmitRoleEntryDialog}
        roles={roles}
      />
    </div>
  )
})
