import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { memo, useCallback, useState } from 'react'
import { ToolbarButton } from '../../components/toolbarButton'
import FormatListBulleted from '~icons/material-symbols/format-list-bulleted'
import FormatListNumbered from '~icons/material-symbols/format-list-numbered'

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  number: 'Numbered List',
  check: 'Check List',
  paragraph: 'Normal',
}

export const ListTheme = {
  listitem: 'CrewEditorTheme__listItem',
  listitemChecked: 'CrewEditorTheme__listItemChecked',
  listitemUnchecked: 'CrewEditorTheme__listItemUnchecked',
  nested: {
    listitem: 'CrewEditorTheme__nestedListItem',
  },
  olDepth: [
    'CrewEditorTheme__ol1',
    'CrewEditorTheme__ol2',
    'CrewEditorTheme__ol3',
    'CrewEditorTheme__ol4',
    'CrewEditorTheme__ol5',
  ],
  ul: 'CrewEditorTheme__ul',
}

export const ListPlugin = memo(() => {
  const [editor] = useLexicalComposerContext()

  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph')

  const formatList = useCallback(
    (listType: keyof typeof blockTypeToBlockName) => {
      if (listType === 'number' && blockType !== 'number') {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
        setBlockType('number')
      } else if (listType === 'bullet' && blockType !== 'bullet') {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        setBlockType('bullet')
      } else if (listType === 'check' && blockType !== 'check') {
        editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined)
        setBlockType('check')
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        setBlockType('paragraph')
      }
    },
    [blockType, editor]
  )

  return (
    <>
      <div className="h-8">
        <ToolbarButton
          onHandleButtonClick={() => formatList('bullet')}
          icon={<FormatListBulleted width={24} height={24} />}
          isApplied={blockType === 'bullet'}
        />
      </div>
      <div className="h-8">
        <ToolbarButton
          onHandleButtonClick={() => formatList('number')}
          icon={<FormatListNumbered width={24} height={24} />}
          isApplied={blockType === 'number'}
        />
      </div>
      {/* TODO: 後ほどスプリント9.0内でツールバーの構造を変更予定。CREW-9501で不要なツールをコメントアウトにより除去する（表示時の参考にするため）
      <div className="h-6">
        <ToolbarButton
          onHandleButtonClick={() => formatList('check')}
          icon={mdiFormatListCheckbox}
          isApplied={blockType === 'check'}
        />
      </div>
      */}
    </>
  )
})
