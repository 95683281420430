var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { debounce } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetChatRoomReadQuery, useUpdateChatRoomReadMutation, } from '@crew/apis/chatRead/chatReadApis';
import { useSelectLastReadMessageIdInChatRoom, useSelectLastReadMessageIdsInChatRoomClan, useUnreadService, } from '@crew/states';
import { compareUlid } from '@crew/utils/ulid';
import { UPDATE_LAST_READ_MESSAGE_DEBOUNCE_MSEC } from '@crew/configs/constants';
// 型付きのuseSelector
const useAppSelector = useSelector;
/**
 * ChatRoomの既読メッセージIDをバックエンドから取得してreduxに格納し、また既読IDの更新が必要かを判定して必要な場合は更新処理を行うhook
 * @param chatRoomId 対象ChatRoomのID
 * @returns
 */
export const useChatRoomLastReadMessage = (chatRoomId) => {
    //////////////////////////////////////// 取得系
    var _a, _b, _c;
    // バックエンドから取得した既読情報
    const getChatRoomReadQueryResult = useGetChatRoomReadQuery({ chatRoomId });
    const remoteLastReadMessageId = (_c = (_b = (_a = getChatRoomReadQueryResult.data) === null || _a === void 0 ? void 0 : _a.chatRoomRead) === null || _b === void 0 ? void 0 : _b.lastMessageId) !== null && _c !== void 0 ? _c : undefined;
    const isSuccessRemoteLastReadMessageIdFetch = getChatRoomReadQueryResult.isSuccess;
    // redux上の既読情報
    const selectLastReadMessageId = useSelectLastReadMessageIdInChatRoom();
    const lastReadMessageIdOnCurrentChatRoom = useAppSelector((state) => selectLastReadMessageId(state, chatRoomId));
    // バックエンドの初期化が必要かどうか
    const needsInitializeRemote = isSuccessRemoteLastReadMessageIdFetch && // バックエンドからの取得が成功していて
        remoteLastReadMessageId === undefined && // バックエンドからの取得結果がない場合は初期化が必要
        lastReadMessageIdOnCurrentChatRoom === undefined; // ローカルに既読情報がある場合は既に初期化済みで再fetchしてないだけであるため初期化不要
    const unreadService = useUnreadService();
    // 必要なら、バックエンドからの取得結果をreduxに反映する
    if (isSuccessRemoteLastReadMessageIdFetch && // バックエンドからの取得が成功している
        remoteLastReadMessageId && // バックエンドからの取得結果がある
        (lastReadMessageIdOnCurrentChatRoom === undefined || // ローカルに既読情報がない、または
            compareUlid(remoteLastReadMessageId, '>', lastReadMessageIdOnCurrentChatRoom)) // ローカルよりもバックエンドの方が新しい
    ) {
        unreadService.updateChatRoomLastReadMessageId({
            chatRoomId,
            messageId: remoteLastReadMessageId,
        });
    }
    //////////////////////////////////////// 更新系
    // 表示した事のあるメッセージの中で一番IDが大きいもの。既読更新時に最終既読IDとして使う。
    // 同一レンダリング処理中に複数の関数から利用されるパターンがあるため、statusでなくrefを使う
    const newestViewedMessageIdRef = useRef();
    /**
     * 画面に表示した事のあるアイテムの中で、一番大きいIDを記録する。
     * 既にもっと大きいIDが登録されている場合は更新不要なので何もしない。
     * 既読更新時、このIDを最終既読メッセージとして処理を行う。
     * @param id 対象ID
     */
    const updateNewestViewedMessageId = useCallback((id) => {
        if (newestViewedMessageIdRef.current === undefined ||
            compareUlid(id, '>', newestViewedMessageIdRef.current)) {
            newestViewedMessageIdRef.current = id;
        }
    }, []);
    // 今現在画面に表示しているアイテムのリスト。現在の表示位置が未読ラインを越えているかの判定に使用する。
    // このリスト中で一番小さいIDと未読ライン表示中のIDを比較する
    const [displayingItems, setDisplayingItems] = useState([]);
    // stateの初期化
    const reset = useCallback(() => {
        newestViewedMessageIdRef.current = undefined;
        setDisplayingItems([]);
    }, []);
    // バックエンドの既読情報更新mutationを取得する
    const [updateRemoteLastReadMutation] = useUpdateChatRoomReadMutation();
    // 処理条件判定をせずに既読情報を更新する
    const forceUpdateLastReadMessageId = useCallback((newLastReadMessageId, isLayerOnly) => __awaiter(void 0, void 0, void 0, function* () {
        // API呼び出しより先にredux側を更新する。
        // スクロールが端にある場合は新規メッセージを即時既読扱いにするが、真っ先にReduxの更新をしておかないと、
        // 再レンダリング処理が先に走り未読ラインが表示されてしまうため。
        unreadService.updateChatRoomLastReadMessageId({
            chatRoomId,
            messageId: newLastReadMessageId,
        });
        yield updateRemoteLastReadMutation({
            chatRoomId,
            lastReadMessageId: newLastReadMessageId,
            isLayerOnly,
        }).unwrap();
    }), [chatRoomId, unreadService, updateRemoteLastReadMutation]);
    /**
     * debounceしつつ処理条件判定をせずに既読情報を更新する
     */
    const debouncedForceUpdateLastReadMessageId = useMemo(() => debounce(forceUpdateLastReadMessageId, UPDATE_LAST_READ_MESSAGE_DEBOUNCE_MSEC), [forceUpdateLastReadMessageId]);
    // 必要があったら既読情報の更新を行う。スクロール完了時や、初回ロード時の最初のレンダリング後に呼ぶ。
    // 処理に必要な情報が集まっていない場合は処理しない。
    // 指定したIDが古かった場合、古いIDで上書き更新される事はない。
    // 処理はdebounceされる
    const updateLastReadMessageId = useCallback(
    // この関数自体をdebounceしないのは、依存が変わってfuncが作り直されるとdebounce処理が別れて効かなくなってしまうため。
    // 実際に処理を行う部分のみdebounceする
    (unreadLineMessageId, isLayerOnly) => {
        if (!isSuccessRemoteLastReadMessageIdFetch || // backendから未読情報の取得が完了していない
            displayingItems.length === 0 || // 表示中のメッセージがない
            newestViewedMessageIdRef.current === undefined || //表示した事のあるメッセージの中で一番IDが大きいものが記録されていない
            unreadLineMessageId === undefined // 未読ラインが設置されていない
        ) {
            // 必要な情報が足りない
            return;
        }
        // 未読ラインを越えているかの判定に使用するため、表示中メッセージが持つtargetIdの一番古いもの(一番IDが小さいもの)を抽出する
        const oldestViewingMessageId = displayingItems
            .map((m) => m.messageId)
            .reduce((prev, current) => compareUlid(current, '<', prev) ? current : prev);
        // 一番古い表示中メッセージが未読ラインより古い場合は最終既読メッセージを更新する
        if (
        // 一番古い表示中メッセージが未読ラインの位置と等しい、もしくはそれより古い
        // 等しい場合も処理対象としないと、一番古いメッセージに未読ラインがあった場合に更新されなくなってしまう
        compareUlid(oldestViewingMessageId, '<=', unreadLineMessageId)) {
            console.info(`[Chat] updateLastReadMessageId: ${newestViewedMessageIdRef.current} prev: ${lastReadMessageIdOnCurrentChatRoom}`);
            debouncedForceUpdateLastReadMessageId(newestViewedMessageIdRef.current, isLayerOnly);
        }
    }, [
        displayingItems,
        isSuccessRemoteLastReadMessageIdFetch,
        lastReadMessageIdOnCurrentChatRoom,
        debouncedForceUpdateLastReadMessageId,
    ]);
    const selectLastReadMessageIdsInChatRoomClan = useSelectLastReadMessageIdsInChatRoomClan();
    const lastReadMessageIdsInChatRoomClan = useAppSelector((state) => selectLastReadMessageIdsInChatRoomClan(state, chatRoomId));
    return {
        lastReadMessageIdOnCurrentChatRoom,
        lastReadMessageIdsInChatRoomClan,
        needsInitializeRemote,
        forceUpdateLastReadMessageId,
        updateLastReadMessageId,
        isSuccessLastReadFetch: isSuccessRemoteLastReadMessageIdFetch,
        //
        setDisplayingItems,
        updateNewestViewedMessageId,
        reset,
    };
};
