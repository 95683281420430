import DOMPurify from 'dompurify'

import { tag as emojiTag } from '@crew/emoji-data'
import { dedupeFilter, isNotNull } from '@crew/utils'

const purify = DOMPurify()

// サニタイズルール：HtmlEditorの出力するタグのみ有効とする
const sanitizeHtmlOptionNormal = {
  ALLOWED_TAGS: [
    'p',
    'span',
    'br',
    'em',
    's',
    'u',
    'ul',
    'ol',
    'li',
    'strong',
    'table',
    'tbody',
    'tr',
    'td',
    'th',
    // linkCard表示のために追加
    'div',
    'img',
    'a',
    // ブロックスタイル系
    'code',
    'blockquote',
    'h1',
    'h2',
    'h3',
    'pre',
    // ---ここまで
  ],
  ALLOW_DATA_ATTR: false,
  ALLOWED_ATTR: [
    'class',
    'style',
    'href',
    'target',
    'rel',
    `data-${emojiTag.shortNameCustomDataAttributeName}`,
    'data-mention-id',
    // linkCard表示のために追加
    'data-linkcard',
    'data-linkcard-url',

    // コードブロック
    'spellcheck',
    'data-highlight-language',
    'data-gutter',
    // ---ここまで
  ],
} satisfies DOMPurify.Config

// サニタイズルール：絵文字のみ有効とする。チャットメッセージのスレッドヘッダ表示に使う
const sanitizeHtmlOptionAllowOnlyEmoji = {
  ALLOWED_TAGS: ['span'],
  ALLOW_DATA_ATTR: false,
  ALLOWED_ATTR: ['class', `data-${emojiTag.shortNameCustomDataAttributeName}`],
} satisfies DOMPurify.Config

// サニタイズルール：全て無効とする
// TODO: 絵文字はUnicode文字として残したい
// https://break-tmc.atlassian.net/browse/CREW-4429
const sanitizeHtmlOptionAllowNothing = {
  ALLOWED_TAGS: [],
} satisfies DOMPurify.Config

// TODO: sanitizerを有効化する。create-react-appのバグでstyleが全て剥げる不具合があるため、解消を待つ
// https://break-tmc.atlassian.net/browse/CREW-1505
/**
 * HtmlEditorのタグのみ残し、他のタグを全て除去する
 * @param text
 * @returns
 */
export const sanitizeHtml = (text: string): string =>
  purify.sanitize(text, sanitizeHtmlOptionNormal)

/**
 * 絵文字のみ残し、他のタグを全て除去する
 * @param text
 * @returns
 */
export const sanitizeHtmlAllowOnlyEmoji = (text: string): string =>
  purify.sanitize(text, sanitizeHtmlOptionAllowOnlyEmoji)

/**
 * タグを全て除去する
 * @param text
 * @returns
 */
export const sanitizeHtmlAllowNothing = (text: string): string =>
  purify.sanitize(text, sanitizeHtmlOptionAllowNothing)

/**
 * htmlコードの（DOMを構成した）文字列から指定した属性の値を抽出する
 * @param string htmlコード文字列
 * @param string スタイルclass　例) 「span.example」 または 「.example」
 * @param string 抽出したい属性
 * @returns
 */
export const getAttributesFromHtml = (
  htmlStr: string,
  className: string,
  attr: string
): string[] => {
  // htmlコード文字列をDOM化
  const parser = new DOMParser()
  const dom = parser.parseFromString(htmlStr, 'text/html')

  // 指定したクラスが適用されたエレメントから指定した属性の値を取得する
  const elements = dom.querySelectorAll(className)
  const result = Array.from(elements)
    .map((elm) => elm.getAttribute(attr)) //必要な属性の値を取得
    .filter(isNotNull) // 必要な属性がなかった(値がnull)ものを除外
    .filter(dedupeFilter) // もし属性値が被っていたら重複除去する(残す意味がないので)

  return result
}

/**
 * changeDocumentTitle
 * @param title title name display in tab browser
 */
export const changeDocumentTitle = (title: string) => {
  document.title = title
}
