import { useCallback } from 'react'
import {
  useSubscribeMutation,
  useUnsubscribeMutation,
} from '@crew/apis/follow/followApis'
import { EntityType } from '@crew/enums/domain'

export const useCrewSubscription = () => {
  const [subscribeMutation] = useSubscribeMutation()
  const [unsubscribeMutation] = useUnsubscribeMutation()

  // subscribe
  const subscribe = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      await subscribeMutation({
        entityType,
        entityRecordId,
      }).unwrap()
    },
    [subscribeMutation]
  )

  // unsubscribe
  const unsubscribe = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      await unsubscribeMutation({
        entityType,
        entityRecordId,
      }).unwrap()
    },
    [unsubscribeMutation]
  )

  return { subscribe, unsubscribe }
}
