import { apis } from '../apiBase/apiBase';
const subscriptionApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        subscribe: builder.mutation({
            query: (params) => ({
                url: 'follows/subscribe',
                method: 'POST',
                body: params,
            }),
        }),
        unsubscribe: builder.mutation({
            query: (params) => ({
                url: 'follows/unsubscribe',
                method: 'POST',
                body: params,
            }),
        }),
        getFollow: builder.query({
            query: (param) => ({
                url: `follows/${param.entityType}/${param.entityRecordId}`,
                method: 'GET',
            }),
        }),
        insertFollow: builder.mutation({
            query: (params) => ({
                url: `follows/follow`,
                method: `POST`,
                body: params,
            }),
        }),
        deleteFollow: builder.mutation({
            query: (params) => ({
                url: `follows/unfollow`,
                method: `POST`,
                body: params,
            }),
        }),
        getFollowParent: builder.query({
            query: (params) => ({
                url: `follows/parent`,
                params: params,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useSubscribeMutation, useUnsubscribeMutation, useGetFollowQuery, useLazyGetFollowQuery, useInsertFollowMutation, useDeleteFollowMutation, useGetFollowParentQuery, useLazyGetFollowParentQuery, } = subscriptionApis;
