import { FC, memo } from 'react'
import { UploadFile } from 'models/domain/uploadFile'
import { CrewUploadedFileItem } from './crewUploadedFileThumbnail'
import classNames from 'classnames'

type CrewUploadedFileListProps = {
  uploadedFileList: UploadFile[]
  onDeleteFile?: (file: UploadFile) => void // 添付ファイル削除時のコールバック
  showThumbnail?: boolean
  fillIcon?: boolean
  direction: 'horizontal' | 'vertical'
}

export const CrewUploadedFileList: FC<CrewUploadedFileListProps> = memo(
  (props) => (
    <div
      className={classNames(
        'flex gap-x-2.5 flex-wrap gap-1 p-1',
        props.direction === 'vertical' && 'flex-col'
      )}
    >
      {Object.values(props.uploadedFileList).map((item) => (
        <CrewUploadedFileItem
          key={item.keyName + item.name + item.progress}
          item={item}
          showThumbnail={props.showThumbnail ?? false}
          onDeleteFile={props.onDeleteFile}
          fillIcon={props.fillIcon}
        />
      ))}
    </div>
  )
)
