import { FC, memo } from 'react'

type CrewProgressIndicatorProps = {
  progress: number
}

export const CrewProgressIndicator: FC<CrewProgressIndicatorProps> = memo(
  (props) => {
    return (
      <div className="w-full h-2 rounded crew-bg-gray-3">
        <div
          className="crew-bg-gray-5 h-full rounded"
          style={{
            width: `${props.progress}%`,
          }}
        ></div>
      </div>
    )
  }
)
