import { FC, memo, useCallback } from 'react'
import { SignupType } from '@crew/enums/domain'
import { SignupProjectMember } from './components/signupProjectMember/signupProjectMember'
import { SignupTenantMember } from './components/signupTenantMember/signupTenantMember'

type AccountSignupProps = {
  signupType: SignupType
}

export const AccountSignup: FC<AccountSignupProps> = memo((props) => {
  // Render the signup form based on the signup type
  const renderSignup = useCallback(() => {
    switch (props.signupType) {
      case SignupType.ProjectMemberPendings:
        return <SignupProjectMember />
      case SignupType.TenantMemberPendings:
        return <SignupTenantMember />
      default:
        return null
    }
  }, [props.signupType])

  // account signup form
  return renderSignup()
})
