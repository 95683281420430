import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { CrewFollowerUs } from 'components/elements/crewFollowerUs/crewFollowerUs'
import dayjs from '@crew/modules/dayjs'
import { DateFormat } from '@crew/enums/system'
import { FileEntryDialog } from 'features/file/components/fileEntryDialog/fileEntryDialog'
import { memo } from 'react'
import { formatByteSize } from '@crew/utils/number'
import BaselineSaveAlt from '~icons/ic/baseline-save-alt'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'
import { CrewFileIcon } from 'components/elements/crewFileIcon/crewFileIcon'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { useProjectPermissions } from '@crew/hooks'
import { useGetFileQuery } from '@crew/apis/file/fileApis'
import { useCallback, useEffect } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useParams } from 'react-router-dom'
import { useModal } from 'components/layouts/modal/useModal'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { GetFileRequest } from '@crew/apis/dist/file/models/getFile/request'
import { EntityType } from '@crew/enums/dist/domain'
import { useAppSelector } from 'states/hooks'
import { useFileSignedUrl } from 'hooks/useFileSignedUrl'
import { downloadFileFromUrl } from 'utils'

export const FileDetailPanel = memo(() => {
  const { fileId } = useParams()

  const { t } = useTranslation()

  const fileEventMessage = useAppSelector((state) => state.app.fileEventMessage)

  // ファイル詳細の取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getFileParam: GetFileRequest | undefined = fileId
    ? {
        fileId,
      }
    : undefined
  const { data: getFileResult, refetch: getFileRefetch } = useGetFileQuery(
    getFileParam ?? skipToken
  )

  useEffect(() => {
    fileId && getFileRefetch()
  }, [fileEventMessage, fileId, getFileRefetch])

  const [isOpenFileEntryDialog, openFileEntryDialog, closeFileEntryDialog] =
    useModal()

  const file = getFileResult?.file

  const { navigate } = useCrewNavigate()

  const { getFileSignedUrl } = useFileSignedUrl()

  // TODO: ファイルリンクのコピーが機能していないため一時的に非表示。CREW-13731で恒久対応予定
  //      https://break-tmc.atlassian.net/browse/CREW-13731
  // fileIdをクリップボードにコピーする。コピーされる文字列は「[file:<files.id>]」。
  // const handleCopyButtonClick = useCallback(() => {
  //   navigator.clipboard.writeText(`[${EntityType.File}:${fileId}]`)
  //   toast.success(t('message.file.copySuccess'))
  // }, [fileId, t, toast])

  //Click button download request to link download file
  const handleDownloadButtonClick = useCallback(async () => {
    if (!file) return
    const url = await getFileSignedUrl(file.id)

    // download file from signed url
    downloadFileFromUrl(url, file.name)
  }, [file, getFileSignedUrl])

  // ファイル情報が更新されたら詳細を更新
  const handleUpdatedFile = useCallback(() => {
    closeFileEntryDialog()
  }, [closeFileEntryDialog])

  // ファイル情報が削除されたら詳細画面に来る前の画面に戻る、またはファイル一覧に遷移
  const handleDeletedFile = useCallback(() => {
    closeFileEntryDialog()
    //back to task list screen if user directly access task detail screen via url
    navigate('/files')
  }, [closeFileEntryDialog, navigate])

  const { hasPrjFileDownloadPermission, hasPrjFileEditPermission } =
    useProjectPermissions(EntityType.File, fileId)

  return (
    <>
      {file && (
        <div className="flex flex-col gap-2.5 p-2.5">
          <div className="w-full flex gap-2.5 justify-between items-center">
            {/* ページタイトル */}
            <div className="flex gap-2.5 items-center">
              {/* File icon */}
              <div className="shrink-0">
                <CrewFileIcon fileName={file.fileName} width={48} height={48} />
              </div>
              <span className="flex flex-col">
                <span
                  className="font-bold text-2xl line-clamp-1 break-all"
                  title={file.name}
                >
                  {file.name}
                </span>
                {/* 関連先リンク */}
                <CrewRelatedItemLink
                  entityType={file.entityType}
                  id={file.entityRecordId}
                  className="line-clamp-1 break-all"
                />
              </span>

              {/* フォローバッジ */}
              <CrewFollowerUs
                entityType={EntityType.File}
                entityRecordId={file.id}
              />

              {/* タグ */}
              {file.tags && file.tags.length > 0 && (
                <div className="flex flex-wrap gap-2.5 text-sm">
                  {file.tags.map((tag) => (
                    <CrewBadge
                      key={tag.id}
                      displayColor="bg-crew-gray-1-light dark:bg-crew-gray-1-dark dark:text-slate-900"
                    >
                      {tag.name}
                    </CrewBadge>
                  ))}
                </div>
              )}
            </div>
            <div className="flex gap-x-1 ml-auto">
              <div className="flex gap-x-2.5">
                {/* ダウンロードボタン */}
                {hasPrjFileDownloadPermission && (
                  <div
                    className="flex flex-row border rounded-md items-center p-2 crew-hover-gray-2 cursor-pointer"
                    onClick={handleDownloadButtonClick}
                  >
                    <BaselineSaveAlt width={24} height={24} />
                  </div>
                )}

                {/* TODO: ファイルリンクのコピーが機能していないため一時的に非表示。CREW-13731で恒久対応予定
                        https://break-tmc.atlassian.net/browse/CREW-13731 */}
                {/* リンクコピーボタン */}
                {/* <div
                  className="flex flex-row border rounded-md items-center p-2 crew-hover-gray-2 cursor-pointer"
                  onClick={handleCopyButtonClick}
                >
                  <BaselineContentCopy width={24} height={24} />
                </div> */}

                {/* 新規ファイルボタン */}
                {hasPrjFileEditPermission && (
                  <CrewButton
                    text={t('action.edit')}
                    onClick={openFileEntryDialog}
                    stylingMode="outlined"
                  />
                )}
              </div>
            </div>
          </div>

          {/* ファイル項目 */}
          <div className="flex gap-x-8">
            {/* 登録者 */}
            <div className="flex flex-col gap-y-2.5 flex-1 min-w-0 max-w-fit">
              <span className="text-sm crew-text-gray-4">
                {t('label.createdBy')}
              </span>
              {file.createdAttachmentBy && (
                <CrewUserAvatar
                  userId={file.createdAttachmentBy.id}
                  displayName={file.createdAttachmentBy.displayName}
                  size={CrewAvatarSize.xs}
                  showLabel={true}
                  cacheValue={
                    file.createdAttachmentBy.id +
                    file.createdAttachmentBy.version
                  }
                />
              )}
            </div>

            {/* 登録日時 */}
            <div className="flex flex-col gap-y-2.5">
              <span className="text-sm crew-text-gray-4">
                {t('label.registrationDatetime')}
              </span>
              <span>
                {file.createdAttachmentAt &&
                  dayjs(file.createdAttachmentAt).format(
                    DateFormat.YYYYMMDDHHmmss
                  )}
              </span>
            </div>

            {/* 更新者 */}
            <div className="flex flex-col gap-y-2.5 flex-1 min-w-0 max-w-fit">
              <span className="text-sm crew-text-gray-4">
                {t('label.updatedBy')}
              </span>
              {file.lastUpdatedAttachmentBy && (
                <CrewUserAvatar
                  userId={file.lastUpdatedAttachmentBy.id}
                  displayName={file.lastUpdatedAttachmentBy.displayName}
                  size={CrewAvatarSize.xs}
                  showLabel={true}
                  cacheValue={
                    file.lastUpdatedAttachmentBy.id +
                    file.lastUpdatedAttachmentBy.version
                  }
                />
              )}
            </div>

            {/* 更新日時 */}
            <div className="flex flex-col gap-y-2.5">
              <span className="text-sm crew-text-gray-4">
                {t('label.updateDatetime')}
              </span>
              <span>
                {file.lastUpdatedAttachmentAt &&
                  dayjs(file.lastUpdatedAttachmentAt).format(
                    DateFormat.YYYYMMDDHHmmss
                  )}
              </span>
            </div>

            {/* ファイルサイズ */}
            <div className="flex flex-col gap-y-2.5">
              <span className="text-sm crew-text-gray-4">
                {t('label.size')}
              </span>
              <span>{formatByteSize(file.size)}</span>
            </div>
          </div>

          {/* 説明 */}
          <div className="lg:max-w-3xl">
            {file.description && (
              <div className="whitespace-pre-wrap break-all">
                {file.description}
              </div>
            )}
          </div>

          {/* dialog edit file */}
          <FileEntryDialog
            isOpen={isOpenFileEntryDialog}
            title={t('label.editFile')}
            isEditMode={true}
            fileId={file.id}
            onClose={closeFileEntryDialog}
            onUpdated={handleUpdatedFile}
            onDeleted={handleDeletedFile}
          />
        </div>
      )}
    </>
  )
})
