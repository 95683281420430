import { useCallback, useMemo, useState } from 'react'

import { useLogger } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/LoggerProvider'

/**
 * Chime SDKの内部stateなど非同期的に更新されるstateと、DevExtremeのUIコンポーネントのvalueを接続するためのカスタムフック。stateとvalueの型が一致しない場合に使用する。
 * 1. stateの更新があった場合はUIに即座に反映する
 * 1. 値更新イベントハンドラが呼ばれた場合は、UIに即座に反映し、非同期関数を呼び出す
 * 1. 非同期関数の内部で非同期にstateの更新を行う
 * 1. stateとUIを非同期に更新するため値が一致しないタイミングがあるので、その期間はisChangingAsyncStateがtrueになる
 * @param currentAsyncState 現在の非同期state値
 * @param asyncStateUpdater 非同期stateを更新する非同期関数
 * @param stateToValue 非同期stateからvalueを生成する関数
 * @returns UIコンポーネントに接続するためのvalueとevent callback
 */
export const useAsyncStateToValueConnector = <
  TState extends any,
  TValue extends any
>(
  currentAsyncState: TState | undefined,
  asyncStateUpdater: (
    newValue: TValue,
    currentAsyncState: TState | undefined
  ) => Promise<void>,
  stateToValue: (state: TState | undefined) => TValue | undefined
) => {
  const logger = useLogger()

  const currentAsyncValue = useMemo(
    () => stateToValue(currentAsyncState),
    [currentAsyncState, stateToValue]
  )

  // 非同期なstateの更新を検知するため、以前の値を保存するstate
  const [prevAsyncState, setPrevAsyncState] = useState<TState | undefined>(
    currentAsyncState
  )

  // UIの表示値を保持するstate
  const [displayValue, setDisplayValue] = useState<TValue | undefined>(
    currentAsyncValue
  )

  if (currentAsyncState !== prevAsyncState) {
    setPrevAsyncState(currentAsyncState)
    // stateが更新されたらUIに即時反映する
    setDisplayValue(currentAsyncValue)
  }

  // 内部stateの切り替え処理中か否か
  const [isChangingAsyncState, setChangingAsyncState] = useState(false)

  const handleValueChanged = useCallback(
    async ({ value }: { value?: TValue }): Promise<void> => {
      // 更新処理中に多重にイベントが発生した場合は無視する
      if (isChangingAsyncState) {
        return
      }

      if (value === undefined) {
        return
      }

      // 変更イベントを受け取ったらUIに即時反映する
      setDisplayValue(value)

      // 値の更新がない場合は更新処理を行わない
      if (value === stateToValue(currentAsyncState)) {
        return
      }

      setChangingAsyncState(true)

      try {
        await asyncStateUpdater(value, currentAsyncState)
      } catch (error) {
        logger.error('Failed to change chime state')
      } finally {
        setChangingAsyncState(false)
      }
    },
    [
      isChangingAsyncState,
      stateToValue,
      currentAsyncState,
      asyncStateUpdater,
      logger,
    ]
  )

  return { value: displayValue, handleValueChanged, isChangingAsyncState }
}
