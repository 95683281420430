import { useRemoteVideoTileState } from 'modules/amazon-chime-sdk-component-library-devextreme'
import useAttendeeAudioStatus from 'modules/amazon-chime-sdk-component-library-devextreme/hooks/sdk/useAttendeeAudioStatus'
import { memo, FC, RefObject } from 'react'
import { EventDetailWebMeetingAttendeeItem } from '../eventDetailWebMeetingAttendeeItem/eventDetailWebMeetingAttendee'

type AttendeeTileProps = {
  attendee: AttendeeUser
  isFullScreen: boolean
  fullScreenTargetRef: RefObject<HTMLDivElement>
  showAttendeeActionMenu: boolean
}
type AttendeeUser = {
  chimeAttendeeId: string
  displayName: string
  externalUserId?: string
  attendeeUserId: string
  name?: string
  version: number
  isHost: boolean
}
export const EventDetailWebMeetingRemoteAttendee: FC<AttendeeTileProps> = memo(
  (props) => {
    const { muted } = useAttendeeAudioStatus(props.attendee.chimeAttendeeId)
    const { attendeeIdToTileId } = useRemoteVideoTileState()
    const tileId = attendeeIdToTileId[props.attendee.chimeAttendeeId]

    return (
      <EventDetailWebMeetingAttendeeItem
        isHost={props.attendee.isHost}
        isMicrophoneEnable={!muted}
        isVideoEnable={!!tileId}
        displayName={props.attendee.displayName}
        attendeeUserId={props.attendee.attendeeUserId}
        version={props.attendee.version}
        isFullScreen={props.isFullScreen}
        fullScreenTargetRef={props.fullScreenTargetRef}
        showAttendeeActionMenu={props.showAttendeeActionMenu}
        chimeAttendeeId={props.attendee.chimeAttendeeId}
      />
    )
  }
)
