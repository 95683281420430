import { FC, memo } from 'react'
import { CrewNavTabs } from 'components/elements/crewNavTabs/crewNavTabs'
import { useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { FileDetailListTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { CrewNavTabProps } from 'components/elements/crewNavTabs/components/crewNavTab'

export const FileDetailTab: FC = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  // タブアイテム
  const displayTabItems: CrewNavTabProps[] = useMemo(() => {
    const items = Object.values(FileDetailListTabs).map((item) => {
      return {
        onClick: () => {
          // タブに対応するURLへ遷移（相対パス指定）
          navigate(item.value)
        },
        to: item.value,
        text: t(item.text),
        icon: item.icon,
      }
    })

    return items
  }, [navigate, t])

  return <CrewNavTabs tabItems={displayTabItems} />
})
