import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@crew/modules/i18n'
import { useAppDispatch } from 'states/hooks'
import dayjs from '@crew/modules/dayjs'
import {
  useInsertTaskWorkMutation,
  useUpdateTaskWorkMutation,
} from '@crew/apis/task/taskApis'
import { JsonDateFormat } from '@crew/enums/system'
import {
  notifyTaskWorkEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { TaskWork } from '@crew/models/dist/domain'
import { NotifyEventType } from 'enums/app'
import { ValidateRules } from '@crew/utils/form'
import {
  convertScheduledTimeToMinutes,
  isRegexFormatScheduledTime,
} from '@crew/utils'

export type FormValues = {
  description: string | null
  actualWorkTimes: string | null
  remainingWorkTimes: string | null
  workDate: Date
  startTime: Date | null
  endTime: Date | null
  actualProgress: number | null
}

// コメント（説明）の最大文字数
// TODO: 最大文字数をどうすべきかは要検討（当面はチャットの最大文字数に合わせる）
// https://break-tmc.atlassian.net/browse/CREW-1643
const MAX_DESCRIPTION_LENGTH: number = 4000

export const useTaskDetailWorkInputForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // Get functions for insert/update task work
  const [insertTaskWorkMutation] = useInsertTaskWorkMutation()
  const [updateTaskWorkMutation] = useUpdateTaskWorkMutation()

  // react-hook-formの各種データを取得
  const {
    control,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState,
    handleSubmit,
    setError,
  } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  // Insert task work process
  const insertTaskWork = useCallback(
    async (taskId: string, data: FormValues) => {
      // 実績時間と残時間を分に換算
      const actualWorkTimes = convertScheduledTimeToMinutes(
        data.actualWorkTimes
      )
      const remainingWorkTimes = convertScheduledTimeToMinutes(
        data.remainingWorkTimes
      )

      const workDate = dayjs(data.workDate).format(JsonDateFormat.YYYYMMDD)
      const startTime = dayjs(data.startTime).format(JsonDateFormat.HHmmss)
      const endTime = dayjs(data.endTime).format(JsonDateFormat.HHmmss)

      // Create params for insert task work
      const params = {
        taskId,
        workDate: workDate,
        startTime: data.startTime ? workDate + ' ' + startTime : undefined,
        endTime: data.endTime ? workDate + ' ' + endTime : undefined,
        description: data.description ?? undefined,
        actualWorkTimes: actualWorkTimes || 0,
        remainingWorkTimes: remainingWorkTimes || 0,
        actualProgress: data.actualProgress ?? undefined,
      }

      const result = await insertTaskWorkMutation({
        taskWork: params,
      }).unwrap()

      // タスク作業が登録された旨のEventMessageを通知
      const objectEventMessage: ObjectEventMessage<TaskWork> = {
        eventType: NotifyEventType.Inserted,
        id: result.taskWork.taskId,
        object: result.taskWork,
      }
      dispatch(notifyTaskWorkEvent(objectEventMessage))
    },
    [dispatch, insertTaskWorkMutation]
  )

  // Insert task work process
  const updateTaskWork = useCallback(
    async (
      taskId: string,
      taskWorkId: string,
      version: number,
      data: FormValues
    ) => {
      // 実績時間と残時間を分に換算
      const actualWorkTimes = convertScheduledTimeToMinutes(
        data.actualWorkTimes
      )
      const remainingWorkTimes = convertScheduledTimeToMinutes(
        data.remainingWorkTimes
      )

      const workDate = dayjs(data.workDate).format(JsonDateFormat.YYYYMMDD)
      const startTime = dayjs(data.startTime).format(JsonDateFormat.HHmmss)
      const endTime = dayjs(data.endTime).format(JsonDateFormat.HHmmss)

      // Create params for update task work
      const params = {
        id: taskWorkId as string,
        taskId: taskId as string,
        workDate: workDate,
        startTime: data.startTime ? workDate + ' ' + startTime : undefined,
        endTime: data.endTime ? workDate + ' ' + endTime : undefined,
        description: data.description ?? undefined,
        actualWorkTimes: actualWorkTimes ?? 0,
        remainingWorkTimes: remainingWorkTimes ?? 0,
        version: version as number,
      }

      const result = await updateTaskWorkMutation({
        taskWork: params,
      }).unwrap()

      // タスク作業が登録された旨のEventMessageを通知
      const objectEventMessage: ObjectEventMessage<TaskWork> = {
        eventType: NotifyEventType.Updated,
        id: result.taskWork.taskId,
        object: result.taskWork,
      }
      dispatch(notifyTaskWorkEvent(objectEventMessage))
    },
    [dispatch, updateTaskWorkMutation]
  )

  // 実績時間フォーマットのカスタムバリデータ
  const validateActualWorkTimes = useCallback(() => {
    const actualWorkTimes = getValues('actualWorkTimes')

    return isRegexFormatScheduledTime(actualWorkTimes)
  }, [getValues])

  // 残時間フォーマットのカスタムバリデータ
  const validateRemainingWorkTimes = useCallback(() => {
    const estimatedWorkTimes = getValues('remainingWorkTimes')

    return isRegexFormatScheduledTime(estimatedWorkTimes)
  }, [getValues])

  // check validate actual progress
  const validateActualProgress = useCallback(() => {
    const actualProgress = getValues('actualProgress')

    if (!actualProgress) return true

    // actual progress must be a integer
    if (!Number.isInteger(actualProgress)) return false

    // actual progress must be between 0 and 100
    if (actualProgress < 0 || actualProgress > 100) return false

    return true
  }, [getValues])

  //custom validate start time and end time
  const validateTimeRange = useCallback(() => {
    const startTime = getValues('startTime')
    const endTime = getValues('endTime')

    if (!startTime || !endTime || startTime <= endTime) {
      clearErrors('startTime')
      clearErrors('endTime')
      return true
    }

    return false
  }, [clearErrors, getValues])

  // Actual work times duration validation
  const validateActualWorkTimesDuration = useCallback(() => {
    // 「開始日時」と「終了日時」の差分より大きい「実績時間」は登録できないようにする
    if (getValues('startTime') && getValues('endTime')) {
      const inputValue = getValues('actualWorkTimes')

      const startTime = dayjs(getValues('startTime'))
      const endTime = dayjs(getValues('endTime'))

      const diffMinutes = endTime.diff(startTime, 'minute')
      const actualWorkTimes = convertScheduledTimeToMinutes(inputValue)

      if (actualWorkTimes && actualWorkTimes > diffMinutes) {
        return false
      }
    }

    return true
  }, [getValues])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(() => {
    return {
      // コメント（説明）
      description: {
        maxLength: {
          value: MAX_DESCRIPTION_LENGTH,
          message: t('message.general.maxLength', {
            name: t('label.comment'),
            value: MAX_DESCRIPTION_LENGTH,
          }),
        },
      },
      // 実績時間
      actualWorkTimes: {
        required: t('message.general.required'),
        validate: {
          always: () =>
            validateActualWorkTimes() || t('message.task.invalidFormatTime'),
          duration: () =>
            validateActualWorkTimesDuration() ||
            t('message.taskComment.invalidActualWorkTimesDuration'),
        },
      },
      // 残時間
      remainingWorkTimes: {
        required: t('message.general.required'),
        validate: {
          always: () =>
            validateRemainingWorkTimes() || t('message.task.invalidFormatTime'),
        },
      },
      // 作業日
      workDate: {
        required: t('message.general.required'),
      },
      actualProgress: {
        validate: {
          always: () =>
            validateActualProgress() ||
            t('message.general.invalidActualProgress'),
        },
      },
      startTime: {
        validate: {
          checkTime: () =>
            validateTimeRange() || t('message.taskComment.invalidTimeRange'),
        },
      },
      endTime: {
        validate: {
          checkTime: () =>
            validateTimeRange() || t('message.taskComment.invalidTimeRange'),
        },
      },
    }
  }, [
    t,
    validateActualProgress,
    validateActualWorkTimes,
    validateActualWorkTimesDuration,
    validateRemainingWorkTimes,
    validateTimeRange,
  ])

  return {
    control,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState,
    handleSubmit,

    validateRules,

    insertTaskWork,
    updateTaskWork,
    setError,
  }
}
