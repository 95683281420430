import { useTranslation } from '@crew/modules/i18n'
import { CrewDateBox } from 'components/devextreme/crewDateBox'
import { DatePickerDateFormat } from 'enums/system'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetEvmDataQuery } from '@crew/apis/project/projectApis'
import dayjs from '@crew/modules'
import { DateFormat, JsonDateFormat } from '@crew/enums/dist/system'
import { GetEvmDataRequest } from '@crew/apis/dist/project/models/getEvmData/request'
import { skipToken } from '@reduxjs/toolkit/query'
import classNames from 'classnames'
import {
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Label,
  Format,
  Tooltip,
  ValueAxis,
  Title,
  Font,
  Border,
  ConstantLine,
  TickInterval,
} from 'devextreme-react/chart'
import { PointInteractionInfo } from 'devextreme/viz/chart_components/base_chart'
import { isDate } from '@crew/utils'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { CrewChart } from 'components/devextreme/crewChart'

export const evmChartSeries = [
  { value: 'plannedValue', name: 'PV', color: '#598ebf', dashStyle: 'solid' },
  { value: 'earnedValue', name: 'EV', color: '#d16d2a', dashStyle: 'solid' },
  { value: 'actualCost', name: 'AC', color: '#919191', dashStyle: 'solid' },
  {
    value: 'estimateAtCompletion',
    name: 'EAC',
    color: '#919191',
    dashStyle: 'dash',
  },
] as const

type PointInfo = {
  originalArgument: Date
  originalValue: number
  seriesName: string
}

// Legend item marker infor
type MarkerInfor = {
  text: string
  marker: {
    fill: string
  }
}

const dateFormatter = (value: number | Date) => {
  return dayjs(value).format(DateFormat.YYYYMMDD)
}

export const ProjectDetailTaskListEvm: FC = memo(() => {
  const { t } = useTranslation()

  const { projectId } = useParams()

  const today = new Date()
  const [generalShowApiErrors] = useShowApiErrors()

  // 今日の日付を基準日のデフォルト値にする
  const [dataDate, setDataDate] = useState(today)

  // Get EVM data
  const getEvmDataParams: GetEvmDataRequest | undefined = projectId
    ? {
        projectId,
        dataDate: dayjs(dataDate).format(JsonDateFormat.YYYYMMDD),
      }
    : undefined
  const {
    data: getEvmDataResult,
    isError,
    error,
  } = useGetEvmDataQuery(getEvmDataParams ?? skipToken)

  // The hook to detect the error the of the GetEvmData api
  useEffect(() => {
    if (isError) {
      // Show api error on Toast message
      generalShowApiErrors(error)
    }
  }, [isError, error, generalShowApiErrors])

  // Event handle when report date is changed
  const handleReportDateChanged = useCallback((e: string | number | Date) => {
    if (isDate(e)) {
      // 選択した日付を基準日に設定する
      setDataDate(e)
    }
  }, [])

  // Format currency
  const currencyFormatter = useCallback(
    (value: number) => {
      if (value === 0) {
        return '0' + t('label.yen')
      }

      return (
        t('format.currency', {
          value: value,
        }) + t('label.yen')
      )
    },
    [t]
  )

  // Event handle when the pointer enters a series point.
  const handlePointHoverChanged = useCallback((e: PointInteractionInfo) => {
    e.target.showTooltip()
  }, [])

  // Specifies the tooltip content.
  const tooltipTemplate = useCallback(
    (pointInfo: PointInfo, container: Element[]) => {
      let dataType: string
      switch (pointInfo.seriesName) {
        case 'PV':
          dataType = t('label.evm.plannedValue')
          break
        case 'EV':
          dataType = t('label.evm.earnedValue')
          break
        case 'AC':
          dataType = t('label.evm.actualCost')
          break
        case 'EAC':
          dataType = t('label.evm.estimateAtCompletion')
          break
        default:
          dataType = ''
      }

      const dataDate = t('format.date', {
        value: pointInfo.originalArgument,
      })

      const cost = currencyFormatter(pointInfo.originalValue)

      const content = `<div>
      <p>${dataType}</p>
      <p>${dataDate}</p>
      <p>${cost}</p>
    </div>`

      container[0].innerHTML = content
    },
    [currencyFormatter, t]
  )

  // Specifies SVG for legend item marker.
  const markerTemplate = (item: MarkerInfor) => {
    const width = 35
    const height = 2
    const canvasStyle = { display: 'block', margin: 'auto' }
    return (
      <svg width={width} height={height} style={canvasStyle}>
        <line
          x1="0"
          y1={height / 2}
          x2={width}
          y2={height / 2}
          stroke={item.marker.fill}
          strokeWidth="2"
          // make dashed for icon of EAC series
          strokeDasharray={item.text === 'EAC' ? '5, 5' : 'none'}
        />
      </svg>
    )
  }

  // Customizes the text displayed by axis labels.
  const customizeAxisLabel = useCallback(
    (arg: { value: string | number | Date; valueText: string }) => {
      // Make it show with currency format
      return t('format.currency', {
        value: arg.value,
      })
    },
    [t]
  )

  const dailyData = useMemo(() => {
    return getEvmDataResult?.data?.dailyData.map((item) => ({
      ...item,
      date: dayjs(item.date).format(JsonDateFormat.YYYYMMDD),
    }))
  }, [getEvmDataResult?.data?.dailyData])

  const evmIndexData = useMemo(
    () => getEvmDataResult?.data?.evmIndex,
    [getEvmDataResult?.data?.evmIndex]
  )

  const esmIndexData = useMemo(() => {
    if (!getEvmDataResult?.data?.esmIndex) return null

    return {
      ...getEvmDataResult.data.esmIndex,
      earnedSchedule: dayjs(
        getEvmDataResult.data.esmIndex.earnedSchedule
      ).format(JsonDateFormat.YYYYMMDD),
      actualTime: dayjs(getEvmDataResult.data.esmIndex.actualTime).format(
        JsonDateFormat.YYYYMMDD
      ),
      plannedDuration: dayjs(
        getEvmDataResult.data.esmIndex.plannedDuration
      ).format(JsonDateFormat.YYYYMMDD),
      estimateAtCompletion: dayjs(
        getEvmDataResult.data.esmIndex.estimateAtCompletion
      ).format(JsonDateFormat.YYYYMMDD),
    }
  }, [getEvmDataResult?.data?.esmIndex])

  // EVMのデータはプロジェクトデータのため、プロジェクトIDがない場合、何も表示されない
  if (!projectId) {
    return <></>
  }

  // バックエンド側で何かしらのエラーが発生した場合、基準日のみ表示する。
  // バックエンド側で発生したエラーにより、開始日-期限の範囲内に基準日が含まれていない場合の可能性があるため、ユーザーが別の日付を選択できるように基準日を表示する必要がある。
  if (isError) {
    return (
      <>
        <div className="flex flex-1 flex-col justify-start items-center py-2.5 pb-2">
          <div className="flex-col justify-start items-start flex w-11/12 gap-2.5">
            <div className="flex flex-1 flex-row gap-2.5 w-full">
              {/** 基準日 */}
              <div className="flex flex-col gap-1 justify-center">
                <div className="flex flex-col">
                  <label className="crew-text-gray-4">
                    {t('label.evm.referenceDate')}
                  </label>
                  <CrewDateBox
                    type="date"
                    name="reportDate"
                    displayFormat={DatePickerDateFormat.YYYYMMDD}
                    onValueChange={handleReportDateChanged}
                    defaultValue={dataDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  // EVMデータがない場合、メッセージを「表示するデータがありません」と表示する。
  // - タスクがないため。
  // - 期間が設定されたタスクがないため。
  if (!dailyData || !evmIndexData || !esmIndexData) {
    return <div className="text-center py-16">{t('label.noDataText')}</div>
  }

  return (
    <div className="flex flex-1 flex-col justify-start items-center py-2.5 pb-16">
      <div className="flex-col justify-start items-start flex w-11/12 gap-2.5">
        <div className="flex flex-1 flex-row gap-2.5 w-full">
          {/** 基準日 */}
          <div className="flex flex-col gap-1 justify-center">
            <div className="flex flex-col">
              <label className="crew-text-gray-4">
                {t('label.evm.referenceDate')}
              </label>
              <CrewDateBox
                type="date"
                name="reportDate"
                displayFormat={DatePickerDateFormat.YYYYMMDD}
                onValueChange={handleReportDateChanged}
                defaultValue={dataDate}
              />
            </div>
          </div>

          {/** Summary information */}
          <div className="flex flex-1 flex-rows items-center rounded-md crew-bg-gray-1 p-2.5 gap-2.5 flex-wrap justify-around">
            <div className="flex flex-col gap-1">
              <p className="text-center">{t('label.evm.plannedCost')}</p>
              <p className="text-right">
                {currencyFormatter(evmIndexData.budgetAtCompletion)}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-center">{t('label.evm.expectedCost')}</p>
              <p className="text-right">
                {currencyFormatter(evmIndexData.estimateAtCompletion)}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-center">{t('label.evm.costVariance')}</p>
              <p
                className={classNames(
                  'text-right',
                  evmIndexData.varianceAtCompletion < 0
                    ? 'text-crew-red-500'
                    : ''
                )}
              >
                {currencyFormatter(evmIndexData.varianceAtCompletion)}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-center">
                {t('label.evm.plannedCompletionDate')}
              </p>
              <p className="text-right">
                {t('format.date', { value: esmIndexData.plannedDuration })}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-center">
                {t('label.evm.expectedCompletionDate')}
              </p>
              <p className="text-right">
                {t('format.date', {
                  value: esmIndexData.estimateAtCompletion,
                })}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-right">{t('label.evm.scheduleVariance')}</p>
              <p
                className={classNames(
                  'text-right',
                  esmIndexData.varianceAtCompletion < 0
                    ? 'text-crew-red-500'
                    : ''
                )}
              >
                {esmIndexData.varianceAtCompletion}
                {t('label.days')}
              </p>
            </div>
          </div>
        </div>

        {/** Chart */}
        {dailyData && (
          <div className="flex flex-1 w-full py-7">
            <CrewChart
              palette="Violet"
              dataSource={dailyData}
              className="w-full"
              onPointHoverChanged={handlePointHoverChanged}
            >
              {/** Left title */}
              <ValueAxis>
                <Title text={t('label.evm.costWithYen')}>
                  <Font weight={700} />
                </Title>
                <Label customizeText={customizeAxisLabel} />
              </ValueAxis>

              {/** Series settings */}
              <CommonSeriesSettings
                argumentField="date"
                point={{
                  // Don't show the points, just make it visible when hovering over itself
                  size: '0',
                  hoverMode: 'onlyPoint',
                  hoverStyle: {
                    size: '8',
                  },
                }}
              />

              {/** Series */}
              {evmChartSeries.map((item) => (
                <Series
                  key={item.value}
                  valueField={item.value}
                  name={item.name}
                  color={item.color}
                  dashStyle={item.dashStyle}
                />
              ))}

              {/** Date */}
              <ArgumentAxis
                argumentType="datetime"
                grid={{
                  visible: false,
                }}
              >
                <TickInterval days={1} />
                <Label displayMode="rotate" rotationAngle={-45}>
                  <Format formatter={dateFormatter} />
                </Label>

                <ConstantLine
                  value={esmIndexData.earnedSchedule}
                  color="#8585ff"
                  // TODO: 組み合わせできるとは記載あるが指定不可。CREW-15544で公式サポートへ問合せ予定
                  // https://js.devexpress.com/React/Documentation/23_2/ApiReference/UI_Components/dxChart/Configuration/argumentAxis/constantLines/#dashStyle
                  dashStyle="longDash"
                  // dashStyle="dashdotdash"
                  width={1.5}
                >
                  <Label
                    text="ES"
                    horizontalAlignment="left"
                    verticalOffset={10}
                  ></Label>
                </ConstantLine>
                <ConstantLine
                  value={esmIndexData.actualTime}
                  color="#8a8a8a"
                  dashStyle="dash"
                  width={1.5}
                >
                  <Label
                    text="AT"
                    horizontalAlignment={
                      // ES = AT: 左側にESを表示し、右側にATを表示
                      esmIndexData.actualTime === esmIndexData.earnedSchedule
                        ? 'right'
                        : 'left'
                    }
                  ></Label>
                </ConstantLine>
                <ConstantLine
                  value={esmIndexData.plannedDuration}
                  color="#6b6b6b"
                  dashStyle="longDash"
                  width={1.5}
                >
                  <Label
                    text="PD"
                    horizontalAlignment={
                      // AT = PD: 左側にPDを表示し、右側にATを表示
                      esmIndexData.actualTime === esmIndexData.plannedDuration
                        ? 'right'
                        : 'left'
                    }
                  ></Label>
                </ConstantLine>
                <ConstantLine
                  value={esmIndexData.estimateAtCompletion}
                  color="#ff6363"
                  // TODO: 組み合わせできるとは記載あるが指定不可。CREW-15544で公式サポートへ問合せ予定
                  // https://js.devexpress.com/React/Documentation/23_2/ApiReference/UI_Components/dxChart/Configuration/argumentAxis/constantLines/#dashStyle
                  dashStyle="longDash"
                  // dashStyle="longDashdotlongDash"
                  width={1.5}
                >
                  <Label
                    text="EAC(t)"
                    horizontalAlignment={
                      // PD = EAC(t): 左側にPDを表示し、右側にEAC(t)を表示
                      esmIndexData.estimateAtCompletion ===
                      esmIndexData.plannedDuration
                        ? 'right'
                        : 'left'
                    }
                  ></Label>
                </ConstantLine>
              </ArgumentAxis>

              {/** Legend setting */}
              <Legend
                horizontalAlignment="right"
                verticalAlignment="top"
                markerRender={markerTemplate}
              >
                <Border dashStyle="solid" color="red" width={2} />
              </Legend>

              <Tooltip enabled={true} contentTemplate={tooltipTemplate} />
            </CrewChart>
          </div>
        )}

        {/** Detail information */}
        <div className="flex gap-5 flex-wrap w-9/12 mx-auto">
          {/** EVM index */}
          <div className="flex-1">
            <div className="flex flex-col gap-2.5 rounded-md crew-bg-gray-1 p-4">
              <p className="text-center text-xl font-bold">
                {t('label.evm.evmIndex')}
              </p>
              <div className="flex gap-2 flex-col">
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.plannedValue')}</p>
                  <p className="text-center w-1/6">{t('label.evm.pv')}</p>
                  <p className="text-right w-2/6">
                    {currencyFormatter(evmIndexData.plannedValue)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.earnedValue')}</p>
                  <p className="text-center w-1/6">{t('label.evm.ev')}</p>
                  <p className="text-right w-2/6">
                    {currencyFormatter(evmIndexData.earnedValue)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.actualCost')}</p>
                  <p className="text-center w-1/6">{t('label.evm.ac')}</p>
                  <p className="text-right w-2/6">
                    {currencyFormatter(evmIndexData.actualCost)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.scheduleVariance')}</p>
                  <p className="text-center w-1/6">{t('label.evm.sv')}</p>
                  <p
                    className={classNames(
                      'text-right w-2/6',
                      evmIndexData.scheduleVariance < 0
                        ? 'text-crew-red-500'
                        : ''
                    )}
                  >
                    {currencyFormatter(evmIndexData.scheduleVariance)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">
                    {t('label.evm.schedulePerformanceIndex')}
                  </p>
                  <p className="text-center w-1/6">{t('label.evm.spi')}</p>
                  <p
                    className={classNames(
                      'text-right w-2/6',
                      evmIndexData.schedulePerformanceIndex < 1
                        ? 'text-crew-red-500'
                        : ''
                    )}
                  >
                    {Number(evmIndexData.schedulePerformanceIndex).toFixed(2)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.costVariance')}</p>
                  <p className="text-center w-1/6">{t('label.evm.cv')}</p>
                  <p
                    className={classNames(
                      'text-right w-2/6',
                      evmIndexData.costVariance < 0 ? 'text-crew-red-500' : ''
                    )}
                  >
                    {currencyFormatter(evmIndexData.costVariance)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.costPerformanceIndex')}</p>
                  <p className="text-center w-1/6">{t('label.evm.cpi')}</p>
                  <p
                    className={classNames(
                      'text-right w-2/6',
                      evmIndexData.costPerformanceIndex < 1
                        ? 'text-crew-red-500'
                        : ''
                    )}
                  >
                    {Number(evmIndexData.costPerformanceIndex).toFixed(2)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.budgetAtCompletion')}</p>
                  <p className="text-center w-1/6">{t('label.evm.bac')}</p>
                  <p className="text-right w-2/6">
                    {currencyFormatter(evmIndexData.budgetAtCompletion)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.estimateAtCompletion')}</p>
                  <p className="text-center w-1/6">{t('label.evm.eac')}</p>
                  <p className="text-right w-2/6">
                    {currencyFormatter(evmIndexData.estimateAtCompletion)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.varianceAtCompletion')}</p>
                  <p className="text-center w-1/6">{t('label.evm.vac')}</p>
                  <p
                    className={classNames(
                      'text-right w-2/6',
                      evmIndexData.varianceAtCompletion < 0
                        ? 'text-crew-red-500'
                        : ''
                    )}
                  >
                    {currencyFormatter(evmIndexData.varianceAtCompletion)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/** ESM index */}
          <div className="flex-1">
            <div className="flex flex-col gap-2.5 rounded-md crew-bg-gray-1 p-4 ">
              <p className="text-center text-xl font-bold">
                {t('label.evm.esmIndex')}
              </p>
              <div className="flex gap-2 flex-col">
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.earnedSchedule')}</p>
                  <p className="text-center w-1/6">{t('label.evm.es')}</p>
                  <p className="text-right w-2/6">
                    {t('format.date', { value: esmIndexData.earnedSchedule })}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.actualSchedule')}</p>
                  <p className="text-center w-1/6">{t('label.evm.at')}</p>
                  <p className="text-right w-2/6">
                    {t('format.date', { value: esmIndexData.actualTime })}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">{t('label.evm.scheduleVariance')}</p>
                  <p className="text-center w-1/6">{t('label.evm.svt')}</p>
                  <p
                    className={classNames(
                      'text-right w-2/6',
                      esmIndexData.scheduleVariance < 0
                        ? 'text-crew-red-500'
                        : ''
                    )}
                  >
                    {esmIndexData.scheduleVariance}
                    {t('label.days')}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">
                    {t('label.evm.schedulePerformanceIndex')}
                  </p>
                  <p className="text-center w-1/6">{t('label.evm.spit')}</p>
                  <p
                    className={classNames(
                      'text-right w-2/6',
                      esmIndexData.schedulePerformanceIndex < 1
                        ? 'text-crew-red-500'
                        : ''
                    )}
                  >
                    {Number(esmIndexData.schedulePerformanceIndex).toFixed(2)}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">
                    {t('label.evm.plannedCompletionDate')}
                  </p>
                  <p className="text-center w-1/6">{t('label.evm.pd')}</p>
                  <p className="text-right w-2/6">
                    {t('format.date', { value: esmIndexData.plannedDuration })}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">
                    {t('label.evm.expectedCompletionDate')}
                  </p>
                  <p className="text-center w-1/6">{t('label.evm.eact')}</p>
                  <p className="text-right w-2/6">
                    {t('format.date', {
                      value: esmIndexData.estimateAtCompletion,
                    })}
                  </p>
                </div>
                <div className="flex flex-row w-full items-center gap-2.5">
                  <p className="w-3/6">
                    {t('label.evm.completionDateVariance')}
                  </p>
                  <p className="text-center w-1/6">{t('label.evm.vac')}</p>
                  <p
                    className={classNames(
                      'text-right w-2/6',
                      esmIndexData.varianceAtCompletion < 0
                        ? 'text-crew-red-500'
                        : ''
                    )}
                  >
                    {esmIndexData.varianceAtCompletion}
                    {t('label.days')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
