import { useEffectOnce } from '@dx-system/react-use'

import { useForegroundPushNotification } from 'hooks/pushNotification/useForegroundPushNotification'

export const useApp = () => {
  useForegroundPushNotification()

  // ブラウザ判定
  const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')

  useEffectOnce(() => {
    const container = document.getElementById('root')
    if (!container) throw new Error('root element not found')

    // Firefoxの場合、クラスを追加
    isFirefox && container.parentElement?.classList.add('is-firefox')
  })
}
