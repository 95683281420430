import { FC, memo, useCallback, useEffect, useState } from 'react'
import {
  CrewRetryImage,
  CrewRetryImageRetryType,
} from '../../../crewRetryImage/crewRetryImage'
import { useFileSignedUrl } from 'hooks/useFileSignedUrl'

type FileThumbnailItemProps = {
  id: string
  fileName: string
  draggable?: boolean
}

export const FileThumbnailItem: FC<FileThumbnailItemProps> = memo((props) => {
  const { getFileSignedUrlForThumbnail } = useFileSignedUrl()

  const [fileDownloadUrl, setFileDownloadUrl] = useState('')

  // サムネイル取得URLの生成
  const generateThumbnailUrl = useCallback(async () => {
    // Fileの場合
    const url = await getFileSignedUrlForThumbnail(props.id)

    return url
  }, [getFileSignedUrlForThumbnail, props.id])

  // ファイルのサムネイル取得用URLを生成し、子コンポーネントに渡すstateにセットする
  useEffect(() => {
    const setThumbnailUrl = async () => {
      const url = await generateThumbnailUrl()
      setFileDownloadUrl(url)
    }

    setThumbnailUrl()
  }, [generateThumbnailUrl, getFileSignedUrlForThumbnail])

  return (
    <CrewRetryImage
      src={fileDownloadUrl}
      alt={props.fileName}
      retryType={CrewRetryImageRetryType.GenerateNewUrl}
      onGenerateNewUrl={generateThumbnailUrl}
      className="rounded-none h-full w-full object-cover"
      draggable={props.draggable}
    />
  )
})
