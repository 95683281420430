import classNames from 'classnames'
import { FC, memo, useMemo } from 'react'

import { LIMIT_FOR_UNREAD_DISPLAY_COUNT } from 'configs/constants'
import { selectUnreadCountTotal, useUserSetting } from '@crew/states'
import { useAppSelector } from 'states/hooks'
import { SettingKeyType } from '@crew/enums/dist/app'

// 最低幅として、1文字分確保できるようにする
const badgeBaseClassNames =
  'crew-badge text-sm truncate justify-center min-w-[1.5rem] h-5 leading-3 !font-normal has-unread'

export const CrewAttentionUnreadBadge: FC = memo(() => {
  // 「メンションと返信」に通知する内容の個人設定値
  const newsMentionAndReplies = useUserSetting(
    SettingKeyType.NewsMentionAndReplies
  )

  const { attention, mentionToMe, mentionToGroup } = useAppSelector(
    selectUnreadCountTotal(newsMentionAndReplies)
  )

  // if unread number is greater than 99, display 99+
  const counterText =
    attention > LIMIT_FOR_UNREAD_DISPLAY_COUNT
      ? `${LIMIT_FOR_UNREAD_DISPLAY_COUNT}+`
      : `${attention}`

  const targetBackgroundClassNames = useMemo(
    () =>
      mentionToMe > 0
        ? 'crew-badge-red-inverted' // 赤：ユーザー個人宛のメンションがある場合
        : mentionToGroup > 0
        ? 'crew-badge-orange-inverted' // オレンジ：ユーザが属するグループ宛のメンションがある場合。@allや@hereなど
        : 'crew-badge-blue-inverted', // 青：上記以外
    [mentionToMe, mentionToGroup]
  )

  if (attention <= 0) {
    // 未読0なら何も表示しない
    return null
  }

  return (
    <span
      className={classNames(badgeBaseClassNames, targetBackgroundClassNames)}
    >
      {counterText}
    </span>
  )
})
