import { memo, useMemo } from 'react'
import { UserTable } from './components/userTable/userTable'
import { useGetUsersQuery } from '@crew/apis/user/userApis'
import { useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { PaginationState } from '@tanstack/react-table'
import { GetUsersRequest } from '@crew/apis/user/models/getUsers/request'
import { getParamAsString } from 'utils'
import { UserFilters } from 'enums/app'
import { skipToken } from '@reduxjs/toolkit/query'

export const UserListPanel = memo(() => {
  const [searchParams] = useSearchParams()

  const params = qs.parse(searchParams.toString())

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  const requestParams: GetUsersRequest = {
    filter: searchParams.get('filter') || UserFilters.AllUsers,
    keyword: searchParams.get('keyword') || undefined,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
  }

  const { data: getUsersResult } = useGetUsersQuery(requestParams ?? skipToken)

  return (
    <div className="flex flex-col gap-2 overflow-y-hidden">
      <div className="flex-1 overflow-y-hidden">
        <UserTable
          users={getUsersResult?.users ?? []}
          totalCount={getUsersResult?.totalCount ?? 0}
          pagination={pagination}
        />
      </div>
    </div>
  )
})
