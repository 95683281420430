import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { useMyTaskListSearchPanel } from './useMyTaskListSearchPanel'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { TextBox } from 'devextreme-react'
import { debounce } from 'lodash'
import { ComponentCallbackHandler, isEqualParams } from '@crew/utils'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import {
  myTaskListSearchKeywordUpdated,
  myTaskListSearchTaskKindUpdated,
} from 'features/home/components/myTaskPage/states/myTaskSlice'
import qs from 'qs'
import { TaskKindFilters } from 'enums/app'
import { TaskKindRef } from '@crew/models/refs'

type GroupComponentType<T> = {
  data: {
    items: T[]
    key: string
  }
}

// Render task type group name
const renderTaskKindGroupName = ({ data }: GroupComponentType<TaskKindRef>) => {
  // プロジェクトに紐づかないデータは仕様上ありえないが、型制約の関係上チェック処理を追加
  const item = data.items.find((item) => item.projectId)

  if (!item) {
    return <></>
  }

  return <span>{item.projectName}</span>
}

export const MyTaskListSearchPanel = memo(() => {
  const { taskKindDataSource } = useMyTaskListSearchPanel()

  const dispatch = useAppDispatch()

  const { navigate } = useCrewNavigate()

  const location = useLocation()

  const [searchParams] = useSearchParams()

  const keyword = useAppSelector(
    (state) => state.myTask.myTaskListSearchParams.keyword
  )
  const taskKindId = useAppSelector(
    (state) => state.myTask.myTaskListSearchParams.taskKindId
  )

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  //キーワード検索
  const keywordTextBoxRef = useRef<TextBox>(null)

  //delay search when keyup keyword search after 500ms
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        const currentParams: any = qs.parse(searchParams.toString())
        // nullや空文字はundefinedに置き換える
        const newParams = {
          ...currentParams,
          keyword: value || undefined,
          // ページ数：1ページ目を指定
          pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
          // ページサイズ：現在の値を引き継ぐ
          // 現在値がない場合は、ユーザー設定の値を使用し、それも無ければデフォルト値を使用する
          pageSize: currentParams.pageSize ?? Number(defaultListDisplayNumber),
        }

        // 現在のクエリパラメータと同じ場合は検索しない
        if (!isEqualParams(currentParams, newParams)) {
          navigate(location.pathname, newParams, true)
        }
      }, SEARCH_TIMEOUT_MSEC),
    [defaultListDisplayNumber, location.pathname, navigate, searchParams]
  )

  const handleKeywordTextBoxValueChanged = useCallback(() => {
    // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
    const input = keywordTextBoxRef.current?.instance.option('text')
    if (input !== undefined && input !== keyword) {
      dispatch(myTaskListSearchKeywordUpdated(input))
      debouncedSearch(input)
    }
  }, [debouncedSearch, dispatch, keyword])

  // クエリーパラメータが変わったら、検索条件に反映する
  useEffect(() => {
    const currentParams: any = qs.parse(searchParams.toString())

    dispatch(myTaskListSearchKeywordUpdated(currentParams.keyword))
    dispatch(
      myTaskListSearchTaskKindUpdated(
        currentParams.taskKindId || TaskKindFilters.AllTaskKinds
      )
    )

    // マイページのタスク一覧から別ページに遷移する際に検索条件をリセット
    return () => {
      dispatch(myTaskListSearchKeywordUpdated(''))
      dispatch(myTaskListSearchTaskKindUpdated(TaskKindFilters.AllTaskKinds))
    }
  }, [dispatch, searchParams])

  const handleTaskKindChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBox, 'onValueChanged'>
  >(
    (event) => {
      const currentParams: any = qs.parse(searchParams.toString())

      // nullや空文字はundefinedに置き換える
      const newParams = {
        ...currentParams,
        taskKindId: event.value || undefined,
        // ページ数：1ページ目を指定
        pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
        // ページサイズ：現在の値を引き継ぐ
        // 現在値がない場合は、ユーザー設定の値を使用し、それも無ければデフォルト値を使用する
        pageSize: currentParams.pageSize ?? Number(defaultListDisplayNumber),
      }

      dispatch(myTaskListSearchTaskKindUpdated(event.value))
      if (!isEqualParams(currentParams, newParams)) {
        navigate(location.pathname, newParams, true)
      }
    },
    [
      defaultListDisplayNumber,
      dispatch,
      location.pathname,
      navigate,
      searchParams,
    ]
  )

  return (
    <div className="flex-1 flex gap-x-2.5 items-center">
      {/* タスク種別 */}
      <CrewSelectBox
        value={taskKindId}
        dataSource={taskKindDataSource}
        displayExpr="name"
        valueExpr="id"
        searchEnabled={false}
        minSearchLength={0}
        showClearButton={false}
        onValueChanged={handleTaskKindChanged}
        groupComponent={renderTaskKindGroupName}
        grouped
      />
      {/* キーワード */}
      <CrewTextBox
        value={keyword}
        valueChangeEvent="input change"
        onValueChanged={handleKeywordTextBoxValueChanged}
        showClearButton={true}
        ref={keywordTextBoxRef}
        mode="search"
      />
    </div>
  )
})
