import { Dispatch, SetStateAction } from 'react'
import { createUninitializedContext } from '@crew/utils/context'

/** Context用の型 */
type EventDetailPageContextType = {
  confirmAttendance: boolean
  setConfirmAttendance: Dispatch<SetStateAction<boolean>>
}

/** Context */
const {
  context: eventDetailPageContext,
  useInitializedContext: useEventDetailPageContext,
} = createUninitializedContext<EventDetailPageContextType>()

export { useEventDetailPageContext }
// export const EventDetailPageContext =
//   createContext<EventDetailPageContextType>(undefined!)

export const useEventDetailPage = () => {
  return {
    eventDetailPageContext,
  }
}
