import { memo, useMemo } from 'react'
import FilePdf2Line from '~icons/ri/file-pdf-2-line'
import FileTextLine from '~icons/ri/file-text-line'
import FileWord2Fill from '~icons/ri/file-word-2-fill'
import FileExcel2Fill from '~icons/ri/file-excel-2-fill'
import FilePpt2Fill from '~icons/ri/file-ppt-2-fill'
import FileVideoLine from '~icons/ri/file-video-line'
import FileImageLine from '~icons/ri/file-image-line'

import { FileExtensions } from '@crew/enums/app'

const DEFAULT_ICON_WIDTH = 24
const DEFAULT_ICON_HEIGHT = 24

export type CrewFileIconProps = {
  fileName: string
  width?: number
  height?: number
  fill?: boolean
}

export const CrewFileIcon = memo((props: CrewFileIconProps) => {
  const icon = useMemo(() => {
    const width = props.width ?? (props.fill ? 'auto' : DEFAULT_ICON_WIDTH)
    const height = props.height ?? (props.fill ? 'auto' : DEFAULT_ICON_HEIGHT)

    //get file extension
    const fileExtension = props.fileName.split('.').pop()?.toLocaleLowerCase()

    //generate file icon by file extension
    switch (fileExtension) {
      case FileExtensions.Pdf:
        return <FilePdf2Line width={width} height={height} />
      case FileExtensions.Csv:
        return (
          <FileTextLine
            width={width}
            height={height}
            // ファイルの拡張子に応じた色を指定
            className="text-crew-gray-500"
          />
        )
      case FileExtensions.Doc:
      case FileExtensions.Docx:
        return (
          <FileWord2Fill
            width={width}
            height={height}
            // ファイルの拡張子に応じた色を指定
            className="text-crew-blue-600"
          />
        )
      case FileExtensions.Xls:
      case FileExtensions.Xlsx:
        return (
          <FileExcel2Fill
            width={width}
            height={height}
            // ファイルの拡張子に応じた色を指定
            className="text-crew-green-600"
          />
        )
      case FileExtensions.Ppt:
      case FileExtensions.Pptx:
        return (
          <FilePpt2Fill
            width={width}
            height={height}
            // ファイルの拡張子に応じた色を指定
            className="text-crew-orange-600"
          />
        )
      case FileExtensions.Mp4:
      case FileExtensions.Mov:
      case FileExtensions.Mpg:
      case FileExtensions.Mpeg:
      case FileExtensions.Wmv:
      case FileExtensions.Avi:
      case FileExtensions.Flv:
        return (
          <FileVideoLine
            width={width}
            height={height}
            // ファイルの拡張子に応じた色を指定
            className="text-crew-gray-500"
          />
        )
      case FileExtensions.Png:
      case FileExtensions.Jpg:
      case FileExtensions.Jpeg:
      case FileExtensions.Gif:
      case FileExtensions.Bmp:
      case FileExtensions.Tiff:
      case FileExtensions.Tif:
        return (
          <FileImageLine
            width={width}
            height={height}
            // ファイルの拡張子に応じた色を指定
            className="text-crew-gray-500"
          />
        )
      default:
        return (
          <FileTextLine
            width={width}
            height={height}
            // ファイルの拡張子に応じた色を指定
            className="text-crew-gray-500"
          />
        )
    }
  }, [props.fileName, props.fill, props.height, props.width])

  return <>{icon}</>
})
