import { useCallback } from 'react';
import { IsAttentionItem, } from '@crew/apis/attention/models/getAttentions/response';
import { IsDeletedAttentionItem, } from '@crew/apis/websocket/models/deletedAttentionItem/deletedAttentionItem';
import { IsDeletedFeedItem, } from '@crew/apis/websocket/models/deletedFeedItem/deletedFeedItem';
import { PubSubEventType, UnreadInfoTarget, PubSubEventDataType, PubSubEventTargetType, } from '@crew/enums/domain';
import { IsChatMessage, IsChatReaction, IsDirectChannel, IsUpdateUnreadInfo, } from '@crew/models/domain';
import { useAttentionService, useChatMessageService, useBookmarkService, useFeedService, useUnreadService, useActiveChatRoomService, } from '@crew/states';
import { useWebsocket } from './useWebsocket';
import { assertNever } from '@crew/utils';
// 各AsyncMessageTypeに対応したwebSocketのメッセージリスナーを定義したcustom hooks
/**
 * フィードに関連するメッセージのsubscribeを行うcustom hook
 * @param onRecvAddMessage
 * @param onRecvDeleteMessage
 * @param dispatch
 */
export const useSubscribeFeed = (onRecvAddMessage, dispatch, selector) => {
    // メッセージ追加
    useSubscribeFeedChatMessageAdded(onRecvAddMessage, dispatch, selector);
    // メッセージ更新
    useSubscribeFeedChatMessageUpdated(dispatch, selector);
    // メッセージ削除
    useSubscribeFeedChatMessageDeleted(dispatch, selector);
    // リアクション追加
    useSubscribeFeedReactionAdded(dispatch, selector);
    // リアクション削除
    useSubscribeFeedReactionDeleted(dispatch, selector);
    // ブックマーク追加
    useSubscribeBookmarked(dispatch, selector);
    // ブックマーク削除
    useSubscribeDropBookmark(dispatch, selector);
};
/**
 * アテンションに関連するメッセージのsubscribeを行うcustom hook
 * @param onRecvAddOrUpdateMessage
 * @param onRecvDeleteMessage
 * @param dispatch
 */
export const useSubscribeAttention = (onRecvAddMessage, dispatch, selector) => {
    // アテンション追加
    useSubscribeAttentionItemAdded(onRecvAddMessage, dispatch, selector);
    // アテンション削除
    useSubscribeAttentionItemDeleted(dispatch, selector);
    // ブックマーク追加
    useSubscribeBookmarked(dispatch, selector);
    // ブックマーク削除
    useSubscribeDropBookmark(dispatch, selector);
    // リアクション追加
    useSubscribeFeedReactionAdded(dispatch, selector);
    // リアクション削除
    useSubscribeFeedReactionDeleted(dispatch, selector);
};
/**
 * ブックマーク関連のメッセージのsubscribeを行うcustom hook
 * @param onRecvUpdateMessage
 * @param onRecvDeleteMessage
 * @param dispatch
 */
export const useSubscribeBookmark = (dispatch, selector) => {
    // ブックマーク追加
    useSubscribeBookmarked(dispatch, selector);
    // ブックマーク削除
    useSubscribeDropBookmark(dispatch, selector);
    // メッセージ更新
    useSubscribeFeedChatMessageUpdated(dispatch, selector);
    // メッセージ削除
    useSubscribeFeedChatMessageDeleted(dispatch, selector);
    // リアクション追加
    useSubscribeFeedReactionAdded(dispatch, selector);
    // リアクション削除
    useSubscribeFeedReactionDeleted(dispatch, selector);
};
/**
 * ダイレクトメッセージに必要なメッセージのsubscribeを行うcustom hook
 * @param onRecvDirectChannelAdded
 * @param dispatch
 */
export const useSubscribeDirectChannel = (onRecvDirectChannelAdded, dispatch, selector) => {
    // ダイレクトチャンネル追加
    useSubscribeDirectChannelAdded(onRecvDirectChannelAdded, dispatch, selector);
};
/**
 * チャットに関連するメッセージのsubscribeを行うcustom hook
 * @param targetId
 * @param onRecvMessageAdded
 * @param dispatch
 */
export const useSubscribeChat = (targetId, onRecvMessageAdded, dispatch, selector) => {
    // メッセージ追加
    useSubscribeChatMessageAdded(targetId, onRecvMessageAdded, dispatch, selector);
    // メッセージ更新
    useSubscribeChatMessageDeleted(targetId, dispatch, selector);
    // メッセージ削除
    useSubscribeChatMessageUpdated(targetId, dispatch, selector);
    // リアクション追加
    useSubscribeChatReactionAdded(targetId, dispatch, selector);
    // リアクション削除
    useSubscribeChatReactionDeleted(targetId, dispatch, selector);
    // ブックマーク追加
    useSubscribeBookmarked(dispatch, selector);
    // ブックマーク削除
    useSubscribeDropBookmark(dispatch, selector);
};
/**
 * ユーザー設定に関連するメッセージのsubscribeを行うcustom hook
 * @param dispatch reduxのdispatch
 * @param selector reduxのuseSelector
 */
export const useSubscribeUserSetting = (dispatch, selector) => {
    // チャットルーム全既読処理受信用
    useSubscribeUserUnreadAllChatRead(dispatch, selector);
};
export const useSubscribeUnread = () => {
    // 未読情報更新
    useSubscribeUnreadUpdated();
    // 「すべて既読にする」契機での未読情報更新
    useSubscribeUnreadChatRoomAllRead();
};
// =================================================================================
// 以下はメッセージ単位のサブスクライブを行うcustom hooks
// 各コンポーネントからは直接呼び出さないため、exportは行わない
// =================================================================================
/*====== feed_reaction ======================*/
/**
 * メッセージのリアクション追加をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeFeedReactionAdded = (dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv add reaction via websocket. ${recv.chatMessageId}`);
        // リアクション情報を追加
        chatMessageService.insertReaction({ reaction: recv });
    }, [chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.ChatMessageReactionAdded,
        IsValidMessage: IsChatReaction,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue,
    );
};
/**
 * メッセージのリアクション削除をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeFeedReactionDeleted = (dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv delete reaction via websocket. ${recv.chatMessageId}`);
        // リアクション情報の削除
        chatMessageService.deleteReaction({ reaction: recv });
    }, [chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.ChatMessageReactionDeleted,
        IsValidMessage: IsChatReaction,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/*====== feed ==========================================*/
/**
 * メッセージの追加をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeFeedChatMessageAdded = (onRecvMessage, dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    // Sliceの操作を行うためのServiceを取得
    const feedService = useFeedService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv message via websocket. ${recv.id}`);
        // ChatMessageSliceにメッセージを追加
        chatMessageService.addChatMessageToCache({
            chatMessage: recv,
        });
        // FeedのViewModelにもメッセージを追加
        feedService.addFeedLatestMessage({
            chatMessage: recv,
        });
        // コールバック関数を実行。現在は既読情報の更新のみ行っている
        onRecvMessage === null || onRecvMessage === void 0 ? void 0 : onRecvMessage(recv);
    }, [feedService, chatMessageService, onRecvMessage]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.FeedItemAdded,
        IsValidMessage: IsChatMessage,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/**
 * メッセージの更新をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeFeedChatMessageUpdated = (dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        // メッセージを更新
        chatMessageService.setChatMessage({ chatMessage: recv });
    }, [chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.FeedItemUpdated,
        IsValidMessage: IsChatMessage,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/**
 * メッセージの削除をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeFeedChatMessageDeleted = (dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    // Sliceの操作を行うためのServiceを取得
    const feedService = useFeedService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        // メッセージを削除
        chatMessageService.deleteChatMessage({
            chatMessageId: recv.chatMessageId,
            chatRoomId: recv.chatRoomId,
            parentChatRoomId: recv.parentChatRoomId,
            isTopicMessage: recv.parentChatMessageId === null,
        });
        // FeedのViewModelからもメッセージを削除
        feedService.deleteFeedMessage({
            chatMessageId: recv.chatMessageId,
        });
    }, [chatMessageService, feedService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.FeedItemDeleted,
        IsValidMessage: IsDeletedFeedItem,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/*====== bookmark =====================================/

/**
 * メッセージがブックマークされたかをsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeBookmarked = (dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    const bookmarkService = useBookmarkService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv bookmark message via websocket. ${recv.id}`);
        // キャッシュ済みのチャットメッセージを受け取ったブックマーク済みの情報に置き換え
        chatMessageService.setChatMessage({ chatMessage: recv });
        // BookmarkのViewModelにもメッセージを追加
        bookmarkService.addBookmarkMessage({
            chatMessageId: recv.id,
            archived: recv.bookmarks[0].archived,
            createdAt: recv.createdAt,
        });
    }, [bookmarkService, chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.BookmarkAdded,
        IsValidMessage: IsChatMessage,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/**
 * メッセージのブックマーク削除をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeDropBookmark = (dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    const bookmarkService = useBookmarkService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv bookmark message via websocket. ${recv.id}`);
        // キャッシュ済みのチャットメッセージを受け取ったブックマーク解除の情報に置き換え
        chatMessageService.setChatMessage({ chatMessage: recv });
        // BookmarkのViewModelからメッセージを削除
        bookmarkService.deleteBookmarkMessage({ bookmarkId: recv.id });
    }, [bookmarkService, chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.BookmarkDeleted,
        IsValidMessage: IsChatMessage,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/*====== attention =====================================*/
/**
 * アテンションのメッセージ新規追加をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeAttentionItemAdded = (onRecvMessage, dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    const attentionService = useAttentionService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv attention message via websocket. ${recv.entityRecordId}`);
        // ChatMessageSliceに追加
        chatMessageService.addChatMessageToCache({
            chatMessage: recv.chatMessage,
        });
        // AttentionのViewModelに追加
        const parameter = {
            id: recv.entityRecordId,
            attentionType: recv.attentionType,
            attentionUserName: recv.actionUser.displayName,
            chatMessageId: recv.chatMessage.id,
        };
        attentionService.addAttentionMessage(parameter);
        // コールバック関数を実行。現在は既読情報の更新のみ行っている
        onRecvMessage === null || onRecvMessage === void 0 ? void 0 : onRecvMessage(recv);
    }, [attentionService, chatMessageService, onRecvMessage]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.AttentionItemAdded,
        IsValidMessage: IsAttentionItem,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/**
 * アテンションのメッセージの削除をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeAttentionItemDeleted = (dispatch, selector) => {
    const attentionService = useAttentionService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        // AttentionのViewModelから削除
        attentionService.deleteAttentionMessage({
            attentionId: recv.entityRecordId,
        });
        // 他の同様の処理ではmessageを除去しているが、Attentionはメッセージと1:1ではないため、messageはそのまま残す
    }, [attentionService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.AttentionItemDeleted,
        IsValidMessage: IsDeletedAttentionItem,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/*====== chat_message ================================*/
/**
 * チャットのメッセージ新規追加をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeChatMessageAdded = (chatRoomId, onRecvMessage, dispatch, selector) => {
    // Sliceの操作を行うためのServiceを取得
    const chatMessageService = useChatMessageService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv message via websocket. ${recv.id}`);
        // ChatMessageSliceに追加
        chatMessageService.addChatMessageToCache({
            chatMessage: recv,
        });
        // Chatの各ViewModelにもメッセージを追加
        chatMessageService.addChatMessage({
            chatMessage: recv,
            pubsubTargetChatRoomId: chatRoomId !== null && chatRoomId !== void 0 ? chatRoomId : undefined,
        });
        // コールバック関数を実行。現在は既読情報の更新のみ行っている
        onRecvMessage === null || onRecvMessage === void 0 ? void 0 : onRecvMessage(recv);
    }, [chatMessageService, chatRoomId, onRecvMessage]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.ChatRoom,
        targetId: chatRoomId !== null && chatRoomId !== void 0 ? chatRoomId : undefined,
        messageTypeFilter: PubSubEventType.ChatMessageAdded,
        IsValidMessage: IsChatMessage,
        onRecvMessage: handleRecvMessage,
    }, chatRoomId !== null);
};
/**
 * チャットのメッセージ更新をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeChatMessageUpdated = (chatRoomId, dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv message via websocket. ${recv.id}`);
        // メッセージを更新
        chatMessageService.setChatMessage({ chatMessage: recv });
    }, [chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.ChatRoom,
        targetId: chatRoomId !== null && chatRoomId !== void 0 ? chatRoomId : undefined,
        messageTypeFilter: PubSubEventType.ChatMessageUpdated,
        IsValidMessage: IsChatMessage,
        onRecvMessage: handleRecvMessage,
    }, chatRoomId !== null);
};
/**
 * チャットのメッセージの削除をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeChatMessageDeleted = (chatRoomId, dispatch, selector) => {
    // Sliceの操作を行うためのServiceを取得
    const chatMessageService = useChatMessageService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv message via websocket. ${recv.id}`);
        // メッセージを削除
        chatMessageService.deleteChatMessage({
            chatMessageId: recv.id,
            chatRoomId: recv.chatRoomId,
            parentChatRoomId: recv.chatRoom.parentChatRoomId,
            isTopicMessage: recv.parentChatMessageId === null,
        });
    }, [chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.ChatRoom,
        targetId: chatRoomId !== null && chatRoomId !== void 0 ? chatRoomId : undefined,
        messageTypeFilter: PubSubEventType.ChatMessageDeleted,
        IsValidMessage: IsChatMessage,
        onRecvMessage: handleRecvMessage,
    }, chatRoomId !== null);
};
/*====== chat_reaction ===========================*/
/**
 * チャットのリアクション追加をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeChatReactionAdded = (chatRoomId, dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv add reaction via websocket. ${recv.chatMessageId}`);
        // 追加されたリアクションをキャッシュに反映
        chatMessageService.insertReaction({ reaction: recv });
    }, [chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.ChatRoom,
        targetId: chatRoomId !== null && chatRoomId !== void 0 ? chatRoomId : undefined,
        messageTypeFilter: PubSubEventType.ChatMessageReactionAdded,
        IsValidMessage: IsChatReaction,
        onRecvMessage: handleRecvMessage,
    }, chatRoomId !== null);
};
/**
 * チャットのリアクション削除をsubscribeするcustom hook
 * @param params
 * @returns
 */
const useSubscribeChatReactionDeleted = (chatRoomId, dispatch, selector) => {
    const chatMessageService = useChatMessageService(dispatch);
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        console.info(`[subscribeMessage] Recv delete reaction via websocket. ${recv.chatMessageId}`);
        // 削除されたリアクションをキャッシュから削除
        chatMessageService.deleteReaction({ reaction: recv });
    }, [chatMessageService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.ChatRoom,
        targetId: chatRoomId !== null && chatRoomId !== void 0 ? chatRoomId : undefined,
        messageTypeFilter: PubSubEventType.ChatMessageReactionDeleted,
        IsValidMessage: IsChatReaction,
        onRecvMessage: handleRecvMessage,
    }, chatRoomId !== null);
};
/*====== ダイレクトチャンネル ===========================*/
/**
 * ダイレクトチャンネルの追加をsubscribeするcustom hook
 * A custom hook to subscribe if a new direct channel is created
 * @param params
 * @returns
 */
const useSubscribeDirectChannelAdded = (onRecvMessage, dispatch, selector) => {
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        onRecvMessage(recv);
    }, [onRecvMessage]);
    return useWebsocket({
        dataType: PubSubEventDataType.Data,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.DirectChannelAdded,
        IsValidMessage: IsDirectChannel,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/*====== 未読情報 =====================================*/
/**
 * 未読情報更新をsubscribeするcustom hook
 * @returns
 */
const useSubscribeUnreadUpdated = () => {
    const unreadService = useUnreadService();
    const activeChatRoomService = useActiveChatRoomService();
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        recv.forEach((info) => {
            var _a, _b;
            console.info(`[subscribeMessage] Recv UpdateUnreadInfo:${JSON.stringify(info)}`);
            // state更新（未読情報を更新）
            switch (info.targetType) {
                case UnreadInfoTarget.ChatRoom:
                    activeChatRoomService.setActiveChatRoomWithUnreadCount({
                        activeChatRoom: {
                            chatRoomId: info.chatRoomId,
                            parentChatRoomId: info.parentChatRoomId,
                            referenceEntityType: info.referenceEntityType,
                            referenceEntityRecordId: info.referenceEntityRecordId,
                            isFavorite: info.isFavorite,
                            projectName: info.projectName,
                            projectScope: info.projectScope,
                            projectType: info.projectType,
                            projectVersion: info.projectVersion,
                            unreadCount: info.unreadCount,
                            lastReadMessageId: (_a = info.lastReadMessageId) !== null && _a !== void 0 ? _a : undefined,
                            updateSerial: info.updateSerial,
                        },
                    });
                    break;
                case UnreadInfoTarget.ChatThread:
                    unreadService.setChatThreadUnreadCount({
                        topicId: info.entityRecordId,
                        count: info.unreadCount,
                        lastReadMessageId: (_b = info.lastReadMessageId) !== null && _b !== void 0 ? _b : undefined,
                        updateSerial: info.updateSerial,
                    });
                    break;
                default:
                    assertNever(info, 'Invalid target type');
            }
        });
    }, [activeChatRoomService, unreadService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Meta,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.UnreadUpdated,
        IsValidMessage: IsUpdateUnreadInfo,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/**
 * チャットルーム単位の「すべて既読にする」実行時の未読情報更新をsubscribeするcustom hook
 * @returns
 */
const useSubscribeUnreadChatRoomAllRead = () => {
    const unreadService = useUnreadService();
    const activeChatRoomService = useActiveChatRoomService();
    // メッセージ受信時
    const handleRecvMessage = useCallback((_, recv) => {
        recv.forEach((info) => {
            var _a, _b;
            console.info(`[subscribeMessage] Recv UnreadChatRoomAllRead:${JSON.stringify(info)}`);
            // state更新（未読情報を更新）
            switch (info.targetType) {
                case UnreadInfoTarget.ChatRoom:
                    activeChatRoomService.setActiveChatRoomWithUnreadCount({
                        activeChatRoom: {
                            chatRoomId: info.chatRoomId,
                            parentChatRoomId: info.parentChatRoomId,
                            referenceEntityType: info.referenceEntityType,
                            referenceEntityRecordId: info.referenceEntityRecordId,
                            isFavorite: info.isFavorite,
                            projectName: info.projectName,
                            projectScope: info.projectScope,
                            projectType: info.projectType,
                            projectVersion: info.projectVersion,
                            unreadCount: info.unreadCount,
                            lastReadMessageId: (_a = info.lastReadMessageId) !== null && _a !== void 0 ? _a : undefined,
                            updateSerial: info.updateSerial,
                        },
                    });
                    break;
                case UnreadInfoTarget.ChatThread:
                    unreadService.setChatThreadUnreadCount({
                        topicId: info.entityRecordId,
                        count: info.unreadCount,
                        lastReadMessageId: (_b = info.lastReadMessageId) !== null && _b !== void 0 ? _b : undefined,
                        updateSerial: info.updateSerial,
                    });
                    break;
                default:
                    assertNever(info, 'Invalid target type');
            }
        });
    }, [activeChatRoomService, unreadService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Meta,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.UnreadChatRoomAllRead,
        IsValidMessage: IsUpdateUnreadInfo,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
/**
 * ユーザーの全チャットルームの未読情報の全既読処理をsubscribeするcustom hook
 * @param dispatch reduxのdispatch
 * @param selector reduxのuseSelector
 * @returns
 */
const useSubscribeUserUnreadAllChatRead = (dispatch, selector) => {
    const unreadService = useUnreadService();
    // メッセージ受信時
    const handleRecvMessage = useCallback(() => {
        console.info(`[subscribeMessage] Recv UnreadAllChatRead`);
        unreadService.resetAllChatUnread();
    }, [unreadService]);
    return useWebsocket({
        dataType: PubSubEventDataType.Meta,
        targetType: PubSubEventTargetType.User,
        messageTypeFilter: PubSubEventType.UnreadAllChatRead,
        IsValidMessage: true,
        onRecvMessage: handleRecvMessage,
    }, true //ユーザに対するpublishは常時subscribeしたいのでtrue
    );
};
