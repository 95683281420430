import { FC, memo, useMemo } from 'react'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { CrewSpeakerSelectBox } from 'components/chime/crewSpeakerSelectBox/crewSpeakerSelectBox'
import { CrewSpeakerTestButton } from 'components/chime/crewSpeakerTestButton/crewSpeakerTestButton'
import { CrewMicrophoneSwitch } from 'components/chime/crewMicrophoneSwitch'
import { CrewMicrophoneSelectBox } from 'components/chime/crewMicrophoneSelectBox/crewMicrophoneSelectBox'
import { CrewMicrophoneActivityPreview } from 'components/chime/crewMicrophoneActivityPreview'
import { CrewNoiseReductionSwitch } from 'components/chime/crewNoiseReductionSwitch'
import { CrewCameraSwitch } from 'components/chime/crewCameraSwitch'
import { CrewCameraSelectBox } from 'components/chime/crewCameraSelectBox/crewCameraSelectBox'
import { CrewBackgroundEffectSwitch } from 'components/chime/crewBackgroundEffectSwitch'
import { CrewBackgroundEffectSelectBox } from 'components/chime/crewBackgroundEffectSelectBox/crewBackgroundEffectSelectBox'
import { CrewBackgroundEffectFileInput } from 'components/chime/crewBackgroundEffectFileInput/crewBackgroundEffectFileInput'
import { CrewPreviewVideo } from 'components/chime/crewPreviewVideo'
import { useTranslation } from '@crew/modules/i18n'
import { useLocalVideo } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useAppSelector } from 'states/hooks'
import Person from '~icons/material-symbols/person'
import VolumeUp from '~icons/material-symbols/volume-up'
import Mic from '~icons/material-symbols/mic'
import RecordVoiceOver from '~icons/material-symbols/record-voice-over'
import BaselineVideocam from '~icons/ic/baseline-videocam'
import Texture from '~icons/material-symbols/texture'

export type WebMeetingSettingItemsProps = {
  showAutoRecordingSwitch?: boolean // 自動録音スイッチ表示フラグ
}

export const WebMeetingSettingItems: FC<WebMeetingSettingItemsProps> = memo(
  (props) => {
    const { t } = useTranslation()
    const { isVideoEnabled } = useLocalVideo()

    // ログインユーザ情報取得
    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

    const settingItemBaseClassNames = useMemo(() => {
      return 'flex items-center border-b-crew-slate-1-light dark:border-b-crew-slate-1-dark border-b-2 py-1.5'
    }, [])

    return (
      <CrewScrollView>
        <div className="py-2 px-1">
          <div className="flex gap-x-8">
            <div className="flex flex-col flex-1">
              {/* 名前 */}
              <div className={settingItemBaseClassNames}>
                <div className="flex-none w-36">
                  <div className="inline-flex gap-2 items-center">
                    <Person width={24} height={24} />
                    <div>{t('label.displayName')}</div>
                  </div>
                </div>
                <div className="flex-none w-16"></div>
                <div className="flex-auto w-72">
                  <CrewTextBox
                    name="name"
                    labelMode="hidden"
                    disabled={true}
                    value={loggedInUser?.displayName}
                  />
                </div>
              </div>
              {/* 自動録画 */}
              {/* TODO: CREW-8660 Web会議: 会議参加ダイアログの「自動録画」機能を実装する*/}
              {/* https://break-tmc.atlassian.net/browse/CREW-8660*/}
              {/* {props.showAutoRecordingSwitch && (
                <div className={settingItemBaseClassNames}>
                  <div className="flex-none w-36">
                    <div className="inline-flex">
                      <i className="bi bi-circle-fill mr-2"></i>
                      <div>{t('label.automaticRecording')}</div>
                    </div>
                  </div>
                  <div className="flex-none w-16">
                    <CrewSwitch />
                  </div>
                </div>
              )} */}
              {/* スピーカー */}
              <div className={settingItemBaseClassNames}>
                <div className="flex-none w-36">
                  <div className="inline-flex gap-2 items-center">
                    <VolumeUp width={24} height={24} />
                    <div>{t('label.speaker')}</div>
                  </div>
                </div>
                <div className="flex-none w-16"></div>
                <div className="flex-auto w-36">
                  <div className="flex gap-x-2">
                    <CrewSpeakerSelectBox />
                    <CrewSpeakerTestButton />
                  </div>
                </div>
              </div>
              {/* マイク */}
              <div className={settingItemBaseClassNames}>
                <div className="flex-none w-36">
                  <div className="inline-flex gap-2 items-center">
                    <Mic width={24} height={24} />
                    <div>{t('label.microphone')}</div>
                  </div>
                </div>
                <div className="flex-none w-16">
                  <CrewMicrophoneSwitch />
                </div>
                <div className="flex-auto w-36">
                  <div className="flex flex-col gap-y-2">
                    <CrewMicrophoneSelectBox />
                    <CrewMicrophoneActivityPreview />
                  </div>
                </div>
              </div>
              {/* ノイズ除去 */}
              <div className={settingItemBaseClassNames}>
                <div className="flex-none w-36">
                  <div className="inline-flex gap-2 items-center">
                    <RecordVoiceOver width={24} height={24} />
                    <div>{t('label.noiseReduction')}</div>
                  </div>
                </div>
                <div className="flex-none w-16">
                  <CrewNoiseReductionSwitch />
                </div>
              </div>
              {/* カメラ */}
              <div className={settingItemBaseClassNames}>
                <div className="flex-none w-36">
                  <div className="inline-flex gap-2 items-center">
                    <BaselineVideocam width={24} height={24} />
                    <div>{t('label.camera')}</div>
                  </div>
                </div>
                <div className="flex-none w-16">
                  <CrewCameraSwitch />
                </div>
                <div className="flex-auto w-36">
                  <CrewCameraSelectBox />
                </div>
              </div>
              {/* 背景 */}
              <div className={settingItemBaseClassNames}>
                <div className="flex-none w-36">
                  <div className="inline-flex gap-2 items-center">
                    <Texture width={24} height={24} />
                    <div>{t('label.background')}</div>
                  </div>
                </div>
                <div className="flex-none w-16">
                  <CrewBackgroundEffectSwitch />
                </div>
                <div className="flex-auto w-36">
                  <div className="flex flex-col">
                    <CrewBackgroundEffectSelectBox />
                    <CrewBackgroundEffectFileInput />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center flex-shrink-0 w-72">
              {/* カメラ表示確認 */}
              <div className="flex justify-center items-center h-60">
                <div className="w-72">
                  {isVideoEnabled && <CrewPreviewVideo />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CrewScrollView>
    )
  }
)
