import { FC, memo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { Modal } from 'components/layouts/modal/modal'
import { useTranslation } from '@crew/modules/i18n'

export type CrewErrorDialogProps = {
  isOpen: boolean
  message: string
  onCloseButtonClick: () => void
}

export const CrewErrorDialog: FC<CrewErrorDialogProps> = memo(
  (props: CrewErrorDialogProps) => {
    const { t } = useTranslation()

    return (
      <Modal title="" isOpen={props.isOpen} onClose={props.onCloseButtonClick}>
        <div className="text-center">{props.message}</div>
        <div className="mt-5 text-center">
          <CrewButton
            text={t('action.close')}
            type="normal"
            onClick={props.onCloseButtonClick}
          />
        </div>
      </Modal>
    )
  }
)
